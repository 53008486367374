import { gql } from "../__generated__";

export const currentWorkPeriodSummaryQueryDocument = gql(/* GraphQL */ `
  query currentWorkPeriodSummary {
    profile {
      id
      currentEmployment {
        id
        currentWorkPeriod {
          id
          startTime
          endTimeInclusive
          withdrawableBalance
          earnedBalance
          earnedBalanceCurrent
          earnedBalanceMax
          attendances {
            sumShiftCount
          }
          transactionSummary {
            type
            amount
          }
        }
      }
    }
  }
`);

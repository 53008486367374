import { WatchQueryFetchPolicy, useQuery } from "@apollo/client";
import { balanceQueryDocument } from "../apollo/queries/home.gql";
import { isNonEmptyArray } from "@apollo/client/utilities";
import { useMemo } from "react";
import { isAfterOrEqual } from "../utils/date";
import { isBefore } from "date-fns";
import { floor10 } from "../utils/money";
import { useAppState } from "./useAppState";

export enum FAILURE_CODE {
  "SOCIAL_ID" = "SOCIAL_ID", // thieu social Id
  "BANK_ACCOUNT" = "BANK_ACCOUNT", // thieu bank
  "CONTRACT_TYPE_FULL_TIME" = "CONTRACT_TYPE_FULL_TIME", // contract type
  "DAY_OF_MONTH" = "DAY_OF_MONTH", // gioi hanj ngay rut trong thang (crv)
  "DEDUCTION" = "DEDUCTION", // tru do nhieu ly do
  "PERIOD_ADVANCES_LIMIT" = "PERIOD_ADVANCES_LIMIT", // rut qua so lan trong thang
  "SALARY_CAP" = "SALARY_CAP", // luong qua cao
  "SENIORITY" = "SENIORITY", // 2 thang thu viec
  "STATUS" = "STATUS", // inactive nhung van dung cai ben ngoai`
  "TERMINATION" = "TERMINATION", //sap nghi viec
}

/**
 * Custom hook that retrieves withdrawal balance information.
 * @param fetchPolicy - The fetch policy for the GraphQL query (default: "cache-and-network").
 * @returns An object containing withdrawal balance information and other related data.
 */
function useWithdrawalBalance(
  fetchPolicy: WatchQueryFetchPolicy = "cache-and-network"
) {
  const {
    state: { accessToken },
  } = useAppState();

  const { data, refetch, networkStatus } = useQuery(balanceQueryDocument, {
    skip: !accessToken,
    fetchPolicy,
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  const advanceablePeriods = useMemo(
    () => data?.profile.currentEmployment?.advanceablePeriods,
    [data?.profile.currentEmployment?.advanceablePeriods]
  );

  const currentAdvanceablePeriod = useMemo(() => {
    if (isNonEmptyArray(data?.profile.currentEmployment?.advanceablePeriods)) {
      const now = new Date();
      return data?.profile.currentEmployment?.advanceablePeriods.find(
        (advanceablePeriod) =>
          isAfterOrEqual(
            now,
            new Date(advanceablePeriod.workPeriod.startTime)
          ) &&
          isBefore(now, new Date(advanceablePeriod.workPeriod.endTimeInclusive))
      );
    }
    return undefined;
  }, [data?.profile.currentEmployment?.advanceablePeriods]);

  const previousAdvanceablePeriod = useMemo(() => {
    if (isNonEmptyArray(data?.profile.currentEmployment?.advanceablePeriods)) {
      const now = new Date();
      return data?.profile.currentEmployment?.advanceablePeriods.find(
        (advanceablePeriod) =>
          isAfterOrEqual(
            now,
            new Date(advanceablePeriod.workPeriod.endTimeInclusive) // ngay cutoff
          ) && isBefore(now, new Date(advanceablePeriod.endTimeInclusive)) // ngay tra luong
      );
    }
    return undefined;
  }, [data?.profile.currentEmployment?.advanceablePeriods]);

  const lastMonthSalary = useMemo(() => {
    if (previousAdvanceablePeriod) {
      return floor10(
        previousAdvanceablePeriod.workPeriod.withdrawableBalance.value || 0,
        0
      );
    }
    return floor10(
      currentAdvanceablePeriod?.workPeriod.withdrawableBalance.value || 0,
      0
    );
  }, [
    currentAdvanceablePeriod?.workPeriod.withdrawableBalance.value,
    previousAdvanceablePeriod,
  ]);

  const thisMonthSalary = useMemo(() => {
    if (previousAdvanceablePeriod) {
      return floor10(
        currentAdvanceablePeriod?.workPeriod.withdrawableBalance.value || 0,
        0
      );
    }
    return 0;
  }, [
    currentAdvanceablePeriod?.workPeriod.withdrawableBalance.value,
    previousAdvanceablePeriod,
  ]);

  const withdrawableBalance = useMemo(
    () => lastMonthSalary + thisMonthSalary,
    [lastMonthSalary, thisMonthSalary]
  );

  const minAdvanceValue = useMemo(() => {
    if (data?.profile.currentEmployment?.group?.minAdvanceValue) {
      return data.profile.currentEmployment.group.minAdvanceValue.value;
    }
    return 100_000;
  }, [data?.profile.currentEmployment?.group?.minAdvanceValue]);

  const group = useMemo(() => {
    return data?.profile.currentEmployment?.group;
  }, [data?.profile.currentEmployment?.group]);

  const shouldShowGrayCard = useMemo(() => {
    const failureIndex =
      typeof data?.profile.currentEmployment?.advanceablePeriods?.findIndex(
        (period) => period.workPeriod.advanceValidation.failure
      ) === "number"
        ? data?.profile.currentEmployment?.advanceablePeriods.findIndex(
            (period) => period.workPeriod.advanceValidation.failure
          )
        : -1; // Sai o day

    const failure =
      failureIndex < 0
        ? undefined
        : data?.profile.currentEmployment?.advanceablePeriods[failureIndex]
            .workPeriod.advanceValidation.failure;

    if (
      typeof failureIndex === "number" && // co failure
      failure
      // && failure.code !== FAILURE_CODE.DEDUCTION // khong phai loi kieu deduction => comment out because of new home design
    ) {
      return true;
    }

    return false;
  }, [data?.profile.currentEmployment?.advanceablePeriods]);

  return {
    advanceablePeriods,
    currentAdvanceablePeriod,
    previousAdvanceablePeriod,
    refetch,
    networkStatus,
    group,
    withdrawableBalance,
    minAdvanceValue,
    shouldShowGrayCard,
  };
}

export default useWithdrawalBalance;

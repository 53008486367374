export const LATEST_PHONE_REGEX =
  /^(0|\+84|84)(3[2-9]|5[1-9]|7[0|6-9]|8[1-9]|9[0|1|2|3|4|6|7|8|9]|01[2|6|8|9])([0-9]{7})$/;

export const validatePhone = (phone: string) => {
  return LATEST_PHONE_REGEX.test(phone);
};

export const internationalizeVietnamesePhone = (phone: string) => {
  const phoneNumber = phone.replace(/\s/g, "");
  if (phoneNumber.startsWith("0")) {
    return "+84" + phoneNumber.slice(1);
  } else if (phoneNumber.startsWith("84")) {
    return "+" + phoneNumber;
  } else if (phoneNumber.startsWith("+84")) {
    return phoneNumber;
  } else {
    throw new Error("Invalid phone number");
  }
};

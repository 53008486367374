import { analytics } from "@/services/segment";
import { UserTraits } from "@segment/analytics-next";
import * as Sentry from "@sentry/react";

export const identifyUser = async (
  userId: string | null,
  properties: UserTraits
) => {
  if (userId) {
    const {
      username = undefined,
      phone = undefined,
      company = undefined,
    } = properties;

    analytics.identify(userId, {
      id: userId,
      username: username,
      phone: phone,
      ...(company && {
        company,
      }),
    });

    Sentry.setUser({
      id: userId,
      ...(username && { username }),
    });
  } else {
    analytics.identify(null);
    Sentry.setUser(null);
  }
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CalculateFeeQuery,
  CalculateLoanFeeV2PayloadEntry,
  CreateLoanApplicationMutation,
} from "@/apollo/__generated__/graphql";
import { AtomEffect, atom } from "recoil";

export const WITHDRAWAL_STEP = 100_000;

export type WithdrawalState = {
  step: number;
  value: number;
  voucherId: string;
  entries: CalculateLoanFeeV2PayloadEntry[];
  feeResponse?: CalculateFeeQuery;
  order?: CreateLoanApplicationMutation["advanceV3_createLoanApplication"]["order"];
  loading: boolean;
  error: any;
};

export const defaultWithdrawalState: WithdrawalState = {
  step: 0,
  value: WITHDRAWAL_STEP,
  voucherId: "",
  entries: [],
  feeResponse: undefined,
  order: undefined,
  loading: false,
  error: undefined,
};

const withdrawalEffect: AtomEffect<WithdrawalState> = ({ onSet }) => {
  onSet((newValue) => {
    if (newValue.step === 0) {
      // reset state when going back
      return {
        ...newValue,
        entries: [],
        feeResponse: undefined,
        order: undefined,
        loading: false,
        error: undefined,
        // do not reset value and voucherId for prefill
      };
    }
  });
};

export const withdrawalState = atom<WithdrawalState>({
  key: "withdrawal",
  default: defaultWithdrawalState,
  effects: [withdrawalEffect],
});

import { useQuery } from "@apollo/client";
import { useDeferredValue, useMemo } from "react";
import { activeHomeBannerQueryDocument } from "../../../apollo/queries/home.gql";
import { isNonEmptyArray } from "@apollo/client/utilities";
import ImageSlider from "./ImageSlider";
import { motion } from "framer-motion";

function HomeBanner() {
  const { data } = useQuery(activeHomeBannerQueryDocument, {
    variables: {
      slot: "Home",
    },
  });

  const activeBanners = useMemo(() => {
    if (
      isNonEmptyArray(data?.profile.currentEmployment?.banner_activeBanners)
    ) {
      return data?.profile.currentEmployment?.banner_activeBanners.map(
        (activeBanner) => ({
          imageSrc: activeBanner.contentImage?.uri,
          bgColor: "",
        })
      );
    }
    return [];
  }, [data?.profile.currentEmployment?.banner_activeBanners]);

  const isShowing = useDeferredValue(
    Boolean(activeBanners && isNonEmptyArray(activeBanners)) // NO impact on first render
  );

  return (
    <>
      {isShowing && (
        <motion.div layout>
          <ImageSlider sliders={activeBanners} />
        </motion.div>
      )}
    </>
  );
}
export default HomeBanner;

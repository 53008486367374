import { MessagesQuery } from "@/apollo/__generated__/graphql";
import { ANALYTIC_CATEGORIES, trackEvent } from "@/helpers/events";
import { hasHTMLElement } from "@/utils/string";
import { format } from "date-fns";
import { vi } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type Props = {
  message: MessagesQuery["profile"]["messages"]["nodes"][number];
  unLimitLines?: boolean;
};

function Message({ message, unLimitLines }: Props) {
  const navigate = useNavigate();

  const handleClickMessage: React.MouseEventHandler<HTMLLIElement> = (e) => {
    e.preventDefault();
    // Traker
    trackEvent(ANALYTIC_CATEGORIES.Message, "press_detail_message");
    navigate(`/messages/${message.id}`);
  };

  return (
    <li
      role="button"
      tabIndex={-1}
      onClick={handleClickMessage}
      className="flex items-center gap-x-4 p-4"
    >
      <div className="w-full min-w-0 rounded-xl p-3 shadow-main bg-white">
        <div
          className={twMerge(
            `font-primary text-base leading-6 text-primaryBlack ${
              unLimitLines ? "line-clamp-none" : "line-clamp-1"
            }`,
            hasHTMLElement(message.title) ? undefined : "font-bold" // if title is HTML, don't make it bold
          )}
          dangerouslySetInnerHTML={{
            __html: message.title,
          }}
        />
        <div
          className={`font-primary text-sm leading-5 text-darkGray ${
            unLimitLines ? "line-clamp-none" : "line-clamp-3"
          }`}
          dangerouslySetInnerHTML={{
            __html: message.content,
          }}
        />
      </div>
      <div>
        <time className="text-xs text-gray96">
          {format(new Date(message.createdDate), "HH:mm", {
            locale: vi,
          })}
        </time>
      </div>
    </li>
  );
}

export default Message;

import { PropsWithChildren } from "react";
import NumPad from "./NumPad";
import { twMerge } from "tailwind-merge";

interface IComponentProps extends PropsWithChildren {
  onChange: (val: number) => void;
  onDelete?: () => void;
  disableDelete?: boolean;
  className?: string;
}

type Key = {
  value: number | "NOTHING" | "BACKSPACE";
  label: React.ReactNode;
};

const KEYBOARD_MATRIX: Key[] = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: "NOTHING",
    label: "",
  },
  {
    value: 0,
    label: "0",
  },
  {
    value: "BACKSPACE",
    label: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="h-6 w-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z"
        />
      </svg>
    ),
  },
];

export function T9Keyboard({
  onChange,
  onDelete,
  disableDelete,
  className,
}: IComponentProps) {
  return (
    <div
      className={twMerge(
        "grid h-[250px] w-full grid-cols-3 gap-[1px] border-y border-y-[#F1F1F1] bg-[#F1F1F1]",
        className
      )}
    >
      {KEYBOARD_MATRIX.map((num) => (
        <NumPad
          key={`operator-${num.value}`}
          disabled={num.value === "BACKSPACE" ? disableDelete : false}
          onClick={() => {
            if (num.value === "BACKSPACE") {
              onDelete?.();
              return;
            }
            if (num.value === "NOTHING") {
              return;
            }
            onChange(num.value as number);
          }}
        >
          <span>{num.label}</span>
        </NumPad>
      ))}
    </div>
  );
}

import { Suspense, lazy, useCallback, useEffect, useState } from "react";
import PageContainer from "../components/layouts/PageContainer";
import PageHeader from "../components/PageHeader";
import PinInput from "../components/PinInput";
import { useLazyQuery, useMutation } from "@apollo/client";
import { loginMutationDocument } from "../apollo/mutations/login.gql";
import { useGlobalLoaderService } from "../hooks/useGlobalLoaderService";
import { useAppState } from "../hooks/useAppState";
import { Navigate, useNavigate } from "react-router-dom";
import { graphqlErrorParser } from "@/helpers";
import { autoLinkMutationDocument } from "@/apollo/mutations/onboard.gql";
import {
  getCompanyBySlug,
  getLinkableCompany,
} from "@/apollo/queries/company.gql";
import { PIN_LENGTH } from "@/constants";
import { ANALYTIC_CATEGORIES, trackEvent } from "@/helpers/events";
import { T9Keyboard } from "@/components/Keyboard/T9Keyboard";
import { personalInformationQueryDocument } from "@/apollo/queries/employment.gql";
import { RoutePath } from "@/router/path";
import { requestChangePasswordWithoutSocialIdMutationDocument } from "@/apollo/mutations/password.gql";
import { checkFlowChangePasswordQueryDocument } from "@/apollo/queries/password.gql";
import { useSetRecoilState } from "recoil";
import {
  defaultRestPasswordState,
  resetPasswordState,
} from "@/state/resetPassword";
import { useToast } from "@/hooks/useToast";

const Icon = lazy(() => import("@/components/icons/Lock"));

export const VerifyPinPage = () => {
  const { showMessage } = useToast();
  const navigate = useNavigate();
  const setPasswordState = useSetRecoilState(resetPasswordState);

  const [pin, setPin] = useState<string>("");
  const [loginFunc] = useMutation(loginMutationDocument);
  const [autoLinkMutation] = useMutation(autoLinkMutationDocument);
  const [fetchEmploymentFunc] = useLazyQuery(personalInformationQueryDocument);
  const [fetchLinkableCompanyFunc] = useLazyQuery(getLinkableCompany);

  const [checkFlowChangePasswordFunc] = useLazyQuery(
    checkFlowChangePasswordQueryDocument
  );
  const [requestVerifyOTPChangePasswordFunc] = useMutation(
    requestChangePasswordWithoutSocialIdMutationDocument
  );

  const { loading, setGlobalLoading } = useGlobalLoaderService();
  const [error, setError] = useState<unknown>();
  const {
    state: { deviceToken, slug, phoneNumber },
    setState,
  } = useAppState();

  const handleKeyboardChange = useCallback((val: number) => {
    setPin((old) => `${old}${val}`.slice(0, PIN_LENGTH));
  }, []);

  const handleDelete = useCallback(() => {
    setPin((old) => old.slice(0, -1));
  }, []);

  const performLogin = async () => {
    if (!deviceToken) {
      throw new Error("Missing device token");
    }
    const refetchQueries = slug
      ? [{ query: getCompanyBySlug, variables: { slug } }]
      : [];

    return await loginFunc({
      variables: {
        payload: {
          deviceToken,
          password: pin,
          scope: "loan-application-create offline_access profile",
        },
      },
      refetchQueries,
      awaitRefetchQueries: true,
    });
  };

  const fetchLinkableCompany = async (accessToken?: string) => {
    if (!accessToken) {
      throw new Error("Missing access token");
    }

    return await fetchLinkableCompanyFunc({
      context: {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      },
    });
  };

  const performAutoLink = async (companyId: string, accessToken?: string) => {
    if (!accessToken) {
      throw new Error("Missing access token");
    }

    return await autoLinkMutation({
      variables: {
        payload: {
          companyId,
        },
      },
      context: {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      },
    });
  };

  const handleClickNext = useCallback(async () => {
    try {
      if (loading || !deviceToken) {
        return;
      }

      // tracker
      trackEvent(ANALYTIC_CATEGORIES.LoginCredential, "submit_phone");

      setGlobalLoading(true);

      const { data } = await performLogin();

      // double check employment in server
      const { data: employmentData } = await fetchEmploymentFunc({
        context: {
          headers: {
            authorization: `Bearer ${data?.login.accessToken}`,
          },
        },
      });

      // onboarded
      if (employmentData?.profile.currentEmployment?.id) {
        setState((prevState) => ({
          ...prevState,
          accessToken: data?.login.accessToken,
          refreshToken: data?.login.refreshToken,
        }));

        navigate(RoutePath.Index);
        return;
      }

      // not onboarded -> fetch linkable company
      const { data: linkableCompanyData } = await fetchLinkableCompany(
        data?.login.accessToken
      );

      // -> auto link first company if exist
      if (
        linkableCompanyData?.tryMatchPhoneWithLinkableCompany &&
        linkableCompanyData?.tryMatchPhoneWithLinkableCompany.length > 0
      ) {
        const firstChoice =
          linkableCompanyData?.tryMatchPhoneWithLinkableCompany[0];

        await performAutoLink(firstChoice.id, data?.login.accessToken);

        setState((prevState) => ({
          ...prevState,
          accessToken: data?.login.accessToken,
          refreshToken: data?.login.refreshToken,
          slug: firstChoice.slug,
        }));
        navigate(RoutePath.Index);
        return;
      }

      setState((prevState) => ({
        ...prevState,
        accessToken: data?.login.accessToken,
        refreshToken: data?.login.refreshToken,
      }));

      navigate(RoutePath.Onboard);
    } catch (error) {
      setPin("");
      setError(error);
      console.log({ error });
    } finally {
      setGlobalLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, setGlobalLoading, deviceToken, pin, slug, setState, navigate]);

  const handleForgotPassword: React.MouseEventHandler<
    HTMLButtonElement
  > = async (e) => {
    e.preventDefault();

    if (!phoneNumber) return;

    setGlobalLoading(true);

    try {
      const result = await checkFlowChangePasswordFunc({
        variables: {
          phoneNumber,
        },
      });
      if (result.data?.checkFlowChangePassword.idNumberFirst3Characters) {
        setPasswordState({
          ...defaultRestPasswordState,
          phoneNumber,
          firstThreeCharsOfSocialId:
            result.data?.checkFlowChangePassword.idNumberFirst3Characters,
          flow: result.data?.checkFlowChangePassword.flow,
          step: "idVerification",
        });
      } else {
        // request verify otp => no need to collect user identity
        const res = await requestVerifyOTPChangePasswordFunc({
          variables: {
            phoneNumber,
          },
        });

        setPasswordState({
          ...defaultRestPasswordState,
          phoneNumber,
          flow: result.data?.checkFlowChangePassword.flow,
          requestId: res.data?.requestChangePassword.requestId,
          step: "otpVerification",
        });
      }
      setGlobalLoading(false);
      navigate(RoutePath.ForgotPassword);
    } catch (error) {
      showMessage.error(graphqlErrorParser(error));
      setGlobalLoading(false);
      // TODO error handling
    }
  };

  useEffect(() => {
    if (pin.length === PIN_LENGTH) {
      handleClickNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  if (!deviceToken) {
    return <Navigate to={RoutePath.Login} replace />;
  }

  return (
    <PageContainer className="flex min-h-viewport flex-col justify-between bg-white">
      <PageHeader title="" hideLeftButton hideRightButton />
      <div className="flex-1">
        <div className="px-4 pt-4 grid gap-6">
          <Suspense fallback={<div className="w-16 h-16" />}>
            <Icon className="w-16 h-16 text-primary/10" />
          </Suspense>
          <div>
            <h3 className="text-2xl mb-2 font-bold text-primaryBlack">
              Xin chào, {phoneNumber}
            </h3>
            <p className="text-[15px] leading-[21px] text-darkGray line-clamp-2">
              Mật khẩu đăng nhập Vui của bạn là <br />
              &nbsp;
            </p>
          </div>
          <div className="flex flex-col items-center justify-center gap-2 py-5">
            <PinInput length={PIN_LENGTH} value={pin} />
            {error ? (
              <p
                aria-describedby={graphqlErrorParser(error)}
                className="px-4 text-center text-[13px] leading-5  text-[#FF2638] lining-nums"
              >
                {graphqlErrorParser(error)}
              </p>
            ) : null}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center">
        <button
          className="px-3 py-3 text-primary font-bold text-[15px] leading-[21px] "
          onClick={handleForgotPassword}
        >
          Quên mật khẩu
        </button>
      </div>
      <T9Keyboard
        onChange={handleKeyboardChange}
        onDelete={handleDelete}
        disableDelete={pin.length === 0}
      />
    </PageContainer>
  );
};

export default VerifyPinPage;

import { SVGProps, useId } from "react";
const LogoWhite = (props: SVGProps<SVGSVGElement>) => {
  const phet = useId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 16"
      fill="none"
      {...props}
    >
      <g clipPath={`url(#alo${phet}ola)`}>
        <path
          d="M24.2745 7.08033C25.2156 7.12033 26 6.36033 26 5.44033C26 4.52033 25.2548 3.76033 24.3529 3.76033C23.4117 3.76033 22.6666 4.56033 22.745 5.52033C22.7843 6.36033 23.4509 7.04033 24.2745 7.08033Z"
          fill="white"
        />
        <path
          d="M25.7255 8.71968L22.7059 7.91968L22.2745 7.79968C21.7647 10.5197 19.451 12.5997 16.5882 12.5997C13.4118 12.5997 10.8235 9.95968 10.8235 6.75968C10.8235 6.23968 10.902 5.75968 11.0196 5.27968C11.0588 5.15968 11.098 5.03968 11.1373 4.91968L8.43137 4.23968L7.96078 4.11968L5.68628 10.8797L3.41176 4.07968H0L4 15.9997H7.45098L9.05882 11.1597C10.6667 13.8797 13.5686 15.6797 16.902 15.6797C18.902 15.6797 20.7843 14.9997 22.2745 13.8797C22.2353 13.8797 22.2353 13.9197 22.1961 13.9197C22.2353 13.9197 22.2353 13.8797 22.2745 13.8797V15.9997H25.7255V8.71968ZM11.0588 5.27968C11.0588 5.35968 11.0196 5.43968 11.0196 5.47968C11.0196 5.43968 11.0588 5.39968 11.0588 5.31968C11.0196 5.31968 11.0588 5.31968 11.0588 5.27968ZM10.902 5.99968C10.902 5.91968 10.9412 5.83968 10.9412 5.75968C10.902 5.87968 10.902 5.95968 10.902 5.99968ZM10.7843 6.79968C10.7843 6.95968 10.7843 7.15968 10.7843 7.31968C10.7843 7.15968 10.7843 6.95968 10.7843 6.79968ZM10.8627 6.27968C10.8627 6.35968 10.8235 6.47968 10.8235 6.55968C10.8235 6.47968 10.8235 6.39968 10.8627 6.27968ZM18.8627 14.9597C18.6667 14.9597 18.5098 14.9597 18.3137 14.9597C18.5098 14.9997 18.7059 14.9997 18.8627 14.9597ZM20.4314 14.6797C20.3922 14.6797 20.3922 14.6797 20.3529 14.7197C20.3529 14.7197 20.3922 14.7197 20.4314 14.6797ZM19.9216 14.8397C19.8039 14.8797 19.7255 14.8797 19.6078 14.8797C19.7255 14.8797 19.8039 14.8397 19.9216 14.8397ZM19.4118 14.9197C19.2941 14.9197 19.1765 14.9597 19.0196 14.9597C19.1373 14.9597 19.2941 14.9197 19.4118 14.9197ZM21.8039 14.1197C21.8039 14.1197 21.8431 14.1197 21.8431 14.0797C21.8431 14.1197 21.8039 14.1197 21.8039 14.1197ZM20.8235 14.5597C20.8627 14.5597 20.8627 14.5597 20.902 14.5197C20.902 14.5597 20.8627 14.5597 20.8235 14.5597Z"
          fill="white"
        />
        <path
          d="M10.3138 3.32C11.2942 3.44 12.1569 2.64 12.1569 1.68C12.1569 0.76 11.4118 0 10.5099 0C9.52947 0 8.78437 0.84 8.90202 1.84C8.98045 2.6 9.56868 3.24 10.3138 3.32Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={`alo${phet}ola`}>
          <rect width="26" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default LogoWhite;

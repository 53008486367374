import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

type Props = PropsWithChildren<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>;

const PrimaryContainerButton = ({ children, className, ...props }: Props) => {
  return (
    <button
      className={twMerge(
        "group flex h-12 w-full items-center justify-center rounded-xl bg-primary shadow-normal disabled:cursor-not-allowed disabled:bg-[#EEEEEE] disabled:text-[#B3B3B3]",
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default PrimaryContainerButton;

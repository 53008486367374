import { useEffect, useRef } from "react";
import { Location, useLocation } from "react-router-dom";

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

const useLocationChange = (
  action: (
    location: Location<unknown>,
    prevLocation: Location<unknown> | undefined
  ) => void
) => {
  const location = useLocation();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    action(location, prevLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
};

export default useLocationChange;

import { AppState, defaultAppState, appState } from "@/state/user";
import { useCallback } from "react";
import { SetterOrUpdater, useRecoilState } from "recoil";

type UserService = {
  state: AppState;
  clear: () => void;
  setState: SetterOrUpdater<AppState>;
};

// for backward compatibility
export function useAppState(): UserService {
  const [state, setState] = useRecoilState<AppState>(appState);
  const clear = useCallback(() => {
    setState({ ...defaultAppState, initialized: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    state,
    clear,
    setState,
  };
}

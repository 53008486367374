import { PropsWithChildren, memo, useMemo } from "react";
import { twMerge } from "tailwind-merge";

interface IComponentProps extends PropsWithChildren {}

function Cell({ children }: IComponentProps) {
  const hasChild = useMemo(() => {
    return !!children;
  }, [children]);

  return (
    <div
      className={twMerge(
        "flex h-11 xs:h-14 w-9 xs:w-12 items-center justify-center rounded-lg border bg-white text-xl font-bold",
        hasChild ? "border-primary" : "border-[#E5E7EB]"
      )}
    >
      {children}
    </div>
  );
}

const MemoizedCell = memo(
  Cell,
  (oldProps, newProps) => oldProps.children === newProps.children
);

export default MemoizedCell;

import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

const PageContainer = ({
  children,
  className,
  ...rest
}: PropsWithChildren<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>) => {
  return (
    <div
      className={twMerge(
        "h-[var(--window-height)] w-full bg-white overflow-y-auto",
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export default PageContainer;

import { get } from "lodash-es";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const storage: Storage = sessionStorage;
export const persistKey = "app-preferences";

export function getState<T>(stateKey: string): T {
  const toParse = storage.getItem(persistKey);

  if (toParse === null || toParse === undefined) {
    return {} as T;
  }
  if (typeof toParse === "string") {
    return get(parseState<T>(toParse), stateKey); // chua xong
  }

  return {} as T;
}

export function parseState<T>(state: string): T {
  if (state === undefined) {
    return {} as T;
  }
  try {
    return JSON.parse(state);
  } catch (e) {
    console.error(e);
    return {} as T;
  }
}

import React from "react";
import { twMerge } from "tailwind-merge";

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export default function NumPad({
  children,
  className,
  disabled,
  onClick,
  ...rest
}: Props) {
  return (
    <button
      onClick={onClick}
      className={twMerge(
        "flex items-center justify-center bg-white text-xl font-medium lining-nums leading-6 active:bg-[#F5F5F5] disabled:bg-gray-200",
        className
      )}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
}

import { useQuery } from "@apollo/client";
import * as React from "react";
import { messagesQueryDocument } from "@/apollo/queries/message.gql";

// eslint-disable-next-line react-refresh/only-export-components
const LIMIT = 24; // dont wanna merge in InmemmoryCache

interface IProps {
  skip?: boolean;
}

function useMessages({ skip = false }: IProps) {
  const { data, loading, fetchMore, error, refetch } = useQuery(
    messagesQueryDocument,
    {
      skip,
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      variables: {
        query: {
          limit: LIMIT,
          offset: 0,
        },
      },
    },
  );

  const hasNextPage = React.useMemo(() => {
    const messages = data?.profile.messages;
    return !!(
      messages?.total && messages.total > (messages.nodes || []).length
    );
  }, [data?.profile.messages]);

  return {
    loading,
    items: data?.profile.messages.nodes || [],
    hasNextPage,
    error,
    fetchMore,
    limit: LIMIT,
    refetch,
  };
}

export default useMessages;

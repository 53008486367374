/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum InactiveType {
  Inactive = "Inactive",
  InactiveCompany = "InactiveCompany",
  Resign = "Resign",
}

export enum ShiftUnit {
  Day = "Day",
  Hour = "Hour",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

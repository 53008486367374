import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

type Props = PropsWithChildren<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>;

const MenuItem = ({ type = "button", className, children, ...rest }: Props) => {
  return (
    <button
      type={type}
      className={twMerge(
        "flex flex-col items-center justify-start gap-y-2 py-[5px]",
        className,
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

export default MenuItem;

const MenuLoading = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-y-2 py-[5px]">
      <div className="flex h-8 w-8 items-center justify-center">
        <div className="h-8 w-8 animate-pulse rounded-full bg-slate-200" />
      </div>
      <div>
        <p className="mb-1.5 h-3 w-12 animate-pulse rounded bg-slate-200 text-center text-xs font-medium leading-[15px] text-darkGray"></p>
        <p className="h-3 w-12 animate-pulse rounded bg-slate-200 text-center  text-xs font-medium leading-[15px] text-darkGray"></p>
      </div>
    </div>
  );
};

export default MenuLoading;

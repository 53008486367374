import { PLATFORM } from "@/constants";

import { AnalyticsBrowser, MiddlewareFunction } from "@segment/analytics-next";

const isDEV = import.meta.env.DEV;

export const writeKey = isDEV
  ? "s8btdoDuwCAwV2cb8AixnxS85PyxpfIC" // Open Vui Sandbox write key
  : import.meta.env.VITE_SEGMENT_WRITE_KEY; // Production write key or something else

export const analytics = new AnalyticsBrowser();

const logMiddleware: MiddlewareFunction = ({ next, payload }) => {
  console.log(payload.obj.type, payload.obj?.properties);

  return next(payload);
};

const platformMiddleware: MiddlewareFunction = ({ next, payload }) => {
  payload.obj.properties = {
    ...payload.obj.properties,
    platform: PLATFORM, // Inject platform
  };
  return next(payload);
};

analytics.addSourceMiddleware(platformMiddleware);

if (isDEV) {
  analytics.addSourceMiddleware(logMiddleware);
}

type Props = { title?: string };

function MenuTitle({ title = "" }: Props) {
  return (
    <h4 className="text-[15px] font-bold leading-[21px] text-primaryBlack">
      {title}
    </h4>
  );
}

export default MenuTitle;

import { Dialog } from "@headlessui/react";
import VuiLoading from "../Lotties/Loading";
import { useRef } from "react";

type Props = {
  isOpen: boolean;
};

const FullScreenLoading = ({ isOpen }: Props) => {
  const refDiv = useRef(null);
  return (
    <Dialog
      open={isOpen}
      onClose={() => null}
      initialFocus={refDiv}
      className={"mx-auto max-w-[480px] fixed inset-0 z-[99999999]"}
    >
      <div className="absolute inset-0 bg-black/40" aria-hidden="true" />
      <div className="absolute inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-sm rounded-2xl bg-white p-4">
          <div
            ref={refDiv}
            className="flex h-full w-full items-center justify-center "
          >
            <VuiLoading />
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default FullScreenLoading;

import { persistKey, storage } from "@/utils/storage";
import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: persistKey, // store the same key with user becasue we want to clear the storage when user logout
  storage,
});

type LastSyncTimeState = number | null; // Unix timestamp

const initialState: LastSyncTimeState = null;

// An atom using Recoil for state management of the last sync time
export const lastSyncTimeState = atom({
  key: "lastSyncTimeState",
  default: initialState,
  effects: [persistAtom],
});

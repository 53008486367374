export function hideSplashScreen() {
  if (!window) {
    return;
  }
  const splashScreen = window.document.getElementById("bootsplash");

  if (!splashScreen) {
    return;
  }

  if (splashScreen.classList.contains("bootsplash--hidden")) {
    return;
  }

  splashScreen.classList.add("bootsplash--hidden");
}

import { MessagesQuery } from "@/apollo/__generated__/graphql";
import { markAllNotificationsAsReadMutationDocument } from "@/apollo/mutations/message.gql";
import { totalUnReadMessageQueryDocument } from "@/apollo/queries/message.gql";
import SimpleLoading from "@/components/Loader/SimpleLoading";
import PageHeader from "@/components/PageHeader";
import PageContainer from "@/components/layouts/PageContainer";
import Message from "@/components/messages/Message";
import Timeline from "@/components/messages/Timeline";
import withScrollReset from "@/hocs/withScrollReset";
import { useMessages } from "@/hooks";
import { RoutePath } from "@/router/path";
import { useMutation } from "@apollo/client";
import { format } from "date-fns";
import { vi } from "date-fns/locale";
import _, { isEmpty, orderBy, map, property, uniqBy } from "lodash-es";
import { Fragment, useEffect, useMemo } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { v4 as uuidv4 } from "uuid";

const InAppMessagePage = () => {
  const navigate = useNavigate();
  const {
    items: messages,
    loading,
    hasNextPage,
    fetchMore,
    limit,
    error,
  } = useMessages({
    skip: false,
  });

  const [markAllAsReadMutation] = useMutation(
    markAllNotificationsAsReadMutationDocument
  );

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: async () => {
      try {
        const currentLength = messages.length;

        if (!loading) {
          await fetchMore({
            variables: {
              query: {
                limit,
                offset: currentLength,
              },
            },
          });
        }
      } catch (error) {
        // TODO handle error here
        console.log(error);
      }
    },
    disabled: !!error,
    rootMargin: "0px 0px 100px 0px",
  });

  const listMessages: MessagesQuery["profile"]["messages"]["nodes"] =
    useMemo(() => {
      if (messages) {
        const derivedMessages = map(messages, (message) => {
          if (!message.group?.id) {
            return {
              ...message,
              group: {
                id: uuidv4(),
              } as MessagesQuery["profile"]["messages"]["nodes"][number]["group"],
            };
          }
          return message;
        });
        return uniqBy(uniqBy(derivedMessages, property("group.id")), "id");
      }
      return [];
    }, [messages]);

  const sectionListData = useMemo(() => {
    if (isEmpty(listMessages)) {
      return [];
    }

    return _.chain(orderBy(listMessages, ["id"], ["desc"]))
      .groupBy((item) =>
        format(new Date(item.createdDate), "EEEE dd LLL", {
          locale: vi,
        })
      )
      .map((value, key) => ({
        date: key,
        data: orderBy(value, ["id"], ["desc"]),
      }))
      .value();
  }, [listMessages]);

  const handleGoBack = () => {
    try {
      if (window.history.state && window.history.state.idx > 0) {
        navigate(-1);
      } else {
        navigate(RoutePath.Home, { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
      }
    } catch (error) {
      console.log("error", error);
      navigate(RoutePath.Home, { replace: true });
    }
  };

  useEffect(() => {
    const asyncAction = async () => {
      try {
        await markAllAsReadMutation({
          optimisticResponse: {
            markAllAsRead: null,
          },
          errorPolicy: "ignore",
          refetchQueries: [{ query: totalUnReadMessageQueryDocument }],
        });
      } catch (error) {
        console.log("error", error);
      }
    };

    asyncAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer className="min-h-viewport bg-white pb-14">
      <PageHeader
        onLeftButtonPress={handleGoBack}
        title="Vui Thông báo"
        hideRightButton
        wrapperClassName="z-[11] bg-white"
      />
      <div>
        {sectionListData && sectionListData.length > 0 ? (
          <>
            {sectionListData.map((messageByDay) => (
              <div
                key={`section_title_${messageByDay.date}`}
                className="relative "
              >
                <div className="sticky top-12 z-10 bg-white px-3 py-1.5 text-center text-xs font-medium leading-6 text-darkGray">
                  <h3>{messageByDay.date}</h3>
                </div>
                <ul role="list">
                  {messageByDay.data.map((message, idx) =>
                    message.tags.includes("loanApplication") ? (
                      <div
                        className="p-4"
                        key={`timeline_${message.id}_${idx}`}
                      >
                        <Timeline message={message} />
                      </div>
                    ) : (
                      <Fragment key={`message_${message.id}_${idx}`}>
                        {message && <Message message={message} />}
                      </Fragment>
                    )
                  )}
                </ul>
              </div>
            ))}
          </>
        ) : (
          <div
            className={twMerge(
              "flex h-[300px] w-full justify-center items-center py-4",
              loading && "!hidden"
            )}
          >
            <img src="/images/no-messages.png" className="w-48 object-cover" />
          </div>
        )}
        {(loading || hasNextPage) && (
          <div
            ref={sentryRef}
            className="flex w-full items-center justify-center py-3"
          >
            <SimpleLoading text="Đang tải thêm dữ liệu" />
          </div>
        )}
      </div>
    </PageContainer>
  );
};

export const InAppMessagePageWithScrollReset =
  withScrollReset(InAppMessagePage);

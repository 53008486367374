import { gql } from "../__generated__";

export const personalInformationQueryDocument = gql(/* GraphQL */ `
  query personalInformation {
    profile {
      id
      phoneNumber
      currentEmployment {
        id
        status
        fullName
        phoneNumber
        employeeId
        socialIdNumber
        bank {
          id
          code
          name
          logo {
            id
            uri
          }
        }
        bankAccount
        company {
          id
          name
          shortName
          logo {
            id
            uri
          }
          features {
            payslip
          }
        }
        features {
          withdrawAllAmount
          attendanceToggle
          isSpendingEnable
          topUp
          softpin
        }
      }
    }
  }
`);

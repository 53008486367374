/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import AttendanceInfo from "../components/attendance/AttendanceInfo";
import Calendar, {
  DATE_FORMAT_FOR_QUERY,
} from "../components/attendance/Calendar";
import PrimaryContainerButton from "../components/button/PrimaryContainerButton";
import PageContainer from "../components/layouts/PageContainer";
import useWithdrawalBalance from "../hooks/useWithdrawalBalance";
import Modal from "../components/Modal";
import { useCallback, useMemo, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  TotalWorkPeriodsQuery,
  OrderDirection,
  EmploymentWorkPeriodOrderable,
  AttendanceType,
  ShiftUnit,
} from "../apollo/__generated__/graphql";
import {
  attendancesQueryDocument,
  totalWorkPeriosQueryDocument,
} from "../apollo/queries/attendances.gql";
import WorkPeriodPicker, {
  PickerRef,
} from "../components/attendance/WorkPeriodPicker";
import {
  format,
  startOfDay,
  startOfMonth,
  endOfDay,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  differenceInDays,
  addDays,
  isSameMonth,
  getDate,
  getMonth,
  subSeconds,
} from "date-fns";
import { isBetweenInclusive } from "../utils/date";
import { vi } from "date-fns/locale";
import { RoutePath } from "@/router/path";
import { ANALYTIC_CATEGORIES, trackEvent } from "@/helpers/events";
import { useToast } from "@/hooks/useToast";

export const AttendancePage = () => {
  const { showMessage } = useToast();
  const pickerRef = useRef<PickerRef>(null);
  const [activeAttendance, setActiveAttendance] = useState<any>();
  const { withdrawableBalance, minAdvanceValue, shouldShowGrayCard } =
    useWithdrawalBalance();
  const navigate = useNavigate();

  const [activeWorkPeriod, setActiveWorkPeriod] =
    useState<
      NonNullable<
        TotalWorkPeriodsQuery["profile"]["currentEmployment"]
      >["workPeriods"]["nodes"][number]
    >();

  const { data: totalWorkPeriodData } = useQuery(totalWorkPeriosQueryDocument, {
    variables: {
      query: {
        orderDirection: OrderDirection.Desc,
        orderBy: EmploymentWorkPeriodOrderable.StartTime,
        limit: 100,
        offset: 0,
      },
    },
    onCompleted(data) {
      setActiveWorkPeriod(data.profile.currentEmployment?.currentWorkPeriod);
    },
  });

  const { data } = useQuery(attendancesQueryDocument, {
    skip: !(
      activeWorkPeriod?.id &&
      activeWorkPeriod?.startTime &&
      activeWorkPeriod?.endTimeInclusive
    ),
    notifyOnNetworkStatusChange: true,
    variables: {
      query: {
        limit: 100,
        offset: 0,
        filter: {
          notTypes: [AttendanceType.Unknown],
          dateRange: {
            from: format(
              activeWorkPeriod
                ? new Date(activeWorkPeriod?.startTime)
                : new Date(),
              DATE_FORMAT_FOR_QUERY
            ),
            toInclusive: format(
              activeWorkPeriod
                ? new Date(activeWorkPeriod?.endTimeInclusive)
                : new Date(),
              DATE_FORMAT_FOR_QUERY
            ),
          },
        },
      },
      workPeriodId: activeWorkPeriod?.id as string,
    },
  });

  const handleShowPicker = useCallback(() => {
    pickerRef.current?.show();
  }, []);

  const attendances = useMemo(() => {
    const startTimeObj = activeWorkPeriod
      ? startOfDay(new Date(activeWorkPeriod.startTime))
      : startOfMonth(new Date());

    const endTimeObj = activeWorkPeriod
      ? endOfDay(subSeconds(new Date(activeWorkPeriod.endTimeInclusive), 1))
      : endOfMonth(new Date());

    const startDate = startOfWeek(startTimeObj, {
      weekStartsOn: 1,
    });

    const endDate = endOfWeek(endTimeObj, {
      weekStartsOn: 1,
    });

    const diff = differenceInDays(endDate, startDate);

    const dates = Array.from({ length: diff + 1 }) // +1 includes enddate
      .fill(0)
      .map((_, idx) => {
        return addDays(startDate, idx);
      });

    const blankCalendar = dates.map((dateObj) => {
      return {
        isSamePeriod: activeWorkPeriod
          ? isBetweenInclusive(dateObj, startTimeObj, endTimeObj)
          : isSameMonth(dateObj, startTimeObj),
        dateInNumber:
          getDate(dateObj) === 1
            ? `${getDate(dateObj)}/${getMonth(dateObj) + 1}`
            : getDate(dateObj),
        dateObj: dateObj,
        date: format(dateObj, DATE_FORMAT_FOR_QUERY),
      };
    });

    return blankCalendar.map((generatedDate) => {
      return {
        ...generatedDate,
        ...(data?.profile.currentEmployment?.attendances.nodes &&
          data.profile.currentEmployment.attendances.nodes.find(
            (item) => item.date === generatedDate.date
          )),
      };
    });
  }, [activeWorkPeriod, data?.profile.currentEmployment?.attendances?.nodes]);

  const disabledWithdrawal = useMemo(() => {
    return withdrawableBalance < minAdvanceValue || shouldShowGrayCard;
  }, [withdrawableBalance, minAdvanceValue, shouldShowGrayCard]);

  const handlePressWithdrawal = () => {
    // Tracker
    trackEvent(ANALYTIC_CATEGORIES.Attendance, "press_withdrawal", {
      name: "Rút tiền",
    });

    if (disabledWithdrawal) {
      showMessage.error("Bạn không thể rút tiền vào thời điểm này");
      return;
    }

    navigate(RoutePath.Withdrawal);
    return;
  };

  const onDayClick = (item: any) => {
    // Tracker
    trackEvent(ANALYTIC_CATEGORIES.Attendance, "press_date", {
      date: item.date,
      shiftCount: item.shiftCount,
    });

    setActiveAttendance(item);
  };

  const handleSelectWorkPeriod = useCallback(
    (
      item: NonNullable<
        TotalWorkPeriodsQuery["profile"]["currentEmployment"]
      >["workPeriods"]["nodes"][number]
    ) => {
      trackEvent(ANALYTIC_CATEGORIES.Attendance, "press_month", {
        workPeriodId: item.id,
        startTime: item.startTime,
        endTimeInclusive: item.endTimeInclusive,
      });
      setActiveWorkPeriod(item);
    },
    []
  );

  return (
    <>
      <PageContainer className="pb-[72px] flex flex-col gap-y-0">
        <PageHeader
          title="Chi tiết bảng công"
          hideRightButton
          wrapperClassName="bg-white"
        />
        <AttendanceInfo
          onClick={handleShowPicker}
          shiftCountUnit={data?.profile.currentEmployment?.shiftCountUnit}
          startTime={activeWorkPeriod?.startTime}
          endTime={activeWorkPeriod?.endTimeInclusive}
          sumShiftCount={
            data?.profile.currentEmployment?.workPeriod?.attendances
              .sumShiftCount || 0
          }
        />
        <Calendar
          attendances={attendances}
          onDayClick={onDayClick}
          unit={data?.profile.currentEmployment?.shiftCountUnit}
        />
      </PageContainer>
      <div className="fixed bottom-0 left-0 right-0 mx-auto max-w-[480px] bg-white p-4">
        <PrimaryContainerButton
          onClick={handlePressWithdrawal}
          disabled={disabledWithdrawal}
        >
          <p className="text-base font-medium text-white">Rút lương</p>
        </PrimaryContainerButton>
      </div>
      <Modal
        panelClassName="w-full"
        textClassName="w-full text-left"
        open={!!activeAttendance}
        onClose={() => setActiveAttendance(false)}
        onConfirm={() => setActiveAttendance(false)}
        confirmText="Đóng"
        title={
          <span className="text-primary">
            {activeAttendance?.dateObj
              ? format(activeAttendance?.dateObj, "eeee, dd/MM/yyyy", {
                  locale: vi,
                })
              : ""}
            &nbsp;
          </span>
        }
        descriptionWrapperClassName={"mt-4"}
        description={
          <>
            <div className="flex items-center justify-between">
              <strong>Thời gian ghi nhận:</strong>
              <span>
                {activeAttendance?.shiftCount}{" "}
                {data?.profile.currentEmployment?.shiftCountUnit ===
                ShiftUnit.Hour
                  ? "giờ"
                  : "ngày"}
                &nbsp;
              </span>
            </div>
          </>
        }
      />
      <WorkPeriodPicker
        ref={pickerRef}
        items={
          totalWorkPeriodData?.profile.currentEmployment?.workPeriods.nodes ||
          []
        }
        activeWorkPeriod={activeWorkPeriod}
        onSelect={handleSelectWorkPeriod}
      />
    </>
  );
};

export default AttendancePage;

import { Fragment, PropsWithChildren, Suspense } from "react";
import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import { twMerge } from "tailwind-merge";
import PrimaryContainerButton from "../button/PrimaryContainerButton";
import WhiteOutlinedButton from "../button/WhiteOutlinedButton";

interface IComponentProps extends PropsWithChildren {
  open: boolean;
  onConfirm?: () => void;
  onClose: (value: boolean) => void;
  title?: React.ReactNode;
  description?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  icon?: React.ReactNode;
  onCancel?: () => void;
  panelClassName?: string;
  textClassName?: string;
  descriptionWrapperClassName?: HTMLDivElement["className"];
  actionsClassName?: HTMLDivElement["className"];
  renderTextContent?: ({
    open,
    title,
    description,
  }: {
    open: boolean;
    title?: React.ReactNode;
    description?: React.ReactNode;
  }) => React.ReactNode;
  hideActions?: boolean;
}

export default function Modal({
  open,
  onClose,
  icon,
  title,
  description,
  onConfirm,
  confirmText,
  onCancel,
  cancelText,
  panelClassName,
  textClassName,
  descriptionWrapperClassName,
  actionsClassName,
  renderTextContent,
  hideActions = false,
}: IComponentProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 font-primary lining-nums"
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-5 text-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={twMerge(
                  "relative max-w-[480px] transform overflow-hidden rounded-lg bg-white p-4 text-left shadow-xl transition-all",
                  panelClassName
                )}
              >
                {renderTextContent ? (
                  <>{renderTextContent({ open, title, description })}</>
                ) : (
                  <div className="flex flex-col items-center justify-center">
                    <Suspense fallback={<div>Loading...</div>}>
                      {icon ? icon : null}
                    </Suspense>
                    <div className={twMerge(textClassName)}>
                      <Dialog.Title
                        as="h1"
                        className="text-xl font-bold text-primaryBlack"
                      >
                        {title}
                      </Dialog.Title>
                      <div
                        className={twMerge("mt-2", descriptionWrapperClassName)}
                      >
                        <div className="text-[15px] leading-[22px] text-darkGray">
                          {description}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {hideActions ? null : (
                  <div
                    className={twMerge(
                      "mt-5 grid grid-cols-1 gap-y-3 sm:mt-6",
                      actionsClassName
                    )}
                  >
                    {confirmText ? (
                      <PrimaryContainerButton onClick={onConfirm}>
                        <span className="text-base font-bold text-white">
                          {confirmText}
                        </span>
                      </PrimaryContainerButton>
                    ) : null}
                    {cancelText ? (
                      <WhiteOutlinedButton onClick={onCancel}>
                        <span className="text-darkGray">{cancelText}</span>
                      </WhiteOutlinedButton>
                    ) : null}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

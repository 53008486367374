/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-catch */
export const moneyToNumber = (money: string) => {
  if (!money) {
    return 0;
  }
  const [amount] = money.split(" ");
  const nums = amount.split(/[,.]/);
  const ret = [nums.slice(0, nums.length - 1).join("")];
  if (nums.length < 2) {
    return +nums[0];
  }
  ret.push(nums[nums.length - 1]);
  return +ret.join(nums[nums.length - 1].length < 3 ? "." : "");
};

export const moneyToCurrency = (money: string) => {
  const [, currency] = money.split(" ");
  return currency;
};

export const addCommas = (n: number) => {
  const rx = /(\d+)(\d{3})/;
  return String(n).replace(/^\d+/, (w) => {
    while (rx.test(w)) {
      w = w.replace(rx, "$1,$2");
    }
    return w;
  });
};

export const numberToMoney = (
  amount = 0,
  hideCurrency = false,
  currency = " VND",
) => {
  try {
    // const formatted =
    //   Platform.OS === 'android'
    //     ? amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    //     : Number(amount.toFixed(0)).toLocaleString('vi-VN');

    const formatted = amount
      .toFixed(0)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    return `${formatted}${!hideCurrency ? currency : ""}`;
  } catch (e) {
    throw e;
  }
};

function decimalAdjust(
  type: "round" | "floor" | "ceil",
  value: any,
  exp: number,
) {
  // If the exp is undefined or zero...
  if (typeof exp === "undefined" || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split("e");
  value = Math[type](+`${value[0]}e${value[1] ? +value[1] - exp : -exp}`);
  // Shift back
  value = value.toString().split("e");
  return +`${value[0]}e${value[1] ? +value[1] + exp : exp}`;
}

// Decimal round
export const round10 = (value: any, exp: number) =>
  decimalAdjust("round", value, exp);
// Decimal floor
export const floor10 = (value: any, exp: number) =>
  decimalAdjust("floor", value, exp);
// Decimal ceil
export const ceil10 = (value: any, exp: number) =>
  decimalAdjust("ceil", value, exp);

export const generateSuggestions = (amount: number) => {
  let suggestions = [100_000, 200_000, 500_000];
  if (amount <= 0) {
    suggestions = [100_000, 200_000, 500_000];
  }
  if (amount > 0 && amount < 10) {
    suggestions = [amount * 10_000, amount * 100_000, amount * 1_000_000];
  }
  if (amount > 9 && amount < 100) {
    suggestions = [amount * 1_000, amount * 10_000, amount * 100_000];
  }
  if (amount > 99 && amount < 1000) {
    suggestions = [amount * 100, amount * 1_000, amount * 10_000];
  }
  if (amount > 999 && amount < 10_000) {
    suggestions = [amount * 100, amount * 1_000, amount * 10_000];
  }
  if (amount > 9_999 && amount < 100_000) {
    suggestions = [amount * 100, amount * 1_000];
  }
  if (amount > 99_999 && amount < 1_000_000) {
    suggestions = [amount * 100];
  }

  return suggestions.map((s) => Number(s.toFixed(0)));
};

import { gql } from "../__generated__";

export const syncEmploymentFromExternalSourceMutationDocument = gql(
  /* GraphQL */ `
    mutation syncEmploymentFromExternalSource($id: ID!) {
      syncEmploymentFromExternalSource(id: $id)
    }
  `
);

export const unlinkUserEmploymentMutationDocument = gql(/* GraphQL */ `
  mutation unlinkUserEmployment($id: ID!) {
    unlinkUserEmployment(id: $id) {
      employment {
        id
      }
    }
  }
`);

import PageContainer from "@/components/layouts/PageContainer";
import PrimaryContainerButton from "@/components/button/PrimaryContainerButton";
import withCSBubble from "@/hocs/withCSBubble";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePath } from "@/router/path";

function ErrorPage() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleRetry = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    navigate(RoutePath.Index, {
      replace: true,
    });
  };

  return (
    <PageContainer className="flex h-viewport w-full flex-col">
      <div className="flex-1 flex flex-col justify-center items-center gap-y-5">
        <img
          className="object-contain object-center w-full max-w-[248px]"
          src="/images/onboarding_fail.webp"
          alt="Onboarding fail"
        />
        <div className="space-y-1 text-center px-5">
          <h3 className="font-bold text-xl text-primaryOnTheme">
            {state?.errorTitle || "Có lỗi xảy ra"}
          </h3>
          <p className="font-normal text-base text-secondaryOnTheme">
            {state?.errorMessage || ""}
          </p>
        </div>
      </div>
      {!state?.hideRetry && (
        <div className="p-4">
          <PrimaryContainerButton
            onClick={handleRetry}
            type="button"
            className="text-white"
          >
            <span className="font-medium lining-nums text-white">Thử lại</span>
          </PrimaryContainerButton>
        </div>
      )}
    </PageContainer>
  );
}

export const ServiceIneligibilityErrorPage = withCSBubble(ErrorPage);

import { gql } from "../__generated__";

export const markAllNotificationsAsReadMutationDocument = gql(/* GraphQL */ `
  mutation markAllAsRead {
    markAllAsRead
  }
`);

export const markNotificationAsReadMutationDocument = gql(/* GraphQL */ `
  mutation markNotificationAsReadMutationDocument($id: ID!) {
    markAsRead(id: $id) {
      message {
        id
        status
      }
    }
  }
`);

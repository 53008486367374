import { gql } from "../__generated__";

export const agreementQueryDocumentTemplateString = /* GraphQL */ `
  query agreement {
    profile {
      id
      phoneNumber
      currentEmployment {
        id
        company {
          id
          status
        }
        status
        agreement {
          id
          createdDate
          lastModifiedDate
          status
          agreementHtmlFile {
            id
            uri
          }
          sign {
            id
            file {
              id
              uri
            }
          }
        }
      }
    }
  }
`;

export const agreementQueryDocument = gql(agreementQueryDocumentTemplateString);

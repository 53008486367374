import { gql } from "../__generated__";

export const totalWorkPeriosQueryDocument = gql(/* GraphQL */ `
  query totalWorkPeriods($query: EmploymentWorkPeriodQuery) {
    profile {
      id
      currentEmployment {
        id
        currentWorkPeriod {
          id
          startTime
          endTimeInclusive
        }
        workPeriods(query: $query) {
          total
          limit
          offset
          nodes {
            id
            startTime
            endTimeInclusive
          }
        }
      }
    }
  }
`);

export const attendancesQueryDocument = gql(/* GraphQL */ `
  query attendances($query: AttendanceQuery!, $workPeriodId: ID!) {
    profile {
      id
      currentEmployment {
        id
        shiftCountUnit
        workPeriod(id: $workPeriodId) {
          id
          attendances {
            sumShiftCount
          }
        }
        attendances(query: $query) {
          limit
          offset
          sumShiftCount
          total
          nodes {
            id
            createdDate
            lastModifiedDate
            status
            date
            checkinSecond
            checkoutSecond
            workingDayType
            workingTimeHours
            workingTimeRatePerHour
            submittedAt
            earnedBalance
            type
            vendorType
            times
            shiftCount
            shiftWork
          }
        }
      }
    }
  }
`);

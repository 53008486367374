import MenuItem from "./MenuItem";
import MenuTitle from "./MenuTitle";

import { useNavigate } from "react-router-dom";
import { RoutePath } from "@/router/path";
import { Feature, defaultFeatures } from "./data";
import MenuLoading from "./MenuLoading";
import { Suspense } from "react";
import { ANALYTIC_CATEGORIES, trackEvent } from "@/helpers/events";
import { useWithdrawalBalance } from "@/hooks";
import toast from "react-hot-toast";
import { motion } from "framer-motion";

type Props = {
  title?: string;
  features?: Feature[];
  loading?: boolean;
};

function Menu({
  title = "Tiền lương của bạn",
  features = defaultFeatures,
  loading = true,
}: Props) {
  const navigate = useNavigate();
  const { withdrawableBalance } = useWithdrawalBalance();
  const handlePressMenuItem = (name: string) => {
    switch (name) {
      case "attendance":
        // tracker
        trackEvent(ANALYTIC_CATEGORIES.Attendance, "press_attendance");
        navigate(RoutePath.Attendance);
        break;
      case "payslip":
        // tracker
        trackEvent(ANALYTIC_CATEGORIES.Payslip, "view_payslip");
        navigate(RoutePath.Payslip);
        break;
      case "transaction_history":
        // tracker
        trackEvent(ANALYTIC_CATEGORIES.LoanHistory, "press_loan_history");
        navigate(RoutePath.Transaction);
        break;
      case "top_up":
        // tracker
        trackEvent(ANALYTIC_CATEGORIES.TopUp, "press_button");

        if (withdrawableBalance < 20_000) {
          toast.error("Số dư không đủ để thực hiện giao dịch", {
            id: "balance_not_enough",
          });
          return;
        }

        navigate(RoutePath.TopUp);
        break;
      case "phone_card":
        // tracker
        trackEvent(ANALYTIC_CATEGORIES.PhoneCard, "press_phonecard");

        if (withdrawableBalance < 20_000) {
          toast.error("Số dư không đủ để thực hiện giao dịch", {
            id: "balance_not_enough",
          });
          return;
        }
        navigate(RoutePath.PhoneCard);
        break;
      case "setting":
        trackEvent(ANALYTIC_CATEGORIES.Setting, "press_setting");
        navigate(RoutePath.Setting);
        break;
      default:
        break;
    }
  };

  if (!features.length && !loading) return null;

  return (
    <motion.div layout className="flex flex-col gap-y-3">
      <MenuTitle title={title} />
      <div className="xs:grid-cols-4 grid w-full grid-cols-3 gap-3">
        {loading ? (
          <>
            {[1, 2, 3].map((item) => {
              return <MenuLoading key={`skeleton_item_${item}`} />;
            })}
          </>
        ) : (
          <>
            {features.map((feature, idx) => (
              <MenuItem
                onClick={() => handlePressMenuItem(feature.name)}
                key={`${feature.name}-${idx}`}
              >
                <div className="flex h-8 w-8 items-center justify-center overflow-hidden">
                  <Suspense
                    fallback={
                      <div className="h-8 w-8 animate-pulse rounded-full bg-slate-200" />
                    }
                  >
                    <feature.icon />
                  </Suspense>
                </div>
                <p className="text-center text-xs font-medium leading-[15px]  text-darkGray">
                  {feature.displayName}
                </p>
              </MenuItem>
            ))}
          </>
        )}
      </div>
    </motion.div>
  );
}

export default Menu;

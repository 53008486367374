import { RouterProvider } from "react-router-dom";
import HomePage from "@/pages/HomePage";
import NotFoundPage from "@/pages/NotFoundPage";
import IndexPage from "@/pages/IndexPage";
import Layout from "@/components/layouts/Layout";
import { RootErrorBoundary } from "@/components/Error/RootErrorBoundary";
import { RoutePath } from "./path";
import { PageErrorBoundary } from "@/components/Error/PageErrorBoundary";
import { sentryCreateBrowserRouter } from "../sentry.ts";
import BottomNavigatorLayout from "@/components/layouts/BottomNavigatorLayout.tsx";
import FullScreenLoading from "@/components/FullScreenLoading/index.tsx";
import LoginPage from "@/pages/LoginPage.tsx";
import { VerifyOTPPage } from "@/pages/VerifyOTPPage.tsx";
import CreatePinPage from "@/pages/CreatePinPage.tsx";
import VerifyPinPage from "@/pages/VerifyPinPage.tsx";
import AttendancePage from "@/pages/AttendancePage.tsx";
import { SettingPageWithScrollReset } from "@/pages/SettingPage";
import AgreementPage from "@/pages/AgreementPage";
import { InAppMessagePageWithScrollReset } from "@/pages/InAppMessagePage";
import SummaryPage from "@/pages/SummaryPage";
import { ServiceIneligibilityErrorPage } from "@/pages/ServiceIneligibilityErrorPage.tsx";
import { DataSharingPolicyPage } from "@/pages/DataSharingPolicyPage.tsx";

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <IndexPage /> },
      {
        path: "home", // RoutePath.Home
        Component: BottomNavigatorLayout,
        children: [
          {
            index: true,
            element: <HomePage />,
            errorElement: <RootErrorBoundary />,
          },
        ],
      },
      {
        path: "attendance", // RoutePath.Attendance
        element: <AttendancePage />,
        errorElement: <PageErrorBoundary />,
        // async lazy() {
        //   const { AttendancePage } = await import("@/pages/AttendancePage");
        //   return {
        //     element: <AttendancePage />,
        //     errorElement: <PageErrorBoundary />,
        //   };
        // },
      },
      {
        path: "transactions", // RoutePath.Transaction
        children: [
          {
            index: true,
            async lazy() {
              const { TransactionPage } = await import(
                "@/pages/TransactionPage"
              );
              return {
                element: <TransactionPage />,
                errorElement: <PageErrorBoundary />,
              };
            },
          },
          {
            path: ":transactionId",
            async lazy() {
              const { TransactionDetailPage } = await import(
                "@/pages/TransactionDetailPage"
              );
              return {
                element: <TransactionDetailPage />,
                errorElement: <PageErrorBoundary />,
              };
            },
          },
        ],
      },
      {
        path: "profile", // RoutePath.Profile
        async lazy() {
          const { ProfilePage } = await import("@/pages/ProfilePage");
          return {
            element: <ProfilePage />,
            errorElement: <PageErrorBoundary />,
          };
        },
      },

      {
        path: "setting", // RoutePath.Setting
        Component: BottomNavigatorLayout,
        children: [
          {
            index: true,
            element: <SettingPageWithScrollReset />,
            errorElement: <PageErrorBoundary />,
            // async lazy() {
            //   const { SettingPageWithScrollReset } = await import(
            //     "@/pages/SettingPage"
            //   );
            //   return {
            //     element: <SettingPageWithScrollReset />,
            //     errorElement: <PageErrorBoundary />,
            //   };
            // },
          },
        ],
      },
      {
        path: "support", // RoutePath.Support
        async lazy() {
          const { SupportPage } = await import("@/pages/SupportPage");
          return {
            element: <SupportPage />,
            errorElement: <PageErrorBoundary />,
          };
        },
      },
      {
        path: "faq", // RoutePath.FAQ
        async lazy() {
          const { FAQPage } = await import("@/pages/FAQPage");
          return {
            element: <FAQPage />,
            errorElement: <PageErrorBoundary />,
          };
        },
      },
      {
        path: "onboard", // RoutePath.Onboard
        async lazy() {
          const { OnboardPage } = await import("@/pages/OnboardPage");
          return {
            element: <OnboardPage />,
            errorElement: <PageErrorBoundary />,
          };
        },
      },
      {
        path: "agreement", // RoutePath.Agreement
        element: <AgreementPage />,
        errorElement: <PageErrorBoundary />,
        // async lazy() {
        //   const { AgreementPage } = await import("@/pages/AgreementPage");
        //   return {
        //     element: <AgreementPage />,
        //     errorElement: <PageErrorBoundary />,
        //   };
        // },
      },
      {
        path: "withdrawal", // RoutePath.Withdrawal
        async lazy() {
          const { WithdrawalPage } = await import("@/pages/WithdrawalPage");
          return {
            element: <WithdrawalPage />,
            errorElement: <PageErrorBoundary />,
          };
        },
      },
      {
        path: "top-up", // RoutePath.TopUp
        children: [
          {
            index: true,
            async lazy() {
              const { TopUpPage } = await import("@/pages/TopUpPage");
              return {
                element: <TopUpPage />,
                errorElement: <PageErrorBoundary />,
              };
            },
          },
          {
            path: ":orderId/confirmation",
            async lazy() {
              const { TopUpConfirmationPage } = await import(
                "@/pages/TopUpConfirmationPage"
              );
              return {
                element: <TopUpConfirmationPage />,
                errorElement: <PageErrorBoundary />,
              };
            },
          },
          {
            path: ":orderId/success",
            async lazy() {
              const { TopUpSuccessPage } = await import(
                "@/pages/TopUpSuccessPage"
              );
              return {
                element: <TopUpSuccessPage />,
                errorElement: <PageErrorBoundary />,
              };
            },
          },
          {
            path: ":orderId/failure",
            async lazy() {
              const { TopUpFailurePage } = await import(
                "@/pages/TopUpFailurePage"
              );
              return {
                element: <TopUpFailurePage />,
                errorElement: <PageErrorBoundary />,
              };
            },
          },
        ],
      },
      {
        path: "phone-card", // RoutePath.PhoneCard
        children: [
          {
            index: true,
            async lazy() {
              const { PhoneCardPage } = await import("@/pages/PhoneCardPage");
              return {
                element: <PhoneCardPage />,
                errorElement: <PageErrorBoundary />,
              };
            },
          },
          {
            path: ":orderId/confirmation",
            async lazy() {
              const { PhoneCardConfirmationPage } = await import(
                "@/pages/PhoneCardConfirmationPage"
              );
              return {
                element: <PhoneCardConfirmationPage />,
                errorElement: <PageErrorBoundary />,
              };
            },
          },
          {
            path: ":orderId/success",
            async lazy() {
              const { PhoneCardSuccessPage } = await import(
                "@/pages/PhoneCardSuccessPage"
              );
              return {
                element: <PhoneCardSuccessPage />,
                errorElement: <PageErrorBoundary />,
              };
            },
          },
          {
            path: ":orderId/failure",
            async lazy() {
              const { PhoneCardFailurePage } = await import(
                "@/pages/PhoneCardFailurePage"
              );
              return {
                element: <PhoneCardFailurePage />,
                errorElement: <PageErrorBoundary />,
              };
            },
          },
        ],
      },
      {
        path: "messages", // RoutePath.InAppMessage
        Component: BottomNavigatorLayout,
        children: [
          {
            index: true,
            element: <InAppMessagePageWithScrollReset />,
            errorElement: <PageErrorBoundary />,
            // async lazy() {
            //   const { InAppMessagePageWithScrollReset } = await import(
            //     "@/pages/InAppMessagePage"
            //   );
            //   return {
            //     element: <InAppMessagePageWithScrollReset />,
            //     errorElement: <PageErrorBoundary />,
            //   };
            // },
          },
          {
            path: ":messageId",
            async lazy() {
              const { InAppMessageDetailPageWithScrollReset } = await import(
                "@/pages/InAppMessageDetailPage"
              );
              return {
                element: <InAppMessageDetailPageWithScrollReset />,
                errorElement: <PageErrorBoundary />,
              };
            },
          },
        ],
      },
      {
        path: "payslip", //RoutePath.Payslip
        async lazy() {
          const { PayslipPage } = await import("@/pages/PayslipPage");
          return {
            element: <PayslipPage />,
            errorElement: <PageErrorBoundary />,
          };
        },
      },
      {
        path: "summary", // RoutePath.Summary
        element: <SummaryPage />,
        errorElement: <PageErrorBoundary />,
        // async lazy() {
        //   const { SummaryPage } = await import("@/pages/SummaryPage");
        //   return {
        //     element: <SummaryPage />,
        //     errorElement: <PageErrorBoundary />,
        //   };
        // },
      },
      {
        path: RoutePath.UpdatePhoneNumber.slice(1), // update-phone-number
        async lazy() {
          const { EditPhoneNumberPage } = await import(
            "@/pages/PhoneNumberPage.tsx"
          );
          return {
            element: <EditPhoneNumberPage />,
            errorElement: <PageErrorBoundary />,
          };
        },
      },
    ],
  },
  {
    path: RoutePath.Walkthrough,
    async lazy() {
      const { WalkthroughPage } = await import("@/pages/Walkthrough");
      return {
        element: <WalkthroughPage />,
        errorElement: <PageErrorBoundary />,
      };
    },
  },
  {
    path: RoutePath.Login,
    element: <LoginPage />,
    errorElement: <PageErrorBoundary />,
  },
  {
    path: RoutePath.VerifyOTP,
    element: <VerifyOTPPage />,
    errorElement: <PageErrorBoundary />,
    // async lazy() {
    //   const { VerifyOTPPage } = await import("@/pages/VerifyOTPPage");
    //   return {
    //     element: <VerifyOTPPage />,
    //     errorElement: <PageErrorBoundary />,
    //   };
    // },
  },
  {
    path: RoutePath.CreatePin, // RoutePath.CreatePin
    element: <CreatePinPage />,
    errorElement: <PageErrorBoundary />,
    // async lazy() {
    //   const { CreatePinPage } = await import("@/pages/CreatePinPage");
    //   return {
    //     element: <CreatePinPage />,
    //     errorElement: <PageErrorBoundary />,
    //   };
    // },
  },
  {
    path: RoutePath.VerifyPin, // RoutePath.VerifyPin
    element: <VerifyPinPage />,
    errorElement: <PageErrorBoundary />,
    // async lazy() {
    //   const { VerifyPinPage } = await import("@/pages/VerifyPinPage");
    //   return {
    //     element: <VerifyPinPage />,
    //     errorElement: <PageErrorBoundary />,
    //   };
    // },
  },
  {
    path: RoutePath.TermAndCondition,
    async lazy() {
      const { TermPage } = await import("@/pages/TermPage");
      return {
        element: <TermPage />,
        errorElement: <PageErrorBoundary />,
      };
    },
  },
  {
    path: RoutePath.ForgotPassword,
    async lazy() {
      const { ForgotPasswordPage } = await import("@/pages/ForgotPasswordPage");
      return {
        element: <ForgotPasswordPage />,
        errorElement: <PageErrorBoundary />,
      };
    },
  },
  {
    path: RoutePath.DataSharingPolicy,
    element: <DataSharingPolicyPage />,
    errorElement: <PageErrorBoundary />,
  },
  {
    path: RoutePath.DataSharingConsent,
    async lazy() {
      const { DataSharingConsentPage } = await import(
        "@/pages/DataSharingConsentPage.tsx"
      );
      return {
        element: <DataSharingConsentPage />,
        errorElement: <PageErrorBoundary />,
      };
    },
  },
  {
    path: RoutePath.ServiceIneligibilityError,
    element: <ServiceIneligibilityErrorPage />,
    errorElement: <PageErrorBoundary />,
  },
  { path: "*", element: <NotFoundPage /> },
]);

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

export default function Root() {
  return (
    <RouterProvider
      router={router}
      fallbackElement={<FullScreenLoading isOpen />}
    />
  );
}

import React from "react";
import ChevronRightIcon from "../icons/ChevronRightIcon";
import { twMerge } from "tailwind-merge";

interface Props<T extends React.ElementType> {
  as?: T;
  renderIcon?: () => React.ReactNode;
  text?: string;
}

function Row<T extends React.ElementType = "div">({
  as,
  renderIcon,
  text,
  className,
  ...rest
}: Props<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof Props<T>>) {
  const Comp = as || "div";

  return (
    <Comp className={twMerge("w-full group flex gap-3", className)} {...rest}>
      <div className="py-2 pl-2">
        <div className="w-10 h-10 justify-center flex items-center">
          {renderIcon ? (
            renderIcon()
          ) : (
            <img
              src="/images/default_avatar.png"
              className="w-10 h-10 rounded-full"
              alt=""
            />
          )}
        </div>
      </div>
      <div className="flex-1 flex flex-row justify-between items-center border-b border-b-[#E7EAEB] mr-2 group-last:border-b-0">
        <h3 className="font-medium text-[15px] leading-[21px] text-primaryBlack">
          {text ? text : "Nguyễn Văn A"}
        </h3>
        <ChevronRightIcon className="text-gray96" />
      </div>
    </Comp>
  );
}

export default Row;

/* eslint-disable @typescript-eslint/no-explicit-any */
import PageHeader from "@/components/PageHeader";
import PageContainer from "@/components/layouts/PageContainer";
import Row from "@/components/setting/Row";
import UserProfileIcon from "@/assets/icons/user-profile.svg?react";
import SupportIcon from "@/assets/icons/support.svg?react";
import QuestionIcon from "@/assets/icons/question.svg?react";
import BookIcon from "@/assets/icons/book.svg?react";
import { Link, useNavigate } from "react-router-dom";
import { RoutePath } from "@/router/path";
import { usePersonalInformation } from "@/hooks";
import { KeyIcon } from "@heroicons/react/24/outline";
import { useLazyQuery, useMutation } from "@apollo/client";
import { checkFlowChangePasswordQueryDocument } from "@/apollo/queries/password.gql";
import { useSetRecoilState } from "recoil";
import {
  defaultRestPasswordState,
  resetPasswordState,
} from "@/state/resetPassword";
import { requestChangePasswordWithoutSocialIdMutationDocument } from "@/apollo/mutations/password.gql";
import { useGlobalLoaderService } from "@/hooks/useGlobalLoaderService";
import { useToast } from "@/hooks/useToast";
import { graphqlErrorParser } from "@/helpers";
import { ANALYTIC_CATEGORIES, trackEvent } from "@/helpers/events";
import withScrollReset from "@/hocs/withScrollReset";

const firstBlock = [
  {
    id: 1,
    text: "Thông tin cá nhân",
    renderIcon: () => <UserProfileIcon className="text-primary" />,
    as: Link,
    to: RoutePath.Profile,
    onClick: () => {
      // Tracker
      trackEvent(ANALYTIC_CATEGORIES.EmployeeInfo, "press_employee_info", {
        slot: "Setting",
      });
    },
  },
];

const secondBlocks = [
  {
    id: 2,
    text: "Chat với Vui",
    renderIcon: () => <SupportIcon className="text-primary" />,
    as: Link,
    to: RoutePath.Support,
    onClick: () => {
      // Tracker
      trackEvent(ANALYTIC_CATEGORIES.HelpDesk, "press_help");
    },
  },
  {
    id: 3,
    text: "Hỏi đáp ngắn gọn",
    renderIcon: () => <QuestionIcon className="text-primary" />,
    as: Link,
    to: RoutePath.FAQ,
    onClick: () => {
      // Tracker
      trackEvent(ANALYTIC_CATEGORIES.Faq, "press_faq");
    },
  },
  {
    id: 4,
    text: "Điều khoản và dịch vụ",
    renderIcon: () => <BookIcon className="text-primary" />,
    as: Link,
    to: RoutePath.TermAndCondition,
    onClick: () => {
      // Tracker
      trackEvent(ANALYTIC_CATEGORIES.TermCondition, "view_toc");
    },
  },
];
function SettingPage() {
  const { showMessage } = useToast();
  const navigate = useNavigate();
  const { personalInformation } = usePersonalInformation();
  const { setGlobalLoading } = useGlobalLoaderService();

  const setState = useSetRecoilState(resetPasswordState);

  const [checkFlowChangePasswordFunc] = useLazyQuery(
    checkFlowChangePasswordQueryDocument
  );

  const [requestVerifyOTPChangePasswordFunc] = useMutation(
    requestChangePasswordWithoutSocialIdMutationDocument
  );

  const handleForgotPassword: React.MouseEventHandler<HTMLDivElement> = async (
    e
  ) => {
    e.preventDefault();

    if (!personalInformation?.phoneNumber) return;

    setGlobalLoading(true);

    try {
      const result = await checkFlowChangePasswordFunc({
        variables: {
          phoneNumber: personalInformation?.phoneNumber,
        },
      });
      if (result.data?.checkFlowChangePassword.idNumberFirst3Characters) {
        setState({
          ...defaultRestPasswordState,
          phoneNumber: personalInformation?.phoneNumber,
          firstThreeCharsOfSocialId:
            result.data?.checkFlowChangePassword.idNumberFirst3Characters,
          flow: result.data?.checkFlowChangePassword.flow,
          step: "idVerification",
        });
      } else {
        // request verify otp => no need to collect user identity
        const res = await requestVerifyOTPChangePasswordFunc({
          variables: {
            phoneNumber: personalInformation?.phoneNumber,
          },
        });

        setState({
          ...defaultRestPasswordState,
          phoneNumber: personalInformation?.phoneNumber,
          flow: result.data?.checkFlowChangePassword.flow,
          requestId: res.data?.requestChangePassword.requestId,
          step: "otpVerification",
        });
      }
      setGlobalLoading(false);
      navigate(RoutePath.ForgotPassword);
    } catch (error) {
      showMessage.error(graphqlErrorParser(error));
      setGlobalLoading(false);
      // TODO error handling
    }
  };

  return (
    <PageContainer className="bg-[#F2F2F2]">
      <PageHeader
        hideLeftButton
        wrapperClassName="bg-white"
        title="Cài đặt"
        hideRightButton
      />
      <div className="bg-white w-full p-4 flex flex-row gap-3 border-t">
        <img
          src="/images/default_avatar.png"
          className="w-10 h-10 rounded-full"
          alt=""
        />
        <div>
          <h3 className="font-medium text-[15px] leading-[21px] text-primaryBlack">
            {personalInformation?.currentEmployment?.fullName}
          </h3>
          <p className="font-medium text-[15px] leading-[21px] text-gray96">
            {personalInformation?.phoneNumber}
          </p>
        </div>
      </div>
      <div className="p-4 grid gap-4">
        <div role="list" className="p-0 bg-white rounded-xl  w-full">
          {firstBlock.map(({ id, ...item }) => (
            <Row key={`setting_item_${id}`} {...item} />
          ))}
        </div>
        <div role="list" className="p-0 bg-white rounded-xl  w-full">
          {secondBlocks.map(({ id, ...item }) => (
            <Row key={`setting_item_${id}`} {...item} />
          ))}
        </div>
        <div role="list" className="p-0 bg-white rounded-xl  w-full">
          <Row
            text="Đặt lại mật khẩu"
            renderIcon={() => <KeyIcon className="w-5 h-5 text-primary" />}
            as="div"
            role="button"
            tabIndex={-1}
            onClick={handleForgotPassword}
          />
        </div>
      </div>
    </PageContainer>
  );
}

const SettingPageWithScrollReset = withScrollReset(SettingPage);

export { SettingPageWithScrollReset };

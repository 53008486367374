import {
  LoanApplicationStatus,
  LoanApplicationType,
  MessagesQuery,
} from "@/apollo/__generated__/graphql";
import { ANALYTIC_CATEGORIES, trackEvent } from "@/helpers/events";
import { numberToMoney } from "@/utils/money";
import { hasHTMLElement } from "@/utils/string";
import {
  CheckIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { ClockIcon } from "@heroicons/react/24/solid";
import { format } from "date-fns";
import { vi } from "date-fns/locale";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type Props = {
  message: MessagesQuery["profile"]["messages"]["nodes"][number];
};

export default function Timeline({ message }: Props) {
  const navigate = useNavigate();
  const messages = useMemo(() => {
    return message?.group?.messages;
  }, [message]);

  const loanApplication = useMemo(() => {
    return message?.group?.loanApplication;
  }, [message]);

  const overrideTitleByMessage = useCallback(
    (message: MessagesQuery["profile"]["messages"]["nodes"][number]) => {
      const isRejected = /(u1F61E.png)+/g.test(message.content);
      const isDisbursed = /(u1F38A.png)+/g.test(message.content);
      const isApproved = /(u1F44C.0.png)+/g.test(message.content);
      const isTopUp =
        message.group?.loanApplication?.type === LoanApplicationType.TopUp;
      const isLock4Disburse =
        message.group?.loanApplication?.status ===
        LoanApplicationStatus.LockForDisbursement;

      if (isTopUp && isLock4Disburse) {
        return message.title;
      }
      if (isApproved) {
        return "Vui đã tiếp nhận yêu cầu";
      }

      if (isDisbursed) {
        return isTopUp
          ? message.content.replace(/(<([^>]+)>)/gi, "")
          : "Yêu cầu đã được duyệt";
      }

      if (isRejected) {
        return isTopUp
          ? message.content.replace(/(<([^>]+)>)/gi, "")
          : "Yêu cầu bị từ chối";
      }
    },
    []
  );

  const iconByMessage = useCallback(
    (message: MessagesQuery["profile"]["messages"]["nodes"][number]) => {
      const isRejected = /(u1F61E.png)+/g.test(message.content);
      const isDisbursed = /(u1F38A.png)+/g.test(message.content);
      const isApproved = /(u1F44C.0.png)+/g.test(message.content);

      if (isApproved) {
        return ClockIcon;
      }

      if (isDisbursed) {
        return CheckIcon;
      }

      if (isRejected) {
        return XCircleIcon;
      }
      return QuestionMarkCircleIcon;
    },
    []
  );

  const bgIconByMessage = useCallback(
    (message: MessagesQuery["profile"]["messages"]["nodes"][number]) => {
      const isRejected = /(u1F61E.png)+/g.test(message.content);
      const isDisbursed = /(u1F38A.png)+/g.test(message.content);
      const isApproved = /(u1F44C.0.png)+/g.test(message.content);

      if (isApproved) {
        return "bg-gray-400";
      }

      if (isDisbursed) {
        return "bg-green-400";
      }

      if (isRejected) {
        return "bg-red-400";
      }
      return "bg-gray-400";
    },
    []
  );

  const timeline = useMemo(() => {
    if (!messages) return [];
    return messages
      .map((message) => {
        return {
          id: message.id,
          title: overrideTitleByMessage(message),
          content: message.content,
          href: `/messages/${message.id}`,
          date: message.createdDate,
          datetime: message.createdDate,
          icon: iconByMessage(message),
          iconBackground: bgIconByMessage(message),
        };
      })
      .reverse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const handleClick = useCallback(() => {
    // Traker
    trackEvent(ANALYTIC_CATEGORIES.Message, "press_detail_message");
    if (message.id) {
      navigate(`/messages/${message.id}`);
    }
  }, [message.id, navigate]);

  return (
    <div className="flex items-center gap-x-4">
      <div
        role="button"
        onClick={handleClick}
        className="flow-root w-full rounded-xl bg-white p-4 shadow-main"
      >
        <h3 className="mb-4 font-primary font-bold">
          Yêu cầu nhận lương{" "}
          <span className="font-bold text-primary">
            {numberToMoney(+loanApplication?.amountValue || 0, false, "đ")}
          </span>
        </h3>
        <ul role="list" className="-mb-8">
          {timeline.map((event, eventIdx) => (
            <li key={`item_${event.id}_${eventIdx}`}>
              <div className="relative pb-8">
                {eventIdx !== timeline.length - 1 ? (
                  <span
                    className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={twMerge(
                        event.iconBackground,
                        "flex h-8 w-8 items-center justify-center rounded-full"
                      )}
                    >
                      <event.icon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4">
                    <div>
                      <div
                        className={twMerge(
                          "line-clamp-2 font-primary text-base leading-6 text-primaryBlack",
                          hasHTMLElement(event.title) ? undefined : "font-bold" // if title is HTML, don't make it bold
                        )}
                        dangerouslySetInnerHTML={{
                          __html: event.title,
                        }}
                      />
                      <div className="line-clamp-3 font-primary text-sm leading-5 text-darkGray">
                        {format(new Date(event.date), "HH:mm EEEE dd LLL", {
                          locale: vi,
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <time className="text-xs text-gray96">
          {format(new Date(message.createdDate), "HH:mm", {
            locale: vi,
          })}
        </time>
      </div>
    </div>
  );
}

import { personalInformationQueryDocument } from "../apollo/queries/employment.gql";
import { WatchQueryFetchPolicy, useQuery } from "@apollo/client";
import { useAppState } from "./useAppState";

export default function usePersonalInformation(
  fetchPolicy: WatchQueryFetchPolicy = "cache-first"
) {
  const {
    state: { accessToken },
  } = useAppState();

  const { data, refetch, loading } = useQuery(
    personalInformationQueryDocument,
    {
      skip: !accessToken,
      // notifyOnNetworkStatusChange: true,
      fetchPolicy,
      nextFetchPolicy: "cache-first",
    }
  );

  return {
    personalInformation: data?.profile,
    refetch,
    loading,
  };
}

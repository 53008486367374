import OTPInput from "@/components/OTPInput";
import PageHeader from "@/components/PageHeader";
import PageContainer from "@/components/layouts/PageContainer";
import { OTP_LENGTH } from "@/constants";
import { graphqlErrorParser } from "@/helpers";
import React, {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  requestDeviceTokenMutationDocument,
  requestLoginMutationDocument,
} from "@/apollo/mutations/login.gql";
import { useMutation } from "@apollo/client";
import { useAppState } from "@/hooks/useAppState";
import { RoutePath } from "@/router/path";
import { useCountdown } from "usehooks-ts";
import { twMerge } from "tailwind-merge";
import { ANALYTIC_CATEGORIES, trackEvent } from "@/helpers/events";
import { useGlobalLoaderService } from "@/hooks/useGlobalLoaderService";
import Modal from "@/components/Modal";
import { defaultAppState } from "@/state/user";

const Icon = lazy(() => import("@/components/icons/PhoneWithBubble"));

const isOTPValid = (otp: string) => {
  return /^[0-9]{6}$/.test(otp);
};

export const VerifyOTPPage: React.FC = () => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { state, setState } = useAppState();
  const { setGlobalLoading } = useGlobalLoaderService();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [confirmGoBack, setConfirmGoBack] = useState<boolean>(false);

  const createUserFlow = searchParams.get("isNew") === "1";
  const requestId = searchParams.get("requestId");
  const phoneNumber = searchParams.get("phoneNumber");

  const [requestDeviceTokenMutation] = useMutation(
    requestDeviceTokenMutationDocument
  );

  const [requestLoginMutation] = useMutation(requestLoginMutationDocument);

  const [otpValue, setOtpValue] = useState("");
  const [error, setError] = useState("");

  const [count, { startCountdown, resetCountdown }] = useCountdown({
    countStart: 60,
    countStop: 0,
    intervalMs: 1000,
  });

  const countdownText = useMemo(() => {
    const mins = Math.floor(count / 60);
    const secs = Math.floor(count % 60);

    return `${mins < 10 ? "0" + mins : mins}:${secs < 10 ? "0" + secs : secs}`;
  }, [count]);

  const handleSubmit = async (otpValue: string) => {
    try {
      if (!requestId) {
        throw new Error("Missing requestId");
      }

      if (!isOTPValid(otpValue)) {
        throw new Error("OTP không hợp lệ");
      }

      // tracker
      trackEvent(ANALYTIC_CATEGORIES.RegisterCredential, "submit_otp");

      setGlobalLoading(true);

      const result = await requestDeviceTokenMutation({
        variables: {
          payload: {
            requestId,
            otp: otpValue,
            otpLength: OTP_LENGTH,
          },
        },
      });

      if (result.data?.requestDeviceToken) {
        setState((prev) => ({
          ...prev,
          deviceToken: result.data?.requestDeviceToken.deviceToken,
        }));

        if (createUserFlow) {
          navigate(RoutePath.CreatePin);
        } else {
          navigate(RoutePath.VerifyPin);
        }
      }
    } catch (error) {
      setOtpValue(""); // clear otp value

      setGlobalLoading(false);
      setError(graphqlErrorParser(error));
    } finally {
      setGlobalLoading(false);
    }
  };

  const handleResendRequestLogin = async () => {
    try {
      if (count !== 0) {
        return;
      }

      if (!requestId) {
        throw new Error("Missing requestId");
      }
      // TODO tracker not defined

      const result = await requestLoginMutation({
        variables: {
          payload: {
            phoneNumber,
          },
        },
      });

      if (result.data?.requestLogin) {
        searchParams.set("requestId", result.data.requestLogin.token);

        // restart countdown
        resetCountdown();
        startCountdown();

        inputRef.current?.focus();

        // reset state
        setError("");
        setOtpValue("");
      }
    } catch (error) {
      setError(graphqlErrorParser(error));
    }
  };

  const handleChangePhoneNumber: React.MouseEventHandler<HTMLButtonElement> =
    useCallback((e) => {
      e.preventDefault();
      setConfirmGoBack(true);
    }, []);

  const handleConfirmChangePhoneNumber = useCallback(() => {
    setConfirmGoBack(false);
    setState((prevState) => ({
      ...defaultAppState,
      initialized: true,
      slug: prevState.slug,
    }));
    navigate(RoutePath.Login);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelChangePhoneNumber = useCallback(() => {
    setConfirmGoBack(false);
  }, []);

  useEffect(() => {
    startCountdown();
    return () => {
      resetCountdown();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (otpValue.length === OTP_LENGTH) {
      inputRef.current?.blur();
      handleSubmit(otpValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpValue]);

  return (
    <>
      <PageContainer className="h-viewport flex flex-col">
        <PageHeader
          title=""
          hideLeftButton
          rightElement={
            state.phoneNumber ? null : ( // hide change phone number button if user already has phone number
              <button onClick={handleChangePhoneNumber}>
                <strong className="text-primary text-sm px-1">
                  Đổi số điện thoại
                </strong>
              </button>
            )
          }
        />
        <div className="px-4 pt-4 grid gap-6">
          <Suspense fallback={<div className="w-16 h-16" />}>
            <Icon className="w-16 h-16 text-primary/10" />
          </Suspense>
          <div>
            <h3 className="text-2xl mb-2 font-bold text-primaryBlack">
              Nhập mã xác thực
            </h3>
            <p className="text-[15px] leading-[21px] text-darkGray">
              Mã xác thực {OTP_LENGTH} ký tự được gửi tới số{" "}
              <strong className="text-primary">{phoneNumber}</strong>
            </p>
          </div>
          <div className="flex flex-col items-start gap-2">
            <div tabIndex={-1} onClick={() => inputRef.current?.focus()}>
              <OTPInput length={OTP_LENGTH} value={otpValue} />
              <input
                className="sr-only"
                ref={inputRef}
                inputMode="tel"
                name="otp"
                value={otpValue}
                autoComplete="one-time-code"
                onChange={(e) =>
                  setOtpValue(e.target.value.slice(0, OTP_LENGTH))
                }
              />
            </div>
            {error ? (
              <p className="text-left text-[13px] leading-5  text-[#FF2638] lining-nums">
                {error}
              </p>
            ) : null}
            <div className="w-full py-4 text-center text-sm font-medium">
              {count ? (
                <strong className="text-gray96">
                  Gửi lại mã sau {countdownText} giây
                </strong>
              ) : (
                <>
                  <span className="text-gray96">Không nhận được mã?&nbsp;</span>
                  <strong
                    role="button"
                    onClick={handleResendRequestLogin}
                    className={twMerge(
                      "inline ",
                      count
                        ? "cursor-not-allowed text-darkGray"
                        : "cursor-pointer text-primary"
                    )}
                  >
                    Gửi lại
                  </strong>
                </>
              )}
            </div>
          </div>
        </div>
      </PageContainer>
      <Modal
        panelClassName="w-full"
        textClassName="w-full text-center"
        open={!!confirmGoBack}
        onClose={handleCancelChangePhoneNumber}
        onConfirm={handleConfirmChangePhoneNumber}
        onCancel={handleCancelChangePhoneNumber}
        confirmText="Thay đổi"
        cancelText="Ở lại "
        title={"Thay đổi số điện thoại"}
        descriptionWrapperClassName={"mt-2"}
        description={"Bạn chắc chắn muốn thay đổi số điện thoại chứ"}
      />
    </>
  );
};

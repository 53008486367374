import { gql } from "../__generated__";

export const checkFlowChangePasswordQueryDocument = gql(/* GraphQL */ `
  query checkFlowChangePassword($phoneNumber: PhoneNumber!) {
    checkFlowChangePassword(phoneNumber: $phoneNumber) {
      flow
      idNumberFirst3Characters
    }
  }
`);

import { motion } from "framer-motion";
import LogoWhite from "../../LogoWhite";
import WhiteContainerButton from "../../button/WhiteContainerButton";
import WhiteOutlinedButton from "../../button/WhiteOutlinedButton";
import { PatternBg } from "../PatternBg";
import VuiBalance from "./VuiBalance";

type Props = {
  withdrawableBalance?: number;
  logoUrl?: string;
  onClickWithdraw?: () => void;
  onClickDetail?: () => void;
};

function EWACard({
  withdrawableBalance = 0,
  logoUrl,
  onClickDetail,
  onClickWithdraw,
}: Props) {
  return (
    <motion.div
      layout
      className="relative flex w-full flex-col justify-between gap-2 p-4"
      style={{
        aspectRatio: 320 / 189,
      }}
    >
      <div className="z-[1] w-full">
        <div className="xs:mb-3 z-10 mb-1 flex w-full flex-row items-center justify-between">
          <LogoWhite width={26} height={16} />
          <div className="h-[22px]">
            {logoUrl ? (
              <img
                src={logoUrl}
                alt="logo"
                className="max-h-[22px] max-w-[100px] object-contain"
              />
            ) : (
              <div className="flex h-10 w-10 items-center justify-center rounded-full bg-black/10">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 3.33333C12.2975 3.33333 14.1667 5.20249 14.1667 7.49999C14.1667 9.51166 12.7325 11.1958 10.8333 11.5817V12.5C10.8333 12.9608 10.4608 13.3333 10 13.3333C9.53917 13.3333 9.16667 12.9608 9.16667 12.5V10.8333C9.16667 10.3725 9.53917 9.99999 10 9.99999C11.3783 9.99999 12.5 8.87833 12.5 7.49999C12.5 6.12166 11.3783 4.99999 10 4.99999C8.62167 4.99999 7.5 6.12166 7.5 7.49999C7.5 7.96083 7.1275 8.33333 6.66667 8.33333C6.20584 8.33333 5.83334 7.96083 5.83334 7.49999C5.83334 5.20249 7.7025 3.33333 10 3.33333ZM9.16667 15.8333C9.16667 15.3725 9.53917 15 10 15C10.4608 15 10.8333 15.3725 10.8333 15.8333C10.8333 16.2942 10.4608 16.6667 10 16.6667C9.53917 16.6667 9.16667 16.2942 9.16667 15.8333Z"
                    fill="white"
                  />
                  <mask
                    id="mask0_0_74"
                    maskUnits="userSpaceOnUse"
                    x="5"
                    y="3"
                    width="10"
                    height="14"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 3.33333C12.2975 3.33333 14.1667 5.20249 14.1667 7.49999C14.1667 9.51166 12.7325 11.1958 10.8333 11.5817V12.5C10.8333 12.9608 10.4608 13.3333 10 13.3333C9.53917 13.3333 9.16667 12.9608 9.16667 12.5V10.8333C9.16667 10.3725 9.53917 9.99999 10 9.99999C11.3783 9.99999 12.5 8.87833 12.5 7.49999C12.5 6.12166 11.3783 4.99999 10 4.99999C8.62167 4.99999 7.5 6.12166 7.5 7.49999C7.5 7.96083 7.1275 8.33333 6.66667 8.33333C6.20584 8.33333 5.83334 7.96083 5.83334 7.49999C5.83334 5.20249 7.7025 3.33333 10 3.33333ZM9.16667 15.8333C9.16667 15.3725 9.53917 15 10 15C10.4608 15 10.8333 15.3725 10.8333 15.8333C10.8333 16.2942 10.4608 16.6667 10 16.6667C9.53917 16.6667 9.16667 16.2942 9.16667 15.8333Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_0_74)">
                    <rect width="20" height="20" fill="white" />
                  </g>
                </svg>
              </div>
            )}
          </div>
        </div>
        <VuiBalance balance={withdrawableBalance} />
      </div>
      <div className="z-[1] flex gap-2">
        <WhiteOutlinedButton onClick={onClickDetail}>
          <p className="text-center text-base font-bold text-white">Chi tiết</p>
        </WhiteOutlinedButton>
        <WhiteContainerButton onClick={onClickWithdraw}>
          <p className="text-center text-base font-bold text-primary-dark">
            Rút lương
          </p>
        </WhiteContainerButton>
      </div>

      <div className="absolute inset-0 z-0 rounded-2xl bg-primary">
        <PatternBg />
      </div>
    </motion.div>
  );
}

export default EWACard;

import { Outlet } from "react-router-dom";
import BottonNavigator from "../BottomNavigator";

const BottomNavigatorLayout = () => {
  return (
    <>
      <div className="bg-white">
        <Outlet />
      </div>
      <BottonNavigator />
    </>
  );
};

export default BottomNavigatorLayout;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { hideSplashScreen } from "@/utils/splashScreen";
import { persistKey, storage } from "@/utils/storage";
import { AtomEffect, atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: persistKey,
  storage,
});

const themeEffect: AtomEffect<AppState> = ({ onSet }) => {
  onSet(({ defaultColor, lightColor, darkColor }) => {
    if (defaultColor) {
      document.documentElement.style.setProperty(
        "--color-primary",
        defaultColor
      );
    }
    if (lightColor) {
      document.documentElement.style.setProperty(
        "--color-primary-light",
        lightColor
      );
    }
    if (darkColor) {
      document.documentElement.style.setProperty(
        "--color-primary-dark",
        darkColor
      );
    }
  });
};

const automaticHideSplashScreen: AtomEffect<AppState> = ({ onSet }) => {
  onSet(({ initialized }) => {
    if (initialized) {
      hideSplashScreen();
    }
  });
};

export const appStateKey = "appState";

export type AppState = {
  initialized?: boolean;
  deviceToken?: string | null;
  accessToken?: string | null;
  refreshToken?: string | null;
  slug?: string | null;
  lightColor?: string | null;
  darkColor?: string | null;
  defaultColor?: string | null;
  phoneNumber?: string | null;
  employeeCode?: string | null;
  phoneNumberChange?: boolean | null;
  errorMessage?: string | null;
  onboardingFlow?: string | null;
};

export const defaultAppState: AppState = {
  initialized: false,
  slug: undefined,
  deviceToken: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  lightColor: undefined,
  darkColor: undefined,
  defaultColor: undefined,
  phoneNumber: undefined,
  employeeCode: undefined,
  phoneNumberChange: undefined,
  errorMessage: undefined,
  onboardingFlow: undefined,
};

export const appState = atom<AppState>({
  key: appStateKey,
  default: defaultAppState,
  effects: [persistAtom, themeEffect, automaticHideSplashScreen],
});

/* eslint-disable react-refresh/only-export-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay } from "swiper/modules";

interface Image {
  imageSrc: any;
  bgColor: React.CSSProperties["backgroundColor"];
}

type Props = {
  title?: string;
  sliders?: Image[];
};

export default function ImageSlider({ sliders = [] }: Props) {
  return (
    <div>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        centeredSlides={false}
        autoplay={{
          delay: 10000,
          disableOnInteraction: true,
        }}
        modules={[Autoplay]}
      >
        {sliders.map((item, idx) => {
          return (
            <SwiperSlide
              className="!w-full"
              key={`authenticated_slider_${idx}`}
            >
              <div className="flex items-center justify-center">
                <img
                  className="h-full w-full object-cover object-center"
                  src={item.imageSrc}
                  alt=""
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

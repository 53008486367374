import { useGlobalLoaderService } from "../../hooks/useGlobalLoaderService";
import FullScreenLoading from "../FullScreenLoading";

const GlobalLoader = () => {
  const { loading } = useGlobalLoaderService();

  return <FullScreenLoading isOpen={loading} />;
};

export default GlobalLoader;

export function hexToSpaceSeparated(hex: string) {
  // Remove the hash if it's there
  hex = hex.replace("#", "");

  // Convert to RGB values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Return as space-separated string
  return `${r} ${g} ${b}`;
}

export function spaceSeparatedToHex(rgb: string) {
  // Split the string into an array of strings
  const [r, g, b] = rgb.split(" ");

  // Convert each string to a number and then to a hex string
  const rHex = parseInt(r).toString(16).padStart(2, "0");
  const gHex = parseInt(g).toString(16).padStart(2, "0");
  const bHex = parseInt(b).toString(16).padStart(2, "0");

  // Combine the hex strings and return the result
  return `#${rHex}${gHex}${bHex}`;
}

export function parseRGB(rgbString: string) {
  const match = rgbString.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
  if (match) {
    return `${match[1]} ${match[2]} ${match[3]}`;
  } else {
    throw new Error("Invalid RGB string");
  }
}

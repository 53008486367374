import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

type Props = PropsWithChildren<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>;

const WhiteContainerButton = ({ children, className, ...props }: Props) => {
  return (
    <button
      className={twMerge(
        "group xs:h-[42px] flex h-9 w-full items-center justify-center rounded-xl bg-white shadow-normal",
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default WhiteContainerButton;

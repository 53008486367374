import { HOTLINE } from "@/constants";

const withCSBubble = <P extends object>(Component: React.ComponentType<P>) => {
  return (props: P) => {
    return (
      <>
        <Component {...props} />
        <div className="fixed z-50 right-4 bottom-24">
          <a href={`tel:${HOTLINE}`}>
            <img
              src="/images/call_center.webp"
              className="object-contain object-center h-16 drop-shadow-lg"
              alt="Call Center"
            />
          </a>
        </div>
      </>
    );
  };
};

export default withCSBubble;

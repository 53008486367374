import { gql } from "../__generated__";

export const getCompanyBySlug = gql(/* GraphQL */ `
  query getCompanyBySlug($slug: String!) {
    companyBySlug(slug: $slug) {
      authRealm
      id
      name
      code
      slug
      shortName
      logo {
        id
        uri
      }
      onboardCompany {
        id
        workflow
        displayName
        employeeIdPlaceHolder
      }
    }
  }
`);

export const getLinkableCompany = gql(/* GraphQL */ `
  query getLinkableCompany {
    tryMatchPhoneWithLinkableCompany {
      authRealm
      id
      name
      code
      slug
      shortName
      logo {
        id
        uri
      }
      onboardCompany {
        id
        workflow
        displayName
        employeeIdPlaceHolder
      }
    }
  }
`);

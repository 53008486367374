import Lottie from "lottie-react";
import animationData from "@assets/lottie/loading.json";

interface Props {
  dots?: number;
  size?: number;
  colors?: string[];
  borderRadius?: number;
}

const VuiLoading = ({ size = 64, borderRadius = 24 }: Props) => {
  return (
    <Lottie
      animationData={animationData}
      style={{
        width: size,
        height: size,
        borderRadius,
      }}
      loop
      autoPlay
      rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
    />
  );
};

export default VuiLoading;

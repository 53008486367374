import { ANALYTIC_CATEGORIES, trackEvent } from "@/helpers/events";
import { RoutePath } from "@/router/path";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

type Props = {
  name?: string | null;
};

function HomeHeader({ name = null }: Props) {
  const navigate = useNavigate();

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    // Traker
    trackEvent(ANALYTIC_CATEGORIES.EmployeeInfo, "press_employee_info", {
      slot: "Home",
    });
    navigate(RoutePath.Profile);
  };

  return (
    <motion.div layout className="flex w-full items-start justify-between pt-3">
      <button type="button" onClick={handleClick}>
        <p className="text-left text-[14px] font-bold leading-[18px] text-primary">
          Xin chào
        </p>
        {name ? (
          <h3 className="text-lg font-bold leading-6 lining-nums">{name}</h3>
        ) : (
          <div className="flex h-6 flex-col justify-end">
            <div className="h-[18px] w-48 rounded-md bg-gray-200 "></div>
          </div>
        )}
      </button>
    </motion.div>
  );
}

export default HomeHeader;

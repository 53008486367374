import { PropsWithChildren, memo, useMemo } from "react";
import { twMerge } from "tailwind-merge";

interface IComponentProps extends PropsWithChildren {}

export default memo(
  function Dot({ children }: IComponentProps) {
    const hasChild = useMemo(() => {
      return !!children;
    }, [children]);

    return (
      <div
        className={twMerge(
          "flex h-4 w-4 items-center justify-center rounded-lg border-2 text-xl font-bold",
          hasChild
            ? "border-primary/20 bg-primary"
            : "border-[#E5E7EB] bg-white ",
        )}
      />
    );
  },
  (oldVal, newVal) => oldVal.children === newVal.children,
);

/* eslint-disable @typescript-eslint/no-explicit-any */
import { lazy } from "react";
const AttendanceIcon = lazy(
  () => import("@/assets/icons/attendance.svg?react")
);
const PayslipIcon = lazy(() => import("@/assets/icons/payslip.svg?react"));
const TransactionHistoryIcon = lazy(
  () => import("@/assets/icons/transaction-history.svg?react")
);
const TopUpIcon = lazy(() => import("@/assets/icons/top-up.svg?react"));
const PhoneCardIcon = lazy(() => import("@/assets/icons/phone-card.svg?react"));
const CogIcon = lazy(() => import("@/assets/icons/setting.svg?react"));
export type Feature = {
  displayName: string;
  name:
    | "top_up"
    | "attendance"
    | "payslip"
    | "transaction_history"
    | "phone_card"
    | "setting";
  icon: React.ComponentType<any>;
};

export const defaultFeatures: Feature[] = [
  {
    displayName: "Chi tiết bảng công",
    name: "attendance",
    icon: AttendanceIcon,
  },
  {
    displayName: "Phiếu lương tạm tính",
    name: "payslip",
    icon: PayslipIcon,
  },
  {
    displayName: "Lịch sử \ngiao dịch",
    name: "transaction_history",
    icon: TransactionHistoryIcon,
  },
];

export const vasFeatures: Feature[] = [
  {
    displayName: "Nạp điện thoại",
    icon: TopUpIcon,
    name: "top_up",
  },
  {
    displayName: "Mua thẻ",
    icon: PhoneCardIcon,
    name: "phone_card",
  },
];

export const otherFeatures: Feature[] = [
  {
    displayName: "Cài đặt",
    icon: CogIcon,
    name: "setting",
  },
];

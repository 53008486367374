/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AttendanceStatus,
  AttendanceType,
} from "../../../apollo/__generated__/graphql";
import { twMerge } from "tailwind-merge";

type Props = {
  item: {
    id?: string | undefined;
    createdDate?: string;
    lastModifiedDate?: string;
    status?: AttendanceStatus | undefined;
    date: string;
    checkinSecond?: number | null | undefined;
    checkoutSecond?: number | null | undefined;
    dateObj: Date;
    isSamePeriod?: boolean;
    dateInNumber?: string;
    type?: AttendanceType;
    shiftCount?: number;
  };
  onSelectDate?: (item: any) => void;
};

export default function Day({ item, onSelectDate }: Props) {
  const handleSelectDate = (item: any) => {
    if (item.type === AttendanceType.RecordMissing) return;
    if (item.type === AttendanceType.Standard && !item.shiftCount) return;
    if (item.type === AttendanceType.Standard && item.shiftCount) {
      onSelectDate && onSelectDate(item);
    }
  };
  return (
    <button
      onClick={() => handleSelectDate(item)}
      className={twMerge(
        "aspect-h-1 aspect-w-1 w-full  rounded-xl",
        item.isSamePeriod
          ? "bg-transparent text-[#4D4D4D]"
          : "bg-transparent text-[#B9BFCA]",
        item.type === AttendanceType.Standard &&
          item?.shiftCount &&
          item.shiftCount > 0 &&
          "bg-primary text-white"
      )}
    >
      <div className="flex h-full w-full items-center justify-center rounded-xl">
        <span className={twMerge("text-base font-medium lining-nums")}>
          {item.dateInNumber}
        </span>
      </div>
    </button>
  );
}

import { SVGProps } from "react";

export const PatternBg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 375 255"
      fill="transparent"
      fillOpacity={1}
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      {...props}
    >
      <rect x={0} y={0} width="100%" height="100%" rx={16} />

      <g opacity={0.32}>
        <g opacity={0.7}>
          <path
            opacity={0.7}
            d="M-131.666 565.2v-.3c15.4-4.6 30.3-15.4 43-31.2 11.2-13.9 20.6-31.4 26.4-49.3 6-18.4 9.1-37.2 12.2-55.4 2.6-15.6 5.4-31.8 9.8-47.6 25.4-89.7 113.3-170.7 200.3-184.4 4.1-.6 8.2-1.2 12.3-1.7 15.8-2 32.2-4.1 47-13.2 13.6-8.3 24.7-21.8 31.9-38.9 6.4-15.2 9.6-32.5 8.9-48.8-.7-16.8-4.5-32.9-8.1-48.4-3.1-13.1-6.3-26.7-7.6-40.8-2.1-22.4.7-47 8.1-71.2 7.4-24.2 19.4-48 34.8-68.7 15.3-20.8 34.8-39.6 56.3-54.6 21.5-14.9 45-25.9 68-31.7v.3c-22.9 5.8-46.4 16.7-67.8 31.6-21.4 14.9-40.9 33.7-56.2 54.4-15.3 20.7-27.3 44.5-34.7 68.6-7.4 24.1-10.2 48.7-8 71.1 1.3 14 4.5 27.6 7.6 40.8 3.6 15.5 7.4 31.6 8.1 48.5.7 16.3-2.5 33.7-8.9 49-7.3 17.2-18.4 30.8-32.1 39.1-14.9 9.1-31.3 11.2-47.2 13.3-4 .5-8.2 1.1-12.3 1.7-86.8 13.7-174.6 94.6-199.9 184.1-4.5 15.8-7.2 32-9.8 47.6-3.1 18.2-6.3 37.1-12.2 55.5-5.8 18-15.2 35.6-26.5 49.5-13 15.6-27.9 26.5-43.4 31.1Z"
            fill="#FEFCFF"
          />
          <path
            opacity={0.7}
            d="m-133.666 564-.1-.5c13.9-5.2 27.8-16.4 40.3-32.5 10.8-13.9 20.4-31.4 27-49.1.8-2.2 1.6-4.4 2.4-6.7 5.2-15 8.6-29.8 12-44.2 3.5-15.1 7.2-30.8 12.8-46.1 8.5-23.3 22.7-46.3 35.6-65.7 16.3-24.6 30.8-43.2 45.6-58.5 22.5-23.2 48.1-41.9 74.1-53.9 11.6-5.4 22.6-9.3 33.7-12.1 6-1.5 12.2-2.6 18.8-3.8 5.9-1.1 12-2.2 18-3.6 9.4-2.2 16.9-5 23.7-8.6 13.6-7.4 24.6-18.7 32.7-33.6 7.5-13.8 12-29.9 12.6-45.4.6-15-1.5-29.4-3.5-43.3-1.8-12.2-3.6-24.9-3.7-38.1 0-20.3 4.5-42.6 13-64.7 9.1-23.4 22.8-46.6 39.7-67.1 16.5-20 36.9-38.6 59.2-53.7 22.2-15 46.2-26.7 69.5-33.8v.5c-23.3 7-47.2 18.7-69.4 33.7-22.2 15-42.6 33.6-59 53.5-16.8 20.4-30.5 43.6-39.5 66.9-8.6 22-13 44.3-13 64.5 0 13.2 1.9 25.8 3.7 38 2 13.9 4.1 28.3 3.5 43.4-.7 15.5-5.2 31.7-12.7 45.6-8 14.8-19.4 26.6-33 33.9-6.8 3.7-14.4 6.4-23.8 8.7-6 1.4-12.1 2.5-18 3.6-6.6 1.2-12.7 2.3-18.8 3.8-11.1 2.8-22 6.7-33.6 12.1-25.9 12-51.4 30.6-73.9 53.8-14.8 15.3-29.3 33.9-45.5 58.4-12.8 19.4-27.1 42.3-35.5 65.6-5.6 15.3-9.2 30.9-12.8 46-3.4 14.4-6.8 29.3-12 44.3-.8 2.3-1.6 4.6-2.4 6.7-6.7 17.8-16.3 35.3-27.1 49.3-12.5 16.2-26.6 27.5-40.6 32.7Z"
            fill="#FEFCFF"
          />
          <path
            opacity={0.7}
            d="m-135.566 562.9-.1-.6c27.6-12.6 51.8-51 65.3-82.7.9-2.1 1.8-4.3 2.7-6.5 5.5-13.6 9.6-26.8 13.5-39.5 4.8-15.5 9.3-30.1 16.1-45 7.3-15.9 18.2-32 29.7-49.1 3.2-4.7 6.5-9.6 9.7-14.5 2.6-4 5.2-8 7.8-11.9 11.5-17.6 22.4-34.3 34.3-48.8 19.6-23.9 42.2-43.7 65.3-57.3 11.1-6.6 20.6-11.1 30.7-14.6 8.2-2.8 17.5-4.8 26.6-6.8 3.5-.8 7.2-1.6 10.8-2.4 10-2.3 17.5-4.8 24.3-8 13.8-6.6 25.3-16.6 34.1-29.8 8.1-12.1 13.6-26.5 15.7-40.4 1.9-13.3 1.5-26 1-38.4-.4-11.2-.9-22.9.5-35.1 2-18.7 8.4-39.4 18.6-59.9 10.8-22 26.1-44 44-63.7 35.9-39.3 84.5-71.6 133.1-88.6l.1.6c-48.5 17-96.9 49.2-132.7 88.4-17.9 19.6-33.1 41.5-43.9 63.5-10.1 20.4-16.5 41.1-18.5 59.7-1.3 12.2-.9 23.8-.5 35 .5 12.4.9 25.2-1 38.5-2.1 14.1-7.7 28.6-15.9 40.8-8.9 13.3-20.5 23.5-34.4 30.1-6.8 3.2-14.4 5.7-24.4 8.1l-10.8 2.4c-9 1.9-18.4 3.9-26.5 6.8-10.1 3.5-19.5 8-30.6 14.5-23 13.5-45.5 33.3-65 57.1-11.9 14.5-22.8 31.2-34.3 48.8-2.6 3.9-5.2 7.9-7.8 11.9-3.2 4.9-6.5 9.8-9.7 14.5-11.5 17.1-22.4 33.2-29.7 49-6.8 14.9-11.3 29.5-16.1 44.9-3.9 12.7-8 25.9-13.5 39.6-.9 2.2-1.8 4.4-2.7 6.5-13.6 31.6-38 70.1-65.8 82.9Z"
            fill="#FEFCFF"
          />
          <path
            opacity={0.7}
            d="m-137.466 561.7-.2-.7c29.3-16.5 55.9-68.3 63.3-83.8l3-6.3c5.6-12 10-23.2 14.3-34.1 6.1-15.4 11.8-30 20.1-44.6 6-10.5 14-21.3 22.6-32.7 6.8-9.1 13.9-18.6 20.7-28.8 7.2-11 13.6-21.8 19.7-32.2 6.4-10.9 12.5-21.2 18.9-30.6 16.6-24.3 36.2-45.3 56.5-60.6 7.6-5.8 16.1-11.8 27.8-17.1 10.4-4.6 23.1-7.6 35.5-10.4l2.5-.6c8.1-1.9 16.8-4 24.9-7.4 14.1-5.8 26-14.6 35.5-26.1 8.6-10.5 15.3-23.1 18.8-35.5 3.3-11.7 4.3-22.5 5.4-34 1-10.1 2-20.5 4.6-31.7 4.1-17.1 12.4-36.2 24.1-55.2 12.6-20.6 29.3-41.4 48.4-60.2 38-37.4 88.1-70.2 137.5-89.8l.1.8c-49.3 19.6-99.3 52.3-137.1 89.6-19 18.7-35.6 39.5-48.2 60-11.6 18.9-19.9 37.9-24 54.9-2.6 11.1-3.6 21.5-4.6 31.6-1.1 11.5-2.1 22.4-5.4 34.1-3.5 12.6-10.3 25.3-19 35.9-9.6 11.6-21.7 20.5-35.9 26.4-8.2 3.4-16.9 5.6-25.1 7.5l-2.5.6c-12.3 2.8-25 5.7-35.3 10.3-11.7 5.2-20 11.2-27.6 17-20.2 15.2-39.6 36.1-56.2 60.3-6.4 9.4-12.5 19.7-18.9 30.6-6.1 10.4-12.5 21.3-19.8 32.3-6.8 10.3-13.8 19.7-20.7 28.9-8.5 11.4-16.6 22.1-22.5 32.6-8.3 14.6-14 29.1-20 44.5-4.3 10.9-8.7 22.2-14.3 34.2l-3 6.3c-7.6 15.2-34.3 67.3-63.9 84Z"
            fill="#FEFCFF"
          />
          <path
            opacity={0.7}
            d="m-139.366 560.4-.4-.8c16.6-11.7 37.3-40.2 61.3-84.9l3.3-6.1c5.2-9.7 9.6-18.7 13.9-27.4 7.7-15.8 15.1-30.8 25.4-45.5 5.2-7.4 11.7-15 18.7-23.1 9.1-10.6 19.4-22.6 28.4-36.2 9.9-15 17.1-29.6 24.2-43.7 3.7-7.5 7.3-14.6 11-21.3 13.8-24.8 30.2-46.9 47.6-63.9 6.4-6.2 13.6-13.3 24.9-19.6 10.7-6 24.9-9.6 38.5-12.7 8.5-1.9 17.2-3.9 25.6-6.8 14.4-5.1 26.8-12.6 36.9-22.3 9.1-8.8 16.9-19.7 21.9-30.6 4.6-10.2 7.2-19.8 9.9-29.9 2.4-8.8 4.8-18 8.7-27.9 6.2-15.5 16.5-33 29.6-50.4 14.4-19.2 32.6-38.8 52.7-56.8 39.8-35.5 91.5-68.7 141.8-91l.2.9c-50.2 22.3-101.8 55.4-141.5 90.8-20 17.9-38.2 37.4-52.5 56.6-13.1 17.3-23.3 34.7-29.5 50.1-3.9 9.9-6.4 19-8.7 27.8-2.7 10.2-5.3 19.8-10 30.1-5 11.1-12.9 22.1-22.1 31-10.2 9.9-22.8 17.5-37.4 22.6-8.4 3-17.2 4.9-25.7 6.8-13.6 3.1-27.6 6.7-38.3 12.6-11.2 6.2-18.4 13.2-24.7 19.4-17.3 17-33.7 39-47.4 63.6-3.7 6.6-7.2 13.7-10.9 21.2-7 14.1-14.3 28.8-24.3 43.9-9 13.7-19.4 25.7-28.5 36.4-6.9 8-13.4 15.7-18.6 23-10.2 14.6-17.5 29.5-25.3 45.3-4.3 8.7-8.7 17.7-13.9 27.5l-3.3 6.1c-24 44.7-44.7 73.5-61.5 85.2Z"
            fill="#FEFCFF"
          />
          <path
            opacity={0.7}
            d="m-141.266 559.2-.6-.8c16.8-14.8 38.1-50.4 55.2-79.1l4.2-7 3.6-6c3.9-6.3 7.4-12.2 10.7-17.9 10.8-18.2 20.1-33.9 33.4-49.2 4.6-5.3 10-10.7 15.8-16.5 11.4-11.5 24.4-24.4 35.1-40.7 11.7-17.9 19-36 25.4-51.9 2.1-5.3 4.2-10.4 6.3-15.2 10.9-25 24.3-48.3 38.8-67.2l.4-.5c5.5-7.2 11.2-14.7 21.7-21.7 8.7-5.9 21.5-10.6 39.2-14.5l5.4-1.2c7.3-1.6 14.1-3.1 20.9-5 14.8-4.4 27.7-10.6 38.4-18.6 12.2-9.1 20.3-19 24.9-25.7 6.2-9 10.5-17.6 14.9-26.7 3.6-7.3 7.3-14.9 12.3-23.2 8.4-14 20.6-29.7 35.1-45.6 16.3-17.8 36-36.2 57.1-53.3 41.3-33.3 94.6-66.9 146.2-92.2l.3 1c-51.6 25.2-104.7 58.7-145.9 92-21 17-40.7 35.4-56.9 53.1-14.5 15.8-26.6 31.4-35 45.3-5 8.3-8.7 15.8-12.3 23.1-4.5 9.2-8.7 17.8-15 26.9-4.7 6.8-12.9 16.8-25.3 26.1-11 8.3-24 14.6-39 19-6.8 2-13.6 3.5-20.9 5.1l-5.4 1.2c-17.5 3.9-30.1 8.5-38.7 14.3-10.3 6.9-15.9 14.2-21.3 21.3l-.4.5c-14.4 18.8-27.8 42-38.6 66.9-2.1 4.8-4.1 9.8-6.2 15.2-6.4 16-13.7 34.2-25.5 52.2-10.7 16.4-23.8 29.4-35.3 40.9-5.7 5.8-11.2 11.2-15.7 16.4-13.1 15.2-22.4 30.9-33.2 49-3.4 5.7-6.9 11.6-10.7 17.9-1.2 1.9-2.4 3.9-3.6 5.9l-4.2 7c-17.3 28.7-38.7 64.4-55.6 79.4Z"
            fill="#FEFCFF"
          />
          <path
            opacity={0.7}
            d="M-143.966 557.2c9.8-11.2 22.7-31.9 36.2-53.8 7.1-11.4 14.4-23.3 21.2-33.5 1.3-2 2.6-3.9 3.9-5.8l.1-.2c18.2-26.3 30.3-43.7 48.9-61.2 3.9-3.7 8.4-7.5 13.1-11.5 13.8-11.7 29.4-25 41.5-43.5 13.2-20.2 19.4-40.6 24.8-58.5 1.1-3.7 2.2-7.2 3.3-10.7 8.1-25.1 18.4-49.5 30-70.5l.6-1c4.2-7.8 8.6-15.8 18.6-23.7 11-8.7 27.7-13.6 39.8-16.3 3.9-.8 7.7-1.6 11.4-2.3 5.4-1.1 10.5-2.1 15.5-3.3 15.7-3.7 28.7-8.6 39.9-14.9 10.2-5.7 19.6-12.7 28-20.9 8.6-8.3 15-16.5 21.9-25.1 4.2-5.4 8.6-10.9 13.9-17 10.8-12.5 24.9-26.6 40.6-40.8 18.6-16.8 39.2-33.5 61.4-49.8 42.5-31.1 95.9-64.3 150.6-93.3l.3 1.1c-54.6 29-108 62.1-150.4 93.2-22.1 16.3-42.7 33-61.2 49.7-15.7 14.1-29.7 28.2-40.5 40.6-5.3 6.1-9.6 11.6-13.9 17-6.9 8.7-13.4 16.9-22 25.3-8.6 8.3-18.1 15.4-28.4 21.2-11.3 6.3-24.4 11.2-40.2 15-5.1 1.2-10.1 2.2-15.5 3.3-3.7.7-7.5 1.5-11.4 2.3-11.9 2.6-28.4 7.5-39.2 16-9.7 7.7-14 15.5-18.2 23.2l-.6 1c-11.5 20.9-21.8 45.2-29.8 70.2-1.1 3.4-2.2 6.9-3.3 10.6-5.5 18.1-11.7 38.6-25 59-12.3 18.8-28 32.1-41.8 43.9-4.7 4-9.2 7.8-13.1 11.5-18.5 17.3-30.5 34.7-48.6 60.9l-.1.2-3.9 5.7c-6.8 10.2-14.1 22.1-21.2 33.5-13.6 21.9-26.5 42.7-36.4 54l-.8-.8Z"
            fill="#FEFCFF"
          />
          <path
            opacity={0.7}
            d="M-146.065 556c4.3-6.5 9.8-15.9 16.3-26.7 11.5-19.4 25.8-43.4 39.3-61.9 1.4-1.9 2.8-3.8 4.2-5.6 20.5-26.3 35-41.3 53.8-55.5 3.2-2.4 6.7-4.9 10.3-7.5 15.4-11 34.5-24.6 48.1-45.4 14.5-22.2 19.1-44.5 23.2-64.3.5-2.4 1-4.7 1.5-7 5.4-25 12.5-49.8 21.1-73.7l.4-1.2c3-8.5 6.2-17.2 15.8-26.1 11.6-10.7 30.3-15.9 40.4-18.1 6-1.3 11.9-2.3 17.6-3.3 3.4-.6 6.7-1.1 9.9-1.7 16.8-3.1 30.3-6.8 41.4-11.1 10.5-4.2 20.7-9.4 31.2-16.1 13.5-8.6 23.6-17 36.4-27.7 2.5-2.1 5.2-4.3 8-6.6 14.3-11.8 29.8-23.9 46.1-35.9 21.2-15.8 43.3-31.4 65.7-46.3 40.7-27.1 91.3-58 154.9-94.5l.4 1.2c-63.5 36.5-114.1 67.4-154.8 94.4-22.4 14.9-44.5 30.5-65.6 46.3-16.2 12-31.7 24.1-46 35.9-2.8 2.3-5.4 4.5-8 6.6-12.8 10.7-23 19.1-36.6 27.8-10.5 6.7-20.8 12-31.5 16.2-11.2 4.4-24.8 8.1-41.6 11.2-3.3.6-6.6 1.2-9.9 1.7-5.7 1-11.6 2-17.6 3.2-9.9 2.1-28.3 7.2-39.6 17.6-9.3 8.6-12.4 17.1-15.3 25.3l-.4 1.2c-8.6 23.9-15.7 48.6-21.1 73.5-.5 2.3-1 4.6-1.5 7-4.1 19.9-8.8 42.4-23.4 64.9-13.8 21.1-33.1 34.8-48.6 45.9-3.7 2.6-7.1 5.1-10.3 7.5-18.6 14.1-33.1 29.1-53.5 55.2-1.4 1.7-2.8 3.6-4.2 5.5-13.4 18.3-27.7 42.4-39.2 61.7-6.4 10.8-12 20.2-16.3 26.8l-1-.4Z"
            fill="#FEFCFF"
          />
          <path
            opacity={0.7}
            d="M-148.166 554.9c9.4-20.4 31.4-62.2 53.7-89.9 1.5-1.9 3-3.7 4.5-5.4 22-25.3 38.8-37.8 58.7-49.7 2.2-1.3 4.6-2.7 7-4.1 17.9-10.5 40.1-23.5 55.1-46.5 15.6-24 18.4-49.2 20.7-69.5l.4-3.8c2.8-24 6.7-48.5 12.3-77l.2-1.1c1.7-8.8 3.7-18.8 13.1-28.8 8.4-8.9 23.4-16.2 41-19.9 8.3-1.8 16.4-2.8 24.1-3.9l4.1-.5c17.3-2.3 31.1-4.5 42.9-7.4 14-3.5 25.7-7.8 34.3-11.2 17.5-6.9 31.9-14.1 52.9-26.5 14.1-8.2 30.8-18.4 50.1-30.3l15.3-9.4c16.9-10.4 36.1-22.2 56.1-34.4 19.3-11.7 39.2-23.5 60.3-36.1 32.9-19.6 67-39.9 98.9-59.6l.5 1.3c-31.9 19.7-66 40-98.9 59.6-21.1 12.6-41 24.4-60.3 36.1-20 12.1-39.2 23.9-56.1 34.3l-15.3 9.4c-19.4 11.8-36.1 22-50.2 30.3-21.1 12.4-35.6 19.6-53.2 26.6-8.7 3.4-20.4 7.8-34.5 11.3-11.8 2.9-25.7 5.1-43 7.4l-4.1.5c-7.7 1-15.7 2.1-24 3.8-17.3 3.6-31.9 10.7-40 19.3-9 9.6-10.8 18.8-12.6 27.8l-.2 1.1c-5.6 28.5-9.5 52.9-12.3 76.9l-.4 3.8c-2.3 20.5-5.1 46-21 70.3-15.3 23.5-37.8 36.7-55.8 47.2-2.4 1.4-4.7 2.8-7 4.1-19.8 11.9-36.4 24.3-58.2 49.3-1.5 1.7-3 3.5-4.5 5.3-22.1 27.5-44 69.1-53.4 89.4l-1.2-.1Z"
            fill="#FEFCFF"
          />
          <path
            opacity={0.7}
            d="M-150.266 553.7c6-20.4 27.3-63.5 51.7-91.2 1.6-1.8 3.2-3.6 4.8-5.2 23.4-24 43.5-34.5 63.6-43.9l2.8-1.3c19.6-9.1 46.4-21.6 63-47.2 16.7-25.7 17.2-52.7 17.6-74.4v-1c.5-26.1 1.9-50.4 3-71.8l.5-9.3c.5-9.6 1.1-20.4 10.3-31.6 8.2-9.9 23.8-18 41.7-21.7 9.9-2 19.3-3 28.9-3.8 7-.6 13.4-1.1 19.7-1.5 8.8-.6 17.2-1.2 24.7-2.1 14.1-1.6 26.8-4.1 37.4-6.4 19.6-4.1 37.6-8.9 61.5-18.6 15.6-6.3 34.2-14.9 57-26.3 20.6-10.3 44.9-23.2 74.3-39.4 65.7-36 119.2-67.7 163.5-96.8l.7 1.4c-44.4 29.1-97.9 60.8-163.7 96.9-29.4 16.2-53.8 29.1-74.4 39.4-22.8 11.4-41.5 20-57.2 26.4-24.1 9.8-42.1 14.6-61.8 18.8-10.6 2.2-23.4 4.7-37.6 6.4-7.5.9-15.9 1.5-24.7 2.1-6.2.5-12.7.9-19.7 1.5-9.5.8-18.9 1.7-28.7 3.8-17.5 3.6-32.6 11.4-40.5 20.9-8.8 10.6-9.3 20.7-9.8 30.4l-.5 9.3c-1.2 21.4-2.5 45.7-3 71.8v1c-.4 22-.9 49.3-18 75.5-17 26.1-44.2 38.8-64 48l-2.8 1.3c-20 9.4-39.8 19.7-63 43.5-1.6 1.6-3.2 3.3-4.8 5.1-24.2 27.5-45.3 70.1-51.3 90.3l-1.2-.3Z"
            fill="#FEFCFF"
          />
          <path
            opacity={0.7}
            d="M-152.366 552.6c3.2-22.6 24.5-66.2 49.8-92.5 1.7-1.8 3.4-3.5 5.2-5.1 24.6-22.7 46.9-30.5 68.5-38.1 19.1-6.7 51.1-18 69.5-46.3 17.7-27.3 15.7-56.3 14.2-77.5-1-14.6-1.9-28.5-2.7-42.1-.9-14.6-1.7-28.4-2.7-41.5v-.5c-.8-10.4-1.6-22.2 7.5-34.6 8-11 23.8-19.8 42.3-23.5 10.8-2.2 21-2.8 29.6-3.2 12.1-.5 23.1-.3 32.9-.1 4.5.1 8.8.2 13 .2 14.7 0 28.2-.7 40.6-1.5 23.6-1.5 44.5-3.9 70.1-10.8 18-4.8 39-12.1 62.5-21.5 23.3-9.5 49.8-21.6 78.6-35.9 43.4-21.6 107.7-56.1 167.8-98l.8 1.5c-60.3 42-124.7 76.5-168.2 98.1-28.9 14.4-55.4 26.5-78.8 36-23.5 9.5-44.6 16.8-62.7 21.6-25.7 6.9-46.8 9.4-70.4 10.9-12.4.8-25.9 1.5-40.7 1.5-4.1 0-8.4-.1-13-.2-9.7-.2-20.8-.4-32.8.1-8.5.3-18.6 1-29.3 3.2-17.9 3.7-33.2 12.1-40.9 22.6-8.6 11.7-7.7 23.1-7 33.1v.5c1 13 1.8 26.8 2.7 41.5.8 13.5 1.7 27.5 2.7 42 1.5 21.5 3.6 50.9-14.5 78.8-18.9 29.1-51.4 40.5-70.8 47.3-21.4 7.5-43.4 15.3-67.7 37.6-1.7 1.5-3.4 3.2-5 4.9-24.3 25.3-46 69-49.2 91.2l-1.9.3Z"
            fill="#FEFCFF"
          />
          <path
            opacity={0.7}
            d="M-154.466 551.5c.1-22.9 20.6-67.9 47.9-93.9 1.8-1.7 3.7-3.4 5.5-4.9 24.3-20.1 46.5-25.6 69.9-31.5l3.5-.9c24.7-6.2 55.4-16.8 73.2-44.2 17.2-26.5 14.5-54.9 10.7-79.5l-1.6-10.2c-4.2-27.3-8.2-53.1-12.6-76.6-2.1-11.2-4.5-23.9 4.4-37.6 9.2-14.2 28.2-22.4 43-25.4 9.1-1.8 18.7-2.6 30.3-2.6 14.7.1 28 1.6 39.8 3 2.6.3 5.1.6 7.6.9 16.3 1.8 31.2 2.7 43.7 3.4 23.1 1.2 48.7 1.7 78.7-3 20.4-3.2 43.2-8.9 67.9-16.8 25.5-8.2 53.4-19.1 82.9-32.5 44-19.8 109.6-53.1 172.1-99.1l.9 1.6c-62.8 46.1-128.5 79.5-172.6 99.4-29.6 13.4-57.6 24.3-83.2 32.6-24.8 7.9-47.8 13.6-68.3 16.8-30.2 4.8-55.8 4.3-79.1 3-12.5-.7-27.4-1.6-43.8-3.4-2.5-.3-5-.6-7.6-.9-11.7-1.4-25-2.9-39.6-3-11.5-.1-21 .8-29.9 2.6-14.2 2.9-32.5 10.7-41.3 24.2-8.4 12.9-6.1 25.1-4.1 36 4.4 23.6 8.4 49.4 12.6 76.7l1.6 10.2c3.9 25 6.6 53.9-11.1 81.1-18.3 28.2-49.6 39-74.8 45.3l-3.5.9c-23.2 5.7-45.1 11.2-68.9 30.9-1.8 1.5-3.6 3.1-5.3 4.7-26.8 25.5-47 69.7-47.1 92.1l-1.8.6Z"
            fill="#FEFCFF"
          />
          <path
            opacity={0.7}
            d="M-156.566 550.4c-3.1-26.1 18.8-71.5 46-95.3 1.9-1.7 3.8-3.2 5.8-4.7 24.5-18.3 47.2-21.7 71.1-25.4 2.4-.4 4.8-.7 7.2-1.1 37.3-5.9 62.4-19.6 76.9-42 17.1-26.3 13.6-54.8 7.2-81.6-6.5-27.5-14.3-59.8-23-90.1-3.2-11.2-7.3-25.1 1.5-40.2 5.9-10.2 20.4-22.7 43.6-27.2 9.6-1.9 19.4-2.5 31-2 16.3.8 31 3.9 44.1 6.7l4.8 1c14 2.9 28.5 5.5 46.8 8.3 24.4 3.6 53.8 7.1 87.3 4.8 22.7-1.5 47.4-5.6 73.4-12 27.4-6.8 56.7-16.6 87.2-29 64.7-26.4 125.7-61 176.4-100.3l1 1.7c-51 39.4-112.2 74.2-177.1 100.6-30.6 12.5-60 22.3-87.5 29.1-26.2 6.5-51 10.5-73.8 12.1-33.6 2.3-63.2-1.2-87.7-4.8-18.4-2.8-32.8-5.4-46.9-8.3l-4.8-1c-13-2.7-27.7-5.8-43.8-6.6-11.4-.5-21 .1-30.5 1.9-13.9 2.8-33.2 11.2-41.7 25.8-8.2 14.1-4.3 27.6-1.2 38.3 8.7 30.3 16.5 62.6 23 90.2 6.4 27.3 10 56.3-7.6 83.4-15 23.1-40.7 37.3-78.8 43.3-2.4.4-4.8.7-7.2 1.1-23.6 3.6-46 7-70 24.9-1.9 1.4-3.8 2.9-5.6 4.5-26.5 23.2-48 67.5-45 93l-2.1.9Z"
            fill="#FEFCFF"
          />
          <path
            opacity={0.7}
            d="M-158.565 549.3c-6.2-27 14.8-73.1 44-96.6 2-1.6 4.1-3.1 6.1-4.5 25-16.8 49.5-18.4 73.2-19.9 3.3-.2 6.7-.4 10-.7 26.3-2 61.7-10.6 80.6-39.9 17.8-27.5 11.9-57.7 3.8-83.6-11.3-36.3-21.7-66.8-31.8-93.4-4.2-11.1-10-26.3-1.5-42.7 8.7-16.8 29.3-26.2 44.2-29.1 10-2 20.4-2.4 31.7-1.4 17.1 1.5 32.8 6.2 46.7 10.3l3.7 1.1c15.7 4.7 32.1 9 49.9 13.2 36.6 8.5 66.2 12.4 95.8 12.6 25 .2 51.5-2.2 78.9-7.2 29.3-5.4 60-14 91.5-25.6 66.5-24.5 129-59.5 180.7-101.4l1.1 1.7c-52 42.1-114.7 77.3-181.6 101.9-31.6 11.6-62.5 20.3-91.9 25.7-27.5 5-54.2 7.5-79.3 7.3-29.8-.2-59.5-4.1-96.3-12.7-17.9-4.2-34.2-8.5-50-13.2l-3.7-1.1c-14.4-4.3-29.4-8.8-46.3-10.3-11.1-1-21.3-.6-31 1.3-14.3 2.8-33.9 11.7-42.2 27.5-8 15.3-2.4 30 1.7 40.7 10.1 26.6 20.5 57.2 31.8 93.5 8.3 26.4 14.2 57.3-4.1 85.7-19.6 30.3-55.8 39.2-82.8 41.3-3.4.3-6.7.5-10.1.7-23.3 1.5-47.4 3.1-71.9 19.5-2 1.3-4 2.8-5.9 4.3-27.9 22.5-48.8 68.1-42.9 93.9l-2.1 1.1Z"
            fill="#FEFCFF"
          />
          <path
            opacity={0.7}
            d="M-160.566 548.2c-9.1-27.6 11.4-75.2 42.1-97.9 2.1-1.6 4.3-3 6.4-4.3 25.7-15.6 51.1-15.2 75.7-14.9 4.1.1 8.3.1 12.4.1 22.4-.1 63.2-5.1 84.3-37.7 18.4-28.5 10.5-59.3.3-85.7-13.8-35.8-27.1-67.4-40.6-96.6-5.1-11-12.8-27.5-4.5-45.2 5.3-11.3 20.2-26.2 44.9-30.9 10.4-2 21-2.3 32.3-.8 17.8 2.3 33.6 8.3 48.9 14.1l3 1.1c17.3 6.5 35.1 12.6 53 18.1 38.2 11.4 71.4 17.9 104.4 20.3 27.3 2 55.7 1.1 84.4-2.5 31.1-3.9 63.3-11.4 95.8-22.1 68.5-22.7 132.5-58.1 185-102.5l1.3 1.8c-52.8 44.7-117.1 80.3-186.1 103.1-32.7 10.8-65.1 18.3-96.3 22.2-28.9 3.6-57.4 4.4-84.8 2.5-33.1-2.4-66.4-8.9-104.9-20.5-17.9-5.4-35.8-11.5-53.1-18.1l-3-1.1c-15.2-5.8-30.9-11.7-48.4-14-11.2-1.5-21.5-1.2-31.6.7-23.5 4.5-37.6 18.5-42.6 29.1-7.1 15.3-1.9 29.1 4.6 43.1 13.5 29.2 26.8 60.9 40.6 96.8 4.3 11.3 9.5 26.2 10.6 42.1 1.2 17.3-2.5 32.3-11.3 45.9-21.9 33.9-63.8 39.1-86.8 39.2-4.1 0-8.4 0-12.4-.1-24.2-.3-49.2-.7-74.2 14.5-2.1 1.3-4.2 2.7-6.2 4.2-29.8 22.1-49.6 68.1-40.8 94.8l-2.4 1.2Z"
            fill="#FEFCFF"
          />
          <path
            opacity={0.7}
            d="M-162.565 547.1c-12.1-28.6 7.4-76.9 40.1-99.3 2.2-1.5 4.5-2.9 6.8-4.1 26.5-14.5 53-12.2 78.5-10.1 4.7.4 9.7.8 14.4 1.1 58 3.6 82-26.4 88-35.6 19.1-29.6 8.3-62.4-3.2-87.7-16-35.1-32.6-68.7-49.4-99.9-7.8-14.5-14.9-30.2-7.5-47.8 7.6-18.1 28.4-29.5 45.5-32.8 10.7-2 21.5-2.1 33-.2 18.1 3 34.4 10.4 50.2 17.6l3.1 1.4c18.4 8.4 37.2 16.1 56.1 22.9 39.7 14.4 76.7 23.6 113 28.2 29.5 3.7 59.8 4.5 89.9 2.3 32.9-2.4 66.5-8.7 100.1-18.7 70.6-21 136.1-56.8 189.3-103.7l1.4 1.8c-53.6 47.2-119.5 83.3-190.6 104.4-33.8 10-67.6 16.3-100.7 18.8-30.3 2.2-60.7 1.4-90.4-2.3-36.5-4.6-73.6-13.9-113.5-28.4-18.9-6.9-37.8-14.6-56.2-23l-3.1-1.4c-15.7-7.1-31.9-14.5-49.7-17.5-11.2-1.9-21.8-1.8-32.2.1-16.3 3.1-35.9 13.8-43 30.7-6.9 16.4-.1 31.5 7.5 45.6 16.8 31.2 33.4 64.9 49.4 100.1 11.8 25.8 22.7 59.5 2.8 90.3-9.3 14.5-23.4 25-41.8 31.2-18.5 6.3-36.9 6.7-49 6-4.8-.3-9.7-.7-14.5-1.1-25.2-2.1-51.2-4.3-76.9 9.7-2.2 1.2-4.3 2.5-6.5 4-31.3 21.8-50.2 68.3-38.5 95.9l-2.4 1.5Z"
            fill="#FEFCFF"
          />
          <path
            opacity={0.7}
            d="M-164.666 545.9c-7.8-15.3-7-36.2 2.1-57.3 9.1-21.1 24.9-38.3 43.2-47.3 27.4-13.4 54.9-9.3 81.5-5.4 5.4.8 10.9 1.6 16.3 2.3 47.9 5.8 78.8-13.5 91.7-33.5 19.9-30.9 5.8-66-6.6-89.8-18.3-35-37.8-69.8-58.2-103.2-4.1-6.7-8.6-14.5-11.1-23.2-2.8-9.6-2.6-18.5.7-27.1 7.3-19.3 28.5-31.3 46.1-34.7 32.5-6.1 59.6 8.1 85.8 21.8l2.7 1.4c95.9 50.2 186.2 73.4 276.2 71 109.3-3 217.9-46.7 298-120l1.5 1.9c-80.7 73.9-190.1 118-300.1 121-90.4 2.4-181.1-20.8-277.3-71.2l-2.7-1.4c-25.9-13.6-52.6-27.6-84.3-21.6-16.7 3.1-36.6 14.4-43.5 32.3-6.7 17.6 1.5 33.3 10.4 48 20.4 33.5 40 68.3 58.2 103.3 26.5 50.7 16.3 77.2 6.3 92.7-20.5 31.9-62.4 39-94.8 35.1-5.4-.7-11-1.5-16.4-2.3-26.2-3.9-53.3-7.9-79.8 5.1-17.5 8.6-32.6 25.1-41.4 45.4-8.8 20.3-9.5 40.3-2.1 55l-2.4 1.7Z"
            fill="#FEFCFF"
          />
        </g>
      </g>
    </svg>
  );
};

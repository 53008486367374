/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, lazy, useCallback, useEffect, useState } from "react";
import PageHeader from "../components/PageHeader";
import PageContainer from "../components/layouts/PageContainer";
import { graphqlErrorParser } from "../helpers";
import PinInput from "../components/PinInput";
import { useGlobalLoaderService } from "../hooks/useGlobalLoaderService";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useAppState } from "../hooks/useAppState";
import { useNavigate } from "react-router";
import { RoutePath } from "../router/path";
import { finalizeUserRegistrationMutationDocument } from "../apollo/mutations/login.gql";
import { PIN_LENGTH } from "@/constants";
import {
  getCompanyBySlug,
  getLinkableCompany,
} from "@/apollo/queries/company.gql";
import { autoLinkMutationDocument } from "@/apollo/mutations/onboard.gql";
import { useBeforeUnload } from "react-router-dom";
import { ANALYTIC_CATEGORIES, trackEvent } from "@/helpers/events";
import { T9Keyboard } from "@/components/Keyboard/T9Keyboard";
import { defaultAppState } from "@/state/user";
import Modal from "@/components/Modal";

const Icon = lazy(() => import("@/components/icons/Lock"));

export const CreatePinPage = () => {
  const {
    state: { deviceToken, slug, phoneNumber },
    setState,
  } = useAppState();
  const navigate = useNavigate();
  const [confirmGoBack, setConfirmGoBack] = useState<boolean>(false);

  const [step, setStep] = useState<number>(0);
  const [pin, setPin] = useState<string>("");
  const [pinConfirmation, setPinConfirmation] = useState<string>("");
  const [error, setError] = useState<unknown>();

  const [finalizeUserRegistrationMutation] = useMutation(
    finalizeUserRegistrationMutationDocument
  );

  const [autoLinkMutation] = useMutation(autoLinkMutationDocument);

  const [fetchLinkableCompanyFunc] = useLazyQuery(getLinkableCompany);

  const { loading, setGlobalLoading } = useGlobalLoaderService();

  const handleBack = useCallback(() => {
    setStep(0);
    setPin("");
    setPinConfirmation("");
  }, []);

  const handleKeyboardChange = useCallback(
    (val: number) => {
      if (step === 0) {
        setPin((old) => `${old}${val}`.slice(0, PIN_LENGTH));
      } else {
        setPinConfirmation((old) => `${old}${val}`.slice(0, PIN_LENGTH));
      }
      setError(undefined); // clear error when user type
    },
    [step]
  );

  const handleDelete = useCallback(() => {
    if (step === 0) {
      setPin((old) => old.slice(0, -1));
    } else {
      setPinConfirmation((old) => old.slice(0, -1));
    }
  }, [step]);

  const performFinalizeUserRegistration = async () => {
    return await finalizeUserRegistrationMutation({
      variables: {
        payload: {
          deviceToken: deviceToken || "",
          password: pin,
        },
      },
      refetchQueries: slug
        ? [
            {
              query: getCompanyBySlug,
              variables: {
                slug,
              },
            },
          ]
        : [],
      awaitRefetchQueries: true,
    });
  };

  const fetchLinkableCompany = async (accessToken?: string) => {
    if (!accessToken) throw new Error("Missing access token");
    return await fetchLinkableCompanyFunc({
      context: {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      },
    });
  };

  const performAutoLink = async (companyId: string, accessToken?: string) => {
    if (!accessToken) throw new Error("Missing access token");
    return await autoLinkMutation({
      variables: {
        payload: {
          companyId,
        },
      },
      context: {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      },
    });
  };

  const handleNext = useCallback(async () => {
    try {
      if (loading) {
        return;
      }
      // tracker
      trackEvent(ANALYTIC_CATEGORIES.RegisterCredential, "confirm_password");

      if (pin !== pinConfirmation) {
        setPinConfirmation(""); // clear pin confirmation
        throw new Error("Hai mật khẩu không trùng khớp");
      }
      // Step: 1 create Passcode ->
      setGlobalLoading(true);
      const { data } = await performFinalizeUserRegistration();

      // get linkable company
      const { data: linkableCompanyData } = await fetchLinkableCompany(
        data?.finalizeUserRegistration.accessToken
      );

      if (
        linkableCompanyData?.tryMatchPhoneWithLinkableCompany &&
        linkableCompanyData?.tryMatchPhoneWithLinkableCompany.length > 0
      ) {
        const firstChoice =
          linkableCompanyData?.tryMatchPhoneWithLinkableCompany[0];

        await performAutoLink(
          firstChoice.id,
          data?.finalizeUserRegistration.accessToken
        );

        // Navigate to sign agreement
        setState((prevState) => ({
          ...prevState,
          accessToken: data?.finalizeUserRegistration.accessToken,
          refreshToken: data?.finalizeUserRegistration.refreshToken,
          slug: firstChoice.slug,
        }));

        navigate(RoutePath.Index);
      } else {
        setState((prevState) => ({
          ...prevState,
          accessToken: data?.finalizeUserRegistration.accessToken,
          refreshToken: data?.finalizeUserRegistration.refreshToken,
        }));
        // Navigate to onboard
        navigate(RoutePath.Onboard);
      }
    } catch (error) {
      setError(error);
    } finally {
      setGlobalLoading(false);
    }
  }, [
    step,
    loading,
    pin,
    pinConfirmation,
    setGlobalLoading,
    performFinalizeUserRegistration,
    fetchLinkableCompany,
    performAutoLink,
    setState,
    navigate,
  ]);

  const handleChangePhoneNumber: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        e.preventDefault();
        setConfirmGoBack(true);
      },
      [navigate]
    );

  const handleConfirmChangePhoneNumber = useCallback(() => {
    setConfirmGoBack(false);
    setState((prevState) => ({
      ...defaultAppState,
      initialized: true,
      slug: prevState.slug,
    }));
    navigate(RoutePath.Login);
  }, [navigate]);

  const handleCancelChangePhoneNumber = useCallback(() => {
    setConfirmGoBack(false);
  }, [navigate]);

  useEffect(() => {
    // auto next step when user type enough PIN_LENGTH
    if (pin.length === PIN_LENGTH) {
      // tracker
      trackEvent(ANALYTIC_CATEGORIES.RegisterCredential, "create_password");
      setStep((old) => Math.min(old + 1, 1));
    }
  }, [pin]);

  useEffect(() => {
    // auto next step when user type enough PIN_LENGTH
    if (pinConfirmation.length === PIN_LENGTH) {
      handleNext();
    }
  }, [pinConfirmation]);

  useBeforeUnload(
    useCallback(() => {
      setGlobalLoading(false); // prevent non-stop loading when user close tab
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  );

  return (
    <>
      <PageContainer className="flex h-viewport flex-col justify-between">
        <PageHeader
          hideRightButton
          hideLeftButton={step === 0}
          // title={step === 0 ? "Khởi tạo mật khẩu" : "Xác nhận mật khẩu"}
          title=""
          onLeftButtonPress={handleBack}
          rightElement={
            phoneNumber ? null : ( // hide change phone number button if user already has phone number
              <button
                className="bg-transparent"
                onClick={handleChangePhoneNumber}
              >
                <strong className="text-primary text-sm px-1">
                  Đổi số điện thoại
                </strong>
              </button>
            )
          }
        />

        <div className="flex-1">
          <div className="px-4 pt-4 grid gap-6">
            <Suspense fallback={<div className="w-16 h-16" />}>
              <Icon className="w-16 h-16 text-primary/10" />
            </Suspense>
            {step === 0 ? (
              <>
                <div>
                  <h3 className="text-2xl mb-2 font-bold text-primaryBlack">
                    Tạo mật khẩu
                  </h3>
                  <p className="text-[15px] leading-[21px] text-darkGray line-clamp-2">
                    Nhớ mật khẩu này để đăng nhập và xác nhận các giao dịch nhé
                  </p>
                </div>
                <div className="flex items-center justify-center py-5">
                  <PinInput length={PIN_LENGTH} value={pin} />
                </div>
              </>
            ) : (
              <>
                <div>
                  <h3 className="text-2xl mb-2 font-bold text-primaryBlack">
                    Xác nhận mật khẩu
                  </h3>
                  <p className="text-[15px] leading-[21px] text-darkGray line-clamp-2">
                    Nhập lại mật khẩu lần nữa để chắc chắn nào <br />
                    &nbsp;
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center gap-2 py-5">
                  <PinInput length={PIN_LENGTH} value={pinConfirmation} />
                  {error ? (
                    <p
                      aria-describedby={graphqlErrorParser(error)}
                      className="px-4 text-center text-[13px] leading-5  text-[#FF2638] lining-nums"
                    >
                      {graphqlErrorParser(error)}
                    </p>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>

        <T9Keyboard onChange={handleKeyboardChange} onDelete={handleDelete} />
      </PageContainer>
      <Modal
        panelClassName="w-full"
        textClassName="w-full text-center"
        open={!!confirmGoBack}
        onClose={handleCancelChangePhoneNumber}
        onConfirm={handleConfirmChangePhoneNumber}
        onCancel={handleCancelChangePhoneNumber}
        confirmText="Thay đổi"
        cancelText="Ở lại "
        title={"Thay đổi số điện thoại"}
        descriptionWrapperClassName={"mt-2"}
        description={"Bạn chắc chắn muốn thay đổi số điện thoại chứ"}
      />
    </>
  );
};

export default CreatePinPage;

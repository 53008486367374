import { NavLink } from "react-router-dom";
import Home from "./icons/Home";
import { RoutePath } from "@/router/path";
import Bell from "./icons/Bell";
import Cog from "./icons/Cog";
import { ANALYTIC_CATEGORIES, trackEvent } from "@/helpers/events";
import { totalUnReadMessageQueryDocument } from "@/apollo/queries/message.gql";
import { useQuery } from "@apollo/client";

function BottonNavigator() {
  const { data } = useQuery(totalUnReadMessageQueryDocument);
  const handleClickHome = () => {
    // Traker
    trackEvent(ANALYTIC_CATEGORIES.Home, "press_home");
  };

  const handlClickMessage = () => {
    // Traker
    trackEvent(ANALYTIC_CATEGORIES.Message, "press_message");
  };

  const handleClickSetting = () => {
    // Traker
    trackEvent(ANALYTIC_CATEGORIES.Setting, "press_setting");
  };

  return (
    <div className="fixed z-10 bottom-0 w-full mx-auto max-w-[480px] bg-white shadow-main h-14 grid grid-cols-3">
      <NavLink
        preventScrollReset
        to={RoutePath.Home}
        onClick={handleClickHome}
        className={({ isActive }) =>
          [
            "flex mt-1.5 justify-center items-center flex-col gap-1 ",
            isActive ? "text-primary" : "text-gray96",
          ].join(" ")
        }
      >
        <Home className="w-6 h-6" />
        <span className="text-[10px] leading-[14px]">Trang chủ</span>
      </NavLink>
      <NavLink
        preventScrollReset
        to={RoutePath.InAppMessage}
        onClick={handlClickMessage}
        className={({ isActive }) =>
          [
            "flex mt-1.5 justify-center items-center flex-col gap-1 ",
            isActive ? "text-primary" : "text-gray96",
          ].join(" ")
        }
      >
        <div className="w-6 h-6 relative">
          {data?.profile?.messages?.total && data.profile.messages.total > 0 ? (
            <span
              className="absolute -right-4 -top-2 ml-auto min-w-max whitespace-nowrap rounded-full bg-red-500 px-2.5 py-0.5 text-center text-xs font-medium lining-nums leading-5 text-white ring-1 ring-inset ring-gray-200"
              aria-hidden="true"
            >
              {data?.profile.messages.total > 20
                ? "20+"
                : data?.profile.messages.total}
            </span>
          ) : null}
          <Bell className="w-6 h-6" />
        </div>
        <span className="text-[10px] leading-[14px]">Thông báo</span>
      </NavLink>
      <NavLink
        preventScrollReset
        to={RoutePath.Setting}
        onClick={handleClickSetting}
        className={({ isActive }) =>
          [
            "flex mt-1.5 justify-center items-center flex-col gap-1 ",
            isActive ? "text-primary" : "text-gray96",
          ].join(" ")
        }
      >
        <Cog className="w-6 h-6" />
        <span className="text-[10px] leading-[14px]">Cài đặt</span>
      </NavLink>
    </div>
  );
}

export default BottonNavigator;

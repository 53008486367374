import { gql } from "../__generated__";

export const requestChangePasswordMutationDocument = gql(/* GraphQL */ `
  mutation requestChangePassword(
    $phoneNumber: PhoneNumber!
    $otpLength: Int
    $idNumberValidate: String!
  ) {
    requestChangePasswordV2(
      phoneNumber: $phoneNumber
      otpLength: $otpLength
      idNumberValidate: $idNumberValidate
    ) {
      requestId
      otp {
        success
        debug_otp
        retryAfter
      }
      debug_otp
    }
  }
`);

export const requestChangePasswordWithoutSocialIdMutationDocument = gql(
  /* GraphQL */ `
    mutation requestChangePasswordWithoutSocialId(
      $phoneNumber: PhoneNumber!
      $otpLength: Int
    ) {
      requestChangePassword(phoneNumber: $phoneNumber, otpLength: $otpLength) {
        requestId
        otp {
          success
          debug_otp
          retryAfter
        }
        debug_otp
      }
    }
  `
);

export const verifyChangePasswordMutationDocument = gql(/* GraphQL */ `
  mutation verifyChangePasswordRequest(
    $payload: VerifiyChangePasswordRequestPayload!
  ) {
    verifyChangePasswordRequest(payload: $payload) {
      token
    }
  }
`);

export const finalizeChangePasswordMutationDocument = gql(/* GraphQL */ `
  mutation finalizeChangePasswordRequest(
    $payload: FinalizeChangePasswordRequestPayload!
  ) {
    finalizeChangePasswordRequest(payload: $payload) {
      accessToken
      refreshToken
    }
  }
`);

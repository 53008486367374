import { numberToMoney } from "@/utils/money";
import { isNonEmptyArray } from "@apollo/client/utilities";
import { Suspense, useEffect, useMemo, useState } from "react";
import LogoWhite from "../LogoWhite";
import { useWithdrawalBalance } from "@/hooks";
import { stringHelper } from "@/helpers/string";
import { motion } from "framer-motion";

type Props = {
  logoUrl?: string;
};

function GrayCard({ logoUrl }: Props) {
  const [msg, setMsg] = useState({
    title: "",
    content: "",
  });
  const { currentAdvanceablePeriod, minAdvanceValue, shouldShowGrayCard } =
    useWithdrawalBalance("cache-first");

  const numberOfAttendances = useMemo(
    () => currentAdvanceablePeriod?.workPeriod.attendances.sumShiftCount,
    [currentAdvanceablePeriod?.workPeriod.attendances.sumShiftCount]
  );

  useEffect(() => {
    const asyncAction = async () => {
      try {
        const isDeduction =
          currentAdvanceablePeriod?.workPeriod.advanceValidation.failure
            ?.extensions.deduction &&
          isNonEmptyArray(
            currentAdvanceablePeriod?.workPeriod.advanceValidation.failure
              ?.extensions.deduction
          );

        if (isDeduction) {
          // uu tien deduction
          return {
            title: await stringHelper.parseEmoji(
              "Rất tiếc, số dư hiện tại không đủ để bạn rút lương sớm :disappointed:"
            ),
            content:
              await stringHelper.parseEmoji(`<span>Hệ thống ghi nhận số dư tháng này của bạn bị khấu trừ:</span>
            <ul>
               ${currentAdvanceablePeriod?.workPeriod.advanceValidation.failure?.extensions.deduction
                 ?.map(
                   (item) =>
                     `<li>
                   <strong>${numberToMoney(
                     item.amount.value,
                     false,
                     "đ"
                   )}</strong>
                    <span>${item.message}</span></li>`
                 )
                 .join("")}
            </ul>
            <span>Làm thêm công, lương sẽ về! :grinning:</span>
            `),
          };
        }

        if (shouldShowGrayCard) {
          return {
            title: await stringHelper.parseEmoji(
              currentAdvanceablePeriod?.workPeriod.advanceValidation.failure
                ?.title || ""
            ),
            content: await stringHelper.parseEmoji(
              currentAdvanceablePeriod?.workPeriod.advanceValidation.failure
                ?.content || ""
            ),
          };
        }

        // khong co tien + khong co cong
        if (numberOfAttendances === 0) {
          return {
            title: await stringHelper.parseEmoji(
              "Rất tiếc, bạn không thể rút lương sớm hôm nay :disappointed:"
            ),
            content: await stringHelper.parseEmoji(
              "Vì tháng này bạn chưa có công. Liên hệ Quản lý để yêu cầu cập nhật công nhé!"
            ),
          };
        }

        // khong co tien
        if (
          currentAdvanceablePeriod?.workPeriod.withdrawableBalance.value <
          minAdvanceValue
        ) {
          return {
            title: await stringHelper.parseEmoji(
              "Rất tiếc, bạn không thể rút lương sớm hôm nay :disappointed:"
            ),
            content: await stringHelper.parseEmoji(`
            <span>Vì số dư hiện tại của bạn dưới ${Math.floor(
              minAdvanceValue / 1000
            )}K.</span>
            <span>Làm thêm công, lương sẽ về! :grinning:</span>`),
          };
        }

        return {
          title: "",
          content: "",
        };
      } catch (error) {
        return {
          title: "",
          content: "",
        };
      }
    };

    asyncAction().then((data) => {
      setMsg(data);
    });
  }, [
    currentAdvanceablePeriod?.workPeriod.advanceValidation.failure?.content,
    currentAdvanceablePeriod?.workPeriod.advanceValidation.failure?.extensions
      .deduction,
    currentAdvanceablePeriod?.workPeriod.advanceValidation.failure?.title,
    currentAdvanceablePeriod?.workPeriod.withdrawableBalance.value,
    minAdvanceValue,
    numberOfAttendances,
    shouldShowGrayCard,
  ]);

  return (
    <motion.div
      layout
      className="relative flex w-full flex-col justify-start gap-4 p-4 overflow-hidden rounded-2xl"
      style={{
        background: "linear-gradient(109deg, #C6CEE0 0%, #A7B1C8 100%)",
        aspectRatio: 320 / 189,
      }}
    >
      <div className="xs:mb-3 z-10 mb-1 flex w-full flex-row items-center justify-between">
        <LogoWhite width={26} height={16} />

        <div className="h-[22px]">
          {logoUrl ? (
            <img
              src={logoUrl}
              alt="logo"
              className="max-h-[22px] max-w-[100px] object-contain"
            />
          ) : (
            <div className="-mr-2 -mt-2 flex h-8 w-8 items-center justify-center rounded-full bg-black/10">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 3.33333C12.2975 3.33333 14.1667 5.20249 14.1667 7.49999C14.1667 9.51166 12.7325 11.1958 10.8333 11.5817V12.5C10.8333 12.9608 10.4608 13.3333 10 13.3333C9.53917 13.3333 9.16667 12.9608 9.16667 12.5V10.8333C9.16667 10.3725 9.53917 9.99999 10 9.99999C11.3783 9.99999 12.5 8.87833 12.5 7.49999C12.5 6.12166 11.3783 4.99999 10 4.99999C8.62167 4.99999 7.5 6.12166 7.5 7.49999C7.5 7.96083 7.1275 8.33333 6.66667 8.33333C6.20584 8.33333 5.83334 7.96083 5.83334 7.49999C5.83334 5.20249 7.7025 3.33333 10 3.33333ZM9.16667 15.8333C9.16667 15.3725 9.53917 15 10 15C10.4608 15 10.8333 15.3725 10.8333 15.8333C10.8333 16.2942 10.4608 16.6667 10 16.6667C9.53917 16.6667 9.16667 16.2942 9.16667 15.8333Z"
                  fill="white"
                />
                <mask
                  id="mask0_0_74"
                  maskUnits="userSpaceOnUse"
                  x="5"
                  y="3"
                  width="10"
                  height="14"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 3.33333C12.2975 3.33333 14.1667 5.20249 14.1667 7.49999C14.1667 9.51166 12.7325 11.1958 10.8333 11.5817V12.5C10.8333 12.9608 10.4608 13.3333 10 13.3333C9.53917 13.3333 9.16667 12.9608 9.16667 12.5V10.8333C9.16667 10.3725 9.53917 9.99999 10 9.99999C11.3783 9.99999 12.5 8.87833 12.5 7.49999C12.5 6.12166 11.3783 4.99999 10 4.99999C8.62167 4.99999 7.5 6.12166 7.5 7.49999C7.5 7.96083 7.1275 8.33333 6.66667 8.33333C6.20584 8.33333 5.83334 7.96083 5.83334 7.49999C5.83334 5.20249 7.7025 3.33333 10 3.33333ZM9.16667 15.8333C9.16667 15.3725 9.53917 15 10 15C10.4608 15 10.8333 15.3725 10.8333 15.8333C10.8333 16.2942 10.4608 16.6667 10 16.6667C9.53917 16.6667 9.16667 16.2942 9.16667 15.8333Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_0_74)">
                  <rect width="20" height="20" fill="white" />
                </g>
              </svg>
            </div>
          )}
        </div>
      </div>
      <div className="z-10">
        <Suspense fallback={<div />}>
          <h3 className="text-base font-medium text-primaryBlack">
            {msg?.title}
          </h3>
        </Suspense>
        <div className="mt-2 text-sm text-darkGray">
          <Suspense fallback={<div />}>
            <div
              dangerouslySetInnerHTML={{
                __html: msg?.content,
              }}
            />
          </Suspense>
        </div>
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 251 135"
        fill="none"
        className="absolute inset-0 overflow-hidden z-0 h-full"
      >
        <path
          d="M28 177.273C151.156 177.273 251 75.3604 251 -50.7955C251 -176.951 151.156 -278.864 28 -278.864C-95.1558 -278.864 -195 -176.951 -195 -50.7955C-195 75.3604 -95.1558 177.273 28 177.273Z"
          fill="#242B3D"
          fillOpacity="0.05"
        />
      </svg>
    </motion.div>
  );
}

export default GrayCard;

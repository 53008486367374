import {
  isAfter,
  isBefore,
  isEqual,
  intervalToDuration,
  formatDuration,
} from "date-fns";
// import {vi} from 'date-fns/locale';
import vi from "date-fns/esm/locale/vi/index.js";

export const isAfterOrEqual = (
  dateLeft: number | Date,
  dateRight: number | Date
) => {
  return isEqual(dateLeft, dateRight) || isAfter(dateLeft, dateRight);
};

export const isBeforeOrEqual = (
  dateLeft: number | Date,
  dateRight: number | Date
) => {
  return isEqual(dateLeft, dateRight) || isBefore(dateLeft, dateRight);
};

export const isChristmasEvent = () => {
  const today = new Date();
  const christmas = new Date(today.getFullYear(), 11, 26);
  const oneWeekBeforeChristmas = new Date(today.getFullYear(), 11, 18);

  return (
    isAfterOrEqual(today, oneWeekBeforeChristmas) &&
    isBeforeOrEqual(today, christmas)
  );
};

export const isBetweenInclusive = (
  date: number | Date,
  dateLeft: number | Date,
  dateRight: number | Date
) => {
  return isAfterOrEqual(date, dateLeft) && isBeforeOrEqual(date, dateRight);
};

export const customFormatDuration = ({
  start,
  end,
}: {
  start: number;
  end: number;
}) => {
  const durations = intervalToDuration({ start, end });
  return formatDuration(durations, {
    locale: vi,
  });
};

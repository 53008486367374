/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import keywordSet from "@/assets/emoji/emojilib.json";

let emojiJson: any;

async function getEmojiJson() {
  if (!emojiJson) {
    emojiJson = (await import("unicode-emoji-json")).default;
  }
  return emojiJson;
}

export const stringHelper = {
  handleCutFileName: (str: string) => {
    return str.length > 9 ? `${str.substring(0, 4)}[...].pdf` : str;
  },
  handleCutShortDescription: (str: string) => {
    return str?.length > 50 ? `${str?.substring(0, 48)}...` : str;
  },
  getEmojiObjectByCode: async (emojiCode: string) => {
    try {
      const data = await getEmojiJson();
      emojiCode = emojiCode.replace(/:/g, "");

      const emojiMap = { ...data };

      for (const emoji in emojiMap) {
        // @ts-expect-error
        emojiMap[emoji].keywords = keywordSet[emoji];
      }

      const emojiKey = Object.keys(emojiMap).find(
        (emojiKey) => emojiMap[emojiKey].keywords?.includes(emojiCode)
      );

      return emojiKey;
    } catch (error) {
      return null;
    }
  },
  async parseEmoji(text: string) {
    if (typeof text !== "string") {
      throw new Error("The text parameter should be a string.");
    }

    const emojisRegExp = /:(\w+):/g;
    const emojisList = text.match(emojisRegExp);

    if (emojisList) {
      for (const emojiCode of emojisList) {
        const emoji = await this.getEmojiObjectByCode(emojiCode);
        if (emoji) {
          const regEx = new RegExp(emojiCode);
          text = text.replace(regEx, emoji);
        }
      }
    }
    return text;
  },
};

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const withScrollReset = <P extends object>(
  Component: React.ComponentType<P>
) => {
  return (props: P) => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return <Component {...props} />;
  };
};

export default withScrollReset;

import { gql } from "../__generated__";

export const linkEmploymentNguyenKimFlowMutationDocument = gql(/* GraphQL */ `
  mutation linkEmploymentNguyenKim(
    $id: ID!
    $payload: nguyenkim_LinkEmploymentPayload!
  ) {
    nguyenkim_linkEmployment(id: $id, payload: $payload) {
      employment {
        id
        status
        fullName
      }
    }
  }
`);

export const autoLinkMutationDocument = gql(/* GraphQL */ `
  mutation tryAutoLink($payload: TryAutoLinkEmploymentPayload!) {
    tryAutoLinkEmployment(payload: $payload) {
      employment {
        id
        status
        fullName
      }
    }
  }
`);

export const defaultLinkEmploymentMutationDocument = gql(/* GraphQL */ `
  mutation linkEmployment($payload: UserLinkEmploymentPayload!) {
    linkEmployment(payload: $payload) {
      employment {
        id
        status
        fullName
      }
    }
  }
`);

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AttendanceType, ShiftWorkType } from "@/apollo/__generated__/graphql";
import Day from "./Day";
import Instructions from "./Instructions";
import { round } from "lodash-es";
import { useMemo } from "react";
import { ShiftUnit } from "@/types/graphql-global-types";

type Props = {
  onDayClick?: (date: Date) => void;
  attendances: any[];
  unit?: ShiftUnit | null;
};

export const DATE_FORMAT_FOR_QUERY = "yyyy-MM-dd";

enum WeekdayInNumber {
  "Mon" = "T2",
  "Tue" = "T3",
  "Wed" = "T4",
  "Thu" = "T5",
  "Fri" = "T6",
  "Sat" = "T7",
  "Sun" = "CN",
}
const Calendar = ({ onDayClick, attendances, unit = ShiftUnit.Day }: Props) => {
  const totalStandard = useMemo(
    () =>
      round(
        attendances
          .filter((item) => item.shiftWork === ShiftWorkType.Day)
          ?.reduce((acc, current) => acc + Number(current.shiftCount), 0) || 0,
        2
      ),
    [attendances]
  );

  const totalRecordMissing = useMemo(
    () =>
      attendances.filter((item) => item.type === AttendanceType.RecordMissing)
        ?.length,
    [attendances]
  );

  const totalNightShift = useMemo(
    () =>
      round(
        attendances
          .filter((item) => item.shiftWork === ShiftWorkType.Night)
          ?.reduce((acc, current) => acc + Number(current.shiftCount), 0) || 0,
        2
      ),
    [attendances]
  );
  return (
    <div className="flex flex-col gap-4 px-4 py-3">
      <div className="flex flex-col gap-y-3">
        <div className="grid grid-cols-7 gap-x-2">
          {Object.values(WeekdayInNumber).map((weekday) => {
            return (
              <div
                className="text-center text-[13px] lining-nums leading-5 text-[#8E96A4]"
                key={`week_${weekday}`}
              >
                {weekday}
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-7 gap-2">
          {attendances.map((item, idx) => {
            return (
              <Day
                onSelectDate={onDayClick}
                key={`item-${item.dateInNumber}-${idx}`}
                item={item}
              />
            );
          })}
        </div>
      </div>
      <p className="text-center text-sm font-medium text-gray96">
        Bấm vào từng ngày để xem chi tiết công nhé
      </p>
      <Instructions
        unit={unit}
        totalStandard={totalStandard}
        totalNightShift={totalNightShift}
        totalRecordMissing={totalRecordMissing}
      />
    </div>
  );
};

export default Calendar;

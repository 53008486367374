import { useNavigate, useRouteError } from "react-router-dom";
import PageContainer from "../layouts/PageContainer";
import { HomeIcon } from "@heroicons/react/20/solid";
import { Suspense, lazy } from "react";
import { graphqlErrorParser } from "@/helpers";
import SimpleLoading from "../Loader/SimpleLoading";

const NotFoundIcon = lazy(() => import("@/assets/icons/not-found.svg?react"));

export function PageErrorBoundary() {
  const navigate = useNavigate();
  const error = useRouteError() as Error;

  const handleGoIndex = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate("/");
  };

  return (
    <PageContainer className="flex h-viewport w-full flex-col items-center justify-center gap-4">
      <Suspense fallback={<SimpleLoading />}>
        <NotFoundIcon />
      </Suspense>
      <p className="text-center">{graphqlErrorParser(error)}</p>
      <button
        onClick={handleGoIndex}
        type="button"
        className="inline-flex items-center gap-x-1.5 rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm"
      >
        <HomeIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
        Về trang chủ
      </button>
    </PageContainer>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldPolicy, Reference, FieldFunctionOptions } from "@apollo/client";
import { uniqBy } from "lodash-es";

type KeyArgs = FieldPolicy<any>["keyArgs"];

export type TVuiExisting<TNode> = {
  nodes: TNode[];
  limit?: number;
  offset?: number;
  total?: number;
};

export type TVuiIncoming<TNode> = {
  nodes: TNode[];
  limit?: number;
  offset?: number;
  total?: number;
};

export type VuiFieldPolicy<TNode> = FieldPolicy<
  TVuiExisting<TNode>,
  TVuiIncoming<TNode>,
  TVuiIncoming<TNode>
>;

export function vuiStylePagination<T = Reference>(
  keyArgs: KeyArgs = false
): VuiFieldPolicy<T> {
  return {
    keyArgs,
    merge(
      existing,
      incoming,
      { args, canRead, readField }: FieldFunctionOptions
    ) {
      // Dua tay day nao. Mai ben nhau ban nhe.
      const offset = args?.query?.offset ?? -1;
      const limit = args?.query?.limit ?? -1;

      // Insert the incoming elements in the right places, according to args.
      if (offset >= 0 && limit > 0) {
        // filter dangling references from the existing items
        const mergedNodes =
          existing?.nodes && existing?.nodes.length
            ? existing.nodes.slice(0).filter(canRead as any)
            : // ? existing.nodes.filter(canRead)
              [];

        // we need the offset difference of the existing array and what was requested,
        // since existing can already contain newly inserted items that may be present in the incoming
        const offsetDiff = Math.max(mergedNodes?.length - offset, 0);
        const end = offset + Math.min(limit, incoming.nodes?.length ?? 0);

        for (let i: number = offset; i < end; ++i) {
          const node = incoming.nodes[i - offset];

          // find if the node is already present in the array
          // this could happen when new  obj is added at the top of the list, and when
          // requesting a new page to the server, the server sends the same id back in the new page
          const existing = mergedNodes
            .slice(offset, offset + limit)
            .find((m) => {
              if (!m) {
                return false;
              }
              return (
                readField<T>("id", m as any) === readField<T>("id", node as any)
              );
            });

          if (!existing) {
            mergedNodes[i + offsetDiff] = node;
          }
        }
        // we filter for empty spots in case the incoming contained existing items.
        // This could happen if items were inserted at the top of the list

        const nodes = mergedNodes.filter((m: any) => m);

        return {
          ...incoming,
          nodes,
        };
      }
      return incoming;
    },
    read(existing) {
      return existing;
    },
  };
}

export function nanoStylePagination(key = "__ref") {
  return (existing: any, incoming: any) => {
    return existing
      ? {
          ...incoming,
          nodes: uniqBy([...existing.nodes, ...incoming.nodes], key),
        }
      : incoming;
  };
}

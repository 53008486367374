import { gql } from "../__generated__";

export const finalizeUserRegistrationMutationDocument = gql(/* GraphQL */ `
  mutation finalizeUserRegistration(
    $payload: FinaliseUserRegistrationPayload!
  ) {
    finalizeUserRegistration(payload: $payload) {
      accessToken
      expiresIn
      refreshToken
      refreshExpiresIn
    }
  }
`);

export const loginMutationDocument = gql(/* GraphQL */ `
  mutation login($payload: LoginPayload!) {
    login(payload: $payload) {
      accessToken
      expiresIn
      refreshToken
      refreshExpiresIn
    }
  }
`);

export const requestLoginMutationDocument = gql(/* GraphQL */ `
  mutation requestLogin($payload: RequestLoginPayload!) {
    requestLogin(payload: $payload) {
      isNew
      token
      debug_otp
    }
  }
`);

export const requestDeviceTokenMutationDocument = gql(/* GraphQL */ `
  mutation requestDeviceTokenV2($payload: RequestDeviceTokenPayload!) {
    requestDeviceToken(payload: $payload) {
      deviceToken
    }
  }
`);

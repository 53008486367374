import { gql } from "../__generated__";

export const balanceQueryDocument = gql(/* GraphQL */ `
  query balance {
    profile {
      id
      currentEmployment {
        id
        shiftCountUnit
        group {
          id
          transactionFeePercentage
          limitPercentageBySalaryPeriod
          dailyLimit
          numberOfAdvanceBySalaryPeriod
          numberOfAdvanceByDay
          limitPerAdvance
          salaryPeriodFreeAmount
          minAdvanceValue
        }
        advanceablePeriods {
          startTime
          endTimeInclusive
          workPeriod {
            id
            startTime
            endTimeInclusive
            withdrawableBalance
            earnedBalance
            earnedBalanceCurrent
            earnedBalanceMax
            attendances {
              sumShiftCount
            }
            advanceValidation {
              success
              failure {
                code
                title
                mood
                content
                extensions {
                  deduction {
                    code
                    message
                    amount
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const activeHomeBannerQueryDocument = gql(/* GraphQL */ `
  query nextGenActiveBanners($slot: String) {
    profile {
      id
      currentEmployment {
        id
        banner_activeBanners(slot: $slot) {
          id
          contentImage {
            id
            uri
          }
          title
          code
          description
          slot
          priority
          status
          actionType
          actionData {
            noop
            internal
            external
            inAppBrowser
          }
        }
      }
    }
  }
`);

export const activeVouchersQueryDocument = gql(/* GraphQL */ `
  query vouchers {
    profile {
      id
      currentEmployment {
        id
        vouchers: vouchersV2_5(
          query: {
            limit: 100
            offset: 0
            filter: { active: true, types: ["All", "Cashback"] }
          }
        ) {
          id
          createdDate
          lastModifiedDate
          minAmount
          maxAmount
          remain
          total
          maxUsageByEmployment
          fromDate
          toDate
          description
          fixedDiscount
          unlimitedDiscountAmount
          unlimitedDiscountTime
          code
          type
          icon {
            id
            uri
          }
          banner {
            id
            uri
          }
          preferenceRoute
          minApplicableAmount
          displayInfo
          usedQuantity
          isOutOfStock
          displayDescription
        }
      }
    }
  }
`);

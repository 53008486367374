export const OTP_LENGTH = 6;
export const PIN_LENGTH = 4;
export const HOTLINE = "1900866625";
export const DURATION_BETWEEN_TWO_SYNC = 1000 * 60 * 5; // 5 minutes
export const PLATFORM = "open-vui";
export const API_URI =
  import.meta.env.VITE_API_URI || "https://api.sandbox.vuiapp.vn/graphql";

export const USER_CONSENT_KEY = "USERCONSENT";

// export const API_URI =
//   import.meta.env.VITE_API_URI ||
//   "https://api.canary.vuiapp.nanofin.tech/graphql";

import { format, subSeconds } from "date-fns";
import { ShiftUnit } from "../../types/graphql-global-types";
import { twMerge } from "tailwind-merge";

type Props = {
  onClick?: () => void;
  startTime?: string;
  endTime?: string;
  sumShiftCount?: number;
  shiftCountUnit?: ShiftUnit | null;
  hideShiftCount?: boolean;
  wrapperClassName?: string;
};

function AttendanceInfo({
  onClick,
  startTime,
  endTime,
  shiftCountUnit = ShiftUnit.Day,
  sumShiftCount,
  hideShiftCount = false,
  wrapperClassName,
}: Props) {
  return (
    <div className={twMerge("w-full", wrapperClassName)}>
      <div className="flex w-full flex-row items-start justify-between bg-white px-4 py-3 lining-nums">
        <button className="lining-nums" type="button" onClick={onClick}>
          <div className="mb-1 flex flex-row items-center gap-1">
            <div className="text-base font-bold text-primaryBlack">
              Kỳ công tháng{" "}
              {startTime ? new Date(startTime || "").getMonth() + 1 : " "}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </div>
          <div className="text-left text-sm text-gray-400">
            {startTime
              ? format(new Date(startTime), "dd/MM/yyyy")
              : "" || "NaN"}{" "}
            -{" "}
            {endTime
              ? format(subSeconds(new Date(endTime), 1), "dd/MM/yyyy")
              : "" || "NaN"}
          </div>
        </button>
        {hideShiftCount ? null : (
          <div className="flex flex-row items-center justify-center gap-2 rounded-xl bg-primary-light px-3 py-2">
            <span className="text-base font-bold text-primary">
              <span>
                {sumShiftCount}{" "}
                {shiftCountUnit === ShiftUnit.Hour ? "giờ" : "ngày"}
              </span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default AttendanceInfo;

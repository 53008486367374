import React, { PropsWithChildren, useMemo } from "react";
import Cell from "./Cell";

interface IComponentProps extends PropsWithChildren {
  length?: number;
  value: string;
  children?: React.ReactNode;
}

export default function OTPInput({ value = "", length = 4 }: IComponentProps) {
  const otp = useMemo(() => {
    return value.slice(0, length);
  }, [length, value]);

  return (
    <>
      <div
        className="grid gap-2"
        style={{
          gridTemplateColumns: `repeat(${length}, minmax(0, 1fr))`,
        }}
      >
        {new Array(length).fill(0).map((_item, idx) => {
          return <Cell key={`cell_${idx}`}>{otp[idx]}</Cell>;
        })}
      </div>
    </>
  );
}

import { atom } from "recoil";

type BalanceState = {
  visible: boolean;
};

const initialState: BalanceState = {
  visible: false,
};

// a atom using recoil for state management of balance
export const balanceState = atom({
  key: "balanceState",
  default: initialState,
});

import { persistKey, storage } from "@/utils/storage";
import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: persistKey,
  storage,
});

type ResetPasswordStep =
  | "idVerification"
  | "otpVerification"
  | "passwordCreation"
  | "passwordConfirmation"
  | "success";

type ResetPasswordState = {
  flow?: string;
  step: ResetPasswordStep;
  firstThreeCharsOfSocialId?: string | null;
  socialId?: string;
  phoneNumber: string;
  requestId?: string;
  newPassword: string;
  confirmNewPassword: string;
  token?: string;
};

export const defaultRestPasswordState: ResetPasswordState = {
  flow: undefined,
  step: "idVerification",
  phoneNumber: "",
  socialId: undefined,
  firstThreeCharsOfSocialId: undefined,
  requestId: undefined,
  token: undefined,
  newPassword: "",
  confirmNewPassword: "",
};

export const resetPasswordState = atom<ResetPasswordState>({
  key: "resetPasswordState",
  default: defaultRestPasswordState,
  effects: [persistAtom],
});

import * as Sentry from "@sentry/react";
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { API_URI } from "./constants";

Sentry.init({
  dsn: "https://4763c04a9ab7f97d92f3992ea1c3ada6@o402372.ingest.sentry.io/4506389282619392",
  tracePropagationTargets: ["localhost", /^\//],
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: [API_URI],
      unmask: [".unmask-me"],
      maskAllInputs: false,
      maskAllText: false,
    }),
  ],
  environment: import.meta.env.MODE,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: import.meta.env.DEV ? 1.0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

console.log("Sentry initialized", import.meta.env.MODE);

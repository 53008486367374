import ApolloLayer from "@ApolloLayer";
import GlobalLoader from "./components/Loader/GlobalLoader";
import { RecoilRoot } from "recoil";
import Root from "./router";
import { Toaster } from "react-hot-toast";
import AppStateInitializer from "./services/AppStateInitializer";
import { useEffect } from "react";
import { analytics, writeKey } from "./services/segment";

declare global {
  interface Window {
    GRAPHQL_URL?: string;
    DEBUG_OTP?: string;
    NODE_ENV?: string;
  }
}

const App = () => {
  useEffect(() => {
    // Init segment when app start
    analytics
      .load({
        writeKey,
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    const calculateClientHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--window-height", `${doc.clientHeight}px`);
    };

    // Set initial value
    calculateClientHeight();

    window.addEventListener("resize", calculateClientHeight);

    return () => {
      window.removeEventListener("resize", calculateClientHeight);
    };
  }, []);

  return (
    <div
      id="app"
      className="relative mx-auto max-w-[480px] font-primary lining-nums h-viewport"
    >
      <RecoilRoot>
        <AppStateInitializer>
          <ApolloLayer>
            <Root />
            <GlobalLoader />
            <Toaster
              toastOptions={{
                className: ".unmask-me",
                style: {
                  padding: "16px",
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "black",
                },
              }}
            />
          </ApolloLayer>
        </AppStateInitializer>
      </RecoilRoot>
    </div>
  );
};

export default App;

import { USER_CONSENT_KEY } from "@/constants";
import { useHideBootSplash, useLocationChange } from "@/hooks";
import { RoutePath } from "@/router/path";
import { useAppState } from "@/hooks/useAppState";
import { defaultWithdrawalState, withdrawalState } from "@/state/withdrawal";
import { Navigate, Outlet } from "react-router-dom";
import { useSetRecoilState } from "recoil";

function Layout() {
  const { state } = useAppState();
  const setWithdrawalState = useSetRecoilState(withdrawalState);

  useLocationChange((_, prevLocation) => {
    if (prevLocation?.pathname === RoutePath.Withdrawal) {
      // Reset withdrawal state when leaving withdrawal page
      setWithdrawalState(defaultWithdrawalState);
    }
  });

  useHideBootSplash();

  // Redirect to ServiceIneligibilityError page if there is an error message
  if (state.errorMessage) {
    return (
      <Navigate
        to={RoutePath.ServiceIneligibilityError}
        state={{
          errorMessage: state.errorMessage,
          hideRetry: true,
        }}
      />
    );
  }

  if (!state.deviceToken) {
    // Redirect to DataSharingConsent page if onboarding flow is USERCONSENT
    if (state.onboardingFlow === USER_CONSENT_KEY) {
      return <Navigate to={RoutePath.DataSharingConsent} />;
    }

    return <Navigate to={RoutePath.Walkthrough} />;
  }
  return <Outlet />;
}

export default Layout;

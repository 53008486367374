import { persistKey, storage } from "@/utils/storage";
import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: persistKey,
  storage,
});

type PhoneNumberStep =
  | "opening"
  | "otpVerification"
  | "pinVerification"
  | "success"
  | "error";

type PhoneNumberState = {
  step: PhoneNumberStep;
  currentPhoneNumber?: string;
  phoneNumber?: string;
  createRequestId?: string;
  verifyRequestId?: string;
  error?: {
    title: string;
    message: string;
  };
};

export const defaultPhoneNumberState: PhoneNumberState = {
  step: "opening",
  currentPhoneNumber: undefined,
  phoneNumber: undefined,
  createRequestId: undefined,
  verifyRequestId: undefined,
  error: undefined,
};

export const phoneNumberState = atom<PhoneNumberState>({
  key: "phoneNumber",
  default: defaultPhoneNumberState,
  effects: [persistAtom],
});

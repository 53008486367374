/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";
import { format, subSeconds } from "date-fns";
import Sheet from "react-modal-sheet";
import { TotalWorkPeriodsQuery } from "../../../apollo/__generated__/graphql";

export type PickerRef = {
  show: () => void;
  hide: () => void;
};

type WorkPeriods = NonNullable<
  TotalWorkPeriodsQuery["profile"]["currentEmployment"]
>["workPeriods"]["nodes"];

interface IComponentProps extends PropsWithChildren {
  items?: WorkPeriods;
  onSelect?: (item: WorkPeriods[number]) => void;
  activeWorkPeriod?: WorkPeriods[number];
}

export default forwardRef<PickerRef, IComponentProps>(function WorkPeriodPicker(
  { items, onSelect, activeWorkPeriod }: IComponentProps,
  ref,
) {
  const [sheetVisible, setSheetVisible] = useState<boolean>(false);
  const sheetRef = useRef<any>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleSelect = useCallback((item: WorkPeriods[number]) => {
    onSelect && onSelect(item);
    setSheetVisible(false);
  }, []);

  const formatString = useCallback((item: WorkPeriods[number]) => {
    const startTimeObj = new Date(item.startTime);
    const endTimeObj = subSeconds(new Date(item.endTimeInclusive), 1); // minus 1 second to make it inclusive
    return `Tháng ${format(startTimeObj, "M/yyyy")} (${format(
      startTimeObj,
      "dd/MM",
    )} - ${format(endTimeObj, "dd/MM")})`;
  }, []);

  useEffect(() => {
    if (sheetVisible) {
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
    }
  }, [sheetVisible]);

  useImperativeHandle(
    ref,
    () => {
      return {
        show() {
          {
            setSheetVisible(true);
          }
        },
        hide() {
          sheetRef.current &&
            sheetRef.current?.snapTo &&
            sheetRef.current?.snapTo(0);
        },
      };
    },
    [],
  );

  useEffect(() => {
    return () => {
      if (document.body.classList.contains("overflow-y-hidden")) {
        document.body.classList.remove("overflow-y-hidden");
      }
    };
  }, []);

  return (
    <Sheet
      isOpen={sheetVisible}
      onClose={() => setSheetVisible(false)}
      ref={sheetRef}
    >
      <Sheet.Container>
        <Sheet.Content>
          <Sheet.Scroller>
            <div className="sticky top-0 flex items-center justify-between rounded-t-2xl border-b bg-white p-3 font-primary lining-nums">
              <button
                onClick={() => setSheetVisible(false)}
                className="h-8 w-8"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <strong className="text-[17px] leading-6">Chọn kỳ công</strong>
              </div>
              <div className="h-8 w-8" />
            </div>

            <div
              className="overflow-y-auto pb-20 font-primary lining-nums"
              ref={scrollRef}
            >
              <div className="px-6">
                <div className="divide-y divide-[#f2f4f7]">
                  {items && items.length > 0 ? (
                    <>
                      {items.map((item) => {
                        const selected = activeWorkPeriod?.id === item.id;
                        return (
                          <div
                            key={`row_${item.id}`}
                            role={"button"}
                            onClick={() => handleSelect(item)}
                            className="flex w-full flex-row items-center justify-between py-3"
                          >
                            <div className="flex flex-1 flex-col">
                              <h3
                                className={twMerge(
                                  " text-[15px] leading-[22px] tracking-normal",
                                  selected
                                    ? "font-bold text-primary"
                                    : "font-medium text-[#4D4D4D]",
                                )}
                              >
                                {formatString(item)}
                              </h3>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop className="bg-transparent" />
    </Sheet>
  );
});

import { gql } from "../__generated__";
export const getPhoneNumberQueryDocument = gql(/* GraphQL */ `
  query getPhoneNumber {
    profile {
      id
      phoneNumber
      currentEmployment {
        id
        phoneNumber
      }
    }
  }
`);

import { gql } from "../__generated__";

export const generateAgreementMutationDocument = gql(/* GraphQL */ `
  mutation generateEmploymentAgreement($employmentId: ID!, $qualifier: String) {
    generateEmploymentAgreement(
      employmentId: $employmentId
      qualifier: $qualifier
    ) {
      agreement {
        id
        createdDate
        lastModifiedDate
        status
        agreementFile {
          id
          uri
        }
        agreementHtmlFile {
          id
          uri
        }
      }
    }
  }
`);

export const requestOTPMutationDocument = gql(/* GraphQL */ `
  mutation requestOTP($otpLength: Int) {
    sendUserOtpV2(otpLength: $otpLength) {
      debug_otp
    }
  }
`);

export const signToCMutationDocument = gql(/* GraphQL */ `
  mutation signAgreement($payload: EmploymentSignOtpPayload) {
    signAgreement(payload: $payload)
  }
`);

export const agreeToCMutationDocument = gql(/* GraphQL */ `
  mutation agreeTermAndCondition($employmentId: ID!) {
    agreeTermAndCondition(employmentId: $employmentId) {
      agreement {
        id
        status
      }
    }
  }
`);

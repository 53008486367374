import React, { Suspense, lazy } from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { RoutePath } from "@/router/path";
import SimpleLoading from "../Loader/SimpleLoading";
import ArrowLeftIcon from "@/assets/icons/arrow-left.svg?react";

const InfoIcon = lazy(() => import("@/assets/icons/info.svg?react"));

type Props = {
  theme?: "light" | "dark";
  title?: string;
  hideLeftButton?: boolean;
  onLeftButtonPress?: () => void;
  hideRightButton?: boolean;
  onRightButtonPress?: () => void;
  wrapperClassName?: React.HTMLAttributes<HTMLDivElement>["className"];
  fallBackPath?: RoutePath;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
};

function PageHeader({
  theme = "light",
  title = "Bữa trưa Vui vẻ",
  onLeftButtonPress = undefined,

  hideLeftButton = false,
  hideRightButton = true,
  onRightButtonPress = () => null,
  wrapperClassName,
  fallBackPath = RoutePath.Home,
  leftElement = null,
  rightElement = null,
}: Props) {
  const navigate = useNavigate();

  const handlePressBack = () => {
    try {
      if (onLeftButtonPress) {
        onLeftButtonPress();
      } else {
        // TODO: check if the current entry in the history stack is the first entry -> not working now
        if (window.history.state && window.history.state.idx > 0) {
          navigate(-1);
        } else {
          navigate(fallBackPath, { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
        }
      }
    } catch (error) {
      navigate(fallBackPath, { replace: true });
    }
  };

  return (
    <div
      className={twMerge(
        "flex w-full flex-row items-center justify-between px-3 py-2 sticky top-0 z-10 bg-white",
        theme === "light" ? "text-primaryBlack" : "text-white",
        wrapperClassName
      )}
    >
      {leftElement !== null ? (
        leftElement
      ) : (
        <div className="h-8 w-8">
          {hideLeftButton ? null : (
            <button
              onClick={handlePressBack}
              className="flex h-8 w-8 items-center justify-center"
            >
              <Suspense fallback={<SimpleLoading text="" />}>
                <ArrowLeftIcon />
              </Suspense>
            </button>
          )}
        </div>
      )}
      <div className="flex-1">
        <div className="flex flex-row items-center justify-center gap-2">
          <p className=" text-base font-bold">{title}</p>
        </div>
      </div>

      {rightElement !== null ? (
        rightElement
      ) : (
        <div className="h-8 w-8">
          {hideRightButton ? null : (
            <button
              onClick={onRightButtonPress}
              className="flex h-8 w-8 items-center justify-center"
            >
              <Suspense fallback={<SimpleLoading text="" />}>
                <InfoIcon />
              </Suspense>
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default PageHeader;

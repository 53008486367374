import { useRouteError } from "react-router-dom";
import PageContainer from "../layouts/PageContainer";
import { graphqlErrorParser } from "@/helpers";

export function RootErrorBoundary() {
  const error = useRouteError() as Error;
  return (
    <PageContainer>
      <h1>Uh oh, something went terribly wrong 😩</h1>
      <pre>{graphqlErrorParser(error)}</pre>
      <button onClick={() => (window.location.href = "/")}>
        Click here to reload the app
      </button>
    </PageContainer>
  );
}

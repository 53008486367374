/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** represent currency code */
  Currency: { input: any; output: any };
  /** represent date */
  Date: { input: any; output: any };
  /** represent decimal value */
  Decimal: { input: any; output: any };
  /** represent a time-based amount of time, mapped to java.time.Duration */
  Duration: { input: any; output: any };
  /** represent email address */
  Email: { input: any; output: any };
  I18nMessage: { input: any; output: any };
  Instant: { input: any; output: any };
  JSON: { input: any; output: any };
  LocalDate: { input: any; output: any };
  LocalTime: { input: any; output: any };
  /** Long type */
  Long: { input: any; output: any };
  /** represent monetary value */
  Money: { input: any; output: any };
  /** represent a date-time with a time-zone in the ISO-8601 calendar system, mapped to java.time.OffsetDateTime */
  OffsetDateTime: { input: any; output: any };
  PhoneNumber: { input: any; output: any };
  /** represent fully-qualified uri */
  URI: { input: any; output: any };
  /** represent nothing state, useful when define return type of delete operations */
  Void: { input: any; output: any };
};

export enum ActionName {
  Close = "Close",
  Create = "Create",
  Disburse = "Disburse",
}

export type ActionResponse = {
  actionMaker?: Maybe<Scalars["String"]["output"]>;
  actionName?: Maybe<ActionName>;
  actionTime?: Maybe<Scalars["Instant"]["output"]>;
};

export enum ActiveStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type AdminUser = {
  accountId: Scalars["String"]["output"];
  dob?: Maybe<Scalars["LocalDate"]["output"]>;
  full_name?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  role?: Maybe<RoleEntity>;
  user_name?: Maybe<Scalars["String"]["output"]>;
};

export type AdminUserPayload = {
  dob?: InputMaybe<Scalars["LocalDate"]["input"]>;
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  passWord: Scalars["String"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
  userName: Scalars["String"]["input"];
};

export type AdvanceFee = {
  employerFee: Scalars["Money"]["output"];
  userFee: Scalars["Money"]["output"];
};

export type AdvanceMoneySourceDetail = {
  advanceV3ResponseDetails: Array<AdvanceV3ResponseDetail>;
  cashbackResponseDetails?: Maybe<Array<CashbackResponseDetail>>;
  cashbackVoucherResponseDetails?: Maybe<Array<CashbackVoucherResponseDetail>>;
};

export type AdvanceV3ResponseDetail = {
  amount: Scalars["Money"]["output"];
  fee: AdvanceFee;
  workPeriodEntry?: Maybe<EmploymentWorkPeriod>;
};

export type Advertiser = {
  id?: Maybe<Scalars["ID"]["output"]>;
  logo?: Maybe<StorageObject>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type AmountLimit = {
  limitAmountPerTransaction?: Maybe<Scalars["Money"]["output"]>;
  limitTransactionAmountPerDay?: Maybe<Scalars["Money"]["output"]>;
  limitTransactionAmountPerPeriod?: Maybe<Scalars["Money"]["output"]>;
  limitTransactionPerDay?: Maybe<Scalars["Int"]["output"]>;
  limitTransactionPerPeriod?: Maybe<Scalars["Int"]["output"]>;
  minAmountPerTransaction?: Maybe<Scalars["Money"]["output"]>;
};

export type AmountPerWorkPeriodResponse = {
  fee?: Maybe<Scalars["Money"]["output"]>;
  firstWorkPeriodAmount?: Maybe<Scalars["Money"]["output"]>;
  payLaterWorkPeriods?: Maybe<Array<Maybe<PayLaterWorkPeriod>>>;
  totalAmount?: Maybe<Scalars["Money"]["output"]>;
  workPeriodAmount?: Maybe<Scalars["Money"]["output"]>;
};

export type AmountPolicy = {
  amountLimit?: Maybe<AmountLimit>;
  code?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type AnnamMigrationEmployeeFile = {
  storageObjectId: Scalars["String"]["input"];
};

export type AnnamPushAdvanceFile = {
  storageObjectId?: InputMaybe<Scalars["String"]["input"]>;
};

export type AppReview = {
  emoji: AppReviewEmoji;
  employmentId: Scalars["ID"]["output"];
  id: Scalars["ID"]["output"];
  review?: Maybe<Scalars["String"]["output"]>;
  selectOptions?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export enum AppReviewEmoji {
  Happy = "Happy",
  Unhappy = "Unhappy",
}

export type AppReviewPayload = {
  emoji: AppReviewEmoji;
  employmentId: Scalars["ID"]["input"];
  review?: InputMaybe<Scalars["String"]["input"]>;
  selectedOptions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export enum AppReviewStatus {
  Disable = "Disable",
  Enable = "Enable",
  Rated = "Rated",
}

export type ApplicationException = {
  applicationCode?: Maybe<Scalars["String"]["output"]>;
  code: Scalars["Int"]["output"];
  data?: Maybe<Scalars["JSON"]["output"]>;
  message: Scalars["String"]["output"];
};

export type ApplyVoucherLoanApplicationResponse = {
  oldActuallyReceived?: Maybe<Scalars["Money"]["output"]>;
  oldFee?: Maybe<Scalars["Money"]["output"]>;
  voucher?: Maybe<Voucher>;
};

export type AppraisalInformationResponse = {
  changePhoneHistory?: Maybe<Array<Maybe<ChanePhoneResponse>>>;
  isSharePhoneDirectory?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AppraisalResponse = {
  description?: Maybe<Scalars["String"]["output"]>;
};

export enum AppraisalType {
  Both = "Both",
  ByRelatives = "ByRelatives",
  ByUser = "ByUser",
}

export type AssignEmploymentToGroupPayload = {
  employmentId: Scalars["ID"]["input"];
  groupId: Scalars["ID"]["input"];
};

export type AssignRolePayload = {
  roles?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  userId: Scalars["String"]["input"];
};

export type AstNode = {
  condition?: InputMaybe<EmploymentConditionNode>;
  pathNode?: InputMaybe<PathNode>;
  valueNode?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type Attendance = {
  checkinSecond?: Maybe<Scalars["Int"]["output"]>;
  checkoutSecond?: Maybe<Scalars["Int"]["output"]>;
  createdDate: Scalars["Instant"]["output"];
  date: Scalars["Date"]["output"];
  earnedBalance: Scalars["Money"]["output"];
  employment?: Maybe<Employment>;
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  overTimeRatePerHour?: Maybe<Scalars["Money"]["output"]>;
  overtimeHours?: Maybe<Scalars["Float"]["output"]>;
  shiftCount: Scalars["Decimal"]["output"];
  shiftWork: ShiftWorkType;
  status: AttendanceStatus;
  submittedAt?: Maybe<Scalars["Instant"]["output"]>;
  times: Array<Maybe<Scalars["LocalTime"]["output"]>>;
  type: AttendanceType;
  vendorType?: Maybe<Scalars["String"]["output"]>;
  workingDayType: WorkingDayType;
  workingTimeHours: Scalars["Float"]["output"];
  workingTimeRatePerHour: Scalars["Money"]["output"];
};

export type AttendanceAutomationConfig = {
  attendanceDateFromNow?: Maybe<Scalars["Int"]["output"]>;
  createdDate: Scalars["Instant"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  effectiveEndDate?: Maybe<Scalars["Instant"]["output"]>;
  effectiveStartDate: Scalars["Instant"]["output"];
  employmentSegment?: Maybe<EmploymentSegmentResponse>;
  id: Scalars["ID"]["output"];
  invalidDayOfWeek?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  lastModifiedDate: Scalars["Instant"]["output"];
  linkEmploymentFlow?: Maybe<AutoAttendanceLinkEmploymentFlow>;
  name: Scalars["String"]["output"];
  shiftCountAdded: Scalars["Decimal"]["output"];
  status: CreditConfigStatus;
  tenantId: Scalars["ID"]["output"];
};

export enum AttendanceAutomationConfigOrderable {
  CreatedDate = "createdDate",
  Id = "id",
  LastModifiedDate = "lastModifiedDate",
}

export type AttendanceAutomationConfigPayload = {
  attendanceDateFromNow?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  effectiveEndDate?: InputMaybe<Scalars["Instant"]["input"]>;
  effectiveStartDate: Scalars["Instant"]["input"];
  employmentSegment?: InputMaybe<EmploymentSegment>;
  invalidDayOfWeek?: InputMaybe<Array<Scalars["String"]["input"]>>;
  linkEmploymentFlow?: InputMaybe<AutoAttendanceLinkEmploymentFlow>;
  name: Scalars["String"]["input"];
  shiftCountAdded: Scalars["Decimal"]["input"];
  status: CreditConfigStatus;
  tenantId: Scalars["ID"]["input"];
};

export type AttendanceAutomationConfigQuery = {
  filter?: InputMaybe<AttendanceAutomationConfigQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AttendanceAutomationConfigOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type AttendanceAutomationConfigQueryFilter = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  isEffective?: InputMaybe<Scalars["Boolean"]["input"]>;
  linkEmploymentFlows?: InputMaybe<
    Array<InputMaybe<AutoAttendanceLinkEmploymentFlow>>
  >;
  name?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<CreditConfigStatus>;
  tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AttendanceAutomationConfigResponse = {
  limit?: Maybe<Scalars["Int"]["output"]>;
  nodes: Array<Maybe<AttendanceAutomationConfig>>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type AttendanceAutomationConfigUpdatePayload = {
  attendanceDateFromNow?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  effectiveEndDate?: InputMaybe<Scalars["Instant"]["input"]>;
  effectiveStartDate?: InputMaybe<Scalars["Instant"]["input"]>;
  employmentSegment?: InputMaybe<EmploymentSegment>;
  id: Scalars["ID"]["input"];
  invalidDayOfWeek?: InputMaybe<Array<Scalars["String"]["input"]>>;
  linkEmploymentFlow?: InputMaybe<AutoAttendanceLinkEmploymentFlow>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  shiftCountAdded?: InputMaybe<Scalars["Decimal"]["input"]>;
  status?: InputMaybe<CreditConfigStatus>;
};

export type AttendanceConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Attendance>;
  offset: Scalars["Int"]["output"];
  sumShiftCount: Scalars["Decimal"]["output"];
  total: Scalars["Int"]["output"];
};

export enum AttendanceOrderable {
  CreatedDate = "createdDate",
  Id = "id",
  LastModifiedDate = "lastModifiedDate",
  Status = "status",
}

export type AttendanceQuery = {
  filter?: InputMaybe<AttendanceQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AttendanceOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type AttendanceQueryFilter = {
  companyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  createdAt?: InputMaybe<OffsetDateTimeRange>;
  dateRange?: InputMaybe<LocalDateRange>;
  employmentEmployeeIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  employmentIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  notTypes?: InputMaybe<Array<AttendanceType>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  shiftWorkTypes?: InputMaybe<Array<ShiftWorkType>>;
  statuses?: InputMaybe<Array<AttendanceStatus>>;
  submittedAt?: InputMaybe<OffsetDateTimeRange>;
  types?: InputMaybe<Array<AttendanceType>>;
};

export enum AttendanceStatus {
  Approved = "Approved",
  Canceled = "Canceled",
  Draft = "Draft",
  Rejected = "Rejected",
}

export enum AttendanceType {
  HalfTime = "HalfTime",
  PaidAbsent = "PaidAbsent",
  RecordMissing = "RecordMissing",
  Standard = "Standard",
  Unknown = "Unknown",
  UnpaidAbsent = "UnpaidAbsent",
}

export type AttendanceWarning = {
  count?: Maybe<Scalars["Int"]["output"]>;
  date?: Maybe<Scalars["LocalDate"]["output"]>;
  workplaceName?: Maybe<Scalars["String"]["output"]>;
};

export type AttendanceWarningQueryFilter = {
  companyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  dateRange?: InputMaybe<LocalDateRange>;
  workplaceIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type AttributeColumn = {
  extensions?: Maybe<Array<AttributeColumnExtension>>;
  id: AttributeId;
  required: Scalars["Boolean"]["output"];
  schema: AttributeSchema;
  title: Scalars["String"]["output"];
  unique: Scalars["Boolean"]["output"];
  valueMapping?: Maybe<Array<AttributeColumnValueMapping>>;
};

export type AttributeColumnExtension = {
  key: Scalars["String"]["output"];
  value?: Maybe<Scalars["JSON"]["output"]>;
};

export type AttributeColumnPayload = {
  extensions?: InputMaybe<Array<AttributeColumnPayloadExtension>>;
  id: AttributeIdPayload;
  title: Scalars["String"]["input"];
};

export type AttributeColumnPayloadExtension = {
  key: Scalars["String"]["input"];
  value?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type AttributeColumnValueMapping = {
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type AttributeId = {
  group: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type AttributeIdPayload = {
  group: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type AttributeResponse = {
  name?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<PayslipAttributeType>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type AttributeSchema = {
  enum?: Maybe<Array<Scalars["String"]["output"]>>;
  type: AttributeType;
};

export enum AttributeType {
  BankAccount = "BankAccount",
  Boolean = "Boolean",
  Decimal = "Decimal",
  Email = "Email",
  Float = "Float",
  Instant = "Instant",
  Int = "Int",
  LocalDate = "LocalDate",
  LocalDateTime = "LocalDateTime",
  LocalTime = "LocalTime",
  Long = "Long",
  Money = "Money",
  PhoneNumber = "PhoneNumber",
  SocialIdNumber = "SocialIdNumber",
  String = "String",
  Uri = "URI",
  ZonedDateTime = "ZonedDateTime",
}

export type AttributeValue = {
  id: AttributeId;
  value: Scalars["String"]["output"];
};

export type AttributeValuePayload = {
  id: AttributeIdPayload;
  value: Scalars["String"]["input"];
};

export type AuditLog = {
  action: Scalars["String"]["output"];
  arguments?: Maybe<Scalars["JSON"]["output"]>;
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lanchi_processAttendanceFile?: Maybe<Lanchi_AuditLogProcessAttendanceFile>;
  lanchi_processAttendanceFileV2?: Maybe<Lanchi_AuditLogProcessAttendanceFile>;
  lanchi_processAttendanceFileV3?: Maybe<Lanchi_AuditLogProcessAttendanceFile>;
  lastModifiedDate: Scalars["Instant"]["output"];
  principal?: Maybe<AuditPrincipal>;
  processAttendanceFile?: Maybe<AuditLogProcessAttendanceFile>;
  processAttendanceFileV2?: Maybe<AuditLogProcessAttendanceFileV2>;
  processEmploymentFile?: Maybe<AuditLogProcessEmploymentFile>;
  processEmploymentFileV2?: Maybe<AuditLogProcessEmploymentFileV2>;
};

export type AuditLogConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<AuditLog>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export enum AuditLogOrderable {
  CreatedDate = "createdDate",
  Id = "id",
}

export type AuditLogProcessAttendanceFile = {
  company?: Maybe<Company>;
  storageObject?: Maybe<StorageObject>;
};

export type AuditLogProcessAttendanceFileV2 = {
  payload: AuditLogProcessAttendanceFileV2Payload;
};

export type AuditLogProcessAttendanceFileV2Payload = {
  group?: Maybe<CompanyEmploymentGroup>;
  storageObject?: Maybe<StorageObject>;
};

export type AuditLogProcessEmploymentFile = {
  company?: Maybe<Company>;
  storageObject?: Maybe<StorageObject>;
};

export type AuditLogProcessEmploymentFileV2 = {
  payload: AuditLogProcessEmploymentFileV2Payload;
};

export type AuditLogProcessEmploymentFileV2Payload = {
  group?: Maybe<CompanyEmploymentGroup>;
  storageObject?: Maybe<StorageObject>;
};

export type AuditLogQuery = {
  filter?: InputMaybe<AuditLogQueryFilter>;
  filters?: InputMaybe<Array<AuditLogQueryFilter>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AuditLogOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type AuditLogQueryFilter = {
  actions?: InputMaybe<Array<Scalars["String"]["input"]>>;
  companyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  notActions?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type AuditPrincipal = {
  subject: Scalars["ID"]["output"];
  username?: Maybe<Scalars["String"]["output"]>;
};

export type AuthResponse = {
  accessToken: Scalars["String"]["output"];
  expiresIn: Scalars["Duration"]["output"];
  refreshExpiresIn?: Maybe<Scalars["Duration"]["output"]>;
  refreshToken?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated No longer supported */
  user?: Maybe<User>;
};

export enum AutoAttendanceLinkEmploymentFlow {
  LinkDate = "LinkDate",
  PeriodToLinkDate = "PeriodToLinkDate",
}

export type AutoAttendancePayload = {
  companyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type BffResponse = {
  body: Body;
  header: Header;
  metadata: Metadata;
};

export type BalanceModification = {
  change: WithdrawableBalanceExplanation_Change;
  explanation: WithdrawableBalanceExplanation;
};

export type Bank = {
  acb247BankCode?: Maybe<Scalars["String"]["output"]>;
  acb247BankName?: Maybe<Scalars["String"]["output"]>;
  acbBankCode?: Maybe<Scalars["String"]["output"]>;
  acbBankName?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated use vpbankBankName */
  alias?: Maybe<Scalars["String"]["output"]>;
  code: Scalars["String"]["output"];
  fullName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  logo?: Maybe<StorageObject>;
  name: Scalars["String"]["output"];
  shortName: Scalars["String"]["output"];
  slug: Scalars["String"]["output"];
  /** @deprecated use vpbankBankCode */
  transferCode?: Maybe<Scalars["String"]["output"]>;
  vietqrBankCodes?: Maybe<Array<Scalars["String"]["output"]>>;
  vpbankBankCode?: Maybe<Scalars["String"]["output"]>;
  vpbankBankName?: Maybe<Scalars["String"]["output"]>;
};

export type BankConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Bank>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type BankProviders = {
  account: Scalars["String"]["output"];
  balance: Scalars["Money"]["output"];
  code: Scalars["String"]["output"];
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  minThreshold?: Maybe<Scalars["Money"]["output"]>;
  name: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
  tenantId: Scalars["ID"]["output"];
};

export type BankProvidersPayload = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  minThreshold?: InputMaybe<Scalars["Int"]["input"]>;
};

export type BankQuery = {
  filter?: InputMaybe<BankQueryFilter>;
  filters?: InputMaybe<Array<BankQueryFilter>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type BankQueryFilter = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  slugs?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type Banner = {
  action: BannerAction;
  associations: BannerAssociationConnection;
  content: BannerContent;
  contentImage?: Maybe<StorageObject>;
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  status: BannerStatus;
  title: Scalars["String"]["output"];
  type: BannerType;
};

export type BannerAssociationsArgs = {
  query?: BannerAssociationQuery;
};

export type BannerAction = {
  client?: Maybe<BannerActionClient>;
  external?: Maybe<BannerActionExternal>;
  noop?: Maybe<BannerActionNoop>;
  popup?: Maybe<BannerActionPopup>;
};

export type BannerActionClient = {
  name: Scalars["String"]["output"];
};

export type BannerActionClientPayload = {
  name: Scalars["String"]["input"];
};

export type BannerActionExternal = {
  uri: Scalars["URI"]["output"];
};

export type BannerActionExternalPayload = {
  uri: Scalars["URI"]["input"];
};

export type BannerActionNoop = {
  noop?: Maybe<Scalars["Void"]["output"]>;
};

export type BannerActionNoopPayload = {
  noop?: InputMaybe<Scalars["Void"]["input"]>;
};

export type BannerActionPayload = {
  client?: InputMaybe<BannerActionClientPayload>;
  external?: InputMaybe<BannerActionExternalPayload>;
  noop?: InputMaybe<BannerActionNoopPayload>;
  popup?: InputMaybe<BannerActionPopupPayload>;
};

export type BannerActionPopup = {
  content: BannerContent;
};

export type BannerActionPopupPayload = {
  content: BannerContentPayload;
};

export type BannerAssociation = {
  banner: Banner;
  company: Company;
  createdDate: Scalars["Instant"]["output"];
  description: Scalars["String"]["output"];
  employmentFilter: BannerAssociationEmploymentFilter;
  endTime: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  startTime: Scalars["Instant"]["output"];
  status: BannerAssociationStatus;
};

export type BannerAssociationConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<BannerAssociation>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type BannerAssociationEmploymentFilter = {
  employments?: Maybe<Array<Employment>>;
  groups?: Maybe<Array<CompanyEmploymentGroup>>;
};

export type BannerAssociationEmploymentFilterPayload = {
  employmentIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  groupIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export enum BannerAssociationOrderable {
  CreatedDate = "createdDate",
  Id = "id",
}

export type BannerAssociationQuery = {
  filter?: BannerAssociationQueryFilter;
  limit?: Scalars["Int"]["input"];
  offset?: Scalars["Int"]["input"];
  orderBy?: BannerAssociationOrderable;
  orderDirection?: OrderDirection;
};

export type BannerAssociationQueryFilter = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type BannerAssociationResponse = {
  bannerAssociation: BannerAssociation;
  bannerAssociations?: Maybe<Array<BannerAssociation>>;
};

export enum BannerAssociationStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type BannerConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Banner>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type BannerContent = {
  html?: Maybe<BannerContentHtml>;
  svg?: Maybe<BannerContentSvg>;
};

export type BannerContentHtml = {
  content: Scalars["String"]["output"];
};

export type BannerContentHtmlPayload = {
  content: Scalars["String"]["input"];
};

export type BannerContentPayload = {
  html?: InputMaybe<BannerContentHtmlPayload>;
  svg?: InputMaybe<BannerContentSvgPayload>;
};

export type BannerContentSvg = {
  content: Scalars["String"]["output"];
};

export type BannerContentSvgPayload = {
  content: Scalars["String"]["input"];
};

export type BannerIdFilter = {
  and?: InputMaybe<Array<BannerIdFilter>>;
  eq?: InputMaybe<Scalars["ID"]["input"]>;
  in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  not?: InputMaybe<BannerIdFilter>;
  or?: InputMaybe<Array<BannerIdFilter>>;
};

export enum BannerOrderable {
  CreatedDate = "createdDate",
  Id = "id",
}

export type BannerQuery = {
  filter?: BannerQueryFilter;
  limit?: Scalars["Int"]["input"];
  offset?: Scalars["Int"]["input"];
  orderBy?: BannerOrderable;
  orderDirection?: OrderDirection;
};

export type BannerQueryFilter = {
  and?: InputMaybe<Array<BannerQueryFilter>>;
  id?: InputMaybe<BannerIdFilter>;
  not?: InputMaybe<BannerQueryFilter>;
  or?: InputMaybe<Array<BannerQueryFilter>>;
};

export enum BannerStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export enum BannerType {
  Image = "Image",
  Svg = "Svg",
}

export type BatchDisbursementPayload = {
  amount?: InputMaybe<Scalars["Money"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  disbursementDate?: InputMaybe<Scalars["Instant"]["input"]>;
  externalTransactionId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Beneficiary = {
  beneficiaryCode: Scalars["String"]["output"];
  beneficiaryId: Scalars["String"]["output"];
};

export type BillDetail = {
  amount: Scalars["Money"]["output"];
  from?: Maybe<Scalars["LocalDate"]["output"]>;
  fromStringValue?: Maybe<Scalars["String"]["output"]>;
  to?: Maybe<Scalars["LocalDate"]["output"]>;
  toStringValue?: Maybe<Scalars["String"]["output"]>;
};

export type BillDetailStatus = {
  billType: BillType;
  status: Scalars["Boolean"]["output"];
};

export type BillResponseDetail = {
  amount: Scalars["Money"]["output"];
  billingCode: Scalars["String"]["output"];
  customerInfo: CustomerInfo;
  details?: Maybe<Array<BillDetail>>;
  minAmount: Scalars["Money"]["output"];
  referenceCode: Scalars["String"]["output"];
  serviceCode: Scalars["String"]["output"];
};

export enum BillType {
  Electricity = "Electricity",
  Finance = "Finance",
  Internet = "Internet",
  Other = "Other",
  Television = "Television",
  Water = "Water",
}

export type BillingConfigV2 = {
  details?: Maybe<Array<BillDetailStatus>>;
  isUsable: Scalars["Boolean"]["output"];
};

export type Body = {
  layoutType: Scalars["String"]["output"];
  section: Array<Section>;
};

export type CalculateAdvanceV3Extension = {
  cashbackAmount?: InputMaybe<Scalars["Money"]["input"]>;
  cashbackVoucherId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CalculateAdvanceV3Payload = {
  entries: Array<CalculateLoanFeeV2PayloadEntry>;
  extension?: InputMaybe<CalculateAdvanceV3Extension>;
};

export type CalculateAdvanceV3Response = {
  amount: Scalars["Money"]["output"];
  detail: AdvanceMoneySourceDetail;
  employeeFeeBeforePromotion: Scalars["Money"]["output"];
  employeeReceiveAmount: Scalars["Money"]["output"];
  fee: AdvanceFee;
};

export type CalculateLoanFeePayload = {
  amount: Scalars["Money"]["input"];
  employmentId: Scalars["ID"]["input"];
  userSelection?: InputMaybe<Scalars["Boolean"]["input"]>;
  voucherId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CalculateLoanFeeResponse = {
  actuallyReceived: Scalars["Money"]["output"];
  amount: Scalars["Money"]["output"];
  fee: Scalars["Money"]["output"];
  feeBeforePromotion?: Maybe<Scalars["Money"]["output"]>;
  voucherAppliedResponse?: Maybe<ApplyVoucherLoanApplicationResponse>;
};

export type CalculateLoanFeeV2Payload = {
  employmentId: Scalars["ID"]["input"];
  entries: Array<CalculateLoanFeeV2PayloadEntry>;
  voucherId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CalculateLoanFeeV2PayloadEntry = {
  amount: Scalars["Money"]["input"];
  workPeriodId: Scalars["ID"]["input"];
};

export type CalculateLoanFeeV2Response = {
  entries: Array<CalculateLoanFeeV2ResponseEntry>;
};

export type CalculateLoanFeeV2ResponseEntry = {
  employerFee: Scalars["Money"]["output"];
  employerFeeBeforePromotion: Scalars["Money"]["output"];
  fee: Scalars["Money"]["output"];
  feeBeforePromotion: Scalars["Money"]["output"];
  workPeriod: EmploymentWorkPeriod;
};

export type CalculateLoanFeeV2WithAutoApplyVoucherPayload = {
  employmentId: Scalars["ID"]["input"];
  entries: Array<CalculateLoanFeeV2PayloadEntry>;
};

export type CalculateLoanFeeV2WithAutoApplyVoucherResponse = {
  entries: Array<CalculateLoanFeeV2ResponseEntry>;
  voucher?: Maybe<Voucher>;
};

export type CalculateOrderCashbackAmountResponse = {
  amount: Scalars["Money"]["output"];
  type: Scalars["String"]["output"];
};

export type CalculateOrderEwaExplanationResponse = {
  actualReceiveAmount: Scalars["Money"]["output"];
  amount: Scalars["Money"]["output"];
  employerFee: Scalars["Money"]["output"];
  fee: Scalars["Money"]["output"];
  totalEmployeePay: Scalars["Money"]["output"];
  type: Scalars["String"]["output"];
  workPeriod: EmploymentWorkPeriod;
};

export type CalculateOrderPaymentUnionPayload = {
  cashback?: InputMaybe<Array<CalculateOrderPriceCashbackPayload>>;
  ewa?: InputMaybe<Array<CalculateOrderPriceEwaPayload>>;
};

export type CalculateOrderPriceCashbackPayload = {
  amount: Scalars["Money"]["input"];
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type CalculateOrderPriceEwaPayload = {
  actualReceiveAmount: Scalars["Money"]["input"];
  type?: InputMaybe<Scalars["String"]["input"]>;
  workPeriodEntryId: Scalars["ID"]["input"];
};

export type CalculateOrderPriceRepresentationResponse = {
  actualPrice: Scalars["Money"]["output"];
  originPrice: Scalars["Money"]["output"];
  paidPrice?: Maybe<PaidPriceUnion>;
};

export type CalculateTransferPayload = {
  calculationContext?: InputMaybe<Transfer_CalculationContext>;
  employmentId: Scalars["ID"]["input"];
  entries: Array<CalculateLoanFeeV2PayloadEntry>;
  extension?: InputMaybe<CalculateAdvanceV3Extension>;
};

export type CalculateTransferResponse = {
  amount: Scalars["Money"]["output"];
  detail: TransferMoneySourceDetail;
  fee: AdvanceFee;
  feeBeforePromotion: Scalars["Money"]["output"];
  transferAmount: Scalars["Money"]["output"];
};

export type CallLogPayLoad = {
  borrowHistoryNote?: InputMaybe<Scalars["String"]["input"]>;
  callStatus: CallStatus;
  installmentLoanId: Scalars["ID"]["input"];
  kycNote?: InputMaybe<Scalars["String"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  ownerStatus: OwnerStatus;
  purposeUse: Scalars["String"]["input"];
  reasonReject?: InputMaybe<Scalars["String"]["input"]>;
  referral?: InputMaybe<Scalars["String"]["input"]>;
};

export type CallLogResponse = {
  borrowHistoryNote?: Maybe<Scalars["String"]["output"]>;
  callStatus?: Maybe<CallStatus>;
  createdDate?: Maybe<Scalars["Instant"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  installmentLoanId?: Maybe<Scalars["ID"]["output"]>;
  kycNote?: Maybe<Scalars["String"]["output"]>;
  logBy?: Maybe<Scalars["String"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  ownerStatus?: Maybe<OwnerStatus>;
  purposeUse?: Maybe<Scalars["String"]["output"]>;
  reasonReject?: Maybe<Scalars["String"]["output"]>;
  referral?: Maybe<Scalars["String"]["output"]>;
};

export enum CallStatus {
  Busy = "Busy",
  CallLater = "CallLater",
  Called = "Called",
  NoPickup1 = "NoPickup1",
  NoPickup2 = "NoPickup2",
  NoPickup3 = "NoPickup3",
}

export type Campaign = {
  code: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  status: CampaignStatus;
  steps?: Maybe<Array<CampaignStep>>;
  totalStep?: Maybe<Scalars["Int"]["output"]>;
};

export type CampaignFilter = {
  applicationLayerInProgressFilter?: InputMaybe<Scalars["Boolean"]["input"]>;
  available?: InputMaybe<Scalars["Boolean"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  status?: InputMaybe<CampaignStatus>;
  tenantIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type CampaignProgress = {
  campaignId: Scalars["ID"]["output"];
  currentStep: Scalars["String"]["output"];
  employmentId: Scalars["ID"]["output"];
  id: Scalars["ID"]["output"];
  status: CampaignProgressStatus;
};

export enum CampaignProgressStatus {
  Cancelled = "Cancelled",
  Completed = "Completed",
  Pending = "Pending",
}

export type CampaignQuery = {
  all?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<CampaignFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum CampaignStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type CampaignStep = {
  id: Scalars["ID"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
  reward?: Maybe<CampaignStepReward>;
  step: Scalars["Int"]["output"];
  subStep?: Maybe<Scalars["Int"]["output"]>;
  successMessage?: Maybe<Scalars["String"]["output"]>;
};

export type CampaignStepCashbackReward = {
  amount: Scalars["Money"]["output"];
};

export type CampaignStepReward = {
  cashbackReward?: Maybe<CampaignStepCashbackReward>;
  voucherReward?: Maybe<CampaignStepVoucherReward>;
};

export type CampaignStepVoucherReward = {
  amount: Scalars["Money"]["output"];
};

export type CancelInstallmentLoanPayLoad = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  installmentLoanId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CardConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<OrderItemCard>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type CardInfo = {
  amount: Scalars["Money"]["output"];
  carrierType: CarrierType;
};

export type CardQuery = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CarrierDataConfig = {
  discount: Scalars["Decimal"]["output"];
  limitation: Scalars["Money"]["output"];
};

export type CarrierDataInfo = {
  carrier: CarrierType;
  dataAmount?: Maybe<Scalars["String"]["output"]>;
  duration?: Maybe<Scalars["String"]["output"]>;
  packageId: Scalars["String"]["output"];
};

export enum CarrierType {
  Gmobile = "Gmobile",
  Mobiphone = "Mobiphone",
  Vietnamobile = "Vietnamobile",
  Viettel = "Viettel",
  Vinaphone = "Vinaphone",
}

export type CashbackInfo = {
  availableCashback?: Maybe<Scalars["Money"]["output"]>;
  cbUserId: Scalars["ID"]["output"];
  expDate?: Maybe<Scalars["Date"]["output"]>;
  totalCashback?: Maybe<Scalars["Money"]["output"]>;
  totalUsedCashback?: Maybe<Scalars["Money"]["output"]>;
};

export type CashbackPayload = {
  amount: Scalars["Money"]["input"];
  expiredDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
};

export type CashbackResponseDetail = {
  amount: Scalars["Money"]["output"];
};

export type CashbackRollbackPayload = {
  transactionId: Scalars["ID"]["input"];
};

export type CashbackTransaction = {
  cashbackType?: Maybe<CashbackType>;
  cbUserId: Scalars["ID"]["output"];
  code?: Maybe<Scalars["String"]["output"]>;
  createdDate: Scalars["Instant"]["output"];
  descriptionMessage: Scalars["String"]["output"];
  expiredDate?: Maybe<Scalars["LocalDate"]["output"]>;
  issuedDate: Scalars["LocalDate"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  metadata?: Maybe<Scalars["JSON"]["output"]>;
  transactionActualAmount: Scalars["Money"]["output"];
  transactionAmount: Scalars["Money"]["output"];
  transactionType: CashbackTransactionType;
};

export type CashbackTransactionFilter = {
  cbUserId?: InputMaybe<Scalars["ID"]["input"]>;
  cbUserIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type CashbackTransactionQuery = {
  filter?: InputMaybe<CashbackTransactionFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CashbackTransactionResponse = {
  limit?: Maybe<Scalars["Int"]["output"]>;
  nodes?: Maybe<Array<Maybe<CashbackTransaction>>>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export enum CashbackTransactionType {
  Expired = "Expired",
  Reward = "Reward",
  Rollback = "Rollback",
  Transaction = "Transaction",
  Used = "Used",
}

export enum CashbackType {
  Cashback = "Cashback",
  Earning = "Earning",
}

export type CashbackUser = {
  cashbackHistory?: Maybe<CashbackTransactionResponse>;
  cashbackInfo?: Maybe<CashbackInfo>;
  expiringTransaction?: Maybe<CashbackTransaction>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  totalInvitationCashback?: Maybe<Scalars["Money"]["output"]>;
};

export type CashbackUserCashbackHistoryArgs = {
  query?: InputMaybe<CashbackTransactionQuery>;
};

export type CashbackVoucherResponseDetail = {
  amount: Scalars["Money"]["output"];
  voucher: Voucher;
};

export type CashbackWithdrawPayload = {
  metadata?: InputMaybe<Scalars["JSON"]["input"]>;
  withdrawAmount: Scalars["Money"]["input"];
};

export type CategoryConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<CompanyCategory>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export enum CategoryEarningOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export type CategoryEarningQuery = {
  filter?: InputMaybe<CategoryEarningQueryFilterFe>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<CategoryEarningOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type CategoryEarningQueryFilter = {
  status?: InputMaybe<CategoryStatus>;
};

export type CategoryEarningQueryFilterFe = {
  filter?: InputMaybe<CategoryEarningQueryFilter>;
  productQuery?: InputMaybe<ProductQuery>;
};

export enum CategoryOrderable {
  CategoryId = "CategoryId",
  CategoryName = "CategoryName",
  CategoryOrder = "CategoryOrder",
}

export type CategoryQuery = {
  all?: InputMaybe<Scalars["Boolean"]["input"]>;
  filters?: InputMaybe<Array<CategoryQueryFilter>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<CategoryOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type CategoryQueryFilter = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  slug?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type CategoryResponse = {
  items?: Maybe<Array<Maybe<AttributeResponse>>>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export enum CategoryStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type CellularConfigItemPayload = {
  amount: Scalars["Money"]["input"];
  discount: Scalars["Decimal"]["input"];
  status: CellularNetworkItemStatus;
  type: CellularNetworkItemType;
};

export type CellularNetworkConfig = {
  items?: Maybe<Array<CellularNetworkItem>>;
  name: Scalars["String"]["output"];
  order?: Maybe<Scalars["Int"]["output"]>;
  status: CellularNetworkStatus;
  thumbnail?: Maybe<StorageObject>;
  type: CellularNetworkType;
};

export type CellularNetworkConfigItemsArgs = {
  type?: InputMaybe<CellularNetworkItemType>;
};

export type CellularNetworkItem = {
  amount: Scalars["Money"]["output"];
  discount: Scalars["Decimal"]["output"];
  status: CellularNetworkItemStatus;
  type?: Maybe<CellularNetworkItemType>;
};

export enum CellularNetworkItemStatus {
  Active = "Active",
  Inactive = "Inactive",
  OutOfStock = "OutOfStock",
}

export enum CellularNetworkItemType {
  Card = "Card",
  Data = "Data",
  TopUp = "TopUp",
}

export enum CellularNetworkStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export enum CellularNetworkType {
  Gmobile = "Gmobile",
  Mobiphone = "Mobiphone",
  Vietnamobile = "Vietnamobile",
  Viettel = "Viettel",
  Vinaphone = "Vinaphone",
}

export type ChanePhoneResponse = {
  changeDate?: Maybe<Scalars["Instant"]["output"]>;
  newNumber?: Maybe<Scalars["String"]["output"]>;
  oldNumber?: Maybe<Scalars["String"]["output"]>;
};

export enum ChangeMode {
  Down = "DOWN",
  Nochange = "NOCHANGE",
  Up = "UP",
}

export type ChargeBusinessAdminQuery = {
  filter?: InputMaybe<ChargeBusinessAdminQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<UserPayHistoryOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type ChargeBusinessAdminQueryFilter = {
  amount?: InputMaybe<MoneyRangeFilter>;
  companyId?: InputMaybe<Scalars["ID"]["input"]>;
  createdDateRange?: InputMaybe<LocalDateRange>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<SettlementStatus>;
  tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ChargeBusinessDetailAdminQuery = {
  filter: ChargeBusinessDetailAdminQueryFilter;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<UserPayHistoryOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type ChargeBusinessDetailAdminQueryFilter = {
  companyId: Scalars["ID"]["input"];
  offset: Scalars["Int"]["input"];
};

export type ChargeBusinessDetailResponse = {
  employeeId?: Maybe<Scalars["String"]["output"]>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  recoverAmount?: Maybe<Scalars["Money"]["output"]>;
  status?: Maybe<SettlementStatus>;
  transactionCode?: Maybe<Scalars["String"]["output"]>;
  userPayAmount?: Maybe<Scalars["Money"]["output"]>;
  userPayableAmount?: Maybe<Scalars["Money"]["output"]>;
};

export type ChargeBusinessResponse = {
  actualReimburseDate?: Maybe<Scalars["LocalDate"]["output"]>;
  companyCode?: Maybe<Scalars["String"]["output"]>;
  companyId?: Maybe<Scalars["ID"]["output"]>;
  expectReimburseDate?: Maybe<Scalars["LocalDate"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  recoverAmount?: Maybe<Scalars["Money"]["output"]>;
  status?: Maybe<SettlementStatus>;
  tenantCode?: Maybe<Scalars["String"]["output"]>;
  totalLoan?: Maybe<Scalars["Int"]["output"]>;
  userPayAmount?: Maybe<Scalars["Money"]["output"]>;
  userPayableAmount?: Maybe<Scalars["Money"]["output"]>;
};

export type CheckFlowChangePasswordResponse = {
  flow: FlowChangePassword;
  idNumberFirst3Characters?: Maybe<Scalars["String"]["output"]>;
};

export type CloseOrderPayload = {
  code: Scalars["String"]["input"];
  message: Scalars["String"]["input"];
};

export type ComissionDisbursementFilter = {
  endDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  productId?: InputMaybe<Scalars["ID"]["input"]>;
  publisherId?: InputMaybe<Scalars["ID"]["input"]>;
  startDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  type?: InputMaybe<ComissionDisbursementType>;
};

export enum ComissionDisbursementOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export type ComissionDisbursementQuery = {
  filter?: InputMaybe<ComissionDisbursementFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<ComissionDisbursementOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export enum ComissionDisbursementType {
  Nano = "Nano",
  Publisher = "Publisher",
}

export type CommunicationEvent = {
  content?: Maybe<Scalars["String"]["output"]>;
  createdDate: Scalars["Instant"]["output"];
  employment: Employment;
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  type: CommunicationEventType;
};

export type CommunicationEventChart = {
  clappedDate?: Maybe<Scalars["Instant"]["output"]>;
  departmentName?: Maybe<Scalars["String"]["output"]>;
  employeeId: Scalars["String"]["output"];
  employmentName: Scalars["String"]["output"];
  seenDate?: Maybe<Scalars["Instant"]["output"]>;
};

export type CommunicationEventChartFilter = {
  createdDate?: InputMaybe<OffsetDateTimeRange>;
  type?: InputMaybe<CommunicationEventType>;
};

export type CommunicationEventChartQuery = {
  filter?: InputMaybe<CommunicationEventChartFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CommunicationEventLineChart = {
  date: Scalars["Instant"]["output"];
  total: Scalars["Int"]["output"];
  type: CommunicationEventType;
};

export type CommunicationEventPieChart = {
  departmentId: Scalars["ID"]["output"];
  departmentName: Scalars["String"]["output"];
  totalEmployments: Scalars["Int"]["output"];
  totalEvents: Scalars["Int"]["output"];
};

export enum CommunicationEventType {
  Clap = "Clap",
  Seen = "Seen",
}

export type CommunicationPost = {
  chartDetailEvents: MutateCommunicationEventChartResponse;
  company: Company;
  createdDate: Scalars["Instant"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  employmentEvents?: Maybe<Array<CommunicationEvent>>;
  endDate?: Maybe<Scalars["Instant"]["output"]>;
  id: Scalars["ID"]["output"];
  image?: Maybe<StorageObject>;
  imageType?: Maybe<ImageResponse>;
  lastModifiedDate: Scalars["Instant"]["output"];
  lineChartByDates: MutateCommunicationEventLineResponse;
  pdf?: Maybe<StorageObject>;
  pieChartByDates: MutateCommunicationEventPipeResponse;
  publishedDate?: Maybe<Scalars["Instant"]["output"]>;
  scheduledDate?: Maybe<Scalars["Instant"]["output"]>;
  sendToDepartments?: Maybe<Array<Department>>;
  sendToIndividuals?: Maybe<Array<Employment>>;
  sendToType?: Maybe<CommunicationPostSendTo>;
  shortDescription?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["Instant"]["output"]>;
  status: CommunicationPostStatus;
  title?: Maybe<Scalars["String"]["output"]>;
  totalClaps: Scalars["Int"]["output"];
  totalViews: Scalars["Int"]["output"];
  type?: Maybe<CommunicationPostType>;
};

export type CommunicationPostChartDetailEventsArgs = {
  query?: InputMaybe<CommunicationEventChartQuery>;
};

export type CommunicationPostEmploymentEventsArgs = {
  employmentId: Scalars["ID"]["input"];
};

export type CommunicationPostLineChartByDatesArgs = {
  filter?: InputMaybe<CommunicationEventChartFilter>;
};

export type CommunicationPostPieChartByDatesArgs = {
  filter?: InputMaybe<CommunicationEventChartFilter>;
};

export type CommunicationPostConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<CommunicationPost>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type CommunicationPostFilter = {
  status?: InputMaybe<CommunicationPostStatus>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<CommunicationPostType>;
};

export enum CommunicationPostOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
  PublishDate = "PublishDate",
  Status = "Status",
}

export type CommunicationPostQuery = {
  filter?: InputMaybe<CommunicationPostFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<CommunicationPostOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export enum CommunicationPostSendTo {
  All = "All",
  Department = "Department",
  Individual = "Individual",
}

export enum CommunicationPostStatus {
  Deactive = "Deactive",
  Draft = "Draft",
  Pending = "Pending",
  Published = "Published",
}

export enum CommunicationPostType {
  Announcement = "Announcement",
}

export type CommunicationTemplate = {
  createdDate: Scalars["Instant"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  name: Scalars["String"]["output"];
  source: StorageObject;
  status: CommunicationTemplateStatus;
  type?: Maybe<Scalars["String"]["output"]>;
};

export enum CommunicationTemplateStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type Company = {
  activeToc?: Maybe<TermAndCondition>;
  address?: Maybe<Scalars["String"]["output"]>;
  auditLog: AuditLog;
  auditLogs: AuditLogConnection;
  authRealm?: Maybe<Scalars["String"]["output"]>;
  authUrl?: Maybe<Scalars["URI"]["output"]>;
  bannerAssociation: BannerAssociation;
  bannerAssociations: BannerAssociationConnection;
  code: Scalars["String"]["output"];
  communicationPost: CommunicationPost;
  communicationPosts: CommunicationPostConnection;
  contractCompanyAddress?: Maybe<Scalars["String"]["output"]>;
  contractCompanyName?: Maybe<Scalars["String"]["output"]>;
  contractCompanyTaxCode?: Maybe<Scalars["String"]["output"]>;
  contractNo?: Maybe<Scalars["String"]["output"]>;
  createdDate: Scalars["Instant"]["output"];
  departments: DepartmentConnection;
  descendant?: Maybe<Company>;
  description?: Maybe<Scalars["String"]["output"]>;
  disbursements: DisbursementConnection;
  email?: Maybe<Scalars["Email"]["output"]>;
  employment: Employment;
  /** @deprecated No longer supported */
  employmentStats?: Maybe<EmploymentStats>;
  employments: EmploymentConnection;
  features: CompanyFeatures;
  getVendorInfo: VendorDetail;
  groupsAsTree: Array<CompanyEmploymentGroupTreeNode>;
  id: Scalars["ID"]["output"];
  /** @deprecated No longer supported */
  isIntegrated: Scalars["Boolean"]["output"];
  isRoot?: Maybe<Scalars["Boolean"]["output"]>;
  isShowPayLaterEmployerPortal?: Maybe<Scalars["Boolean"]["output"]>;
  isShowPayslipEmployerPortal?: Maybe<Scalars["Boolean"]["output"]>;
  isShowShiftMealEmployerPortal?: Maybe<Scalars["Boolean"]["output"]>;
  l0MaintenanceFeeConfig?: Maybe<CompanyInvoiceMaintenanceConfig>;
  l1MaintenanceFeeConfig?: Maybe<CompanyInvoiceMaintenanceConfig>;
  l2MaintenanceFeeConfig?: Maybe<CompanyInvoiceMaintenanceConfig>;
  l3MaintenanceFeeConfig?: Maybe<CompanyInvoiceMaintenanceConfig>;
  lastModifiedDate: Scalars["Instant"]["output"];
  latePenaltyFeeRate: Scalars["Float"]["output"];
  loanApplication: LoanApplication;
  /** @deprecated No longer supported */
  loanApplicationCountGraph: Array<LoanApplicationCountGraphEntry>;
  loanApplicationCountGraphV2: Array<LoanApplicationCountGraphEntryV2>;
  /** @deprecated No longer supported */
  loanApplicationStats: LoanApplicationStats;
  loanApplications: LoanApplicationConnection;
  logo?: Maybe<StorageObject>;
  makeCheckEnable?: Maybe<Scalars["Boolean"]["output"]>;
  makeChecks?: Maybe<MakeCheckRequestConnection>;
  name: Scalars["String"]["output"];
  negativeLogo?: Maybe<StorageObject>;
  onboardCompany?: Maybe<OnboardCompany>;
  paymentDayInMonth?: Maybe<Scalars["Int"]["output"]>;
  paymentMonthAfterCutoff?: Maybe<Scalars["Int"]["output"]>;
  portalDomain?: Maybe<Scalars["URI"]["output"]>;
  recursiveDescendants: Array<Company>;
  reportReceiverEmails?: Maybe<Array<Scalars["String"]["output"]>>;
  role?: Maybe<TenantRole>;
  roles: Array<TenantRole>;
  shortName?: Maybe<Scalars["String"]["output"]>;
  showEVuiOnboardConfirmationScreen?: Maybe<Scalars["Boolean"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  status: CompanyStatus;
  subscriptionAmount: Scalars["Money"]["output"];
  surveyForm: SurveyFormResponse;
  surveyFormEnabled: Scalars["Boolean"]["output"];
  surveyForms: SurveyFormConnection;
  taxCode?: Maybe<Scalars["String"]["output"]>;
  tenantUser: TenantUser;
  tenantUsers: TenantUserConnection;
  workSchedule: CompanyWorkSchedule;
  workSchedules: CompanyWorkScheduleConnection;
  workplaces?: Maybe<WorkplaceConnection>;
};

export type CompanyAuditLogArgs = {
  id: Scalars["ID"]["input"];
};

export type CompanyAuditLogsArgs = {
  query?: InputMaybe<AuditLogQuery>;
};

export type CompanyBannerAssociationArgs = {
  id: Scalars["ID"]["input"];
};

export type CompanyBannerAssociationsArgs = {
  query?: BannerAssociationQuery;
};

export type CompanyCommunicationPostArgs = {
  id: Scalars["ID"]["input"];
};

export type CompanyCommunicationPostsArgs = {
  query?: InputMaybe<CommunicationPostQuery>;
};

export type CompanyDepartmentsArgs = {
  query?: InputMaybe<DepartmentQuery>;
};

export type CompanyDescendantArgs = {
  id: Scalars["ID"]["input"];
};

export type CompanyDisbursementsArgs = {
  query?: InputMaybe<DisbursementQuery>;
};

export type CompanyEmploymentArgs = {
  id: Scalars["ID"]["input"];
};

export type CompanyEmploymentStatsArgs = {
  filter?: InputMaybe<EmploymentQueryFilter>;
};

export type CompanyEmploymentsArgs = {
  query?: InputMaybe<CompanyEmploymentQuery>;
};

export type CompanyLoanApplicationArgs = {
  id: Scalars["ID"]["input"];
};

export type CompanyLoanApplicationCountGraphArgs = {
  query: LoanApplicationCountGraphQuery;
};

export type CompanyLoanApplicationCountGraphV2Args = {
  query: LoanApplicationCountGraphQuery;
};

export type CompanyLoanApplicationStatsArgs = {
  filter?: InputMaybe<LoanApplicationQueryFilter>;
};

export type CompanyLoanApplicationsArgs = {
  query?: InputMaybe<LoanApplicationQuery>;
};

export type CompanyMakeChecksArgs = {
  query?: InputMaybe<MakeCheckRequestQuery>;
};

export type CompanyRoleArgs = {
  name: Scalars["String"]["input"];
};

export type CompanySurveyFormArgs = {
  id: Scalars["ID"]["input"];
};

export type CompanySurveyFormsArgs = {
  query?: SurveyFormQuery;
};

export type CompanyTenantUserArgs = {
  id: Scalars["ID"]["input"];
};

export type CompanyTenantUsersArgs = {
  query?: TenantUserQuery;
};

export type CompanyWorkScheduleArgs = {
  id: Scalars["ID"]["input"];
};

export type CompanyWorkSchedulesArgs = {
  query?: InputMaybe<CompanyWorkScheduleQuery>;
};

export type CompanyWorkplacesArgs = {
  query?: InputMaybe<WorkplaceQuery>;
};

export type CompanyCategory = {
  companies: CompanyConnection;
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  name: Scalars["String"]["output"];
};

export type CompanyCategoryCompaniesArgs = {
  query?: InputMaybe<CompanyQuery>;
};

export type CompanyConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Company>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type CompanyEmploymentGroup = {
  attendanceFileProcessorQualifier?: Maybe<Scalars["String"]["output"]>;
  company: Company;
  createdDate?: Maybe<Scalars["Instant"]["output"]>;
  dailyLimit: Scalars["Money"]["output"];
  employmentFileProcessorQualifier?: Maybe<Scalars["String"]["output"]>;
  fixedFee: Scalars["Money"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate?: Maybe<Scalars["Instant"]["output"]>;
  limitPerAdvance?: Maybe<Scalars["Money"]["output"]>;
  limitPercentageBySalaryPeriod: Scalars["Float"]["output"];
  minAdvanceValue: Scalars["Money"]["output"];
  minimumFee: Scalars["Money"]["output"];
  name: Scalars["String"]["output"];
  numberOfAdvanceByDay: Scalars["Int"]["output"];
  numberOfAdvanceBySalaryPeriod: Scalars["Int"]["output"];
  payPeriodEndDayInMonth: Scalars["Int"]["output"];
  payPeriodStartDayInMonth: Scalars["Int"]["output"];
  payslipCalculatorQualifier?: Maybe<Scalars["String"]["output"]>;
  periodLimit?: Maybe<Scalars["Money"]["output"]>;
  salaryPeriodFreeAmount?: Maybe<Scalars["Money"]["output"]>;
  shiftCountUnit: ShiftUnit;
  transactionFeePercentage: Scalars["Float"]["output"];
  workerType: WorkerType;
};

export type CompanyEmploymentGroupTreeNode = {
  group: CompanyEmploymentGroup;
  parentId?: Maybe<Scalars["ID"]["output"]>;
};

export type CompanyEmploymentQuery = {
  filter?: InputMaybe<EmploymentQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<EmploymentOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type CompanyFeatures = {
  autoLinkEnabled: Scalars["Boolean"]["output"];
  hideFee: Scalars["Boolean"]["output"];
  intercom: Scalars["Boolean"]["output"];
  invitation: Scalars["Boolean"]["output"];
  invitation92021: Scalars["Boolean"]["output"];
  invitation92021_result: Scalars["Boolean"]["output"];
  lanchi_onboarding: Scalars["Boolean"]["output"];
  newEWA?: Maybe<Scalars["Boolean"]["output"]>;
  noop?: Maybe<Scalars["Void"]["output"]>;
  payslip: Scalars["Boolean"]["output"];
  saving_config?: Maybe<TenantSavingConfigResponse>;
  topUp_config?: Maybe<TenantTopUpConfigResponse>;
  widgetAttendanceVersion: CompanyFeaturesWidgetAttendanceVersion;
};

export enum CompanyFeaturesWidgetAttendanceVersion {
  V1 = "v1",
  V2 = "v2",
}

export type CompanyInvoiceMaintenanceConfig = {
  maintenanceFeeRate?: Maybe<Scalars["Float"]["output"]>;
  maintenanceOverdueDate?: Maybe<Scalars["Int"]["output"]>;
};

export enum CompanyOrderable {
  CompanyName = "companyName",
  CreatedDate = "createdDate",
  Id = "id",
  Index = "index",
  LastModifiedDate = "lastModifiedDate",
  ShortName = "shortName",
  Slug = "slug",
  Status = "status",
}

export type CompanyQuery = {
  filter?: InputMaybe<CompanyQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<CompanyOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type CompanyQueryFilter = {
  addressSearch?: InputMaybe<Scalars["String"]["input"]>;
  emailSearch?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  linkable?: InputMaybe<Scalars["Boolean"]["input"]>;
  nameSearch?: InputMaybe<Scalars["String"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  slugSearch?: InputMaybe<Scalars["String"]["input"]>;
  statuses?: InputMaybe<Array<CompanyStatus>>;
};

export type CompanyResponse = {
  total?: Maybe<Scalars["Int"]["output"]>;
  vendorCompanies?: Maybe<Array<Maybe<VendorCompany>>>;
};

export enum CompanyStatus {
  Active = "Active",
  Inactive = "Inactive",
  Pilot = "Pilot",
}

export type CompanyWorkPeriod = {
  attendance: Attendance;
  attendances: AttendanceConnection;
  disbursement: Disbursement;
  disbursements: DisbursementConnection;
  endTimeInclusive: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  loanApplication: LoanApplication;
  loanApplications: LoanApplicationConnection;
  startTime: Scalars["Instant"]["output"];
};

export type CompanyWorkPeriodAttendanceArgs = {
  id: Scalars["ID"]["input"];
};

export type CompanyWorkPeriodAttendancesArgs = {
  query?: InputMaybe<AttendanceQuery>;
};

export type CompanyWorkPeriodDisbursementArgs = {
  id: Scalars["ID"]["input"];
};

export type CompanyWorkPeriodDisbursementsArgs = {
  query?: InputMaybe<DisbursementQuery>;
};

export type CompanyWorkPeriodLoanApplicationArgs = {
  id: Scalars["ID"]["input"];
};

export type CompanyWorkPeriodLoanApplicationsArgs = {
  query?: InputMaybe<LoanApplicationQuery>;
};

export type CompanyWorkPeriodConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<CompanyWorkPeriod>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export enum CompanyWorkPeriodOrderable {
  CreatedDate = "createdDate",
  Id = "id",
  LastModifiedDate = "lastModifiedDate",
  StartTime = "startTime",
}

export type CompanyWorkPeriodQuery = {
  filter?: InputMaybe<CompanyWorkPeriodQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<CompanyWorkPeriodOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type CompanyWorkPeriodQueryFilter = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type CompanyWorkSchedule = {
  code: Scalars["String"]["output"];
  createdDate: Scalars["Instant"]["output"];
  currentWorkPeriod: CompanyWorkPeriod;
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  workPeriod: CompanyWorkPeriod;
  workPeriods: CompanyWorkPeriodConnection;
};

export type CompanyWorkScheduleWorkPeriodArgs = {
  id: Scalars["ID"]["input"];
};

export type CompanyWorkScheduleWorkPeriodsArgs = {
  query?: InputMaybe<CompanyWorkPeriodQuery>;
};

export type CompanyWorkScheduleConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<CompanyWorkSchedule>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type CompanyWorkScheduleQuery = {
  filter?: InputMaybe<CompanyWorkScheduleQueryFilter>;
  filters?: InputMaybe<Array<CompanyWorkScheduleQueryFilter>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CompanyWorkScheduleQueryFilter = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export enum ConditionType {
  Date = "DATE",
  Id = "ID",
  List = "LIST",
  Number = "NUMBER",
  String = "STRING",
}

export type Config = {
  key: Scalars["String"]["output"];
  value: Scalars["JSON"]["output"];
};

/** A connection to a list of items. */
export type ConfigConnection = {
  /** a list of edges */
  edges: Array<ConfigEdge>;
  /** a list of nodes */
  nodes: Array<Config>;
  /** details about this specific page */
  pageInfo: PageInfo;
  /** total number of node */
  total: Scalars["Long"]["output"];
};

/** An edge in a connection */
export type ConfigEdge = {
  /** cursor marks a unique position or index into the connection */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: Config;
};

export type Configurations = {
  key?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  tenantId?: Maybe<Scalars["ID"]["output"]>;
  value?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type ConfigurationsPayload = {
  key: Scalars["String"]["input"];
  status?: InputMaybe<Scalars["String"]["input"]>;
  tenantId: Scalars["ID"]["input"];
  value: Array<InputMaybe<Scalars["String"]["input"]>>;
};

export type ConfirmBankAccountPayload = {
  account: Scalars["String"]["input"];
  bankId: Scalars["ID"]["input"];
};

export type ConfirmBankAccountResponse = {
  employment?: Maybe<Employment>;
  isSystemUser?: Maybe<Scalars["Boolean"]["output"]>;
};

export type Contact = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  relation?: InputMaybe<Scalars["String"]["input"]>;
};

export type ContactTransferResponse = {
  fullName?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["PhoneNumber"]["output"]>;
  transferInfo?: Maybe<TransferInfo>;
};

export type ContentSettlementScreenResponse = {
  content?: Maybe<Scalars["String"]["output"]>;
  isPay?: Maybe<Scalars["Boolean"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type Contestant = {
  missedTotal: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  successTotal: Scalars["Int"]["output"];
};

/**
 * val payloads: List<LoanApplicationMutation.CreateLoanApplicationV2Payload.Entry>,
 * val extension: CalculateAdvanceV3Payload.Extension? = null,
 * val employeeReceiveAmount: MonetaryAmount,
 */
export type CreateAdvanceV3Payload = {
  employeeReceiveAmount: Scalars["Money"]["input"];
  extension?: InputMaybe<CalculateAdvanceV3Extension>;
  payloads: Array<CreateLoanApplicationV2PayloadEntry>;
};

export type CreateAdvertiserPayload = {
  logo_id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
};

export type CreateAttendancePayload = {
  checkinSecond?: InputMaybe<Scalars["Int"]["input"]>;
  checkoutSecond?: InputMaybe<Scalars["Int"]["input"]>;
  date: Scalars["Date"]["input"];
  employmentId: Scalars["ID"]["input"];
  overTimeRatePerHour?: InputMaybe<Scalars["Money"]["input"]>;
  overtimeHours?: InputMaybe<Scalars["Float"]["input"]>;
  submittedAt?: InputMaybe<Scalars["Instant"]["input"]>;
  workingDayType: WorkingDayType;
  workingTimeHours: Scalars["Float"]["input"];
  workingTimeRatePerHour: Scalars["Money"]["input"];
};

export type CreateBannerAssociationPayload = {
  bannerId: Scalars["ID"]["input"];
  companyId?: InputMaybe<Scalars["ID"]["input"]>;
  companyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  description: Scalars["String"]["input"];
  employmentFilter: BannerAssociationEmploymentFilterPayload;
  endTime: Scalars["Instant"]["input"];
  startTime: Scalars["Instant"]["input"];
  status: BannerAssociationStatus;
};

export type CreateBannerPayload = {
  action?: InputMaybe<BannerActionPayload>;
  content?: InputMaybe<BannerContentPayload>;
  contentImageId?: InputMaybe<Scalars["ID"]["input"]>;
  status: BannerStatus;
  title: Scalars["String"]["input"];
  type: BannerType;
};

export type CreateBatchFilePayload = {
  batchId: Scalars["ID"]["input"];
  filename: Scalars["String"]["input"];
  qualifier: Scalars["String"]["input"];
};

export type CreateBatchFileResponse = {
  skipIds: Array<Scalars["ID"]["output"]>;
  storageObject: StorageObject;
};

export type CreateChangePhoneNumberPayload = {
  newPhoneNumber: Scalars["PhoneNumber"]["input"];
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
  otpMethod?: InputMaybe<OtpMethod>;
};

export type CreateCommunicationPostPayload = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Instant"]["input"]>;
  imageId?: InputMaybe<Scalars["ID"]["input"]>;
  pdfId?: InputMaybe<Scalars["ID"]["input"]>;
  scheduledDate?: InputMaybe<Scalars["Instant"]["input"]>;
  sendToDepartmentIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sendToIndividualIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sendToType?: InputMaybe<CommunicationPostSendTo>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Instant"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<CommunicationPostType>;
};

export type CreateCommunicationTemplatePayload = {
  companyId?: InputMaybe<Scalars["ID"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  sourceId: Scalars["ID"]["input"];
  status: CommunicationTemplateStatus;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateCompanyEmploymentGroupPayload = {
  companyId: Scalars["ID"]["input"];
  dailyLimit: Scalars["Money"]["input"];
  fixedFee: Scalars["Money"]["input"];
  limitPercentageBySalaryPeriod: Scalars["Float"]["input"];
  minimumFee: Scalars["Money"]["input"];
  name: Scalars["String"]["input"];
  numberOfAdvanceByDay: Scalars["Int"]["input"];
  numberOfAdvanceBySalaryPeriod: Scalars["Int"]["input"];
  payPeriodEndDate: Scalars["Int"]["input"];
  payPeriodStartDate: Scalars["Int"]["input"];
  transactionFeePercentage: Scalars["Float"]["input"];
  workerType?: InputMaybe<WorkerType>;
};

export type CreateCompanyEmploymentPayload = {
  bankAccount?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountMasked?: InputMaybe<Scalars["String"]["input"]>;
  bankId?: InputMaybe<Scalars["ID"]["input"]>;
  departmentName?: InputMaybe<Scalars["String"]["input"]>;
  dob?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["Email"]["input"]>;
  employeeCode?: InputMaybe<Scalars["String"]["input"]>;
  employeeId: Scalars["String"]["input"];
  expireDate?: InputMaybe<Scalars["String"]["input"]>;
  fullName: Scalars["String"]["input"];
  gender?: InputMaybe<Scalars["String"]["input"]>;
  groupId?: InputMaybe<Scalars["ID"]["input"]>;
  idNumber?: InputMaybe<Scalars["String"]["input"]>;
  metadata?: InputMaybe<Scalars["JSON"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  salary?: InputMaybe<Scalars["Money"]["input"]>;
  socialId?: InputMaybe<Scalars["String"]["input"]>;
  socialIdIssuedDate?: InputMaybe<Scalars["Instant"]["input"]>;
  socialIdIssuedPlace?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Instant"]["input"]>;
  status: EmploymentStatus;
  type?: InputMaybe<EmploymentType>;
  workingDayPerMonth?: InputMaybe<Scalars["Int"]["input"]>;
  workingHourPerDay?: InputMaybe<Scalars["Int"]["input"]>;
  workplaceName?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateCompanyPayload = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  contractCompanyAddress?: InputMaybe<Scalars["String"]["input"]>;
  contractCompanyName?: InputMaybe<Scalars["String"]["input"]>;
  contractCompanyTaxCode?: InputMaybe<Scalars["String"]["input"]>;
  contractNo?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["Email"]["input"]>;
  logoId?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  negativeLogoId?: InputMaybe<Scalars["ID"]["input"]>;
  paymentDayInMonth?: InputMaybe<Scalars["Int"]["input"]>;
  paymentMonthAfterCutoff?: InputMaybe<Scalars["Int"]["input"]>;
  reportReceiverEmails?: InputMaybe<Array<Scalars["String"]["input"]>>;
  shortName?: InputMaybe<Scalars["String"]["input"]>;
  slug: Scalars["String"]["input"];
  status: CompanyStatus;
  tocId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CreateCompanyRequestLinkEmployment = {
  bankAccount?: InputMaybe<Scalars["String"]["input"]>;
  bankId?: InputMaybe<Scalars["ID"]["input"]>;
  dataSharingAgreementFileId?: InputMaybe<Scalars["ID"]["input"]>;
  email?: InputMaybe<Scalars["Email"]["input"]>;
  employeeCode?: InputMaybe<Scalars["String"]["input"]>;
  employeeId: Scalars["String"]["input"];
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  socialId?: InputMaybe<Scalars["String"]["input"]>;
  socialIdIssuedDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  socialIdIssuedPlace?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateCompanyTermAndConditionPayload = {
  storageId: Scalars["ID"]["input"];
};

export type CreateCronJobPayload = {
  content?: InputMaybe<Scalars["JSON"]["input"]>;
  expression: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  type: CronJobType;
};

export type CreateEwaSurveyPayload = {
  question1?: InputMaybe<Scalars["JSON"]["input"]>;
  question2?: InputMaybe<Scalars["JSON"]["input"]>;
  question3?: InputMaybe<Scalars["JSON"]["input"]>;
  question4?: InputMaybe<Scalars["JSON"]["input"]>;
  question5?: InputMaybe<Scalars["JSON"]["input"]>;
  question6?: InputMaybe<Scalars["JSON"]["input"]>;
  question7?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type CreateEmployeeWaitListPayload = {
  employeeCode: Scalars["String"]["input"];
  fullName: Scalars["String"]["input"];
};

export type CreateEmploymentMessagePayload = {
  content: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
};

export type CreateGreetingCardTemplate = {
  amountColorCode: Scalars["String"]["input"];
  cardName: Scalars["String"]["input"];
  code: Scalars["String"]["input"];
  contentColorCode: Scalars["String"]["input"];
  defaultContent: Scalars["String"]["input"];
  errorColorCode: Scalars["String"]["input"];
  imageId: Scalars["ID"]["input"];
  validFrom?: InputMaybe<Scalars["Instant"]["input"]>;
  validTo?: InputMaybe<Scalars["Instant"]["input"]>;
};

export type CreateInstallmentLoanPayLoad = {
  amount: Scalars["Decimal"]["input"];
  packageId: Scalars["ID"]["input"];
};

export type CreateInstallmentLoanResponse = {
  createDate?: Maybe<Scalars["Instant"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  numberSequence?: Maybe<Scalars["Int"]["output"]>;
  transactionCode?: Maybe<Scalars["String"]["output"]>;
};

export type CreateKycVendorPayload = {
  additionalData?: InputMaybe<Scalars["JSON"]["input"]>;
  employmentId: Scalars["ID"]["input"];
  featureName: Scalars["String"]["input"];
  vendorCode?: Scalars["String"]["input"];
  vendorData?: InputMaybe<Scalars["JSON"]["input"]>;
  vendorStatus: Scalars["String"]["input"];
  vendorUuid: Scalars["String"]["input"];
};

export type CreateLoanApplicationBatchPayload = {
  filter?: InputMaybe<LoanApplicationQueryFilter>;
  title: Scalars["String"]["input"];
};

export type CreateLoanApplicationPayload = {
  amount: Scalars["Money"]["input"];
  employmentId: Scalars["ID"]["input"];
  fee: Scalars["Money"]["input"];
  purpose?: InputMaybe<Array<Scalars["String"]["input"]>>;
  userSelection?: InputMaybe<Scalars["Boolean"]["input"]>;
  voucherId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CreateLoanApplicationV2Payload = {
  employmentId: Scalars["ID"]["input"];
  entries: Array<CreateLoanApplicationV2PayloadEntry>;
  voucherId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CreateLoanApplicationV2PayloadEntry = {
  amount: Scalars["Money"]["input"];
  fee: Scalars["Money"]["input"];
  workPeriodId: Scalars["ID"]["input"];
};

export type CreateLoanApplicationV2Response = {
  loanApplications: Array<LoanApplication>;
};

export type CreateNpsSurveyPayload = {
  question1: Scalars["JSON"]["input"];
  question2: Scalars["JSON"]["input"];
  selectedOptions?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type CreateOnboardCompanyPayload = {
  companyId: Scalars["ID"]["input"];
  displayName: Scalars["String"]["input"];
  fullName: Scalars["String"]["input"];
  isDisplayable?: InputMaybe<Scalars["Boolean"]["input"]>;
  platforms?: InputMaybe<Array<OnboardCompanyPlatform>>;
  searchTokens?: InputMaybe<Array<Scalars["String"]["input"]>>;
  workflow?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateOrderPayloadV2 = {
  beneficiary?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  extension?: InputMaybe<Spending_OrderExtension>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CreatePayLaterSurveyPayload = {
  question1?: InputMaybe<Scalars["JSON"]["input"]>;
  question2?: InputMaybe<Scalars["JSON"]["input"]>;
  question3?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type CreatePhonePayload = {
  employmentId: Scalars["ID"]["input"];
  listPhoneBook: Array<ListPhoneBook>;
};

export type CreateProfileKycPayload = {
  backCardId?: InputMaybe<Scalars["ID"]["input"]>;
  frontCardId?: InputMaybe<Scalars["ID"]["input"]>;
  taxCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateProfileKycPayloadV2 = {
  backCardId?: InputMaybe<Scalars["ID"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bankName?: InputMaybe<Scalars["String"]["input"]>;
  featureName?: InputMaybe<Scalars["String"]["input"]>;
  frontCardId?: InputMaybe<Scalars["ID"]["input"]>;
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  idNumber?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  taxCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateProfileKycPayloadV3 = {
  backCardId?: InputMaybe<Scalars["ID"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bankName?: InputMaybe<Scalars["String"]["input"]>;
  featureName?: InputMaybe<Scalars["String"]["input"]>;
  frontCardId?: InputMaybe<Scalars["ID"]["input"]>;
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  idNumber?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  portraitIDs?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  taxCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateProfileKycPayloadV4 = {
  backCardId?: InputMaybe<Scalars["ID"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bankName?: InputMaybe<Scalars["String"]["input"]>;
  featureName?: InputMaybe<Scalars["String"]["input"]>;
  frontCardId?: InputMaybe<Scalars["ID"]["input"]>;
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  idNumber?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  portraitIDs?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  relativeContact?: InputMaybe<Scalars["JSON"]["input"]>;
  taxCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateRatingPayload = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  ratingIcon?: InputMaybe<Scalars["String"]["input"]>;
  ratingNumber?: InputMaybe<Scalars["Float"]["input"]>;
  tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type CreateRewardPayload = {
  employmentId: Scalars["ID"]["input"];
  type: RewardType;
};

export type CreateRolePayload = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  permissions: Array<TenantPermission>;
};

export type CreateSegmentPayload = {
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  effectiveEndDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  effectiveStartDate: Scalars["LocalDate"]["input"];
  employmentSegment?: InputMaybe<EmploymentSegment>;
  name: Scalars["String"]["input"];
  status?: InputMaybe<SegmentStatus>;
};

export type CreateShiftMealSurveyPayload = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  reasons: Array<InputMaybe<Scalars["String"]["input"]>>;
  score: Scalars["Int"]["input"];
};

export type CreateStorageObjectFromUriPayload = {
  uri: Scalars["URI"]["input"];
};

export type CreateStorageObjectPayload = {
  contentType: Scalars["String"]["input"];
  filename: Scalars["String"]["input"];
};

export type CreateStorageObjectResponse = {
  storageObject: StorageObject;
  uploadUri: Scalars["URI"]["output"];
};

export type CreateSurveyForm = {
  editors: Array<Scalars["String"]["input"]>;
  formName: Scalars["String"]["input"];
  fromDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  fromInstant?: InputMaybe<Scalars["Instant"]["input"]>;
  informDate?: InputMaybe<Scalars["Instant"]["input"]>;
  toDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  toInstant?: InputMaybe<Scalars["Instant"]["input"]>;
};

export type CreateSurveyPayload = {
  data: Scalars["JSON"]["input"];
  employmentId: Scalars["ID"]["input"];
  surveyId: Scalars["String"]["input"];
};

export type CreateTenantUserPayload = {
  createTemporaryPassword?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["Email"]["input"]>;
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  sendEmail?: InputMaybe<Scalars["Boolean"]["input"]>;
  status: TenantUserStatus;
  username: Scalars["String"]["input"];
};

export type CreateTenantUserResponse = {
  password?: Maybe<Scalars["String"]["output"]>;
  user: TenantUser;
};

export type CreateTopUpSavingTransactionPayload = {
  amount: Scalars["Money"]["input"];
  entryId: Scalars["ID"]["input"];
};

export type CreateTopUpTransactionPayload = {
  amount: Scalars["Decimal"]["input"];
  cellularNetworkType: CellularNetworkType;
  discount: Scalars["Decimal"]["input"];
  entryId: Scalars["ID"]["input"];
  itemType: CellularNetworkItemType;
  targetPhone: Scalars["PhoneNumber"]["input"];
};

export type CreateTransferPayload = {
  bankAccount: Scalars["String"]["input"];
  bankAccountName: Scalars["String"]["input"];
  bankCode: Scalars["String"]["input"];
  bankId: Scalars["ID"]["input"];
  employmentId: Scalars["ID"]["input"];
  extension?: InputMaybe<CalculateAdvanceV3Extension>;
  greetingCard?: InputMaybe<TransferGreetingCardPayload>;
  payloads: Array<CreateLoanApplicationV2PayloadEntry>;
  transferAmount: Scalars["Money"]["input"];
  transferDesc: Scalars["String"]["input"];
  type?: InputMaybe<TransferType>;
};

export type CreateTransferResponse = {
  order?: Maybe<Order>;
};

export type CreateUptimeMonitorPayload = {
  custom_http_headers?: InputMaybe<Scalars["JSON"]["input"]>;
  custom_http_statuses?: InputMaybe<Scalars["String"]["input"]>;
  httpMethod?: InputMaybe<HttpMethodType>;
  http_auth_type?: InputMaybe<HttpAuthType>;
  http_password?: InputMaybe<Scalars["String"]["input"]>;
  http_username?: InputMaybe<Scalars["String"]["input"]>;
  interval?: InputMaybe<Scalars["Int"]["input"]>;
  monitor?: InputMaybe<Array<InputMaybe<MonitorType>>>;
  name: Scalars["String"]["input"];
  post_value?: InputMaybe<Scalars["JSON"]["input"]>;
  timeout?: InputMaybe<Scalars["Int"]["input"]>;
  type: UptimeMonitorType;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateUserStepPayload = {
  metadata?: InputMaybe<Scalars["JSON"]["input"]>;
  stepId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CreateVoucherPayload = {
  applicableEmploymentIds: Array<Scalars["ID"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  fixedDiscount?: InputMaybe<Scalars["Float"]["input"]>;
  fromDate: Scalars["Instant"]["input"];
  maxAmount?: InputMaybe<Scalars["Float"]["input"]>;
  maxUsageByEmployment?: InputMaybe<Scalars["Int"]["input"]>;
  minAmount?: InputMaybe<Scalars["Float"]["input"]>;
  minApplicableAmount?: InputMaybe<Scalars["Money"]["input"]>;
  toDate: Scalars["Instant"]["input"];
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CreateVoucherV2Payload = {
  employmentId: Scalars["ID"]["input"];
  type: Scalars["String"]["input"];
};

export type CreateWithdrawSavingTransactionPayload = {
  amount: Scalars["Money"]["input"];
  fee?: InputMaybe<Scalars["Money"]["input"]>;
};

export enum CreditConfigStatus {
  Active = "Active",
  Draft = "Draft",
  Inactive = "Inactive",
}

export type CronJobFilter = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  statuses?: InputMaybe<Array<InputMaybe<CronJobStatus>>>;
  taskId?: InputMaybe<Scalars["String"]["input"]>;
};

export enum CronJobStatus {
  Delete = "Delete",
  Pause = "Pause",
  Running = "Running",
}

export enum CronJobType {
  Internal = "Internal",
  Lambda = "Lambda",
  Queue = "Queue",
}

export type CronjobExpression = {
  config?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export enum CronjobOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export type CronjobQuery = {
  filter?: InputMaybe<CronJobFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<CronjobOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type CustomerInfo = {
  address?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type CutoffCompanyMessagePayload = {
  companyId: Scalars["ID"]["input"];
  month: Scalars["Int"]["input"];
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum DayOfWeek {
  Fri = "FRI",
  Mon = "MON",
  Sat = "SAT",
  Sun = "SUN",
  Thu = "THU",
  Tue = "TUE",
  Wed = "WED",
}

export type DeclineInstallmentLoanPayLoad = {
  appraisalType?: InputMaybe<AppraisalType>;
  description?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id: Scalars["ID"]["input"];
  reasonId?: InputMaybe<Scalars["ID"]["input"]>;
  reasonIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type DeclineProfileKyc = {
  description?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id: Scalars["ID"]["input"];
  reasonId: Scalars["ID"]["input"];
};

export type Deduction = {
  amount: Scalars["Money"]["output"];
  category: DeductionCategory;
};

export enum DeductionCategory {
  PersonalIncomeTax = "PersonalIncomeTax",
  SalaryAdvanced = "SalaryAdvanced",
  SocialInsurance = "SocialInsurance",
  UnionFee = "UnionFee",
}

export type DemoBankAccountPayload = {
  account: Scalars["String"]["input"];
  bankId: Scalars["ID"]["input"];
  beneficiaryName: Scalars["String"]["input"];
};

export type DemoLinkEmploymentPayload = {
  bankAccount?: InputMaybe<DemoBankAccountPayload>;
};

export type Department = {
  code: Scalars["String"]["output"];
  createdDate: Scalars["Instant"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  name: Scalars["String"]["output"];
  phone?: Maybe<Scalars["String"]["output"]>;
  status: DepartmentStatus;
};

export type DepartmentConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Department>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export enum DepartmentOrderable {
  CreatedDate = "CreatedDate",
  DepartmentName = "DepartmentName",
  Id = "Id",
  LastModifiedDate = "LastModifiedDate",
  Status = "Status",
}

export type DepartmentQuery = {
  filter?: InputMaybe<DepartmentQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<DepartmentOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type DepartmentQueryFilter = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameFilter?: InputMaybe<StringFilterV2>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  statuses?: InputMaybe<Array<DepartmentStatus>>;
};

export enum DepartmentStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type DisburseLoanApplicationPayload = {
  externalRef: Scalars["String"]["input"];
};

export type DisburseLoanApplicationResponse = {
  disbursement: Disbursement;
  loanApplication: LoanApplication;
};

export type Disbursement = {
  amount: Scalars["Money"]["output"];
  bankAccountId: Scalars["String"]["output"];
  createdDate: Scalars["Instant"]["output"];
  disbursementDate?: Maybe<Scalars["Instant"]["output"]>;
  externalTransactionId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  loanApplication: LoanApplication;
  rejectionMessage?: Maybe<Scalars["String"]["output"]>;
  rejectionReason?: Maybe<RejectDisbursementReason>;
  sourceBank?: Maybe<Bank>;
  sourceBankAccount?: Maybe<Scalars["String"]["output"]>;
  status: DisbursementStatus;
};

export type DisbursementConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Disbursement>;
  offset: Scalars["Int"]["output"];
  sumAmounts: Array<Scalars["Money"]["output"]>;
  total: Scalars["Int"]["output"];
};

export enum DisbursementOrderable {
  Amount = "Amount",
  CreatedDate = "CreatedDate",
  Id = "Id",
  LastModifiedDate = "LastModifiedDate",
  LoanApplicationCode = "LoanApplicationCode",
  Status = "Status",
}

export type DisbursementQuery = {
  filter?: InputMaybe<DisbursementQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<DisbursementOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type DisbursementQueryFilter = {
  amount?: InputMaybe<MoneyRangeFilter>;
  companyId?: InputMaybe<Scalars["ID"]["input"]>;
  companyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  createdDate?: InputMaybe<OffsetDateTimeRange>;
  disbursementAccount?: InputMaybe<Scalars["String"]["input"]>;
  employmentEmployeeId?: InputMaybe<Scalars["String"]["input"]>;
  employmentName?: InputMaybe<Scalars["String"]["input"]>;
  externalTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  lastModifiedDate?: InputMaybe<OffsetDateTimeRange>;
  loanApplicationCode?: InputMaybe<Scalars["String"]["input"]>;
  loanApplicationCreatedDate?: InputMaybe<OffsetDateTimeRange>;
  month?: InputMaybe<Scalars["Int"]["input"]>;
  sourceBankAccount?: InputMaybe<Scalars["String"]["input"]>;
  statuses?: InputMaybe<Array<DisbursementStatus>>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum DisbursementStatus {
  Refunded = "Refunded",
  Rejected = "Rejected",
  Released = "Released",
}

export type DiscountedItem = {
  buyAttempt: Scalars["Int"]["output"];
  convertedItemCode: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type Earning = {
  amount: Scalars["Money"]["output"];
  category: EarningCategory;
};

export enum EarningCallbackResponseStatus {
  Fail = "Fail",
  Pending = "Pending",
  Success = "Success",
  WaitControl = "WaitControl",
}

export enum EarningCategory {
  Allowance = "Allowance",
  Salary = "Salary",
}

export type EarningIntegrationVendorPayload = {
  productId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type EarningIntegrationVendorResponse = {
  url?: Maybe<Scalars["String"]["output"]>;
};

export enum EmployeeEligibilityStatus {
  Eligible = "Eligible",
  Ineligible = "Ineligible",
  Pending = "Pending",
  Resigned = "Resigned",
}

export type EmployeeInfo = {
  employeeId?: Maybe<Scalars["String"]["output"]>;
  fullName?: Maybe<Scalars["String"]["output"]>;
};

export type EmployeeUiComponent = UiManagement & {
  banner?: Maybe<StorageObject>;
  bannerV2?: Maybe<Banner_Banner>;
  content: Scalars["String"]["output"];
  displayPath: Scalars["String"]["output"];
  featureNames?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  header: Scalars["String"]["output"];
  identifier?: Maybe<Scalars["String"]["output"]>;
  maxCounter: Scalars["Int"]["output"];
  maxShowPerDay?: Maybe<Scalars["Int"]["output"]>;
  type: Ui_Type;
  waitTimeBetweenClosure: Scalars["String"]["output"];
};

export enum EmployeeWarningSource {
  Bot = "Bot",
  CanceledSettlement = "CanceledSettlement",
  ManualResignClicked = "ManualResignClicked",
  None = "None",
}

export enum EmployeeWarningStatus {
  Archived = "Archived",
  InProgress = "InProgress",
  Normal = "Normal",
  Settled = "Settled",
  Warning = "Warning",
}

/** All fields as Optional, depends on Company data & willingness to share */
export type Employment = {
  activeBanners: Array<Banner>;
  activeBannersV2: Array<Banner>;
  advanceablePeriods: Array<EmploymentAdvancePeriod>;
  agreement?: Maybe<EmploymentAgreement>;
  appReview?: Maybe<AppReviewStatus>;
  attendances: AttendanceConnection;
  bank?: Maybe<Bank>;
  bankAccount?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated No longer supported */
  bankAccountMasked?: Maybe<Scalars["String"]["output"]>;
  banner_activeBanners: Array<Banner_Banner>;
  campaigns?: Maybe<Array<EmploymentCampaign>>;
  cashbackAccount?: Maybe<CashbackUser>;
  communicationPost: CommunicationPost;
  communicationPosts: CommunicationPostConnection;
  company: Company;
  configByKey: Scalars["Boolean"]["output"];
  configByKeys: Scalars["JSON"]["output"];
  countUnSeenCommunicationPost: Scalars["Int"]["output"];
  currentBalance: Scalars["Money"]["output"];
  currentDepartment?: Maybe<Department>;
  currentWorkPeriod: EmploymentWorkPeriod;
  dob?: Maybe<Scalars["Date"]["output"]>;
  earnedBalance: Scalars["Money"]["output"];
  eligibilityStatus?: Maybe<EmployeeEligibilityStatus>;
  email?: Maybe<Scalars["Email"]["output"]>;
  employeeId?: Maybe<Scalars["String"]["output"]>;
  employeeWarningSource: EmployeeWarningSource;
  employeeWarningStatus: EmployeeWarningStatus;
  employerLegalName?: Maybe<Scalars["String"]["output"]>;
  extension?: Maybe<Scalars["JSON"]["output"]>;
  externalStatus: EmploymentExternalStatus;
  features: EmploymentFeature;
  fullName?: Maybe<Scalars["String"]["output"]>;
  fullNameNormalized?: Maybe<Scalars["String"]["output"]>;
  getLogChangeEmployment?: Maybe<Array<LogChangeEmployment>>;
  greetingCard: GreetingCard;
  greetingCardTemplates: GreetingCardTemplateConnection;
  /** @deprecated No longer supported */
  grossAllowance?: Maybe<Scalars["Money"]["output"]>;
  /** @deprecated No longer supported */
  grossSalary?: Maybe<Scalars["Money"]["output"]>;
  group?: Maybe<CompanyEmploymentGroup>;
  hraQuests?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id: Scalars["ID"]["output"];
  inActiveContentHome?: Maybe<InActiveContentHomeResponse>;
  independenceDay2020_config: IndependenceDay2020_EmploymentConfig;
  interestBalance: Scalars["Money"]["output"];
  invitation92021RewardAggregated: Invitation92021RewardAggregatedConnection;
  invitation92021Rewards: Invitation92021RewardConnection;
  invitation_popup: Invitation_PopupConnection;
  invitor?: Maybe<Employment>;
  isAgreedOnLatestToc: Scalars["Boolean"]["output"];
  isLinked: Scalars["Boolean"]["output"];
  /** @deprecated No longer supported */
  isPilotCompany: Scalars["Boolean"]["output"];
  kycProfileByFeatureName?: Maybe<ProfileResponse>;
  kycVas_status?: Maybe<KycVas_Status>;
  lanchi_allowance?: Maybe<Scalars["Money"]["output"]>;
  lanchi_grossSalary?: Maybe<Scalars["Money"]["output"]>;
  lanchi_offGrantShiftCountThreshold?: Maybe<Scalars["Int"]["output"]>;
  lanchi_shiftCountOffset?: Maybe<Scalars["Int"]["output"]>;
  lanchi_totalCompensation?: Maybe<Scalars["Money"]["output"]>;
  lastUpdateAttendanceDate?: Maybe<Scalars["Instant"]["output"]>;
  leaveRequest?: Maybe<LeaveRequest>;
  limitPercentageBySalaryPeriod: Scalars["Float"]["output"];
  linkDate?: Maybe<Scalars["LocalDate"]["output"]>;
  linkedDate?: Maybe<Scalars["LocalDate"]["output"]>;
  loanApplication: LoanApplication;
  loanApplications: LoanApplicationConnection;
  loanable?: Maybe<Scalars["Boolean"]["output"]>;
  lockStatus: EmploymentLockStatus;
  metadata?: Maybe<Scalars["JSON"]["output"]>;
  payLaterContract?: Maybe<StorageObject>;
  payLaterLateWarning?: Maybe<PayLaterLateWarningResponse>;
  payslip: Payslip;
  payslipHrData?: Maybe<HrDataResponse>;
  phoneNumber?: Maybe<Scalars["PhoneNumber"]["output"]>;
  prize_getPrizes: Prize_PrizeConnection;
  promotionPosts: Array<Promotions_FinedPromotion>;
  reasonByEmployment?: Maybe<Array<Maybe<ReasonDetail>>>;
  registrationStatus: EmploymentRegistrationStatus;
  resignData?: Maybe<ResignDataResponse>;
  resignDate?: Maybe<Scalars["LocalDate"]["output"]>;
  rewards?: Maybe<Array<Reward>>;
  salary?: Maybe<Scalars["Money"]["output"]>;
  /** @deprecated No longer supported */
  salaryCycleDayOfMonth?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated No longer supported */
  salaryCyclePeriod?: Maybe<Scalars["Duration"]["output"]>;
  salaryPerHour?: Maybe<Scalars["Money"]["output"]>;
  salaryPeriodEndDate: Scalars["OffsetDateTime"]["output"];
  salaryPeriodStartDate: Scalars["OffsetDateTime"]["output"];
  savingAccount?: Maybe<Saving_SavingAccount>;
  savingBalance: Scalars["Money"]["output"];
  savingWithdrawFeeConfig?: Maybe<Array<SavingWithdrawFeeConfig>>;
  saving_calculate: WithdrawCalculationResponse;
  saving_config?: Maybe<SavingConfig>;
  shiftCountNumber?: Maybe<Scalars["Float"]["output"]>;
  shiftCountUnit?: Maybe<ShiftUnit>;
  socialIdNumber?: Maybe<Scalars["String"]["output"]>;
  softpinAmountPolicy: Array<AmountPolicy>;
  softpinDailyQuotaAvailable: Scalars["Boolean"]["output"];
  softpinPeriodQuotaAvailable: Scalars["Boolean"]["output"];
  spdUser: SpdUser;
  spending_campaign?: Maybe<TenantCampaignDiscountConfig>;
  standardizedData: Array<Integration_EmploymentStandardizedData>;
  startDate?: Maybe<Scalars["LocalDate"]["output"]>;
  status?: Maybe<EmploymentStatus>;
  summaryAdvanceByDay: EmploymentSummaryAdvance;
  summaryAdvanceBySalaryPeriod: EmploymentSummaryAdvance;
  survey?: Maybe<Survey>;
  surveyForm: SurveyForm_EmploymentSurveyForm;
  surveyForms: SurveyForm_EmploymentSurveyFormConnection;
  surveys?: Maybe<Array<Survey>>;
  tenantCode: Scalars["String"]["output"];
  topUp_config: TopUpConfig;
  transferAmountPolicy?: Maybe<Array<Maybe<AmountPolicy>>>;
  transferDailyQuotaAvailable?: Maybe<Scalars["Boolean"]["output"]>;
  transferPeriodQuotaAvailable?: Maybe<Scalars["Boolean"]["output"]>;
  type?: Maybe<EmploymentType>;
  unreadGreetingCards: GreetingCardConnection;
  user?: Maybe<User>;
  virtualAccount?: Maybe<VirtualAccountResponse>;
  vouchers?: Maybe<Array<Maybe<Voucher>>>;
  vouchersV2: Array<Voucher>;
  vouchersV2_5?: Maybe<Array<Voucher>>;
  withdrawableBalance: Scalars["Money"]["output"];
  workPeriod: EmploymentWorkPeriod;
  workPeriods: EmploymentPeriodConnection;
  workingDayPerMonth?: Maybe<Scalars["Int"]["output"]>;
  workingHourPerDay?: Maybe<Scalars["Int"]["output"]>;
  workplace?: Maybe<Workplace>;
  yearInReview2021: YearInReview2021DataRowResponse;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentAttendancesArgs = {
  query?: AttendanceQuery;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentBanner_ActiveBannersArgs = {
  slot?: InputMaybe<Scalars["String"]["input"]>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentCampaignsArgs = {
  query?: InputMaybe<CampaignQuery>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentCommunicationPostArgs = {
  id: Scalars["ID"]["input"];
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentCommunicationPostsArgs = {
  query?: InputMaybe<CommunicationPostQuery>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentConfigByKeyArgs = {
  key?: InputMaybe<Scalars["String"]["input"]>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentConfigByKeysArgs = {
  keys: Array<Scalars["String"]["input"]>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentExtensionArgs = {
  key?: InputMaybe<Scalars["String"]["input"]>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentGetLogChangeEmploymentArgs = {
  query?: InputMaybe<LogChangeEmploymentQuery>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentGreetingCardArgs = {
  id: Scalars["ID"]["input"];
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentGreetingCardTemplatesArgs = {
  query?: InputMaybe<GreetingCardTemplateQuery>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentInvitation92021RewardAggregatedArgs = {
  query?: InputMaybe<Invitation92021RewardQuery>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentInvitation92021RewardsArgs = {
  query?: InputMaybe<Invitation92021RewardQuery>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentInvitation_PopupArgs = {
  query?: InputMaybe<Invitation_PopupQuery>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentKycProfileByFeatureNameArgs = {
  featureName?: InputMaybe<Scalars["String"]["input"]>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentLoanApplicationArgs = {
  id: Scalars["ID"]["input"];
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentLoanApplicationsArgs = {
  query?: InputMaybe<LoanApplicationQuery>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentPrize_GetPrizesArgs = {
  query?: InputMaybe<Prize_PrizeQuery>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentSaving_CalculateArgs = {
  payload: CreateWithdrawSavingTransactionPayload;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentStandardizedDataArgs = {
  lookUpKeys: Array<Scalars["String"]["input"]>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentSummaryAdvanceByDayArgs = {
  date: Scalars["Instant"]["input"];
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentSummaryAdvanceBySalaryPeriodArgs = {
  from: Scalars["Instant"]["input"];
  to: Scalars["Instant"]["input"];
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentSurveyFormArgs = {
  id: Scalars["ID"]["input"];
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentSurveyFormsArgs = {
  query?: InputMaybe<SurveyFormQuery>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentUnreadGreetingCardsArgs = {
  query?: InputMaybe<GreetingCardQuery>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentVirtualAccountArgs = {
  vendorCode?: InputMaybe<Scalars["String"]["input"]>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentVouchersV2Args = {
  type?: InputMaybe<Scalars["String"]["input"]>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentVouchersV2_5Args = {
  query?: InputMaybe<VoucherQuery>;
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentWorkPeriodArgs = {
  id: Scalars["ID"]["input"];
};

/** All fields as Optional, depends on Company data & willingness to share */
export type EmploymentWorkPeriodsArgs = {
  query?: InputMaybe<EmploymentWorkPeriodQuery>;
};

export type EmploymentAdvanceFeature = {
  notAllowToAdvance?: Maybe<Scalars["Boolean"]["output"]>;
  notAllowToDisburse?: Maybe<Scalars["Boolean"]["output"]>;
};

export type EmploymentAdvancePeriod = {
  endTimeInclusive: Scalars["Instant"]["output"];
  startTime: Scalars["Instant"]["output"];
  workPeriod: EmploymentWorkPeriod;
};

export type EmploymentAgreement = {
  agreedDate?: Maybe<Scalars["Instant"]["output"]>;
  agreementFile?: Maybe<StorageObject>;
  agreementHtmlFile?: Maybe<StorageObject>;
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  sign?: Maybe<EmploymentAgreementSign>;
  signObject?: Maybe<StorageObject>;
  status: EmploymentAgreementStatus;
  toc: TermAndCondition;
};

export type EmploymentAgreementSign = {
  createdDate: Scalars["Instant"]["output"];
  file: StorageObject;
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
};

export enum EmploymentAgreementStatus {
  Agreed = "Agreed",
  Generated = "Generated",
}

export type EmploymentAttendanceAlertData = {
  isShowMissingAttendanceButton: Scalars["Boolean"]["output"];
  lastAlertTime?: Maybe<Scalars["Instant"]["output"]>;
  lastAttendanceTime?: Maybe<Scalars["Instant"]["output"]>;
  tenantMissingAttendanceAllowance: Scalars["Int"]["output"];
};

export type EmploymentCampaign = {
  campaign: Campaign;
  currentProgress?: Maybe<CampaignProgress>;
  description?: Maybe<Scalars["String"]["output"]>;
  employment: Employment;
  progresses?: Maybe<Array<CampaignProgress>>;
};

export type EmploymentConditionNode = {
  args: Array<InputMaybe<AstNode>>;
  operator: Operator;
  type?: InputMaybe<EmploymentConditionNodeType>;
};

export enum EmploymentConditionNodeType {
  Default = "DEFAULT",
  SubQuery = "SUB_QUERY",
  SubQueryV2 = "SUB_QUERY_V2",
}

export type EmploymentConditionSuggestedEntry = {
  displayName?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type EmploymentConditionSuggestion = {
  availableOperators: Array<Maybe<Operator>>;
  availableValues?: Maybe<Array<Maybe<EmploymentConditionSuggestedEntry>>>;
  code: EmploymentFieldName;
  displayName: Scalars["String"]["output"];
  fieldType: ConditionType;
};

export type EmploymentConditionSuggestionNodes = {
  nodes: Array<Maybe<EmploymentConditionSuggestion>>;
};

export type EmploymentConditionSuggestionResponse = {
  segmentType?: Maybe<EmploymentConditionNodeType>;
  suggestion?: Maybe<EmploymentConditionSuggestionNodes>;
};

export type EmploymentConnection = {
  edges: Array<EmploymentEdge>;
  limit: Scalars["Int"]["output"];
  nodes: Array<Employment>;
  offset: Scalars["Int"]["output"];
  pageInfo: PageInfo;
  total: Scalars["Int"]["output"];
};

export type EmploymentEdge = {
  cursor: Scalars["String"]["output"];
  node: Employment;
};

export enum EmploymentExternalStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type EmploymentFeature = {
  advance?: Maybe<EmploymentAdvanceFeature>;
  advanceV2: Scalars["Boolean"]["output"];
  alertAttendance: EmploymentAttendanceAlertData;
  attendanceToggle: Scalars["Boolean"]["output"];
  autoEWA: Scalars["Boolean"]["output"];
  billing: Scalars["Boolean"]["output"];
  billingV2: BillingConfigV2;
  carrierDataConfig?: Maybe<CarrierDataConfig>;
  cashback?: Maybe<Scalars["Boolean"]["output"]>;
  cashbackDirectWithdrawal?: Maybe<Scalars["Boolean"]["output"]>;
  companyPay: Scalars["Boolean"]["output"];
  demo?: Maybe<TenantDemoInfo>;
  disableEwaSlider?: Maybe<Scalars["Boolean"]["output"]>;
  earning: Scalars["Boolean"]["output"];
  feature_attendance: FeatureRepresentation;
  feature_homeV4: FeatureRepresentation;
  floatIcons: Array<FloatIconResponse>;
  gamingConfig?: Maybe<GamingConfig>;
  homeV3: Scalars["Boolean"]["output"];
  hra?: Maybe<HraConfig>;
  invitation92021_advanced: Scalars["Boolean"]["output"];
  invitation92021_invited: Scalars["Boolean"]["output"];
  invitationCampaign: Scalars["Boolean"]["output"];
  invitationCampaignConfig?: Maybe<InvitationCampaignConfig>;
  isAllowToChangePhoneNumber: Scalars["Boolean"]["output"];
  isB2CEnable: Scalars["Boolean"]["output"];
  isSpendingEnable: Scalars["Boolean"]["output"];
  kycVas: Scalars["Boolean"]["output"];
  kycVendor?: Maybe<KycVendorConfig>;
  limitByPeriod: Scalars["Boolean"]["output"];
  newEWA?: Maybe<Scalars["Boolean"]["output"]>;
  noVoucher: Scalars["Boolean"]["output"];
  onboardDemo: Scalars["Boolean"]["output"];
  payLater: Scalars["Boolean"]["output"];
  paylater_popup?: Maybe<Array<PaylaterPopupResponse>>;
  payslipV2: Scalars["Boolean"]["output"];
  qr?: Maybe<Scalars["Boolean"]["output"]>;
  qr_TransferUI: EmploymentQrTransferConfig;
  saving: Scalars["Boolean"]["output"];
  savingConfigV2?: Maybe<Saving_SavingConfig>;
  shouldShowVoucher20222010_Popup: Scalars["Boolean"]["output"];
  softpin: Scalars["Boolean"]["output"];
  softpinV2: SoftpinConfigV2;
  surveyFormEnabled: Scalars["Boolean"]["output"];
  topUp: Scalars["Boolean"]["output"];
  transferBanner?: Maybe<StorageObject>;
  uiInstructions: Array<EmployeeUiComponent>;
  ui_homeStructure: BffResponse;
  unlinkable: Scalars["Boolean"]["output"];
  withdrawAllAmount: Scalars["Boolean"]["output"];
};

export type EmploymentFeatureUiInstructionsArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

export enum EmploymentFieldName {
  BankAccount = "bankAccount",
  BankId = "bankId",
  CloseNpsDay = "closeNpsDay",
  CloseShiftMealSurveyDay = "closeShiftMealSurveyDay",
  CompanyCode = "companyCode",
  CompanyId = "companyId",
  ContractType = "contractType",
  CountCloseShiftMealSurvey = "countCloseShiftMealSurvey",
  DepartmentId = "departmentId",
  Dob = "dob",
  EmployeeId = "employeeId",
  FullName = "fullName",
  Gender = "gender",
  GroupId = "groupId",
  Id = "id",
  IdNumber = "idNumber",
  LinkedDate = "linkedDate",
  LinkedDay = "linkedDay",
  PhoneNumber = "phoneNumber",
  RegisteredPhoneNumber = "registeredPhoneNumber",
  Salary = "salary",
  SalaryPerHour = "salaryPerHour",
  SeniorityDay = "seniorityDay",
  SeniorityMonth = "seniorityMonth",
  SeniorityYear = "seniorityYear",
  StartDate = "startDate",
  SubmitNpsDay = "submitNpsDay",
  SubmitShiftMealSurveyDay = "submitShiftMealSurveyDay",
  TenantId = "tenantId",
  WorkingDayPerMonth = "workingDayPerMonth",
  WorkingHourPerDay = "workingHourPerDay",
  WorkplaceId = "workplaceId",
}

export enum EmploymentLockStatus {
  Locked = "Locked",
  Unlocked = "Unlocked",
}

export enum EmploymentOrderable {
  EmployeeId = "EmployeeId",
  FullName = "FullName",
  Id = "Id",
  Status = "Status",
}

export type EmploymentPeriodConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<EmploymentWorkPeriod>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type EmploymentQrTransferConfig = {
  campaign?: Maybe<Scalars["String"]["output"]>;
  position: Qr_Position;
};

export type EmploymentQuery = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<EmploymentQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<EmploymentOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type EmploymentQueryFilter = {
  bankAccountSearch?: InputMaybe<Scalars["String"]["input"]>;
  bankIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  bankSearch?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * salary: MonetaryAmountRangeFilter
   * attendance: AttendanceRangeFilter
   */
  companyCodeSearch?: InputMaybe<StringFilterV2>;
  companyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  companyNameSearch?: InputMaybe<Scalars["String"]["input"]>;
  conditionNode?: InputMaybe<EmploymentConditionNode>;
  departmentIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  departmentSearch?: InputMaybe<StringFilterV2>;
  employeeIdSearch?: InputMaybe<Scalars["String"]["input"]>;
  employeeIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  employeeWarningSource?: InputMaybe<EmployeeWarningSource>;
  employeeWarningStatus?: InputMaybe<EmployeeWarningStatus>;
  extensions?: InputMaybe<Array<EmploymentQueryFilterExtension>>;
  externalStatuses?: InputMaybe<Array<EmploymentExternalStatus>>;
  fullNameSearch?: InputMaybe<Scalars["String"]["input"]>;
  groupIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  hasActiveTermination?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDisbursedLoanApplicationIn?: InputMaybe<OffsetDateTimeRange>;
  hasDisbursedLoanApplicationWorkPeriodIdIn?: InputMaybe<
    Array<Scalars["ID"]["input"]>
  >;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  isLinked?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLoaned?: InputMaybe<Scalars["Boolean"]["input"]>;
  linkDate?: InputMaybe<LocalDateRange>;
  lockStatuses?: InputMaybe<Array<EmploymentLockStatus>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumbers?: InputMaybe<Array<Scalars["String"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  registrationStatus?: InputMaybe<Array<EmploymentRegistrationStatus>>;
  resignDate?: InputMaybe<LocalDateRange>;
  statuses?: InputMaybe<Array<EmploymentStatus>>;
  tenantId?: InputMaybe<Core_TenantIdFilter>;
  type?: InputMaybe<EmploymentType>;
  userIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  userPhoneNumberSearch?: InputMaybe<StringFilterV2>;
  withSaving?: InputMaybe<Scalars["Boolean"]["input"]>;
  workplaceIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  workplaceSearch?: InputMaybe<Scalars["String"]["input"]>;
};

export type EmploymentQueryFilterExtension = {
  key: Scalars["String"]["input"];
  value?: InputMaybe<Scalars["JSON"]["input"]>;
};

export enum EmploymentRegistrationStatus {
  AcceptedAgreement = "AcceptedAgreement",
  NotAcceptAgreement = "NotAcceptAgreement",
  NotRegistered = "NotRegistered",
}

export type EmploymentSegment = {
  astTree?: InputMaybe<EmploymentConditionNode>;
};

export type EmploymentSegmentFilter = {
  employeeId?: InputMaybe<Scalars["String"]["input"]>;
  employmentSegment: EmploymentSegment;
};

export type EmploymentSegmentPreviewResponse = {
  employeeId?: Maybe<Scalars["String"]["output"]>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  salary?: Maybe<Scalars["Money"]["output"]>;
};

export type EmploymentSegmentQuery = {
  filter?: InputMaybe<EmploymentSegmentFilter>;
  limit: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
};

export type EmploymentSegmentResponse = {
  astTree?: Maybe<Scalars["JSON"]["output"]>;
};

export type EmploymentSignOtpPayload = {
  employmentId: Scalars["ID"]["input"];
  otp: Scalars["String"]["input"];
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
};

export type EmploymentStats = {
  total: Scalars["Int"]["output"];
};

export enum EmploymentStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type EmploymentSummaryAdvance = {
  count: Scalars["Int"]["output"];
  total: Scalars["Money"]["output"];
};

export type EmploymentSurveyResponseV2 = {
  createdDate: Scalars["Instant"]["output"];
  employment: Employment;
  responses: Array<SurveyResponseDetailV2>;
};

export enum EmploymentType {
  CasualTerm = "CasualTerm",
  FixedTerm = "FixedTerm",
  FullTime = "FullTime",
  PartTime = "PartTime",
  PermanentTerm = "PermanentTerm",
  Student = "Student",
  Unknown = "Unknown",
}

export type EmploymentWorkPeriod = {
  advanceValidation: EmploymentWorkPeriodAdvanceValidation;
  attendance: Attendance;
  attendances: AttendanceConnection;
  earnedBalance: Scalars["Money"]["output"];
  earnedBalanceCurrent: Scalars["Money"]["output"];
  earnedBalanceMax: Scalars["Money"]["output"];
  endTimeInclusive: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  loanApplication: LoanApplication;
  loanApplications: LoanApplicationConnection;
  startTime: Scalars["Instant"]["output"];
  transactionSummary?: Maybe<Array<TransactionSummary>>;
  withdrawableBalance: Scalars["Money"]["output"];
  withdrawbleBalanceV2: WithdrawableBalanceResponse;
};

export type EmploymentWorkPeriodAttendanceArgs = {
  id: Scalars["ID"]["input"];
};

export type EmploymentWorkPeriodAttendancesArgs = {
  query?: InputMaybe<AttendanceQuery>;
};

export type EmploymentWorkPeriodLoanApplicationArgs = {
  id: Scalars["ID"]["input"];
};

export type EmploymentWorkPeriodLoanApplicationsArgs = {
  query?: InputMaybe<LoanApplicationQuery>;
};

export type EmploymentWorkPeriodAdvanceValidation = {
  failure?: Maybe<EmploymentWorkPeriodAdvanceValidationFailure>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type EmploymentWorkPeriodAdvanceValidationFailure = {
  code: Scalars["String"]["output"];
  content: Scalars["I18nMessage"]["output"];
  extensions: EmploymentWorkPeriodAdvanceValidationFailureExtensions;
  mood: EmploymentWorkPeriodAdvanceValidationFailureMood;
  title: Scalars["I18nMessage"]["output"];
};

export type EmploymentWorkPeriodAdvanceValidationFailureExtensions = {
  deduction?: Maybe<
    Array<EmploymentWorkPeriodAdvanceableFailureExtensionsDeduction>
  >;
};

export enum EmploymentWorkPeriodAdvanceValidationFailureMood {
  Default = "Default",
  Positive = "Positive",
}

export type EmploymentWorkPeriodAdvanceableFailureExtensionsDeduction = {
  amount: Scalars["Money"]["output"];
  code: Scalars["String"]["output"];
  message: Scalars["I18nMessage"]["output"];
};

export enum EmploymentWorkPeriodOrderable {
  CreatedDate = "createdDate",
  Id = "id",
  LastModifiedDate = "lastModifiedDate",
  StartTime = "startTime",
}

export type EmploymentWorkPeriodQuery = {
  filter?: InputMaybe<EmploymentWorkPeriodQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<EmploymentWorkPeriodOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type EmploymentWorkPeriodQueryFilter = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type EwaTransferDetail = {
  amount: Scalars["Money"]["output"];
  fee: AdvanceFee;
  workPeriodEntry?: Maybe<EmploymentWorkPeriod>;
};

export type ExchangeVoucherAnnamPayload = {
  code: Scalars["String"]["input"];
  employmentId: Scalars["String"]["input"];
};

export type ExecuteChangePhoneNumberRequestPayload = {
  otp: Scalars["String"]["input"];
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ExportEntity = {
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  status: Status;
  storageObject: StorageObject;
  taskId: Scalars["ID"]["output"];
};

export type FailedInfo = {
  code?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type Feature = {
  id?: Maybe<Scalars["ID"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type FeatureInfo = {
  affectedEmployment?: Maybe<Scalars["Int"]["output"]>;
  effectiveEndDate?: Maybe<Scalars["Instant"]["output"]>;
  effectiveStartDate?: Maybe<Scalars["Instant"]["output"]>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
};

export type FeatureObject = {
  id?: Maybe<Scalars["ID"]["output"]>;
  name: Scalars["String"]["output"];
  uis: Array<Ui>;
};

export type FeatureRepresentation = {
  enabled: Scalars["Boolean"]["output"];
};

export enum FeeModel {
  Copay = "COPAY",
  Employee = "EMPLOYEE",
  Employer = "EMPLOYER",
}

export enum FeedbackAdminOrderable {
  Id = "Id",
}

export type FeedbackAdminQueryFilter = {
  keySearch?: InputMaybe<Scalars["String"]["input"]>;
};

export type FeedbackAdminResponse = {
  department?: Maybe<Scalars["String"]["output"]>;
  employeeId?: Maybe<Scalars["String"]["output"]>;
  employmentId?: Maybe<Scalars["ID"]["output"]>;
  feedbackDate?: Maybe<Scalars["LocalDate"]["output"]>;
  fullName?: Maybe<Scalars["String"]["output"]>;
};

export type FeedbackDetailResponse = {
  employmentFeedback?: Maybe<Scalars["String"]["output"]>;
};

export type FeedbackPayslipPayload = {
  data: Scalars["String"]["input"];
  endDate: Scalars["LocalDate"]["input"];
  startDate: Scalars["LocalDate"]["input"];
};

export type FeedbackQuery = {
  filter?: InputMaybe<FeedbackAdminQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<FeedbackAdminOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type FinaliseUserRegistrationPayload = {
  deviceToken: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type FinalizeChangePasswordRequestPayload = {
  password: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

export type FinalizeChangePhoneNumberRequestPayload = {
  password: Scalars["String"]["input"];
};

export type FinishUpdateEmploymentJobPayload = {
  companyId: Scalars["ID"]["input"];
  jobId: Scalars["ID"]["input"];
  payload?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type Flags = {
  serviceACBUnavailable: Scalars["Boolean"]["output"];
  serviceVPBUnavailable: Scalars["Boolean"]["output"];
  underConstruction: Scalars["Boolean"]["output"];
};

export type FloatIconResponse = UiComponentRepresentation & {
  action?: Maybe<Banner_ActionData>;
  actionData?: Maybe<Banner_ActionData>;
  actionType?: Maybe<Banner_ActionType>;
  code?: Maybe<Scalars["String"]["output"]>;
  icon: StorageObject;
  link?: Maybe<Scalars["String"]["output"]>;
  maxCounter: Scalars["Int"]["output"];
  waitTimeBetweenClosure: Scalars["String"]["output"];
};

export enum FlowChangePassword {
  Default = "Default",
  RequireIdNumber = "RequireIdNumber",
}

export type FormQuestion = {
  title: Scalars["String"]["output"];
  type: FormQuestionType;
};

export enum FormQuestionType {
  Checkbox = "CHECKBOX",
  CheckboxGrid = "CHECKBOX_GRID",
  Date = "DATE",
  Datetime = "DATETIME",
  Duration = "DURATION",
  FileUpload = "FILE_UPLOAD",
  Grid = "GRID",
  List = "LIST",
  MultipleChoice = "MULTIPLE_CHOICE",
  ParagraphText = "PARAGRAPH_TEXT",
  Scale = "SCALE",
  Text = "TEXT",
  Time = "TIME",
}

export enum FunctionType {
  Billing = "Billing",
  Data = "Data",
  Softpin = "Softpin",
  TopUp = "TopUp",
}

export type GamingConfig = {
  campaignCode: Scalars["String"]["output"];
  from?: Maybe<Scalars["Instant"]["output"]>;
  gameCode: Scalars["String"]["output"];
  icon?: Maybe<Scalars["String"]["output"]>;
  to?: Maybe<Scalars["Instant"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type GetBillResponse = {
  code: Scalars["String"]["output"];
  detail?: Maybe<BillResponseDetail>;
  isTrueBillCode: Scalars["Boolean"]["output"];
  message: Scalars["String"]["output"];
  packages?: Maybe<Array<GetBillResponsePackage>>;
  paidYet: Scalars["Boolean"]["output"];
};

export type GetBillResponsePackage = {
  description?: Maybe<Scalars["String"]["output"]>;
  infos?: Maybe<Array<GetBillResponsePackageInfo>>;
  name: Scalars["String"]["output"];
};

export type GetBillResponsePackageInfo = {
  code: Scalars["String"]["output"];
  periodInMonth?: Maybe<Scalars["Int"]["output"]>;
  periodInString?: Maybe<Scalars["String"]["output"]>;
  price: Scalars["Money"]["output"];
};

export type GetExistedPaymentResponse = {
  code: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  raw?: Maybe<Scalars["JSON"]["output"]>;
  retryable: Scalars["Boolean"]["output"];
};

export type GreetingCard = {
  amount?: Maybe<Scalars["Money"]["output"]>;
  content: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  sender: Employment;
  status?: Maybe<MessageStatus>;
  template: GreetingCardTemplate;
};

export type GreetingCardConnection = {
  limit?: Maybe<Scalars["Int"]["output"]>;
  nodes?: Maybe<Array<Maybe<GreetingCard>>>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type GreetingCardFilter = {
  haveAmount?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  receiverId?: InputMaybe<Scalars["ID"]["input"]>;
  unread?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum GreetingCardOrderable {
  Id = "Id",
}

export type GreetingCardQuery = {
  filter?: InputMaybe<GreetingCardFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<GreetingCardOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type GreetingCardTemplate = {
  amountColorCode: Scalars["String"]["output"];
  cardName: Scalars["String"]["output"];
  code: Scalars["String"]["output"];
  contentColorCode: Scalars["String"]["output"];
  defaultContent: Scalars["String"]["output"];
  errorColorCode: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  image: StorageObject;
  thumbnail?: Maybe<StorageObject>;
};

export type GreetingCardTemplateConnection = {
  limit?: Maybe<Scalars["Int"]["output"]>;
  nodes?: Maybe<Array<Maybe<GreetingCardTemplate>>>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type GreetingCardTemplateFilter = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type GreetingCardTemplateQuery = {
  filter?: InputMaybe<GreetingCardTemplateFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GreetingCardTemplateResponse = {
  greetingCardTemplate?: Maybe<GreetingCardTemplate>;
};

export type Header = {
  backgroundColor: Scalars["String"]["output"];
  logoObject?: Maybe<StorageObject>;
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type HighlightCompanySortPayload = {
  index: Scalars["Int"]["input"];
  onboardCompanyId: Scalars["ID"]["input"];
};

export type HomeDisplayInfo = {
  active: Scalars["Boolean"]["output"];
  text?: Maybe<Scalars["String"]["output"]>;
  textColor?: Maybe<Scalars["String"]["output"]>;
  type: FunctionType;
};

export type HrDataResponse = {
  email?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
};

export type HraConfig = {
  enable: Scalars["Boolean"]["output"];
  fromDate?: Maybe<Scalars["Date"]["output"]>;
  toDate?: Maybe<Scalars["Date"]["output"]>;
};

export type HraPrize = {
  description: Scalars["String"]["output"];
  longName?: Maybe<Scalars["String"]["output"]>;
  prizeImage?: Maybe<StorageObject>;
  quantity: Scalars["Int"]["output"];
  shortName?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type HraSendGiftPayload = {
  employmentId?: InputMaybe<Scalars["ID"]["input"]>;
  gifts?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type HraSendGiftResult = {
  voucher?: Maybe<Voucher>;
  voucherRemaining?: Maybe<Scalars["Int"]["output"]>;
};

export type HraSendStoryPayload = {
  answer?: InputMaybe<Scalars["String"]["input"]>;
  employmentId?: InputMaybe<Scalars["ID"]["input"]>;
  story?: InputMaybe<Scalars["String"]["input"]>;
};

export type HraSummary = {
  totalGift: Scalars["Int"]["output"];
  totalStory: Scalars["Int"]["output"];
};

export type HraSurveyPayload = {
  answer1?: InputMaybe<Scalars["String"]["input"]>;
  answer2?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
};

export type HraTemplateStory = {
  companyName: Scalars["String"]["output"];
  content: Array<Scalars["String"]["output"]>;
  icon?: Maybe<StorageObject>;
  thumbnail?: Maybe<StorageObject>;
  title: Scalars["String"]["output"];
  userName: Scalars["String"]["output"];
};

export type HraYearEndGiftResponse = {
  total?: Maybe<Scalars["Int"]["output"]>;
  totalApricot?: Maybe<Scalars["Int"]["output"]>;
  totalChungCake?: Maybe<Scalars["Int"]["output"]>;
  totalHearth?: Maybe<Scalars["Int"]["output"]>;
  totalKumquat?: Maybe<Scalars["Int"]["output"]>;
  totalPistachio?: Maybe<Scalars["Int"]["output"]>;
  totalRocket?: Maybe<Scalars["Int"]["output"]>;
  totalUser?: Maybe<Scalars["Int"]["output"]>;
};

export type HraYearEndResponse = {
  companyName: Scalars["String"]["output"];
  gender?: Maybe<Scalars["String"]["output"]>;
  gifts?: Maybe<HraYearEndGiftResponse>;
  icon?: Maybe<StorageObject>;
  largestVolume: Scalars["Int"]["output"];
  shortName?: Maybe<Scalars["String"]["output"]>;
  stories: Array<Maybe<HraYearEndStory>>;
  thanks: Array<Maybe<Scalars["String"]["output"]>>;
  totalLoanCreated: Scalars["Int"]["output"];
  userName: Scalars["String"]["output"];
};

export type HraYearEndStory = {
  content: Scalars["String"]["output"];
  username: Scalars["String"]["output"];
};

export enum HttpAuthType {
  Digest = "Digest",
  HttpBasic = "HTTPBasic",
}

export enum HttpMethodType {
  Delete = "DELETE",
  Get = "GET",
  Head = "HEAD",
  Options = "OPTIONS",
  Patch = "PATCH",
  Post = "POST",
  Put = "PUT",
}

export type IdFilterInput = {
  and?: InputMaybe<Array<IdFilterInput>>;
  eq?: InputMaybe<Scalars["ID"]["input"]>;
  in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  includes?: InputMaybe<Scalars["String"]["input"]>;
  normalizedIncludes?: InputMaybe<Scalars["String"]["input"]>;
  not?: InputMaybe<IdFilterInput>;
  or?: InputMaybe<Array<IdFilterInput>>;
};

export type ImageResponse = {
  imageContent?: Maybe<StorageObject>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type ImpersonateUserPayload = {
  scope?: InputMaybe<Scalars["String"]["input"]>;
  userId: Scalars["ID"]["input"];
};

export type ImpersonateUserResponse = {
  accessToken: Scalars["String"]["output"];
  expiresIn: Scalars["Duration"]["output"];
  user: User;
};

export type ImportApiAttendancePayload = {
  checkinSecond?: InputMaybe<Scalars["Int"]["input"]>;
  checkoutSecond?: InputMaybe<Scalars["Int"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  employeeId?: InputMaybe<Scalars["String"]["input"]>;
  overtimeHours?: InputMaybe<Scalars["Float"]["input"]>;
  submittedAt?: InputMaybe<Scalars["Instant"]["input"]>;
  workingDayType?: InputMaybe<WorkingDayType>;
  workingTimeHours?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImportChargeUserFromBusinessData = {
  companyCode: Scalars["String"]["input"];
  employeeId: Scalars["String"]["input"];
  isResign?: InputMaybe<Scalars["Boolean"]["input"]>;
  totalAmount: Scalars["Int"]["input"];
  transactionCode: Scalars["String"]["input"];
  workPeriodEntryId: Scalars["ID"]["input"];
};

export type ImportChargeUserFromBusinessPayLoad = {
  payDate: Scalars["LocalDate"]["input"];
  rows: Array<InputMaybe<ImportChargeUserFromBusinessData>>;
};

export type ImportChargeUserFromBusinessPayLoadV2 = {
  userPayBusinessId: Scalars["ID"]["input"];
};

export type ImportChargeUserFromUserPayLoad = {
  payDate: Scalars["OffsetDateTime"]["input"];
  phone: Scalars["String"]["input"];
  totalAmount: Scalars["Int"]["input"];
  transactionCode: Scalars["String"]["input"];
  transferContent: Scalars["String"]["input"];
};

export enum ImportJobMethod {
  Api = "API",
  Import = "Import",
}

export type ImportJobPayload = {
  bucket: Scalars["String"]["input"];
  jobMethod?: InputMaybe<ImportJobMethod>;
  jobType?: InputMaybe<ImportJobType>;
  key: Scalars["String"]["input"];
};

export enum ImportJobType {
  Attendance = "Attendance",
  Employment = "Employment",
}

export type InActiveContentHomeResponse = {
  content?: Maybe<Scalars["String"]["output"]>;
  inactiveType?: Maybe<InactiveType>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export enum InactiveType {
  Inactive = "Inactive",
  InactiveCompany = "InactiveCompany",
  Resign = "Resign",
}

export type IndependenceDay2020_EmploymentConfig = {
  remaining: Scalars["Int"]["output"];
};

export type IndependenceDay2020_Prize = {
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  type: IndependenceDay2020_PrizeType;
};

export enum IndependenceDay2020_PrizeType {
  ExternalVoucher = "ExternalVoucher",
  LoanVoucher1m = "LoanVoucher1m",
  LoanVoucher2m = "LoanVoucher2m",
  LoanVoucher200k = "LoanVoucher200k",
  LoanVoucher500k = "LoanVoucher500k",
  Nothing = "Nothing",
  PhoneCard10k = "PhoneCard10k",
  PhoneCard20k = "PhoneCard20k",
}

export type InitBackgroundImagePayload = {
  backgroundImageId: Scalars["ID"]["input"];
  code: Scalars["String"]["input"];
  os?: InputMaybe<Banner_Os>;
  ratio?: InputMaybe<Scalars["String"]["input"]>;
};

export type InquiryBankAccountPayload = {
  bankAccount?: InputMaybe<Scalars["String"]["input"]>;
  bankCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type InquiryBankAccountResponse = {
  bankAccountName?: Maybe<Scalars["String"]["output"]>;
  bankInfo?: Maybe<Bank>;
  error?: Maybe<Scalars["String"]["output"]>;
};

export type InstallmentLoanAdminQuery = {
  filter?: InputMaybe<InstallmentLoanAdminQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<InstallmentLoanOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type InstallmentLoanAdminQueryFilter = {
  amount?: InputMaybe<MoneyRangeFilter>;
  companyId?: InputMaybe<Scalars["ID"]["input"]>;
  createdDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  createdDateOffsetDateTimeRange?: InputMaybe<OffsetDateTimeRange>;
  createdDateRange?: InputMaybe<LocalDateRange>;
  employeeId?: InputMaybe<Scalars["String"]["input"]>;
  employmentIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  isWorkingStatus?: InputMaybe<Scalars["Boolean"]["input"]>;
  keySearch?: InputMaybe<Scalars["String"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<InstallmentLoanStatus>;
  statuses?: InputMaybe<Array<InputMaybe<InstallmentLoanStatus>>>;
  tenantIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  transactionCode?: InputMaybe<Scalars["String"]["input"]>;
  workPeriodEntryId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type InstallmentLoanAdminResponse = {
  amount?: Maybe<Scalars["Money"]["output"]>;
  bankAccountNumber?: Maybe<Scalars["String"]["output"]>;
  companyCode?: Maybe<Scalars["String"]["output"]>;
  createdDate?: Maybe<Scalars["Instant"]["output"]>;
  currentPeriod?: Maybe<Scalars["String"]["output"]>;
  employeeId?: Maybe<Scalars["String"]["output"]>;
  employment: Employment;
  employmentId?: Maybe<Scalars["ID"]["output"]>;
  fee?: Maybe<Scalars["Money"]["output"]>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  handledAt?: Maybe<Scalars["Instant"]["output"]>;
  handledBy?: Maybe<Scalars["String"]["output"]>;
  installmentLoanId?: Maybe<Scalars["ID"]["output"]>;
  lateStatus: Scalars["Boolean"]["output"];
  paylaterReasonDeclines: Array<PayLaterReasonDecline>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  reconcileDate?: Maybe<Scalars["LocalDate"]["output"]>;
  recoverAmount?: Maybe<Scalars["Money"]["output"]>;
  recoverAmountCurrentPeriod?: Maybe<Scalars["Money"]["output"]>;
  status?: Maybe<InstallmentLoanStatus>;
  summary: InstallmentLoanDetailResponse;
  tenantCode?: Maybe<Scalars["String"]["output"]>;
  transactionCode?: Maybe<Scalars["String"]["output"]>;
  virtualAccountNumber?: Maybe<Scalars["String"]["output"]>;
  workPeriod?: Maybe<Scalars["Int"]["output"]>;
};

export type InstallmentLoanDecline = {
  id?: Maybe<Scalars["ID"]["output"]>;
  reasons?: Maybe<Array<Maybe<PayLaterReasonDecline>>>;
};

export type InstallmentLoanDetailResponse = {
  actionHistory?: Maybe<Array<Maybe<ActionResponse>>>;
  appraisalInformation?: Maybe<AppraisalInformationResponse>;
  apr?: Maybe<Scalars["Float"]["output"]>;
  callLogHistory?: Maybe<Array<Maybe<CallLogResponse>>>;
  contentSettlementScreen?: Maybe<ContentSettlementScreenResponse>;
  earlyFee?: Maybe<Scalars["Money"]["output"]>;
  earlyFeeRate?: Maybe<Scalars["Float"]["output"]>;
  endDate?: Maybe<Scalars["LocalDate"]["output"]>;
  fee?: Maybe<Scalars["Money"]["output"]>;
  isCancelInstallmentLoan: Scalars["Boolean"]["output"];
  joinSegmentDate?: Maybe<Scalars["LocalDate"]["output"]>;
  lastLateWorkPeriodResponse?: Maybe<LastLateWorkPeriodResponse>;
  lateFee?: Maybe<Scalars["Money"]["output"]>;
  limitAmount?: Maybe<Scalars["Money"]["output"]>;
  numberSequence?: Maybe<Scalars["Int"]["output"]>;
  payLaterUserPayableResponses?: Maybe<
    Array<Maybe<PayLaterUserPayableResponse>>
  >;
  paymentAmount?: Maybe<Scalars["Money"]["output"]>;
  paymentHistory?: Maybe<Array<Maybe<PaymentHistoryDetailResponse>>>;
  paymentProcess?: Maybe<PaymentProcessResponse>;
  period: InstallmentLoanDetailResponse_Period;
  periodSummary: Array<Admin_Anhnvt_Installment_PeriodSummary>;
  principalAmount?: Maybe<Scalars["Money"]["output"]>;
  remainingAmount?: Maybe<Scalars["Money"]["output"]>;
  segmentName?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["LocalDate"]["output"]>;
  totalAmount: Scalars["Money"]["output"];
  totalFee?: Maybe<Scalars["Money"]["output"]>;
  totalInstallmentLoan?: Maybe<Scalars["Int"]["output"]>;
  transactionCode?: Maybe<Scalars["String"]["output"]>;
  virtualAccountNumber?: Maybe<Scalars["String"]["output"]>;
  workPeriod?: Maybe<Scalars["Int"]["output"]>;
  workPeriodsResponse?: Maybe<Array<Maybe<WorkPeriodResponse>>>;
};

export type InstallmentLoanDetailResponse_Period = {
  createdLoanDate: Scalars["Instant"]["output"];
  from: Scalars["Instant"]["output"];
  to: Scalars["Instant"]["output"];
};

export type InstallmentLoanHistoryResponse = {
  amount?: Maybe<Scalars["Money"]["output"]>;
  createdDate?: Maybe<Scalars["Instant"]["output"]>;
  disbursementCode?: Maybe<Scalars["String"]["output"]>;
  handledAt?: Maybe<Scalars["Instant"]["output"]>;
  handledBy?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  status?: Maybe<InstallmentLoanStatus>;
  workPeriod?: Maybe<Scalars["Int"]["output"]>;
};

export enum InstallmentLoanOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export type InstallmentLoanQuery = {
  filter?: InputMaybe<InstallmentLoanQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<InstallmentLoanOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type InstallmentLoanQueryFilter = {
  employmentId?: InputMaybe<Scalars["ID"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  status?: InputMaybe<InstallmentLoanStatus>;
};

export type InstallmentLoanResponse = {
  createdDate?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  description?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  disbursementCode?: Maybe<Scalars["String"]["output"]>;
  fee?: Maybe<Scalars["Money"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  lastLateWorkPeriodResponse?: Maybe<LastLateWorkPeriodResponse>;
  payLaterUserPayableResponses?: Maybe<
    Array<Maybe<PayLaterUserPayableResponse>>
  >;
  paylaterReasonDecline?: Maybe<PayLaterReasonDecline>;
  paymentAmount?: Maybe<Scalars["Money"]["output"]>;
  remainingAmount?: Maybe<Scalars["Money"]["output"]>;
  status?: Maybe<InstallmentLoanStatus>;
  sumAmount?: Maybe<Scalars["Money"]["output"]>;
  totalAmount?: Maybe<Scalars["Money"]["output"]>;
};

export enum InstallmentLoanStatus {
  Closed = "Closed",
  Created = "Created",
  Done = "Done",
  Pending = "Pending",
}

export type InstantFilter = {
  equalTo?: InputMaybe<Scalars["Instant"]["input"]>;
  greaterThan?: InputMaybe<Scalars["Instant"]["input"]>;
  greaterThanOrEqualTo?: InputMaybe<Scalars["Instant"]["input"]>;
  in?: InputMaybe<Array<Scalars["Instant"]["input"]>>;
  lessThan?: InputMaybe<Scalars["Instant"]["input"]>;
  lessThanOrEqualTo?: InputMaybe<Scalars["Instant"]["input"]>;
  notEqualTo?: InputMaybe<Scalars["Instant"]["input"]>;
  notIn?: InputMaybe<Array<Scalars["Instant"]["input"]>>;
};

export type InstantFilterInput = {
  and?: InputMaybe<Array<InstantFilterInput>>;
  eq?: InputMaybe<Scalars["Instant"]["input"]>;
  gt?: InputMaybe<Scalars["Instant"]["input"]>;
  gte?: InputMaybe<Scalars["Instant"]["input"]>;
  in?: InputMaybe<Array<Scalars["Instant"]["input"]>>;
  lt?: InputMaybe<Scalars["Instant"]["input"]>;
  lte?: InputMaybe<Scalars["Instant"]["input"]>;
  not?: InputMaybe<InstantFilterInput>;
  or?: InputMaybe<Array<InstantFilterInput>>;
};

export type InstantRange = {
  from: Scalars["Instant"]["output"];
  to: Scalars["Instant"]["output"];
};

export type InstantRangeInput = {
  from?: InputMaybe<Scalars["Instant"]["input"]>;
  to?: InputMaybe<Scalars["Instant"]["input"]>;
};

export type Invitation92021Reward = {
  activation: InvitationActivation;
  amount: Scalars["Money"]["output"];
  id: Scalars["ID"]["output"];
  invitee: Employment;
  inviter: Employment;
  reasonCode: Invitation92021RewardReasonCode;
};

export type Invitation92021RewardAggregated = {
  activation: InvitationActivation;
  amount: Scalars["Money"]["output"];
  invitee: Employment;
  inviter: Employment;
  type?: Maybe<Invitation92021RewardType>;
};

export type Invitation92021RewardAggregatedConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Invitation92021RewardAggregated>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Invitation92021RewardConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Invitation92021Reward>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Invitation92021RewardFilter = {
  employmentIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  inviteeEmploymentIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  inviterEmploymentIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  reasonCodes?: InputMaybe<Array<Invitation92021RewardReasonCode>>;
};

export enum Invitation92021RewardOrderable {
  Id = "id",
  InvitationActivationId = "invitationActivationId",
  InviteeEmploymentId = "inviteeEmploymentId",
  InviterEmploymentId = "inviterEmploymentId",
  Value = "value",
}

export type Invitation92021RewardQuery = {
  filter?: InputMaybe<Invitation92021RewardFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Invitation92021RewardOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export enum Invitation92021RewardReasonCode {
  InviteeFirstLoanApplicationApproved = "Invitee_FirstLoanApplicationApproved",
  InviterFirstInviteeLoanApplicationApproved = "Inviter_FirstInviteeLoanApplicationApproved",
  InviterInviteeAcceptedInvitation = "Inviter_InviteeAcceptedInvitation",
}

export enum Invitation92021RewardType {
  Invitee = "Invitee",
  Inviter = "Inviter",
}

export type InvitationActivation = {
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  status?: Maybe<InvitationActivationStatus>;
};

export enum InvitationActivationStatus {
  Accepted = "Accepted",
  Success = "Success",
}

export type InvitationCampaignConfig = {
  shouldShowBanner: Scalars["Boolean"]["output"];
};

export type InvitationCode = {
  code: Scalars["String"]["output"];
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
};

/** Invitation */
export type InvitationEmployment = {
  employeeId: Scalars["String"]["output"];
  employmentId: Scalars["ID"]["output"];
  fullName: Scalars["String"]["output"];
  lastPokeTime?: Maybe<Scalars["Instant"]["output"]>;
  phoneNumber?: Maybe<Scalars["PhoneNumber"]["output"]>;
};

export type InvitationEmploymentConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<InvitationEmployment>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type InvitationInviteeInfo = {
  inviteeFullName: Scalars["String"]["output"];
  inviteePhoneNumberMasked: Scalars["String"]["output"];
  isB2CUser: Scalars["Boolean"]["output"];
  rewardAmount?: Maybe<Scalars["Int"]["output"]>;
};

export type InvitationInviterInfo = {
  inviterFullName: Scalars["String"]["output"];
  inviterPhoneNumberMasked: Scalars["String"]["output"];
  totalInvitee?: Maybe<Scalars["Int"]["output"]>;
  totalRewardAmount?: Maybe<Scalars["Int"]["output"]>;
};

export type InvitationSummaryInfo = {
  currentPosition: Scalars["Int"]["output"];
  inviteeList?: Maybe<Array<InvitationInviteeInfo>>;
  totalInvitee: Scalars["Int"]["output"];
  totalRewardAmount: Scalars["Int"]["output"];
};

export enum InvoiceRescheduleType {
  B2C = "B2C",
  NextPeriod = "NextPeriod",
  PreviousPeriod = "PreviousPeriod",
}

export type InvoiceWorkPeriod = {
  endTimeInclusive: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  startTime: Scalars["Instant"]["output"];
};

export type Item = {
  actionData: Home_ActionData;
  backgroundColor: Scalars["String"]["output"];
  backgroundImageObject?: Maybe<StorageObject>;
  badgeBackgroundColor?: Maybe<Scalars["String"]["output"]>;
  badgeTextColor?: Maybe<Scalars["String"]["output"]>;
  badgeTitle?: Maybe<Scalars["String"]["output"]>;
  color: Scalars["String"]["output"];
  content: Scalars["String"]["output"];
  contentColor: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  order: Scalars["Int"]["output"];
  title: Scalars["String"]["output"];
  titleColor: Scalars["String"]["output"];
  type?: Maybe<Scalars["String"]["output"]>;
};

export type ItemColor = {
  countdownTextColor?: Maybe<Scalars["String"]["output"]>;
  remainingItemTextColor?: Maybe<Scalars["String"]["output"]>;
  seeMoreTextColor?: Maybe<Scalars["String"]["output"]>;
  selectedItemBackgroundColor?: Maybe<Scalars["String"]["output"]>;
  textBackgroundColor?: Maybe<Scalars["String"]["output"]>;
  textColor?: Maybe<Scalars["String"]["output"]>;
};

export type ItemContact = {
  bccAddress?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  ccAddress?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  companyId: Scalars["ID"]["input"];
  toAddress: Array<InputMaybe<Scalars["String"]["input"]>>;
};

export type ItemMailUploadFile = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  fileName: Scalars["String"]["input"];
  storageId: Scalars["ID"]["input"];
  type: Scalars["String"]["input"];
};

export type ItemScheduleGenerator = {
  cronjobExpression: Scalars["String"]["input"];
  endTime?: InputMaybe<Scalars["Instant"]["input"]>;
  name: Scalars["String"]["input"];
  parameters?: InputMaybe<Array<InputMaybe<ParameterInput>>>;
  startTime?: InputMaybe<Scalars["Instant"]["input"]>;
  status: Scalars["String"]["input"];
};

export type ItemTemplate = {
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  mailSubject: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
};

export type JobQueneResponse = {
  input?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type KycReminderResponse = {
  isRemind: Scalars["Boolean"]["output"];
  profile?: Maybe<ProfileResponse>;
};

export type KangarooLinkEmploymentPayload = {
  socialId?: InputMaybe<Scalars["String"]["input"]>;
};

export type KycVendorConfig = {
  vendorCode?: Maybe<Scalars["String"]["output"]>;
};

export type KycVendorData = {
  id: Scalars["ID"]["output"];
  oldIdNumber?: Maybe<Scalars["String"]["output"]>;
  vendorProfile?: Maybe<KycVendorProfile>;
};

export type KycVendorProfile = {
  address?: Maybe<Scalars["String"]["output"]>;
  expiredDate?: Maybe<Scalars["String"]["output"]>;
  faceMatch?: Maybe<Scalars["Float"]["output"]>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  idNumber?: Maybe<Scalars["String"]["output"]>;
  liveness?: Maybe<Scalars["Boolean"]["output"]>;
  oldIdNumber?: Maybe<Scalars["String"]["output"]>;
  socialIdIssuedDate?: Maybe<Scalars["LocalDate"]["output"]>;
  socialIdIssuedPlace?: Maybe<Scalars["String"]["output"]>;
};

export type LanchiApproveRequestPayload = {
  id: Scalars["ID"]["input"];
};

export type LanchiLinkRequest = {
  approvedBy?: Maybe<Scalars["String"]["output"]>;
  createdDate: Scalars["Instant"]["output"];
  expectedCompany: Company;
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  note?: Maybe<LanchiValidationErrCode>;
  rejectMessage?: Maybe<Scalars["String"]["output"]>;
  requestLink: RequestLink;
  status: LanchiLinkRequestStatus;
};

export enum LanchiLinkRequestStatus {
  FinalApproved = "FinalApproved",
  Initialized = "Initialized",
  /** TempApproved */
  Rejected = "Rejected",
}

export type LanchiRequestLinkConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<LanchiLinkRequest>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export enum LanchiRequestLinkOrderable {
  CreatedDate = "createdDate",
  Index = "index",
  LastModifiedDate = "lastModifiedDate",
  Status = "status",
}

export type LanchiRequestLinkQuery = {
  all?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<LanchiRequestLinkQueryFilter>;
  filters?: InputMaybe<Array<LanchiRequestLinkQueryFilter>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<LanchiRequestLinkOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type LanchiRequestLinkQueryFilter = {
  createdDate?: InputMaybe<OffsetDateTimeRange>;
  employeeString?: InputMaybe<Scalars["String"]["input"]>;
  fullInformation?: InputMaybe<Scalars["Boolean"]["input"]>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  lastModifiedDate?: InputMaybe<OffsetDateTimeRange>;
  phoneNumberString?: InputMaybe<Scalars["String"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  signedAgreement?: InputMaybe<Scalars["Boolean"]["input"]>;
  socialIdString?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Array<LanchiLinkRequestStatus>>;
};

export enum LanchiValidationErrCode {
  ErrWrongSocialIdInfo = "ERR_WRONG_SOCIAL_ID_INFO",
}

export type LastLateWorkPeriodResponse = {
  amount?: Maybe<Scalars["Money"]["output"]>;
  lateFee?: Maybe<Scalars["Money"]["output"]>;
  nonPerformingAmount?: Maybe<Scalars["Money"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  totalDayLate?: Maybe<Scalars["Int"]["output"]>;
};

export type LastSubmitSurveyResponse = {
  description?: Maybe<Scalars["String"]["output"]>;
  isShowShiftMealSurvey?: Maybe<Scalars["Boolean"]["output"]>;
  reasons?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  score?: Maybe<Scalars["Int"]["output"]>;
};

export type LeAssociateUserConsentPayload = {
  employeeCode: Scalars["String"]["input"];
};

export type LeAssociateUserConsentResponse = {
  employeeCode: Scalars["String"]["output"];
  phoneNumber: Scalars["PhoneNumber"]["output"];
};

export type LeaveRequest = {
  archivedDueDate?: Maybe<Scalars["Instant"]["output"]>;
  createdDate?: Maybe<Scalars["Instant"]["output"]>;
  executionStatus: LeaveRequestExecuteStatus;
  id: Scalars["ID"]["output"];
  lastModifiedDate?: Maybe<Scalars["Instant"]["output"]>;
  logs?: Maybe<Array<LeaveRequestLog>>;
  note?: Maybe<Scalars["String"]["output"]>;
  resignDate?: Maybe<Scalars["LocalDate"]["output"]>;
  resignStatus: LeaveRequestResignStatus;
};

export type LeaveRequestArchivePayload = {
  archiveDate: Scalars["Instant"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
};

export enum LeaveRequestExecuteStatus {
  Archived = "Archived",
  Failed = "Failed",
  Finished = "Finished",
  InProgress = "InProgress",
  Pending = "Pending",
}

export enum LeaveRequestFieldChangeable {
  ArchivedDueDate = "ArchivedDueDate",
  ExecuteDueDate = "ExecuteDueDate",
  ExecutionStatus = "ExecutionStatus",
  Note = "Note",
  ResignDate = "ResignDate",
  ResignStatus = "ResignStatus",
  ResignStatusChangeSource = "ResignStatusChangeSource",
}

export type LeaveRequestLog = {
  changes: LeaveRequestLogChanges;
  createdDate?: Maybe<Scalars["Instant"]["output"]>;
  id: Scalars["ID"]["output"];
  lastModifiedDate?: Maybe<Scalars["Instant"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
};

export type LeaveRequestLogChange = {
  field: LeaveRequestFieldChangeable;
  from?: Maybe<Scalars["String"]["output"]>;
  to?: Maybe<Scalars["String"]["output"]>;
};

export type LeaveRequestLogChanges = {
  changes: Array<LeaveRequestLogChange>;
};

export type LeaveRequestMutateResponse = {
  leaveRequest?: Maybe<LeaveRequest>;
};

export enum LeaveRequestResignStatus {
  Confirmed = "Confirmed",
  NotConfirmed = "NotConfirmed",
}

export type LeaveRequestUpdatePayload = {
  note: Scalars["String"]["input"];
  resignDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  resignStatus?: InputMaybe<LeaveRequestResignStatus>;
};

export type LimitAmountPayLaterResponse = {
  availableAmount?: Maybe<Scalars["Money"]["output"]>;
  limitAmount?: Maybe<Scalars["Money"]["output"]>;
  salary?: Maybe<Scalars["Money"]["output"]>;
  useAmount?: Maybe<Scalars["Money"]["output"]>;
};

export type LimitPercentageConfig = {
  createdDate: Scalars["Instant"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  effectiveEndDate?: Maybe<Scalars["Instant"]["output"]>;
  effectiveStartDate: Scalars["Instant"]["output"];
  employmentSegment?: Maybe<EmploymentSegmentResponse>;
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  limitPercentage: Scalars["Decimal"]["output"];
  name: Scalars["String"]["output"];
  priority: Scalars["Int"]["output"];
  status: CreditConfigStatus;
};

export enum LimitPercentageConfigOrderable {
  CreatedDate = "createdDate",
  Id = "id",
  LastModifiedDate = "lastModifiedDate",
  Priority = "priority",
}

export type LimitPercentageConfigPayload = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  effectiveEndDate?: InputMaybe<Scalars["Instant"]["input"]>;
  effectiveStartDate: Scalars["Instant"]["input"];
  employmentSegment?: InputMaybe<EmploymentSegment>;
  limitPercentage: Scalars["Decimal"]["input"];
  name: Scalars["String"]["input"];
  priority?: InputMaybe<Scalars["Int"]["input"]>;
  status: CreditConfigStatus;
  tenantId: Scalars["ID"]["input"];
};

export type LimitPercentageConfigQuery = {
  filter?: InputMaybe<LimitPercentageConfigQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<LimitPercentageConfigOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type LimitPercentageConfigQueryFilter = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  isEffective?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<CreditConfigStatus>;
  tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type LimitPercentageConfigResponse = {
  limit?: Maybe<Scalars["Int"]["output"]>;
  nodes: Array<Maybe<LimitPercentageConfig>>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type LimitPercentageConfigUpdatePayload = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  effectiveEndDate?: InputMaybe<Scalars["Instant"]["input"]>;
  effectiveStartDate?: InputMaybe<Scalars["Instant"]["input"]>;
  employmentSegment?: InputMaybe<EmploymentSegment>;
  id: Scalars["ID"]["input"];
  limitPercentage?: InputMaybe<Scalars["Decimal"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<CreditConfigStatus>;
};

export type LinkTenantPayload = {
  tenantId?: InputMaybe<Scalars["ID"]["input"]>;
  vendorId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ListPhoneBook = {
  label: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
};

export type LoanApplication = {
  actuallyReceived: Scalars["Money"]["output"];
  /** @deprecated use actuallyReceived */
  actuallyReceivedCurrency: Scalars["Currency"]["output"];
  /** @deprecated use actuallyReceived */
  actuallyReceivedValue: Scalars["Decimal"]["output"];
  /** @deprecated use amountValue & amountCurrency instead */
  amount: Scalars["Money"]["output"];
  amountCurrency: Scalars["Currency"]["output"];
  amountValue: Scalars["Decimal"]["output"];
  appliedVoucher?: Maybe<Voucher>;
  bankDisburseStatement: Scalars["I18nMessage"]["output"];
  code?: Maybe<Scalars["String"]["output"]>;
  createdDate: Scalars["Instant"]["output"];
  disbursement?: Maybe<Disbursement>;
  disbursementAccount: Scalars["String"]["output"];
  disbursementAccountName: Scalars["String"]["output"];
  disbursementBank: Bank;
  disbursementRepresentations?: Maybe<
    Array<Maybe<Disburser_DisbursementRepresentation>>
  >;
  employerFee: Scalars["Money"]["output"];
  employment: Employment;
  fee: Scalars["Money"]["output"];
  /** @deprecated use fee with x-debug-format-money: json instead */
  feeCurrency: Scalars["Currency"]["output"];
  /** @deprecated use fee with x-debug-format-money: json instead */
  feeValue: Scalars["Decimal"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  paymentDetail?: Maybe<PaymentDetail>;
  period: EmploymentAdvancePeriod;
  /** @deprecated No longer supported */
  purpose?: Maybe<Array<Scalars["String"]["output"]>>;
  receivableAmount: Scalars["Money"]["output"];
  rejectionMessage?: Maybe<Scalars["String"]["output"]>;
  rejectionReason?: Maybe<RejectLoanApplicationReason>;
  senderBankAccount: Scalars["String"]["output"];
  status: LoanApplicationStatus;
  transaction?: Maybe<Transaction>;
  type: LoanApplicationType;
};

export type LoanApplicationBatch = {
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  loanApplication: LoanApplication;
  loanApplications: LoanApplicationConnection;
  title: Scalars["String"]["output"];
};

export type LoanApplicationBatchLoanApplicationArgs = {
  id: Scalars["ID"]["input"];
};

export type LoanApplicationBatchLoanApplicationsArgs = {
  query?: LoanApplicationQuery;
};

export type LoanApplicationBatchConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<LoanApplicationBatch>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export enum LoanApplicationBatchOrderable {
  CreatedDate = "createdDate",
  Id = "id",
  LastModifiedDate = "lastModifiedDate",
  Title = "title",
}

export type LoanApplicationBatchQuery = {
  filter?: InputMaybe<LoanApplicationBatchQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<LoanApplicationBatchOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type LoanApplicationBatchQueryFilter = {
  createdDate?: InputMaybe<OffsetDateTimeRange>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type LoanApplicationConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<LoanApplication>;
  offset: Scalars["Int"]["output"];
  sumActuallyReceived: Array<Scalars["Money"]["output"]>;
  sumAmounts: Array<Scalars["Money"]["output"]>;
  sumEmployerFees: Array<Scalars["Money"]["output"]>;
  sumFees: Array<Scalars["Money"]["output"]>;
  sumReceivableAmounts: Array<Scalars["Money"]["output"]>;
  total: Scalars["Int"]["output"];
};

export type LoanApplicationCountGraphEntry = {
  x: Scalars["Instant"]["output"];
  y: Scalars["Int"]["output"];
};

export type LoanApplicationCountGraphEntryV2 = {
  x: Scalars["Instant"]["output"];
  y: Array<LoanApplicationCountGraphEntryV2_Detail>;
};

export type LoanApplicationCountGraphEntryV2_Detail = {
  count: Scalars["Int"]["output"];
  type: LoanApplicationType;
};

export type LoanApplicationCountGraphQuery = {
  createdDateAfter: Scalars["OffsetDateTime"]["input"];
  createdDateBefore: Scalars["OffsetDateTime"]["input"];
  statuses?: InputMaybe<Array<LoanApplicationStatus>>;
  stepPeriod: Scalars["Duration"]["input"];
};

export enum LoanApplicationOrderable {
  Amount = "Amount",
  CreatedDate = "CreatedDate",
  Id = "Id",
  LastModifiedDate = "LastModifiedDate",
  Status = "Status",
}

export type LoanApplicationQuery = {
  filter?: InputMaybe<LoanApplicationQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<LoanApplicationOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type LoanApplicationQueryFilter = {
  bankAccount?: InputMaybe<Scalars["String"]["input"]>;
  codes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  companyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  companySlugs?: InputMaybe<Array<Scalars["String"]["input"]>>;
  createdDate?: InputMaybe<OffsetDateTimeRange>;
  departmentIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  departmentSearch?: InputMaybe<Scalars["String"]["input"]>;
  disbursementBankSlugs?: InputMaybe<Array<Scalars["String"]["input"]>>;
  employeeId?: InputMaybe<Scalars["String"]["input"]>;
  employmentId?: InputMaybe<Scalars["ID"]["input"]>;
  employmentName?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  loanApplicationCode?: InputMaybe<Scalars["String"]["input"]>;
  money?: InputMaybe<MoneyRangeFilter>;
  month?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  retryable?: InputMaybe<Scalars["Boolean"]["input"]>;
  statuses?: InputMaybe<Array<LoanApplicationStatus>>;
  types?: InputMaybe<Array<LoanApplicationType>>;
  workPeriodEntryIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LoanApplicationStats = {
  sumAmounts: Array<Scalars["Money"]["output"]>;
  sumFees: Array<Scalars["Money"]["output"]>;
  total: Scalars["Int"]["output"];
};

export enum LoanApplicationStatus {
  Approved = "Approved",
  Closed = "Closed",
  DemoDisbursed = "DemoDisbursed",
  Disbursed = "Disbursed",
  LockForDisbursement = "LockForDisbursement",
  Pending = "Pending",
}

export enum LoanApplicationType {
  Advance = "Advance",
  Billing = "Billing",
  Card = "Card",
  DailyInterest = "DailyInterest",
  Data = "Data",
  Fee = "Fee",
  PayLater = "PayLater",
  Principal = "Principal",
  QrTransfer = "QrTransfer",
  Saving = "Saving",
  SpendingAdvance = "SpendingAdvance",
  SubscriptionPackage = "SubscriptionPackage",
  TopUp = "TopUp",
  Transfer = "Transfer",
  Unknown = "Unknown",
}

export type LocalDateRange = {
  from?: InputMaybe<Scalars["LocalDate"]["input"]>;
  fromInclusive?: InputMaybe<Scalars["LocalDate"]["input"]>;
  to?: InputMaybe<Scalars["LocalDate"]["input"]>;
  toInclusive?: InputMaybe<Scalars["LocalDate"]["input"]>;
};

export type LockEmploymentPayload = {
  reasonCode: Scalars["String"]["input"];
  reasonDetail?: InputMaybe<Scalars["String"]["input"]>;
  resignDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
};

export type LockPayload = {
  applicableDate?: InputMaybe<Scalars["Instant"]["input"]>;
  reasonCode: Scalars["String"]["input"];
  reasonDetail?: InputMaybe<Scalars["String"]["input"]>;
  resignDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
};

export type LogChangeEmployment = {
  action: Scalars["String"]["output"];
  createdDate?: Maybe<Scalars["Instant"]["output"]>;
  detail?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  newValue?: Maybe<Scalars["String"]["output"]>;
  oldValue?: Maybe<Scalars["String"]["output"]>;
  tenantId: Scalars["ID"]["output"];
  typeAction?: Maybe<Scalars["String"]["output"]>;
  typeId?: Maybe<Scalars["ID"]["output"]>;
  typeImpersonate?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<Scalars["String"]["output"]>;
};

export type LogChangeEmploymentQuery = {
  action?: InputMaybe<Scalars["String"]["input"]>;
  tenantId?: InputMaybe<Scalars["ID"]["input"]>;
  user?: InputMaybe<Scalars["String"]["input"]>;
};

export type LogFilter = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum LogOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export type LogPayload = {
  log?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  statusCode?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LogQuery = {
  filter?: InputMaybe<LogFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<LogOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type LoginPayload = {
  confirmSharingInformation?: InputMaybe<Scalars["Boolean"]["input"]>;
  deviceToken: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  scope?: InputMaybe<Scalars["String"]["input"]>;
};

export type LoginRefreshToken = {
  confirmSharingInformation?: InputMaybe<Scalars["Boolean"]["input"]>;
  refreshToken: Scalars["String"]["input"];
};

export type LoginResponse = {
  accessToken?: Maybe<Scalars["String"]["output"]>;
  expiresIn?: Maybe<Scalars["Int"]["output"]>;
  refreshExpiresIn?: Maybe<Scalars["Int"]["output"]>;
  refreshToken?: Maybe<Scalars["String"]["output"]>;
  scope?: Maybe<Scalars["String"]["output"]>;
};

export type MailConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<MailScheduleGenerator>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type MailContact = {
  bccAddress?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  ccAddress?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  companyId?: Maybe<Scalars["ID"]["output"]>;
  companyName?: Maybe<Scalars["String"]["output"]>;
  createdDate?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  lastModifiedDate?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  toAddress?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type MailContactAndCronJobPayload = {
  contact?: InputMaybe<MailContactPayload>;
  cronjobExpression?: InputMaybe<Scalars["String"]["input"]>;
  cronjobName?: InputMaybe<Scalars["String"]["input"]>;
  endTime?: InputMaybe<Scalars["Instant"]["input"]>;
  startTime?: InputMaybe<Scalars["Instant"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type MailContactPayload = {
  bccAddress?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  ccAddress?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  companyId?: InputMaybe<Scalars["ID"]["input"]>;
  mailContactId?: InputMaybe<Scalars["ID"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  toAddress?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MailFileUpload = {
  createdDate?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  fileName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  lastModifiedDate?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type MailHistory = {
  attachmentIds?: Maybe<Array<Maybe<StorageObject>>>;
  bccAddress?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  ccAddress?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  companyId?: Maybe<Scalars["ID"]["output"]>;
  companyName?: Maybe<Scalars["String"]["output"]>;
  content?: Maybe<Scalars["String"]["output"]>;
  createdDate?: Maybe<Scalars["Instant"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  mailDescription?: Maybe<Scalars["String"]["output"]>;
  mailTittle?: Maybe<Scalars["String"]["output"]>;
  sendAt?: Maybe<Scalars["Instant"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  toAddress?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type MailHistoryConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<MailHistory>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type MailHistoryFilter = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export enum MailHistoryOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
  SendAt = "SendAt",
}

export type MailHistoryQuery = {
  filters?: InputMaybe<Array<InputMaybe<MailHistoryFilter>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<MailHistoryOrderable>;
  orderDirection?: OrderDirection;
};

export type MailScheduleGenerator = {
  companyName?: Maybe<Scalars["String"]["output"]>;
  createdDate?: Maybe<Scalars["String"]["output"]>;
  cronjobExpression?: Maybe<Scalars["String"]["output"]>;
  cronjobExpressionName?: Maybe<Scalars["String"]["output"]>;
  endTime?: Maybe<Scalars["Instant"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  lastModifiedDate?: Maybe<Scalars["String"]["output"]>;
  mailContact?: Maybe<MailContact>;
  mailContactId?: Maybe<Scalars["ID"]["output"]>;
  mailTemplate?: Maybe<MailTemplates>;
  mailTemplateId?: Maybe<Scalars["ID"]["output"]>;
  parameters?: Maybe<Array<Maybe<Parameter>>>;
  startTime?: Maybe<Scalars["Instant"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  upcomingSchedule?: Maybe<Scalars["String"]["output"]>;
};

export type MailScheduleGeneratorAllPayload = {
  contactPayload?: InputMaybe<ItemContact>;
  scheduleGenerator?: InputMaybe<ItemScheduleGenerator>;
  template?: InputMaybe<ItemTemplate>;
  uploadFiles?: InputMaybe<Array<InputMaybe<ItemMailUploadFile>>>;
};

export type MailScheduleGeneratorFilter = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  endTime?: InputMaybe<Scalars["Instant"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  startTime?: InputMaybe<Scalars["Instant"]["input"]>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  templateCodes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export enum MailScheduleGeneratorOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
  LastModifiedDate = "LastModifiedDate",
  UpComingExecuteTime = "UpComingExecuteTime",
}

export type MailScheduleGeneratorPayload = {
  cronjobExpression?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  mailContactId?: InputMaybe<Scalars["ID"]["input"]>;
  mailTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
  parameters?: InputMaybe<Array<InputMaybe<ParameterInput>>>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type MailScheduleGeneratorQuery = {
  filters?: InputMaybe<Array<InputMaybe<MailScheduleGeneratorFilter>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<MailScheduleGeneratorOrderable>;
  orderDirection?: OrderDirection;
};

export type MailTemplateConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<MailTemplates>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type MailTemplatePayload = {
  attachmentIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  mailSubject?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  template?: InputMaybe<Scalars["String"]["input"]>;
  templateId?: InputMaybe<Scalars["ID"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type MailTemplates = {
  activeSchedules?: Maybe<Scalars["Int"]["output"]>;
  attachmentIds?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
  attachments?: Maybe<Array<Maybe<StorageObject>>>;
  code?: Maybe<Scalars["String"]["output"]>;
  createdDate?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  lastModifiedDate?: Maybe<Scalars["String"]["output"]>;
  mailSubject?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  storageId?: Maybe<Scalars["ID"]["output"]>;
  template?: Maybe<StorageObject>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type MailTemplatesFilter = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export enum MailTemplatesOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
  LastModifiedDate = "LastModifiedDate",
}

export type MailTemplatesQuery = {
  filters?: InputMaybe<Array<InputMaybe<MailTemplatesFilter>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<MailTemplatesOrderable>;
  orderDirection?: OrderDirection;
};

export type MailUploadFilePayload = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  fileName?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  storageId?: InputMaybe<Scalars["ID"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type MakeCheckRequest = {
  applyDate?: Maybe<Scalars["String"]["output"]>;
  cancelReason?: Maybe<Scalars["String"]["output"]>;
  companyId?: Maybe<Scalars["ID"]["output"]>;
  createdDate?: Maybe<Scalars["String"]["output"]>;
  detail?: Maybe<Makecheck_MakeCheckRequestDetail>;
  historyChange?: Maybe<Scalars["JSON"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  isResolvable?: Maybe<Scalars["Boolean"]["output"]>;
  issuedBy?: Maybe<Scalars["String"]["output"]>;
  lastModifiedBy?: Maybe<Scalars["String"]["output"]>;
  lastModifiedDate?: Maybe<Scalars["String"]["output"]>;
  plugin?: Maybe<Makecheck_TenantPlugin>;
  resolvedBy?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Makecheck_MakeCheckStatus>;
  storageObject?: Maybe<StorageObject>;
  storageObjectName?: Maybe<Scalars["String"]["output"]>;
  tenantCode?: Maybe<Scalars["String"]["output"]>;
  tenantId?: Maybe<Scalars["ID"]["output"]>;
  tenantStatistic?: Maybe<Makecheck_MakeCheckTenantDetail>;
  totalEmployment?: Maybe<Scalars["Int"]["output"]>;
  totalEmploymentWithdrawAble?: Maybe<Scalars["Int"]["output"]>;
  totalWithdrawalActual?: Maybe<Scalars["Money"]["output"]>;
  totalWithdrawalCurrent?: Maybe<Scalars["Money"]["output"]>;
  totalWithdrawalExpected?: Maybe<Scalars["Money"]["output"]>;
  totalWithdrawalLimit?: Maybe<Scalars["Money"]["output"]>;
  workPeriodEntryId?: Maybe<Scalars["ID"]["output"]>;
};

export type MakeCheckRequestConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<MakeCheckRequest>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type MakeCheckRequestFilter = {
  applyDate?: InputMaybe<OffsetDateTimeRange>;
  companyIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  createdDate?: InputMaybe<OffsetDateTimeRange>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  lastModifiedBys?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  searchString?: InputMaybe<StringFilterV2>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  tenantIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export enum MakeCheckRequestOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
  LastModifiedDate = "LastModifiedDate",
}

export type MakeCheckRequestQuery = {
  filter?: InputMaybe<MakeCheckRequestFilter>;
  filters?: InputMaybe<Array<InputMaybe<MakeCheckRequestFilter>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<MakeCheckRequestOrderable>;
  orderDirection?: OrderDirection;
};

export type MaskingDataLinkEmploymentPayload = {
  bankAccount: Scalars["String"]["input"];
  bankId: Scalars["ID"]["input"];
  companyId: Scalars["ID"]["input"];
  employeeId: Scalars["String"]["input"];
  socialId: Scalars["String"]["input"];
};

export type Message = {
  content: Scalars["I18nMessage"]["output"];
  createdDate: Scalars["Instant"]["output"];
  data?: Maybe<MessageBatch_MessageData>;
  group?: Maybe<MessageGroup>;
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  status: MessageStatus;
  tags: Array<Scalars["String"]["output"]>;
  title: Scalars["I18nMessage"]["output"];
  user: User;
};

export type MessageConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Message>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type MessageGroup = {
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  loanApplication?: Maybe<LoanApplication>;
  messages?: Maybe<Array<Message>>;
};

export type MessageQuery = {
  filter?: InputMaybe<MessageQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MessageQueryFilter = {
  fromDate?: InputMaybe<Scalars["Instant"]["input"]>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  notTags?: InputMaybe<Array<Scalars["String"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  statuses?: InputMaybe<Array<MessageStatus>>;
  tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
  toDate?: InputMaybe<Scalars["Instant"]["input"]>;
};

export enum MessageStatus {
  Read = "Read",
  Unread = "Unread",
}

export type Metadata = {
  onPrimaryBrand: Scalars["String"]["output"];
  onSubPrimaryBrand: Scalars["String"]["output"];
  primaryColor: Scalars["String"]["output"];
  sub2PrimaryBrand: Scalars["String"]["output"];
  subPrimaryBrand: Scalars["String"]["output"];
};

export type MoneyRangeFilter = {
  from?: InputMaybe<Scalars["Money"]["input"]>;
  to?: InputMaybe<Scalars["Money"]["input"]>;
};

export enum MonitorType {
  Mail = "Mail",
  Slack = "Slack",
}

export type MutateAttendanceResponse = {
  attendance?: Maybe<Attendance>;
};

export type MutateBannerResponse = {
  banner: Banner;
};

export type MutateBillResponse = {
  bill: Spending_Bill;
};

export type MutateChangePhoneNumberResponse = {
  request?: Maybe<UserChangePhoneNumberRequest>;
};

export type MutateCommunicationEventChartResponse = {
  limit: Scalars["Int"]["output"];
  nodes?: Maybe<Array<CommunicationEventChart>>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type MutateCommunicationEventLineResponse = {
  nodes?: Maybe<Array<CommunicationEventLineChart>>;
};

export type MutateCommunicationEventPipeResponse = {
  nodes?: Maybe<Array<CommunicationEventPieChart>>;
};

export type MutateCommunicationPostEventResponse = {
  communicationEvent: CommunicationEvent;
};

export type MutateCommunicationPostResponse = {
  communication: CommunicationPost;
};

export type MutateCompanyEmploymentGroupResponse = {
  group: CompanyEmploymentGroup;
};

export type MutateCompanyResponse = {
  company: Company;
};

export type MutateComponentResponse = {
  component: Ui_Component;
};

export type MutateEmploymentAgreementResponse = {
  agreement: EmploymentAgreement;
};

export type MutateEmploymentResponse = {
  employment: Employment;
};

export type MutateLoanApplicationBatchResponse = {
  loanApplicationBatch: LoanApplicationBatch;
};

export type MutateLoanApplicationResponse = {
  loanApplication: LoanApplication;
};

export type MutateMessageResponse = {
  message: Message;
};

export type MutateOnboardCompanyResponse = {
  onboardCompany: OnboardCompany;
};

export type MutateOrderResponse = {
  order: Order;
};

export type MutateRatingResponse = {
  rating: Rating;
};

export type MutateRequestLinkResponse = {
  request?: Maybe<RequestLink>;
};

export type MutateSurveyResponse = {
  survey?: Maybe<Survey>;
};

export type MutateTaskResponse = {
  task?: Maybe<Task>;
};

export type MutateTenantRoleResponse = {
  role: TenantRole;
};

export type MutateTenantUserResponse = {
  user: TenantUser;
};

export type MutateTopUpTransactionResponse = {
  loanApplication: LoanApplication;
};

export type MutateUserResponse = {
  user: User;
};

export type MutateVoucherResponse = {
  voucher?: Maybe<Voucher>;
};

export type Mutation = {
  ackJob?: Maybe<Scalars["Void"]["output"]>;
  activateCompany: MutateCompanyResponse;
  activation202206_claimReward?: Maybe<Scalars["Void"]["output"]>;
  activation202206_disableCampaign?: Maybe<Scalars["Void"]["output"]>;
  activation202206_enableCampaign?: Maybe<Scalars["Void"]["output"]>;
  activation202206_updateCampaignConfig?: Maybe<Scalars["Void"]["output"]>;
  activeInvitationCode: InvitationActivation;
  addCashback?: Maybe<Scalars["Void"]["output"]>;
  admin_ProcessBankStatement?: Maybe<Scalars["Void"]["output"]>;
  admin_ReconcileProcessVendorFile: Admin_ReconcileVendorFileResult;
  admin_ReconcileRetryVendorFile: Admin_ReconcileVendorFileResult;
  admin_RetryProcessBankStatement?: Maybe<Scalars["Void"]["output"]>;
  admin_SyncRawTxn?: Maybe<Scalars["Void"]["output"]>;
  admin_cancelContracts?: Maybe<Scalars["Void"]["output"]>;
  admin_canceledRevisedFiles?: Maybe<Scalars["Void"]["output"]>;
  admin_changePhoneNumber?: Maybe<Scalars["Void"]["output"]>;
  admin_closeInvoiceEntry?: Maybe<Scalars["Void"]["output"]>;
  /** admin_createReconcileTxnFromSource(rawTxnId: ID, payload: admin_CreateReconcileTxnPayload): Void */
  admin_closeReconcileTxn?: Maybe<Scalars["Void"]["output"]>;
  admin_createBankProviderTxn?: Maybe<Scalars["Void"]["output"]>;
  admin_createInvoice?: Maybe<Admin_Invoice>;
  admin_createInvoiceContracts: Array<Admin_Contract>;
  admin_createInvoiceDetail?: Maybe<Array<Admin_InvoiceDetail>>;
  admin_createInvoicePayment: Admin_InvoicePayment;
  admin_createRequestRecEntry?: Maybe<Scalars["Void"]["output"]>;
  admin_deleteInvoiceDetail?: Maybe<Scalars["Void"]["output"]>;
  admin_deleteInvoicePayment?: Maybe<Scalars["Void"]["output"]>;
  admin_doneRequestRecEntries?: Maybe<Scalars["Void"]["output"]>;
  admin_generateReportByEntryIdAndSendEmail?: Maybe<Scalars["Void"]["output"]>;
  admin_invoiceReport?: Maybe<Scalars["Void"]["output"]>;
  admin_invoiceSendReport?: Maybe<Scalars["Void"]["output"]>;
  admin_lockEmployees?: Maybe<Scalars["Void"]["output"]>;
  admin_lockEmployment: MutateEmploymentResponse;
  admin_moveToB2cCollection?: Maybe<Scalars["Void"]["output"]>;
  admin_moveToNextPeriod?: Maybe<Scalars["Void"]["output"]>;
  admin_moveToOtherInvoice?: Maybe<Scalars["Void"]["output"]>;
  admin_nextInvoiceEntry?: Maybe<Scalars["Void"]["output"]>;
  admin_paidInvoice?: Maybe<Scalars["Void"]["output"]>;
  admin_paidInvoiceEntry?: Maybe<Scalars["Void"]["output"]>;
  admin_processRequestRecEntries?: Maybe<Scalars["Void"]["output"]>;
  admin_refreshContractStatuses: Array<Admin_Contract>;
  admin_reopenInvoice?: Maybe<Scalars["Void"]["output"]>;
  admin_reopenInvoiceEntry?: Maybe<Scalars["Void"]["output"]>;
  admin_resignEmployees?: Maybe<Scalars["Void"]["output"]>;
  admin_retryConvertRevisedFiles?: Maybe<Scalars["Void"]["output"]>;
  admin_retryCreateContracts: Array<Admin_Contract>;
  admin_revertReconcileTxn?: Maybe<Scalars["Void"]["output"]>;
  admin_syncAllData?: Maybe<Scalars["Void"]["output"]>;
  admin_syncEmploymentAttendance?: Maybe<Scalars["Void"]["output"]>;
  admin_syncEmploymentsAttendance?: Maybe<Scalars["Void"]["output"]>;
  admin_syncEtlData?: Maybe<Scalars["Void"]["output"]>;
  admin_syncInvoiceBalanceDue?: Maybe<Scalars["Void"]["output"]>;
  admin_syncInvoiceBatch?: Maybe<Scalars["Void"]["output"]>;
  admin_syncInvoiceEntry?: Maybe<Scalars["Void"]["output"]>;
  admin_syncInvoiceEntryBatch?: Maybe<Scalars["Void"]["output"]>;
  admin_syncKeys?: Maybe<Scalars["Void"]["output"]>;
  admin_syncKeysFromConfig?: Maybe<Scalars["Void"]["output"]>;
  /** admin_reconcileReconcileTxn(ids: [ID!]): Void */
  admin_syncReconcileTxn?: Maybe<Scalars["Void"]["output"]>;
  admin_syncTenantAttendance?: Maybe<Scalars["Void"]["output"]>;
  admin_syncWorkPeriod?: Maybe<Scalars["Void"]["output"]>;
  admin_toggleTenantProduct?: Maybe<Scalars["Void"]["output"]>;
  admin_triggerAttendanceAutomation?: Maybe<Scalars["Void"]["output"]>;
  admin_unlockEmployees?: Maybe<Scalars["Void"]["output"]>;
  admin_unlockEmployment: MutateEmploymentResponse;
  admin_unlockUser: MutateUserResponse;
  admin_unpaidInvoice?: Maybe<Scalars["Void"]["output"]>;
  admin_unpaidInvoiceEntry?: Maybe<Scalars["Void"]["output"]>;
  admin_unreconciledInvoiceEntry?: Maybe<Scalars["Void"]["output"]>;
  admin_updateCashbackConfig?: Maybe<Scalars["Void"]["output"]>;
  admin_updateEmployment: MutateEmploymentResponse;
  admin_updateTenantDataConfig?: Maybe<Scalars["Void"]["output"]>;
  admin_uploadRevisedFiles?: Maybe<Array<Maybe<Admin_InvoiceRevisedFile>>>;
  admin_waitingForPaymentRequestRecEntries?: Maybe<Scalars["Void"]["output"]>;
  admin_writeOffInvoiceEntry?: Maybe<Scalars["Void"]["output"]>;
  admin_writeOffRequestRecEntries?: Maybe<Scalars["Void"]["output"]>;
  advanceV3_createLoanApplication: MutateOrderResponse;
  agreeTermAndCondition: MutateEmploymentAgreementResponse;
  alertMissingAttendance?: Maybe<Scalars["Void"]["output"]>;
  annam__exchangeVoucher: MutateVoucherResponse;
  annam__migrationEmployeeId?: Maybe<Scalars["Void"]["output"]>;
  annam__pushAdvance?: Maybe<Scalars["Void"]["output"]>;
  approveCompanyLinkEmploymentRequests?: Maybe<Scalars["Void"]["output"]>;
  approveLoanApplication: MutateLoanApplicationResponse;
  approveProfileKYC?: Maybe<Scalars["Void"]["output"]>;
  archiveLeaveRequest: LeaveRequestMutateResponse;
  assignEmploymentToGroup: MutateEmploymentResponse;
  assignRole?: Maybe<Scalars["Void"]["output"]>;
  assignTenantRole?: Maybe<AccessControl_AccessControl>;
  autoAttendance?: Maybe<Scalars["Void"]["output"]>;
  b2c_onboard: MutateEmploymentResponse;
  b2c_submitForm: B2c_MutateCollectedFormResponse;
  b2c_submitQuest: B2c_SubmitQuestResponse;
  b2c_submitZaloQuest: B2c_SubmitQuestResponse;
  b2c_withdrawCashback: CashbackTransaction;
  banner_CreateBackgroundImage?: Maybe<Banner_BackgroundImage>;
  banner_activateCampaign: Banner_BannerCampaignResponse;
  banner_createBanner: Banner_MutateBannerResponse;
  banner_createCampaign: Banner_BannerCampaignResponse;
  banner_deactivateCampaign: Banner_BannerCampaignResponse;
  banner_updateBanner: Banner_MutateBannerResponse;
  banner_updateCampaign: Banner_BannerCampaignResponse;
  batchApproveAttendances?: Maybe<Scalars["Void"]["output"]>;
  batchUpdateDisbursement?: Maybe<Scalars["Void"]["output"]>;
  bi_invokeFunction: Scalars["JSON"]["output"];
  bnpl_closeInstallmentLoan?: Maybe<Scalars["Void"]["output"]>;
  bnpl_disburseInstallmentLoan?: Maybe<Scalars["Void"]["output"]>;
  bnpl_saveAppraisal?: Maybe<Scalars["Void"]["output"]>;
  cadenaSyncAdvance?: Maybe<Scalars["Void"]["output"]>;
  calculateSavingTransaction?: Maybe<WithdrawCalculationResponse>;
  cancelAppReview?: Maybe<Scalars["Void"]["output"]>;
  cancelCronJob?: Maybe<Scalars["Void"]["output"]>;
  cancelInstallmentLoan?: Maybe<Scalars["Void"]["output"]>;
  cancelSettlementLeaveRequest: LeaveRequestMutateResponse;
  cancelSurveyForm: SurveyFormResponse;
  checkBalanceZns?: Maybe<Scalars["Void"]["output"]>;
  clearJob?: Maybe<Scalars["Void"]["output"]>;
  closeCompanyGroupWorkPeriod?: Maybe<Scalars["Void"]["output"]>;
  closeNpsSurvey?: Maybe<Scalars["Void"]["output"]>;
  closeShiftMealSurvey?: Maybe<Scalars["Void"]["output"]>;
  codepush_activateRelease: Codepush_ReleaseResponse;
  codepush_createRelease: Codepush_ReleaseResponse;
  codepush_deactivateRelease: Codepush_ReleaseResponse;
  codepush_deleteRelease?: Maybe<Scalars["Void"]["output"]>;
  codepush_updateRelease: Codepush_ReleaseResponse;
  configAutoEWA?: Maybe<Scalars["Void"]["output"]>;
  confirmResignManualLeaveRequest: LeaveRequestMutateResponse;
  confirmSettlement?: Maybe<Scalars["Void"]["output"]>;
  core_activateExtensionInstallation?: Maybe<Scalars["Void"]["output"]>;
  core_admin_createTenant: Core_MutateTenantResponse;
  core_admin_deactivateTenant: Core_MutateTenantResponse;
  core_admin_disableFeature?: Maybe<Scalars["Void"]["output"]>;
  core_admin_enableFeature?: Maybe<Scalars["Void"]["output"]>;
  core_admin_updateTenant: Core_MutateTenantResponse;
  core_deactivateExtensionInstallation?: Maybe<Scalars["Void"]["output"]>;
  core_exportTenantResources: Core_ExportTenantResourcesResponse;
  core_importEmployees?: Maybe<Scalars["Void"]["output"]>;
  core_importTenantResources?: Maybe<Scalars["Void"]["output"]>;
  core_installExtension: Core_InstallExtensionResponse;
  core_regenerateAgreement?: Maybe<Scalars["Void"]["output"]>;
  core_uninstallExtensionInstallation?: Maybe<Scalars["Void"]["output"]>;
  createActiveTermAndConditionCompany: TermAndCondition;
  createAdvertiser?: Maybe<Scalars["Void"]["output"]>;
  createAttendance: MutateAttendanceResponse;
  createAttendanceAutomationConfig?: Maybe<Scalars["Void"]["output"]>;
  createBanner: MutateBannerResponse;
  createBannerAssociation: BannerAssociationResponse;
  createBatchFile: CreateBatchFileResponse;
  createCallLog?: Maybe<Scalars["Void"]["output"]>;
  createCommunicationPost: MutateCommunicationPostResponse;
  createCommunicationPostEventClap: MutateCommunicationPostEventResponse;
  createCommunicationPostEventSeen: MutateCommunicationPostEventResponse;
  createCommunicationTemplate: CommunicationTemplate;
  createCompany: MutateCompanyResponse;
  createCompanyEmploymentGroup: MutateCompanyEmploymentGroupResponse;
  createCompanyLinkEmploymentRequest?: Maybe<MutateRequestLinkResponse>;
  createCronJob?: Maybe<Scalars["Void"]["output"]>;
  createCrvChild?: Maybe<Scalars["Void"]["output"]>;
  createDynamicLimitPercentage?: Maybe<Scalars["Void"]["output"]>;
  createEmploymentAttendanceAutomation?: Maybe<Scalars["Void"]["output"]>;
  createEmploymentRewardMessage: MutateMessageResponse;
  createEmploymentSurvey: MutateSurveyResponse;
  createGreetingCardTemplate?: Maybe<GreetingCardTemplateResponse>;
  createImportJob: MutateTaskResponse;
  createInstallmentLoan?: Maybe<CreateInstallmentLoanResponse>;
  createLoanApplication: MutateLoanApplicationResponse;
  createLoanApplicationBatch: MutateLoanApplicationBatchResponse;
  createLoanApplicationV2: CreateLoanApplicationV2Response;
  createMailScheduleGenerator?: Maybe<Scalars["Void"]["output"]>;
  createNpsSurvey?: Maybe<Scalars["Void"]["output"]>;
  createPayLaterSurvey?: Maybe<Scalars["Void"]["output"]>;
  createPhoneBook?: Maybe<Scalars["Void"]["output"]>;
  createPreAdvanceConfig?: Maybe<Scalars["Void"]["output"]>;
  createProfileKYC?: Maybe<Scalars["Void"]["output"]>;
  createProfileKYCV2?: Maybe<Scalars["Void"]["output"]>;
  createProfileKYCV3?: Maybe<Scalars["Void"]["output"]>;
  createProfileKYCV4?: Maybe<Scalars["Void"]["output"]>;
  createPublicStorageObject: CreateStorageObjectResponse;
  createReward: Reward;
  createRole?: Maybe<Scalars["Void"]["output"]>;
  createSegment?: Maybe<Scalars["Void"]["output"]>;
  createShiftMealSurvey?: Maybe<Scalars["Void"]["output"]>;
  createStorageObject: CreateStorageObjectResponse;
  createStorageObjectFromUri: CreateStorageObjectResponse;
  createSurvey?: Maybe<Scalars["Void"]["output"]>;
  createSurveyForm: SurveyFormResponse;
  createTenantRole: MutateTenantRoleResponse;
  createTenantUser: CreateTenantUserResponse;
  createTokenForProcessWorkplaceAttendanceFile: AuthResponse;
  createTopUpSavingTransaction: Array<LoanApplication>;
  createTopUpTransaction: MutateTopUpTransactionResponse;
  createUptimeMonitor?: Maybe<Scalars["Void"]["output"]>;
  createUserAdmin?: Maybe<Scalars["Void"]["output"]>;
  createUserStep?: Maybe<Scalars["Void"]["output"]>;
  createVendor?: Maybe<Scalars["Void"]["output"]>;
  /** testRetry: Boolean */
  createVendorKycProfile?: Maybe<KycVendorData>;
  createVirtualAccount?: Maybe<Scalars["Void"]["output"]>;
  createVoucher: MutateVoucherResponse;
  createVoucher_V2?: Maybe<Scalars["Void"]["output"]>;
  createWaitList?: Maybe<Scalars["Void"]["output"]>;
  createWithdrawSavingTransaction: Saving_SavingTransaction;
  crvInitChildren?: Maybe<Scalars["Void"]["output"]>;
  crv_init?: Maybe<Scalars["Void"]["output"]>;
  crv_migrate20211220?: Maybe<Scalars["Void"]["output"]>;
  crv_syncEmployee?: Maybe<Scalars["Void"]["output"]>;
  crv_syncEmployees?: Maybe<Scalars["Void"]["output"]>;
  crv_syncTaxCodeAndAddress?: Maybe<Scalars["Void"]["output"]>;
  crv_syncTnx?: Maybe<Scalars["Void"]["output"]>;
  deactivateCompany: MutateCompanyResponse;
  deactivateEmployment?: Maybe<Scalars["Void"]["output"]>;
  declineProfileKYC?: Maybe<Scalars["Void"]["output"]>;
  deleteAdvertiser?: Maybe<Scalars["Void"]["output"]>;
  deleteCommunicationPost?: Maybe<Scalars["Void"]["output"]>;
  deleteCommunicationTemplate?: Maybe<Scalars["Void"]["output"]>;
  /** @deprecated No longer supported */
  deleteCompany?: Maybe<Scalars["Void"]["output"]>;
  deleteMonitor?: Maybe<Scalars["Void"]["output"]>;
  deletePayslip?: Maybe<Scalars["Void"]["output"]>;
  deleteSurvey?: Maybe<Scalars["Void"]["output"]>;
  demo_linkEmployment: MutateEmploymentResponse;
  devicemanager_generateTask?: Maybe<Scalars["Void"]["output"]>;
  disburseLoanApplication: DisburseLoanApplicationResponse;
  disburser_executionResponse?: Maybe<Scalars["Void"]["output"]>;
  disburser_fail?: Maybe<Scalars["Void"]["output"]>;
  disburser_lock: Disburser_LockResponse;
  disburser_success?: Maybe<Scalars["Void"]["output"]>;
  disburserv2_closeDisbursement?: Maybe<Disburserv2_CloseDisbursementPayload>;
  disburserv2_deleteDisbursement?: Maybe<Disburserv2_DeleteDisbursementPayload>;
  disburserv2_failDisbursement?: Maybe<Disburserv2_FailDisbursementPayload>;
  disburserv2_lockDisbursements?: Maybe<Disburserv2_LockDisbursementsPayload>;
  disburserv2_retryFailedDisbursement?: Maybe<Disburserv2_RetryFailedDisbursementPayload>;
  disburserv2_succeedDisbursement?: Maybe<Disburserv2_SucceedDisbursementPayload>;
  disburserv2_unlockDisbursement?: Maybe<Disburserv2_UnlockDisbursementPayload>;
  earningIntegrationVendor?: Maybe<EarningIntegrationVendorResponse>;
  earningValidateOTP?: Maybe<Scalars["Void"]["output"]>;
  ewa_admin_createLoanFeeConfig: Ewa_MutateLoanFeeConfigResponse;
  ewa_admin_createLoanFeeConfigRule: Ewa_MutateLoanFeeConfigRuleResponse;
  ewa_admin_createPromotion: Ewa_CreatePromotionResponse;
  ewa_admin_updateLoanFeeConfig: Ewa_MutateLoanFeeConfigResponse;
  ewa_admin_updateLoanFeeConfigRule: Ewa_MutateLoanFeeConfigRuleResponse;
  ewa_admin_updatePromotion: Ewa_UpdatePromotionResponse;
  ewa_updateTenantEmployeeImportColumns?: Maybe<Scalars["Void"]["output"]>;
  exchangePreUserToken: Scalars["String"]["output"];
  exchangeRefreshToken: AuthResponse;
  exchangeTokenForPhoneNumber: Scalars["PhoneNumber"]["output"];
  executeLeaveRequest: LeaveRequestMutateResponse;
  expireCashback?: Maybe<Scalars["Void"]["output"]>;
  feedbackPayslip?: Maybe<Scalars["Void"]["output"]>;
  fileProcessor_createTask?: Maybe<FileProcessor_CreateTaskPayload>;
  fileProcessor_createTaskExecution?: Maybe<FileProcessor_CreateTaskExecutionPayload>;
  finalizeChangePasswordRequest: AuthResponse;
  finalizeUserRegistration: AuthResponse;
  findContactByPhoneNumber?: Maybe<ContactTransferResponse>;
  finishUpdateEmployments?: Maybe<Scalars["Void"]["output"]>;
  frt_syncAttendances?: Maybe<Scalars["Void"]["output"]>;
  frt_syncEmployees?: Maybe<Scalars["Void"]["output"]>;
  generateCompanyContract?: Maybe<TermAndCondition>;
  generateEmploymentAgreement: MutateEmploymentAgreementResponse;
  generateOtp?: Maybe<Scalars["String"]["output"]>;
  generateReportByEntryId?: Maybe<Scalars["Void"]["output"]>;
  generateReportForTenantByEntryId?: Maybe<Scalars["Void"]["output"]>;
  generateReportVendor?: Maybe<Scalars["Void"]["output"]>;
  getContactTransferInfo: TransferInfo;
  getOrCreateLeaveRequest: LeaveRequestMutateResponse;
  gs25_initBOGroup?: Maybe<Scalars["Void"]["output"]>;
  gs25_migrateEmploymentsV2?: Maybe<Scalars["Void"]["output"]>;
  gs25_syncBOAttendances?: Maybe<Scalars["Void"]["output"]>;
  haseca_processAttendanceFile: Lanchi_ProcessResponse;
  haseca_processAttendanceFileV2?: Maybe<Scalars["Void"]["output"]>;
  haseca_processEmploymentFile: Lanchi_ProcessResponse;
  /** Quest 1 */
  hra_SendGift?: Maybe<HraSendGiftResult>;
  /** Quest 2 */
  hra_SendStory?: Maybe<Scalars["String"]["output"]>;
  /** Survey */
  hra_Survey?: Maybe<Scalars["Void"]["output"]>;
  hypermarketAddDepartment?: Maybe<Scalars["Void"]["output"]>;
  impersonateUser: ImpersonateUserResponse;
  importAPIAttendances?: Maybe<Scalars["Void"]["output"]>;
  importChargeUserFromBusiness?: Maybe<Scalars["Void"]["output"]>;
  importChargeUserFromBusinessV2?: Maybe<Scalars["Void"]["output"]>;
  importChargeUserFromUser?: Maybe<Scalars["Void"]["output"]>;
  inactiveVendor?: Maybe<Scalars["Void"]["output"]>;
  independenceDay2020_acquirePrize: IndependenceDay2020_Prize;
  initAccessTrade?: Maybe<Scalars["Void"]["output"]>;
  initBanksKeywords?: Maybe<Scalars["Void"]["output"]>;
  initFamimart?: Maybe<Scalars["Void"]["output"]>;
  initGreenSpeed?: Maybe<Scalars["Void"]["output"]>;
  initGreenSpeedHgx?: Maybe<Scalars["Void"]["output"]>;
  initHsv?: Maybe<Scalars["Void"]["output"]>;
  initMinhNguyen?: Maybe<Scalars["Void"]["output"]>;
  initMobifonePlus?: Maybe<Scalars["Void"]["output"]>;
  initMutosi?: Maybe<Scalars["Void"]["output"]>;
  initPassio?: Maybe<Scalars["Void"]["output"]>;
  initPhamNguyen?: Maybe<Scalars["Void"]["output"]>;
  initTelio?: Maybe<Scalars["Void"]["output"]>;
  invitation92021_sendNotificationAllEndOfEvent92021?: Maybe<
    Scalars["Void"]["output"]
  >;
  invitation92021_sendNotificationParticipantEndOfEvent92021?: Maybe<
    Scalars["Void"]["output"]
  >;
  invitation_acceptInvitation?: Maybe<Scalars["Void"]["output"]>;
  invitation_generateInvitationCode: Scalars["String"]["output"];
  invitation_pokeFriend?: Maybe<Scalars["Void"]["output"]>;
  invitation_readAll?: Maybe<Scalars["Void"]["output"]>;
  invitation_validateInvitationCode?: Maybe<Scalars["Void"]["output"]>;
  io_syncToExternal?: Maybe<Scalars["Void"]["output"]>;
  kangaroo_linkEmployment: MutateEmploymentResponse;
  kangaroo_syncAttendances?: Maybe<Scalars["Void"]["output"]>;
  la_employeeConsent: LeAssociateUserConsentResponse;
  la_syncAttendances?: Maybe<Scalars["Void"]["output"]>;
  la_syncEmployee?: Maybe<Scalars["Void"]["output"]>;
  la_syncEmployees?: Maybe<Scalars["Void"]["output"]>;
  lanchi_finalApproveRequestLinks?: Maybe<Scalars["Void"]["output"]>;
  /** lanchi_finalApproveRequestLinks(ids: [ID!]!): Void */
  lanchi_processApproveEmploymentFile_V2: Lanchi_ProcessResponse;
  lanchi_processAttendanceFile?: Maybe<Scalars["Void"]["output"]>;
  lanchi_processAttendanceFileV2: Lanchi_ProcessResponse;
  lanchi_processAttendanceFileV3: Lanchi_ProcessResponse;
  lanchi_processEmploymentFile_V2?: Maybe<Scalars["Void"]["output"]>;
  lanchi_processEmploymentFile_V3: Lanchi_ProcessResponse;
  lanchi_processValidationEntitiesFile: Lanchi_ProcessResponse;
  lanchi_rejectRequestLinks?: Maybe<Scalars["Void"]["output"]>;
  linkEmployment: MutateEmploymentResponse;
  linkTenant?: Maybe<Scalars["Void"]["output"]>;
  loanApplication_retry?: Maybe<Scalars["Void"]["output"]>;
  lockEmployment: MutateEmploymentResponse;
  lockEmployments?: Maybe<Scalars["Void"]["output"]>;
  lockLoanApplicationForDisbursement?: Maybe<Array<LoanApplication>>;
  loctroi_syncMissingTxn?: Maybe<Scalars["Void"]["output"]>;
  login: AuthResponse;
  loginRefreshToken: AuthResponse;
  loginVendor?: Maybe<LoginResponse>;
  logivan_linkEmployment: MutateEmploymentResponse;
  logoutDevice?: Maybe<Scalars["Void"]["output"]>;
  makeCheck_cancel?: Maybe<Scalars["Void"]["output"]>;
  makeCheck_createNotificationProfiles?: Maybe<Scalars["Void"]["output"]>;
  makeCheck_disableNotificationProfiles?: Maybe<Scalars["Void"]["output"]>;
  makeCheck_resolve?: Maybe<Scalars["Void"]["output"]>;
  makeCheck_trigger?: Maybe<Scalars["Void"]["output"]>;
  manualCancelCampaign?: Maybe<Scalars["Void"]["output"]>;
  markAllAsRead?: Maybe<Scalars["Void"]["output"]>;
  markAsRead: MutateMessageResponse;
  markAsUsed?: Maybe<TopUpCard>;
  maskingData_linkEmployment: MutateEmploymentResponse;
  messageBatch_createMessageBatch: MessageBatch_MessageBatchResponse;
  messageBatch_reRun: MessageBatch_MessageBatchResponse;
  messageBatch_updateMessageBatch: MessageBatch_MessageBatchResponse;
  migrateCommissionDisbursement?: Maybe<Scalars["Void"]["output"]>;
  migrateEmploymentOLAP?: Maybe<Scalars["Void"]["output"]>;
  migrateOffSetSchedule?: Maybe<Scalars["Void"]["output"]>;
  migratePayableDate?: Maybe<Scalars["Void"]["output"]>;
  migrateProfileKYC?: Maybe<Scalars["Void"]["output"]>;
  migratePublisher?: Maybe<Scalars["Void"]["output"]>;
  migrateVirtualAccount?: Maybe<Scalars["Void"]["output"]>;
  mobifoneplus_init?: Maybe<Scalars["Void"]["output"]>;
  mobifoneplus_update20220330?: Maybe<Scalars["Void"]["output"]>;
  moveClevertap?: Maybe<Scalars["Void"]["output"]>;
  nguyenkim_init?: Maybe<Scalars["Void"]["output"]>;
  nguyenkim_linkEmployment: MutateEmploymentResponse;
  nguyenkim_syncAttendances?: Maybe<Scalars["Void"]["output"]>;
  onboard_upsertCompany: MutateOnboardCompanyResponse;
  operation_triggerMigrate?: Maybe<Scalars["Void"]["output"]>;
  operation_updateEmployeeByPayload?: Maybe<Scalars["Void"]["output"]>;
  order_closeOrder: MutateOrderResponse;
  order_closeOrderByCode?: Maybe<MutateOrderResponse>;
  order_createOrder: MutateOrderResponse;
  order_manualCorrectPendingVasOrder?: Maybe<Scalars["Void"]["output"]>;
  order_payOrder?: Maybe<Scalars["Void"]["output"]>;
  order_successOrder: MutateOrderResponse;
  order_successOrderByCode?: Maybe<MutateOrderResponse>;
  org_admin_createCompany: MutateCompanyResponse;
  org_admin_createWorkSchedule: Org_MutateWorkScheduleResponse;
  org_admin_createWorkScheduleRule: Org_MutateWorkScheduleRuleResponse;
  org_admin_findOrCreateTenantRootCompany: MutateCompanyResponse;
  org_admin_updateCompany: MutateCompanyResponse;
  org_admin_updateCompanyBillingInfo: MutateCompanyResponse;
  org_admin_updateWorkSchedule: Org_MutateWorkScheduleResponse;
  org_admin_updateWorkScheduleRule: Org_MutateWorkScheduleRuleResponse;
  pauseJob?: Maybe<Scalars["Void"]["output"]>;
  pauseMonitor?: Maybe<Scalars["Void"]["output"]>;
  payLaterValidateOTP?: Maybe<Scalars["Void"]["output"]>;
  paylater_alertRejection?: Maybe<Scalars["Void"]["output"]>;
  pmdashboard_addContact: Pmdashboard_TenantContactResponse;
  pmdashboard_deleteContact?: Maybe<Scalars["Void"]["output"]>;
  pmdashboard_pmUpdate?: Maybe<Scalars["Void"]["output"]>;
  pmdashboard_sync?: Maybe<Scalars["Void"]["output"]>;
  pmdashboard_syncAll?: Maybe<Scalars["Void"]["output"]>;
  pmdashboard_updateContact: Pmdashboard_TenantContactResponse;
  processApprovedAttendances?: Maybe<Scalars["Void"]["output"]>;
  processAttendanceFile?: Maybe<Scalars["Void"]["output"]>;
  processAttendanceFileV2: ProcessAttendanceFileV2Response;
  processEmploymentFile?: Maybe<Scalars["Void"]["output"]>;
  processEmploymentFileV2: ProcessEmploymentFileV2Response;
  processEmploymentFileV3: ProcessEmploymentFileV2Response;
  processTerminationFiles: ProcessTerminationFileResponse;
  processWorkplaceAttendanceFile?: Maybe<Scalars["Void"]["output"]>;
  promotion20220308_issueVouchers?: Maybe<Scalars["Void"]["output"]>;
  publishCommunicationPost: MutateCommunicationPostResponse;
  publishSurveyForm: SurveyFormResponse;
  pushImageNotification?: Maybe<Scalars["Void"]["output"]>;
  putEmploymentConfig?: Maybe<Scalars["Void"]["output"]>;
  reApproveProfileKYC?: Maybe<Scalars["Void"]["output"]>;
  reDeclineProfileKYC?: Maybe<Scalars["Void"]["output"]>;
  reInitGreenSpeedGroups?: Maybe<Scalars["Void"]["output"]>;
  reduceCashback?: Maybe<Scalars["Void"]["output"]>;
  registerDevice?: Maybe<Scalars["Void"]["output"]>;
  registerNotificationToken?: Maybe<Scalars["Void"]["output"]>;
  registerPublisher?: Maybe<Scalars["String"]["output"]>;
  registerUserPayLater?: Maybe<Scalars["String"]["output"]>;
  registerVendor?: Maybe<Scalars["Void"]["output"]>;
  registerWaitlist?: Maybe<Scalars["Void"]["output"]>;
  rejectChargeUserFromBusiness?: Maybe<Scalars["Void"]["output"]>;
  rejectCompanyLinkEmploymentRequest?: Maybe<Scalars["Void"]["output"]>;
  rejectLoanApplication: MutateLoanApplicationResponse;
  requestChangePassword: RequestChangePasswordResponse;
  requestChangePasswordV2: RequestChangePasswordResponse;
  requestDeviceToken: RequestDeviceTokenResponse;
  requestLogin: RequestLoginResponse;
  requestResendOtp: RequestResendOtpResponse;
  requestVerifyDevice?: Maybe<VerifyDeviceResponse>;
  resendOtp: SendUserOtpV2Response;
  /** deleteTenantUser(companyId: ID!, id: ID!): Void */
  resetTenantUserPassword: ResetTenantUserPasswordResponse;
  resumeJob?: Maybe<Scalars["Void"]["output"]>;
  resumeMonitor?: Maybe<Scalars["Void"]["output"]>;
  retryCreateVendorKycProfile?: Maybe<Scalars["Boolean"]["output"]>;
  rollbackCashback?: Maybe<CashbackTransaction>;
  sapttf_syncAdvances?: Maybe<Scalars["Void"]["output"]>;
  saveAppraisalKYC?: Maybe<Scalars["Void"]["output"]>;
  saveAsDraftCommunicationPost: MutateCommunicationPostResponse;
  saveFileUpload?: Maybe<Scalars["String"]["output"]>;
  saveInvitor: Scalars["Boolean"]["output"];
  savingAutoConfig_upsert?: Maybe<Scalars["Void"]["output"]>;
  saving_generateCloseFeatureAgreement: Saving_MutateSavingAccountAgreementResponse;
  saving_generateSavingAccountAgreement: Saving_MutateSavingAccountAgreementResponse;
  saving_initSavingAccount: Saving_MutateSavingAccountResponse;
  saving_manuallyIssueInterest?: Maybe<Scalars["Void"]["output"]>;
  saving_migrateExistedTransactions?: Maybe<Scalars["Void"]["output"]>;
  saving_migrateTotalBalance?: Maybe<Scalars["Void"]["output"]>;
  saving_signAgreementCloseFeature: Saving_MutateSavingAccountAgreementResponse;
  saving_signSavingAccountAgreement: Saving_MutateSavingAccountAgreementResponse;
  sendCutOffMessages?: Maybe<Scalars["Void"]["output"]>;
  sendFinedMessageToEmployments?: Maybe<Scalars["Void"]["output"]>;
  sendMessage: MutateMessageResponse;
  sendMessageToEmployments?: Maybe<Scalars["Void"]["output"]>;
  sendOtpPortal?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated use v2 */
  sendUserOtp?: Maybe<Scalars["Void"]["output"]>;
  sendUserOtpV2: SendUserOtpV2Response;
  sendVnrNotification?: Maybe<Scalars["Void"]["output"]>;
  sendZnsMessage?: Maybe<Scalars["Void"]["output"]>;
  setConfig?: Maybe<SetConfigPayload>;
  setRequiredPassword?: Maybe<Scalars["Void"]["output"]>;
  setTenantUserRole: MutateTenantUserResponse;
  shortlink_generate: Scalars["URI"]["output"];
  signAgreement?: Maybe<Scalars["Void"]["output"]>;
  spending_deactivateBill: MutateBillResponse;
  spending_getOrCreateBill: MutateBillResponse;
  spending_updateBillReminder: MutateBillResponse;
  startJobImmediately?: Maybe<Scalars["Void"]["output"]>;
  submitAppReview?: Maybe<AppReview>;
  /** @deprecated No longer supported */
  submitCompanyEmployeeWaitList?: Maybe<WaitList>;
  submitInAppReview?: Maybe<Scalars["Void"]["output"]>;
  submitToHandleChargeUserFromBusiness?: Maybe<Scalars["Void"]["output"]>;
  syncClevertapWithPhones?: Maybe<Scalars["Void"]["output"]>;
  syncEmploymentByFile?: Maybe<Scalars["Void"]["output"]>;
  syncEmploymentFromExternalSource?: Maybe<Scalars["Void"]["output"]>;
  syncFormStructure?: Maybe<Scalars["Void"]["output"]>;
  syncFromEmployment?: Maybe<Scalars["Void"]["output"]>;
  syncResponseSurveyForm?: Maybe<Scalars["Void"]["output"]>;
  syncTelioAttendances?: Maybe<Scalars["Void"]["output"]>;
  syncTelioBlackListEmployees?: Maybe<Scalars["Void"]["output"]>;
  syncTelioEmployees?: Maybe<Scalars["Void"]["output"]>;
  syncTelioTerminations?: Maybe<Scalars["Void"]["output"]>;
  syncTelioWhiteListEmployees?: Maybe<Scalars["Void"]["output"]>;
  syncTransferContacts?: Maybe<Scalars["Void"]["output"]>;
  sync_LocTroiAdvances?: Maybe<Scalars["Void"]["output"]>;
  sync_LocTroiEmployments?: Maybe<Scalars["Void"]["output"]>;
  sync_LocTroiPendingLinkEmployees?: Maybe<Scalars["Void"]["output"]>;
  system_closeAllExpiredWorkPeriods?: Maybe<Scalars["Void"]["output"]>;
  system_createEmploymentToken: Scalars["String"]["output"];
  system_syncCompanyAuth?: Maybe<Scalars["Void"]["output"]>;
  system_syncCompanyAuthCreateUser?: Maybe<Scalars["Void"]["output"]>;
  system_syncEmploymentTenant?: Maybe<Scalars["Void"]["output"]>;
  testLinkEmployment?: Maybe<Scalars["Void"]["output"]>;
  testSheet?: Maybe<Scalars["Void"]["output"]>;
  toggleSurveyForm: SurveyFormResponse;
  transfer_CreateTransferOrder?: Maybe<CreateTransferResponse>;
  triggerAlertEmployee?: Maybe<Scalars["Void"]["output"]>;
  triggerAttendanceAutomation?: Maybe<Scalars["Void"]["output"]>;
  triggerAttendanceReportAlert?: Maybe<Scalars["Void"]["output"]>;
  triggerEltTransform?: Maybe<Scalars["Void"]["output"]>;
  triggerEmployeeDataReportAlert?: Maybe<Scalars["Void"]["output"]>;
  triggerGenerateLateFee?: Maybe<Scalars["Void"]["output"]>;
  triggerGenerateScheduler?: Maybe<Scalars["Void"]["output"]>;
  triggerLoanApplicationErrorNotifications?: Maybe<Scalars["Void"]["output"]>;
  triggerMapLoanCore?: Maybe<Scalars["Void"]["output"]>;
  triggerSendMail?: Maybe<Scalars["Void"]["output"]>;
  tryAutoLinkEmployment: TryAutoLinkEmploymentResponse;
  twedu_linkEmployment: MutateEmploymentResponse;
  twedu_remappingTweduEmployment?: Maybe<Scalars["Void"]["output"]>;
  ui_createComponent: MutateComponentResponse;
  ui_updateComponent: MutateComponentResponse;
  unlinkEmployment?: Maybe<MutateEmploymentResponse>;
  unlinkTenant?: Maybe<Scalars["Void"]["output"]>;
  unlinkUserEmployment: MutateEmploymentResponse;
  unlockEmployment: MutateEmploymentResponse;
  unlockEmployments?: Maybe<Scalars["Void"]["output"]>;
  unpublishedCommunicationPost: MutateCommunicationPostResponse;
  unregisterNotificationToken?: Maybe<Scalars["Void"]["output"]>;
  updateAdvertiser?: Maybe<Scalars["Void"]["output"]>;
  updateAttendanceAutomationConfig?: Maybe<Scalars["Void"]["output"]>;
  updateBanner: MutateBannerResponse;
  updateBannerAssociation: BannerAssociationResponse;
  updateBatchedEmployments?: Maybe<Scalars["Void"]["output"]>;
  updateCommunicationPost: MutateCommunicationPostResponse;
  updateCompany: MutateCompanyResponse;
  updateCronJob?: Maybe<Scalars["Void"]["output"]>;
  updateDynamicLimitPercentage?: Maybe<Scalars["Void"]["output"]>;
  updateFlagServiceACBUnavailable?: Maybe<Scalars["Void"]["output"]>;
  updateFlagServiceVPBUnavailable?: Maybe<Scalars["Void"]["output"]>;
  updateFlagUnderConstruction?: Maybe<Scalars["Void"]["output"]>;
  updateGreetingCardTemplate: GreetingCardTemplateResponse;
  updateGroupAttendanceFileProcessor: UpdateGroupAttendanceFileProcessorResponse;
  updateGroupEmploymentFileProcessor: UpdateGroupEmploymentFileProcessorResponse;
  updateGroupPayslipCalculator: UpdateGroupPayslipCalculatorResponse;
  updateImportJob: MutateTaskResponse;
  updateLeaveRequest: LeaveRequestMutateResponse;
  updateLoanForPayLater?: Maybe<Scalars["Void"]["output"]>;
  updateMinThreshold?: Maybe<Scalars["String"]["output"]>;
  updateMoneyEsms?: Maybe<Scalars["Void"]["output"]>;
  updateMoneyImedia?: Maybe<Scalars["Void"]["output"]>;
  updateNotificationToken?: Maybe<Scalars["Void"]["output"]>;
  updatePayslip?: Maybe<Scalars["Void"]["output"]>;
  updatePreAdvanceConfig?: Maybe<Scalars["Void"]["output"]>;
  updateProfile: MutateUserResponse;
  updateProfileKYC?: Maybe<Scalars["Void"]["output"]>;
  updateProfileKYCAdmin?: Maybe<Scalars["Void"]["output"]>;
  updateSegment?: Maybe<Scalars["Void"]["output"]>;
  updateSurveyForm: SurveyFormResponse;
  updateTenantConfig?: Maybe<Scalars["Void"]["output"]>;
  updateTenantRole: MutateTenantRoleResponse;
  updateTenantUser: MutateTenantUserResponse;
  updateUptimeMonitor?: Maybe<Scalars["Void"]["output"]>;
  /** @deprecated No longer supported */
  updateUser: MutateUserResponse;
  updateVendor?: Maybe<Scalars["Void"]["output"]>;
  updateVendorPassword?: Maybe<Scalars["Void"]["output"]>;
  updateVendorTheme?: Maybe<Scalars["Void"]["output"]>;
  updateVirtualAccount?: Maybe<Scalars["Void"]["output"]>;
  updateWaitlistStatus?: Maybe<Scalars["Void"]["output"]>;
  uploadPayslip?: Maybe<Scalars["Void"]["output"]>;
  upsertCompanyEmailContact?: Maybe<Scalars["String"]["output"]>;
  upsertEmailSchedulerGeneratorContact?: Maybe<Scalars["Void"]["output"]>;
  upsertEmailTemplate?: Maybe<Scalars["String"]["output"]>;
  upsertHighlightOnboardCompanies?: Maybe<Scalars["Void"]["output"]>;
  upsertIntegrationTenantConfiguration?: Maybe<Scalars["String"]["output"]>;
  upsertMailScheduleGenerator?: Maybe<Scalars["String"]["output"]>;
  upsertRating: MutateRatingResponse;
  upsertTenantTopUpConfig?: Maybe<Scalars["Void"]["output"]>;
  user_cancelDeletionRequest: User_DeletionRequestResponse;
  user_changePassword?: Maybe<Scalars["Void"]["output"]>;
  user_confirmSharingInformation?: Maybe<Scalars["Void"]["output"]>;
  /** Create a request to change phone number */
  user_createChangePhoneNumberRequest: MutateChangePhoneNumberResponse;
  user_finalizeChangePhoneNumberRequest: User_FinalizeChangePhoneNumberResponse;
  user_requestDeletion: User_DeletionRequestResponse;
  user_resendChangePhoneNumberOtp?: Maybe<Scalars["Void"]["output"]>;
  /** Execute a request to change phone number */
  user_verifyOtpChangePhoneNumberRequest: MutateChangePhoneNumberResponse;
  validateVoucher?: Maybe<Scalars["Void"]["output"]>;
  verifyChangePasswordRequest: VerifyChangePasswordRequestResponse;
  verifyIdNumber?: Maybe<Scalars["Void"]["output"]>;
  verifyPassword: AuthResponse;
  verifyUserRegistration: VerifyUserRegistrationResponse;
  vuanem_syncAttendances?: Maybe<Scalars["Void"]["output"]>;
  vuanem_syncEmployees?: Maybe<Scalars["Void"]["output"]>;
  waitlist_addCompaniesToCampaign?: Maybe<Scalars["Void"]["output"]>;
  waitlist_crawl?: Maybe<Scalars["Void"]["output"]>;
  waitlist_createCampaign: Waitlist_CampaignResponse;
  waitlist_createCompany: Waitlist_CompanyMutateResponse;
  waitlist_deactivateWaitlistUser?: Maybe<Scalars["Void"]["output"]>;
  waitlist_manuallySyncContacts?: Maybe<Scalars["Void"]["output"]>;
  waitlist_registerCollab?: Maybe<Scalars["ID"]["output"]>;
  waitlist_registerUser: Waitlist_UserMutateResponse;
  waitlist_removeCompaniesFromCampaign?: Maybe<Scalars["Void"]["output"]>;
  waitlist_seedCompanies_vui4?: Maybe<Scalars["Void"]["output"]>;
  waitlist_syncHubspotCompanies?: Maybe<Scalars["Void"]["output"]>;
  waitlist_updateCampaign: Waitlist_CampaignResponse;
  waitlist_updateCompany: Waitlist_CompanyMutateResponse;
  waitlist_updateCompanyStageWorkingWithEmployer: Waitlist_CompanyMutateResponse;
  waitlist_updateUserInvitor: Waitlist_UserMutateResponse;
  waitlist_validateOTP?: Maybe<Scalars["Void"]["output"]>;
  withdrawCashback: CashbackTransaction;
  yearInReview_2021_markAsPopped?: Maybe<Scalars["Void"]["output"]>;
  yearInReview_2021_requestGenerateShareImages?: Maybe<
    Scalars["Void"]["output"]
  >;
  yody_syncAttendances?: Maybe<Scalars["Void"]["output"]>;
  yody_syncEmployees?: Maybe<Scalars["Void"]["output"]>;
};

export type MutationAckJobArgs = {
  payload: LogPayload;
};

export type MutationActivateCompanyArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationActivation202206_ClaimRewardArgs = {
  campaignId: Scalars["ID"]["input"];
};

export type MutationActivation202206_DisableCampaignArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationActivation202206_EnableCampaignArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationActivation202206_UpdateCampaignConfigArgs = {
  id: Scalars["ID"]["input"];
  payload: Activation202206_UpdateCampaignConfigPayload;
};

export type MutationActiveInvitationCodeArgs = {
  inviteeId: Scalars["ID"]["input"];
};

export type MutationAddCashbackArgs = {
  employmentId: Scalars["ID"]["input"];
  payload?: InputMaybe<CashbackPayload>;
};

export type MutationAdmin_ProcessBankStatementArgs = {
  payload?: InputMaybe<Admin_ProcessBankStatementPayload>;
};

export type MutationAdmin_ReconcileProcessVendorFileArgs = {
  payload: Admin_ProcessVendorReconciliationPayload;
};

export type MutationAdmin_ReconcileRetryVendorFileArgs = {
  vendorFileId: Scalars["ID"]["input"];
};

export type MutationAdmin_RetryProcessBankStatementArgs = {
  vendorFileId: Scalars["ID"]["input"];
};

export type MutationAdmin_CancelContractsArgs = {
  contractIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
};

export type MutationAdmin_CanceledRevisedFilesArgs = {
  revisedFileIds: Array<Scalars["ID"]["input"]>;
};

export type MutationAdmin_ChangePhoneNumberArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  newPhoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
};

export type MutationAdmin_CloseInvoiceEntryArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type MutationAdmin_CloseReconcileTxnArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  note: Scalars["String"]["input"];
};

export type MutationAdmin_CreateBankProviderTxnArgs = {
  payload: Org_CreateBankProviderTxnPayload;
};

export type MutationAdmin_CreateInvoiceArgs = {
  payload?: InputMaybe<Admin_CreateInvoicePayload>;
};

export type MutationAdmin_CreateInvoiceContractsArgs = {
  invoiceIds: Array<Scalars["ID"]["input"]>;
};

export type MutationAdmin_CreateInvoiceDetailArgs = {
  invoiceId: Scalars["ID"]["input"];
  payload?: InputMaybe<Array<Admin_CreateInvoiceDetailPayload>>;
};

export type MutationAdmin_CreateInvoicePaymentArgs = {
  invoiceId: Scalars["ID"]["input"];
  payload: Admin_CreateInvoicePaymentPayload;
};

export type MutationAdmin_CreateRequestRecEntryArgs = {
  payload?: InputMaybe<Admin_CreateRequestRecEntryPayload>;
};

export type MutationAdmin_DeleteInvoiceDetailArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  invoiceId: Scalars["ID"]["input"];
};

export type MutationAdmin_DeleteInvoicePaymentArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  invoiceId: Scalars["ID"]["input"];
};

export type MutationAdmin_DoneRequestRecEntriesArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  payload?: InputMaybe<Admin_DoneRequestRecEntryPayload>;
};

export type MutationAdmin_GenerateReportByEntryIdAndSendEmailArgs = {
  emails: Array<Scalars["Email"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type MutationAdmin_InvoiceReportArgs = {
  payload?: InputMaybe<Admin_SendReportPayload>;
};

export type MutationAdmin_InvoiceSendReportArgs = {
  id: Scalars["ID"]["input"];
  sendTo?: InputMaybe<Array<InputMaybe<Scalars["Email"]["input"]>>>;
};

export type MutationAdmin_LockEmployeesArgs = {
  payload: Admin_LockEmployee;
};

export type MutationAdmin_LockEmploymentArgs = {
  id: Scalars["ID"]["input"];
  payload?: InputMaybe<LockPayload>;
};

export type MutationAdmin_MoveToB2cCollectionArgs = {
  payload: Admin_RescheduleInvoicePayload;
};

export type MutationAdmin_MoveToNextPeriodArgs = {
  payload: Admin_RescheduleInvoicePayload;
};

export type MutationAdmin_MoveToOtherInvoiceArgs = {
  entryIds: Array<Scalars["ID"]["input"]>;
  toInvoiceId: Scalars["ID"]["input"];
};

export type MutationAdmin_NextInvoiceEntryArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type MutationAdmin_PaidInvoiceArgs = {
  invoiceId: Scalars["ID"]["input"];
  payload?: InputMaybe<Admin_PaidInvoicePayload>;
};

export type MutationAdmin_PaidInvoiceEntryArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type MutationAdmin_ProcessRequestRecEntriesArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type MutationAdmin_RefreshContractStatusesArgs = {
  contractIds: Array<Scalars["ID"]["input"]>;
};

export type MutationAdmin_ReopenInvoiceArgs = {
  invoiceId: Scalars["ID"]["input"];
};

export type MutationAdmin_ReopenInvoiceEntryArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type MutationAdmin_ResignEmployeesArgs = {
  payload: Admin_LockEmployee;
};

export type MutationAdmin_RetryConvertRevisedFilesArgs = {
  revisedFileIds: Array<Scalars["ID"]["input"]>;
};

export type MutationAdmin_RetryCreateContractsArgs = {
  contractIds: Array<Scalars["ID"]["input"]>;
};

export type MutationAdmin_RevertReconcileTxnArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  note: Scalars["String"]["input"];
};

export type MutationAdmin_SyncAllDataArgs = {
  configFileId: Scalars["ID"]["input"];
};

export type MutationAdmin_SyncEmploymentAttendanceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationAdmin_SyncEmploymentsAttendanceArgs = {
  id?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type MutationAdmin_SyncEtlDataArgs = {
  configFileId: Scalars["ID"]["input"];
};

export type MutationAdmin_SyncInvoiceBalanceDueArgs = {
  invoiceId: Scalars["ID"]["input"];
};

export type MutationAdmin_SyncInvoiceEntryArgs = {
  invoiceId: Scalars["ID"]["input"];
};

export type MutationAdmin_SyncKeysArgs = {
  configFileId: Scalars["ID"]["input"];
  keys?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type MutationAdmin_SyncKeysFromConfigArgs = {
  configFileId: Scalars["ID"]["input"];
};

export type MutationAdmin_SyncTenantAttendanceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationAdmin_ToggleTenantProductArgs = {
  id: Scalars["ID"]["input"];
  tenantId: Scalars["ID"]["input"];
};

export type MutationAdmin_TriggerAttendanceAutomationArgs = {
  payload?: InputMaybe<TriggerAttendanceAutomationPayload>;
};

export type MutationAdmin_UnlockEmployeesArgs = {
  payload: Admin_UnLockEmployee;
};

export type MutationAdmin_UnlockEmploymentArgs = {
  id: Scalars["ID"]["input"];
  payload?: InputMaybe<UnLockPayload>;
};

export type MutationAdmin_UnlockUserArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationAdmin_UnpaidInvoiceArgs = {
  invoiceId: Scalars["ID"]["input"];
};

export type MutationAdmin_UnpaidInvoiceEntryArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type MutationAdmin_UnreconciledInvoiceEntryArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type MutationAdmin_UpdateCashbackConfigArgs = {
  payload: Admin_UpdateCashbackPayload;
  tenantId: Scalars["ID"]["input"];
};

export type MutationAdmin_UpdateEmploymentArgs = {
  id: Scalars["ID"]["input"];
  payload: Admin_UpdateEmploymentPayload;
};

export type MutationAdmin_UpdateTenantDataConfigArgs = {
  payload: Admin_UpdateTenantDataConfig;
  tenantId: Scalars["ID"]["input"];
};

export type MutationAdmin_UploadRevisedFilesArgs = {
  invoiceId: Scalars["ID"]["input"];
  payload: UploadRevisedFilePayload;
};

export type MutationAdmin_WaitingForPaymentRequestRecEntriesArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type MutationAdmin_WriteOffInvoiceEntryArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type MutationAdmin_WriteOffRequestRecEntriesArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  payload?: InputMaybe<Admin_WriteOffRequestRecEntryPayload>;
};

export type MutationAdvanceV3_CreateLoanApplicationArgs = {
  employmentId: Scalars["ID"]["input"];
  payload: CreateAdvanceV3Payload;
};

export type MutationAgreeTermAndConditionArgs = {
  employmentId: Scalars["ID"]["input"];
};

export type MutationAlertMissingAttendanceArgs = {
  employmentId: Scalars["ID"]["input"];
};

export type MutationAnnam__ExchangeVoucherArgs = {
  payload: ExchangeVoucherAnnamPayload;
};

export type MutationAnnam__MigrationEmployeeIdArgs = {
  payload: AnnamMigrationEmployeeFile;
};

export type MutationAnnam__PushAdvanceArgs = {
  payload?: InputMaybe<AnnamPushAdvanceFile>;
};

export type MutationApproveCompanyLinkEmploymentRequestsArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type MutationApproveLoanApplicationArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationApproveProfileKycArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationArchiveLeaveRequestArgs = {
  leaveRequestId: Scalars["ID"]["input"];
  payload: LeaveRequestArchivePayload;
};

export type MutationAssignEmploymentToGroupArgs = {
  payload?: InputMaybe<AssignEmploymentToGroupPayload>;
};

export type MutationAssignRoleArgs = {
  payload: AssignRolePayload;
};

export type MutationAssignTenantRoleArgs = {
  payload?: InputMaybe<AccessControl_AssignTenantRolePayload>;
};

export type MutationAutoAttendanceArgs = {
  payload: AutoAttendancePayload;
};

export type MutationB2c_SubmitFormArgs = {
  payload?: InputMaybe<B2c_SubmitFormPayload>;
};

export type MutationB2c_SubmitQuestArgs = {
  payload: B2c_CreateMissionPayload;
};

export type MutationB2c_SubmitZaloQuestArgs = {
  payload?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationB2c_WithdrawCashbackArgs = {
  employmentId: Scalars["ID"]["input"];
  payload?: InputMaybe<CashbackWithdrawPayload>;
};

export type MutationBanner_CreateBackgroundImageArgs = {
  payload?: InputMaybe<InitBackgroundImagePayload>;
};

export type MutationBanner_ActivateCampaignArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationBanner_CreateBannerArgs = {
  payload?: InputMaybe<Banner_InitPayload>;
};

export type MutationBanner_CreateCampaignArgs = {
  payload?: InputMaybe<Banner_BannerCampaignInitPayload>;
};

export type MutationBanner_DeactivateCampaignArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationBanner_UpdateBannerArgs = {
  id: Scalars["ID"]["input"];
  payload?: InputMaybe<Banner_UpdatePayload>;
};

export type MutationBanner_UpdateCampaignArgs = {
  id: Scalars["ID"]["input"];
  payload?: InputMaybe<Banner_BannerCampaignUpdatePayload>;
};

export type MutationBatchApproveAttendancesArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type MutationBatchUpdateDisbursementArgs = {
  payload: Array<BatchDisbursementPayload>;
};

export type MutationBi_InvokeFunctionArgs = {
  body: Scalars["JSON"]["input"];
  function: Scalars["String"]["input"];
};

export type MutationBnpl_CloseInstallmentLoanArgs = {
  payload: DeclineInstallmentLoanPayLoad;
};

export type MutationBnpl_DisburseInstallmentLoanArgs = {
  id: Scalars["ID"]["input"];
  payload?: InputMaybe<Bnpl_AppraisalPayload>;
};

export type MutationBnpl_SaveAppraisalArgs = {
  id: Scalars["ID"]["input"];
  payload: Bnpl_AppraisalPayload;
};

export type MutationCalculateSavingTransactionArgs = {
  employmentId: Scalars["ID"]["input"];
  payload: CreateWithdrawSavingTransactionPayload;
};

export type MutationCancelAppReviewArgs = {
  employmentId: Scalars["ID"]["input"];
};

export type MutationCancelCronJobArgs = {
  taskId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCancelInstallmentLoanArgs = {
  payload?: InputMaybe<CancelInstallmentLoanPayLoad>;
};

export type MutationCancelSettlementLeaveRequestArgs = {
  leaveRequestId: Scalars["ID"]["input"];
};

export type MutationCancelSurveyFormArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCloseCompanyGroupWorkPeriodArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCodepush_ActivateReleaseArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCodepush_CreateReleaseArgs = {
  payload?: InputMaybe<Codepush_ReleaseInitPayload>;
};

export type MutationCodepush_DeactivateReleaseArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCodepush_DeleteReleaseArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCodepush_UpdateReleaseArgs = {
  id: Scalars["ID"]["input"];
  payload?: InputMaybe<Codepush_ReleaseUpdatePayload>;
};

export type MutationConfigAutoEwaArgs = {
  isOnAutoEWA: Scalars["Boolean"]["input"];
};

export type MutationConfirmResignManualLeaveRequestArgs = {
  leaveRequestId: Scalars["ID"]["input"];
};

export type MutationConfirmSettlementArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationCore_ActivateExtensionInstallationArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCore_Admin_CreateTenantArgs = {
  payload: Core_CreateTenantPayload;
};

export type MutationCore_Admin_DeactivateTenantArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCore_Admin_DisableFeatureArgs = {
  feature: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
};

export type MutationCore_Admin_EnableFeatureArgs = {
  feature: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
};

export type MutationCore_Admin_UpdateTenantArgs = {
  id: Scalars["ID"]["input"];
  payload: Core_UpdateTenantPayload;
};

export type MutationCore_DeactivateExtensionInstallationArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCore_ExportTenantResourcesArgs = {
  id: Scalars["ID"]["input"];
  payload: Core_ExportTenantResourcesPayload;
};

export type MutationCore_ImportEmployeesArgs = {
  payloads: Array<Core_ImportEmployeePayload>;
  storageObjectId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationCore_ImportTenantResourcesArgs = {
  id: Scalars["ID"]["input"];
  payload: Core_ImportTenantResourcesPayload;
};

export type MutationCore_InstallExtensionArgs = {
  payload: Core_InstallExtensionPayload;
  tenantId: Scalars["ID"]["input"];
};

export type MutationCore_RegenerateAgreementArgs = {
  newTermAndConditionObject: Scalars["ID"]["input"];
  tocId: Scalars["ID"]["input"];
};

export type MutationCore_UninstallExtensionInstallationArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCreateActiveTermAndConditionCompanyArgs = {
  companyId: Scalars["ID"]["input"];
  payload: CreateCompanyTermAndConditionPayload;
};

export type MutationCreateAdvertiserArgs = {
  createAdvertiserPayload?: InputMaybe<CreateAdvertiserPayload>;
};

export type MutationCreateAttendanceArgs = {
  payload: CreateAttendancePayload;
};

export type MutationCreateAttendanceAutomationConfigArgs = {
  payload: AttendanceAutomationConfigPayload;
};

export type MutationCreateBannerArgs = {
  payload: CreateBannerPayload;
};

export type MutationCreateBannerAssociationArgs = {
  payload: CreateBannerAssociationPayload;
};

export type MutationCreateBatchFileArgs = {
  payload: CreateBatchFilePayload;
};

export type MutationCreateCallLogArgs = {
  payload?: InputMaybe<CallLogPayLoad>;
};

export type MutationCreateCommunicationPostArgs = {
  companyId: Scalars["ID"]["input"];
  payload: CreateCommunicationPostPayload;
};

export type MutationCreateCommunicationPostEventClapArgs = {
  communicationId: Scalars["ID"]["input"];
  employmentId: Scalars["ID"]["input"];
};

export type MutationCreateCommunicationPostEventSeenArgs = {
  communicationId: Scalars["ID"]["input"];
  employmentId: Scalars["ID"]["input"];
};

export type MutationCreateCommunicationTemplateArgs = {
  payload: CreateCommunicationTemplatePayload;
};

export type MutationCreateCompanyArgs = {
  payload: CreateCompanyPayload;
};

export type MutationCreateCompanyEmploymentGroupArgs = {
  payload: CreateCompanyEmploymentGroupPayload;
};

export type MutationCreateCompanyLinkEmploymentRequestArgs = {
  companyId: Scalars["ID"]["input"];
  fake?: InputMaybe<Scalars["Boolean"]["input"]>;
  payload: CreateCompanyRequestLinkEmployment;
};

export type MutationCreateCronJobArgs = {
  payload: CreateCronJobPayload;
};

export type MutationCreateCrvChildArgs = {
  slug: Scalars["String"]["input"];
};

export type MutationCreateDynamicLimitPercentageArgs = {
  payload: LimitPercentageConfigPayload;
};

export type MutationCreateEmploymentAttendanceAutomationArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCreateEmploymentRewardMessageArgs = {
  employmentId: Scalars["ID"]["input"];
  payload: CreateEmploymentMessagePayload;
};

export type MutationCreateEmploymentSurveyArgs = {
  payload?: InputMaybe<CreateSurveyPayload>;
};

export type MutationCreateGreetingCardTemplateArgs = {
  payload: CreateGreetingCardTemplate;
};

export type MutationCreateImportJobArgs = {
  companyId: Scalars["ID"]["input"];
  payload: ImportJobPayload;
};

export type MutationCreateInstallmentLoanArgs = {
  payload?: InputMaybe<CreateInstallmentLoanPayLoad>;
};

export type MutationCreateLoanApplicationArgs = {
  payload: CreateLoanApplicationPayload;
};

export type MutationCreateLoanApplicationBatchArgs = {
  payload: CreateLoanApplicationBatchPayload;
};

export type MutationCreateLoanApplicationV2Args = {
  payload: CreateLoanApplicationV2Payload;
};

export type MutationCreateMailScheduleGeneratorArgs = {
  payload?: InputMaybe<MailScheduleGeneratorAllPayload>;
};

export type MutationCreateNpsSurveyArgs = {
  payload: CreateNpsSurveyPayload;
};

export type MutationCreatePayLaterSurveyArgs = {
  payload: CreatePayLaterSurveyPayload;
};

export type MutationCreatePhoneBookArgs = {
  payload: CreatePhonePayload;
};

export type MutationCreatePreAdvanceConfigArgs = {
  payload: PreAdvanceConfigPayload;
};

export type MutationCreateProfileKycArgs = {
  payload: CreateProfileKycPayload;
};

export type MutationCreateProfileKycv2Args = {
  payload: CreateProfileKycPayloadV2;
};

export type MutationCreateProfileKycv3Args = {
  payload: CreateProfileKycPayloadV3;
};

export type MutationCreateProfileKycv4Args = {
  payload: CreateProfileKycPayloadV4;
};

export type MutationCreatePublicStorageObjectArgs = {
  payload: CreateStorageObjectPayload;
};

export type MutationCreateRewardArgs = {
  payload: CreateRewardPayload;
};

export type MutationCreateRoleArgs = {
  payload: RolePayload;
};

export type MutationCreateSegmentArgs = {
  payload?: InputMaybe<CreateSegmentPayload>;
};

export type MutationCreateShiftMealSurveyArgs = {
  payload: CreateShiftMealSurveyPayload;
};

export type MutationCreateStorageObjectArgs = {
  payload: CreateStorageObjectPayload;
};

export type MutationCreateStorageObjectFromUriArgs = {
  payload: CreateStorageObjectFromUriPayload;
};

export type MutationCreateSurveyArgs = {
  payload?: InputMaybe<CreateEwaSurveyPayload>;
};

export type MutationCreateSurveyFormArgs = {
  payload: CreateSurveyForm;
};

export type MutationCreateTenantRoleArgs = {
  companyId: Scalars["ID"]["input"];
  payload: CreateRolePayload;
};

export type MutationCreateTenantUserArgs = {
  companyId: Scalars["ID"]["input"];
  payload: CreateTenantUserPayload;
};

export type MutationCreateTokenForProcessWorkplaceAttendanceFileArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCreateTopUpSavingTransactionArgs = {
  employmentId: Scalars["ID"]["input"];
  payloads: Array<CreateTopUpSavingTransactionPayload>;
};

export type MutationCreateTopUpTransactionArgs = {
  employmentId: Scalars["ID"]["input"];
  payload: CreateTopUpTransactionPayload;
};

export type MutationCreateUptimeMonitorArgs = {
  payload: CreateUptimeMonitorPayload;
};

export type MutationCreateUserAdminArgs = {
  payload?: InputMaybe<AdminUserPayload>;
};

export type MutationCreateUserStepArgs = {
  payload?: InputMaybe<CreateUserStepPayload>;
};

export type MutationCreateVendorArgs = {
  payload: VendorPayload;
};

export type MutationCreateVendorKycProfileArgs = {
  payload?: InputMaybe<CreateKycVendorPayload>;
};

export type MutationCreateVirtualAccountArgs = {
  employmentIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  vendorCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateVoucherArgs = {
  payload: CreateVoucherPayload;
};

export type MutationCreateVoucher_V2Args = {
  payload: CreateVoucherV2Payload;
};

export type MutationCreateWithdrawSavingTransactionArgs = {
  employmentId: Scalars["ID"]["input"];
  payload: CreateWithdrawSavingTransactionPayload;
};

export type MutationCrv_InitArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCrv_Migrate20211220Args = {
  id: Scalars["ID"]["input"];
};

export type MutationCrv_SyncEmployeeArgs = {
  eeCode?: InputMaybe<Scalars["String"]["input"]>;
  socialId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCrv_SyncTaxCodeAndAddressArgs = {
  idCompany: Scalars["String"]["input"];
  idStorageObject: Scalars["String"]["input"];
};

export type MutationDeactivateCompanyArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeactivateEmploymentArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeclineProfileKycArgs = {
  payload: DeclineProfileKyc;
};

export type MutationDeleteAdvertiserArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationDeleteCommunicationPostArgs = {
  communicationId: Scalars["ID"]["input"];
};

export type MutationDeleteCommunicationTemplateArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type MutationDeleteCompanyArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteMonitorArgs = {
  monitorId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationDeletePayslipArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationDeleteSurveyArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDemo_LinkEmploymentArgs = {
  id: Scalars["ID"]["input"];
  payload?: InputMaybe<DemoLinkEmploymentPayload>;
};

export type MutationDisburseLoanApplicationArgs = {
  id: Scalars["ID"]["input"];
  payload: DisburseLoanApplicationPayload;
};

export type MutationDisburser_ExecutionResponseArgs = {
  payload: DisburserExecution_DisbursementExecution;
};

export type MutationDisburser_FailArgs = {
  id: Scalars["ID"]["input"];
  payload: Disburser_FailPayload;
};

export type MutationDisburser_LockArgs = {
  source?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationDisburser_SuccessArgs = {
  id: Scalars["ID"]["input"];
  payload: Disburser_SuccessPayload;
};

export type MutationDisburserv2_CloseDisbursementArgs = {
  input: Disburserv2_CloseDisbursementInput;
};

export type MutationDisburserv2_DeleteDisbursementArgs = {
  input: Disburserv2_DeleteDisbursementInput;
};

export type MutationDisburserv2_FailDisbursementArgs = {
  input: Disburserv2_FailDisbursementInput;
};

export type MutationDisburserv2_LockDisbursementsArgs = {
  input: Disburserv2_LockDisbursementsInput;
};

export type MutationDisburserv2_RetryFailedDisbursementArgs = {
  input: Disburserv2_RetryFailedDisbursementInput;
};

export type MutationDisburserv2_SucceedDisbursementArgs = {
  input: Disburserv2_SucceedDisbursementInput;
};

export type MutationDisburserv2_UnlockDisbursementArgs = {
  input: Disburserv2_UnlockDisbursementInput;
};

export type MutationEarningIntegrationVendorArgs = {
  payload?: InputMaybe<EarningIntegrationVendorPayload>;
};

export type MutationEarningValidateOtpArgs = {
  contractId: Scalars["ID"]["input"];
  otpCode: Scalars["String"]["input"];
};

export type MutationEwa_Admin_CreateLoanFeeConfigArgs = {
  payload: Ewa_CreateLoanFeeConfigPayload;
};

export type MutationEwa_Admin_CreateLoanFeeConfigRuleArgs = {
  payload: Ewa_CreateLoanFeeConfigRulePayload;
};

export type MutationEwa_Admin_CreatePromotionArgs = {
  payload: Ewa_CreatePromotionPayload;
};

export type MutationEwa_Admin_UpdateLoanFeeConfigArgs = {
  id: Scalars["ID"]["input"];
  payload: Ewa_UpdateLoanFeeConfigPayload;
};

export type MutationEwa_Admin_UpdateLoanFeeConfigRuleArgs = {
  id: Scalars["ID"]["input"];
  payload: Ewa_UpdateLoanFeeConfigRulePayload;
};

export type MutationEwa_Admin_UpdatePromotionArgs = {
  id: Scalars["ID"]["input"];
  payload: Ewa_UpdatePromotionPayload;
};

export type MutationEwa_UpdateTenantEmployeeImportColumnsArgs = {
  payloads: Array<AttributeColumnPayload>;
};

export type MutationExchangePreUserTokenArgs = {
  token: Scalars["String"]["input"];
};

export type MutationExchangeRefreshTokenArgs = {
  token: Scalars["String"]["input"];
};

export type MutationExchangeTokenForPhoneNumberArgs = {
  token: Scalars["String"]["input"];
};

export type MutationExecuteLeaveRequestArgs = {
  employmentId: Scalars["ID"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationExpireCashbackArgs = {
  eventIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type MutationFeedbackPayslipArgs = {
  payload: FeedbackPayslipPayload;
};

export type MutationFileProcessor_CreateTaskArgs = {
  input: FileProcessor_CreateTaskInput;
};

export type MutationFileProcessor_CreateTaskExecutionArgs = {
  input: FileProcessor_CreateTaskExecutionInput;
};

export type MutationFinalizeChangePasswordRequestArgs = {
  payload: FinalizeChangePasswordRequestPayload;
};

export type MutationFinalizeUserRegistrationArgs = {
  payload: FinaliseUserRegistrationPayload;
};

export type MutationFindContactByPhoneNumberArgs = {
  phoneNumber: Scalars["PhoneNumber"]["input"];
};

export type MutationFinishUpdateEmploymentsArgs = {
  payload: FinishUpdateEmploymentJobPayload;
};

export type MutationGenerateCompanyContractArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationGenerateEmploymentAgreementArgs = {
  employmentId: Scalars["ID"]["input"];
  qualifier?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationGenerateOtpArgs = {
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
  phone: Scalars["PhoneNumber"]["input"];
};

export type MutationGenerateReportByEntryIdArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationGenerateReportForTenantByEntryIdArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationGenerateReportVendorArgs = {
  vendorIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type MutationGetContactTransferInfoArgs = {
  contactId: Scalars["ID"]["input"];
};

export type MutationGetOrCreateLeaveRequestArgs = {
  employmentId: Scalars["ID"]["input"];
};

export type MutationHaseca_ProcessAttendanceFileArgs = {
  payload: Haseca_ProcessAttendanceFilePayload;
};

export type MutationHaseca_ProcessAttendanceFileV2Args = {
  storageObjectId: Scalars["ID"]["input"];
};

export type MutationHaseca_ProcessEmploymentFileArgs = {
  payload: Haseca_ProcessEmploymentFilePayload;
};

export type MutationHra_SendGiftArgs = {
  payload?: InputMaybe<HraSendGiftPayload>;
};

export type MutationHra_SendStoryArgs = {
  payload?: InputMaybe<HraSendStoryPayload>;
};

export type MutationHra_SurveyArgs = {
  payload?: InputMaybe<HraSurveyPayload>;
};

export type MutationHypermarketAddDepartmentArgs = {
  idCompany: Scalars["String"]["input"];
  idStorageObject: Scalars["String"]["input"];
};

export type MutationImpersonateUserArgs = {
  payload: ImpersonateUserPayload;
};

export type MutationImportApiAttendancesArgs = {
  companyId: Scalars["ID"]["input"];
  jobId: Scalars["ID"]["input"];
  payload?: InputMaybe<Array<ImportApiAttendancePayload>>;
};

export type MutationImportChargeUserFromBusinessArgs = {
  payload?: InputMaybe<ImportChargeUserFromBusinessPayLoad>;
};

export type MutationImportChargeUserFromBusinessV2Args = {
  payload?: InputMaybe<ImportChargeUserFromBusinessPayLoadV2>;
};

export type MutationImportChargeUserFromUserArgs = {
  payload?: InputMaybe<Array<InputMaybe<ImportChargeUserFromUserPayLoad>>>;
};

export type MutationInactiveVendorArgs = {
  vendorId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationIndependenceDay2020_AcquirePrizeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationInvitation_AcceptInvitationArgs = {
  invitationCode: Scalars["String"]["input"];
};

export type MutationInvitation_PokeFriendArgs = {
  inviteeId: Scalars["ID"]["input"];
};

export type MutationInvitation_ReadAllArgs = {
  employmentId: Scalars["ID"]["input"];
};

export type MutationInvitation_ValidateInvitationCodeArgs = {
  invitationCode: Scalars["String"]["input"];
  phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
};

export type MutationIo_SyncToExternalArgs = {
  configKey?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationKangaroo_LinkEmploymentArgs = {
  payload?: InputMaybe<KangarooLinkEmploymentPayload>;
};

export type MutationLa_EmployeeConsentArgs = {
  payload: LeAssociateUserConsentPayload;
};

export type MutationLa_SyncEmployeeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationLanchi_FinalApproveRequestLinksArgs = {
  payload: Array<LanchiApproveRequestPayload>;
};

export type MutationLanchi_ProcessApproveEmploymentFile_V2Args = {
  payload: ProcessApproveEmployeeFilePayload;
};

export type MutationLanchi_ProcessAttendanceFileArgs = {
  payload: Lanchi_ProcessAttendanceFilePayload;
};

export type MutationLanchi_ProcessAttendanceFileV2Args = {
  payload: Lanchi_ProcessAttendanceFilePayload;
};

export type MutationLanchi_ProcessAttendanceFileV3Args = {
  payload: Lanchi_ProcessAttendanceFilePayload;
};

export type MutationLanchi_ProcessEmploymentFile_V2Args = {
  payload: Lanchi_ProcessEmploymentFilePayload;
};

export type MutationLanchi_ProcessEmploymentFile_V3Args = {
  payload: Lanchi_ProcessEmploymentFilePayload;
};

export type MutationLanchi_ProcessValidationEntitiesFileArgs = {
  payload?: InputMaybe<ProcessValidationFormFilePayload>;
};

export type MutationLanchi_RejectRequestLinksArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  rejectMessage?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationLinkEmploymentArgs = {
  payload: UserLinkEmploymentPayload;
};

export type MutationLinkTenantArgs = {
  payload?: InputMaybe<LinkTenantPayload>;
};

export type MutationLoanApplication_RetryArgs = {
  loanApplicationId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationLockEmploymentArgs = {
  id: Scalars["ID"]["input"];
  payloads: LockEmploymentPayload;
};

export type MutationLockEmploymentsArgs = {
  ids: Array<Scalars["ID"]["input"]>;
  payloads?: InputMaybe<LockEmploymentPayload>;
};

export type MutationLockLoanApplicationForDisbursementArgs = {
  query?: InputMaybe<LoanApplicationQuery>;
};

export type MutationLoctroi_SyncMissingTxnArgs = {
  storageId: Scalars["ID"]["input"];
};

export type MutationLoginArgs = {
  payload: LoginPayload;
};

export type MutationLoginRefreshTokenArgs = {
  payload: LoginRefreshToken;
};

export type MutationLoginVendorArgs = {
  password?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationLogivan_LinkEmploymentArgs = {
  payload: Logivan_LinkEmploymentPayload;
};

export type MutationLogoutDeviceArgs = {
  userId: Scalars["ID"]["input"];
};

export type MutationMakeCheck_CancelArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationMakeCheck_CreateNotificationProfilesArgs = {
  payload?: InputMaybe<Makecheck_MakeCheckNotificationEmailPayload>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationMakeCheck_ResolveArgs = {
  payload?: InputMaybe<Makecheck_MakeCheckDecisionPayload>;
  tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationMakeCheck_TriggerArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationMarkAsReadArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationMarkAsUsedArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationMaskingData_LinkEmploymentArgs = {
  payload: MaskingDataLinkEmploymentPayload;
};

export type MutationMessageBatch_CreateMessageBatchArgs = {
  payload?: InputMaybe<MessageBatch_CreateMessageBatchPayload>;
};

export type MutationMessageBatch_ReRunArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationMessageBatch_UpdateMessageBatchArgs = {
  id: Scalars["ID"]["input"];
  payload?: InputMaybe<MessageBatch_UpdateMessageBatchPayload>;
};

export type MutationMigrateProfileKycArgs = {
  date?: InputMaybe<Scalars["LocalDate"]["input"]>;
};

export type MutationMobifoneplus_InitArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationMobifoneplus_Update20220330Args = {
  id: Scalars["ID"]["input"];
};

export type MutationNguyenkim_InitArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationNguyenkim_LinkEmploymentArgs = {
  id: Scalars["ID"]["input"];
  payload: Nguyenkim_LinkEmploymentPayload;
};

export type MutationOnboard_UpsertCompanyArgs = {
  payload: CreateOnboardCompanyPayload;
};

export type MutationOperation_TriggerMigrateArgs = {
  tenantIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type MutationOperation_UpdateEmployeeByPayloadArgs = {
  payload?: InputMaybe<Array<InputMaybe<Operation_EmploymentMigratePayload>>>;
};

export type MutationOrder_CloseOrderArgs = {
  orderId: Scalars["ID"]["input"];
  payload: CloseOrderPayload;
};

export type MutationOrder_CloseOrderByCodeArgs = {
  code: Scalars["String"]["input"];
  payload: CloseOrderPayload;
};

export type MutationOrder_CreateOrderArgs = {
  employmentId: Scalars["ID"]["input"];
  payloads: Array<CreateOrderPayloadV2>;
};

export type MutationOrder_ManualCorrectPendingVasOrderArgs = {
  orderId: Scalars["ID"]["input"];
};

export type MutationOrder_PayOrderArgs = {
  employmentId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
  paymentPayload: PayOrderPaymentUnionPayload;
};

export type MutationOrder_SuccessOrderArgs = {
  orderId: Scalars["ID"]["input"];
  payload: SuccessOrderPayload;
};

export type MutationOrder_SuccessOrderByCodeArgs = {
  code: Scalars["String"]["input"];
  payload: SuccessOrderPayload;
};

export type MutationOrg_Admin_CreateCompanyArgs = {
  payload: Org_Admin_CreateCompany;
};

export type MutationOrg_Admin_CreateWorkScheduleArgs = {
  payload: Org_CreateWorkSchedulePayload;
};

export type MutationOrg_Admin_CreateWorkScheduleRuleArgs = {
  payload: Org_CreateWorkScheduleRulePayload;
};

export type MutationOrg_Admin_FindOrCreateTenantRootCompanyArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationOrg_Admin_UpdateCompanyArgs = {
  id: Scalars["ID"]["input"];
  payload: Org_Admin_UpdateCompany;
};

export type MutationOrg_Admin_UpdateCompanyBillingInfoArgs = {
  id: Scalars["ID"]["input"];
  payload?: InputMaybe<Org_Admin_CompanyBillingInfoPayload>;
};

export type MutationOrg_Admin_UpdateWorkScheduleArgs = {
  id: Scalars["ID"]["input"];
  payload: Org_UpdateWorkSchedulePayload;
};

export type MutationOrg_Admin_UpdateWorkScheduleRuleArgs = {
  id: Scalars["ID"]["input"];
  payload: Org_UpdateWorkScheduleRulePayload;
};

export type MutationPauseJobArgs = {
  taskId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationPauseMonitorArgs = {
  monitorId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationPayLaterValidateOtpArgs = {
  contractId: Scalars["ID"]["input"];
  otpCode: Scalars["String"]["input"];
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationPaylater_AlertRejectionArgs = {
  employmentId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
};

export type MutationPmdashboard_AddContactArgs = {
  payload: Pmdashboard_AddContactPayload;
  tenantInformationId: Scalars["ID"]["input"];
};

export type MutationPmdashboard_DeleteContactArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationPmdashboard_PmUpdateArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  payload?: InputMaybe<Pmdashboard_PmUpdatePayload>;
};

export type MutationPmdashboard_SyncArgs = {
  tenantID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationPmdashboard_UpdateContactArgs = {
  id: Scalars["ID"]["input"];
  payload: Pmdashboard_AddContactPayload;
};

export type MutationProcessAttendanceFileArgs = {
  companyId: Scalars["ID"]["input"];
  storageId: Scalars["ID"]["input"];
};

export type MutationProcessAttendanceFileV2Args = {
  payload: ProcessAttendanceFileV2Payload;
};

export type MutationProcessEmploymentFileArgs = {
  companyId: Scalars["ID"]["input"];
  storageId: Scalars["ID"]["input"];
};

export type MutationProcessEmploymentFileV2Args = {
  payload: ProcessEmploymentFileV2Payload;
};

export type MutationProcessEmploymentFileV3Args = {
  payload: ProcessEmploymentFileV3Payload;
};

export type MutationProcessTerminationFilesArgs = {
  id: ProcessTerminationFilePayload;
};

export type MutationProcessWorkplaceAttendanceFileArgs = {
  id: Scalars["ID"]["input"];
  payload: ProcessWorkplaceAttendanceFilePayload;
};

export type MutationPublishCommunicationPostArgs = {
  communicationId: Scalars["ID"]["input"];
};

export type MutationPublishSurveyFormArgs = {
  id: Scalars["ID"]["input"];
  payload: UpdateSurveyForm;
};

export type MutationPushImageNotificationArgs = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  employmentId?: InputMaybe<Scalars["ID"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationPutEmploymentConfigArgs = {
  employmentId: Scalars["ID"]["input"];
  key: Scalars["String"]["input"];
  value: Scalars["Boolean"]["input"];
};

export type MutationReApproveProfileKycArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationReDeclineProfileKycArgs = {
  payload: DeclineProfileKyc;
};

export type MutationReduceCashbackArgs = {
  employmentId: Scalars["ID"]["input"];
  payload?: InputMaybe<CashbackPayload>;
};

export type MutationRegisterDeviceArgs = {
  payload?: InputMaybe<RegisterDevicePayload>;
};

export type MutationRegisterNotificationTokenArgs = {
  payload: RegisterNotificationTokenPayload;
};

export type MutationRegisterUserPayLaterArgs = {
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationRegisterVendorArgs = {
  code: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  username: Scalars["String"]["input"];
};

export type MutationRegisterWaitlistArgs = {
  payload: WaitlistPayload;
};

export type MutationRejectChargeUserFromBusinessArgs = {
  payload?: InputMaybe<RejectChargeUserFromBusinessPayLoad>;
};

export type MutationRejectCompanyLinkEmploymentRequestArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type MutationRejectLoanApplicationArgs = {
  id: Scalars["ID"]["input"];
  payload: RejectLoanApplicationPayload;
};

export type MutationRequestChangePasswordArgs = {
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber: Scalars["PhoneNumber"]["input"];
};

export type MutationRequestChangePasswordV2Args = {
  idNumberValidate: Scalars["String"]["input"];
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber: Scalars["PhoneNumber"]["input"];
};

export type MutationRequestDeviceTokenArgs = {
  payload: RequestDeviceTokenPayload;
};

export type MutationRequestLoginArgs = {
  payload: RequestLoginPayload;
};

export type MutationRequestResendOtpArgs = {
  payload: RequestResendOtpPayload;
};

export type MutationRequestVerifyDeviceArgs = {
  payload?: InputMaybe<VerifyDevicePayload>;
};

export type MutationResendOtpArgs = {
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
  senderMethod?: InputMaybe<OtpMethod>;
};

export type MutationResetTenantUserPasswordArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
};

export type MutationResumeJobArgs = {
  taskId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationResumeMonitorArgs = {
  monitorId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationRetryCreateVendorKycProfileArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRollbackCashbackArgs = {
  rollbackPayload?: InputMaybe<CashbackRollbackPayload>;
};

export type MutationSaveAppraisalKycArgs = {
  id: Scalars["ID"]["input"];
  payload: SaveAppraisalKycPayload;
};

export type MutationSaveAsDraftCommunicationPostArgs = {
  communicationId: Scalars["ID"]["input"];
};

export type MutationSaveFileUploadArgs = {
  payload: MailUploadFilePayload;
};

export type MutationSaveInvitorArgs = {
  employmentId: Scalars["ID"]["input"];
  invitorPhone: Scalars["PhoneNumber"]["input"];
};

export type MutationSavingAutoConfig_UpsertArgs = {
  employmentId: Scalars["ID"]["input"];
  payload?: InputMaybe<SavingAutoConfig_UpsertPayload>;
  savingAccountId: Scalars["ID"]["input"];
};

export type MutationSaving_GenerateCloseFeatureAgreementArgs = {
  employmentId: Scalars["ID"]["input"];
};

export type MutationSaving_GenerateSavingAccountAgreementArgs = {
  employmentId: Scalars["ID"]["input"];
};

export type MutationSaving_InitSavingAccountArgs = {
  employmentId: Scalars["ID"]["input"];
};

export type MutationSaving_SignAgreementCloseFeatureArgs = {
  payload: SignAgreementCloseFeaturePayload;
};

export type MutationSaving_SignSavingAccountAgreementArgs = {
  employmentId: Scalars["ID"]["input"];
  otp?: InputMaybe<Scalars["String"]["input"]>;
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationSendCutOffMessagesArgs = {
  payload: CutoffCompanyMessagePayload;
};

export type MutationSendFinedMessageToEmploymentsArgs = {
  payload: SendFinedMessageToEmploymentsPayload;
};

export type MutationSendMessageArgs = {
  payload: SendMessagePayload;
};

export type MutationSendMessageToEmploymentsArgs = {
  payload: SendMessageToEmploymentsPayload;
};

export type MutationSendOtpPortalArgs = {
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
  phone: Scalars["PhoneNumber"]["input"];
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSendUserOtpArgs = {
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
  senderMethod?: InputMaybe<OtpMethod>;
};

export type MutationSendUserOtpV2Args = {
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
  senderMethod?: InputMaybe<OtpMethod>;
};

export type MutationSendVnrNotificationArgs = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  employeeCode?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSendZnsMessageArgs = {
  payload: ZnsMessage;
};

export type MutationSetConfigArgs = {
  input: SetConfigInput;
};

export type MutationSetRequiredPasswordArgs = {
  password: Scalars["String"]["input"];
};

export type MutationSetTenantUserRoleArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  role: Scalars["String"]["input"];
};

export type MutationShortlink_GenerateArgs = {
  payload: Shortlink_GeneratePayload;
};

export type MutationSignAgreementArgs = {
  payload?: InputMaybe<EmploymentSignOtpPayload>;
};

export type MutationSpending_DeactivateBillArgs = {
  employmentId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
};

export type MutationSpending_GetOrCreateBillArgs = {
  employmentId: Scalars["ID"]["input"];
  payload: Spending_CreateBillPayload;
};

export type MutationSpending_UpdateBillReminderArgs = {
  employmentId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  reminderStatus: Spending_BillReminderStatus;
};

export type MutationStartJobImmediatelyArgs = {
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSubmitAppReviewArgs = {
  payload: AppReviewPayload;
};

export type MutationSubmitCompanyEmployeeWaitListArgs = {
  companyId: Scalars["ID"]["input"];
  payload: CreateEmployeeWaitListPayload;
};

export type MutationSubmitInAppReviewArgs = {
  appReviewId: Scalars["ID"]["input"];
};

export type MutationSubmitToHandleChargeUserFromBusinessArgs = {
  payload?: InputMaybe<SubmitToHandleChargeUserFromBusiness>;
};

export type MutationSyncClevertapWithPhonesArgs = {
  phones?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MutationSyncEmploymentByFileArgs = {
  id?: InputMaybe<SyncEmploymentByFilePayload>;
};

export type MutationSyncEmploymentFromExternalSourceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationSyncFormStructureArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationSyncResponseSurveyFormArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationSyncTransferContactsArgs = {
  payload: SyncContactPayload;
};

export type MutationSystem_CreateEmploymentTokenArgs = {
  payload: System_CreateEmploymentTokenPayload;
};

export type MutationSystem_SyncCompanyAuthArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationSystem_SyncCompanyAuthCreateUserArgs = {
  emails: Array<Scalars["Email"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type MutationToggleSurveyFormArgs = {
  id: Scalars["ID"]["input"];
  overrideStatus: SurveyForm_OverrideStatus;
};

export type MutationTransfer_CreateTransferOrderArgs = {
  payload?: InputMaybe<CreateTransferPayload>;
};

export type MutationTriggerGenerateLateFeeArgs = {
  ids: Array<InputMaybe<Scalars["ID"]["input"]>>;
};

export type MutationTriggerMapLoanCoreArgs = {
  ids: Array<InputMaybe<Scalars["ID"]["input"]>>;
  workPeriodEntryId: Scalars["ID"]["input"];
};

export type MutationTryAutoLinkEmploymentArgs = {
  payload: TryAutoLinkEmploymentPayload;
};

export type MutationTwedu_LinkEmploymentArgs = {
  companyId: Scalars["ID"]["input"];
  payload: TweduLinkEmploymentPayload;
};

export type MutationUi_CreateComponentArgs = {
  payload: Ui_ComponentInitPayload;
};

export type MutationUi_UpdateComponentArgs = {
  id: Scalars["ID"]["input"];
  payload: Ui_ComponentUpdatePayload;
};

export type MutationUnlinkEmploymentArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationUnlinkTenantArgs = {
  payload?: InputMaybe<LinkTenantPayload>;
};

export type MutationUnlinkUserEmploymentArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationUnlockEmploymentArgs = {
  id: Scalars["ID"]["input"];
  payload?: InputMaybe<UnLockEmploymentPayload>;
};

export type MutationUnlockEmploymentsArgs = {
  ids: Array<Scalars["ID"]["input"]>;
  payload?: InputMaybe<UnLockEmploymentPayload>;
};

export type MutationUnpublishedCommunicationPostArgs = {
  communicationId: Scalars["ID"]["input"];
};

export type MutationUnregisterNotificationTokenArgs = {
  payload: UnregisterNotificationTokenPayload;
};

export type MutationUpdateAdvertiserArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  payload?: InputMaybe<UpdateAdvertiserPayload>;
};

export type MutationUpdateAttendanceAutomationConfigArgs = {
  payload: AttendanceAutomationConfigUpdatePayload;
};

export type MutationUpdateBannerArgs = {
  id: Scalars["ID"]["input"];
  payload: UpdateBannerPayload;
};

export type MutationUpdateBannerAssociationArgs = {
  id: Scalars["ID"]["input"];
  payload: UpdateBannerAssociationPayload;
};

export type MutationUpdateBatchedEmploymentsArgs = {
  companyId: Scalars["ID"]["input"];
  jobId: Scalars["ID"]["input"];
  payload?: InputMaybe<Array<CreateCompanyEmploymentPayload>>;
};

export type MutationUpdateCommunicationPostArgs = {
  communicationId: Scalars["ID"]["input"];
  payload: UpdateCommunicationPostPayload;
};

export type MutationUpdateCompanyArgs = {
  id: Scalars["ID"]["input"];
  payload: UpdateCompanyPayload;
};

export type MutationUpdateCronJobArgs = {
  jobId?: InputMaybe<Scalars["ID"]["input"]>;
  payload: UpdateCronJobPayload;
};

export type MutationUpdateDynamicLimitPercentageArgs = {
  payload: LimitPercentageConfigUpdatePayload;
};

export type MutationUpdateFlagServiceAcbUnavailableArgs = {
  value: Scalars["Boolean"]["input"];
};

export type MutationUpdateFlagServiceVpbUnavailableArgs = {
  value: Scalars["Boolean"]["input"];
};

export type MutationUpdateFlagUnderConstructionArgs = {
  value: Scalars["Boolean"]["input"];
};

export type MutationUpdateGreetingCardTemplateArgs = {
  payload?: InputMaybe<UpdateGreetingCardTemplate>;
  templateId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationUpdateGroupAttendanceFileProcessorArgs = {
  id: Scalars["ID"]["input"];
  payload: UpdateGroupAttendanceFileProcessorPayload;
};

export type MutationUpdateGroupEmploymentFileProcessorArgs = {
  id: Scalars["ID"]["input"];
  payload: UpdateGroupEmploymentFileProcessorPayload;
};

export type MutationUpdateGroupPayslipCalculatorArgs = {
  id: Scalars["ID"]["input"];
  payload: UpdateGroupPayslipCalculatorPayload;
};

export type MutationUpdateImportJobArgs = {
  id: Scalars["ID"]["input"];
  payload: UpdateProgressJobPayload;
};

export type MutationUpdateLeaveRequestArgs = {
  leaveRequestId: Scalars["ID"]["input"];
  payload: LeaveRequestUpdatePayload;
};

export type MutationUpdateLoanForPayLaterArgs = {
  workPeriodEntryId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationUpdateMinThresholdArgs = {
  payload?: InputMaybe<BankProvidersPayload>;
};

export type MutationUpdateNotificationTokenArgs = {
  payload?: InputMaybe<UpdateNotificationTokenPayload>;
};

export type MutationUpdatePayslipArgs = {
  id: Scalars["ID"]["input"];
  payload: UpdatePayslipPayload;
};

export type MutationUpdatePreAdvanceConfigArgs = {
  payload: PreAdvanceConfigUpdatePayload;
};

export type MutationUpdateProfileArgs = {
  payload?: InputMaybe<UpdateUserPayload>;
};

export type MutationUpdateProfileKycArgs = {
  id: Scalars["ID"]["input"];
  payload: UpdateProfileKycPayload;
};

export type MutationUpdateProfileKycAdminArgs = {
  id: Scalars["ID"]["input"];
  payload: UpdateProfileKycPayloadAdmin;
};

export type MutationUpdateSegmentArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  payload?: InputMaybe<UpdateSegmentPayload>;
};

export type MutationUpdateSurveyFormArgs = {
  id: Scalars["ID"]["input"];
  payload: UpdateSurveyForm;
};

export type MutationUpdateTenantConfigArgs = {
  id: Scalars["ID"]["input"];
  key: Scalars["String"]["input"];
  value: Scalars["JSON"]["input"];
};

export type MutationUpdateTenantRoleArgs = {
  companyId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  payload: UpdateRolePayload;
};

export type MutationUpdateTenantUserArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  payload: UpdateTenantUserPayload;
};

export type MutationUpdateUptimeMonitorArgs = {
  monitorId?: InputMaybe<Scalars["String"]["input"]>;
  payload: UpdateUptimeMonitorPayload;
};

export type MutationUpdateUserArgs = {
  id: Scalars["ID"]["input"];
  payload?: InputMaybe<UpdateUserPayload>;
};

export type MutationUpdateVendorArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  payload?: InputMaybe<UpdatePayload>;
};

export type MutationUpdateVendorPasswordArgs = {
  payload?: InputMaybe<UpdateVendorPasswordPayload>;
};

export type MutationUpdateVendorThemeArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  payload?: InputMaybe<UpdateVendorThemePayload>;
};

export type MutationUpdateVirtualAccountArgs = {
  employmentIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  vendorCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateWaitlistStatusArgs = {
  status: WaitlistStatus;
  waitlistId: Scalars["ID"]["input"];
};

export type MutationUploadPayslipArgs = {
  payload: UploadPayslipPayload;
};

export type MutationUpsertCompanyEmailContactArgs = {
  payload: MailContactPayload;
};

export type MutationUpsertEmailSchedulerGeneratorContactArgs = {
  mailSchedulerGeneratorId?: InputMaybe<Scalars["ID"]["input"]>;
  payload: MailContactAndCronJobPayload;
};

export type MutationUpsertEmailTemplateArgs = {
  payload: MailTemplatePayload;
  templateCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpsertHighlightOnboardCompaniesArgs = {
  payloads?: InputMaybe<Array<HighlightCompanySortPayload>>;
};

export type MutationUpsertIntegrationTenantConfigurationArgs = {
  payload: ConfigurationsPayload;
};

export type MutationUpsertMailScheduleGeneratorArgs = {
  payload?: InputMaybe<MailScheduleGeneratorPayload>;
};

export type MutationUpsertRatingArgs = {
  payload: CreateRatingPayload;
};

export type MutationUpsertTenantTopUpConfigArgs = {
  payload: TenantTopUpConfigPayload;
  tenantId: Scalars["ID"]["input"];
};

export type MutationUser_ChangePasswordArgs = {
  payload?: InputMaybe<UpdatePasswordPayload>;
};

export type MutationUser_CreateChangePhoneNumberRequestArgs = {
  payload: CreateChangePhoneNumberPayload;
};

export type MutationUser_FinalizeChangePhoneNumberRequestArgs = {
  payload: FinalizeChangePhoneNumberRequestPayload;
  requestId: Scalars["ID"]["input"];
};

export type MutationUser_ResendChangePhoneNumberOtpArgs = {
  payload: ResendChangePhoneNumberOtp;
};

export type MutationUser_VerifyOtpChangePhoneNumberRequestArgs = {
  payload: ExecuteChangePhoneNumberRequestPayload;
  requestId: Scalars["ID"]["input"];
};

export type MutationValidateVoucherArgs = {
  payload?: InputMaybe<ValidateVoucherPayload>;
  voucherId: Scalars["ID"]["input"];
};

export type MutationVerifyChangePasswordRequestArgs = {
  payload: VerifiyChangePasswordRequestPayload;
};

export type MutationVerifyIdNumberArgs = {
  idNumber: Scalars["String"]["input"];
};

export type MutationVerifyPasswordArgs = {
  payload: VerifyPasswordPayload;
};

export type MutationVerifyUserRegistrationArgs = {
  payload: VerifiyUserRegistrationPayload;
};

export type MutationWaitlist_AddCompaniesToCampaignArgs = {
  companyIds: Array<Scalars["ID"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type MutationWaitlist_CrawlArgs = {
  index: Scalars["Int"]["input"];
};

export type MutationWaitlist_CreateCampaignArgs = {
  payload: Waitlist_CreateCampaignPayload;
};

export type MutationWaitlist_CreateCompanyArgs = {
  payload: Waitlist_CreateCompanyPayload;
};

export type MutationWaitlist_RegisterCollabArgs = {
  payload?: InputMaybe<Waitlist_RegisterWaitlistCollab>;
};

export type MutationWaitlist_RegisterUserArgs = {
  payload: Waitlist_RegisterUserPayload;
};

export type MutationWaitlist_RemoveCompaniesFromCampaignArgs = {
  companyIds: Array<Scalars["ID"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type MutationWaitlist_UpdateCampaignArgs = {
  id: Scalars["ID"]["input"];
  payload: Waitlist_UpdateCampaignPayload;
};

export type MutationWaitlist_UpdateCompanyArgs = {
  id: Scalars["ID"]["input"];
  payload: Waitlist_UpdateCompanyPayload;
};

export type MutationWaitlist_UpdateCompanyStageWorkingWithEmployerArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationWaitlist_UpdateUserInvitorArgs = {
  id: Scalars["ID"]["input"];
  payload: Waitlist_UpdateUserInvitorPayload;
};

export type MutationWaitlist_ValidateOtpArgs = {
  id: Scalars["ID"]["input"];
  otpCode: Scalars["String"]["input"];
};

export type MutationWithdrawCashbackArgs = {
  employmentId: Scalars["ID"]["input"];
  withdrawPayload?: InputMaybe<CashbackWithdrawPayload>;
};

export type MutationYearInReview_2021_MarkAsPoppedArgs = {
  employmentId: Scalars["ID"]["input"];
};

export type MutationYearInReview_2021_RequestGenerateShareImagesArgs = {
  employmentId: Scalars["ID"]["input"];
};

export type MutationYody_SyncAttendancesArgs = {
  employeeCodes?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type NormalizedStringFilter = {
  contains?: InputMaybe<Scalars["String"]["input"]>;
  eq?: InputMaybe<Scalars["String"]["input"]>;
  includes?: InputMaybe<Scalars["String"]["input"]>;
  normalizedIncludes?: InputMaybe<Scalars["String"]["input"]>;
};

export type OffsetDateTimeRange = {
  from?: InputMaybe<Scalars["OffsetDateTime"]["input"]>;
  fromInclusive?: InputMaybe<Scalars["OffsetDateTime"]["input"]>;
  to?: InputMaybe<Scalars["OffsetDateTime"]["input"]>;
  toInclusive?: InputMaybe<Scalars["OffsetDateTime"]["input"]>;
};

export type OnboardCompany = {
  company: Company;
  displayName: Scalars["String"]["output"];
  employeeIdPlaceHolder?: Maybe<Scalars["String"]["output"]>;
  fullName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isDisplayable: Scalars["Boolean"]["output"];
  isHighlight?: Maybe<Scalars["Boolean"]["output"]>;
  platforms: Array<OnboardCompanyPlatform>;
  searchTokens?: Maybe<Array<Scalars["String"]["output"]>>;
  workflow: Scalars["String"]["output"];
};

export type OnboardCompanyConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<OnboardCompany>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type OnboardCompanyFilter = {
  isDisplayable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHighlight?: InputMaybe<Scalars["Boolean"]["input"]>;
  platforms?: InputMaybe<Array<OnboardCompanyPlatform>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export enum OnboardCompanyOrderable {
  DisplayName = "displayName",
  FullName = "fullName",
  Id = "id",
  SortIndex = "sortIndex",
}

export enum OnboardCompanyPlatform {
  Mobile = "Mobile",
  Zalo = "Zalo",
}

export type OnboardCompanyQuery = {
  filter?: InputMaybe<OnboardCompanyFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<OnboardCompanyOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export enum OnboardingFlow {
  Default = "DEFAULT",
  Friwo = "FRIWO",
  Maskingdata = "MASKINGDATA",
  Mobifoneplus = "MOBIFONEPLUS",
  Nguyenkim = "NGUYENKIM",
  OnboardDemoWithBank = "ONBOARD_DEMO_WITH_BANK",
  OnboardDemoWithOutBank = "ONBOARD_DEMO_WITH_OUT_BANK",
  Phamnguyen = "PHAMNGUYEN",
  Vuanem = "VUANEM",
}

export enum Operator {
  And = "AND",
  Contains = "CONTAINS",
  ContainsIgnoreCase = "CONTAINS_IGNORE_CASE",
  Equal = "EQUAL",
  Exist = "EXIST",
  GreaterOrEqual = "GREATER_OR_EQUAL",
  GreaterThan = "GREATER_THAN",
  In = "IN",
  LessOrEqual = "LESS_OR_EQUAL",
  LessThan = "LESS_THAN",
  NotContains = "NOT_CONTAINS",
  NotContainsIgnoreCase = "NOT_CONTAINS_IGNORE_CASE",
  NotEqual = "NOT_EQUAL",
  NotExist = "NOT_EXIST",
  NotIn = "NOT_IN",
  Or = "OR",
}

export type Order = {
  actualPrice: Scalars["Money"]["output"];
  billTransaction?: Maybe<Spending_BillTransaction>;
  cards?: Maybe<Array<TopUpCard>>;
  code: Scalars["String"]["output"];
  createdDate: Scalars["Instant"]["output"];
  failedInfo?: Maybe<FailedInfo>;
  id: Scalars["ID"]["output"];
  items: Array<OrderItem>;
  lastModifiedDate: Scalars["Instant"]["output"];
  originPrice: Scalars["Money"]["output"];
  paidPrice?: Maybe<Scalars["Money"]["output"]>;
  payment?: Maybe<Payment>;
  status: OrderStatus;
  subscriptionPackage?: Maybe<UserSubscriptionPackage>;
  topUpData?: Maybe<TopUpResponseData>;
  transferInfo?: Maybe<TransferInfo>;
};

/** query order direction */
export enum OrderDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type OrderItem = {
  cards?: Maybe<Array<Maybe<OrderItemCard>>>;
  quantity?: Maybe<Scalars["Int"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type OrderItemCard = {
  amount: Scalars["Money"]["output"];
  carrier: CarrierType;
  pinCode: Scalars["String"]["output"];
  serialNumber: Scalars["String"]["output"];
  settledDate: Scalars["Instant"]["output"];
};

export enum OrderStatus {
  Failed = "Failed",
  Finished = "Finished",
  InProgress = "InProgress",
  Pending = "Pending",
  Purchasing = "Purchasing",
  TryDepositing = "TryDepositing",
}

export enum OtpMethod {
  Sms = "Sms",
  Voice = "Voice",
  Zns = "Zns",
}

export type OtpResponse = {
  debug_otp?: Maybe<Scalars["String"]["output"]>;
  retryAfter: Scalars["Duration"]["output"];
  success: Scalars["Boolean"]["output"];
};

export enum OwnerStatus {
  NonOwner = "NonOwner",
  Owner = "Owner",
  Unknown = "Unknown",
}

export type PackageResponse = {
  id?: Maybe<Scalars["ID"]["output"]>;
  month?: Maybe<Scalars["Float"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  work_period?: Maybe<Scalars["Int"]["output"]>;
};

export type PageInfo = {
  endCursor?: Maybe<Scalars["String"]["output"]>;
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  startCursor?: Maybe<Scalars["String"]["output"]>;
};

export type PaidPriceUnion = {
  amount: Scalars["Money"]["output"];
  cashback?: Maybe<Array<CalculateOrderCashbackAmountResponse>>;
  employerFee: Scalars["Money"]["output"];
  ewa?: Maybe<Array<CalculateOrderEwaExplanationResponse>>;
  fee: Scalars["Money"]["output"];
  feeBeforePromotion: Scalars["Money"]["output"];
  totalEmployeePay: Scalars["Money"]["output"];
};

export type Parameter = {
  key?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type ParameterInput = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type PathNode = {
  path?: InputMaybe<EmploymentFieldName>;
};

export type PayLaterAmountConfig = {
  maxAmount?: Maybe<Scalars["Int"]["output"]>;
  minAmount?: Maybe<Scalars["Int"]["output"]>;
};

export type PayLaterFeatureContentResponse = {
  featureFeePercentContent?: Maybe<Scalars["String"]["output"]>;
  featureMonthContent?: Maybe<Scalars["String"]["output"]>;
  featurePeriodContent?: Maybe<Scalars["String"]["output"]>;
};

export type PayLaterLateWarningResponse = {
  amount?: Maybe<Scalars["Money"]["output"]>;
  installmentLoanId?: Maybe<Scalars["ID"]["output"]>;
  startGenLateFeeDate?: Maybe<Scalars["LocalDate"]["output"]>;
  totalDayLate?: Maybe<Scalars["Int"]["output"]>;
};

export enum PayLaterReasonDeclineOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export type PayLaterReasonDeclineQuery = {
  filter?: InputMaybe<PayLaterReasonDeclineQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PayLaterReasonDeclineOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type PayLaterReasonDeclineQueryFilter = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type PayLaterSelfRepaymentStatusResponse = {
  status?: Maybe<PayLaterSelfRepaymentStatusStatus>;
  workPeriod?: Maybe<Scalars["Int"]["output"]>;
};

export enum PayLaterSelfRepaymentStatusStatus {
  Done = "Done",
  InProgress = "InProgress",
}

export type PayLaterUserPayableResponse = {
  amount?: Maybe<Scalars["Money"]["output"]>;
  endDate?: Maybe<Scalars["LocalDate"]["output"]>;
  isCurrent?: Maybe<Scalars["Boolean"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  reconcileDate?: Maybe<Scalars["LocalDate"]["output"]>;
  startDate?: Maybe<Scalars["LocalDate"]["output"]>;
  status?: Maybe<UserPayableStatus>;
};

export type PayLaterValidateResponse = {
  amount?: Maybe<Scalars["Money"]["output"]>;
  isHotline?: Maybe<Scalars["Boolean"]["output"]>;
  isShowSecondButton?: Maybe<Scalars["Boolean"]["output"]>;
  isSuccess?: Maybe<Scalars["Boolean"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type PayLaterWorkPeriod = {
  endDate?: Maybe<Scalars["LocalDate"]["output"]>;
  reconcileDate?: Maybe<Scalars["LocalDate"]["output"]>;
  startDate?: Maybe<Scalars["LocalDate"]["output"]>;
};

export type PayLaterWorkPeriodDetail = {
  createdDate?: Maybe<Scalars["Instant"]["output"]>;
  endDate?: Maybe<Scalars["LocalDate"]["output"]>;
  fee?: Maybe<Scalars["Money"]["output"]>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  lateFee?: Maybe<Scalars["Money"]["output"]>;
  lateFeeFromRemainingAmount?: Maybe<Scalars["Money"]["output"]>;
  loanCode?: Maybe<Scalars["String"]["output"]>;
  nonPerformingAmount?: Maybe<Scalars["Money"]["output"]>;
  paymentAmount?: Maybe<Scalars["Money"]["output"]>;
  principalAmount?: Maybe<Scalars["Money"]["output"]>;
  reconcileDate?: Maybe<Scalars["LocalDate"]["output"]>;
  remainingAmount?: Maybe<Scalars["Money"]["output"]>;
  startDate?: Maybe<Scalars["LocalDate"]["output"]>;
  status?: Maybe<UserPayableStatus>;
  totalAmount?: Maybe<Scalars["Money"]["output"]>;
  totalDayLate?: Maybe<Scalars["Int"]["output"]>;
};

export type PayOrderPaymentCashbackPayload = {
  amount: Scalars["Money"]["input"];
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type PayOrderPaymentUnionPayload = {
  cashback?: InputMaybe<Array<PayOrderPaymentCashbackPayload>>;
  ewa?: InputMaybe<Array<PayOrderPriceEwaPayload>>;
};

export type PayOrderPriceEwaPayload = {
  actualReceiveAmount: Scalars["Money"]["input"];
  amount: Scalars["Money"]["input"];
  fee: Scalars["Money"]["input"];
  type?: InputMaybe<Scalars["String"]["input"]>;
  workPeriodEntryId: Scalars["ID"]["input"];
};

export enum PaySourceType {
  Cashback = "Cashback",
  CashbackVoucher = "CashbackVoucher",
  Ewa = "Ewa",
  Other = "Other",
}

export type PaylaterPopupResponse = UiComponentRepresentation & {
  action?: Maybe<Banner_ActionData>;
  actionData?: Maybe<Banner_ActionData>;
  actionType?: Maybe<Banner_ActionType>;
  code?: Maybe<Scalars["String"]["output"]>;
  link?: Maybe<Scalars["String"]["output"]>;
  maxCounter: Scalars["Int"]["output"];
  popup: StorageObject;
  waitTimeBetweenClosure: Scalars["String"]["output"];
};

export type PayloadFee = {
  applied_date: Scalars["LocalDate"]["input"];
  commission_percent: Scalars["Int"]["input"];
  due_date?: InputMaybe<Scalars["LocalDate"]["input"]>;
  maintenance_fees: Scalars["Money"]["input"];
  payment_date?: InputMaybe<Scalars["Int"]["input"]>;
  share_promotion?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Payment = {
  amount: Scalars["Money"]["output"];
  details: Array<PaymentDetail>;
  id: Scalars["ID"]["output"];
  order: Order;
};

export type PaymentDetail = {
  amount: Scalars["Money"]["output"];
  loanApplication?: Maybe<LoanApplication>;
  payment: Payment;
  status: PaymentDetailStatus;
  type: PaySourceType;
  voucher?: Maybe<Voucher>;
};

export enum PaymentDetailStatus {
  Failed = "Failed",
  InProgress = "InProgress",
  Pending = "Pending",
  Success = "Success",
}

export type PaymentHistoryDetailResponse = {
  amount?: Maybe<Scalars["Money"]["output"]>;
  createdDate?: Maybe<Scalars["LocalDate"]["output"]>;
  createdTime?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  isPending?: Maybe<Scalars["Boolean"]["output"]>;
  paymentType?: Maybe<PaymentType>;
  type?: Maybe<PaymentHistoryDetailType>;
};

export enum PaymentHistoryDetailType {
  Change = "Change",
  Ewa = "Ewa",
  Settlement = "Settlement",
}

export type PaymentHistoryResponse = {
  details?: Maybe<Array<Maybe<PaymentHistoryDetailResponse>>>;
  totalAmount?: Maybe<Scalars["Money"]["output"]>;
};

export type PaymentProcessResponse = {
  dueBalance?: Maybe<Scalars["Money"]["output"]>;
  notDueBalance?: Maybe<Scalars["Money"]["output"]>;
  overDueStartDate?: Maybe<Scalars["LocalDate"]["output"]>;
  overdueBalance?: Maybe<Scalars["Money"]["output"]>;
  paidBalance?: Maybe<Scalars["Money"]["output"]>;
  payAmountRemind?: Maybe<Scalars["Money"]["output"]>;
  payDayRemind?: Maybe<Scalars["LocalDate"]["output"]>;
  totalDayLate?: Maybe<Scalars["Int"]["output"]>;
};

export enum PaymentStatus {
  Done = "Done",
  Undone = "Undone",
}

export enum PaymentType {
  All = "All",
  Period = "Period",
}

export type Payslip = {
  deductions: Array<Deduction>;
  earnings: Array<Earning>;
};

export enum PayslipAdminOrderable {
  Id = "Id",
}

export type PayslipAdminQuery = {
  filter?: InputMaybe<PayslipAdminQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PayslipAdminOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type PayslipAdminQueryFilter = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  endDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  isFeedback?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  status?: InputMaybe<PayslipHistoryStatus>;
  statuses?: InputMaybe<Array<InputMaybe<PayslipHistoryStatus>>>;
  tenantIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type PayslipAdminResponse = {
  endDate?: Maybe<Scalars["LocalDate"]["output"]>;
  feedbackAdmin: FeedbackAdminConnection;
  id?: Maybe<Scalars["ID"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  publishDate?: Maybe<Scalars["Instant"]["output"]>;
  reportResponse?: Maybe<PayslipReportResponse>;
  startDate?: Maybe<Scalars["LocalDate"]["output"]>;
  status?: Maybe<PayslipHistoryStatus>;
  storageId?: Maybe<Scalars["ID"]["output"]>;
  totalFeedback?: Maybe<Scalars["Int"]["output"]>;
};

export type PayslipAdminResponseFeedbackAdminArgs = {
  feedbackQuery?: InputMaybe<FeedbackQuery>;
};

export enum PayslipAttributeType {
  Highlight = "Highlight",
}

export enum PayslipHistoryStatus {
  Closed = "Closed",
  Created = "Created",
  Published = "Published",
}

export type PayslipPeriodResponse = {
  endDate?: Maybe<Scalars["LocalDate"]["output"]>;
  startDate?: Maybe<Scalars["LocalDate"]["output"]>;
};

export type PayslipReportResponse = {
  notSuccessEmployeeIds?: Maybe<Array<Maybe<EmployeeInfo>>>;
  totalInputEmployee?: Maybe<Scalars["Int"]["output"]>;
  totalOutputEmployee?: Maybe<Scalars["Int"]["output"]>;
};

export type PayslipResponse = {
  categories?: Maybe<Array<Maybe<CategoryResponse>>>;
  employeeId?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["LocalDate"]["output"]>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  logo?: Maybe<StorageObject>;
  netSalary?: Maybe<Scalars["String"]["output"]>;
  position?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["LocalDate"]["output"]>;
};

export type PreAdvanceConfig = {
  advanceAmount?: Maybe<Scalars["Money"]["output"]>;
  advanceShiftCount?: Maybe<Scalars["Decimal"]["output"]>;
  createdDate: Scalars["Instant"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  effectiveEndDate?: Maybe<Scalars["Instant"]["output"]>;
  effectiveStartDate: Scalars["Instant"]["output"];
  employmentSegment?: Maybe<EmploymentSegmentResponse>;
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  name: Scalars["String"]["output"];
  priority: Scalars["Int"]["output"];
  status: CreditConfigStatus;
};

export enum PreAdvanceConfigOrderable {
  CreatedDate = "createdDate",
  Id = "id",
  LastModifiedDate = "lastModifiedDate",
  Priority = "priority",
}

export type PreAdvanceConfigPayload = {
  advanceAmount: Scalars["Money"]["input"];
  advanceShiftCount?: InputMaybe<Scalars["Decimal"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  effectiveEndDate?: InputMaybe<Scalars["Instant"]["input"]>;
  effectiveStartDate: Scalars["Instant"]["input"];
  employmentSegment?: InputMaybe<EmploymentSegment>;
  name: Scalars["String"]["input"];
  priority?: InputMaybe<Scalars["Int"]["input"]>;
  status: CreditConfigStatus;
  tenantId: Scalars["ID"]["input"];
};

export type PreAdvanceConfigQuery = {
  filter?: InputMaybe<PreAdvanceConfigQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PreAdvanceConfigOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type PreAdvanceConfigQueryFilter = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  isEffective?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<CreditConfigStatus>;
  tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type PreAdvanceConfigResponse = {
  limit?: Maybe<Scalars["Int"]["output"]>;
  nodes: Array<Maybe<PreAdvanceConfig>>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type PreAdvanceConfigUpdatePayload = {
  advanceAmount?: InputMaybe<Scalars["Money"]["input"]>;
  advanceShiftCount?: InputMaybe<Scalars["Decimal"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  effectiveEndDate?: InputMaybe<Scalars["Instant"]["input"]>;
  effectiveStartDate?: InputMaybe<Scalars["Instant"]["input"]>;
  employmentSegment?: InputMaybe<EmploymentSegment>;
  id: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<CreditConfigStatus>;
};

export type PreUser = {
  phone?: Maybe<Scalars["PhoneNumber"]["output"]>;
  waitlist_User?: Maybe<Waitlist_User>;
};

export type ProcessApproveEmployeeFilePayload = {
  storageObjectId: Scalars["ID"]["input"];
};

export type ProcessAttendanceFileV2Payload = {
  employmentFilter?: InputMaybe<EmploymentQueryFilter>;
  groupId?: InputMaybe<Scalars["ID"]["input"]>;
  qualifier?: InputMaybe<Scalars["String"]["input"]>;
  storageObjectId: Scalars["ID"]["input"];
};

export type ProcessAttendanceFileV2Response = {
  taskId: Scalars["ID"]["output"];
};

export type ProcessEmploymentFileV2Payload = {
  groupId?: InputMaybe<Scalars["ID"]["input"]>;
  storageObjectId: Scalars["ID"]["input"];
};

export type ProcessEmploymentFileV2Response = {
  taskId: Scalars["ID"]["output"];
};

export type ProcessEmploymentFileV3Payload = {
  groupId?: InputMaybe<Scalars["ID"]["input"]>;
  qualifier?: InputMaybe<Scalars["String"]["input"]>;
  storageObjectId: Scalars["ID"]["input"];
};

export type ProcessTerminationFilePayload = {
  qualifier?: InputMaybe<Scalars["String"]["input"]>;
  storageObjectId: Scalars["ID"]["input"];
};

export type ProcessTerminationFileResponse = {
  taskId: Scalars["ID"]["output"];
};

export type ProcessValidationFormFilePayload = {
  storageObjectId: Scalars["ID"]["input"];
};

export type ProcessWorkplaceAttendanceFilePayload = {
  qualifier: Scalars["String"]["input"];
  storageObjectId: Scalars["ID"]["input"];
};

export type ProductEarning = {
  advertiser?: Maybe<Advertiser>;
  campaign?: Maybe<Campaign>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  logo?: Maybe<StorageObject>;
  name?: Maybe<Scalars["String"]["output"]>;
  onboardContent?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<ProductType>;
};

export type ProductExtension = {
  replaceable?: Maybe<ReplaceableExtension>;
};

export enum ProductOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export type ProductQuery = {
  filter?: InputMaybe<ProductQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<ProductOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type ProductQueryFilter = {
  tenantIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  type?: InputMaybe<ProductType>;
};

export type ProductResolved = {
  ableToBuy: Scalars["Boolean"]["output"];
  actualPrice: Scalars["Money"]["output"];
  cardInfo?: Maybe<CardInfo>;
  carrierDataInfo?: Maybe<CarrierDataInfo>;
  code: Scalars["String"]["output"];
  extension?: Maybe<ProductExtension>;
  image?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  originPrice: Scalars["Money"]["output"];
  tags?: Maybe<Array<Scalars["String"]["output"]>>;
  topUpInfo?: Maybe<TopUpInfo>;
  type: Scalars["String"]["output"];
};

export enum ProductType {
  Default = "Default",
  Hot = "Hot",
  New = "New",
}

export enum ProfileOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
  Status = "Status",
}

export type ProfileQuery = {
  filter?: InputMaybe<ProfileQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<ProfileOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type ProfileQueryFilter = {
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  employeeId?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  keySearch?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  status?: InputMaybe<ProfileStatus>;
};

export type ProfileResponse = {
  actionDate?: Maybe<Scalars["Instant"]["output"]>;
  appraisalResponse?: Maybe<AppraisalResponse>;
  backCard?: Maybe<StorageObject>;
  backUpBackCard?: Maybe<StorageObject>;
  backUpFrontCard?: Maybe<StorageObject>;
  backUpPortraits?: Maybe<Array<Maybe<StorageObject>>>;
  bankAccountNumber?: Maybe<Scalars["String"]["output"]>;
  bankName?: Maybe<Scalars["String"]["output"]>;
  companyName?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  employeeId?: Maybe<Scalars["String"]["output"]>;
  featureName?: Maybe<Scalars["String"]["output"]>;
  frontCard?: Maybe<StorageObject>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  handleBy?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  idNumber?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  portraits?: Maybe<Array<Maybe<StorageObject>>>;
  profileExisted?: Maybe<Array<Maybe<ProfileResponse>>>;
  reasonDecline?: Maybe<ReasonDecline>;
  relativeContact?: Maybe<Scalars["JSON"]["output"]>;
  socialIdIssuedDate?: Maybe<Scalars["LocalDate"]["output"]>;
  socialIdIssuedPlace?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<ProfileStatus>;
  superAdminHandleBy?: Maybe<Scalars["String"]["output"]>;
  taxCode?: Maybe<Scalars["String"]["output"]>;
  vendorData?: Maybe<KycVendorData>;
};

export enum ProfileStatus {
  Pending = "Pending",
  Reject = "Reject",
  Success = "Success",
}

export type Province = {
  code: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type Query = {
  _service?: Maybe<_Service>;
  aclUI?: Maybe<Array<Maybe<Result>>>;
  adminUsers?: Maybe<Array<Maybe<AdminUser>>>;
  admin_InvoiceActivities: Admin_InvoiceActivityConnection;
  admin_InvoiceActivity: Admin_InvoiceActivity;
  admin_InvoiceDetail?: Maybe<Admin_InvoiceDetail>;
  admin_InvoiceDetails: Admin_InvoiceDetailConnection;
  admin_InvoicePayment?: Maybe<Admin_InvoicePayment>;
  admin_InvoicePayments: Admin_InvoicePaymentConnection;
  admin_RawTransaction: Admin_RawTxn;
  admin_RawTransactions: Admin_RawTxnConnection;
  admin_ReconcileTransaction: Admin_ReconcileTxn;
  admin_ReconcileTransactions: Admin_ReconcileTxnConnection;
  admin_ReconcileVendorFile?: Maybe<Admin_ReconcileVendorFile>;
  admin_ReconcileVendorFiles: Admin_ReconcileVendorFileConnection;
  admin_b2cLoanAccounts?: Maybe<Admin_B2cLoanAccountConnection>;
  admin_earningProducts: Earning_ProductEarningConnection;
  admin_exportMisaCreditReport?: Maybe<Array<Maybe<Admin_VendorTxn>>>;
  admin_exportMisaDebitReport?: Maybe<Array<Maybe<Admin_VendorTxn>>>;
  admin_getUploadRevisedFileStatuses?: Maybe<Admin_UploadRevisedFilesStatus>;
  admin_installmentLoan: Admin_Anhnvt_InstallmentLoan;
  admin_invoice?: Maybe<Admin_Invoice>;
  admin_invoiceContracts?: Maybe<Admin_ContractConnection>;
  admin_invoices: Admin_InvoiceConnection;
  admin_reconcileVendor?: Maybe<Admin_ReconcileVendor>;
  admin_reconcileVendors: Admin_ReconcileVendorConnection;
  admin_requestRecEntries: Admin_RequestRecEntryConnection;
  admin_requestRecEntry?: Maybe<Admin_RequestRecEntry>;
  admin_tenantCashbackConfig?: Maybe<Admin_CashbackConfig>;
  advanceV3_calculate: CalculateAdvanceV3Response;
  attendance: Attendance;
  attendanceAutomationConfigs?: Maybe<AttendanceAutomationConfigResponse>;
  attendanceFileProcessorQualifiers: Array<Maybe<Scalars["String"]["output"]>>;
  attendances: AttendanceConnection;
  autoEWADescription?: Maybe<Scalars["String"]["output"]>;
  bank: Bank;
  bankBySlug: Bank;
  banks: BankConnection;
  banner: Banner;
  banner_BackgroundImages?: Maybe<Banner_BackgroundImageConnection>;
  banner_banner: Banner_Banner;
  banner_bannerCampaign: Banner_BannerCampaign;
  banner_bannerCampaigns?: Maybe<Banner_BannerCampaignsConnection>;
  banner_banners: Banner_BannerConnection;
  banners: BannerConnection;
  bnpl_demoCalculateFeeResponse: Array<Bnpl_DemoInstallmentLoanFeeCalculateResponse>;
  calculateLoanFee: CalculateLoanFeeResponse;
  calculateLoanFeeV2: CalculateLoanFeeV2Response;
  calculateLoanFeeV2WithAutoApplyVoucher: CalculateLoanFeeV2WithAutoApplyVoucherResponse;
  chargeBusinessAdmin: ChargeBusinessAdminConnection;
  chargeBusinessDetailAdmin: ChargeBusinessDetailAdminConnection;
  checkAmountPayLater?: Maybe<PayLaterValidateResponse>;
  checkFlowChangePassword: CheckFlowChangePasswordResponse;
  checkSegmentPayLater?: Maybe<PayLaterValidateResponse>;
  checkStatusInstallmentLoan?: Maybe<InstallmentLoanStatus>;
  codepush_release: Codepush_ReleaseResponse;
  codepush_releases: Codepush_ReleasesResponse;
  codepush_shouldUpdate?: Maybe<Scalars["Boolean"]["output"]>;
  comissionDisbursements: ComissionDisbursementOrderableConnection;
  common_queryAppVersion: Scalars["Boolean"]["output"];
  communicationTemplateImages?: Maybe<Array<CommunicationTemplate>>;
  companies: CompanyConnection;
  company: Company;
  companyBySlug: Company;
  /** @deprecated No longer supported */
  companyCategories: CategoryConnection;
  /** @deprecated No longer supported */
  companyCategory: CompanyCategory;
  configs: ConfigConnection;
  core_extensionFactories: Array<Core_ExtensionFactory>;
  core_extensionPoints: Array<Core_ExtensionPoint>;
  core_tenant: Core_Tenant;
  core_tenantFeatures: Array<Scalars["String"]["output"]>;
  core_tenants: TenantConnection;
  cronJobs: CronJobConnection;
  disbursement: Disbursement;
  disbursements: DisbursementConnection;
  disburser_DisbursementRetryHistoryQuery?: Maybe<Disburser_DisbursementRetryHistoryConnection>;
  disburserv2_disbursement: Disburserv2_Disbursement;
  disburserv2_disbursements: Disburserv2_DisbursementConnection;
  earningUserInfo: EarningUserInfo;
  employment: Employment;
  employmentConditionSuggestions?: Maybe<EmploymentConditionSuggestionNodes>;
  employmentConditionSuggestionsV2?: Maybe<
    Array<Maybe<EmploymentConditionSuggestionResponse>>
  >;
  employmentFileProcessorQualifiers: Array<Scalars["String"]["output"]>;
  employmentSearch_v2: EmploymentConnection;
  employmentSegment: EmploymentSegmentConnection;
  employments: EmploymentConnection;
  export_getByTaskId: ExportEntity;
  features?: Maybe<Array<Maybe<FeatureObject>>>;
  fileProcessor_functionIdentifiers: Array<Scalars["String"]["output"]>;
  flags: Flags;
  generateContract?: Maybe<StorageObject>;
  generatePayLaterContract?: Maybe<StorageObject>;
  getAllBankProviders?: Maybe<Array<Maybe<BankProviders>>>;
  getAllConfigurations?: Maybe<Array<Maybe<Configurations>>>;
  getAllReason?: Maybe<Array<Maybe<ReasonResponse>>>;
  getAmountPerWorkPeriod: AmountPerWorkPeriodResponse;
  getAvailableConfigurationKey?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  getConfig?: Maybe<Scalars["JSON"]["output"]>;
  getConfigurationsByTenantId?: Maybe<Array<Maybe<Configurations>>>;
  getCronjobExpressions?: Maybe<Array<Maybe<CronjobExpression>>>;
  getDefaultTopUpConfig: Scalars["JSON"]["output"];
  getDeviceState?: Maybe<Scalars["String"]["output"]>;
  getEmailContacts?: Maybe<Array<Maybe<MailContact>>>;
  getFeatureNameById?: Maybe<Scalars["String"]["output"]>;
  getFileUploads?: Maybe<Array<Maybe<MailFileUpload>>>;
  getHistoryMails?: Maybe<MailHistoryConnection>;
  getInfoVendor?: Maybe<VendorInfoReponse>;
  getLimitAmountPayLater: LimitAmountPayLaterResponse;
  getMailTemplateByCode?: Maybe<MailTemplates>;
  getMailTemplates?: Maybe<MailTemplateConnection>;
  getPayLaterAmountConfig?: Maybe<PayLaterAmountConfig>;
  getRecentProfile?: Maybe<ProfileResponse>;
  getRecentProfileByFeatureName?: Maybe<ProfileResponse>;
  getScheduleGenerators?: Maybe<Array<Maybe<MailScheduleGenerator>>>;
  getScheduleMails?: Maybe<MailConnection>;
  getTaxCode: Scalars["String"]["output"];
  getUpcomingExecuteTime?: Maybe<Scalars["String"]["output"]>;
  getVendorById?: Maybe<VendorDetail>;
  getWaitlistRegister?: Maybe<WaitlistRegisterQueryReponse>;
  getWaitlists?: Maybe<WaitListReponse>;
  getWoayPlayLink?: Maybe<Scalars["URI"]["output"]>;
  gs25_migrateEmployments?: Maybe<Scalars["Void"]["output"]>;
  gs25_syncAttendances?: Maybe<Scalars["Void"]["output"]>;
  gs25_syncEmploymentsV2?: Maybe<Scalars["Void"]["output"]>;
  gs25_syncResignations?: Maybe<Scalars["Void"]["output"]>;
  guestBanks: Array<Bank>;
  hra_findAllTemplateStory?: Maybe<Array<HraTemplateStory>>;
  hra_prizePool?: Maybe<Array<HraPrize>>;
  hra_summaryByTenant: HraSummary;
  hra_yearEnd?: Maybe<HraYearEndResponse>;
  independenceDay2020_topContestants: Array<Contestant>;
  installmentLoan?: Maybe<InstallmentLoanDetailResponse>;
  installmentLoanDetail?: Maybe<InstallmentLoanDetailResponse>;
  installmentLoanHistory?: Maybe<Array<Maybe<InstallmentLoanHistoryResponse>>>;
  installmentLoans: InstallmentLoanConnection;
  installmentLoansAdmin: InstallmentLoanAdminConnection;
  installmentLoansEmployerPortal: InstallmentLoanAdminConnection;
  invitationCode: InvitationCode;
  invitation_getAcceptedInvitationEmployments?: Maybe<InvitationEmploymentConnection>;
  invitation_getInvitationCode?: Maybe<Scalars["String"]["output"]>;
  invitation_getTotalAcceptedEmployment?: Maybe<Scalars["Int"]["output"]>;
  invitation_getUnlinkEmployments: InvitationEmploymentConnection;
  invitation_inviteeB2CList: InvitationSummaryInfo;
  invitation_inviteeList: InvitationSummaryInfo;
  invitation_leaderboard?: Maybe<Array<InvitationInviterInfo>>;
  isConfirmedSharingInformation: Scalars["Boolean"]["output"];
  isFirstLoan?: Maybe<Scalars["Boolean"]["output"]>;
  isKYC?: Maybe<ProfileStatus>;
  isKYCReminder?: Maybe<KycReminderResponse>;
  isKYCV2?: Maybe<ProfileStatus>;
  isOnAutoEWA: Scalars["Boolean"]["output"];
  isShowButtonConfirmSettlement?: Maybe<Scalars["Boolean"]["output"]>;
  isShowFeedbackButton?: Maybe<Scalars["Boolean"]["output"]>;
  isValidCredential?: Maybe<Scalars["Boolean"]["output"]>;
  jobNames?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  jobQuenes: Array<Maybe<JobQueneResponse>>;
  kycProfile?: Maybe<ProfileResponse>;
  kycUserInfo: KycUserInfo;
  lanchi_requestLink: LanchiLinkRequest;
  lanchi_requestLinks: LanchiRequestLinkConnection;
  lanchi_totalValidationEntities: Scalars["Int"]["output"];
  lanchi_validationEntities: ValidationEntityConnection;
  lastSubmitSurvey?: Maybe<LastSubmitSurveyResponse>;
  limitPercentageConfigs?: Maybe<LimitPercentageConfigResponse>;
  loanApplication: LoanApplication;
  loanApplicationBatch: LoanApplicationBatch;
  loanApplicationBatches: LoanApplicationBatchConnection;
  loanApplications: LoanApplicationConnection;
  logs: LogConnection;
  makeCheck_notificationProfiles?: Maybe<Makecheck_MakeCheckNotificationProfile>;
  makeCheck_query?: Maybe<MakeCheckRequestConnection>;
  messageBatch_calculateTotalMatchingUsers?: Maybe<Scalars["Int"]["output"]>;
  messageBatch_messageBatch: MessageBatch_MessageBatch;
  messageBatch_messageBatches: MessageBatch_MessageBatchConnection;
  onboardCompanies: OnboardCompanyConnection;
  order_calculateOrder?: Maybe<CalculateOrderPriceRepresentationResponse>;
  otpResendOptions: Array<OtpMethod>;
  packages?: Maybe<Array<Maybe<PackageResponse>>>;
  payLaterFeatureContent?: Maybe<PayLaterFeatureContentResponse>;
  payLaterInterestRate?: Maybe<Scalars["Float"]["output"]>;
  payLaterReasonDeclines: PayLaterReasonDeclineConnection;
  payLaterSelfRepaymentStatus?: Maybe<PayLaterSelfRepaymentStatusResponse>;
  payLaterUserPayableHistory?: Maybe<InstallmentLoanResponse>;
  payLaterWorkPeriodDetail?: Maybe<PayLaterWorkPeriodDetail>;
  payLater_getUserPayableHistoryByInstallmentLoanId: InstallmentLoanResponse;
  paymentHistory?: Maybe<PaymentHistoryResponse>;
  payslip: PayslipResponse;
  payslipAdmin: PayslipAdminConnection;
  payslipCalculatorQualifiers: Array<Scalars["String"]["output"]>;
  payslipDataDetail?: Maybe<PayslipResponse>;
  payslipFeedbackDetail?: Maybe<FeedbackDetailResponse>;
  payslipPeriods: PayslipPeriodConnection;
  pmdashboard_detail: Pmdashboard_TenantInformation;
  pmdashboard_query?: Maybe<Pmdashboard_TenantInformationConnection>;
  preAdvanceConfigs?: Maybe<PreAdvanceConfigResponse>;
  preUser?: Maybe<PreUser>;
  /** campaigns(campaignQuery:CampaignQuery):campaignConnection! */
  productContent: ProductContent;
  products: CategoryConnection;
  productsV2: CategoryConnection;
  profile: User;
  profileFromPhone: Employment;
  profiles: ProfileConnection;
  realmUser?: Maybe<RealmUser>;
  reasonCancelInstallmentLoan: ReasonCancelConnection;
  reasonDeclines: ReasonDeclineConnection;
  requestLink: RequestLink;
  requestLinks: RequestLinkConnection;
  roles?: Maybe<Array<Maybe<Role>>>;
  segments: SegmentConnection;
  settlementAdmin: SettlementAdminConnection;
  shiftMealSurveySubmitAdmin: ShiftMealSurveySubmitConnection;
  spending_getBill: GetBillResponse;
  spending_getBillTransaction?: Maybe<GetExistedPaymentResponse>;
  spending_getCardTransaction?: Maybe<GetExistedPaymentResponse>;
  spending_getTopUpTransaction?: Maybe<GetExistedPaymentResponse>;
  spending_items: Array<ProductResolved>;
  spending_provinces: Array<Province>;
  steps: StepResponse;
  storageObjectById: StorageObject;
  subject?: Maybe<Subject>;
  timeSlices: Array<TimeSlice>;
  transactions: TransactionConnection;
  transfer_CalculateTransferOrder?: Maybe<CalculateTransferResponse>;
  transfer_ConfirmBankAccount?: Maybe<ConfirmBankAccountResponse>;
  transfer_InquiryBankAccount?: Maybe<InquiryBankAccountResponse>;
  tryMatchPhoneWithLinkableCompany?: Maybe<Array<Company>>;
  ui_component: Ui_Component;
  ui_components: UiComponentConnection;
  uptimes: UptimeConnection;
  user: User;
  userInfoForSurvey: UserInfoForSurveyResponse;
  userPayBusinessAdmin: UserPayBusinessAdminConnection;
  userPayBusinessDetail: UserPayBusinessDetailConnection;
  userPaymentProgress?: Maybe<UserPaymentProgressResponse>;
  users: UserConnection;
  vendorCompanies?: Maybe<CompanyResponse>;
  vendorCompanyDashboard?: Maybe<Array<VendorCompanyDashboardResponse>>;
  vendorLoanDashboard?: Maybe<Array<VendorLoanDashboardResponse>>;
  vendorTenants?: Maybe<Array<Maybe<Core_Tenant>>>;
  vendorUsageRateDashboard: VendorUsageRateDashboardResponse;
  vendorUsers: VendorUserConnection;
  vendors?: Maybe<VendorReponse>;
  vendorsLoans?: Maybe<VendorLoanResponse>;
  version: Scalars["String"]["output"];
  viewer: Viewer;
  vn24hInit?: Maybe<Scalars["Void"]["output"]>;
  vn24hSyncAttendances?: Maybe<Scalars["Void"]["output"]>;
  vn24hSyncEmployees?: Maybe<Scalars["Void"]["output"]>;
  waitlist_campaignByCode: Waitlist_Campaign;
  waitlist_companies: Waitlist_CompanyConnection;
  waitlist_company: Waitlist_Company;
  waitlist_user: Waitlist_User;
  waitlist_userProfile: Waitlist_User;
  waitlist_users: Waitlist_UserConnection;
  warningEWATransaction?: Maybe<WarningEwaTransactionResponse>;
};

export type QueryAdmin_InvoiceActivitiesArgs = {
  query?: InputMaybe<Admin_InvoiceActivityQuery>;
};

export type QueryAdmin_InvoiceActivityArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAdmin_InvoiceDetailArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAdmin_InvoiceDetailsArgs = {
  query?: InputMaybe<Admin_InvoiceDetailQuery>;
};

export type QueryAdmin_InvoicePaymentArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAdmin_InvoicePaymentsArgs = {
  query?: InputMaybe<Admin_InvoicePaymentQuery>;
};

export type QueryAdmin_RawTransactionArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAdmin_RawTransactionsArgs = {
  query?: InputMaybe<Admin_RawTxnQuery>;
};

export type QueryAdmin_ReconcileTransactionArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAdmin_ReconcileTransactionsArgs = {
  query?: InputMaybe<Admin_ReconcileTxnQuery>;
};

export type QueryAdmin_ReconcileVendorFileArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAdmin_ReconcileVendorFilesArgs = {
  query?: InputMaybe<Admin_ReconcileVendorFileQuery>;
};

export type QueryAdmin_B2cLoanAccountsArgs = {
  query?: InputMaybe<Admin_B2cLoanAccountQuery>;
};

export type QueryAdmin_EarningProductsArgs = {
  query?: InputMaybe<ProductQuery>;
};

export type QueryAdmin_ExportMisaCreditReportArgs = {
  payload?: InputMaybe<Admin_ExportMisaPayload>;
};

export type QueryAdmin_ExportMisaDebitReportArgs = {
  payload?: InputMaybe<Admin_ExportMisaPayload>;
};

export type QueryAdmin_GetUploadRevisedFileStatusesArgs = {
  revisedFileIds: Array<Scalars["ID"]["input"]>;
};

export type QueryAdmin_InstallmentLoanArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAdmin_InvoiceArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAdmin_InvoiceContractsArgs = {
  query?: InputMaybe<Admin_ContractQuery>;
};

export type QueryAdmin_InvoicesArgs = {
  query?: InputMaybe<Admin_InvoiceQuery>;
};

export type QueryAdmin_ReconcileVendorArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAdmin_ReconcileVendorsArgs = {
  query?: InputMaybe<Admin_ReconcileVendorQuery>;
};

export type QueryAdmin_RequestRecEntriesArgs = {
  query?: InputMaybe<Admin_RequestRecEntryQuery>;
};

export type QueryAdmin_RequestRecEntryArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAdmin_TenantCashbackConfigArgs = {
  tenantId: Scalars["ID"]["input"];
};

export type QueryAdvanceV3_CalculateArgs = {
  employmentId: Scalars["ID"]["input"];
  payload?: InputMaybe<CalculateAdvanceV3Payload>;
};

export type QueryAttendanceArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAttendanceAutomationConfigsArgs = {
  query?: InputMaybe<AttendanceAutomationConfigQuery>;
};

export type QueryAttendancesArgs = {
  query?: AttendanceQuery;
};

export type QueryBankArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryBankBySlugArgs = {
  slug: Scalars["String"]["input"];
};

export type QueryBanksArgs = {
  query?: InputMaybe<BankQuery>;
};

export type QueryBannerArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryBanner_BackgroundImagesArgs = {
  query?: InputMaybe<Banner_BackgroundImageQuery>;
};

export type QueryBanner_BannerArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryBanner_BannerCampaignArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryBanner_BannerCampaignsArgs = {
  query?: InputMaybe<Banner_BannerCampaignQuery>;
};

export type QueryBanner_BannersArgs = {
  query?: InputMaybe<Banner_BannerQuery>;
};

export type QueryBannersArgs = {
  query?: BannerQuery;
};

export type QueryBnpl_DemoCalculateFeeResponseArgs = {
  payload?: InputMaybe<Bnpl_DemoCalculateFeePayload>;
};

export type QueryCalculateLoanFeeArgs = {
  payload: CalculateLoanFeePayload;
};

export type QueryCalculateLoanFeeV2Args = {
  payload: CalculateLoanFeeV2Payload;
};

export type QueryCalculateLoanFeeV2WithAutoApplyVoucherArgs = {
  payload: CalculateLoanFeeV2WithAutoApplyVoucherPayload;
};

export type QueryChargeBusinessAdminArgs = {
  chargeBusinessAdminQuery?: InputMaybe<ChargeBusinessAdminQuery>;
};

export type QueryChargeBusinessDetailAdminArgs = {
  chargeBusinessDetailAdminQuery?: InputMaybe<ChargeBusinessDetailAdminQuery>;
};

export type QueryCheckAmountPayLaterArgs = {
  payload?: InputMaybe<CreateInstallmentLoanPayLoad>;
};

export type QueryCheckFlowChangePasswordArgs = {
  phoneNumber: Scalars["PhoneNumber"]["input"];
};

export type QueryCodepush_ReleaseArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryCodepush_ShouldUpdateArgs = {
  payload?: InputMaybe<Codepush_DeviceInfoInput>;
};

export type QueryComissionDisbursementsArgs = {
  comissionDisbursementQuery?: InputMaybe<ComissionDisbursementQuery>;
};

export type QueryCommon_QueryAppVersionArgs = {
  os?: InputMaybe<Common_Appversion_Os>;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCommunicationTemplateImagesArgs = {
  companyId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryCompaniesArgs = {
  query?: InputMaybe<CompanyQuery>;
};

export type QueryCompanyArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryCompanyBySlugArgs = {
  slug: Scalars["String"]["input"];
};

export type QueryCompanyCategoriesArgs = {
  query?: InputMaybe<CategoryQuery>;
};

export type QueryCompanyCategoryArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryConfigsArgs = {
  query: SystemConfigQueryInput;
};

export type QueryCore_TenantArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryCore_TenantsArgs = {
  query?: InputMaybe<TenantQuery>;
};

export type QueryCronJobsArgs = {
  cronjobQuery?: InputMaybe<CronjobQuery>;
};

export type QueryDisbursementArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryDisbursementsArgs = {
  query?: InputMaybe<DisbursementQuery>;
};

export type QueryDisburser_DisbursementRetryHistoryQueryArgs = {
  query?: InputMaybe<Disburser_DisbursementRetryHistoryQuery>;
};

export type QueryDisburserv2_DisbursementArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryDisburserv2_DisbursementsArgs = {
  query?: InputMaybe<Disburserv2_DisbursementQuery>;
};

export type QueryEmploymentArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryEmploymentConditionSuggestionsArgs = {
  tenantIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type QueryEmploymentSearch_V2Args = {
  query?: InputMaybe<EmploymentQuery>;
};

export type QueryEmploymentSegmentArgs = {
  employmentSegmentQuery?: InputMaybe<EmploymentSegmentQuery>;
};

export type QueryEmploymentsArgs = {
  query?: InputMaybe<EmploymentQuery>;
};

export type QueryExport_GetByTaskIdArgs = {
  taskId: Scalars["ID"]["input"];
};

export type QueryGetAmountPerWorkPeriodArgs = {
  amount: Scalars["Decimal"]["input"];
  packageId: Scalars["ID"]["input"];
};

export type QueryGetConfigArgs = {
  key: Scalars["String"]["input"];
};

export type QueryGetConfigurationsByTenantIdArgs = {
  tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryGetDefaultTopUpConfigArgs = {
  type?: InputMaybe<CellularNetworkItemType>;
};

export type QueryGetDeviceStateArgs = {
  deviceId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetFeatureNameByIdArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryGetHistoryMailsArgs = {
  query?: InputMaybe<MailHistoryQuery>;
};

export type QueryGetMailTemplateByCodeArgs = {
  code?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetMailTemplatesArgs = {
  query?: InputMaybe<MailTemplatesQuery>;
};

export type QueryGetRecentProfileByFeatureNameArgs = {
  featureName?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetScheduleMailsArgs = {
  query?: InputMaybe<MailScheduleGeneratorQuery>;
};

export type QueryGetTaxCodeArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetUpcomingExecuteTimeArgs = {
  code?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetVendorByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetWaitlistRegisterArgs = {
  waitlistQuery?: InputMaybe<WaitlistRegisterQuery>;
};

export type QueryGetWaitlistsArgs = {
  waitlistQuery?: InputMaybe<WaitlistQuery>;
};

export type QueryGetWoayPlayLinkArgs = {
  gameCode: Scalars["String"]["input"];
  userPhone: Scalars["PhoneNumber"]["input"];
};

export type QueryGs25_SyncAttendancesArgs = {
  from?: InputMaybe<Scalars["Instant"]["input"]>;
  to?: InputMaybe<Scalars["Instant"]["input"]>;
};

export type QueryGuestBanksArgs = {
  input: Scalars["String"]["input"];
};

export type QueryHra_SummaryByTenantArgs = {
  tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryHra_YearEndArgs = {
  code?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryInstallmentLoanArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryInstallmentLoanHistoryArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryInstallmentLoansArgs = {
  installmentLoanQuery?: InputMaybe<InstallmentLoanQuery>;
};

export type QueryInstallmentLoansAdminArgs = {
  installmentLoanAdminQuery?: InputMaybe<InstallmentLoanAdminQuery>;
};

export type QueryInstallmentLoansEmployerPortalArgs = {
  installmentLoanAdminQuery?: InputMaybe<InstallmentLoanAdminQuery>;
};

export type QueryInvitationCodeArgs = {
  code: Scalars["String"]["input"];
  tenantId: Scalars["ID"]["input"];
};

export type QueryInvitation_GetAcceptedInvitationEmploymentsArgs = {
  query?: InputMaybe<EmploymentQuery>;
};

export type QueryInvitation_GetUnlinkEmploymentsArgs = {
  query?: InputMaybe<EmploymentQuery>;
};

export type QueryIsConfirmedSharingInformationArgs = {
  phoneNumber: Scalars["PhoneNumber"]["input"];
};

export type QueryIsKycv2Args = {
  featureName?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryIsShowButtonConfirmSettlementArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryIsShowFeedbackButtonArgs = {
  endDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  startDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
};

export type QueryIsValidCredentialArgs = {
  password?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryJobNamesArgs = {
  nameSearch?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryKycProfileArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryLanchi_RequestLinkArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryLanchi_RequestLinksArgs = {
  query?: InputMaybe<LanchiRequestLinkQuery>;
};

export type QueryLanchi_ValidationEntitiesArgs = {
  query?: InputMaybe<ValidationEntityQuery>;
};

export type QueryLastSubmitSurveyArgs = {
  employmentId: Scalars["ID"]["input"];
};

export type QueryLimitPercentageConfigsArgs = {
  query?: InputMaybe<LimitPercentageConfigQuery>;
};

export type QueryLoanApplicationArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryLoanApplicationBatchArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryLoanApplicationBatchesArgs = {
  query?: LoanApplicationBatchQuery;
};

export type QueryLoanApplicationsArgs = {
  query?: InputMaybe<LoanApplicationQuery>;
};

export type QueryLogsArgs = {
  logQuery?: InputMaybe<LogQuery>;
};

export type QueryMakeCheck_QueryArgs = {
  query?: InputMaybe<MakeCheckRequestQuery>;
};

export type QueryMessageBatch_CalculateTotalMatchingUsersArgs = {
  payload?: InputMaybe<MessageBatch_CalculateMatchingUsersPayload>;
};

export type QueryMessageBatch_MessageBatchArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryMessageBatch_MessageBatchesArgs = {
  query?: InputMaybe<MessageBatch_MessageBatchQuery>;
};

export type QueryOnboardCompaniesArgs = {
  query: OnboardCompanyQuery;
};

export type QueryOrder_CalculateOrderArgs = {
  employmentId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
  prepaidPaymentPayload?: InputMaybe<CalculateOrderPaymentUnionPayload>;
};

export type QueryOtpResendOptionsArgs = {
  phoneNumber: Scalars["PhoneNumber"]["input"];
  useCase?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryPayLaterReasonDeclinesArgs = {
  reasonDeclineQuery?: InputMaybe<PayLaterReasonDeclineQuery>;
};

export type QueryPayLaterWorkPeriodDetailArgs = {
  id: Scalars["ID"]["input"];
  isCheckAttendance?: InputMaybe<Scalars["Boolean"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryPayLater_GetUserPayableHistoryByInstallmentLoanIdArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryPaymentHistoryArgs = {
  id: Scalars["ID"]["input"];
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryPayslipArgs = {
  endDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  startDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
};

export type QueryPayslipAdminArgs = {
  payslipAdminQuery?: InputMaybe<PayslipAdminQuery>;
};

export type QueryPayslipDataDetailArgs = {
  employmentId?: InputMaybe<Scalars["ID"]["input"]>;
  payslipHistoryId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryPayslipFeedbackDetailArgs = {
  employmentId?: InputMaybe<Scalars["ID"]["input"]>;
  payslipHistoryId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryPayslipPeriodsArgs = {
  limit: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
};

export type QueryPmdashboard_DetailArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryPmdashboard_QueryArgs = {
  query?: InputMaybe<Pmdashboard_TenantInformationQuery>;
};

export type QueryPreAdvanceConfigsArgs = {
  query?: InputMaybe<PreAdvanceConfigQuery>;
};

export type QueryProductContentArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryProductsArgs = {
  categoryQuery?: InputMaybe<CategoryEarningQuery>;
};

export type QueryProductsV2Args = {
  categoryQuery?: InputMaybe<CategoryEarningQuery>;
};

export type QueryProfileFromPhoneArgs = {
  phoneNumber: Scalars["PhoneNumber"]["input"];
};

export type QueryProfilesArgs = {
  profileQuery?: InputMaybe<ProfileQuery>;
};

export type QueryRealmUserArgs = {
  id: Scalars["String"]["input"];
};

export type QueryReasonCancelInstallmentLoanArgs = {
  reasonCancelQuery?: InputMaybe<ReasonCancelQuery>;
};

export type QueryReasonDeclinesArgs = {
  reasonDeclineQuery?: InputMaybe<ReasonDeclineQuery>;
};

export type QueryRequestLinkArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryRequestLinksArgs = {
  query?: InputMaybe<RequestLinkQuery>;
};

export type QueryRolesArgs = {
  roleQuery?: InputMaybe<RoleQuery>;
};

export type QuerySegmentsArgs = {
  segmentQuery?: InputMaybe<SegmentQuery>;
};

export type QuerySettlementAdminArgs = {
  settlementAdminQuery?: InputMaybe<SettlementAdminQuery>;
};

export type QueryShiftMealSurveySubmitAdminArgs = {
  shiftMealSurveySubmitAdminQuery?: InputMaybe<ShiftMealSurveySubmitAdminQuery>;
};

export type QuerySpending_GetBillArgs = {
  employmentId: Scalars["ID"]["input"];
  payload?: InputMaybe<CreateOrderPayloadV2>;
};

export type QuerySpending_GetBillTransactionArgs = {
  paymentRef: Scalars["String"]["input"];
};

export type QuerySpending_GetCardTransactionArgs = {
  paymentRef: Scalars["String"]["input"];
};

export type QuerySpending_GetTopUpTransactionArgs = {
  paymentRef: Scalars["String"]["input"];
};

export type QuerySpending_ItemsArgs = {
  employmentId: Scalars["ID"]["input"];
  type: Scalars["String"]["input"];
};

export type QueryStepsArgs = {
  featureName?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryStorageObjectByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryTimeSlicesArgs = {
  payload: TimeSlicesPayload;
};

export type QueryTransactionsArgs = {
  transactionEarningQuery?: InputMaybe<TransactionEarningQuery>;
};

export type QueryTransfer_CalculateTransferOrderArgs = {
  payload?: InputMaybe<CalculateTransferPayload>;
};

export type QueryTransfer_ConfirmBankAccountArgs = {
  bankAccount?: InputMaybe<ConfirmBankAccountPayload>;
};

export type QueryTransfer_InquiryBankAccountArgs = {
  payload?: InputMaybe<InquiryBankAccountPayload>;
};

export type QueryUi_ComponentArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryUi_ComponentsArgs = {
  query?: InputMaybe<UiComponentQuery>;
};

export type QueryUptimesArgs = {
  uptimeQuery?: InputMaybe<UptimeQuery>;
};

export type QueryUserArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryUserPayBusinessAdminArgs = {
  userPayBusinessAdminQuery?: InputMaybe<UserPayBusinessAdminQuery>;
};

export type QueryUserPayBusinessDetailArgs = {
  userPayBusinessDetailQuery?: InputMaybe<UserPayBusinessDetailQuery>;
};

export type QueryUsersArgs = {
  query?: UserQuery;
};

export type QueryVendorCompaniesArgs = {
  vendorCompanyQuery?: InputMaybe<VendorCompanyQuery>;
};

export type QueryVendorCompanyDashboardArgs = {
  vendorId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryVendorLoanDashboardArgs = {
  payload: VendorLoanDashboardPayLoad;
};

export type QueryVendorTenantsArgs = {
  codeSearch?: InputMaybe<Scalars["String"]["input"]>;
  vendorId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryVendorUsageRateDashboardArgs = {
  fromDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  toDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  vendorId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryVendorUsersArgs = {
  vendorUserQuery?: InputMaybe<VendorUserQuery>;
};

export type QueryVendorsArgs = {
  vendorQuery?: InputMaybe<VendorQuery>;
};

export type QueryVendorsLoansArgs = {
  vendorLoanQuery?: InputMaybe<VendorLoanQuery>;
};

export type QueryWaitlist_CampaignByCodeArgs = {
  code: Scalars["String"]["input"];
};

export type QueryWaitlist_CompaniesArgs = {
  query?: Waitlist_CompanyQuery;
};

export type QueryWaitlist_CompanyArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryWaitlist_UserArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryWaitlist_UsersArgs = {
  query?: Waitlist_UserQuery;
};

export type QueryWarningEwaTransactionArgs = {
  amount?: InputMaybe<Scalars["Money"]["input"]>;
  fee?: InputMaybe<Scalars["Money"]["input"]>;
};

export type QuestionResponse = {
  answer: Scalars["String"]["output"];
};

export type Rating = {
  createdDate: Scalars["Instant"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  ratingIcon?: Maybe<Scalars["String"]["output"]>;
  ratingNumber?: Maybe<Scalars["Float"]["output"]>;
  tags?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type Realm = {
  code: Scalars["String"]["output"];
};

export type RealmData = {
  realm: Scalars["String"]["output"];
  realmRoles: Array<RoleEntity>;
  realmUsers: Array<RealmUser>;
};

export type RealmPayload = {
  code: Scalars["String"]["input"];
};

export type RealmUser = {
  accessControl?: Maybe<AccessControl_UserAccessControl>;
  accountId: Scalars["String"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  realm?: Maybe<Scalars["String"]["output"]>;
  username?: Maybe<Scalars["String"]["output"]>;
};

export enum ReasonCancelOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export type ReasonCancelQuery = {
  filter?: InputMaybe<ReasonCancelQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<ReasonCancelOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type ReasonCancelQueryFilter = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum ReasonDeclineOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export type ReasonDeclineQuery = {
  filter?: InputMaybe<ReasonDeclineQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<ReasonDeclineOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type ReasonDeclineQueryFilter = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ReasonDetail = {
  applicableDate?: Maybe<Scalars["String"]["output"]>;
  reasonCode: Scalars["String"]["output"];
  reasonDetail?: Maybe<Scalars["String"]["output"]>;
  reasonName?: Maybe<Scalars["String"]["output"]>;
};

export type ReasonResponse = {
  code: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  requiredDate: Scalars["String"]["output"];
};

export type RegisterDevicePayload = {
  deviceId: Scalars["String"]["input"];
  deviceName: Scalars["String"]["input"];
  notificationToken?: InputMaybe<Scalars["String"]["input"]>;
  os?: InputMaybe<Scalars["String"]["input"]>;
};

export type RegisterNotificationTokenPayload = {
  token: Scalars["String"]["input"];
};

export type RejectChargeUserFromBusinessPayLoad = {
  userPayBusinessId: Scalars["ID"]["input"];
};

export enum RejectDisbursementReason {
  InvalidAccount = "InvalidAccount",
  Other = "Other",
  Todo = "TODO",
}

export type RejectLoanApplicationPayload = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  reason: RejectLoanApplicationReason;
};

export enum RejectLoanApplicationReason {
  Unknown = "Unknown",
}

export type ReplaceableExtension = {
  token: Scalars["String"]["output"];
};

export type RequestChangePasswordResponse = {
  debug_otp?: Maybe<Scalars["String"]["output"]>;
  otp: OtpResponse;
  requestId: Scalars["String"]["output"];
};

export type RequestDeviceTokenPayload = {
  otp: Scalars["String"]["input"];
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
  requestId: Scalars["String"]["input"];
};

export type RequestDeviceTokenResponse = {
  deviceToken: Scalars["String"]["output"];
};

export type RequestLink = {
  bank?: Maybe<Bank>;
  bankAccount?: Maybe<Scalars["String"]["output"]>;
  company: Company;
  createdDate: Scalars["Instant"]["output"];
  dataSharingAgreementFile?: Maybe<StorageObject>;
  email?: Maybe<Scalars["Email"]["output"]>;
  employeeCode: Scalars["String"]["output"];
  employeeId: Scalars["String"]["output"];
  employment?: Maybe<Employment>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lanchi_requestLink: LanchiLinkRequest;
  lastModifiedDate: Scalars["Instant"]["output"];
  rejectMessage?: Maybe<Scalars["String"]["output"]>;
  socialId?: Maybe<Scalars["String"]["output"]>;
  socialIdIssuedDate?: Maybe<Scalars["LocalDate"]["output"]>;
  socialIdIssuedPlace?: Maybe<Scalars["String"]["output"]>;
  status: RequestLinkStatus;
  user: User;
};

export type RequestLinkConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<RequestLink>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type RequestLinkQuery = {
  all?: InputMaybe<Scalars["Boolean"]["input"]>;
  filters?: InputMaybe<Array<RequestLinkQueryFilter>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<RequestLinkQueryOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type RequestLinkQueryFilter = {
  createdDate?: InputMaybe<OffsetDateTimeRange>;
  employeeId?: InputMaybe<Array<Scalars["String"]["input"]>>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  lastModifiedDate?: InputMaybe<Scalars["OffsetDateTime"]["input"]>;
  phoneNumbers?: InputMaybe<Array<Scalars["String"]["input"]>>;
  socialIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status?: InputMaybe<Array<RequestLinkStatus>>;
};

export enum RequestLinkQueryOrderable {
  CreatedDate = "CreatedDate",
  EmployeeId = "EmployeeId",
  Index = "Index",
  LastModifiedDate = "LastModifiedDate",
}

export enum RequestLinkStatus {
  Approved = "Approved",
  Initialized = "Initialized",
  Rejected = "Rejected",
}

export type RequestLoginPayload = {
  confirmSharingInformation?: InputMaybe<Scalars["Boolean"]["input"]>;
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber: Scalars["PhoneNumber"]["input"];
};

export type RequestLoginResponse = {
  debug_otp?: Maybe<Scalars["String"]["output"]>;
  isNew: Scalars["Boolean"]["output"];
  otp: OtpResponse;
  token: Scalars["String"]["output"];
};

export type RequestResendOtpPayload = {
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
  otpMethod?: InputMaybe<OtpMethod>;
  phoneNumber: Scalars["PhoneNumber"]["input"];
};

export type RequestResendOtpResponse = {
  debug_otp?: Maybe<Scalars["String"]["output"]>;
  otp: OtpResponse;
};

export type ResendChangePhoneNumberOtp = {
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
  otpMethod?: InputMaybe<OtpMethod>;
  requestId: Scalars["ID"]["input"];
};

export type ResetTenantUserPasswordResponse = {
  password: Scalars["String"]["output"];
  user: TenantUser;
};

export type ResignDataResponse = {
  payLater?: Maybe<ResignPayLaterResponse>;
  saving?: Maybe<ResignSavingResponse>;
};

export type ResignPayLaterResponse = {
  contentHome?: Maybe<Scalars["String"]["output"]>;
  isEnable?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ResignSavingResponse = {
  contentHome?: Maybe<Scalars["String"]["output"]>;
  isEnable?: Maybe<Scalars["Boolean"]["output"]>;
};

export type Result = {
  datas?: Maybe<Array<Maybe<FeatureObject>>>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type Reward = {
  createdDate: Scalars["Instant"]["output"];
  data: Scalars["JSON"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  status: RewardStatus;
  type: RewardType;
};

export enum RewardStatus {
  Pending = "Pending",
  Processed = "Processed",
}

export enum RewardType {
  ExternalVoucher = "ExternalVoucher",
  LoanVoucher1m = "LoanVoucher1m",
  LoanVoucher2m = "LoanVoucher2m",
  LoanVoucher200k = "LoanVoucher200k",
  LoanVoucher500k = "LoanVoucher500k",
  PhoneCard10k = "PhoneCard10k",
  PhoneCard20k = "PhoneCard20k",
}

export type Role = {
  features?: Maybe<Array<Maybe<Feature>>>;
  id?: Maybe<Scalars["ID"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type RoleEntity = {
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  permissions: Array<Scalars["String"]["output"]>;
};

export type RoleFilter = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  realm?: InputMaybe<Scalars["String"]["input"]>;
};

export enum RoleOrderable {
  Id = "id",
}

export type RolePayload = {
  features?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  name: Scalars["String"]["input"];
};

export type RoleQuery = {
  filter?: InputMaybe<RoleFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<RoleOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type SaveAppraisalKycPayload = {
  description?: InputMaybe<Scalars["String"]["input"]>;
};

export type SavingConfig = {
  interestIssuePeriod: Scalars["String"]["output"];
  interestRate: Scalars["Float"]["output"];
};

export type SavingSystemConfig = {
  minWalletValueToWithdraw: Scalars["Money"]["output"];
  minimumTopUpValue: Scalars["Money"]["output"];
};

export type SavingWithdrawFeeConfig = {
  daysSinceLastTopUp?: Maybe<Scalars["Int"]["output"]>;
  fixedFee?: Maybe<Scalars["Money"]["output"]>;
  minimumFee?: Maybe<Scalars["Money"]["output"]>;
  percentageFee?: Maybe<Scalars["Decimal"]["output"]>;
};

export type Section = {
  isHighlight: Scalars["Boolean"]["output"];
  items?: Maybe<Array<Item>>;
  order: Scalars["Int"]["output"];
  type: Scalars["String"]["output"];
};

export enum SegmentOrderable {
  Id = "Id",
}

export type SegmentQuery = {
  filter?: InputMaybe<SegmentQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<SegmentOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type SegmentQueryFilter = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  keySearch?: InputMaybe<Scalars["String"]["input"]>;
};

export type SegmentResponse = {
  code?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  effectiveEndDate?: Maybe<Scalars["LocalDate"]["output"]>;
  effectiveStartDate: Scalars["LocalDate"]["output"];
  employmentSegment?: Maybe<EmploymentSegmentResponse>;
  id?: Maybe<Scalars["ID"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<SegmentStatus>;
  totalEmployment?: Maybe<Scalars["Int"]["output"]>;
};

export enum SegmentStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type SendFinedMessageToEmploymentsPayload = {
  content: Scalars["String"]["input"];
  filter: EmploymentQueryFilter;
  finedId: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
};

export type SendMessagePayload = {
  content: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  userId: Scalars["ID"]["input"];
};

export type SendMessageToEmploymentsPayload = {
  content: Scalars["String"]["input"];
  filter: EmploymentQueryFilter;
  tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title: Scalars["String"]["input"];
};

export type SendPushNotificationPayload = {
  content: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
};

export type SendUserOtpV2Response = {
  debug_otp?: Maybe<Scalars["String"]["output"]>;
  otp: OtpResponse;
};

export type SettlementAdminQuery = {
  filter?: InputMaybe<SettlementAdminQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<UserPayHistoryOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type SettlementAdminQueryFilter = {
  amount?: InputMaybe<MoneyRangeFilter>;
  companyId?: InputMaybe<Scalars["ID"]["input"]>;
  createdDateRange?: InputMaybe<LocalDateRange>;
  keySearch?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus?: InputMaybe<PaymentStatus>;
  paymentType?: InputMaybe<PaymentType>;
  status?: InputMaybe<SettlementStatus>;
  tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type SettlementAdminResponse = {
  change?: Maybe<Scalars["Money"]["output"]>;
  companyCode?: Maybe<Scalars["String"]["output"]>;
  createdDate?: Maybe<Scalars["Instant"]["output"]>;
  currentPeriod?: Maybe<Scalars["String"]["output"]>;
  employeeId?: Maybe<Scalars["String"]["output"]>;
  employmentStatus?: Maybe<EmploymentStatus>;
  endDate?: Maybe<Scalars["LocalDate"]["output"]>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  installmentLoanId?: Maybe<Scalars["ID"]["output"]>;
  isInReconcile?: Maybe<Scalars["Boolean"]["output"]>;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentType?: Maybe<PaymentType>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["LocalDate"]["output"]>;
  status?: Maybe<SettlementStatus>;
  tenantCode?: Maybe<Scalars["String"]["output"]>;
  transactionCode?: Maybe<Scalars["String"]["output"]>;
  userPayAmount?: Maybe<Scalars["Money"]["output"]>;
  userPayableAmount?: Maybe<Scalars["Money"]["output"]>;
};

export enum SettlementStatus {
  Enough = "Enough",
  Excess = "Excess",
  Lack = "Lack",
}

export enum ShiftMealSurveySubmitAdminOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export type ShiftMealSurveySubmitAdminQuery = {
  filter?: InputMaybe<ShiftMealSurveySubmitAdminQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<ShiftMealSurveySubmitAdminOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type ShiftMealSurveySubmitAdminQueryFilter = {
  companyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  createdDateRange?: InputMaybe<LocalDateRange>;
  department?: InputMaybe<Scalars["String"]["input"]>;
  employeeId?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  score?: InputMaybe<Scalars["Int"]["input"]>;
  scores?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  tenantIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type ShiftMealSurveySubmitResponse = {
  createdDate?: Maybe<Scalars["Instant"]["output"]>;
  department?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  employeeId?: Maybe<Scalars["String"]["output"]>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  phone?: Maybe<Scalars["PhoneNumber"]["output"]>;
  reasons?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  score?: Maybe<Scalars["Int"]["output"]>;
};

export enum ShiftUnit {
  Day = "Day",
  Hour = "Hour",
}

export enum ShiftWorkType {
  Day = "Day",
  Night = "Night",
}

export type SignAgreementCloseFeaturePayload = {
  employmentId: Scalars["ID"]["input"];
  otp: Scalars["String"]["input"];
  otpLength?: Scalars["Int"]["input"];
};

export type SocialIdDocument = {
  date?: Maybe<Scalars["LocalDate"]["output"]>;
  number: Scalars["String"]["output"];
  place?: Maybe<Scalars["String"]["output"]>;
};

export enum SoftpinCardType {
  SoftpinCard = "SoftpinCard",
  SoftpinData = "SoftpinData",
  Unknown = "Unknown",
}

export type SoftpinConfigV2 = {
  isUsable: Scalars["Boolean"]["output"];
  limitation: Scalars["Money"]["output"];
};

export type SpdUser = {
  bill: Spending_Bill;
  bills: Spending_BillConnection;
  cards: CardConnection;
  cardsV2?: Maybe<TopUpCardResponse>;
  id: Scalars["ID"]["output"];
  order?: Maybe<Order>;
  transferContacts?: Maybe<TransferContactResponse>;
  transferHistory: Array<Maybe<TransferInfo>>;
};

export type SpdUserBillArgs = {
  id: Scalars["ID"]["input"];
};

export type SpdUserBillsArgs = {
  query?: InputMaybe<Spending_BillQuery>;
};

export type SpdUserCardsArgs = {
  query?: InputMaybe<CardQuery>;
};

export type SpdUserCardsV2Args = {
  query?: InputMaybe<TopUpCardQuery>;
};

export type SpdUserOrderArgs = {
  id: Scalars["ID"]["input"];
};

export type SpdUserTransferContactsArgs = {
  query?: InputMaybe<TransferContactQuery>;
};

export type Spending_Bill = {
  billCode: Scalars["String"]["output"];
  billResponse?: Maybe<GetBillResponse>;
  createdDate: Scalars["Instant"]["output"];
  customerInfo?: Maybe<Spending_Bill_CustomerInfo>;
  id: Scalars["ID"]["output"];
  lastFetchTime?: Maybe<Scalars["Instant"]["output"]>;
  lastModifiedDate: Scalars["Instant"]["output"];
  paidYet?: Maybe<Scalars["Boolean"]["output"]>;
  reminderStatus: Spending_BillReminderStatus;
  service?: Maybe<Spending_ServiceProvider>;
  serviceCode: Scalars["String"]["output"];
  status: Spending_BillStatus;
  type: BillType;
  unpaidAmount?: Maybe<Scalars["Money"]["output"]>;
};

export type Spending_BillConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Spending_Bill>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
  unpaidAmount: Array<Scalars["Money"]["output"]>;
};

export enum Spending_BillOrderable {
  Id = "id",
}

export type Spending_BillQuery = {
  filter?: InputMaybe<Spending_BillQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Spending_BillOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Spending_BillQueryFilter = {
  billCodes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lastFetchTime?: InputMaybe<InstantFilter>;
  paidYet?: InputMaybe<Scalars["Boolean"]["input"]>;
  serviceCodes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  statuses?: InputMaybe<Array<Spending_BillStatus>>;
  types?: InputMaybe<Array<BillType>>;
  unpaidAmount?: InputMaybe<MoneyRangeFilter>;
};

export enum Spending_BillReminderStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export enum Spending_BillStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type Spending_BillTransaction = {
  amount: Scalars["Money"]["output"];
  bill: Spending_Bill;
  id: Scalars["ID"]["output"];
  metadata?: Maybe<Spending_BillTransaction_Metadata>;
  partnerTransId: Scalars["String"]["output"];
  refCode: Scalars["String"]["output"];
};

export type Spending_BillTransaction_Metadata = {
  code?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  periodInMonth?: Maybe<Scalars["Int"]["output"]>;
  periodInString?: Maybe<Scalars["String"]["output"]>;
};

export type Spending_Bill_CustomerInfo = {
  address?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type Spending_CreateBillPayload = {
  billCode: Scalars["String"]["input"];
  customerInfo?: InputMaybe<Spending_CustomerInfo>;
  reminderStatus?: InputMaybe<Spending_BillReminderStatus>;
  serviceCode: Scalars["String"]["input"];
  type: BillType;
};

export type Spending_CustomerInfo = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type Spending_OrderExtension = {
  extraPackageCode?: InputMaybe<Scalars["String"]["input"]>;
  replaceToken?: InputMaybe<Spending_OrderExtension_ReplaceToken>;
};

export type Spending_OrderExtension_ReplaceToken = {
  replaceWith: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

/**
 * extend type Employment {
 *     spdUser: SpdUser!
 * }
 */
export type Spending_ServiceProvider = {
  code: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  logo?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  shortName: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export enum Status {
  Failed = "Failed",
  InProgress = "InProgress",
  Pending = "Pending",
  Success = "Success",
  Unknown = "Unknown",
}

export type StepResponse = {
  skip?: Maybe<Scalars["Boolean"]["output"]>;
  steps?: Maybe<Array<Maybe<Step>>>;
};

export type StorageObject = {
  cachedUri: Scalars["URI"]["output"];
  contentType?: Maybe<Scalars["String"]["output"]>;
  filename: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  thumbnail: StorageObject;
  uri: Scalars["URI"]["output"];
};

export type StorageObjectThumbnailArgs = {
  name: Scalars["String"]["input"];
};

export type StringFilter = {
  equalTo?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  includes?: InputMaybe<Scalars["String"]["input"]>;
  includesInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  notEqualTo?: InputMaybe<Scalars["String"]["input"]>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  notIncludes?: InputMaybe<Scalars["String"]["input"]>;
  notIncludesInsensitive?: InputMaybe<Scalars["String"]["input"]>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<StringFilterInput>>;
  eq?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  includes?: InputMaybe<Scalars["String"]["input"]>;
  normalizedIncludes?: InputMaybe<Scalars["String"]["input"]>;
  not?: InputMaybe<StringFilterInput>;
  or?: InputMaybe<Array<StringFilterInput>>;
};

export type StringFilterV2 = {
  and?: InputMaybe<Array<InputMaybe<StringFilterV2>>>;
  eq?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  includes?: InputMaybe<Scalars["String"]["input"]>;
  normalizedIncludes?: InputMaybe<Scalars["String"]["input"]>;
  not?: InputMaybe<StringFilterV2>;
  or?: InputMaybe<Array<InputMaybe<StringFilterV2>>>;
};

export type Subject = {
  id: Scalars["String"]["output"];
  realm: Realm;
  resourceAccess: Array<Scalars["String"]["output"]>;
};

export type SubjectResourceAccessArgs = {
  resource?: InputMaybe<Scalars["String"]["input"]>;
};

export type SubjectPayload = {
  id: Scalars["String"]["input"];
  realm: RealmPayload;
};

export type SubmitToHandleChargeUserFromBusiness = {
  payDate: Scalars["LocalDate"]["input"];
  rows: Array<InputMaybe<ImportChargeUserFromBusinessData>>;
  totalAmount: Scalars["Int"]["input"];
  totalTransaction: Scalars["Int"]["input"];
};

export type Subscription = {
  subUserDevice?: Maybe<Scalars["String"]["output"]>;
};

export type SubscriptionSubUserDeviceArgs = {
  deviceId?: InputMaybe<Scalars["String"]["input"]>;
};

export type SuccessOrderPayload = {
  externalRef: Scalars["String"]["input"];
  settleAt?: InputMaybe<Scalars["Instant"]["input"]>;
};

export type Survey = {
  createdDate: Scalars["Instant"]["output"];
  data: Scalars["JSON"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  surveyId: Scalars["String"]["output"];
};

export type SurveyForm = {
  banner?: Maybe<StorageObject>;
  createdDate: Scalars["Instant"]["output"];
  displayStatus: SurveyFormStatus;
  editors: Array<Scalars["String"]["output"]>;
  employeeList?: Maybe<Array<SurveyUploadEmployeeData>>;
  formLink: Scalars["String"]["output"];
  formName: Scalars["String"]["output"];
  fromDate?: Maybe<Scalars["LocalDate"]["output"]>;
  fromInstant?: Maybe<Scalars["Instant"]["output"]>;
  id: Scalars["ID"]["output"];
  informDate?: Maybe<Scalars["Instant"]["output"]>;
  lastModifiedDate: Scalars["Instant"]["output"];
  overrideStatus?: Maybe<SurveyForm_OverrideStatus>;
  prefillDataList?: Maybe<Array<SurveyFormPrefillData>>;
  questions?: Maybe<Array<FormQuestion>>;
  response: Survey_SurveyFormResponse;
  responseV2: Survey_SurveyResponseV2;
  resultLink: Scalars["String"]["output"];
  segment?: Maybe<Scalars["JSON"]["output"]>;
  status: SurveyFormStatus;
  toDate?: Maybe<Scalars["LocalDate"]["output"]>;
  toInstant?: Maybe<Scalars["Instant"]["output"]>;
};

export type SurveyFormConnection = {
  limit?: Maybe<Scalars["Int"]["output"]>;
  nodes?: Maybe<Array<Maybe<SurveyForm>>>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type SurveyFormFilter = {
  createdDateRange?: InputMaybe<LocalDateRange>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  statuses?: InputMaybe<Array<SurveyFormStatus>>;
  tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum SurveyFormOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
  LastModifiedDate = "LastModifiedDate",
  ToDate = "ToDate",
}

export enum SurveyFormPrefillData {
  Department = "Department",
  EmployeeId = "EmployeeId",
  EmploymentFullName = "EmploymentFullName",
  PhoneNumber = "PhoneNumber",
  Workplace = "Workplace",
}

export type SurveyFormQuery = {
  filter?: InputMaybe<SurveyFormFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<SurveyFormOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type SurveyFormResponse = {
  surveyForm?: Maybe<SurveyForm>;
};

export enum SurveyFormStatus {
  Active = "Active",
  Cancelled = "Cancelled",
  Distributed = "Distributed",
  Draft = "Draft",
  Expired = "Expired",
  Published = "Published",
}

export type SurveyResponseDetail = {
  question: FormQuestion;
  responses: Array<QuestionResponse>;
};

export type SurveyResponseDetailV2 = {
  question: FormQuestion;
  response: Scalars["String"]["output"];
};

export type SurveyUploadEmployeeData = {
  department?: Maybe<Scalars["String"]["output"]>;
  employeeFullName: Scalars["String"]["output"];
  employeeId: Scalars["String"]["output"];
};

export type SyncContactPayload = {
  employmentId: Scalars["ID"]["input"];
  phoneNumbers: Array<InputMaybe<Scalars["PhoneNumber"]["input"]>>;
};

export type SyncEmploymentByFilePayload = {
  fieldId: Scalars["String"]["input"];
  storageObjectId: Scalars["ID"]["input"];
  tenantId: Scalars["ID"]["input"];
};

export enum SynchronizeMethod {
  Api = "API",
  Excel = "EXCEL",
  Rpa = "RPA",
}

export type SystemConfigQueryInput = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<SystemConfigQuery_FilterInput>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Long"]["input"]>;
  orderBy?: InputMaybe<SystemConfigQuery_Orderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type SystemConfigQuery_FilterInput = {
  key?: InputMaybe<StringFilterInput>;
};

export enum SystemConfigQuery_Orderable {
  CreatedDate = "createdDate",
  Key = "key",
  LastModifiedDate = "lastModifiedDate",
}

export type Task = {
  bucket?: Maybe<Scalars["String"]["output"]>;
  company: Company;
  createdDate: Scalars["Instant"]["output"];
  failedPayload?: Maybe<Scalars["JSON"]["output"]>;
  failedRowCount?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  jobMethod?: Maybe<ImportJobMethod>;
  jobType?: Maybe<ImportJobType>;
  key?: Maybe<Scalars["String"]["output"]>;
  lastModifiedDate: Scalars["Instant"]["output"];
  rowCount?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<TaskStatus>;
  successRowCount?: Maybe<Scalars["Int"]["output"]>;
};

export enum TaskStatus {
  Error = "Error",
  Finished = "Finished",
  InProgress = "InProgress",
  Initial = "Initial",
}

export type TenantCampaignDiscountConfig = {
  aboveItemText?: Maybe<Scalars["String"]["output"]>;
  aboveItemTextColor?: Maybe<Scalars["String"]["output"]>;
  banner: StorageObject;
  code: Scalars["String"]["output"];
  descriptionText?: Maybe<Scalars["String"]["output"]>;
  descriptionTextColor?: Maybe<Scalars["String"]["output"]>;
  detailUrl?: Maybe<Scalars["String"]["output"]>;
  discountItems: Array<DiscountedItem>;
  homeDisplayInfo: Array<HomeDisplayInfo>;
  itemColor?: Maybe<ItemColor>;
  textBackgroundColor?: Maybe<Scalars["String"]["output"]>;
  textColor?: Maybe<Scalars["String"]["output"]>;
  time: InstantRange;
};

export type TenantCampaignDiscountConfigDiscountItemsArgs = {
  employmentId: Scalars["ID"]["input"];
};

export type TenantConnection = {
  limit: Scalars["Int"]["output"];
  nodes?: Maybe<Array<Core_Tenant>>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type TenantDemoInfo = {
  demoDisburser?: Maybe<Scalars["Boolean"]["output"]>;
  type?: Maybe<TenantDemoType>;
};

export enum TenantDemoType {
  Demo = "Demo",
  Training = "Training",
}

export type TenantFilter = {
  activeCreditFeatures?: InputMaybe<Scalars["Boolean"]["input"]>;
  authRealm?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<NormalizedStringFilter>;
  codeSearch?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Core_TenantIdFilter>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  statuses?: InputMaybe<Array<InputMaybe<Core_TenantStatus>>>;
};

export enum TenantFunding {
  ByClient = "BY_CLIENT",
  ByNano = "BY_NANO",
}

export enum TenantModel {
  Ewa = "EWA",
  EwaPlus = "EWA_PLUS",
  Mix = "MIX",
}

export enum TenantPermission {
  ManageAttendances = "ManageAttendances",
  ManageEmployments = "ManageEmployments",
  ManageRequestLinks = "ManageRequestLinks",
  ManageUsers = "ManageUsers",
  ViewAttendances = "ViewAttendances",
  ViewAuditLogs = "ViewAuditLogs",
  ViewDisbursements = "ViewDisbursements",
  ViewEmployments = "ViewEmployments",
  ViewLoanApplications = "ViewLoanApplications",
  ViewRequestLinks = "ViewRequestLinks",
}

export type TenantQuery = {
  filter?: InputMaybe<TenantFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Core_TenantOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type TenantRole = {
  description?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  permissions: Array<TenantPermission>;
};

export type TenantSavingConfigResponse = {
  interestIssuePeriod: Scalars["String"]["output"];
  interestRate: Scalars["Float"]["output"];
  isUsable: Scalars["Boolean"]["output"];
};

export type TenantTopUpConfigPayload = {
  cardUsable: Scalars["Boolean"]["input"];
  cellularNetworkPayloads: Array<TopUpCellularConfigPayload>;
  isUsable: Scalars["Boolean"]["input"];
  limitation: Scalars["Money"]["input"];
  networkItemType?: InputMaybe<CellularNetworkItemType>;
};

export type TenantTopUpConfigResponse = {
  cardUsable: Scalars["Boolean"]["output"];
  cellularNetworkConfigs?: Maybe<Array<CellularNetworkConfig>>;
  isUsable: Scalars["Boolean"]["output"];
  limitation: Scalars["Money"]["output"];
};

export type TenantUser = {
  email?: Maybe<Scalars["Email"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  role?: Maybe<Scalars["String"]["output"]>;
  status: TenantUserStatus;
  username: Scalars["String"]["output"];
};

export type TenantUserConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<TenantUser>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type TenantUserQuery = {
  filter?: InputMaybe<TenantUserQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TenantUserQueryFilter = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export enum TenantUserStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type TermAndCondition = {
  createdDate: Scalars["Instant"]["output"];
  file: StorageObject;
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
};

export type TimeSlice = {
  from: Scalars["OffsetDateTime"]["output"];
  loanApplicationsByCreatedDate: LoanApplicationConnection;
  loanApplicationsByLastModifiedDate: LoanApplicationConnection;
  to: Scalars["OffsetDateTime"]["output"];
};

export type TimeSliceLoanApplicationsByCreatedDateArgs = {
  query?: LoanApplicationQuery;
};

export type TimeSliceLoanApplicationsByLastModifiedDateArgs = {
  query?: LoanApplicationQuery;
};

export type TimeSlicesPayload = {
  from: Scalars["OffsetDateTime"]["input"];
  step: Scalars["Duration"]["input"];
  to: Scalars["OffsetDateTime"]["input"];
};

export type TopUpCard = {
  amount: Scalars["Money"]["output"];
  carrier: CarrierType;
  carrierDataInfo?: Maybe<CarrierDataInfo>;
  id: Scalars["ID"]["output"];
  orderId: Scalars["ID"]["output"];
  pinCode: Scalars["String"]["output"];
  serialNumber: Scalars["String"]["output"];
  settledDate: Scalars["Instant"]["output"];
  spdUserId: Scalars["ID"]["output"];
  type: SoftpinCardType;
  usedFlag?: Maybe<Scalars["Boolean"]["output"]>;
};

export type TopUpCardFilter = {
  carrier?: InputMaybe<CarrierType>;
  carriers?: InputMaybe<Array<InputMaybe<CarrierType>>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
  orderIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  spdUserId?: InputMaybe<Scalars["ID"]["input"]>;
  spdUserIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  types?: InputMaybe<Array<SoftpinCardType>>;
};

export enum TopUpCardOrderable {
  Id = "Id",
  SettledDate = "SettledDate",
}

export type TopUpCardQuery = {
  filter?: InputMaybe<TopUpCardFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<TopUpCardOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type TopUpCardResponse = {
  limit?: Maybe<Scalars["Int"]["output"]>;
  nodes?: Maybe<Array<TopUpCard>>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type TopUpCellularConfigPayload = {
  items: Array<CellularConfigItemPayload>;
  name: Scalars["String"]["input"];
  order?: InputMaybe<Scalars["Int"]["input"]>;
  status: CellularNetworkStatus;
  thumbnailId?: InputMaybe<Scalars["ID"]["input"]>;
  type: CellularNetworkType;
};

export type TopUpConfig = {
  cellularNetworkConfigs?: Maybe<Array<CellularNetworkConfig>>;
  limitation: Scalars["Money"]["output"];
};

export type TopUpInfo = {
  amount: Scalars["Money"]["output"];
  carrierType: CarrierType;
};

export type TopUpResponseData = {
  amount: Scalars["Money"]["output"];
  carrier: CarrierType;
  carrierData?: Maybe<CarrierDataInfo>;
  targetPhone: Scalars["PhoneNumber"]["output"];
};

export type Transaction = {
  beneficiary: Beneficiary;
  createdDate: Scalars["Instant"]["output"];
  failedReason?: Maybe<TransactionFailedReason>;
  id: Scalars["ID"]["output"];
  item: TransactionItem;
  lastModifiedDate: Scalars["Instant"]["output"];
  status: TransactionStatus;
};

export enum TransactionEarningOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export type TransactionEarningQuery = {
  filter?: InputMaybe<TransactionEarningQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<TransactionEarningOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type TransactionEarningQueryFilter = {
  endDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  keySearch?: InputMaybe<Scalars["String"]["input"]>;
  productCode?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  status?: InputMaybe<EarningCallbackResponseStatus>;
};

export type TransactionFailedReason = {
  errorCode: Scalars["String"]["output"];
  errorMessage?: Maybe<Scalars["String"]["output"]>;
};

export type TransactionItem = {
  amount: Scalars["Decimal"]["output"];
  vendorItem: Scalars["String"]["output"];
  vendorType: Scalars["String"]["output"];
};

export enum TransactionStatus {
  Failed = "Failed",
  InProgress = "InProgress",
  Pending = "Pending",
  Success = "Success",
  Unknown = "Unknown",
}

export type TransactionSummary = {
  amount: Scalars["Money"]["output"];
  type?: Maybe<Scalars["String"]["output"]>;
};

export type TransferContact = {
  id: Scalars["ID"]["output"];
  phoneNumber: Scalars["String"]["output"];
};

export type TransferContactFilter = {
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type TransferContactQuery = {
  filter?: InputMaybe<TransferContactFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TransferContactResponse = {
  limit: Scalars["Int"]["output"];
  nodes: Array<TransferContact>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type TransferGreetingCardPayload = {
  content: Scalars["String"]["input"];
  receiverId?: InputMaybe<Scalars["ID"]["input"]>;
  templateId: Scalars["ID"]["input"];
};

export type TransferInfo = {
  bankAccount: Scalars["String"]["output"];
  bankAccountName: Scalars["String"]["output"];
  bankCode?: Maybe<Scalars["String"]["output"]>;
  bankInfo?: Maybe<Bank>;
  transferDesc?: Maybe<Scalars["String"]["output"]>;
};

export type TransferMoneySourceDetail = {
  ewaDetails: Array<EwaTransferDetail>;
  wVoucherDetails?: Maybe<Array<WVoucherTransferDetail>>;
};

export enum TransferType {
  QrTransfer = "QrTransfer",
  Transfer = "Transfer",
}

export type Transfer_CalculationContext = {
  bankAccount: Scalars["String"]["input"];
  bankAccountName: Scalars["String"]["input"];
  bankCode: Scalars["String"]["input"];
  transferAmount: Scalars["Money"]["input"];
  transferDesc?: InputMaybe<Scalars["String"]["input"]>;
};

export type TriggerAttendanceAutomationPayload = {
  tenantId: Scalars["ID"]["input"];
};

export type TryAutoLinkEmploymentPayload = {
  companyId: Scalars["ID"]["input"];
};

export type TryAutoLinkEmploymentResponse = {
  employment?: Maybe<Employment>;
};

export type TweduLinkEmploymentPayload = {
  socialId: Scalars["ID"]["input"];
};

export type Ui = {
  name: Scalars["String"]["output"];
};

export type UiComponentConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Ui_Component>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type UiComponentQuery = {
  filter?: InputMaybe<UiComponentQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Ui_ComponentOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type UiComponentQueryFilter = {
  code?: InputMaybe<NormalizedStringFilter>;
  statuses?: InputMaybe<Array<Ui_ComponentStatus>>;
  time?: InputMaybe<InstantRangeInput>;
  types?: InputMaybe<Array<Ui_ComponentType>>;
};

export type UiComponentRepresentation = {
  code?: Maybe<Scalars["String"]["output"]>;
  link?: Maybe<Scalars["String"]["output"]>;
  maxCounter: Scalars["Int"]["output"];
  waitTimeBetweenClosure: Scalars["String"]["output"];
};

/** userInterfaceInstructions */
export type UiManagement = {
  displayPath: Scalars["String"]["output"];
  identifier?: Maybe<Scalars["String"]["output"]>;
  maxCounter: Scalars["Int"]["output"];
  waitTimeBetweenClosure: Scalars["String"]["output"];
};

export type Ui_Component = {
  action?: Maybe<Banner_ActionData>;
  actionData?: Maybe<Banner_ActionData>;
  actionType?: Maybe<Banner_ActionType>;
  code: Scalars["String"]["output"];
  counterInformation: Ui_CounterInformation;
  createdDate: Scalars["Instant"]["output"];
  displayPaths?: Maybe<Array<Scalars["String"]["output"]>>;
  engagementResponse?: Maybe<Ui_EngagementResponse>;
  file: StorageObject;
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  segment?: Maybe<Scalars["JSON"]["output"]>;
  status: Ui_ComponentStatus;
  time?: Maybe<InstantRange>;
  type: Ui_ComponentType;
};

export type Ui_ComponentInitPayload = {
  action?: InputMaybe<Banner_ActionDataInput>;
  code: Scalars["String"]["input"];
  counterInformation?: InputMaybe<Ui_CounterInformationPayload>;
  displayPaths?: InputMaybe<Array<Scalars["String"]["input"]>>;
  fileId: Scalars["ID"]["input"];
  segment?: InputMaybe<EmploymentConditionNode>;
  status: Ui_ComponentStatus;
  time?: InputMaybe<InstantRangeInput>;
  type: Ui_ComponentType;
};

export enum Ui_ComponentOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export enum Ui_ComponentStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export enum Ui_ComponentType {
  FloatIcon = "FLOAT_ICON",
  Popup = "POPUP",
}

export type Ui_ComponentUpdatePayload = {
  action?: InputMaybe<Banner_ActionDataInput>;
  code: Scalars["String"]["input"];
  counterInformation?: InputMaybe<Ui_CounterInformationPayload>;
  displayPaths?: InputMaybe<Array<Scalars["String"]["input"]>>;
  fileId?: InputMaybe<Scalars["ID"]["input"]>;
  segment?: InputMaybe<EmploymentConditionNode>;
  status: Ui_ComponentStatus;
  time?: InputMaybe<InstantRangeInput>;
  type: Ui_ComponentType;
};

export type Ui_CounterInformation = {
  maxCounter: Scalars["Int"]["output"];
  waitTimeBetweenClosure: Scalars["String"]["output"];
};

export type Ui_CounterInformationPayload = {
  maxCounter: Scalars["Int"]["input"];
  waitTimeBetweenClosure: Scalars["String"]["input"];
};

export type Ui_EngagementResponse = {
  close: Scalars["Int"]["output"];
  press: Scalars["Int"]["output"];
};

export enum Ui_Type {
  BottomShift = "BOTTOM_SHIFT",
  HotFeature = "HOT_FEATURE",
  NewFeature = "NEW_FEATURE",
  NudgeBadge = "NUDGE_BADGE",
  PhoneReminder = "PHONE_REMINDER",
  Survey = "SURVEY",
  SurveyBanner = "SURVEY_BANNER",
  SurveyShiftMeal = "SURVEY_SHIFT_MEAL",
  Tooltip = "TOOLTIP",
}

export type UnLockEmploymentPayload = {
  reasonCode: Scalars["String"]["input"];
  reasonDetail?: InputMaybe<Scalars["String"]["input"]>;
};

export type UnLockPayload = {
  reasonCode: Scalars["String"]["input"];
  reasonDetail?: InputMaybe<Scalars["String"]["input"]>;
};

export type UnregisterNotificationTokenPayload = {
  token: Scalars["String"]["input"];
};

export type UpdateAdvertiserPayload = {
  logo_id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
};

export type UpdateBannerAssociationPayload = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  employmentFilter?: InputMaybe<BannerAssociationEmploymentFilterPayload>;
  endTime?: InputMaybe<Scalars["Instant"]["input"]>;
  startTime?: InputMaybe<Scalars["Instant"]["input"]>;
  status?: InputMaybe<BannerAssociationStatus>;
};

export type UpdateBannerPayload = {
  action?: InputMaybe<BannerActionPayload>;
  content?: InputMaybe<BannerContentPayload>;
  contentImageId?: InputMaybe<Scalars["ID"]["input"]>;
  status?: InputMaybe<BannerStatus>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateCommunicationPostPayload = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Instant"]["input"]>;
  imageId?: InputMaybe<Scalars["ID"]["input"]>;
  pdfId?: InputMaybe<Scalars["ID"]["input"]>;
  scheduledDate?: InputMaybe<Scalars["Instant"]["input"]>;
  sendToDepartmentIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sendToIndividualIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sendToType?: InputMaybe<CommunicationPostSendTo>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Instant"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<CommunicationPostType>;
};

export type UpdateCompanyPayload = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  categoryId?: InputMaybe<Scalars["ID"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  contractCompanyAddress?: InputMaybe<Scalars["String"]["input"]>;
  contractCompanyName?: InputMaybe<Scalars["String"]["input"]>;
  contractCompanyTaxCode?: InputMaybe<Scalars["String"]["input"]>;
  contractNo?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["Email"]["input"]>;
  logoId?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  negativeLogoId?: InputMaybe<Scalars["ID"]["input"]>;
  paymentDayInMonth?: InputMaybe<Scalars["Int"]["input"]>;
  paymentMonthAfterCutoff?: InputMaybe<Scalars["Int"]["input"]>;
  reportReceiverEmails?: InputMaybe<Array<Scalars["String"]["input"]>>;
  shortName?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  tocId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UpdateCronJobPayload = {
  content?: InputMaybe<Scalars["JSON"]["input"]>;
  expression: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  type: CronJobType;
};

export type UpdateGreetingCardTemplate = {
  amountColorCode?: InputMaybe<Scalars["String"]["input"]>;
  cardName?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  contentColorCode?: InputMaybe<Scalars["String"]["input"]>;
  defaultContent?: InputMaybe<Scalars["String"]["input"]>;
  errorColorCode?: InputMaybe<Scalars["String"]["input"]>;
  imageId?: InputMaybe<Scalars["ID"]["input"]>;
  validFrom?: InputMaybe<Scalars["Instant"]["input"]>;
  validTo?: InputMaybe<Scalars["Instant"]["input"]>;
};

export type UpdateGroupAttendanceFileProcessorPayload = {
  qualifier?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateGroupAttendanceFileProcessorResponse = {
  group: CompanyEmploymentGroup;
};

export type UpdateGroupEmploymentFileProcessorPayload = {
  qualifier?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateGroupEmploymentFileProcessorResponse = {
  group: CompanyEmploymentGroup;
};

export type UpdateGroupPayslipCalculatorPayload = {
  qualifier?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateGroupPayslipCalculatorResponse = {
  group: CompanyEmploymentGroup;
};

export type UpdateNotificationTokenPayload = {
  deviceId: Scalars["String"]["input"];
  notificationToken: Scalars["String"]["input"];
};

export type UpdatePasswordPayload = {
  oldPassword: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type UpdatePayload = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  contract_code?: InputMaybe<Scalars["String"]["input"]>;
  contract_name?: InputMaybe<Scalars["String"]["input"]>;
  logo_id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  payloadFee: PayloadFee;
  phone_number?: InputMaybe<Scalars["String"]["input"]>;
  primary_color?: InputMaybe<Scalars["String"]["input"]>;
  secondary_color?: InputMaybe<Scalars["String"]["input"]>;
  tax_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdatePayslipPayload = {
  endDate: Scalars["LocalDate"]["input"];
  name: Scalars["String"]["input"];
  publishDate: Scalars["Instant"]["input"];
  startDate: Scalars["LocalDate"]["input"];
  storageId: Scalars["ID"]["input"];
};

export type UpdateProfileKycPayload = {
  backCardId?: InputMaybe<Scalars["ID"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bankName?: InputMaybe<Scalars["String"]["input"]>;
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  employeeId?: InputMaybe<Scalars["String"]["input"]>;
  frontCardId?: InputMaybe<Scalars["ID"]["input"]>;
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  idNumber?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  socialIdIssuedDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  socialIdIssuedPlace?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ProfileStatus>;
  taxCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateProfileKycPayloadAdmin = {
  backCardId?: InputMaybe<Scalars["ID"]["input"]>;
  contacts?: InputMaybe<Array<InputMaybe<Contact>>>;
  frontCardId?: InputMaybe<Scalars["ID"]["input"]>;
  portraitIDs?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type UpdateProgressJobPayload = {
  failedPayload?: InputMaybe<Scalars["JSON"]["input"]>;
  failedRowCount?: InputMaybe<Scalars["Int"]["input"]>;
  rowCount?: InputMaybe<Scalars["Int"]["input"]>;
  successRowCount?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UpdateRolePayload = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  permissions?: InputMaybe<Array<TenantPermission>>;
};

export type UpdateSegmentPayload = {
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  effectiveEndDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  effectiveStartDate: Scalars["LocalDate"]["input"];
  employmentSegment?: InputMaybe<EmploymentSegment>;
  name: Scalars["String"]["input"];
  status?: InputMaybe<SegmentStatus>;
};

export type UpdateSurveyForm = {
  bannerId?: InputMaybe<Scalars["ID"]["input"]>;
  editors?: InputMaybe<Array<Scalars["String"]["input"]>>;
  employeeList?: InputMaybe<Array<UploadEmployeeData>>;
  formName?: InputMaybe<Scalars["String"]["input"]>;
  fromDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  fromInstant?: InputMaybe<Scalars["Instant"]["input"]>;
  informDate?: InputMaybe<Scalars["Instant"]["input"]>;
  prefillDataList?: InputMaybe<Array<SurveyFormPrefillData>>;
  toDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  toInstant?: InputMaybe<Scalars["Instant"]["input"]>;
};

export type UpdateTenantUserPayload = {
  email?: InputMaybe<Scalars["Email"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<TenantUserStatus>;
};

export type UpdateUptimeMonitorPayload = {
  custom_http_headers?: InputMaybe<Scalars["JSON"]["input"]>;
  custom_http_statuses?: InputMaybe<Scalars["String"]["input"]>;
  httpMethod?: InputMaybe<HttpMethodType>;
  http_auth_type?: InputMaybe<HttpAuthType>;
  http_password?: InputMaybe<Scalars["String"]["input"]>;
  http_username?: InputMaybe<Scalars["String"]["input"]>;
  interval?: InputMaybe<Scalars["Int"]["input"]>;
  monitor?: InputMaybe<Array<InputMaybe<MonitorType>>>;
  name: Scalars["String"]["input"];
  post_value?: InputMaybe<Scalars["JSON"]["input"]>;
  timeout?: InputMaybe<Scalars["Int"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateUserPayload = {
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<UpdatePasswordPayload>;
};

export type UpdateVendorPasswordPayload = {
  oldPassword: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type UpdateVendorThemePayload = {
  primary_color?: InputMaybe<Scalars["String"]["input"]>;
  secondary_color?: InputMaybe<Scalars["String"]["input"]>;
};

export type UploadEmployeeData = {
  department?: InputMaybe<Scalars["String"]["input"]>;
  employeeFullName: Scalars["String"]["input"];
  employeeId: Scalars["String"]["input"];
};

export type UploadPayslipPayload = {
  endDate: Scalars["LocalDate"]["input"];
  name: Scalars["String"]["input"];
  publishDate: Scalars["Instant"]["input"];
  startDate: Scalars["LocalDate"]["input"];
  storageId: Scalars["ID"]["input"];
};

export type UploadRevisedFilePayload = {
  revisedFileIds: Array<Scalars["ID"]["input"]>;
};

export type UptimeFilter = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  status?: InputMaybe<UptimeStatus>;
  type?: InputMaybe<UptimeMonitorType>;
};

export enum UptimeMonitorType {
  Http = "HTTP",
  Heartbeat = "Heartbeat",
}

export enum UptimeOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export type UptimeQuery = {
  filter?: InputMaybe<UptimeFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<UptimeOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export enum UptimeStatus {
  Down = "Down",
  Notchecked = "Notchecked",
  Paused = "Paused",
  Up = "Up",
}

export type User = {
  /**
   * firstName: String
   * lastName: String
   * status: UserStatus!
   */
  accountId: Scalars["String"]["output"];
  b2c_collectedForm?: Maybe<B2c_CollectedForm>;
  b2c_quests?: Maybe<Array<B2c_Quest>>;
  b2c_verifyFundUsable: Scalars["Boolean"]["output"];
  currentEmployment?: Maybe<Employment>;
  devices: Array<UserDevice>;
  downloadFlag?: Maybe<Scalars["Boolean"]["output"]>;
  employment: Employment;
  employments: EmploymentConnection;
  /** socialId: SocialId */
  fullName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  message: Message;
  messages: MessageConnection;
  pendingDeletionRequest?: Maybe<User_DeletionRequest>;
  /**
   * createdDate: Instant!
   * lastModifiedDate: Instant!
   *
   * phone: PhoneNumber!
   */
  phoneNumber: Scalars["PhoneNumber"]["output"];
  rating?: Maybe<Rating>;
  referralCode?: Maybe<Scalars["String"]["output"]>;
  referralStatus?: Maybe<UserReferralStatus>;
  referralUsername?: Maybe<Scalars["String"]["output"]>;
  requestLink: RequestLink;
  requestLinks: RequestLinkConnection;
  requiredPassword: Scalars["Boolean"]["output"];
  user_isConfirmedSharingInformation: Scalars["Boolean"]["output"];
  waitList?: Maybe<Array<WaitList>>;
  waitlist_user: Waitlist_User;
  waitlist_users: Waitlist_UserConnection;
};

export type UserEmploymentArgs = {
  id: Scalars["ID"]["input"];
};

export type UserEmploymentsArgs = {
  query?: InputMaybe<EmploymentQuery>;
};

export type UserMessageArgs = {
  id: Scalars["ID"]["input"];
};

export type UserMessagesArgs = {
  query?: MessageQuery;
};

export type UserRequestLinkArgs = {
  id: Scalars["ID"]["input"];
};

export type UserRequestLinksArgs = {
  query?: InputMaybe<RequestLinkQuery>;
};

export type UserWaitlist_UserArgs = {
  id: Scalars["ID"]["input"];
};

export type UserWaitlist_UsersArgs = {
  query?: Waitlist_UserQuery;
};

export type UserAcl = {
  id?: Maybe<Scalars["ID"]["output"]>;
  roles?: Maybe<Array<Maybe<RoleEntity>>>;
};

export type UserChangePhoneNumberRequest = {
  createdDate: Scalars["Instant"]["output"];
  expiredAt: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  phoneNumber: Scalars["PhoneNumber"]["output"];
  status: User_ChangePhoneNumberStatus;
};

export type UserConnection = {
  nodes: Array<User>;
  total: Scalars["Int"]["output"];
};

export type UserDevice = {
  blacklisted: Scalars["Boolean"]["output"];
  currentDevice: Scalars["Boolean"]["output"];
  deviceId: Scalars["String"]["output"];
  deviceName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastLoginTime: Scalars["Instant"]["output"];
  os?: Maybe<Scalars["String"]["output"]>;
  whitelisted: Scalars["Boolean"]["output"];
};

export type UserIdFilter = {
  equalTo?: InputMaybe<Scalars["ID"]["input"]>;
  in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  notEqualTo?: InputMaybe<Scalars["ID"]["input"]>;
  notIn?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type UserInfoForSurveyResponse = {
  isUseEWA?: Maybe<Scalars["Boolean"]["output"]>;
  limitSalary?: Maybe<Scalars["Money"]["output"]>;
};

export type UserLast3MonthHistory = {
  months: Array<Scalars["Int"]["output"]>;
  transactionCount: Array<Scalars["Int"]["output"]>;
  workingDayPercentage: Array<Maybe<Scalars["Float"]["output"]>>;
};

export type UserLinkEmploymentPayload = {
  bankAccount?: InputMaybe<Scalars["String"]["input"]>;
  bankId?: InputMaybe<Scalars["ID"]["input"]>;
  companyId: Scalars["ID"]["input"];
  employeeId?: InputMaybe<Scalars["String"]["input"]>;
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  invitorPhone?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
};

export enum UserOrderable {
  FullName = "FullName",
  Id = "Id",
  PhoneNumber = "PhoneNumber",
}

export type UserPayBusinessAdminQuery = {
  filter?: InputMaybe<UserPayBusinessAdminQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<UserPayBusinessOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type UserPayBusinessAdminQueryFilter = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UserPayBusinessAdminResponse = {
  id?: Maybe<Scalars["ID"]["output"]>;
  payDate?: Maybe<Scalars["LocalDate"]["output"]>;
  status?: Maybe<UserPayBusinessStatus>;
  totalAmount?: Maybe<Scalars["Money"]["output"]>;
  totalTransaction?: Maybe<Scalars["Int"]["output"]>;
};

export enum UserPayBusinessDetailOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export type UserPayBusinessDetailQuery = {
  filter?: InputMaybe<UserPayBusinessDetailQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<UserPayBusinessDetailOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type UserPayBusinessDetailQueryFilter = {
  userPayBusinessId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UserPayBusinessDetailResponse = {
  employeeId?: Maybe<Scalars["String"]["output"]>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  transactionCode?: Maybe<Scalars["String"]["output"]>;
  userPayAmount?: Maybe<Scalars["Money"]["output"]>;
};

export enum UserPayBusinessOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export enum UserPayBusinessStatus {
  Approve = "Approve",
  Pending = "Pending",
  Reject = "Reject",
}

export enum UserPayHistoryOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export enum UserPayableStatus {
  Closed = "Closed",
  Created = "Created",
  Done = "Done",
  Late = "Late",
  WaitControl = "WaitControl",
}

export enum UserPayableType {
  DailyInterest = "DailyInterest",
  EarlyFee = "EarlyFee",
  Fee = "Fee",
  FeeB2C = "FeeB2C",
  Principal = "Principal",
}

export type UserPaymentProgressResponse = {
  endDate?: Maybe<Scalars["LocalDate"]["output"]>;
  isEndInstallmentLoanPeriod?: Maybe<Scalars["Boolean"]["output"]>;
  lateFee?: Maybe<Scalars["Money"]["output"]>;
  nonPerformingAmount?: Maybe<Scalars["Money"]["output"]>;
  payableAmount?: Maybe<Scalars["Money"]["output"]>;
  paymentAmount?: Maybe<Scalars["Money"]["output"]>;
  startDate?: Maybe<Scalars["LocalDate"]["output"]>;
  totalAmount?: Maybe<Scalars["Money"]["output"]>;
  totalDayLate?: Maybe<Scalars["Int"]["output"]>;
  workPeriodName?: Maybe<Scalars["String"]["output"]>;
};

export type UserQuery = {
  filter?: InputMaybe<UserQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<UserOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type UserQueryFilter = {
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export enum UserReferralStatus {
  Done = "Done",
  Waiting = "Waiting",
}

export enum UserStepStatus {
  Pending = "Pending",
  Submit = "Submit",
  Verified = "Verified",
}

export type UserSubscriptionPackage = {
  id: Scalars["ID"]["output"];
  paymentStatus: Subscription_UserSubscriptionPaymentStatus;
  status: Subscription_UserSubscriptionStatus;
  workPeriod?: Maybe<EmploymentWorkPeriod>;
};

export type ValidateVoucherPayload = {
  amount: Scalars["Money"]["input"];
  employmentId: Scalars["ID"]["input"];
  fee?: InputMaybe<Scalars["Money"]["input"]>;
  type: LoanApplicationType;
};

export type ValidationEntity = {
  employeeId: Scalars["String"]["output"];
  employment?: Maybe<Employment>;
  fullName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  workPlace: Scalars["String"]["output"];
};

export type ValidationEntityConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<ValidationEntity>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export enum ValidationEntityOrderable {
  EmployeeId = "EmployeeId",
  EmploymentCreatedDate = "EmploymentCreatedDate",
  EmploymentLastModifiedDate = "EmploymentLastModifiedDate",
  WhitelistWorkplace = "WhitelistWorkplace",
}

export type ValidationEntityQuery = {
  all?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ValidationEntityQueryFilter>;
  filters?: InputMaybe<Array<ValidationEntityQueryFilter>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<ValidationEntityOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type ValidationEntityQueryFilter = {
  employeeIdSearch?: InputMaybe<Scalars["String"]["input"]>;
  employmentFilter?: InputMaybe<EmploymentQueryFilter>;
  fullNameSearch?: InputMaybe<Scalars["String"]["input"]>;
  whiteListWorkplace?: InputMaybe<Scalars["String"]["input"]>;
};

export type VendorCompany = {
  company?: Maybe<Company>;
  tenantId?: Maybe<Scalars["ID"]["output"]>;
};

export type VendorCompanyDashboardResponse = {
  company?: Maybe<Company>;
  totalUserActice?: Maybe<Scalars["Int"]["output"]>;
};

export type VendorCompanyFilter = {
  endDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  keySearch?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  status?: InputMaybe<CompanyStatus>;
  tenantId?: InputMaybe<Scalars["ID"]["input"]>;
  vendorId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type VendorCompanyQuery = {
  filter?: InputMaybe<VendorCompanyFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderByCompany?: InputMaybe<CompanyOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type VendorDetail = {
  address?: Maybe<Scalars["String"]["output"]>;
  code?: Maybe<Scalars["String"]["output"]>;
  contract_code?: Maybe<Scalars["String"]["output"]>;
  contract_name?: Maybe<Scalars["String"]["output"]>;
  darkColor?: Maybe<Scalars["String"]["output"]>;
  fee?: Maybe<VendorFee>;
  id: Scalars["ID"]["output"];
  logo?: Maybe<StorageObject>;
  name?: Maybe<Scalars["String"]["output"]>;
  phone_number?: Maybe<Scalars["String"]["output"]>;
  primary_color?: Maybe<Scalars["String"]["output"]>;
  secondary_color?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<VendorStatus>;
  tax_id?: Maybe<Scalars["String"]["output"]>;
  totalTenant?: Maybe<Scalars["Int"]["output"]>;
};

export type VendorFee = {
  applied_date?: Maybe<Scalars["LocalDate"]["output"]>;
  commission_percent?: Maybe<Scalars["Int"]["output"]>;
  due_date?: Maybe<Scalars["LocalDate"]["output"]>;
  id: Scalars["ID"]["output"];
  maintenance_fees?: Maybe<Scalars["Money"]["output"]>;
  payment_date?: Maybe<Scalars["Int"]["output"]>;
  share_promotion?: Maybe<Scalars["Boolean"]["output"]>;
  status?: Maybe<VendorFeeStatus>;
};

export enum VendorFeeStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type VendorFilter = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<VendorStatus>;
};

export type VendorInfoReponse = {
  address?: Maybe<Scalars["String"]["output"]>;
  code?: Maybe<Scalars["String"]["output"]>;
  contract_code?: Maybe<Scalars["String"]["output"]>;
  contract_name?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  logo?: Maybe<StorageObject>;
  name?: Maybe<Scalars["String"]["output"]>;
  phone_number?: Maybe<Scalars["String"]["output"]>;
  primary_color?: Maybe<Scalars["String"]["output"]>;
  secondary_color?: Maybe<Scalars["ID"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  tax_id?: Maybe<Scalars["String"]["output"]>;
};

export type VendorLoan = {
  actuallyReceived?: Maybe<Scalars["Money"]["output"]>;
  amount?: Maybe<Scalars["Money"]["output"]>;
  code?: Maybe<Scalars["String"]["output"]>;
  commission_fee?: Maybe<Scalars["Money"]["output"]>;
  createdDate?: Maybe<Scalars["Instant"]["output"]>;
  disbursement_code?: Maybe<Scalars["String"]["output"]>;
  employerFee?: Maybe<Scalars["Money"]["output"]>;
  employmentCode?: Maybe<Scalars["String"]["output"]>;
  employmentName?: Maybe<Scalars["String"]["output"]>;
  fee?: Maybe<Scalars["Money"]["output"]>;
  loanApplicationId?: Maybe<Scalars["ID"]["output"]>;
  status?: Maybe<LoanApplicationStatus>;
  tenant?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<LoanApplicationType>;
  vendorId?: Maybe<Scalars["ID"]["output"]>;
};

export type VendorLoanDashboardPayLoad = {
  endDate: Scalars["LocalDate"]["input"];
  startDate: Scalars["LocalDate"]["input"];
  vendorId: Scalars["ID"]["input"];
};

export type VendorLoanDashboardResponse = {
  date?: Maybe<Scalars["LocalDate"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type VendorLoanFilterFe = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  disbursement_code?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  keySearch?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  tenant?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  vendorId?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum VendorLoanOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export type VendorLoanQuery = {
  filter?: InputMaybe<VendorLoanFilterFe>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<VendorLoanOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type VendorLoanResponse = {
  total?: Maybe<Scalars["Int"]["output"]>;
  totalAmount?: Maybe<Scalars["Money"]["output"]>;
  totalComission?: Maybe<Scalars["Money"]["output"]>;
  vendorLoans?: Maybe<Array<Maybe<VendorLoan>>>;
};

export type VendorPayload = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  contract_code?: InputMaybe<Scalars["String"]["input"]>;
  contract_name?: InputMaybe<Scalars["String"]["input"]>;
  logo_id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  payloadFee: PayloadFee;
  phone_number?: InputMaybe<Scalars["String"]["input"]>;
  primary_color?: InputMaybe<Scalars["String"]["input"]>;
  secondary_color?: InputMaybe<Scalars["String"]["input"]>;
  tax_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type VendorQuery = {
  filter?: InputMaybe<VendorFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VendorReponse = {
  limit?: Maybe<Scalars["Int"]["output"]>;
  nodes: Array<VendorDetail>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export enum VendorStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type VendorUsageRateDashboardResponse = {
  totalUserActice?: Maybe<Scalars["Int"]["output"]>;
  totalUserUsage?: Maybe<Scalars["Int"]["output"]>;
};

export type VendorUserFilter = {
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export enum VendorUserOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export type VendorUserQuery = {
  filter?: InputMaybe<VendorUserFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<VendorUserOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

/**
 * extend type Query {
 *     checkFlowChangePassword(phoneNumber: PhoneNumber!): CheckFlowChangePasswordResponse!
 * }
 */
export type VerifiyChangePasswordRequestPayload = {
  otp: Scalars["String"]["input"];
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
  requestId: Scalars["String"]["input"];
};

export type VerifiyUserRegistrationPayload = {
  otp: Scalars["String"]["input"];
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
  requestId: Scalars["String"]["input"];
};

export type VerifyChangePasswordRequestResponse = {
  token: Scalars["String"]["output"];
};

export type VerifyDevicePayload = {
  deviceId: Scalars["String"]["input"];
  phoneNumber: Scalars["PhoneNumber"]["input"];
};

export type VerifyDeviceResponse = {
  isNewDevice: Scalars["Boolean"]["output"];
  success: Scalars["Boolean"]["output"];
  warningPopup?: Maybe<VerifyDeviceWarningPopup>;
};

export type VerifyDeviceWarningPopup = {
  acceptButton: Scalars["String"]["output"];
  content: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type VerifyPasswordPayload = {
  password: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  scope?: InputMaybe<Scalars["String"]["input"]>;
};

export type VerifyUserRegistrationResponse = {
  token?: Maybe<Scalars["String"]["output"]>;
};

export type Viewer = {
  accessControl?: Maybe<AccessControl_AccessControl>;
  company?: Maybe<Company>;
  core_tenant: Core_Tenant;
  tenantPermissions: Array<TenantPermission>;
  user?: Maybe<User>;
};

export type VirtualAccountResponse = {
  bankAccount?: Maybe<Scalars["String"]["output"]>;
  bankName?: Maybe<Scalars["String"]["output"]>;
};

/**
 * extend type Mutation {
 *    testAppScript:Void
 * }
 */
export type Voucher = {
  banner?: Maybe<StorageObject>;
  code: Scalars["String"]["output"];
  createdDate: Scalars["Instant"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  displayDescription?: Maybe<Scalars["String"]["output"]>;
  displayInfo?: Maybe<Scalars["String"]["output"]>;
  fixedDiscount?: Maybe<Scalars["Float"]["output"]>;
  fromDate: Scalars["Instant"]["output"];
  icon?: Maybe<StorageObject>;
  id: Scalars["ID"]["output"];
  isOutOfStock: Scalars["Boolean"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  maxAmount: Scalars["Float"]["output"];
  maxUsageByEmployment?: Maybe<Scalars["Int"]["output"]>;
  minAmount: Scalars["Float"]["output"];
  minApplicableAmount?: Maybe<Scalars["Money"]["output"]>;
  preferenceRoute?: Maybe<Scalars["String"]["output"]>;
  remain?: Maybe<Scalars["Int"]["output"]>;
  toDate: Scalars["Instant"]["output"];
  total?: Maybe<Scalars["Int"]["output"]>;
  type: Scalars["String"]["output"];
  unlimitedDiscountAmount: Scalars["Boolean"]["output"];
  unlimitedDiscountTime: Scalars["Boolean"]["output"];
  usedQuantity: Scalars["Int"]["output"];
};

/**
 * extend type Mutation {
 *    testAppScript:Void
 * }
 */
export type VoucherDisplayDescriptionArgs = {
  type?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * extend type Mutation {
 *    testAppScript:Void
 * }
 */
export type VoucherIsOutOfStockArgs = {
  employmentId?: InputMaybe<Scalars["ID"]["input"]>;
};

/**
 * extend type Mutation {
 *    testAppScript:Void
 * }
 */
export type VoucherUsedQuantityArgs = {
  employmentId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type VoucherFilter = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  types?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export enum VoucherOrderable {
  Id = "Id",
}

export type VoucherQuery = {
  filter?: InputMaybe<VoucherFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<VoucherOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type WVoucherTransferDetail = {
  amount?: Maybe<Scalars["Money"]["output"]>;
  voucher?: Maybe<Voucher>;
};

export type WaitList = {
  company: Company;
  createdDate: Scalars["Instant"]["output"];
  employeeCode: Scalars["String"]["output"];
  fullName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
};

export type WaitListReponse = {
  limit?: Maybe<Scalars["Int"]["output"]>;
  nodes: Array<Waitlist>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type Waitlist = {
  code?: Maybe<Scalars["String"]["output"]>;
  createdDate?: Maybe<Scalars["Instant"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<WaitlistStatus>;
  taxCode?: Maybe<Scalars["String"]["output"]>;
  totalRegister?: Maybe<Scalars["Int"]["output"]>;
  vendor?: Maybe<VendorDetail>;
};

export enum WaitlistOrderable {
  CreatedDate = "createdDate",
  Id = "id",
  LastModifiedDate = "lastModifiedDate",
}

export type WaitlistPayload = {
  code: Scalars["String"]["input"];
  employeeCode: Scalars["String"]["input"];
  employeeName: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber: Scalars["String"]["input"];
  taxCode?: InputMaybe<Scalars["String"]["input"]>;
  vendorCode: Scalars["String"]["input"];
};

export type WaitlistQuery = {
  filter?: InputMaybe<WaitlistQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<WaitlistOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type WaitlistQueryFilter = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<WaitlistStatus>;
  vendorId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type WaitlistRegister = {
  createdDate?: Maybe<Scalars["Instant"]["output"]>;
  employeeCode?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
};

export type WaitlistRegisterQuery = {
  filter?: InputMaybe<WaitlistRegisterQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<WaitlistRegisterQueryOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type WaitlistRegisterQueryFilter = {
  employeeCode?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  waitlistId?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum WaitlistRegisterQueryOrderable {
  CreatedDate = "createdDate",
  Id = "id",
  LastModifiedDate = "lastModifiedDate",
}

export type WaitlistRegisterQueryReponse = {
  limit?: Maybe<Scalars["Int"]["output"]>;
  nodes: Array<WaitlistRegister>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export enum WaitlistStatus {
  Active = "Active",
  Cancelled = "Cancelled",
  Waiting = "Waiting",
}

export type WarningEwaTransactionResponse = {
  amount?: Maybe<Scalars["Money"]["output"]>;
  content?: Maybe<Scalars["String"]["output"]>;
  isWarning: Scalars["Boolean"]["output"];
};

export type WithdrawCalculationResponse = {
  actualReceiveAmount: Scalars["Money"]["output"];
  amount: Scalars["Money"]["output"];
  fee: Scalars["Money"]["output"];
};

export type WithdrawableBalanceExplanation = {
  code: Scalars["String"]["output"];
  metadata?: Maybe<Scalars["JSON"]["output"]>;
  type: Scalars["String"]["output"];
};

export type WithdrawableBalanceExplanation_Change = {
  changeMode: ChangeMode;
  different: Scalars["Money"]["output"];
  newBalance: Scalars["Money"]["output"];
  oldBalance: Scalars["Money"]["output"];
};

export type WithdrawableBalanceResponse = {
  balance: Scalars["Money"]["output"];
  balanceModifications: Array<BalanceModification>;
};

export type WorkPeriodResponse = {
  amount?: Maybe<Scalars["Money"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
};

export enum WorkerType {
  Contract = "Contract",
  FullTime = "FullTime",
  Internship = "Internship",
  PartTime = "PartTime",
  Seasonal = "Seasonal",
  Trainee = "Trainee",
}

export enum WorkingDayType {
  Weekday = "Weekday",
  Weekend = "Weekend",
}

export type Workplace = {
  attendanceWarning: Array<AttendanceWarning>;
  code: Scalars["String"]["output"];
  employments: EmploymentConnection;
  id: Scalars["ID"]["output"];
  loanApplications: LoanApplicationConnection;
  name: Scalars["String"]["output"];
};

export type WorkplaceAttendanceWarningArgs = {
  filter?: InputMaybe<AttendanceWarningQueryFilter>;
};

export type WorkplaceEmploymentsArgs = {
  query?: InputMaybe<EmploymentQuery>;
};

export type WorkplaceLoanApplicationsArgs = {
  query?: InputMaybe<LoanApplicationQuery>;
};

export type WorkplaceConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Workplace>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type WorkplaceFilter = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  nameSearch?: InputMaybe<Scalars["String"]["input"]>;
};

export enum WorkplaceOrderable {
  Id = "id",
  Index = "index",
  WorkPlaceCode = "workPlaceCode",
  WorkPlaceName = "workPlaceName",
}

export type WorkplaceQuery = {
  filter?: InputMaybe<WorkplaceFilter>;
  filters?: InputMaybe<Array<WorkplaceFilter>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<WorkplaceOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type YearInReview2021DataRow = {
  advanceTime: Scalars["Int"]["output"];
  advanceValue: Scalars["Money"]["output"];
  attendanceNumber: Scalars["Int"]["output"];
  finedNumber: Scalars["Int"]["output"];
  linkedDate: Scalars["LocalDate"]["output"];
  maxAdvanceMonth: Scalars["Int"]["output"];
  maxAdvanceValueInMonth: Scalars["Money"]["output"];
  shareNumber: Scalars["Int"]["output"];
  shiftCountUnit: Scalars["String"]["output"];
  tenantCode: Scalars["String"]["output"];
  top10PercentAdvanceAndAdvanceTimeGte5: Scalars["Boolean"]["output"];
  top10PercentAdvanceAndAdvanceValueGte5000000: Scalars["Boolean"]["output"];
  top10PercentAdvanceSum: Scalars["Boolean"]["output"];
  top10PercentAttendanceAndClickGte5: Scalars["Boolean"]["output"];
  top10PercentFined: Scalars["Boolean"]["output"];
  top10PercentInvite: Scalars["Boolean"]["output"];
  topTenPercentShiftCount: Scalars["Boolean"]["output"];
  totalShiftCount: Scalars["Decimal"]["output"];
};

export type YearInReview2021DataRowResponse = {
  data?: Maybe<YearInReview2021DataRow>;
  stat?: Maybe<YearInReview2021Stat>;
};

export type YearInReview2021Stat = {
  autoPoppedUp: Scalars["Boolean"]["output"];
  shareImages?: Maybe<Array<StorageObject>>;
};

export type ZnsMessage = {
  executionId?: InputMaybe<Scalars["String"]["input"]>;
  from: Scalars["String"]["input"];
  templateData?: InputMaybe<Scalars["JSON"]["input"]>;
  templateId?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  to: Scalars["PhoneNumber"]["input"];
};

export type _Service = {
  sdl: Scalars["String"]["output"];
};

export type AccessControl_AccessControl = {
  tenant: AccessControl_TenantAccessControl;
};

export type AccessControl_AssignTenantRolePayload = {
  accountId: Scalars["String"]["input"];
  companyIds: Array<Scalars["String"]["input"]>;
  departmentIds: Array<Scalars["String"]["input"]>;
  roleIds: Array<Scalars["ID"]["input"]>;
};

export type AccessControl_ResourceFilter = {
  companyIds: Array<Scalars["String"]["output"]>;
  departmentIds: Array<Scalars["String"]["output"]>;
};

/**
 * type rbac_ExchangeTokenResponse {
 *     accessToken: String
 * }
 * extend type Query {
 *    exchangeTenantToken: rbac_ExchangeTokenResponse
 * }
 */
export type AccessControl_TenantAccessControl = {
  permissions: Array<Scalars["String"]["output"]>;
  realm: Scalars["String"]["output"];
  resourceFilter: AccessControl_ResourceFilter;
  roleCodes?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type AccessControl_UserAccessControl = {
  companyIds: Array<Scalars["String"]["output"]>;
  departmentIds: Array<Scalars["String"]["output"]>;
  roleCodes: Array<Scalars["String"]["output"]>;
  roleIds: Array<Scalars["ID"]["output"]>;
};

export type Activation202206_UpdateCampaignConfigPayload = {
  rewarderCode: Scalars["String"]["input"];
  rewarderParam?: InputMaybe<Scalars["JSON"]["input"]>;
};

/**
 * enum org_SourceTxnStatus {
 *     Pending
 *     Approved
 *     LockForDisbursement
 *     Closed
 *     Disbursed
 * }
 *
 * enum org_SourceTxnOrderType {
 *     Advance
 *     TopUp
 *     Saving
 *     Card
 *     Billing
 * }
 *
 * type org_SourceTxn {
 *     id: ID!
 *     createdDate: Instant!
 *     lastModifiedDate: Instant!
 *     status: org_SourceTxnStatus!
 *     amount: Money!
 *     orderCode: String!
 *     orderedDate: Instant!
 *     orderType: org_SourceTxnOrderType!
 *     employee: Employment!
 * }
 *
 * input org_SourceTxnFilter {
 *     statuses: [org_SourceTxnStatus!]
 *     orderTypes: [org_SourceTxnOrderType!]
 *     codes: [String!]
 *     companyIds: [ID!]
 *     workPeriodIds: [ID!]
 *     withReconcile: Boolean
 *     codeSearch: String
 * }
 *
 * enum org_SourceTxnOrderable {
 *     Id
 *     CreatedDate
 *     LastModifiedDate
 * }
 *
 * input org_SourceTxnQuery {
 *     limit: Int
 *     offset: Int
 *     orderBy: org_SourceTxnOrderable,
 *     orderDirection: OrderDirection,
 *     filter: org_SourceTxnFilter
 * }
 *
 * type org_SourceTxnConnection {
 *     nodes: [org_SourceTxn!]!
 *     total: Int!
 *     limit: Int!
 *     offset: Int!
 *     sumAmounts: [Money!]
 * }
 *
 * extend type Query {
 *     org_SourceTransactions(query: org_SourceTxnQuery): org_SourceTxnConnection!
 *     org_SourceTransaction(id: ID!): org_SourceTxn
 * }
 *
 * extend type Mutation {
 *     admin_SyncAccountingTxn: Void
 * }
 */
export type Admin_AccountingTxn = {
  disbursementCode: Scalars["String"]["output"];
  externalRefCode: Scalars["String"]["output"];
  loan: LoanApplication;
  loanCode: Scalars["String"]["output"];
};

export type Admin_CashbackConfig = {
  cashbackWithdrawalLimit?: Maybe<Scalars["Int"]["output"]>;
  directWithdrawal: Scalars["Boolean"]["output"];
  enable: Scalars["Boolean"]["output"];
  enableBilling: Scalars["Boolean"]["output"];
};

/** Invoice Contract */
export type Admin_Contract = {
  contractCode: Scalars["String"]["output"];
  contractFile?: Maybe<StorageObject>;
  contractType: Admin_ContractType;
  createdBy: Scalars["String"]["output"];
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  invoice?: Maybe<Admin_Invoice>;
  invoiceId: Scalars["ID"]["output"];
  requestStatus?: Maybe<Admin_ContractRequestStatus>;
  revisedFile?: Maybe<Admin_InvoiceRevisedFile>;
  status: Admin_ContractStatus;
};

export type Admin_ContractConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Admin_Contract>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Admin_ContractFilter = {
  companyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  contractStatuses?: InputMaybe<Array<Admin_ContractStatus>>;
  createdDateRange?: InputMaybe<LocalDateRange>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  invoiceIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  tenantIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export enum Admin_ContractOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export type Admin_ContractQuery = {
  filter?: InputMaybe<Admin_ContractFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Admin_ContractOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export enum Admin_ContractRequestStatus {
  Failure = "FAILURE",
  Finish = "FINISH",
  Pending = "PENDING",
}

export enum Admin_ContractStatus {
  Cancel = "CANCEL",
  Draft = "DRAFT",
  Failure = "FAILURE",
  Finished = "FINISHED",
  Processing = "PROCESSING",
  Rejected = "REJECTED",
}

export enum Admin_ContractType {
  Invoice = "Invoice",
}

export enum Admin_ConvertStatus {
  Done = "Done",
  Failed = "Failed",
  Processing = "Processing",
}

export type Admin_CreateInvoiceDetailPayload = {
  amount: Scalars["Money"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  mandatory: Scalars["Boolean"]["input"];
  name: Admin_InvoiceDetailName;
  type: Admin_InvoiceDetailType;
};

export type Admin_CreateInvoicePayload = {
  companyId: Scalars["ID"]["input"];
  externalRef?: InputMaybe<Scalars["String"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  periodId: Scalars["ID"]["input"];
};

export type Admin_CreateInvoicePaymentPayload = {
  amount: Scalars["Money"]["input"];
  externalRef?: InputMaybe<Scalars["String"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  receiptFileId?: InputMaybe<Scalars["ID"]["input"]>;
  txnDate: Scalars["Instant"]["input"];
};

export type Admin_CreateRequestRecEntryPayload = {
  employmentId: Scalars["ID"]["input"];
  reconcileTxnIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type Admin_DoneRequestRecEntryPayload = {
  externalCode: Scalars["String"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
};

export type Admin_ExportMisaPayload = {
  fromDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  toDate?: InputMaybe<Scalars["LocalDate"]["input"]>;
};

export type Admin_Invoice = {
  activities: Admin_InvoiceActivityConnection;
  actualAmount?: Maybe<Scalars["Money"]["output"]>;
  actualPaymentDate?: Maybe<Scalars["LocalDate"]["output"]>;
  adjustments: Admin_InvoiceAdjustmentConnection;
  amount?: Maybe<Scalars["Money"]["output"]>;
  amountToBeRecovered?: Maybe<Scalars["Money"]["output"]>;
  company: Company;
  companyAddress?: Maybe<Scalars["String"]["output"]>;
  companyCode: Scalars["String"]["output"];
  companyContract?: Maybe<Scalars["String"]["output"]>;
  companyName: Scalars["String"]["output"];
  companyTaxCode?: Maybe<Scalars["String"]["output"]>;
  contractCreated: Scalars["Boolean"]["output"];
  createdDate: Scalars["Instant"]["output"];
  details: Admin_InvoiceDetailConnection;
  dueDate?: Maybe<Scalars["LocalDate"]["output"]>;
  endDate: Scalars["Instant"]["output"];
  externalRef?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  /** employeeInvoices(query: admin_InvoiceEntryQuery): admin_InvoiceEmploymentConnection! */
  invoiceEmployment?: Maybe<Admin_InvoiceEmployment>;
  invoiceEntries?: Maybe<Admin_InvoiceEntryConnection>;
  invoiceEntry?: Maybe<Admin_InvoiceEntry>;
  issuedDate?: Maybe<Scalars["LocalDate"]["output"]>;
  lastModifiedDate: Scalars["Instant"]["output"];
  note?: Maybe<Scalars["String"]["output"]>;
  payments: Admin_InvoicePaymentConnection;
  receiverMails?: Maybe<Admin_InvoiceEmail>;
  rescheduledEntries?: Maybe<Admin_InvoiceEntryConnection>;
  revisedFiles: Admin_InvoiceRevisedFileCollection;
  startDate: Scalars["Instant"]["output"];
  status: Admin_InvoiceStatus;
  tenant: Core_Tenant;
  tenantCode: Scalars["String"]["output"];
  totalDetail?: Maybe<Scalars["Money"]["output"]>;
  totalPayment?: Maybe<Scalars["Money"]["output"]>;
  workPeriod: InvoiceWorkPeriod;
};

export type Admin_InvoiceActivitiesArgs = {
  query?: InputMaybe<Admin_InvoiceActivityQuery>;
};

export type Admin_InvoiceAdjustmentsArgs = {
  query?: InputMaybe<Admin_InvoiceAdjustmentQuery>;
};

export type Admin_InvoiceDetailsArgs = {
  query?: InputMaybe<Admin_InvoiceDetailQuery>;
};

export type Admin_InvoiceInvoiceEmploymentArgs = {
  employmentId: Scalars["ID"]["input"];
};

export type Admin_InvoiceInvoiceEntriesArgs = {
  query: Admin_InvoiceEntryQuery;
};

export type Admin_InvoiceInvoiceEntryArgs = {
  id: Scalars["ID"]["input"];
};

export type Admin_InvoicePaymentsArgs = {
  query?: InputMaybe<Admin_InvoicePaymentQuery>;
};

export type Admin_InvoiceRescheduledEntriesArgs = {
  query: Admin_InvoiceEntryQuery;
};

export type Admin_InvoiceRevisedFilesArgs = {
  query?: InputMaybe<Admin_InvoiceRevisedFileQuery>;
};

export type Admin_InvoiceActivity = {
  action: Admin_InvoiceActivityAction;
  actionType: Admin_InvoiceActivityActionType;
  after?: Maybe<Scalars["JSON"]["output"]>;
  before?: Maybe<Scalars["JSON"]["output"]>;
  createdDate: Scalars["Instant"]["output"];
  endorserAccount?: Maybe<Scalars["String"]["output"]>;
  endorserId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  invoice: Admin_Invoice;
  lastModifiedDate: Scalars["Instant"]["output"];
  tenantId: Scalars["ID"]["output"];
};

export enum Admin_InvoiceActivityAction {
  Created = "Created",
  Deleted = "Deleted",
  Updated = "Updated",
}

export enum Admin_InvoiceActivityActionType {
  Invoice = "Invoice",
  InvoiceDetail = "InvoiceDetail",
  InvoicePayment = "InvoicePayment",
}

export type Admin_InvoiceActivityConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Admin_InvoiceActivity>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Admin_InvoiceActivityFilter = {
  actionTypes?: InputMaybe<Array<Admin_InvoiceActivityActionType>>;
  actions?: InputMaybe<Array<Admin_InvoiceActivityAction>>;
  createdDate?: InputMaybe<OffsetDateTimeRange>;
  invoiceIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  lastModifiedDate?: InputMaybe<OffsetDateTimeRange>;
};

export enum Admin_InvoiceActivityOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
  LastModifiedDate = "LastModifiedDate",
}

export type Admin_InvoiceActivityQuery = {
  filter?: InputMaybe<Admin_InvoiceActivityFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Admin_InvoiceActivityOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Admin_InvoiceAdjustment = {
  adjustAmount: Scalars["Money"]["output"];
  adjustReason: Scalars["String"]["output"];
  adjustStatus: ActiveStatus;
  adjustType: InvoiceRescheduleType;
  createdBy: Scalars["String"]["output"];
  createdDate: Scalars["Instant"]["output"];
  employmentId: Scalars["ID"]["output"];
  id: Scalars["ID"]["output"];
  invoiceId: Scalars["ID"]["output"];
  lastModifiedBy: Scalars["String"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
};

export type Admin_InvoiceAdjustmentConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Admin_InvoiceAdjustment>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Admin_InvoiceAdjustmentFilter = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  invoiceIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export enum Admin_InvoiceAdjustmentOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export type Admin_InvoiceAdjustmentQuery = {
  filter?: InputMaybe<Admin_InvoiceAdjustmentFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Admin_InvoiceAdjustmentOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Admin_InvoiceConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Admin_Invoice>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Admin_InvoiceDetail = {
  amount: Scalars["Money"]["output"];
  createdDate: Scalars["Instant"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  invoice: Admin_Invoice;
  lastModifiedDate: Scalars["Instant"]["output"];
  mandatory: Scalars["Boolean"]["output"];
  name: Admin_InvoiceDetailName;
  status: Admin_InvoiceDetailStatus;
  tenantId: Scalars["ID"]["output"];
  type: Admin_InvoiceDetailType;
};

export type Admin_InvoiceDetailConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Admin_InvoiceDetail>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Admin_InvoiceDetailFilter = {
  invoiceIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  statuses?: InputMaybe<Array<Admin_InvoiceDetailStatus>>;
};

export enum Admin_InvoiceDetailName {
  B2C = "B2C",
  EmployeeFee = "EmployeeFee",
  EmployerFee = "EmployerFee",
  NextPeriod = "NextPeriod",
  Other = "Other",
  PreviousPeriod = "PreviousPeriod",
  Rebate = "Rebate",
  Receivable = "Receivable",
  Subscription = "Subscription",
}

export enum Admin_InvoiceDetailOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
  LastModifiedDate = "LastModifiedDate",
}

export type Admin_InvoiceDetailQuery = {
  filter?: InputMaybe<Admin_InvoiceDetailFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Admin_InvoiceDetailOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export enum Admin_InvoiceDetailStatus {
  Created = "Created",
  Deleted = "Deleted",
  Updated = "Updated",
}

export enum Admin_InvoiceDetailType {
  Addition = "Addition",
  Deduction = "Deduction",
}

export type Admin_InvoiceEmail = {
  receiverMails?: Maybe<Array<Scalars["Email"]["output"]>>;
};

export type Admin_InvoiceEmployment = {
  employee?: Maybe<Employment>;
  employmentId: Scalars["ID"]["output"];
  totalAdjustmentAmount: Scalars["Money"]["output"];
  totalAmount: Scalars["Money"]["output"];
  totalEntryAmount: Scalars["Money"]["output"];
};

export type Admin_InvoiceEntry = {
  actuallyReceivedValue: Scalars["Money"]["output"];
  adjustmentAmount: Scalars["Money"]["output"];
  adjustmentDesc: Scalars["String"]["output"];
  b2cAmount: Scalars["Money"]["output"];
  code: Scalars["String"]["output"];
  company: Company;
  createdDate: Scalars["Instant"]["output"];
  disbursementAccount?: Maybe<Scalars["String"]["output"]>;
  disbursementCode?: Maybe<Scalars["String"]["output"]>;
  disburser?: Maybe<Scalars["String"]["output"]>;
  employee: Employment;
  employeeCode: Scalars["String"]["output"];
  employeeName: Scalars["String"]["output"];
  employerFee: Scalars["Money"]["output"];
  externalRefCode?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  invoice: Admin_Invoice;
  lastModifiedDate: Scalars["Instant"]["output"];
  loan: LoanApplication;
  loanIssuedDate: Scalars["Instant"]["output"];
  loanStatus: Admin_InvoiceEntryLoanStatus;
  loanType: Admin_InvoiceEntryLoanType;
  nextPeriodAmount: Scalars["Money"]["output"];
  openReason?: Maybe<Admin_InvoiceEntryOpenReason>;
  receivableValue: Scalars["Money"]["output"];
  status: Admin_InvoiceEntryStatus;
  userFee: Scalars["Money"]["output"];
};

export type Admin_InvoiceEntryConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Admin_InvoiceEntry>;
  offset: Scalars["Int"]["output"];
  sumAmounts: Array<Maybe<Scalars["Money"]["output"]>>;
  sumEmployerFees: Array<Maybe<Scalars["Money"]["output"]>>;
  sumFees: Array<Maybe<Scalars["Money"]["output"]>>;
  sumReceivableAmounts: Array<Maybe<Scalars["Money"]["output"]>>;
  total: Scalars["Int"]["output"];
};

export type Admin_InvoiceEntryFilter = {
  codeSearch?: InputMaybe<Scalars["String"]["input"]>;
  createdDate?: InputMaybe<OffsetDateTimeRange>;
  employeeIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  invoiceIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  loanIssuedDate?: InputMaybe<OffsetDateTimeRange>;
  loanStatues?: InputMaybe<Array<Admin_InvoiceEntryLoanStatus>>;
  loanTypes?: InputMaybe<Array<Admin_InvoiceEntryLoanType>>;
  statuses?: InputMaybe<Array<Admin_InvoiceEntryStatus>>;
};

export enum Admin_InvoiceEntryLoanStatus {
  Approved = "Approved",
  Closed = "Closed",
  DemoDisbursed = "DemoDisbursed",
  Disbursed = "Disbursed",
  LockForDisbursement = "LockForDisbursement",
  Pending = "Pending",
}

export enum Admin_InvoiceEntryLoanType {
  Advance = "Advance",
  Billing = "Billing",
  Card = "Card",
  DailyInterest = "DailyInterest",
  Data = "Data",
  Fee = "Fee",
  PayLater = "PayLater",
  Principal = "Principal",
  QrTransfer = "QrTransfer",
  Saving = "Saving",
  SpendingAdvance = "SpendingAdvance",
  SubscriptionPackage = "SubscriptionPackage",
  TopUp = "TopUp",
  Transfer = "Transfer",
  Unknown = "Unknown",
}

export enum Admin_InvoiceEntryOpenReason {
  Other = "Other",
  PendingTxn = "PendingTxn",
  UnequalAmount = "UnequalAmount",
  VendorFile = "VendorFile",
}

export enum Admin_InvoiceEntryOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
  LastModifiedDate = "LastModifiedDate",
  LoanIssuedDate = "LoanIssuedDate",
}

export type Admin_InvoiceEntryQuery = {
  filter?: InputMaybe<Admin_InvoiceEntryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Admin_InvoiceEntryOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export enum Admin_InvoiceEntryStatus {
  Closed = "Closed",
  NextInvoice = "NextInvoice",
  Open = "Open",
  Paid = "Paid",
  Reconciled = "Reconciled",
  Unpaid = "Unpaid",
  Unreconciled = "Unreconciled",
  WriteOff = "WriteOff",
}

export type Admin_InvoiceFilter = {
  companyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  contractCreated?: InputMaybe<Scalars["Boolean"]["input"]>;
  createdDate?: InputMaybe<OffsetDateTimeRange>;
  duaDate?: InputMaybe<LocalDateRange>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  lastModifiedDate?: InputMaybe<OffsetDateTimeRange>;
  statuses?: InputMaybe<Array<Admin_InvoiceStatus>>;
  tenantIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  workPeriodEndDate?: InputMaybe<OffsetDateTimeRange>;
  workPeriodIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  workPeriodStartDate?: InputMaybe<OffsetDateTimeRange>;
};

export enum Admin_InvoiceOrderable {
  CreatedDate = "CreatedDate",
  DuaDate = "DuaDate",
  EndDate = "EndDate",
  Id = "Id",
  LastModifiedDate = "LastModifiedDate",
  StartDate = "StartDate",
}

export type Admin_InvoicePayment = {
  amount: Scalars["Money"]["output"];
  code: Scalars["String"]["output"];
  createdDate: Scalars["Instant"]["output"];
  debt: Scalars["Money"]["output"];
  externalRef?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  invoice: Admin_Invoice;
  lastModifiedDate: Scalars["Instant"]["output"];
  note?: Maybe<Scalars["String"]["output"]>;
  receiptFileId?: Maybe<Scalars["ID"]["output"]>;
  status: Admin_InvoicePaymentStatus;
  tenantId: Scalars["ID"]["output"];
  txnDate: Scalars["Instant"]["output"];
};

export type Admin_InvoicePaymentConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Admin_InvoicePayment>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Admin_InvoicePaymentFilter = {
  invoiceIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  statuses?: InputMaybe<Array<Admin_InvoicePaymentStatus>>;
};

export enum Admin_InvoicePaymentOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
  LastModifiedDate = "LastModifiedDate",
}

export type Admin_InvoicePaymentQuery = {
  filter?: InputMaybe<Admin_InvoicePaymentFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Admin_InvoicePaymentOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export enum Admin_InvoicePaymentStatus {
  Created = "Created",
  Deleted = "Deleted",
  Updated = "Updated",
}

export type Admin_InvoiceQuery = {
  filter?: InputMaybe<Admin_InvoiceFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Admin_InvoiceOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export enum Admin_InvoiceReportDepartmentReportMode {
  AdvanceOnly = "AdvanceOnly",
  Combined = "Combined",
  Separated = "Separated",
}

export type Admin_InvoiceRevisedFile = {
  contractSignStatus?: Maybe<Admin_ContractStatus>;
  convertStatus: Admin_ConvertStatus;
  convertedFile?: Maybe<StorageObject>;
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  invoiceCode: Scalars["String"]["output"];
  invoiceId: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  rawFile: StorageObject;
  status: Admin_RevisedStatus;
  uploadedBy: Scalars["String"]["output"];
};

export type Admin_InvoiceRevisedFileCollection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Admin_InvoiceRevisedFile>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Admin_InvoiceRevisedFileFilter = {
  convertStatuses?: InputMaybe<Array<Admin_ConvertStatus>>;
  createdDateRange?: InputMaybe<LocalDateRange>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  invoiceId?: InputMaybe<Scalars["ID"]["input"]>;
  invoiceIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  status?: InputMaybe<Admin_RevisedStatus>;
};

export enum Admin_InvoiceRevisedFileOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export type Admin_InvoiceRevisedFileQuery = {
  filter?: InputMaybe<Admin_InvoiceRevisedFileFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Admin_InvoiceRevisedFileOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export enum Admin_InvoiceStatus {
  Open = "Open",
  Overdue = "Overdue",
  Paid = "Paid",
  Unpaid = "Unpaid",
  WriteOff = "WriteOff",
}

export type Admin_LockEmployee = {
  payloads?: InputMaybe<Array<Admin_LockEmployeePayload>>;
  tenantId: Scalars["ID"]["input"];
};

export type Admin_LockEmployeePayload = {
  employeeId: Scalars["ID"]["input"];
  reasonCode: Scalars["String"]["input"];
  reasonDetail?: InputMaybe<Scalars["String"]["input"]>;
  resignDate?: InputMaybe<Scalars["String"]["input"]>;
};

export type Admin_PaidInvoicePayload = {
  note?: InputMaybe<Scalars["String"]["input"]>;
};

export type Admin_ProcessBankStatementPayload = {
  bankAccount: Scalars["String"]["input"];
  storageId: Scalars["ID"]["input"];
  vendorId: Scalars["ID"]["input"];
};

export type Admin_RawTxn = {
  amount: Scalars["Money"]["output"];
  createdDate: Scalars["Instant"]["output"];
  entries: Array<Admin_RawTxnEntry>;
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  refCode: Scalars["String"]["output"];
  refCodeNormalized: Scalars["String"]["output"];
  refIssuedDate: Scalars["Instant"]["output"];
  refSource: Admin_RawTxnRefSource;
  status: Admin_RawTxnStatus;
};

export type Admin_RawTxnConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Admin_RawTxn>;
  offset: Scalars["Int"]["output"];
  sumAmounts?: Maybe<Array<Scalars["Money"]["output"]>>;
  total: Scalars["Int"]["output"];
};

export type Admin_RawTxnEntry = {
  amount: Scalars["Money"]["output"];
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  rawTxn: Admin_RawTxn;
  refCode: Scalars["String"]["output"];
  refCodeNormalized: Scalars["String"]["output"];
  refIssuedDate: Scalars["Instant"]["output"];
  refSource: Admin_RawTxnEntryRefSource;
  status: Admin_RawTxnEntryStatus;
};

export type Admin_RawTxnEntryRefSource = {
  id: Scalars["ID"]["output"];
  type: Admin_RawTxnEntryRefType;
};

export enum Admin_RawTxnEntryRefType {
  Cashback = "Cashback",
  CashbackVoucher = "CashbackVoucher",
  Ewa = "Ewa",
  InstallmentLoan = "InstallmentLoan",
  PayLater = "PayLater",
  Saving = "Saving",
  WithdrawSaving = "WithdrawSaving",
}

export enum Admin_RawTxnEntryStatus {
  Closed = "Closed",
  Failed = "Failed",
  Pending = "Pending",
  Success = "Success",
}

export type Admin_RawTxnFilter = {
  codeSearch?: InputMaybe<Scalars["String"]["input"]>;
  codes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  createdDate?: InputMaybe<OffsetDateTimeRange>;
  issuedDate?: InputMaybe<OffsetDateTimeRange>;
  lastModifiedDate?: InputMaybe<OffsetDateTimeRange>;
  statuses?: InputMaybe<Array<Admin_RawTxnStatus>>;
  withReconcile?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum Admin_RawTxnOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
  LastModifiedDate = "LastModifiedDate",
}

export type Admin_RawTxnQuery = {
  filter?: InputMaybe<Admin_RawTxnFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Admin_RawTxnOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Admin_RawTxnRefSource = {
  id: Scalars["ID"]["output"];
  type: Admin_RawTxnRefType;
};

export enum Admin_RawTxnRefType {
  InstallmentLoan = "InstallmentLoan",
  PayLater = "PayLater",
  Payment = "Payment",
  Saving = "Saving",
  WithdrawCashback = "WithdrawCashback",
  WithdrawSaving = "WithdrawSaving",
}

export enum Admin_RawTxnStatus {
  Closed = "Closed",
  Failed = "Failed",
  Pending = "Pending",
  Success = "Success",
}

export type Admin_ReconcileTxn = {
  accountingTxn?: Maybe<Admin_AccountingTxn>;
  approvalType: Admin_ReconcileTxnApprovalType;
  arrearsType?: Maybe<Admin_ReconcileTxnArrearsType>;
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  note?: Maybe<Scalars["String"]["output"]>;
  rawTxn?: Maybe<Admin_RawTxn>;
  rawTxnAmount?: Maybe<Scalars["Money"]["output"]>;
  rawTxnCode?: Maybe<Scalars["String"]["output"]>;
  rawTxnCodeNormalized?: Maybe<Scalars["String"]["output"]>;
  rawTxnCreatedDate?: Maybe<Scalars["Instant"]["output"]>;
  status: Admin_ReconcileTxnStatus;
  vendor: Admin_ReconcileVendor;
  vendorId: Scalars["String"]["output"];
  vendorMerchantCode: Scalars["String"]["output"];
  vendorMerchantCodeNormalized: Scalars["String"]["output"];
  vendorTxnAmount: Scalars["Money"]["output"];
  vendorTxnCode: Scalars["String"]["output"];
  vendorTxnCreatedDate?: Maybe<Scalars["Instant"]["output"]>;
};

export enum Admin_ReconcileTxnApprovalType {
  Automatic = "Automatic",
  Manual = "Manual",
}

export enum Admin_ReconcileTxnArrearsType {
  DuplicateTxn = "DuplicateTxn",
  ExceedMoney = "ExceedMoney",
}

export type Admin_ReconcileTxnConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Admin_ReconcileTxn>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Admin_ReconcileTxnFilter = {
  approvalTypes?: InputMaybe<Array<Admin_ReconcileTxnApprovalType>>;
  arrearsTypes?: InputMaybe<Array<Admin_ReconcileTxnArrearsType>>;
  codeSearch?: InputMaybe<Scalars["String"]["input"]>;
  createdDate?: InputMaybe<OffsetDateTimeRange>;
  rawTxnCodes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  rawTxnCreatedDate?: InputMaybe<OffsetDateTimeRange>;
  statuses?: InputMaybe<Array<Admin_ReconcileTxnStatus>>;
  vendorFileIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  vendorIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  vendorTxnCodes?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export enum Admin_ReconcileTxnOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
  LastModifiedDate = "LastModifiedDate",
}

export type Admin_ReconcileTxnQuery = {
  filter?: InputMaybe<Admin_ReconcileTxnFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Admin_ReconcileTxnOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export enum Admin_ReconcileTxnStatus {
  Arrears = "Arrears",
  Closed = "Closed",
  Debt = "Debt",
  Pending = "Pending",
  Reconciled = "Reconciled",
  WaitingForPayment = "WaitingForPayment",
  WriteOff = "WriteOff",
}

export type Admin_ReconcileVendor = {
  code: Scalars["String"]["output"];
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  name: Scalars["String"]["output"];
  status: Admin_ReconcileVendorStatus;
  templateId: Scalars["ID"]["output"];
};

export type Admin_ReconcileVendorConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Admin_ReconcileVendor>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Admin_ReconcileVendorFile = {
  createdDate: Scalars["Instant"]["output"];
  endorserEmail: Scalars["String"]["output"];
  endorserId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  note?: Maybe<Scalars["String"]["output"]>;
  reconcileTxn: Admin_ReconcileTxnConnection;
  status: Admin_ReconcileVendorFileStatus;
  storage: StorageObject;
  taskId?: Maybe<Scalars["String"]["output"]>;
  totalRow: Scalars["Int"]["output"];
  vendor: Admin_ReconcileVendor;
};

export type Admin_ReconcileVendorFileReconcileTxnArgs = {
  query?: InputMaybe<Admin_ReconcileTxnQuery>;
};

export type Admin_ReconcileVendorFileConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Admin_ReconcileVendorFile>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Admin_ReconcileVendorFileFilter = {
  createdDate?: InputMaybe<OffsetDateTimeRange>;
  lastModifiedDate?: InputMaybe<OffsetDateTimeRange>;
  statuses?: InputMaybe<Array<Admin_ReconcileVendorFileStatus>>;
  taskIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  vendorIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type Admin_ReconcileVendorFileQuery = {
  filter?: InputMaybe<Admin_ReconcileVendorFileFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Admin_ReconcileVendorFileResult = {
  vendorFile: Admin_ReconcileVendorFile;
};

export enum Admin_ReconcileVendorFileStatus {
  Done = "Done",
  Failed = "Failed",
  Processing = "Processing",
}

export type Admin_ReconcileVendorQuery = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum Admin_ReconcileVendorStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type Admin_RequestRecEntry = {
  amount: Scalars["Money"]["output"];
  createdDate: Scalars["Instant"]["output"];
  employee: Employment;
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  receivedAmount: Scalars["Money"]["output"];
  reconcileTxn: Admin_ReconcileTxn;
  status: Admin_RequestRecEntryStatus;
  targetPayment?: Maybe<Admin_RequestRecEntryTargetPayment>;
  type?: Maybe<Admin_RequestRecEntryType>;
};

export type Admin_RequestRecEntryConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Admin_RequestRecEntry>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Admin_RequestRecEntryFilter = {
  statuses?: InputMaybe<Array<Admin_RequestRecEntryStatus>>;
};

export enum Admin_RequestRecEntryOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
  LastModifiedDate = "LastModifiedDate",
}

export type Admin_RequestRecEntryQuery = {
  filter?: InputMaybe<Admin_RequestRecEntryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Admin_RequestRecEntryOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export enum Admin_RequestRecEntryStatus {
  Done = "Done",
  Pending = "Pending",
  Processing = "Processing",
  WaitingForPayment = "WaitingForPayment",
  WriteOff = "WriteOff",
}

export enum Admin_RequestRecEntryTargetPayment {
  Company = "Company",
  Employee = "Employee",
}

export enum Admin_RequestRecEntryType {
  Added = "Added",
  Deducted = "Deducted",
}

export type Admin_RescheduleInvoicePayload = {
  adjustType: Scalars["String"]["input"];
  /** type: InvoiceRescheduleType, */
  amount: Scalars["Money"]["input"];
  employmentId: Scalars["ID"]["input"];
  invoiceId: Scalars["ID"]["input"];
  reason: Scalars["String"]["input"];
};

export enum Admin_RevisedStatus {
  Active = "Active",
  Cancelled = "Cancelled",
}

export type Admin_SendReportPayload = {
  accountantName: Scalars["String"]["input"];
  dearName: Scalars["String"]["input"];
  departmentIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  departmentReportModes?: InputMaybe<
    Array<Admin_InvoiceReportDepartmentReportMode>
  >;
  emails?: InputMaybe<Array<Scalars["Email"]["input"]>>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type Admin_UnLockEmployee = {
  payloads?: InputMaybe<Array<Admin_UnLockEmployeePayload>>;
  tenantId: Scalars["ID"]["input"];
};

export type Admin_UnLockEmployeePayload = {
  employeeId: Scalars["ID"]["input"];
  reasonCode?: InputMaybe<Scalars["String"]["input"]>;
  reasonDetail?: InputMaybe<Scalars["String"]["input"]>;
  resignDate?: InputMaybe<Scalars["String"]["input"]>;
};

export type Admin_UpdateEmploymentPayload = {
  bankAccount?: InputMaybe<Scalars["String"]["input"]>;
  bankId?: InputMaybe<Scalars["ID"]["input"]>;
  detail?: InputMaybe<Scalars["String"]["input"]>;
  newEmploymentId?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
};

export type Admin_UploadRevisedFilesStatus = {
  data: Array<Admin_InvoiceRevisedFile>;
  failed: Scalars["Int"]["output"];
  handled: Scalars["Int"]["output"];
  pending: Scalars["Int"]["output"];
  success: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Admin_VendorTxn = {
  bankAccount?: Maybe<Scalars["String"]["output"]>;
  companyCode?: Maybe<Scalars["String"]["output"]>;
  documentDate?: Maybe<Scalars["Date"]["output"]>;
  documentNumber?: Maybe<Scalars["String"]["output"]>;
  merchantCode?: Maybe<Scalars["String"]["output"]>;
  merchantCodeNormalized?: Maybe<Scalars["String"]["output"]>;
  misaCategoryCode?: Maybe<Scalars["String"]["output"]>;
  misaCreditAccount?: Maybe<Scalars["String"]["output"]>;
  misaCustomerCode?: Maybe<Scalars["String"]["output"]>;
  misaDebitAccount?: Maybe<Scalars["String"]["output"]>;
  misaDescription?: Maybe<Scalars["String"]["output"]>;
  subjectCode?: Maybe<Scalars["String"]["output"]>;
  tenantCode?: Maybe<Scalars["String"]["output"]>;
  txnAmount?: Maybe<Scalars["Money"]["output"]>;
  txnAmountValue?: Maybe<Scalars["Decimal"]["output"]>;
  txnDate?: Maybe<Scalars["Date"]["output"]>;
  txnType?: Maybe<Scalars["String"]["output"]>;
  vendorAccountNumber?: Maybe<Scalars["String"]["output"]>;
  vendorCode?: Maybe<Scalars["String"]["output"]>;
};

export type Admin_WriteOffRequestRecEntryPayload = {
  note: Scalars["String"]["input"];
};

export type Admin_Anhnvt_InstallmentLoan = {
  amount: Scalars["Money"]["output"];
  appraisal?: Maybe<AppraisalType>;
  approvalHistory?: Maybe<Array<InstallmentLoanHistoryResponse>>;
  createdDate: Scalars["Instant"]["output"];
  decline?: Maybe<InstallmentLoanDecline>;
  description?: Maybe<Scalars["String"]["output"]>;
  disbursedAt?: Maybe<Scalars["Instant"]["output"]>;
  disbursementCode: Scalars["String"]["output"];
  employment: Employment;
  finalReconcileDate?: Maybe<Scalars["LocalDate"]["output"]>;
  handledAt?: Maybe<Scalars["Instant"]["output"]>;
  handledBy?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  last3MonthHistory?: Maybe<UserLast3MonthHistory>;
  limitAmount?: Maybe<LimitAmountPayLaterResponse>;
  status: InstallmentLoanStatus;
  summary: InstallmentLoanDetailResponse;
  workPeriodNumber: Scalars["Int"]["output"];
};

export type Admin_Anhnvt_InstallmentLoanPayable = {
  amount: Scalars["Money"]["output"];
  loanApplication?: Maybe<LoanApplication>;
  offset: Scalars["Int"]["output"];
  period: Admin_Anhnvt_InstallmentLoanPayable_Period;
  status: UserPayableStatus;
  type: UserPayableType;
  workPeriodId: Scalars["ID"]["output"];
};

export type Admin_Anhnvt_InstallmentLoanPayable_Period = {
  endDate: Scalars["Instant"]["output"];
  startDate: Scalars["Instant"]["output"];
};

export type Admin_Anhnvt_Installment_PeriodSummary = {
  details: Array<Admin_Anhnvt_InstallmentLoanPayable>;
  endDate?: Maybe<Scalars["Instant"]["output"]>;
  remainingAmount: Scalars["Money"]["output"];
  startDate?: Maybe<Scalars["Instant"]["output"]>;
  status: Admin_Anhnvt_Installment_PeriodSummary_Status;
  totalAmount: Scalars["Money"]["output"];
};

export enum Admin_Anhnvt_Installment_PeriodSummary_Status {
  Done = "Done",
  Pending = "Pending",
}

export type Admin_B2cLoanAccount = {
  employment: Employment;
  employmentId: Scalars["ID"]["output"];
  fullName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  phoneNumber: Scalars["PhoneNumber"]["output"];
  totalDebtAmount: Scalars["Money"]["output"];
  totalPaidAmount: Scalars["Money"]["output"];
};

export type Admin_B2cLoanAccountConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Admin_B2cLoanAccount>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Admin_B2cLoanAccountFilter = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export enum Admin_B2cLoanAccountOrderable {
  CreatedDate = "CreatedDate",
  Id = "Id",
}

export type Admin_B2cLoanAccountQuery = {
  filter?: InputMaybe<Admin_B2cLoanAccountFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Admin_B2cLoanAccountOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Admin_ProcessVendorReconciliationPayload = {
  storageId: Scalars["ID"]["input"];
  vendorId: Scalars["ID"]["input"];
};

export type Admin_UpdateCashbackPayload = {
  cashbackWithdrawalLimit: Scalars["Money"]["input"];
  directWithdrawal: Scalars["Boolean"]["input"];
};

export type Admin_UpdateTenantDataConfig = {
  discount: Scalars["Decimal"]["input"];
  limitation?: InputMaybe<Scalars["Money"]["input"]>;
};

export type B2c_BankAccount = {
  account: Scalars["String"]["output"];
  bank: Bank;
};

export type B2c_BankAccountPayload = {
  account: Scalars["String"]["input"];
  bankId: Scalars["ID"]["input"];
};

export type B2c_CollectedForm = {
  bankAccount?: Maybe<B2c_BankAccount>;
  companyName?: Maybe<Scalars["String"]["output"]>;
  fullName: Scalars["String"]["output"];
  metadata?: Maybe<Scalars["JSON"]["output"]>;
  socialIdDocument?: Maybe<SocialIdDocument>;
};

export type B2c_CreateMissionPayload = {
  metadata?: InputMaybe<Scalars["JSON"]["input"]>;
  missionKey: Scalars["String"]["input"];
  status: B2c_QuestStatus;
};

export type B2c_MutateCollectedFormResponse = {
  form: B2c_CollectedForm;
};

export type B2c_Quest = {
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  metadata?: Maybe<Scalars["JSON"]["output"]>;
  missionKey: Scalars["String"]["output"];
  status: B2c_QuestStatus;
};

export enum B2c_QuestStatus {
  InProgress = "InProgress",
  Pending = "Pending",
  Success = "Success",
}

export type B2c_SocialIdDocumentPayload = {
  date?: InputMaybe<Scalars["LocalDate"]["input"]>;
  number: Scalars["String"]["input"];
  place?: InputMaybe<Scalars["String"]["input"]>;
};

export type B2c_SubmitFormPayload = {
  bankAccount?: InputMaybe<B2c_BankAccountPayload>;
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  fullName: Scalars["String"]["input"];
  metadata?: InputMaybe<Scalars["JSON"]["input"]>;
  overrideBeneficiaryBankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  socialIdDocument?: InputMaybe<B2c_SocialIdDocumentPayload>;
};

export type B2c_SubmitQuestResponse = {
  quest: B2c_Quest;
};

export type Banner_ActionData = {
  external?: Maybe<Scalars["String"]["output"]>;
  inAppBrowser?: Maybe<Scalars["String"]["output"]>;
  internal?: Maybe<Scalars["String"]["output"]>;
  noop?: Maybe<Scalars["String"]["output"]>;
  webview?: Maybe<Scalars["String"]["output"]>;
};

export type Banner_ActionDataInput = {
  external?: InputMaybe<Scalars["String"]["input"]>;
  inAppBrowser?: InputMaybe<Scalars["String"]["input"]>;
  internal?: InputMaybe<Scalars["String"]["input"]>;
  noop?: InputMaybe<Scalars["String"]["input"]>;
};

export enum Banner_ActionType {
  External = "external",
  InAppBrowser = "inAppBrowser",
  Internal = "internal",
  Noop = "noop",
  Webview = "webview",
}

export type Banner_BackgroundImage = {
  backgroundColor?: Maybe<Scalars["String"]["output"]>;
  backgroundImage: StorageObject;
  code: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  os?: Maybe<Banner_Os>;
  status?: Maybe<Banner_Status>;
};

export type Banner_BackgroundImageConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Banner_BackgroundImage>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Banner_BackgroundImageFilter = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  os?: InputMaybe<Banner_Os>;
  ratio?: InputMaybe<Scalars["String"]["input"]>;
};

export enum Banner_BackgroundImageOrderable {
  Id = "Id",
}

export type Banner_BackgroundImageQuery = {
  filter?: InputMaybe<Banner_BackgroundImageFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Banner_BackgroundImageOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Banner_Banner = {
  actionData: Banner_ActionData;
  actionType: Banner_ActionType;
  campaigns: Banner_BannerCampaignsConnection;
  code: Scalars["String"]["output"];
  contentImage?: Maybe<StorageObject>;
  createdDate: Scalars["Instant"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  minimumVersion?: Maybe<Scalars["String"]["output"]>;
  os?: Maybe<Banner_Os>;
  priority: Scalars["Int"]["output"];
  slot: Scalars["String"]["output"];
  status: Banner_Status;
  title: Scalars["String"]["output"];
  type: Banner_Type;
};

export type Banner_BannerCampaignsArgs = {
  query?: Banner_BannerCampaignQuery;
};

export type Banner_BannerCampaign = {
  banner: Banner_Banner;
  createdDate: Scalars["Instant"]["output"];
  employeeSelector: Scalars["String"]["output"];
  endTime: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  startTime: Scalars["Instant"]["output"];
  status: Banner_BannerCampaignStatus;
  tenants?: Maybe<Array<Core_Tenant>>;
};

export type Banner_BannerCampaignInitPayload = {
  bannerId: Scalars["ID"]["input"];
  employeeSelector: Scalars["String"]["input"];
  endTime: Scalars["Instant"]["input"];
  startTime: Scalars["Instant"]["input"];
  status: Banner_BannerCampaignStatus;
  tenantIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export enum Banner_BannerCampaignOrderable {
  CreatedDate = "createdDate",
  EndTime = "endTime",
  Id = "id",
  LastModifiedDate = "lastModifiedDate",
  StartTime = "startTime",
}

export type Banner_BannerCampaignQuery = {
  filter?: InputMaybe<Banner_BannerCampaignQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Banner_BannerCampaignOrderable>;
  orderDirection?: OrderDirection;
};

export type Banner_BannerCampaignQueryFilter = {
  endTime?: InputMaybe<Scalars["Instant"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  startTime?: InputMaybe<Scalars["Instant"]["input"]>;
  statuses: Array<Banner_BannerCampaignStatus>;
};

export type Banner_BannerCampaignResponse = {
  campaign: Banner_BannerCampaign;
};

export enum Banner_BannerCampaignStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type Banner_BannerCampaignUpdatePayload = {
  allTenants?: InputMaybe<Scalars["Boolean"]["input"]>;
  bannerId?: InputMaybe<Scalars["ID"]["input"]>;
  employeeSelector?: InputMaybe<Scalars["String"]["input"]>;
  endTime?: InputMaybe<Scalars["Instant"]["input"]>;
  startTime?: InputMaybe<Scalars["Instant"]["input"]>;
  status?: InputMaybe<Banner_BannerCampaignStatus>;
  tenantIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type Banner_BannerCampaignsConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Banner_BannerCampaign>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

/** Connection */
export type Banner_BannerConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Banner_Banner>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export enum Banner_BannerOrderable {
  CreatedDate = "createdDate",
  Id = "id",
}

export type Banner_BannerQuery = {
  filter?: InputMaybe<Banner_BannerQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Banner_BannerOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Banner_BannerQueryFilter = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  createdDate?: InputMaybe<OffsetDateTimeRange>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  minimumVersion?: InputMaybe<Scalars["String"]["input"]>;
  os?: InputMaybe<Banner_Os>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  slots?: InputMaybe<Array<Scalars["String"]["input"]>>;
  statuses?: InputMaybe<Array<Banner_Status>>;
  types?: InputMaybe<Array<Banner_Type>>;
};

export type Banner_InitPayload = {
  actionData: Banner_ActionDataInput;
  actionType: Banner_ActionType;
  code: Scalars["String"]["input"];
  contentImageId?: InputMaybe<Scalars["ID"]["input"]>;
  description: Scalars["String"]["input"];
  minimumVersion: Scalars["String"]["input"];
  os?: InputMaybe<Banner_Os>;
  priority: Scalars["Int"]["input"];
  slot: Scalars["String"]["input"];
  status: Banner_Status;
  title: Scalars["String"]["input"];
  type: Banner_Type;
};

export type Banner_MutateBannerResponse = {
  banner: Banner_Banner;
};

export enum Banner_Os {
  Android = "Android",
  Ios = "Ios",
}

export enum Banner_Status {
  Active = "Active",
  Inactive = "Inactive",
}

export enum Banner_Type {
  Image = "Image",
}

export type Banner_UpdatePayload = {
  actionData?: InputMaybe<Banner_ActionDataInput>;
  actionType?: InputMaybe<Banner_ActionType>;
  allPlatforms?: InputMaybe<Scalars["Boolean"]["input"]>;
  allVersions?: InputMaybe<Scalars["Boolean"]["input"]>;
  contentImageId?: InputMaybe<Scalars["ID"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  minimumVersion?: InputMaybe<Scalars["String"]["input"]>;
  os?: InputMaybe<Banner_Os>;
  priority?: InputMaybe<Scalars["Int"]["input"]>;
  slot?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Banner_Status>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Banner_Type>;
};

export type Bnpl_AppraisalPayload = {
  appraisalType?: InputMaybe<AppraisalType>;
  description?: InputMaybe<Scalars["String"]["input"]>;
};

export type Bnpl_DemoCalculateFeePayload = {
  amount: Scalars["Money"]["input"];
  packageId?: InputMaybe<Scalars["ID"]["input"]>;
  periodNumber: Scalars["Int"]["input"];
};

export type Bnpl_DemoFeeCalculatorInstallmentLoan = {
  comparatorDifferentInPercentage?: Maybe<Scalars["String"]["output"]>;
  displayFee: Scalars["String"]["output"];
  displayName: Scalars["String"]["output"];
  isLteWithRoot?: Maybe<Scalars["Boolean"]["output"]>;
  isRootComparator: Scalars["Boolean"]["output"];
};

export type Bnpl_DemoInstallmentLoanFeeCalculateResponse = {
  code: Scalars["String"]["output"];
  comparatorDifferentInMoney?: Maybe<Scalars["Money"]["output"]>;
  feeConfig?: Maybe<Bnpl_DemoFeeCalculatorInstallmentLoan>;
  monthlyUserPayable?: Maybe<Scalars["Money"]["output"]>;
  totalUserPayable?: Maybe<Scalars["Money"]["output"]>;
};

export type Campaign = {
  commissionAmountType?: Maybe<Scalars["String"]["output"]>;
  commissionAmountValue?: Maybe<Scalars["Float"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  paymentTimeout?: Maybe<Scalars["Int"]["output"]>;
  profit?: Maybe<Scalars["String"]["output"]>;
  rules?: Maybe<Scalars["String"]["output"]>;
};

export type Category = {
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  products?: Maybe<Array<Maybe<ProductEarning>>>;
};

export type CategoryConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Category>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type ChargeBusinessAdminConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<ChargeBusinessResponse>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type ChargeBusinessDetailAdminConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<ChargeBusinessDetailResponse>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Codepush_DeviceInfoInput = {
  appVersion?: InputMaybe<Scalars["String"]["input"]>;
  osName?: InputMaybe<Codepush_OsName>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export enum Codepush_OsName {
  Android = "Android",
  Ios = "Ios",
}

export type Codepush_Release = {
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  osName: Codepush_OsName;
  status: Codepush_ReleaseStatus;
  targetVersion: Scalars["String"]["output"];
  tenants?: Maybe<Array<Core_Tenant>>;
};

export type Codepush_ReleaseInitPayload = {
  osName: Codepush_OsName;
  status: Codepush_ReleaseStatus;
  targetVersion: Scalars["String"]["input"];
  tenantIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type Codepush_ReleaseResponse = {
  release: Codepush_Release;
};

export enum Codepush_ReleaseStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type Codepush_ReleaseUpdatePayload = {
  allTenants?: InputMaybe<Scalars["Boolean"]["input"]>;
  osName?: InputMaybe<Codepush_OsName>;
  status?: InputMaybe<Codepush_ReleaseStatus>;
  targetVersion?: InputMaybe<Scalars["String"]["input"]>;
  tenantIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type Codepush_ReleasesResponse = {
  releases?: Maybe<Array<Codepush_Release>>;
};

export type ComissionDisbursement = {
  amount?: Maybe<Scalars["Money"]["output"]>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  transactionId?: Maybe<Scalars["ID"]["output"]>;
};

export type ComissionDisbursementOrderableConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<ComissionDisbursement>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
  totalAmount: Scalars["Money"]["output"];
};

export enum Common_Appversion_Os {
  Android = "Android",
  Ios = "Ios",
}

export type Core_CreateTenantPayload = {
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  logoId?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type Core_Employee = {
  attributes: Array<AttributeValue>;
  code: Scalars["String"]["output"];
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
};

export type Core_EmployeeAttributesArgs = {
  ids: Array<AttributeIdPayload>;
};

export type Core_EmployeeConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Core_Employee>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export enum Core_EmployeeOrderable {
  Code = "code",
  Id = "id",
}

export type Core_EmployeeQuery = {
  filter?: InputMaybe<Core_EmployeeQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Core_EmployeeOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Core_EmployeeQueryFilter = {
  code?: InputMaybe<StringFilter>;
};

export type Core_ExportTenantResourcesPayload = {
  filename?: InputMaybe<Scalars["String"]["input"]>;
  qualifiers?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type Core_ExportTenantResourcesResponse = {
  storageObject: StorageObject;
};

export type Core_ExtensionFactory = {
  description: Scalars["String"]["output"];
  example?: Maybe<Scalars["JSON"]["output"]>;
  identifier: Scalars["String"]["output"];
  result: Core_ExtensionPoint;
};

export type Core_ExtensionInstallation = {
  config?: Maybe<Scalars["JSON"]["output"]>;
  createdDate: Scalars["Instant"]["output"];
  factory: Core_ExtensionFactory;
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  status: Core_ExtensionInstallationStatus;
};

export type Core_ExtensionInstallationConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Core_ExtensionInstallation>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export enum Core_ExtensionInstallationOrderable {
  CreatedDate = "createdDate",
  Id = "id",
  LastModifiedDate = "lastModifiedDate",
}

export type Core_ExtensionInstallationQuery = {
  filter?: InputMaybe<Core_ExtensionInstallationQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Core_ExtensionInstallationOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Core_ExtensionInstallationQueryFilter = {
  codes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export enum Core_ExtensionInstallationStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type Core_ExtensionPoint = {
  description: Scalars["String"]["output"];
  factories: Array<Core_ExtensionFactory>;
  identifier: Scalars["String"]["output"];
};

export type Core_ImportEmployeePayload = {
  code: Scalars["String"]["input"];
  values: Array<AttributeValuePayload>;
};

export type Core_ImportTenantResourcesPayload = {
  qualifiers?: InputMaybe<Array<Scalars["String"]["input"]>>;
  storageObjectId: Scalars["ID"]["input"];
};

export type Core_InstallExtensionPayload = {
  activate?: InputMaybe<Scalars["Boolean"]["input"]>;
  config?: InputMaybe<Scalars["JSON"]["input"]>;
  factoryIdentifier: Scalars["String"]["input"];
};

export type Core_InstallExtensionResponse = {
  installation: Core_ExtensionInstallation;
};

export type Core_MutateTenantResponse = {
  tenant: Core_Tenant;
};

export type Core_Tenant = {
  automationAttendance: FeatureInfo;
  carrierDataConfig?: Maybe<CarrierDataConfig>;
  code: Scalars["String"]["output"];
  companies: CompanyConnection;
  company: Company;
  companyByCode: Company;
  configKeys: Array<Scalars["String"]["output"]>;
  configs: Array<Core_TenantConfig>;
  core_extensionInstallation: Core_ExtensionInstallation;
  core_extensionInstallations: Core_ExtensionInstallationConnection;
  createdDate: Scalars["Instant"]["output"];
  department: Department;
  departmentByCode: Department;
  departments: DepartmentConnection;
  description?: Maybe<Scalars["String"]["output"]>;
  dynamicLimitPercentage: FeatureInfo;
  earning_products: Earning_ProductEarningConnection;
  employee: Core_Employee;
  employees: Core_EmployeeConnection;
  employment: Employment;
  employments: EmploymentConnection;
  ewa_defaultEmployeeImportColumns: Array<AttributeColumn>;
  ewa_employeeImportColumns?: Maybe<Array<AttributeColumn>>;
  ewa_loanFeeConfig: Ewa_LoanFeeConfig;
  ewa_loanFeeConfigByCode: Ewa_LoanFeeConfig;
  ewa_loanFeeConfigRule: Ewa_LoanFeeConfigRule;
  ewa_loanFeeConfigRuleByCode: Ewa_LoanFeeConfigRule;
  ewa_loanFeeConfigRules: Ewa_LoanFeeConfigRuleConnection;
  ewa_loanFeeConfigs: Ewa_LoanFeeConfigConnection;
  ewa_promotion: Ewa_Promotion;
  ewa_promotionByCode: Ewa_Promotion;
  ewa_promotionTypes: Array<Scalars["String"]["output"]>;
  ewa_promotions: Ewa_PromotionConnection;
  id: Scalars["ID"]["output"];
  isFeatureActive: Scalars["Boolean"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  logo?: Maybe<StorageObject>;
  name?: Maybe<Scalars["String"]["output"]>;
  newEWA?: Maybe<Scalars["Boolean"]["output"]>;
  org_workSchedule: Org_WorkSchedule;
  org_workScheduleByCode: Org_WorkSchedule;
  org_workScheduleRule: Org_WorkScheduleRule;
  org_workScheduleRuleByCode: Org_WorkScheduleRule;
  org_workScheduleRules: Org_WorkScheduleRuleConnection;
  org_workScheduleTypes: Array<Scalars["String"]["output"]>;
  org_workSchedules: Org_WorkScheduleConnection;
  preAdvance: FeatureInfo;
  realms: Array<RealmData>;
  resourcesQualifiers: Array<Scalars["String"]["output"]>;
  rootCompany?: Maybe<Company>;
  saving_config?: Maybe<TenantSavingConfigResponse>;
  task?: Maybe<FileProcessor_Task>;
  tasks: FileProcessor_TaskConnection;
  topUp_config?: Maybe<TenantTopUpConfigResponse>;
  workplace: Workplace;
  workplaceByCode: Workplace;
  workplaces: WorkplaceConnection;
};

export type Core_TenantCompaniesArgs = {
  query?: InputMaybe<CompanyQuery>;
};

export type Core_TenantCompanyArgs = {
  id: Scalars["ID"]["input"];
};

export type Core_TenantCompanyByCodeArgs = {
  code: Scalars["String"]["input"];
};

export type Core_TenantCore_ExtensionInstallationArgs = {
  id: Scalars["ID"]["input"];
};

export type Core_TenantCore_ExtensionInstallationsArgs = {
  query?: InputMaybe<Core_ExtensionInstallationQuery>;
};

export type Core_TenantDepartmentArgs = {
  id: Scalars["ID"]["input"];
};

export type Core_TenantDepartmentByCodeArgs = {
  code: Scalars["String"]["input"];
};

export type Core_TenantDepartmentsArgs = {
  query?: InputMaybe<DepartmentQuery>;
};

export type Core_TenantEarning_ProductsArgs = {
  query?: InputMaybe<ProductQuery>;
};

export type Core_TenantEmployeeArgs = {
  id: Scalars["ID"]["input"];
};

export type Core_TenantEmployeesArgs = {
  query?: Core_EmployeeQuery;
};

export type Core_TenantEmploymentArgs = {
  id: Scalars["ID"]["input"];
};

export type Core_TenantEmploymentsArgs = {
  query?: InputMaybe<EmploymentQuery>;
};

export type Core_TenantEwa_LoanFeeConfigArgs = {
  id: Scalars["ID"]["input"];
};

export type Core_TenantEwa_LoanFeeConfigByCodeArgs = {
  code: Scalars["String"]["input"];
};

export type Core_TenantEwa_LoanFeeConfigRuleArgs = {
  id: Scalars["ID"]["input"];
};

export type Core_TenantEwa_LoanFeeConfigRuleByCodeArgs = {
  code: Scalars["String"]["input"];
};

export type Core_TenantEwa_LoanFeeConfigRulesArgs = {
  query?: InputMaybe<Ewa_LoanFeeConfigRuleQuery>;
};

export type Core_TenantEwa_LoanFeeConfigsArgs = {
  query?: InputMaybe<Ewa_LoanFeeConfigQuery>;
};

export type Core_TenantEwa_PromotionArgs = {
  id: Scalars["ID"]["input"];
};

export type Core_TenantEwa_PromotionByCodeArgs = {
  code: Scalars["String"]["input"];
};

export type Core_TenantEwa_PromotionsArgs = {
  query?: InputMaybe<Ewa_PromotionQuery>;
};

export type Core_TenantIsFeatureActiveArgs = {
  feature: Scalars["String"]["input"];
};

export type Core_TenantOrg_WorkScheduleArgs = {
  id: Scalars["ID"]["input"];
};

export type Core_TenantOrg_WorkScheduleByCodeArgs = {
  code: Scalars["String"]["input"];
};

export type Core_TenantOrg_WorkScheduleRuleArgs = {
  id: Scalars["ID"]["input"];
};

export type Core_TenantOrg_WorkScheduleRuleByCodeArgs = {
  code: Scalars["String"]["input"];
};

export type Core_TenantOrg_WorkScheduleRulesArgs = {
  query?: InputMaybe<Org_WorkScheduleRuleQuery>;
};

export type Core_TenantOrg_WorkSchedulesArgs = {
  query?: InputMaybe<Org_WorkScheduleQuery>;
};

export type Core_TenantTaskArgs = {
  id: Scalars["ID"]["input"];
};

export type Core_TenantTasksArgs = {
  query: FileProcessor_TaskQueryInput;
};

export type Core_TenantWorkplaceArgs = {
  id: Scalars["ID"]["input"];
};

export type Core_TenantWorkplaceByCodeArgs = {
  code: Scalars["String"]["input"];
};

export type Core_TenantWorkplacesArgs = {
  query?: InputMaybe<WorkplaceQuery>;
};

export type Core_TenantConfig = {
  key: Scalars["String"]["output"];
  value?: Maybe<Scalars["JSON"]["output"]>;
};

export type Core_TenantIdFilter = {
  equalTo?: InputMaybe<Scalars["ID"]["input"]>;
  in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  notEqualTo?: InputMaybe<Scalars["ID"]["input"]>;
  notIn?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export enum Core_TenantOrderable {
  Code = "code",
  Id = "id",
}

export enum Core_TenantStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type Core_UpdateTenantPayload = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  logoId?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type CronJobConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<CronJobs>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type CronJobs = {
  expression?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  next_time_run?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  status?: Maybe<CronJobStatus>;
  taskId?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<CronJobType>;
};

export type DisburserExecution_Beneficiary = {
  identifier: Scalars["String"]["input"];
  referenceName?: InputMaybe<Scalars["String"]["input"]>;
  vendorCode: Scalars["String"]["input"];
};

export type DisburserExecution_DisbursementExecution = {
  amount: Scalars["Money"]["input"];
  beneficiary: DisburserExecution_Beneficiary;
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  executionCode?: InputMaybe<Scalars["String"]["input"]>;
  extensions?: InputMaybe<Scalars["JSON"]["input"]>;
  handlerCode: Scalars["String"]["input"];
  handlerParams?: InputMaybe<Scalars["JSON"]["input"]>;
  retryable?: InputMaybe<Scalars["Boolean"]["input"]>;
  source: Scalars["String"]["input"];
  statusFailureErrorCode?: InputMaybe<Scalars["String"]["input"]>;
  statusFailureErrorMessage?: InputMaybe<Scalars["String"]["input"]>;
  statusFailureErrorType?: InputMaybe<Scalars["String"]["input"]>;
  statusSuccessExternalRef?: InputMaybe<Scalars["String"]["input"]>;
  statusType: Scalars["String"]["input"];
};

export type Disburser_Beneficiary = {
  identifier: Scalars["String"]["output"];
  referenceName?: Maybe<Scalars["String"]["output"]>;
  vendorCode: Scalars["String"]["output"];
};

export type Disburser_Disbursement = {
  amount: Scalars["Money"]["output"];
  beneficiary: Disburser_Beneficiary;
  code: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  extensions?: Maybe<Scalars["JSON"]["output"]>;
  id: Scalars["ID"]["output"];
};

export type Disburser_DisbursementRepresentation = {
  amount?: Maybe<Scalars["Money"]["output"]>;
  beneficiary?: Maybe<Disburser_Beneficiary>;
  code?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  extensions?: Maybe<Scalars["JSON"]["output"]>;
  failureOwner?: Maybe<Disburser_FailureOwner>;
  id?: Maybe<Scalars["String"]["output"]>;
  retryable?: Maybe<Scalars["Boolean"]["output"]>;
  source?: Maybe<Scalars["String"]["output"]>;
  sourceUnaffected?: Maybe<Scalars["Boolean"]["output"]>;
  statusFailureErrorType?: Maybe<Disburser_FailureType>;
  statusType?: Maybe<Disburser_StatusType>;
};

export type Disburser_DisbursementRetryHistory = {
  actionBy?: Maybe<Scalars["String"]["output"]>;
  beneficiaryIdentifier?: Maybe<Scalars["String"]["output"]>;
  beneficiaryReferenceName?: Maybe<Scalars["String"]["output"]>;
  beneficiaryVendorCode?: Maybe<Scalars["String"]["output"]>;
  disbursementCode?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  loanApplicationCode?: Maybe<Scalars["String"]["output"]>;
  loanApplicationId?: Maybe<Scalars["String"]["output"]>;
  retryAt?: Maybe<Scalars["Instant"]["output"]>;
};

export type Disburser_DisbursementRetryHistoryConnection = {
  limit?: Maybe<Scalars["Int"]["output"]>;
  nodes?: Maybe<Array<Maybe<Disburser_DisbursementRetryHistory>>>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type Disburser_DisbursementRetryHistoryFilter = {
  actionBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  disbursementCodes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  loanApplicationCodes?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  loanApplicationIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export enum Disburser_DisbursementRetryHistoryOrderable {
  Id = "Id",
  RetryAt = "RetryAt",
}

export type Disburser_DisbursementRetryHistoryQuery = {
  filter?: InputMaybe<Disburser_DisbursementRetryHistoryFilter>;
  filters?: InputMaybe<
    Array<InputMaybe<Disburser_DisbursementRetryHistoryFilter>>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Disburser_DisbursementRetryHistoryOrderable>;
  orderDirection?: OrderDirection;
};

export type Disburser_FailPayload = {
  code: Scalars["String"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  retryable?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum Disburser_FailureOwner {
  Application = "Application",
  System = "System",
}

export enum Disburser_FailureType {
  BeneficiaryNameNotMatch = "BENEFICIARY_NAME_NOT_MATCH",
  BeneficiaryNotFound = "BENEFICIARY_NOT_FOUND",
  Unknown = "UNKNOWN",
}

export type Disburser_LockResponse = {
  disbursements: Array<Disburser_Disbursement>;
};

export enum Disburser_StatusType {
  Closed = "Closed",
  Failure = "Failure",
  Locked = "Locked",
  New = "New",
  Success = "Success",
}

export type Disburser_SuccessPayload = {
  externalRef: Scalars["String"]["input"];
  handlerCode: Scalars["String"]["input"];
  handlerParams?: InputMaybe<Scalars["JSON"]["input"]>;
  time?: InputMaybe<Scalars["Instant"]["input"]>;
};

export type Disburserv2_Beneficiary = {
  identifier: Scalars["String"]["output"];
  referenceName?: Maybe<Scalars["String"]["output"]>;
  vendorCode: Scalars["String"]["output"];
};

export type Disburserv2_BeneficiaryInput = {
  identifier: Scalars["String"]["input"];
  referenceName?: InputMaybe<Scalars["String"]["input"]>;
  vendorCode: Scalars["String"]["input"];
};

export type Disburserv2_Disbursement = {
  amount_value: Scalars["Float"]["output"];
  beneficiary: Disburserv2_Beneficiary;
  code: Scalars["String"]["output"];
  createdDate: Scalars["Instant"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  status: Disburserv2_Disbursement_StatusType;
  statusSuccessExternalRef?: Maybe<Scalars["String"]["output"]>;
  statusSuccessHandlerCode?: Maybe<Scalars["String"]["output"]>;
  statusSuccessHandlerParams?: Maybe<Scalars["JSON"]["output"]>;
  statusSuccessTime?: Maybe<Scalars["Instant"]["output"]>;
};

/** A connection to a list of items. */
export type Disburserv2_DisbursementConnection = {
  /** a list of edges */
  edges: Array<Disburserv2_DisbursementEdge>;
  /** a list of nodes */
  nodes: Array<Disburserv2_Disbursement>;
  /** details about this specific page */
  pageInfo: PageInfo;
  /** total number of node */
  total: Scalars["Long"]["output"];
};

/** An edge in a connection */
export type Disburserv2_DisbursementEdge = {
  /** cursor marks a unique position or index into the connection */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: Disburserv2_Disbursement;
};

export type Disburserv2_DisbursementMutationResolver_CloseDisbursementPayloadInput =
  {
    reason?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Disburserv2_DisbursementMutationResolver_FailDisbursementPayloadInput =
  {
    code: Scalars["String"]["input"];
    message: Scalars["String"]["input"];
    retryable: Scalars["Boolean"]["input"];
  };

export type Disburserv2_DisbursementMutationResolver_LockDisbursementResponse =
  {
    disbursements: Array<Disburserv2_Disbursement>;
  };

export type Disburserv2_DisbursementMutationResolver_RetryFailedDisbursementPayloadInput =
  {
    beneficiary?: InputMaybe<Disburserv2_BeneficiaryInput>;
  };

export type Disburserv2_DisbursementMutationResolver_SucceedDisbursementPayloadInput =
  {
    externalRef: Scalars["String"]["input"];
    handlerCode: Scalars["String"]["input"];
    handlerParams?: InputMaybe<Scalars["JSON"]["input"]>;
    time?: InputMaybe<Scalars["Instant"]["input"]>;
  };

export type Disburserv2_DisbursementQuery = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<Disburserv2_DisbursementQuery_FilterInput>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Long"]["input"]>;
  orderBy?: InputMaybe<Disburserv2_DisbursementQuery_Orderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Disburserv2_DisbursementQuery_FilterInput = {
  code?: InputMaybe<StringFilterInput>;
  source?: InputMaybe<StringFilterInput>;
  statusSuccessHandlerCode?: InputMaybe<StringFilterInput>;
  statusSuccessTime?: InputMaybe<InstantFilterInput>;
  statusType?: InputMaybe<Disburserv2_DisbursementQuery_StatusTypeFilterInput>;
};

export enum Disburserv2_DisbursementQuery_Orderable {
  Code = "Code",
  CreatedDate = "CreatedDate",
  Id = "Id",
  LastModifiedDate = "LastModifiedDate",
}

export type Disburserv2_DisbursementQuery_StatusTypeFilterInput = {
  and?: InputMaybe<Array<Disburserv2_DisbursementQuery_StatusTypeFilterInput>>;
  eq?: InputMaybe<Disburserv2_Disbursement_StatusType>;
  in?: InputMaybe<Array<Disburserv2_Disbursement_StatusType>>;
  not?: InputMaybe<Disburserv2_DisbursementQuery_StatusTypeFilterInput>;
  or?: InputMaybe<Array<Disburserv2_DisbursementQuery_StatusTypeFilterInput>>;
};

export enum Disburserv2_Disbursement_StatusType {
  Closed = "Closed",
  Failure = "Failure",
  Locked = "Locked",
  New = "New",
  Success = "Success",
}

export type Disburserv2_CloseDisbursementInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  payload: Disburserv2_DisbursementMutationResolver_CloseDisbursementPayloadInput;
};

export type Disburserv2_CloseDisbursementPayload = {
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  disbursement: Disburserv2_Disbursement;
};

export type Disburserv2_DeleteDisbursementInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type Disburserv2_DeleteDisbursementPayload = {
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  unit: Scalars["Void"]["output"];
};

export type Disburserv2_FailDisbursementInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  payload: Disburserv2_DisbursementMutationResolver_FailDisbursementPayloadInput;
};

export type Disburserv2_FailDisbursementPayload = {
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  disbursement: Disburserv2_Disbursement;
};

export type Disburserv2_LockDisbursementsInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  source: Scalars["String"]["input"];
};

export type Disburserv2_LockDisbursementsPayload = {
  DisbursementMutationResolver_lockDisbursementResponse: Disburserv2_DisbursementMutationResolver_LockDisbursementResponse;
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
};

export type Disburserv2_RetryFailedDisbursementInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  payload: Disburserv2_DisbursementMutationResolver_RetryFailedDisbursementPayloadInput;
};

export type Disburserv2_RetryFailedDisbursementPayload = {
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  disbursement: Disburserv2_Disbursement;
};

export type Disburserv2_SucceedDisbursementInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  payload: Disburserv2_DisbursementMutationResolver_SucceedDisbursementPayloadInput;
};

export type Disburserv2_SucceedDisbursementPayload = {
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  disbursement: Disburserv2_Disbursement;
};

export type Disburserv2_UnlockDisbursementInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type Disburserv2_UnlockDisbursementPayload = {
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  disbursement: Disburserv2_Disbursement;
};

export type EarningUserInfo = {
  fullName?: Maybe<Scalars["String"]["output"]>;
  idNumber?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
};

export type Earning_ProductEarningConnection = {
  limit?: Maybe<Scalars["Int"]["output"]>;
  nodes?: Maybe<Array<ProductEarning>>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type EmploymentSegmentConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<EmploymentSegmentPreviewResponse>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Ewa_CreateLoanFeeConfigPayload = {
  code: Scalars["String"]["input"];
  employerFee: Ewa_LoanFeeConfigItemPayload;
  name: Scalars["String"]["input"];
  tenantId: Scalars["ID"]["input"];
  userFee: Ewa_LoanFeeConfigItemPayload;
};

export type Ewa_CreateLoanFeeConfigRulePayload = {
  code: Scalars["String"]["input"];
  configId: Scalars["ID"]["input"];
  employeeSelector: Scalars["String"]["input"];
  priority: Scalars["Int"]["input"];
  tenantId: Scalars["ID"]["input"];
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type Ewa_CreatePromotionPayload = {
  code: Scalars["String"]["input"];
  companyId?: InputMaybe<Scalars["ID"]["input"]>;
  endDate: Scalars["Instant"]["input"];
  parameter?: InputMaybe<Scalars["JSON"]["input"]>;
  qualifier: Scalars["String"]["input"];
  startDate: Scalars["Instant"]["input"];
  status: Ewa_PromotionStatus;
  tenantId: Scalars["ID"]["input"];
};

export type Ewa_CreatePromotionResponse = {
  ewa_promotion: Ewa_Promotion;
};

export enum Ewa_FeeChargeStrategy {
  PostCharge = "POST_CHARGE",
  PreCharge = "PRE_CHARGE",
}

export type Ewa_LoanFeeConfig = {
  code: Scalars["String"]["output"];
  createdDate: Scalars["Instant"]["output"];
  employerFee: Ewa_LoanFeeConfigItem;
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  userFee: Ewa_LoanFeeConfigItem;
};

export type Ewa_LoanFeeConfigConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Ewa_LoanFeeConfig>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Ewa_LoanFeeConfigItem = {
  feeChargeStrategy: Ewa_FeeChargeStrategy;
  fixedFee: Scalars["Money"]["output"];
  minimumFee: Scalars["Money"]["output"];
  percentage: Scalars["Decimal"]["output"];
};

export type Ewa_LoanFeeConfigItemPayload = {
  feeChargeStrategy?: InputMaybe<Ewa_FeeChargeStrategy>;
  fixedFee: Scalars["Money"]["input"];
  minimumFee: Scalars["Money"]["input"];
  percentageFee: Scalars["Decimal"]["input"];
};

export enum Ewa_LoanFeeConfigOrderable {
  Code = "code",
  Id = "id",
}

export type Ewa_LoanFeeConfigQuery = {
  filter?: InputMaybe<Ewa_LoanFeeConfigQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Ewa_LoanFeeConfigOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Ewa_LoanFeeConfigQueryFilter = {
  codes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * input org_admin_CreateDepartmentPayload {
 *     tenantId: ID!
 *     code: String!
 *     name: String!
 *     status: DepartmentStatus!
 * }
 *
 * extend type Mutation {
 *     org_admin_createDepartment(payload: org_admin_CreateDepartmentPayload!): MutateDepartmentResponse!
 * }
 *
 * input org_admin_UpdateDepartmentPayload {
 *     name: String
 *     status: DepartmentStatus
 * }
 *
 * extend type Mutation {
 *     org_admin_updateDepartment(id: ID!, payload: org_admin_UpdateDepartmentPayload!): MutateDepartmentResponse!
 * }
 */
export type Ewa_LoanFeeConfigRule = {
  code: Scalars["String"]["output"];
  config: Ewa_LoanFeeConfig;
  createdDate: Scalars["Instant"]["output"];
  employeeSelector: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  priority: Scalars["Int"]["output"];
  type?: Maybe<Scalars["String"]["output"]>;
};

export type Ewa_LoanFeeConfigRuleConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Ewa_LoanFeeConfigRule>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export enum Ewa_LoanFeeConfigRuleOrderable {
  Code = "code",
  Id = "id",
}

export type Ewa_LoanFeeConfigRuleQuery = {
  filter?: InputMaybe<Ewa_LoanFeeConfigRuleQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Ewa_LoanFeeConfigRuleOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Ewa_LoanFeeConfigRuleQueryFilter = {
  codes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export type Ewa_MutateLoanFeeConfigResponse = {
  ewa_loanFeeConfig: Ewa_LoanFeeConfig;
};

export type Ewa_MutateLoanFeeConfigRuleResponse = {
  ewa_loanFeeConfigRule: Ewa_LoanFeeConfigRule;
};

export type Ewa_Promotion = {
  code: Scalars["String"]["output"];
  company?: Maybe<Company>;
  createdDate: Scalars["Instant"]["output"];
  endDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  parameter?: Maybe<Scalars["JSON"]["output"]>;
  qualifier: Scalars["String"]["output"];
  startDate: Scalars["Instant"]["output"];
  status: Ewa_PromotionStatus;
};

export type Ewa_PromotionConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Ewa_Promotion>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Ewa_PromotionIdFilter = {
  equalTo?: InputMaybe<Scalars["ID"]["input"]>;
  in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  notEqualTo?: InputMaybe<Scalars["ID"]["input"]>;
  notIn?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export enum Ewa_PromotionOrderable {
  Code = "code",
  CreatedDate = "createdDate",
  EndDate = "endDate",
  Id = "id",
  LastModifiedDate = "lastModifiedDate",
  StartDate = "startDate",
}

export type Ewa_PromotionQuery = {
  filter?: InputMaybe<Ewa_PromotionQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Ewa_PromotionOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Ewa_PromotionQueryFilter = {
  code?: InputMaybe<StringFilter>;
  endTime?: InputMaybe<InstantFilter>;
  id?: InputMaybe<Ewa_PromotionIdFilter>;
  startTime?: InputMaybe<InstantFilter>;
  status?: InputMaybe<Ewa_PromotionStatusFilter>;
  tenantId?: InputMaybe<Core_TenantIdFilter>;
};

export enum Ewa_PromotionStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type Ewa_PromotionStatusFilter = {
  equalTo?: InputMaybe<Ewa_PromotionStatus>;
  in?: InputMaybe<Array<Ewa_PromotionStatus>>;
  notEqualTo?: InputMaybe<Ewa_PromotionStatus>;
  notIn?: InputMaybe<Array<Ewa_PromotionStatus>>;
};

export type Ewa_UpdateLoanFeeConfigPayload = {
  employerFee?: InputMaybe<Ewa_LoanFeeConfigItemPayload>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  userFee?: InputMaybe<Ewa_LoanFeeConfigItemPayload>;
};

export type Ewa_UpdateLoanFeeConfigRulePayload = {
  configId?: InputMaybe<Scalars["ID"]["input"]>;
  employeeSelector?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["Int"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type Ewa_UpdatePromotionPayload = {
  companyId?: InputMaybe<Scalars["ID"]["input"]>;
  endDate?: InputMaybe<Scalars["Instant"]["input"]>;
  parameter?: InputMaybe<Scalars["JSON"]["input"]>;
  qualifier?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Instant"]["input"]>;
  status?: InputMaybe<Ewa_PromotionStatus>;
};

export type Ewa_UpdatePromotionResponse = {
  ewa_promotion: Ewa_Promotion;
};

export type FeedbackAdminConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<FeedbackAdminResponse>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type FileProcessor_SpreadsheetCellLogContext = {
  columnLabel: Scalars["String"]["output"];
  rowLabel: Scalars["String"]["output"];
  sheetLabel: Scalars["String"]["output"];
};

export type FileProcessor_SpreadsheetProcessorPayload = {
  storageObject: StorageObject;
  storageObjectId: Scalars["ID"]["output"];
};

export type FileProcessor_SpreadsheetProcessorPayloadInput = {
  storageObjectId: Scalars["ID"]["input"];
};

export type FileProcessor_SpreadsheetRowLogContext = {
  row: Scalars["Int"]["output"];
  sheetName: Scalars["String"]["output"];
};

export type FileProcessor_Task = {
  config?: Maybe<FileProcessor_Task_OneOfConfig>;
  execution: FileProcessor_TaskExecution;
  executions: FileProcessor_TaskExecutionConnection;
  executorIdentifier: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  storageObject: StorageObject;
};

export type FileProcessor_TaskExecutionArgs = {
  id: Scalars["ID"]["input"];
};

export type FileProcessor_TaskExecutionsArgs = {
  query: FileProcessor_TaskExecutionQueryInput;
};

/** A connection to a list of items. */
export type FileProcessor_TaskConnection = {
  /** a list of edges */
  edges: Array<FileProcessor_TaskEdge>;
  /** a list of nodes */
  nodes: Array<FileProcessor_Task>;
  /** details about this specific page */
  pageInfo: PageInfo;
  /** total number of node */
  total: Scalars["Long"]["output"];
};

/** An edge in a connection */
export type FileProcessor_TaskEdge = {
  /** cursor marks a unique position or index into the connection */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: FileProcessor_Task;
};

export type FileProcessor_TaskException = {
  cause?: Maybe<FileProcessor_TaskException>;
  className?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  stackTrace: Array<FileProcessor_TaskException_StackTrace>;
};

export type FileProcessor_TaskException_StackTrace = {
  className?: Maybe<Scalars["String"]["output"]>;
  fileName?: Maybe<Scalars["String"]["output"]>;
  lineNumber: Scalars["Int"]["output"];
};

export type FileProcessor_TaskExecution = {
  config?: Maybe<FileProcessor_Task_OneOfConfig>;
  executorIdentifier: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  log?: Maybe<FileProcessor_TaskExecutionLog>;
  logs: FileProcessor_TaskExecutionLogConnection;
  storageObject: StorageObject;
};

export type FileProcessor_TaskExecutionLogArgs = {
  id: Scalars["ID"]["input"];
};

export type FileProcessor_TaskExecutionLogsArgs = {
  query: FileProcessor_TaskExecutionLogQueryInput;
};

/** A connection to a list of items. */
export type FileProcessor_TaskExecutionConnection = {
  /** a list of edges */
  edges: Array<FileProcessor_TaskExecutionEdge>;
  /** a list of nodes */
  nodes: Array<FileProcessor_TaskExecution>;
  /** details about this specific page */
  pageInfo: PageInfo;
  /** total number of node */
  total: Scalars["Long"]["output"];
};

/** An edge in a connection */
export type FileProcessor_TaskExecutionEdge = {
  /** cursor marks a unique position or index into the connection */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: FileProcessor_TaskExecution;
};

export type FileProcessor_TaskExecutionLog = {
  content: Scalars["String"]["output"];
  context?: Maybe<FileProcessor_TaskExecutionLog_OneOfContext>;
  exception?: Maybe<FileProcessor_TaskException>;
  id: Scalars["ID"]["output"];
  level: FileProcessor_TaskExecutionLog_Level;
};

/** A connection to a list of items. */
export type FileProcessor_TaskExecutionLogConnection = {
  /** a list of edges */
  edges: Array<FileProcessor_TaskExecutionLogEdge>;
  /** a list of nodes */
  nodes: Array<FileProcessor_TaskExecutionLog>;
  /** details about this specific page */
  pageInfo: PageInfo;
  /** total number of node */
  total: Scalars["Long"]["output"];
};

/** An edge in a connection */
export type FileProcessor_TaskExecutionLogEdge = {
  /** cursor marks a unique position or index into the connection */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: FileProcessor_TaskExecutionLog;
};

export type FileProcessor_TaskExecutionLogQueryInput = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<FileProcessor_TaskExecutionLogQuery_FilterInput>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Long"]["input"]>;
  orderBy?: InputMaybe<FileProcessor_TaskExecutionLogQuery_Orderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type FileProcessor_TaskExecutionLogQuery_FilterInput = {
  id?: InputMaybe<IdFilterInput>;
  tenantId?: InputMaybe<IdFilterInput>;
};

export enum FileProcessor_TaskExecutionLogQuery_Orderable {
  CreatedDate = "createdDate",
  Id = "id",
  LastModifiedDate = "lastModifiedDate",
}

export enum FileProcessor_TaskExecutionLog_Level {
  Debug = "DEBUG",
  Error = "ERROR",
  Fatal = "FATAL",
  Info = "INFO",
  Trace = "TRACE",
  Warn = "WARN",
}

export type FileProcessor_TaskExecutionLog_OneOfContext = {
  spreadsheetCell?: Maybe<FileProcessor_SpreadsheetCellLogContext>;
  spreadsheetRow?: Maybe<FileProcessor_SpreadsheetRowLogContext>;
};

export type FileProcessor_TaskExecutionQueryInput = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<FileProcessor_TaskExecutionQuery_FilterInput>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Long"]["input"]>;
  orderBy?: InputMaybe<FileProcessor_TaskExecutionQuery_Orderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type FileProcessor_TaskExecutionQuery_FilterInput = {
  id?: InputMaybe<IdFilterInput>;
  status?: InputMaybe<FileProcessor_TaskExecutionQuery_StatusFilterInput>;
  tenantId?: InputMaybe<IdFilterInput>;
};

export enum FileProcessor_TaskExecutionQuery_Orderable {
  CreatedDate = "createdDate",
  Id = "id",
  LastModifiedDate = "lastModifiedDate",
}

export type FileProcessor_TaskExecutionQuery_StatusFilterInput = {
  and?: InputMaybe<Array<FileProcessor_TaskExecutionQuery_StatusFilterInput>>;
  eq?: InputMaybe<FileProcessor_TaskExecution_Status>;
  in?: InputMaybe<Array<FileProcessor_TaskExecution_Status>>;
  not?: InputMaybe<FileProcessor_TaskExecutionQuery_StatusFilterInput>;
  or?: InputMaybe<Array<FileProcessor_TaskExecutionQuery_StatusFilterInput>>;
};

export type FileProcessor_TaskExecution_InitCommandInput = {
  task: Scalars["ID"]["input"];
};

export enum FileProcessor_TaskExecution_Status {
  Executing = "Executing",
  Failed = "Failed",
  Pending = "Pending",
  Succeeded = "Succeeded",
}

export type FileProcessor_TaskQueryInput = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<FileProcessor_TaskQuery_FilterInput>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Long"]["input"]>;
  orderBy?: InputMaybe<FileProcessor_TaskQuery_Orderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type FileProcessor_TaskQuery_FilterInput = {
  id?: InputMaybe<IdFilterInput>;
  tenantId?: InputMaybe<IdFilterInput>;
};

export enum FileProcessor_TaskQuery_Orderable {
  CreatedDate = "createdDate",
  Id = "id",
  LastModifiedDate = "lastModifiedDate",
}

export type FileProcessor_Task_InitCommandInput = {
  config?: InputMaybe<FileProcessor_Task_OneOfConfigInput>;
  executorIdentifier: Scalars["String"]["input"];
  storageObjectId: Scalars["ID"]["input"];
};

export type FileProcessor_Task_OneOfConfig = {
  spreadsheetProcessor?: Maybe<FileProcessor_SpreadsheetProcessorPayload>;
};

export type FileProcessor_Task_OneOfConfigInput = {
  spreadsheetProcessor?: InputMaybe<FileProcessor_SpreadsheetProcessorPayloadInput>;
};

export type FileProcessor_CreateTaskExecutionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  command: FileProcessor_TaskExecution_InitCommandInput;
};

export type FileProcessor_CreateTaskExecutionPayload = {
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  taskExecution: FileProcessor_TaskExecution;
};

export type FileProcessor_CreateTaskInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  command: FileProcessor_Task_InitCommandInput;
};

export type FileProcessor_CreateTaskPayload = {
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  task: FileProcessor_Task;
};

export type Haseca_ProcessAttendanceFilePayload = {
  groupCode?: InputMaybe<Scalars["String"]["input"]>;
  storageObjectId: Scalars["ID"]["input"];
};

export type Haseca_ProcessEmploymentFilePayload = {
  groupCode?: InputMaybe<Scalars["String"]["input"]>;
  storageObjectId: Scalars["ID"]["input"];
};

export type Home_ActionData = {
  actionData?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
};

export type InstallmentLoanAdminConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<InstallmentLoanAdminResponse>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
  totalAmount: Scalars["Money"]["output"];
  totalFeeAmount: Scalars["Money"]["output"];
  totalLateFeeAmount: Scalars["Money"]["output"];
};

export type InstallmentLoanConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<InstallmentLoanResponse>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Integration_EmploymentStandardizedData = {
  key: Scalars["String"]["output"];
  originalValue?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type Invitation_Popup = {
  id: Scalars["ID"]["output"];
  reward: Invitation_Popup_GenericReward;
  status: Invitation_PopupStatus;
  title?: Maybe<Scalars["String"]["output"]>;
  type: Invitation_PopupType;
};

export type Invitation_PopupConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Invitation_Popup>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Invitation_PopupFilter = {
  statuses?: InputMaybe<Array<Invitation_PopupStatus>>;
};

export enum Invitation_PopupOrderable {
  Id = "id",
}

export type Invitation_PopupQuery = {
  filter?: InputMaybe<Invitation_PopupFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Invitation_PopupOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export enum Invitation_PopupStatus {
  Read = "Read",
  Unread = "Unread",
}

export enum Invitation_PopupType {
  Cashback = "Cashback",
  Voucher = "Voucher",
}

export type Invitation_Popup_GenericReward = {
  transaction?: Maybe<CashbackTransaction>;
  voucher?: Maybe<Voucher>;
};

export type KycUserInfo = {
  bankAccountNumber?: Maybe<Scalars["String"]["output"]>;
  bankName?: Maybe<Scalars["String"]["output"]>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  idNumber?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
};

export enum KycVas_Status {
  InProgress = "InProgress",
  Legacy = "Legacy",
  NeedKyc = "NeedKYC",
  ReadyToUse = "ReadyToUse",
  Unknown = "Unknown",
  WaitingForApproval = "WaitingForApproval",
}

export type Kyc_IdentifierData = {
  backCard: StorageObject;
  frontCard: StorageObject;
};

export type Kyc_RelativeContacts = {
  name: Scalars["String"]["output"];
  phone: Scalars["PhoneNumber"]["output"];
  relation: Scalars["String"]["output"];
};

export type Kyc_Selfie = {
  selfies: Array<StorageObject>;
};

export type Kyc_UserInfoData = {
  bankAccountNumber: Scalars["String"]["output"];
  bankName: Scalars["String"]["output"];
  fullName: Scalars["String"]["output"];
  idNumber: Scalars["String"]["output"];
  phoneNumber: Scalars["PhoneNumber"]["output"];
};

export type Lanchi_AuditLogProcessAttendanceFile = {
  storageObject?: Maybe<StorageObject>;
};

export type Lanchi_ProcessAttendanceFilePayload = {
  storageObjectId: Scalars["ID"]["input"];
};

export type Lanchi_ProcessEmploymentFilePayload = {
  storageObjectId: Scalars["ID"]["input"];
};

export type Lanchi_ProcessError = {
  error: ApplicationException;
  line: Scalars["Int"]["output"];
};

export type Lanchi_ProcessResponse = {
  errors: Array<Lanchi_ProcessError>;
};

export type LogConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Logs>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Logivan_LinkEmploymentPayload = {
  companyId: Scalars["ID"]["input"];
  employeeId: Scalars["String"]["input"];
  socialId: Scalars["String"]["input"];
};

export type Logs = {
  createdDate?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  log?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["Int"]["output"]>;
};

export type Makecheck_ItemDecision = {
  applyDate?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  reason?: InputMaybe<Scalars["String"]["input"]>;
  status: Makecheck_MakeCheckStatus;
};

export type Makecheck_MakeCheckDecisionPayload = {
  data?: InputMaybe<Array<InputMaybe<Makecheck_ItemDecision>>>;
  decisionedBy?: InputMaybe<Scalars["String"]["input"]>;
};

export type Makecheck_MakeCheckNotificationEmailPayload = {
  bccAddress?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  ccAddress?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  toAddress?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Makecheck_MakeCheckNotificationProfile = {
  data?: Maybe<Scalars["JSON"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  tenantId?: Maybe<Scalars["ID"]["output"]>;
};

export type Makecheck_MakeCheckRequestDetail = {
  ewa?: Maybe<Makecheck_MakeCheckRequestDetailEwa>;
  payroll?: Maybe<Makecheck_MakeCheckRequestDetailPayroll>;
};

export type Makecheck_MakeCheckRequestDetailEwa = {
  numberOfEmployment?: Maybe<Scalars["Int"]["output"]>;
  totalBalance?: Maybe<Scalars["Money"]["output"]>;
};

export type Makecheck_MakeCheckRequestDetailPayroll = {
  numberOfEmployment?: Maybe<Scalars["Int"]["output"]>;
  numberOfEmploymentHasWithdrawAble?: Maybe<Scalars["Int"]["output"]>;
  numberOfInactiveEmployment?: Maybe<Scalars["Int"]["output"]>;
  totalEarnBalance?: Maybe<Scalars["Money"]["output"]>;
  totalEarnBalanceExpected?: Maybe<Scalars["Money"]["output"]>;
  totalOfEstimationFinish?: Maybe<Scalars["Money"]["output"]>;
};

export enum Makecheck_MakeCheckStatus {
  Approved = "Approved",
  Cancel = "Cancel",
  Finished = "Finished",
  Pending = "Pending",
  Rejected = "Rejected",
}

export type Makecheck_MakeCheckTenantDetail = {
  numberOfFinishEmployment?: Maybe<Scalars["Int"]["output"]>;
  numberOfPendingEmployment?: Maybe<Scalars["Int"]["output"]>;
  numberOfPendingFile?: Maybe<Scalars["Int"]["output"]>;
  tenantLoanCap?: Maybe<Scalars["Money"]["output"]>;
  totalOfFinishEarnBalance?: Maybe<Scalars["Money"]["output"]>;
  totalPendingEarnBalance?: Maybe<Scalars["Money"]["output"]>;
};

export type Makecheck_TenantPlugin = {
  tenantLoanCap?: Maybe<Scalars["Money"]["output"]>;
  validDayOfMonthFrom?: Maybe<Scalars["Int"]["output"]>;
  validDayOfMonthTo?: Maybe<Scalars["Int"]["output"]>;
};

export type MessageBatch_ActionData = {
  external?: Maybe<Scalars["String"]["output"]>;
  inAppBrowser?: Maybe<Scalars["String"]["output"]>;
  internal?: Maybe<Scalars["String"]["output"]>;
  noop?: Maybe<Scalars["String"]["output"]>;
  webview?: Maybe<Scalars["String"]["output"]>;
};

export type MessageBatch_ActionDataInput = {
  external?: InputMaybe<Scalars["String"]["input"]>;
  inAppBrowser?: InputMaybe<Scalars["String"]["input"]>;
  internal?: InputMaybe<Scalars["String"]["input"]>;
  noop?: InputMaybe<Scalars["String"]["input"]>;
  webview?: InputMaybe<Scalars["String"]["input"]>;
};

export enum MessageBatch_ActionType {
  External = "external",
  InAppBrowser = "inAppBrowser",
  Internal = "internal",
  Noop = "noop",
  Webview = "webview",
}

export type MessageBatch_CreateMessageBatchPayload = {
  actionData: MessageBatch_ActionDataInput;
  actionType: MessageBatch_ActionType;
  content: Scalars["String"]["input"];
  employeeSelector: Scalars["String"]["input"];
  hasInAppMessage: Scalars["Boolean"]["input"];
  hasPushNotification: Scalars["Boolean"]["input"];
  messageThrottleLimits?: InputMaybe<Scalars["Int"]["input"]>;
  notificationCategory?: InputMaybe<Scalars["String"]["input"]>;
  scheduleDate: Scalars["Instant"]["input"];
  silent: Scalars["Boolean"]["input"];
  status: MessageBatch_Status;
  tenantIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  title: Scalars["String"]["input"];
};

export type MessageBatch_MessageBatch = {
  actionData: MessageBatch_ActionData;
  actionType: MessageBatch_ActionType;
  content: Scalars["String"]["output"];
  createdDate: Scalars["Instant"]["output"];
  employeeSelector: Scalars["String"]["output"];
  hasInAppMessage: Scalars["Boolean"]["output"];
  hasPushNotification: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  messageThrottleLimits?: Maybe<Scalars["Int"]["output"]>;
  notificationCategory?: Maybe<Scalars["String"]["output"]>;
  scheduleDate: Scalars["Instant"]["output"];
  silent: Scalars["Boolean"]["output"];
  status: MessageBatch_Status;
  tenantIds?: Maybe<Array<Scalars["ID"]["output"]>>;
  tenants?: Maybe<Array<Core_Tenant>>;
  title: Scalars["String"]["output"];
  totalMatchingUsers?: Maybe<Scalars["Int"]["output"]>;
};

export type MessageBatch_MessageBatchConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<MessageBatch_MessageBatch>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type MessageBatch_MessageBatchFilter = {
  createdDate?: InputMaybe<OffsetDateTimeRange>;
  hasInAppMessage?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasPushNotification?: InputMaybe<Scalars["Boolean"]["input"]>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  notificationCategory?: InputMaybe<Scalars["String"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  scheduleDate?: InputMaybe<OffsetDateTimeRange>;
  silent?: InputMaybe<Scalars["Boolean"]["input"]>;
  statuses?: InputMaybe<Array<MessageBatch_Status>>;
};

export type MessageBatch_MessageBatchQuery = {
  filter?: InputMaybe<MessageBatch_MessageBatchFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<MessageBatch_Orderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type MessageBatch_MessageBatchResponse = {
  messageBatch?: Maybe<MessageBatch_MessageBatch>;
};

export type MessageBatch_MessageData = {
  actionData: MessageBatch_ActionData;
  actionType: MessageBatch_ActionType;
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  type: Scalars["String"]["output"];
};

export enum MessageBatch_Orderable {
  CreatedDate = "createdDate",
  Id = "id",
}

export enum MessageBatch_Status {
  Active = "Active",
  Completed = "Completed",
  Inactive = "Inactive",
  Initialized = "Initialized",
  LockForCreateMessage = "LockForCreateMessage",
}

export type MessageBatch_UpdateMessageBatchPayload = {
  actionData?: InputMaybe<MessageBatch_ActionDataInput>;
  actionType?: InputMaybe<MessageBatch_ActionType>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  employeeSelector?: InputMaybe<Scalars["String"]["input"]>;
  hasInAppMessage?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasPushNotification?: InputMaybe<Scalars["Boolean"]["input"]>;
  messageThrottleLimits?: InputMaybe<Scalars["Int"]["input"]>;
  notificationCategory?: InputMaybe<Scalars["String"]["input"]>;
  scheduleDate?: InputMaybe<Scalars["Instant"]["input"]>;
  silent?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<MessageBatch_Status>;
  tenantIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MessageBatch_CalculateMatchingUsersPayload = {
  employeeSelector?: InputMaybe<Scalars["String"]["input"]>;
  tenantIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type Nguyenkim_LinkEmploymentPayload = {
  employeeId: Scalars["String"]["input"];
  fullName: Scalars["String"]["input"];
  socialIdNumber: Scalars["String"]["input"];
};

export type Operation_EmploymentMigratePayload = {
  currentEmployeeId?: InputMaybe<Scalars["String"]["input"]>;
  employmentId?: InputMaybe<Scalars["ID"]["input"]>;
  newEmployeeId: Scalars["String"]["input"];
};

export type Org_CreateBankProviderTxnPayload = {
  account: Scalars["String"]["input"];
  balance: Scalars["Money"]["input"];
  bankProvider: Scalars["String"]["input"];
};

export type Org_CreateWorkSchedulePayload = {
  code: Scalars["String"]["input"];
  startTime: Scalars["Instant"]["input"];
  tenantId: Scalars["ID"]["input"];
  type: Scalars["String"]["input"];
};

export type Org_CreateWorkScheduleRulePayload = {
  code: Scalars["String"]["input"];
  employeeSelector: Scalars["String"]["input"];
  priority: Scalars["Int"]["input"];
  scheduleId: Scalars["ID"]["input"];
  tenantId: Scalars["ID"]["input"];
};

export type Org_MutateWorkScheduleResponse = {
  org_workSchedule: Org_WorkSchedule;
};

export type Org_MutateWorkScheduleRuleResponse = {
  org_workScheduleRule: Org_WorkScheduleRule;
};

export type Org_UpdateWorkSchedulePayload = {
  startTime?: InputMaybe<Scalars["Instant"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type Org_UpdateWorkScheduleRulePayload = {
  employeeSelector?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Org_WorkPeriod = {
  advanceEndDate: Scalars["Instant"]["output"];
  advanceStartDate: Scalars["Instant"]["output"];
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  status: Org_WorkPeriodStatus;
};

export type Org_WorkPeriodConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Org_WorkPeriod>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export enum Org_WorkPeriodOrderable {
  Id = "id",
}

export type Org_WorkPeriodQuery = {
  filter?: InputMaybe<Org_WorkPeriodQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Org_WorkPeriodOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Org_WorkPeriodQueryFilter = {
  status?: InputMaybe<Org_WorkPeriodStatusFilter>;
};

export enum Org_WorkPeriodStatus {
  Closed = "Closed",
  Initialized = "Initialized",
}

export type Org_WorkPeriodStatusFilter = {
  equalTo?: InputMaybe<Org_WorkPeriodStatus>;
  in?: InputMaybe<Array<Org_WorkPeriodStatus>>;
  notEqualTo?: InputMaybe<Org_WorkPeriodStatus>;
  notIn?: InputMaybe<Array<Org_WorkPeriodStatus>>;
};

export type Org_WorkSchedule = {
  code: Scalars["String"]["output"];
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  org_workPeriod: Org_WorkPeriod;
  org_workPeriods: Org_WorkPeriodConnection;
};

export type Org_WorkScheduleOrg_WorkPeriodArgs = {
  id: Scalars["ID"]["input"];
};

export type Org_WorkScheduleOrg_WorkPeriodsArgs = {
  query?: InputMaybe<Org_WorkPeriodQuery>;
};

export type Org_WorkScheduleConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Org_WorkSchedule>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export enum Org_WorkScheduleOrderable {
  Code = "code",
  Id = "id",
}

export type Org_WorkScheduleQuery = {
  filter?: InputMaybe<Org_WorkScheduleQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Org_WorkScheduleOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Org_WorkScheduleQueryFilter = {
  codes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export type Org_WorkScheduleRule = {
  code: Scalars["String"]["output"];
  createdDate: Scalars["Instant"]["output"];
  employeeSelector: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  priority: Scalars["Int"]["output"];
  schedule: Org_WorkSchedule;
};

export type Org_WorkScheduleRuleConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Org_WorkScheduleRule>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export enum Org_WorkScheduleRuleOrderable {
  Code = "code",
  Id = "id",
}

export type Org_WorkScheduleRuleQuery = {
  filter?: InputMaybe<Org_WorkScheduleRuleQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Org_WorkScheduleRuleOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Org_WorkScheduleRuleQueryFilter = {
  codes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export type Org_Admin_CompanyBillingInfoPayload = {
  l0MaintenanceFeeConfig?: InputMaybe<Org_Admin_InvoiceMaintenanceFeeConfig>;
  l1MaintenanceFeeConfig?: InputMaybe<Org_Admin_InvoiceMaintenanceFeeConfig>;
  l2MaintenanceFeeConfig?: InputMaybe<Org_Admin_InvoiceMaintenanceFeeConfig>;
  l3MaintenanceFeeConfig?: InputMaybe<Org_Admin_InvoiceMaintenanceFeeConfig>;
  latePenaltyFeeRate: Scalars["Float"]["input"];
  subscriptionAmount: Scalars["Money"]["input"];
};

export type Org_Admin_CreateCompany = {
  code: Scalars["String"]["input"];
  contractCompanyAddress?: InputMaybe<Scalars["String"]["input"]>;
  contractCompanyName?: InputMaybe<Scalars["String"]["input"]>;
  contractCompanyTaxCode?: InputMaybe<Scalars["String"]["input"]>;
  contractNo?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  logoId?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  negativeLogoId?: InputMaybe<Scalars["ID"]["input"]>;
  paymentDayInMonth?: InputMaybe<Scalars["Int"]["input"]>;
  paymentMonthAfterCutoff?: InputMaybe<Scalars["Int"]["input"]>;
  reportReceiverEmails?: InputMaybe<Array<Scalars["String"]["input"]>>;
  shortName: Scalars["String"]["input"];
  tenantId: Scalars["ID"]["input"];
};

export type Org_Admin_InvoiceMaintenanceFeeConfig = {
  maintenanceFeeRate?: InputMaybe<Scalars["Float"]["input"]>;
  maintenanceOverdueDate?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Org_Admin_UpdateCompany = {
  contractCompanyAddress?: InputMaybe<Scalars["String"]["input"]>;
  contractCompanyName?: InputMaybe<Scalars["String"]["input"]>;
  contractCompanyTaxCode?: InputMaybe<Scalars["String"]["input"]>;
  contractNo?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  logoId?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  negativeLogoId?: InputMaybe<Scalars["ID"]["input"]>;
  paymentDayInMonth?: InputMaybe<Scalars["Int"]["input"]>;
  paymentMonthAfterCutoff?: InputMaybe<Scalars["Int"]["input"]>;
  reportReceiverEmails?: InputMaybe<Array<Scalars["String"]["input"]>>;
  shortName?: InputMaybe<Scalars["String"]["input"]>;
};

export type PayLaterReasonDecline = {
  content: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  title: Scalars["String"]["output"];
};

export type PayLaterReasonDeclineConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<PayLaterReasonDecline>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type PayslipAdminConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<PayslipAdminResponse>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type PayslipPeriodConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<PayslipPeriodResponse>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Pmdashboard_AddContactPayload = {
  contactName: Scalars["String"]["input"];
  contactTitle: Scalars["String"]["input"];
  contactType: Pmdashboard_ContactType;
  email?: InputMaybe<Scalars["Email"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
};

export type Pmdashboard_AttendanceRate = {
  lastAttendanceUpdate?: Maybe<Scalars["Instant"]["output"]>;
  numberOfActiveUser?: Maybe<Scalars["Int"]["output"]>;
  numberOfUser?: Maybe<Scalars["Int"]["output"]>;
  numberOfUserHasAttendance?: Maybe<Scalars["Int"]["output"]>;
  numberOfUserHasBalance?: Maybe<Scalars["Int"]["output"]>;
};

export type Pmdashboard_AttendanceRateByDepartment = {
  departmentCode?: Maybe<Scalars["String"]["output"]>;
  departmentDepartmentName?: Maybe<Scalars["String"]["output"]>;
  lastAttendanceUpdate?: Maybe<Scalars["Instant"]["output"]>;
  numberOfActiveUser?: Maybe<Scalars["Int"]["output"]>;
  numberOfUserHasAttendance?: Maybe<Scalars["Int"]["output"]>;
  numberOfUserHasBalance?: Maybe<Scalars["Int"]["output"]>;
  totalEmployeeInDepartment?: Maybe<Scalars["Int"]["output"]>;
};

export type Pmdashboard_CompanyWorkPeriod = {
  expectedEndDate?: Maybe<Scalars["Instant"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  startDate?: Maybe<Scalars["Instant"]["output"]>;
};

export enum Pmdashboard_ContactType {
  Accountant = "ACCOUNTANT",
  Hr = "HR",
  Manager = "MANAGER",
  Technician = "TECHNICIAN",
}

export type Pmdashboard_DayOfMonthValidatorFactoryConfig = {
  from?: Maybe<Scalars["Int"]["output"]>;
  to?: Maybe<Scalars["Int"]["output"]>;
};

export type Pmdashboard_DepartmentEnableLoanValidatorByFactoryConfig = {
  message?: Maybe<Scalars["String"]["output"]>;
  rules?: Maybe<
    Array<Maybe<Pmdashboard_DepartmentEnableLoanValidatorByFactoryConfigRule>>
  >;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type Pmdashboard_DepartmentEnableLoanValidatorByFactoryConfigRule = {
  departmentCodes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  from?: Maybe<Scalars["Int"]["output"]>;
  to?: Maybe<Scalars["Int"]["output"]>;
};

export type Pmdashboard_NumberRangeFilter = {
  from?: InputMaybe<Scalars["Float"]["input"]>;
  fromInclusive?: InputMaybe<Scalars["Float"]["input"]>;
  to?: InputMaybe<Scalars["Float"]["input"]>;
  toInclusive?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Pmdashboard_PmUpdatePayload = {
  dateOfSendingReconciliationReport?: InputMaybe<Scalars["Int"]["input"]>;
  hrSupport?: InputMaybe<Scalars["String"]["input"]>;
  industryOfTheBusiness?: InputMaybe<Scalars["String"]["input"]>;
  internalCommunications?: InputMaybe<Scalars["String"]["input"]>;
  methodToSynchronizeAttendanceData?: InputMaybe<
    Array<InputMaybe<SynchronizeMethod>>
  >;
  methodToSynchronizeEmployeeData?: InputMaybe<
    Array<InputMaybe<SynchronizeMethod>>
  >;
  note?: InputMaybe<Scalars["String"]["input"]>;
  projectManager?: InputMaybe<Scalars["String"]["input"]>;
  technologyFees?: InputMaybe<Scalars["String"]["input"]>;
  tenantModel?: InputMaybe<TenantModel>;
};

export type Pmdashboard_ReflectLoanFeeConfigItem = {
  employeeFixFee?: Maybe<Scalars["Float"]["output"]>;
  employeePercentageFee?: Maybe<Scalars["Float"]["output"]>;
  employerFixFee?: Maybe<Scalars["Float"]["output"]>;
  employerPercentageFee?: Maybe<Scalars["Float"]["output"]>;
  rules?: Maybe<Array<Maybe<Pmdashboard_ReflectLoanFeeConfigRule>>>;
};

export type Pmdashboard_ReflectLoanFeeConfigRule = {
  amountValidator?: Maybe<Scalars["String"]["output"]>;
  code?: Maybe<Scalars["String"]["output"]>;
  employeeSelector?: Maybe<Scalars["String"]["output"]>;
  priority?: Maybe<Scalars["Int"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type Pmdashboard_ReflectPluginConfig = {
  departmentEnableLoan?: Maybe<
    Array<Maybe<Pmdashboard_DepartmentEnableLoanValidatorByFactoryConfig>>
  >;
  groupDisableLoan?: Maybe<
    Array<Maybe<Pmdashboard_TenantDisableLoanByGroupValidatorFactoryConfig>>
  >;
  validDayOfMonth?: Maybe<
    Array<Maybe<Pmdashboard_DayOfMonthValidatorFactoryConfig>>
  >;
};

export type Pmdashboard_TenantContact = {
  contactName: Scalars["String"]["output"];
  contactTitle: Scalars["String"]["output"];
  contactType: Pmdashboard_ContactType;
  email?: Maybe<Scalars["Email"]["output"]>;
  id: Scalars["ID"]["output"];
  phoneNumber?: Maybe<Scalars["PhoneNumber"]["output"]>;
};

export type Pmdashboard_TenantContactResponse = {
  contact: Pmdashboard_TenantContact;
};

export type Pmdashboard_TenantDisableLoanByGroupValidatorFactoryConfig = {
  message?: Maybe<Scalars["String"]["output"]>;
  rules?: Maybe<
    Array<Maybe<Pmdashboard_TenantDisableLoanByGroupValidatorFactoryConfigRule>>
  >;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type Pmdashboard_TenantDisableLoanByGroupValidatorFactoryConfigRule = {
  from?: Maybe<Scalars["Int"]["output"]>;
  groupCode?: Maybe<Scalars["String"]["output"]>;
  to?: Maybe<Scalars["Int"]["output"]>;
};

export type Pmdashboard_TenantInformation = {
  advancedPeriods?: Maybe<Pmdashboard_ReflectPluginConfig>;
  attendanceRate?: Maybe<Pmdashboard_AttendanceRate>;
  attendanceRateByDepartment?: Maybe<
    Array<Maybe<Pmdashboard_AttendanceRateByDepartment>>
  >;
  attendanceRateInPeriod?: Maybe<Scalars["Float"]["output"]>;
  autolinkEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  avgDisbursedIn90Days?: Maybe<Scalars["Money"]["output"]>;
  billingEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  carrierDataConfigEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  cellularNetwork?: Maybe<Array<Maybe<CellularNetworkItem>>>;
  companyPaymentDayInMonth?: Maybe<Scalars["Int"]["output"]>;
  companyShortName?: Maybe<Scalars["String"]["output"]>;
  contacts: Array<Pmdashboard_TenantContact>;
  createdDate?: Maybe<Scalars["Instant"]["output"]>;
  dailyLimit?: Maybe<Scalars["Money"]["output"]>;
  dateOfSendingReconciliationReport?: Maybe<Scalars["Int"]["output"]>;
  employeesLastUpdatedDate?: Maybe<Scalars["Instant"]["output"]>;
  employeesRegisteredInMonth?: Maybe<Scalars["Int"]["output"]>;
  employeesResignedInMonth?: Maybe<Scalars["Int"]["output"]>;
  feeModel?: Maybe<FeeModel>;
  groups?: Maybe<Array<Maybe<CompanyEmploymentGroup>>>;
  hasWhitelist?: Maybe<Scalars["Boolean"]["output"]>;
  hrSupport?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  industryOfTheBusiness?: Maybe<Scalars["String"]["output"]>;
  internalCommunications?: Maybe<Scalars["String"]["output"]>;
  lastModifiedBy?: Maybe<Scalars["String"]["output"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]["output"]>;
  limitPerAdvance?: Maybe<Scalars["Money"]["output"]>;
  limitPerPeriod?: Maybe<Scalars["Money"]["output"]>;
  limitPercentageSalary?: Maybe<Scalars["Float"]["output"]>;
  loanFeeConfig?: Maybe<Array<Maybe<Pmdashboard_ReflectLoanFeeConfigItem>>>;
  methodToSynchronizeAttendanceData?: Maybe<Array<Maybe<SynchronizeMethod>>>;
  methodToSynchronizeEmployeeData?: Maybe<Array<Maybe<SynchronizeMethod>>>;
  note?: Maybe<Scalars["String"]["output"]>;
  onboardFlow?: Maybe<OnboardingFlow>;
  paylaterEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  payslipV2Enabled?: Maybe<Scalars["Boolean"]["output"]>;
  platforms?: Maybe<Array<Maybe<OnboardCompanyPlatform>>>;
  projectManager?: Maybe<Scalars["String"]["output"]>;
  promotionEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  promotions?: Maybe<Array<Maybe<Ewa_Promotion>>>;
  registrationRate?: Maybe<Scalars["Float"]["output"]>;
  softpinEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  surveyFormEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  surveyShiftMealEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  technologyFees?: Maybe<Scalars["String"]["output"]>;
  tenant?: Maybe<Core_Tenant>;
  tenantCode?: Maybe<Scalars["String"]["output"]>;
  tenantCreatedDate?: Maybe<Scalars["Instant"]["output"]>;
  tenantFunding?: Maybe<TenantFunding>;
  tenantLoanCap?: Maybe<Scalars["Money"]["output"]>;
  tenantModel?: Maybe<TenantModel>;
  tenantStatus?: Maybe<Core_TenantStatus>;
  topUpEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  totalActiveStatusEmployees?: Maybe<Scalars["Int"]["output"]>;
  totalDisbursedInCurrentPeriod?: Maybe<Scalars["Money"]["output"]>;
  totalEmployees?: Maybe<Scalars["Int"]["output"]>;
  totalEmployeesRegistered?: Maybe<Scalars["Int"]["output"]>;
  transferEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  uiInstructionEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  workPeriods?: Maybe<Array<Maybe<Pmdashboard_CompanyWorkPeriod>>>;
};

export type Pmdashboard_TenantInformationConnection = {
  limit?: Maybe<Scalars["Int"]["output"]>;
  nodes?: Maybe<Array<Maybe<Pmdashboard_TenantInformation>>>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type Pmdashboard_TenantInformationFilter = {
  attendanceRateInPeriodRange?: InputMaybe<Pmdashboard_NumberRangeFilter>;
  avgDisbursedIn90DaysRange?: InputMaybe<MoneyRangeFilter>;
  hasWhiteList?: InputMaybe<Scalars["Boolean"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  projectManager?: InputMaybe<Scalars["String"]["input"]>;
  promotionEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  registrationRateRange?: InputMaybe<Pmdashboard_NumberRangeFilter>;
  tenantCodes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  tenantCreatedDateRange?: InputMaybe<OffsetDateTimeRange>;
  tenantFunding?: InputMaybe<Array<InputMaybe<TenantFunding>>>;
  tenantLoanCapRange?: InputMaybe<MoneyRangeFilter>;
  tenantModel?: InputMaybe<Array<InputMaybe<TenantModel>>>;
  tenantShortNames?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  tenantStatus?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  totalDisbursedInCurrentPeriodRange?: InputMaybe<MoneyRangeFilter>;
};

export enum Pmdashboard_TenantInformationOrderable {
  AttendanceRateInPeriod = "AttendanceRateInPeriod",
  AutolinkEnabled = "AutolinkEnabled",
  AvgDisbursedIn90Days = "AvgDisbursedIn90Days",
  BillingEnabled = "BillingEnabled",
  CarrierDataConfigEnabled = "CarrierDataConfigEnabled",
  CompanyPaymentDayInMonth = "CompanyPaymentDayInMonth",
  CompanyShortName = "CompanyShortName",
  DateOfSendingReconciliationReport = "DateOfSendingReconciliationReport",
  EmployeesLastUpdatedDate = "EmployeesLastUpdatedDate",
  EmployeesRegisteredInMonth = "EmployeesRegisteredInMonth",
  EmployeesResignedInMonth = "EmployeesResignedInMonth",
  FeeModel = "FeeModel",
  HasWhitelist = "HasWhitelist",
  HrSupport = "HrSupport",
  IndustryOfTheBusiness = "IndustryOfTheBusiness",
  InternalCommunications = "InternalCommunications",
  MethodToSynchronizeAttendanceData = "MethodToSynchronizeAttendanceData",
  MethodToSynchronizeEmployeeData = "MethodToSynchronizeEmployeeData",
  OnboardFlow = "OnboardFlow",
  PaylaterEnabled = "PaylaterEnabled",
  PayslipV2Enabled = "PayslipV2Enabled",
  Platforms = "Platforms",
  ProjectManager = "ProjectManager",
  RegistrationRate = "RegistrationRate",
  SoftpinEnabled = "SoftpinEnabled",
  SurveyFormEnabled = "SurveyFormEnabled",
  SurveyShiftMealEnabled = "SurveyShiftMealEnabled",
  TechnologyFees = "TechnologyFees",
  TenantCode = "TenantCode",
  TenantCreatedDate = "TenantCreatedDate",
  TenantFunding = "TenantFunding",
  TenantLoanCap = "TenantLoanCap",
  TenantModel = "TenantModel",
  TenantStatus = "TenantStatus",
  TopUpEnabled = "TopUpEnabled",
  TotalActiveStatusEmployees = "TotalActiveStatusEmployees",
  TotalDisbursedInCurrentPeriod = "TotalDisbursedInCurrentPeriod",
  TotalEmployees = "TotalEmployees",
  TotalEmployeesRegistered = "TotalEmployeesRegistered",
  TransferEnabled = "TransferEnabled",
  UiInstructionEnabled = "UiInstructionEnabled",
}

export type Pmdashboard_TenantInformationQuery = {
  filter?: InputMaybe<Pmdashboard_TenantInformationFilter>;
  filters?: InputMaybe<Array<InputMaybe<Pmdashboard_TenantInformationFilter>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Pmdashboard_TenantInformationOrderable>;
  orderDirection?: OrderDirection;
};

export type Prize_Prize = {
  code: Scalars["ID"]["output"];
  createdDate: Scalars["Instant"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  prizeId?: Maybe<Scalars["String"]["output"]>;
  prizeType?: Maybe<Prize_PrizeType>;
  status: Prize_PrizeStatus;
};

export type Prize_PrizeConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Prize_Prize>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Prize_PrizeQuery = {
  filter?: InputMaybe<Prize_PrizeQueryFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Prize_PrizeQueryFilter = {
  campaignCode?: InputMaybe<Scalars["String"]["input"]>;
  createdDate?: InputMaybe<OffsetDateTimeRange>;
  status?: InputMaybe<Array<Prize_PrizeStatus>>;
};

export enum Prize_PrizeStatus {
  Done = "Done",
  Failed = "Failed",
  Inprogress = "Inprogress",
  Pending = "Pending",
  Unknown = "Unknown",
}

export enum Prize_PrizeType {
  NotWinThePrize = "NotWinThePrize",
  Saving = "Saving",
  Voucher = "Voucher",
}

export type ProductContent = {
  conditionsContent?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  image?: Maybe<StorageObject>;
  introduceContent?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  profitsContent?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type Profile = {
  actionDate?: Maybe<Scalars["Instant"]["output"]>;
  companyName?: Maybe<Scalars["String"]["output"]>;
  createdDate?: Maybe<Scalars["Instant"]["output"]>;
  employeeId?: Maybe<Scalars["String"]["output"]>;
  featureName?: Maybe<Scalars["String"]["output"]>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<ProfileStatus>;
};

export type ProfileConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Profile>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Promotions_FinedPromotion = {
  banner?: Maybe<Array<Promotions_FinedPromotion_Banner>>;
  createdDate?: Maybe<Scalars["Instant"]["output"]>;
  cta?: Maybe<Scalars["String"]["output"]>;
  ctaColor?: Maybe<Scalars["String"]["output"]>;
  ctaText?: Maybe<Scalars["String"]["output"]>;
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  shortDescription: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type Promotions_FinedPromotion_Banner = {
  formats: Scalars["JSON"]["output"];
  url: Scalars["String"]["output"];
};

export enum Qr_Position {
  Legacy = "LEGACY",
  New = "NEW",
}

export type ReasonCancel = {
  content: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
};

export type ReasonCancelConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<ReasonCancel>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type ReasonDecline = {
  content?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ReasonDeclineConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<ReasonDecline>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type SavingAutoConfig_Frequent = {
  daysOfMonth?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  daysOfWeek?: InputMaybe<Array<DayOfWeek>>;
};

export type SavingAutoConfig_Frequent_Response = {
  daysOfMonth?: Maybe<Array<Scalars["Int"]["output"]>>;
  daysOfWeek?: Maybe<Array<DayOfWeek>>;
};

export enum SavingAutoConfig_Status {
  Active = "Active",
  Inactive = "Inactive",
}

export type SavingAutoConfig_TopUpAmount = {
  amount?: InputMaybe<Scalars["Money"]["input"]>;
  maxAmountAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SavingAutoConfig_TopUpAmount_Response = {
  amount?: Maybe<Scalars["Money"]["output"]>;
  maxAmountAllowed?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SavingAutoConfig_UpsertPayload = {
  frequent?: InputMaybe<SavingAutoConfig_Frequent>;
  status?: InputMaybe<SavingAutoConfig_Status>;
  topUpAmount: SavingAutoConfig_TopUpAmount;
};

export type Saving_Disbursement = {
  amount: Scalars["Money"]["output"];
  beneficiary: Saving_DisbursementBeneficiary;
  createdDate: Scalars["Instant"]["output"];
  disburser?: Maybe<Saving_DisbursementDisburser>;
  externalTransactionRef?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  status: Saving_DisbursementStatus;
};

/** for type withdraw */
export type Saving_DisbursementBeneficiary = {
  bankAccountBeneficiaryName: Scalars["String"]["output"];
  bankAccountBeneficiaryNumber: Scalars["String"]["output"];
  bankCode: Scalars["String"]["output"];
};

export type Saving_DisbursementDisburser = {
  disburserId: Scalars["String"]["output"];
  disburserType: Scalars["String"]["output"];
};

export enum Saving_DisbursementStatus {
  Closed = "Closed",
  Disbursed = "Disbursed",
  Pending = "Pending",
}

export type Saving_MutateSavingAccountAgreementResponse = {
  agreement: Saving_SavingAgreement;
};

export type Saving_MutateSavingAccountResponse = {
  savingAccount: Saving_SavingAccount;
};

export type Saving_SavingAccount = {
  accumulateInterestFromLatestSwitch: Scalars["Money"]["output"];
  activePolicies: Array<Saving_SavingPolicy>;
  agreement?: Maybe<Saving_SavingAgreement>;
  autoConfig?: Maybe<Saving_SavingAutoConfig>;
  balances: Array<Scalars["Money"]["output"]>;
  id: Scalars["ID"]["output"];
  latestInterestIssueSwitch?: Maybe<Scalars["Instant"]["output"]>;
  openedAt: Scalars["Instant"]["output"];
  owner: Saving_SavingAccountOwner;
  systemSavingConfig: SavingSystemConfig;
  transactions: Saving_SavingTransactionConnection;
};

export type Saving_SavingAccountTransactionsArgs = {
  query?: InputMaybe<Saving_SavingTransactionQuery>;
};

export type Saving_SavingAccountOwner = {
  fullName: Scalars["String"]["output"];
  socialIdDocument: SocialIdDocument;
};

export type Saving_SavingAgreement = {
  closeFeatureHtmlFile?: Maybe<StorageObject>;
  closeFeaturePdfFile?: Maybe<StorageObject>;
  fullName: Scalars["String"]["output"];
  htmlFile: StorageObject;
  id: Scalars["ID"]["output"];
  otp?: Maybe<Scalars["String"]["output"]>;
  pdfFile?: Maybe<StorageObject>;
  shouldShowCloseFeatureBottomSheet: Scalars["Boolean"]["output"];
  socialIdDocument: SocialIdDocument;
  socialIdNumber: Scalars["String"]["output"];
  status: Saving_SavingAgreementStatus;
  toc: Saving_SavingTermAndCondition;
};

export enum Saving_SavingAgreementStatus {
  Agreed = "Agreed",
  Generated = "Generated",
}

export type Saving_SavingAutoConfig = {
  frequent: SavingAutoConfig_Frequent_Response;
  nextExecutionTime?: Maybe<Scalars["Instant"]["output"]>;
  status: SavingAutoConfig_Status;
  topUpAmount: SavingAutoConfig_TopUpAmount_Response;
};

export type Saving_SavingConfig = {
  features?: Maybe<Array<Saving_SavingFeature>>;
  isUsable: Scalars["Boolean"]["output"];
};

export type Saving_SavingFeature = {
  enable: Scalars["Boolean"]["output"];
  key: Saving_SavingFeatureKey;
};

export enum Saving_SavingFeatureKey {
  AutoConfig = "AUTO_CONFIG",
  NoBcc = "NO_BCC",
  ProactivelyTopup = "PROACTIVELY_TOPUP",
}

export type Saving_SavingPolicy = {
  endDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  interestCalculationDaysInYear?: Maybe<Scalars["Int"]["output"]>;
  interestIssuePeriod?: Maybe<Scalars["String"]["output"]>;
  minimumInterestIssueValue: Scalars["Money"]["output"];
  nominalApr?: Maybe<Scalars["Decimal"]["output"]>;
  startDate: Scalars["Instant"]["output"];
};

export type Saving_SavingTermAndCondition = {
  file: StorageObject;
  id: Scalars["ID"]["output"];
  status: Saving_SavingTermAndConditionStatus;
};

export enum Saving_SavingTermAndConditionStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type Saving_SavingTransaction = {
  actualReceive: Scalars["Money"]["output"];
  amount: Scalars["Money"]["output"];
  code: Scalars["String"]["output"];
  createdDate: Scalars["Instant"]["output"];
  disbursement?: Maybe<Saving_Disbursement>;
  fee: Scalars["Money"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  source: Saving_SavingTransactionSource;
  status: Saving_SavingTransactionStatus;
  type: Saving_SavingTransactionType;
};

export type Saving_SavingTransactionConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Saving_SavingTransaction>;
  offset: Scalars["Int"]["output"];
  sumAmounts: Array<Scalars["Money"]["output"]>;
  total: Scalars["Int"]["output"];
};

export type Saving_SavingTransactionFilter = {
  createdDate?: InputMaybe<OffsetDateTimeRange>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sourceTypes?: InputMaybe<Array<Saving_SavingTransactionSourceType>>;
  status?: InputMaybe<Array<Saving_SavingTransactionStatus>>;
  types?: InputMaybe<Array<Saving_SavingTransactionType>>;
};

export enum Saving_SavingTransactionOrderable {
  Id = "id",
}

export type Saving_SavingTransactionQuery = {
  filter?: InputMaybe<Saving_SavingTransactionFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Saving_SavingTransactionOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Saving_SavingTransactionSource = {
  id?: Maybe<Scalars["String"]["output"]>;
  type: Saving_SavingTransactionSourceType;
};

export enum Saving_SavingTransactionSourceType {
  EwaWallet = "EwaWallet",
  Other = "Other",
  SavingWallet = "SavingWallet",
}

export enum Saving_SavingTransactionStatus {
  Failed = "Failed",
  InProgress = "InProgress",
  Pending = "Pending",
  Success = "Success",
  Unknown = "Unknown",
}

export enum Saving_SavingTransactionType {
  InterestIssue = "InterestIssue",
  TopUp = "TopUp",
  Withdraw = "Withdraw",
}

export type SegmentConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<SegmentResponse>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type SetConfigInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  key: Scalars["String"]["input"];
  value: Scalars["JSON"]["input"];
};

export type SetConfigPayload = {
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  unit?: Maybe<Scalars["Void"]["output"]>;
};

export type SettlementAdminConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<SettlementAdminResponse>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type ShiftMealSurveySubmitConnection = {
  averageScore?: Maybe<Scalars["Decimal"]["output"]>;
  limit: Scalars["Int"]["output"];
  nodes: Array<ShiftMealSurveySubmitResponse>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Shortlink_GeneratePayload = {
  description: Scalars["String"]["input"];
  imageUri: Scalars["URI"]["input"];
  predefinedKey?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
  uri: Scalars["URI"]["input"];
};

export type Step = {
  finalStatus: UserStepStatus;
  id?: Maybe<Scalars["ID"]["output"]>;
  identifierData?: Maybe<Kyc_IdentifierData>;
  metadata?: Maybe<Scalars["JSON"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  relativeContacts?: Maybe<Array<Kyc_RelativeContacts>>;
  selfies?: Maybe<Kyc_Selfie>;
  status?: Maybe<UserStepStatus>;
  userInfoData?: Maybe<Kyc_UserInfoData>;
};

export enum Subscription_UserSubscriptionPaymentStatus {
  Paid = "PAID",
  Paying = "PAYING",
  Unpaid = "UNPAID",
}

export enum Subscription_UserSubscriptionStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export type SurveyForm_EmploymentSurveyForm = {
  actionData?: Maybe<Banner_ActionData>;
  actionType?: Maybe<Banner_ActionType>;
  employment?: Maybe<Employment>;
  form?: Maybe<SurveyForm>;
  response?: Maybe<EmploymentSurveyResponseV2>;
};

export type SurveyForm_EmploymentSurveyFormConnection = {
  limit?: Maybe<Scalars["Int"]["output"]>;
  nodes: Array<SurveyForm_EmploymentSurveyForm>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export enum SurveyForm_OverrideStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type Survey_SurveyFormResponse = {
  detail: Array<SurveyResponseDetail>;
  eligibleCount: Scalars["Int"]["output"];
  responseCount: Scalars["Int"]["output"];
};

export type Survey_SurveyResponseV2 = {
  details: Array<EmploymentSurveyResponseV2>;
  eligibleCount: Scalars["Int"]["output"];
  responseCount: Scalars["Int"]["output"];
};

export type System_CreateEmploymentTokenPayload = {
  employmentId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type Transaction = {
  amount?: Maybe<Scalars["Money"]["output"]>;
  createdDate?: Maybe<Scalars["Instant"]["output"]>;
  employeeId?: Maybe<Scalars["String"]["output"]>;
  fullName: Scalars["String"]["output"];
  id?: Maybe<Scalars["ID"]["output"]>;
  idNumber: Scalars["String"]["output"];
  lastModifiedDate?: Maybe<Scalars["Instant"]["output"]>;
  phone: Scalars["String"]["output"];
  productCode?: Maybe<Scalars["String"]["output"]>;
  refCode?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<EarningCallbackResponseStatus>;
  tenantName?: Maybe<Scalars["String"]["output"]>;
  userNetAmount?: Maybe<Scalars["Money"]["output"]>;
};

export type TransactionConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Transaction>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
  totalAmount: Scalars["Money"]["output"];
  totalUserNetAmount: Scalars["Money"]["output"];
};

export type UptimeConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Uptimes>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Uptimes = {
  id?: Maybe<Scalars["ID"]["output"]>;
  monitorId?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<UptimeStatus>;
  type?: Maybe<UptimeMonitorType>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type UserPayBusinessAdminConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<UserPayBusinessAdminResponse>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type UserPayBusinessDetailConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<UserPayBusinessDetailResponse>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export enum User_ChangePhoneNumberStatus {
  Cancelled = "Cancelled",
  Finished = "Finished",
  InProgress = "InProgress",
  Pending = "Pending",
}

export type User_DeletionRequest = {
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
};

export type User_DeletionRequestResponse = {
  deletionRequest: User_DeletionRequest;
};

export type User_FinalizeChangePhoneNumberResponse = {
  authResponse: AuthResponse;
  deviceToken: Scalars["String"]["output"];
};

export type VendorUserConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<VendorUsers>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type VendorUsers = {
  createdDate?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  userAcl?: Maybe<UserAcl>;
  username?: Maybe<Scalars["String"]["output"]>;
  vendor?: Maybe<VendorInfoReponse>;
};

export type Waitlist_Campaign = {
  activation202206_isClaimedByMe: Scalars["Boolean"]["output"];
  activation202206_isEnabled: Scalars["Boolean"]["output"];
  code: Scalars["String"]["output"];
  companies: Waitlist_CompanyConnection;
  createdDate: Scalars["Instant"]["output"];
  displayName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  status: Waitlist_CampaignStatus;
};

export type Waitlist_CampaignCompaniesArgs = {
  query?: Waitlist_CompanyQuery;
};

export type Waitlist_CampaignResponse = {
  campaign: Waitlist_Campaign;
};

export enum Waitlist_CampaignStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type Waitlist_CollabInfo = {
  companyName: Scalars["String"]["output"];
  email: Scalars["Email"]["output"];
  employees: Scalars["Int"]["output"];
  status: Waitlist_CollabInfoStatus;
  subscribeMarketingInfo: Scalars["Boolean"]["output"];
};

export enum Waitlist_CollabInfoStatus {
  Approved = "Approved",
  Pending = "Pending",
}

export type Waitlist_Company = {
  code: Scalars["String"]["output"];
  createdDate: Scalars["Instant"]["output"];
  displayName: Scalars["String"]["output"];
  displayOrder: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  lastModifiedDate: Scalars["Instant"]["output"];
  logo?: Maybe<StorageObject>;
  publicRegisterCount: Scalars["Int"]["output"];
  rejectionMessage?: Maybe<Scalars["String"]["output"]>;
  stage: Waitlist_CompanyStage;
  status: Waitlist_CompanyStatus;
  userCountGoal: Scalars["Int"]["output"];
  userCountSeeding: Scalars["Int"]["output"];
  waitlist_user: Waitlist_User;
  waitlist_users: Waitlist_UserConnection;
};

export type Waitlist_CompanyWaitlist_UserArgs = {
  id: Scalars["ID"]["input"];
};

export type Waitlist_CompanyWaitlist_UsersArgs = {
  query?: Waitlist_UserQuery;
};

export type Waitlist_CompanyConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Waitlist_Company>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Waitlist_CompanyIdFilter = {
  equalTo?: InputMaybe<Scalars["ID"]["input"]>;
  in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  notEqualTo?: InputMaybe<Scalars["ID"]["input"]>;
  notIn?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type Waitlist_CompanyMutateResponse = {
  company: Waitlist_Company;
};

export enum Waitlist_CompanyOrderable {
  CreatedDate = "createdDate",
  DisplayName = "displayName",
  DisplayOrder = "displayOrder",
  Id = "id",
  UserRegistered = "userRegistered",
}

export type Waitlist_CompanyQuery = {
  filters?: InputMaybe<Array<Waitlist_CompanyQueryFilter>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Waitlist_CompanyOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Waitlist_CompanyQueryFilter = {
  codeSearch?: InputMaybe<Scalars["String"]["input"]>;
  displayNameSearch?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Array<Waitlist_CompanyIdFilter>>;
  /**
   * status: [waitlist_CompanyStatusFilter!]
   * stage: [waitlist_CompanyStageFilter!]
   * displayName: [StringFilter!]
   */
  statuses?: InputMaybe<Array<Waitlist_CompanyStatus>>;
};

export enum Waitlist_CompanyStage {
  WaitingForRegister = "WaitingForRegister",
  WorkingWithEmployer = "WorkingWithEmployer",
}

export enum Waitlist_CompanyStatus {
  Active = "Active",
  Inactive = "Inactive",
  Integrated = "Integrated",
  Pending = "Pending",
}

export type Waitlist_CreateCampaignPayload = {
  code: Scalars["String"]["input"];
  displayName: Scalars["String"]["input"];
  status: Waitlist_CampaignStatus;
};

export type Waitlist_CreateCompanyPayload = {
  code: Scalars["String"]["input"];
  displayName: Scalars["String"]["input"];
  displayOrder?: Scalars["Int"]["input"];
  logoId?: InputMaybe<Scalars["ID"]["input"]>;
  status: Waitlist_CompanyStatus;
  userCountGoal: Scalars["Int"]["input"];
  userCountSeeding: Scalars["Int"]["input"];
};

export type Waitlist_RegisterUserPayload = {
  answers?: InputMaybe<Scalars["JSON"]["input"]>;
  companyId: Scalars["ID"]["input"];
  fullName: Scalars["String"]["input"];
  hrPhone?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
  position: Scalars["String"]["input"];
};

export type Waitlist_RegisterWaitlistCollab = {
  companyName: Scalars["String"]["input"];
  email: Scalars["Email"]["input"];
  employees: Scalars["Int"]["input"];
  subscribeMarketingInfo?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Waitlist_UpdateCampaignPayload = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Waitlist_CampaignStatus>;
};

export type Waitlist_UpdateCompanyPayload = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  logoId?: InputMaybe<Scalars["ID"]["input"]>;
  status?: InputMaybe<Waitlist_CompanyStatus>;
  userCountGoal?: InputMaybe<Scalars["Int"]["input"]>;
  userCountSeeding?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Waitlist_UpdateUserInvitorPayload = {
  phoneNumber: Scalars["PhoneNumber"]["input"];
};

export type Waitlist_User = {
  collabInfo?: Maybe<Waitlist_CollabInfo>;
  company: Waitlist_Company;
  createdDate: Scalars["Instant"]["output"];
  id: Scalars["ID"]["output"];
  info: Waitlist_UserInfo;
  invitees: Waitlist_UserConnection;
  invitor?: Maybe<Waitlist_User>;
  lastModifiedDate: Scalars["Instant"]["output"];
  status: Waitlist_UserStatus;
  user: User;
};

export type Waitlist_UserInviteesArgs = {
  query?: Waitlist_UserQuery;
};

export type Waitlist_UserConnection = {
  limit: Scalars["Int"]["output"];
  nodes: Array<Waitlist_User>;
  offset: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type Waitlist_UserIdFilter = {
  equalTo?: InputMaybe<Scalars["ID"]["input"]>;
  in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  notEqualTo?: InputMaybe<Scalars["ID"]["input"]>;
  notIn?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type Waitlist_UserInfo = {
  answers?: Maybe<Scalars["JSON"]["output"]>;
  fullName: Scalars["String"]["output"];
  hrPhone?: Maybe<Scalars["PhoneNumber"]["output"]>;
  position: Scalars["String"]["output"];
};

export type Waitlist_UserMutateResponse = {
  user: Waitlist_User;
};

export enum Waitlist_UserOrderable {
  CreatedDate = "createdDate",
  Id = "id",
}

export type Waitlist_UserQuery = {
  filters?: InputMaybe<Array<Waitlist_UserQueryFilter>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Waitlist_UserOrderable>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Waitlist_UserQueryFilter = {
  companyId?: InputMaybe<Array<Waitlist_CompanyIdFilter>>;
  id?: InputMaybe<Array<Waitlist_UserIdFilter>>;
  invitorId?: InputMaybe<Array<Waitlist_UserIdFilter>>;
  userId?: InputMaybe<Array<UserIdFilter>>;
};

export enum Waitlist_UserStatus {
  Invalid = "Invalid",
  Unverified = "Unverified",
  Valid = "Valid",
}

export type GenerateEmploymentAgreementMutationVariables = Exact<{
  employmentId: Scalars["ID"]["input"];
  qualifier?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GenerateEmploymentAgreementMutation = {
  generateEmploymentAgreement: {
    agreement: {
      id: string;
      createdDate: any;
      lastModifiedDate: any;
      status: EmploymentAgreementStatus;
      agreementFile?: { id: string; uri: any } | null;
      agreementHtmlFile?: { id: string; uri: any } | null;
    };
  };
};

export type RequestOtpMutationVariables = Exact<{
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type RequestOtpMutation = {
  sendUserOtpV2: { debug_otp?: string | null };
};

export type SignAgreementMutationVariables = Exact<{
  payload?: InputMaybe<EmploymentSignOtpPayload>;
}>;

export type SignAgreementMutation = { signAgreement?: any | null };

export type AgreeTermAndConditionMutationVariables = Exact<{
  employmentId: Scalars["ID"]["input"];
}>;

export type AgreeTermAndConditionMutation = {
  agreeTermAndCondition: {
    agreement: { id: string; status: EmploymentAgreementStatus };
  };
};

export type EmployeeConsentMutationMutationVariables = Exact<{
  payload: LeAssociateUserConsentPayload;
}>;

export type EmployeeConsentMutationMutation = {
  la_employeeConsent: { employeeCode: string; phoneNumber: any };
};

export type SyncEmploymentFromExternalSourceMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type SyncEmploymentFromExternalSourceMutation = {
  syncEmploymentFromExternalSource?: any | null;
};

export type UnlinkUserEmploymentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type UnlinkUserEmploymentMutation = {
  unlinkUserEmployment: { employment: { id: string } };
};

export type FinalizeUserRegistrationMutationVariables = Exact<{
  payload: FinaliseUserRegistrationPayload;
}>;

export type FinalizeUserRegistrationMutation = {
  finalizeUserRegistration: {
    accessToken: string;
    expiresIn: any;
    refreshToken?: string | null;
    refreshExpiresIn?: any | null;
  };
};

export type LoginMutationVariables = Exact<{
  payload: LoginPayload;
}>;

export type LoginMutation = {
  login: {
    accessToken: string;
    expiresIn: any;
    refreshToken?: string | null;
    refreshExpiresIn?: any | null;
  };
};

export type RequestLoginMutationVariables = Exact<{
  payload: RequestLoginPayload;
}>;

export type RequestLoginMutation = {
  requestLogin: { isNew: boolean; token: string; debug_otp?: string | null };
};

export type RequestDeviceTokenV2MutationVariables = Exact<{
  payload: RequestDeviceTokenPayload;
}>;

export type RequestDeviceTokenV2Mutation = {
  requestDeviceToken: { deviceToken: string };
};

export type MarkAllAsReadMutationVariables = Exact<{ [key: string]: never }>;

export type MarkAllAsReadMutation = { markAllAsRead?: any | null };

export type MarkNotificationAsReadMutationDocumentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type MarkNotificationAsReadMutationDocumentMutation = {
  markAsRead: { message: { id: string; status: MessageStatus } };
};

export type LinkEmploymentNguyenKimMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  payload: Nguyenkim_LinkEmploymentPayload;
}>;

export type LinkEmploymentNguyenKimMutation = {
  nguyenkim_linkEmployment: {
    employment: {
      id: string;
      status?: EmploymentStatus | null;
      fullName?: string | null;
    };
  };
};

export type TryAutoLinkMutationVariables = Exact<{
  payload: TryAutoLinkEmploymentPayload;
}>;

export type TryAutoLinkMutation = {
  tryAutoLinkEmployment: {
    employment?: {
      id: string;
      status?: EmploymentStatus | null;
      fullName?: string | null;
    } | null;
  };
};

export type LinkEmploymentMutationVariables = Exact<{
  payload: UserLinkEmploymentPayload;
}>;

export type LinkEmploymentMutation = {
  linkEmployment: {
    employment: {
      id: string;
      status?: EmploymentStatus | null;
      fullName?: string | null;
    };
  };
};

export type CreateOrderMutationVariables = Exact<{
  employmentId: Scalars["ID"]["input"];
  payload: Array<CreateOrderPayloadV2> | CreateOrderPayloadV2;
}>;

export type CreateOrderMutation = {
  order_createOrder: {
    order: {
      id: string;
      code: string;
      status: OrderStatus;
      originPrice: any;
      actualPrice: any;
      paidPrice?: any | null;
      createdDate: any;
      lastModifiedDate: any;
      items: Array<{ type?: string | null; quantity?: number | null }>;
    };
  };
};

export type PayOrderMutationVariables = Exact<{
  employmentId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
  paymentPayload: PayOrderPaymentUnionPayload;
}>;

export type PayOrderMutation = { order_payOrder?: any | null };

export type RequestChangePasswordMutationVariables = Exact<{
  phoneNumber: Scalars["PhoneNumber"]["input"];
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
  idNumberValidate: Scalars["String"]["input"];
}>;

export type RequestChangePasswordMutation = {
  requestChangePasswordV2: {
    requestId: string;
    debug_otp?: string | null;
    otp: { success: boolean; debug_otp?: string | null; retryAfter: any };
  };
};

export type RequestChangePasswordWithoutSocialIdMutationVariables = Exact<{
  phoneNumber: Scalars["PhoneNumber"]["input"];
  otpLength?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type RequestChangePasswordWithoutSocialIdMutation = {
  requestChangePassword: {
    requestId: string;
    debug_otp?: string | null;
    otp: { success: boolean; debug_otp?: string | null; retryAfter: any };
  };
};

export type VerifyChangePasswordRequestMutationVariables = Exact<{
  payload: VerifiyChangePasswordRequestPayload;
}>;

export type VerifyChangePasswordRequestMutation = {
  verifyChangePasswordRequest: { token: string };
};

export type FinalizeChangePasswordRequestMutationVariables = Exact<{
  payload: FinalizeChangePasswordRequestPayload;
}>;

export type FinalizeChangePasswordRequestMutation = {
  finalizeChangePasswordRequest: {
    accessToken: string;
    refreshToken?: string | null;
  };
};

export type RequestChangePhoneNumberMutationMutationVariables = Exact<{
  payload: CreateChangePhoneNumberPayload;
}>;

export type RequestChangePhoneNumberMutationMutation = {
  user_createChangePhoneNumberRequest: {
    request?: {
      id: string;
      expiredAt: any;
      status: User_ChangePhoneNumberStatus;
    } | null;
  };
};

export type VerifyChangePhoneNumberMutationMutationVariables = Exact<{
  requestId: Scalars["ID"]["input"];
  payload: ExecuteChangePhoneNumberRequestPayload;
}>;

export type VerifyChangePhoneNumberMutationMutation = {
  user_verifyOtpChangePhoneNumberRequest: {
    request?: { id: string; expiredAt: any } | null;
  };
};

export type ReAttemptChangePhoneNumberMutationMutationVariables = Exact<{
  payload: ResendChangePhoneNumberOtp;
}>;

export type ReAttemptChangePhoneNumberMutationMutation = {
  user_resendChangePhoneNumberOtp?: any | null;
};

export type FinalizeChangePhoneNumberMutationMutationVariables = Exact<{
  requestId: Scalars["ID"]["input"];
  payload: FinalizeChangePhoneNumberRequestPayload;
}>;

export type FinalizeChangePhoneNumberMutationMutation = {
  user_finalizeChangePhoneNumberRequest: {
    deviceToken: string;
    authResponse: {
      accessToken: string;
      expiresIn: any;
      refreshToken?: string | null;
      refreshExpiresIn?: any | null;
    };
  };
};

export type ExchangeTokenMutationVariables = Exact<{
  payload: LoginPayload;
}>;

export type ExchangeTokenMutation = { login: { accessToken: string } };

export type CreateLoanApplicationMutationVariables = Exact<{
  employmentId: Scalars["ID"]["input"];
  payload: CreateAdvanceV3Payload;
}>;

export type CreateLoanApplicationMutation = {
  advanceV3_createLoanApplication: {
    order: {
      id: string;
      code: string;
      actualPrice: any;
      createdDate: any;
      payment?: {
        details: Array<{
          type: PaySourceType;
          amount: any;
          voucher?: {
            id: string;
            code: string;
            description?: string | null;
            type: string;
          } | null;
          loanApplication?: {
            id: string;
            createdDate: any;
            code?: string | null;
            amountValue: any;
            fee: any;
            actuallyReceived: any;
          } | null;
        }>;
      } | null;
    };
  };
};

export type AgreementQueryVariables = Exact<{ [key: string]: never }>;

export type AgreementQuery = {
  profile: {
    id: string;
    phoneNumber: any;
    currentEmployment?: {
      id: string;
      status?: EmploymentStatus | null;
      company: { id: string; status: CompanyStatus };
      agreement?: {
        id: string;
        createdDate: any;
        lastModifiedDate: any;
        status: EmploymentAgreementStatus;
        agreementHtmlFile?: { id: string; uri: any } | null;
        sign?: { id: string; file: { id: string; uri: any } } | null;
      } | null;
    } | null;
  };
};

export type TotalWorkPeriodsQueryVariables = Exact<{
  query?: InputMaybe<EmploymentWorkPeriodQuery>;
}>;

export type TotalWorkPeriodsQuery = {
  profile: {
    id: string;
    currentEmployment?: {
      id: string;
      currentWorkPeriod: { id: string; startTime: any; endTimeInclusive: any };
      workPeriods: {
        total: number;
        limit: number;
        offset: number;
        nodes: Array<{ id: string; startTime: any; endTimeInclusive: any }>;
      };
    } | null;
  };
};

export type AttendancesQueryVariables = Exact<{
  query: AttendanceQuery;
  workPeriodId: Scalars["ID"]["input"];
}>;

export type AttendancesQuery = {
  profile: {
    id: string;
    currentEmployment?: {
      id: string;
      shiftCountUnit?: ShiftUnit | null;
      workPeriod: { id: string; attendances: { sumShiftCount: any } };
      attendances: {
        limit: number;
        offset: number;
        sumShiftCount: any;
        total: number;
        nodes: Array<{
          id: string;
          createdDate: any;
          lastModifiedDate: any;
          status: AttendanceStatus;
          date: any;
          checkinSecond?: number | null;
          checkoutSecond?: number | null;
          workingDayType: WorkingDayType;
          workingTimeHours: number;
          workingTimeRatePerHour: any;
          submittedAt?: any | null;
          earnedBalance: any;
          type: AttendanceType;
          vendorType?: string | null;
          times: Array<any | null>;
          shiftCount: any;
          shiftWork: ShiftWorkType;
        }>;
      };
    } | null;
  };
};

export type BanksQueryDocumentQueryVariables = Exact<{
  query?: InputMaybe<BankQuery>;
}>;

export type BanksQueryDocumentQuery = {
  banks: {
    total: number;
    limit: number;
    offset: number;
    nodes: Array<{
      id: string;
      code: string;
      name: string;
      slug: string;
      logo?: { id: string; uri: any; filename: string } | null;
    }>;
  };
};

export type GetCompanyBySlugQueryVariables = Exact<{
  slug: Scalars["String"]["input"];
}>;

export type GetCompanyBySlugQuery = {
  companyBySlug: {
    authRealm?: string | null;
    id: string;
    name: string;
    code: string;
    slug?: string | null;
    shortName?: string | null;
    logo?: { id: string; uri: any } | null;
    onboardCompany?: {
      id: string;
      workflow: string;
      displayName: string;
      employeeIdPlaceHolder?: string | null;
    } | null;
  };
};

export type GetLinkableCompanyQueryVariables = Exact<{ [key: string]: never }>;

export type GetLinkableCompanyQuery = {
  tryMatchPhoneWithLinkableCompany?: Array<{
    authRealm?: string | null;
    id: string;
    name: string;
    code: string;
    slug?: string | null;
    shortName?: string | null;
    logo?: { id: string; uri: any } | null;
    onboardCompany?: {
      id: string;
      workflow: string;
      displayName: string;
      employeeIdPlaceHolder?: string | null;
    } | null;
  }> | null;
};

export type PersonalInformationQueryVariables = Exact<{ [key: string]: never }>;

export type PersonalInformationQuery = {
  profile: {
    id: string;
    phoneNumber: any;
    currentEmployment?: {
      id: string;
      status?: EmploymentStatus | null;
      fullName?: string | null;
      phoneNumber?: any | null;
      employeeId?: string | null;
      socialIdNumber?: string | null;
      bankAccount?: string | null;
      bank?: {
        id: string;
        code: string;
        name: string;
        logo?: { id: string; uri: any } | null;
      } | null;
      company: {
        id: string;
        name: string;
        shortName?: string | null;
        logo?: { id: string; uri: any } | null;
        features: { payslip: boolean };
      };
      features: {
        withdrawAllAmount: boolean;
        attendanceToggle: boolean;
        isSpendingEnable: boolean;
        topUp: boolean;
        softpin: boolean;
      };
    } | null;
  };
};

export type TransationsQueryVariables = Exact<{
  query: LoanApplicationQuery;
}>;

export type TransationsQuery = {
  profile: {
    id: string;
    currentEmployment?: {
      id: string;
      loanApplications: {
        limit: number;
        offset: number;
        total: number;
        sumAmounts: Array<any>;
        nodes: Array<{
          id: string;
          createdDate: any;
          lastModifiedDate: any;
          status: LoanApplicationStatus;
          type: LoanApplicationType;
          disbursementAccount: string;
          amountValue: any;
          fee: any;
          amountCurrency: any;
          actuallyReceived: any;
          rejectionReason?: RejectLoanApplicationReason | null;
          rejectionMessage?: string | null;
          code?: string | null;
          period: {
            startTime: any;
            endTimeInclusive: any;
            workPeriod: { id: string };
          };
          transaction?: {
            id: string;
            status: TransactionStatus;
            item: { amount: any; vendorType: string; vendorItem: string };
            beneficiary: { beneficiaryId: string; beneficiaryCode: string };
            failedReason?: {
              errorCode: string;
              errorMessage?: string | null;
            } | null;
          } | null;
          paymentDetail?: {
            amount: any;
            payment: {
              id: string;
              amount: any;
              order: {
                id: string;
                code: string;
                status: OrderStatus;
                cards?: Array<{
                  id: string;
                  serialNumber: string;
                  pinCode: string;
                  amount: any;
                  carrier: CarrierType;
                  settledDate: any;
                  usedFlag?: boolean | null;
                  carrierDataInfo?: {
                    carrier: CarrierType;
                    packageId: string;
                    dataAmount?: string | null;
                    duration?: string | null;
                  } | null;
                }> | null;
                billTransaction?: {
                  id: string;
                  bill: { id: string; type: BillType };
                } | null;
              };
            };
          } | null;
        }>;
      };
    } | null;
  };
};

export type TransationQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type TransationQuery = {
  profile: {
    id: string;
    currentEmployment?: {
      id: string;
      loanApplication: {
        id: string;
        createdDate: any;
        lastModifiedDate: any;
        status: LoanApplicationStatus;
        type: LoanApplicationType;
        disbursementAccount: string;
        amountValue: any;
        fee: any;
        amountCurrency: any;
        actuallyReceived: any;
        rejectionReason?: RejectLoanApplicationReason | null;
        rejectionMessage?: string | null;
        code?: string | null;
        period: {
          startTime: any;
          endTimeInclusive: any;
          workPeriod: { id: string };
        };
        transaction?: {
          id: string;
          status: TransactionStatus;
          item: { amount: any; vendorType: string; vendorItem: string };
          beneficiary: { beneficiaryId: string; beneficiaryCode: string };
          failedReason?: {
            errorCode: string;
            errorMessage?: string | null;
          } | null;
        } | null;
        paymentDetail?: {
          amount: any;
          payment: {
            id: string;
            amount: any;
            order: {
              id: string;
              code: string;
              status: OrderStatus;
              cards?: Array<{
                id: string;
                serialNumber: string;
                pinCode: string;
                amount: any;
                carrier: CarrierType;
                settledDate: any;
                usedFlag?: boolean | null;
                carrierDataInfo?: {
                  carrier: CarrierType;
                  packageId: string;
                  dataAmount?: string | null;
                  duration?: string | null;
                } | null;
              }> | null;
              billTransaction?: {
                id: string;
                bill: { id: string; type: BillType };
              } | null;
            };
          };
        } | null;
      };
    } | null;
  };
};

export type BalanceQueryVariables = Exact<{ [key: string]: never }>;

export type BalanceQuery = {
  profile: {
    id: string;
    currentEmployment?: {
      id: string;
      shiftCountUnit?: ShiftUnit | null;
      group?: {
        id: string;
        transactionFeePercentage: number;
        limitPercentageBySalaryPeriod: number;
        dailyLimit: any;
        numberOfAdvanceBySalaryPeriod: number;
        numberOfAdvanceByDay: number;
        limitPerAdvance?: any | null;
        salaryPeriodFreeAmount?: any | null;
        minAdvanceValue: any;
      } | null;
      advanceablePeriods: Array<{
        startTime: any;
        endTimeInclusive: any;
        workPeriod: {
          id: string;
          startTime: any;
          endTimeInclusive: any;
          withdrawableBalance: any;
          earnedBalance: any;
          earnedBalanceCurrent: any;
          earnedBalanceMax: any;
          attendances: { sumShiftCount: any };
          advanceValidation: {
            success?: boolean | null;
            failure?: {
              code: string;
              title: any;
              mood: EmploymentWorkPeriodAdvanceValidationFailureMood;
              content: any;
              extensions: {
                deduction?: Array<{
                  code: string;
                  message: any;
                  amount: any;
                }> | null;
              };
            } | null;
          };
        };
      }>;
    } | null;
  };
};

export type NextGenActiveBannersQueryVariables = Exact<{
  slot?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type NextGenActiveBannersQuery = {
  profile: {
    id: string;
    currentEmployment?: {
      id: string;
      banner_activeBanners: Array<{
        id: string;
        title: string;
        code: string;
        description: string;
        slot: string;
        priority: number;
        status: Banner_Status;
        actionType: Banner_ActionType;
        contentImage?: { id: string; uri: any } | null;
        actionData: {
          noop?: string | null;
          internal?: string | null;
          external?: string | null;
          inAppBrowser?: string | null;
        };
      }>;
    } | null;
  };
};

export type VouchersQueryVariables = Exact<{ [key: string]: never }>;

export type VouchersQuery = {
  profile: {
    id: string;
    currentEmployment?: {
      id: string;
      vouchers?: Array<{
        id: string;
        createdDate: any;
        lastModifiedDate: any;
        minAmount: number;
        maxAmount: number;
        remain?: number | null;
        total?: number | null;
        maxUsageByEmployment?: number | null;
        fromDate: any;
        toDate: any;
        description?: string | null;
        fixedDiscount?: number | null;
        unlimitedDiscountAmount: boolean;
        unlimitedDiscountTime: boolean;
        code: string;
        type: string;
        preferenceRoute?: string | null;
        minApplicableAmount?: any | null;
        displayInfo?: string | null;
        usedQuantity: number;
        isOutOfStock: boolean;
        displayDescription?: string | null;
        icon?: { id: string; uri: any } | null;
        banner?: { id: string; uri: any } | null;
      }> | null;
    } | null;
  };
};

export type MessagesQueryVariables = Exact<{
  query: MessageQuery;
}>;

export type MessagesQuery = {
  profile: {
    id: string;
    messages: {
      total: number;
      limit: number;
      offset: number;
      nodes: Array<{
        id: string;
        createdDate: any;
        lastModifiedDate: any;
        title: any;
        content: any;
        tags: Array<string>;
        status: MessageStatus;
        group?: {
          id: string;
          createdDate: any;
          lastModifiedDate: any;
          loanApplication?: {
            id: string;
            createdDate: any;
            lastModifiedDate: any;
            status: LoanApplicationStatus;
            type: LoanApplicationType;
            amountValue: any;
            amountCurrency: any;
          } | null;
          messages?: Array<{
            id: string;
            createdDate: any;
            lastModifiedDate: any;
            title: any;
            content: any;
            tags: Array<string>;
            status: MessageStatus;
          }> | null;
        } | null;
      }>;
    };
  };
};

export type MessageDetailQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type MessageDetailQuery = {
  profile: {
    id: string;
    message: {
      id: string;
      createdDate: any;
      lastModifiedDate: any;
      title: any;
      content: any;
      tags: Array<string>;
      status: MessageStatus;
      group?: {
        id: string;
        createdDate: any;
        lastModifiedDate: any;
        loanApplication?: {
          id: string;
          createdDate: any;
          lastModifiedDate: any;
          status: LoanApplicationStatus;
          type: LoanApplicationType;
          amountValue: any;
          amountCurrency: any;
        } | null;
        messages?: Array<{
          id: string;
          createdDate: any;
          lastModifiedDate: any;
          title: any;
          content: any;
          tags: Array<string>;
          status: MessageStatus;
        }> | null;
      } | null;
    };
  };
};

export type TotalUnReadMessageQueryVariables = Exact<{ [key: string]: never }>;

export type TotalUnReadMessageQuery = {
  profile: { id: string; messages: { total: number } };
};

export type CalculateOrderQueryVariables = Exact<{
  employmentId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
  prepaidPaymentPayload?: InputMaybe<CalculateOrderPaymentUnionPayload>;
}>;

export type CalculateOrderQuery = {
  order_calculateOrder?: {
    originPrice: any;
    actualPrice: any;
    paidPrice?: {
      amount: any;
      fee: any;
      feeBeforePromotion: any;
      totalEmployeePay: any;
      ewa?: Array<{
        actualReceiveAmount: any;
        amount: any;
        fee: any;
        workPeriod: { id: string };
      }> | null;
    } | null;
  } | null;
};

export type GetSpendingOrderByIdQueryVariables = Exact<{
  orderId: Scalars["ID"]["input"];
}>;

export type GetSpendingOrderByIdQuery = {
  profile: {
    id: string;
    currentEmployment?: {
      id: string;
      spdUser: {
        id: string;
        order?: {
          id: string;
          code: string;
          createdDate: any;
          status: OrderStatus;
          originPrice: any;
          actualPrice: any;
          paidPrice?: any | null;
          failedInfo?: { code?: string | null; message?: string | null } | null;
          topUpData?: {
            targetPhone: any;
            carrier: CarrierType;
            amount: any;
          } | null;
        } | null;
      };
    } | null;
  };
};

export type GetPhoneCardOrderByIdQueryVariables = Exact<{
  orderId: Scalars["ID"]["input"];
}>;

export type GetPhoneCardOrderByIdQuery = {
  profile: {
    id: string;
    currentEmployment?: {
      id: string;
      spdUser: {
        id: string;
        order?: {
          id: string;
          code: string;
          createdDate: any;
          status: OrderStatus;
          originPrice: any;
          actualPrice: any;
          paidPrice?: any | null;
          failedInfo?: { code?: string | null; message?: string | null } | null;
          cards?: Array<{
            id: string;
            serialNumber: string;
            pinCode: string;
            amount: any;
            carrier: CarrierType;
            settledDate: any;
            usedFlag?: boolean | null;
            carrierDataInfo?: {
              carrier: CarrierType;
              packageId: string;
              dataAmount?: string | null;
              duration?: string | null;
            } | null;
          }> | null;
        } | null;
      };
    } | null;
  };
};

export type CheckFlowChangePasswordQueryVariables = Exact<{
  phoneNumber: Scalars["PhoneNumber"]["input"];
}>;

export type CheckFlowChangePasswordQuery = {
  checkFlowChangePassword: {
    flow: FlowChangePassword;
    idNumberFirst3Characters?: string | null;
  };
};

export type PayslipQueryVariables = Exact<{
  loanApplicationQuery: LoanApplicationQuery;
}>;

export type PayslipQuery = {
  profile: {
    id: string;
    currentEmployment?: {
      id: string;
      grossSalary?: any | null;
      grossAllowance?: any | null;
      lanchi_totalCompensation?: any | null;
      shiftCountUnit?: ShiftUnit | null;
      currentWorkPeriod: {
        id: string;
        startTime: any;
        endTimeInclusive: any;
        attendances: { sumShiftCount: any };
        loanApplications: {
          total: number;
          limit: number;
          offset: number;
          sumAmounts: Array<any>;
          nodes: Array<{ id: string; createdDate: any }>;
        };
      };
      payslip: {
        earnings: Array<{ category: EarningCategory; amount: any }>;
        deductions: Array<{ category: DeductionCategory; amount: any }>;
      };
    } | null;
  };
};

export type GetPhoneNumberQueryVariables = Exact<{ [key: string]: never }>;

export type GetPhoneNumberQuery = {
  profile: {
    id: string;
    phoneNumber: any;
    currentEmployment?: { id: string; phoneNumber?: any | null } | null;
  };
};

export type CurrentWorkPeriodSummaryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentWorkPeriodSummaryQuery = {
  profile: {
    id: string;
    currentEmployment?: {
      id: string;
      currentWorkPeriod: {
        id: string;
        startTime: any;
        endTimeInclusive: any;
        withdrawableBalance: any;
        earnedBalance: any;
        earnedBalanceCurrent: any;
        earnedBalanceMax: any;
        attendances: { sumShiftCount: any };
        transactionSummary?: Array<{
          type?: string | null;
          amount: any;
        }> | null;
      };
    } | null;
  };
};

export type TopUpConfigQueryVariables = Exact<{ [key: string]: never }>;

export type TopUpConfigQuery = {
  profile: {
    id: string;
    phoneNumber: any;
    currentEmployment?: {
      id: string;
      currentWorkPeriod: {
        id: string;
        transactionSummary?: Array<{
          type?: string | null;
          amount: any;
        }> | null;
      };
      topUp_config: {
        limitation: any;
        cellularNetworkConfigs?: Array<{
          name: string;
          type: CellularNetworkType;
          status: CellularNetworkStatus;
          order?: number | null;
          thumbnail?: { id: string; uri: any } | null;
          items?: Array<{
            status: CellularNetworkItemStatus;
            discount: any;
            type?: CellularNetworkItemType | null;
            amount: any;
          }> | null;
        }> | null;
      };
    } | null;
  };
};

export type Spending_ItemsQueryVariables = Exact<{
  employmentId: Scalars["ID"]["input"];
  type: Scalars["String"]["input"];
}>;

export type Spending_ItemsQuery = {
  profile: {
    id: string;
    phoneNumber: any;
    currentEmployment?: {
      id: string;
      spending_campaign?: {
        code: string;
        detailUrl?: string | null;
        textColor?: string | null;
        banner: { id: string; uri: any };
        time: { from: any; to: any };
        discountItems: Array<{
          buyAttempt: number;
          convertedItemCode: string;
          type: string;
        }>;
      } | null;
    } | null;
  };
  spending_items: Array<{
    code: string;
    type: string;
    originPrice: any;
    actualPrice: any;
    name?: string | null;
    image?: string | null;
    tags?: Array<string> | null;
    extension?: { replaceable?: { token: string } | null } | null;
    cardInfo?: { amount: any; carrierType: CarrierType } | null;
    topUpInfo?: { amount: any; carrierType: CarrierType } | null;
  }>;
};

export type CalculateFeeQueryVariables = Exact<{
  employmentId: Scalars["ID"]["input"];
  payload?: InputMaybe<CalculateAdvanceV3Payload>;
}>;

export type CalculateFeeQuery = {
  advanceV3_calculate: {
    amount: any;
    employeeReceiveAmount: any;
    employeeFeeBeforePromotion: any;
    fee: { userFee: any; employerFee: any };
    detail: {
      cashbackVoucherResponseDetails?: Array<{
        amount: any;
        voucher: { id: string };
      }> | null;
      cashbackResponseDetails?: Array<{ amount: any }> | null;
      advanceV3ResponseDetails: Array<{
        amount: any;
        fee: { userFee: any; employerFee: any };
      }>;
    };
  };
};

export const GenerateEmploymentAgreementDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "generateEmploymentAgreement" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "employmentId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "qualifier" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "generateEmploymentAgreement" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "employmentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "employmentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "qualifier" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "qualifier" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "agreement" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastModifiedDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "agreementFile" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uri" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "agreementHtmlFile" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uri" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateEmploymentAgreementMutation,
  GenerateEmploymentAgreementMutationVariables
>;
export const RequestOtpDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "requestOTP" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "otpLength" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendUserOtpV2" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "otpLength" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "otpLength" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "debug_otp" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestOtpMutation, RequestOtpMutationVariables>;
export const SignAgreementDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "signAgreement" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payload" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "EmploymentSignOtpPayload" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "signAgreement" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "payload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "payload" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SignAgreementMutation,
  SignAgreementMutationVariables
>;
export const AgreeTermAndConditionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "agreeTermAndCondition" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "employmentId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "agreeTermAndCondition" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "employmentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "employmentId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "agreement" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AgreeTermAndConditionMutation,
  AgreeTermAndConditionMutationVariables
>;
export const EmployeeConsentMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "employeeConsentMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payload" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "LeAssociateUserConsentPayload" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "la_employeeConsent" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "payload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "payload" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "employeeCode" },
                },
                { kind: "Field", name: { kind: "Name", value: "phoneNumber" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployeeConsentMutationMutation,
  EmployeeConsentMutationMutationVariables
>;
export const SyncEmploymentFromExternalSourceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "syncEmploymentFromExternalSource" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "syncEmploymentFromExternalSource" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SyncEmploymentFromExternalSourceMutation,
  SyncEmploymentFromExternalSourceMutationVariables
>;
export const UnlinkUserEmploymentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "unlinkUserEmployment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "unlinkUserEmployment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "employment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UnlinkUserEmploymentMutation,
  UnlinkUserEmploymentMutationVariables
>;
export const FinalizeUserRegistrationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "finalizeUserRegistration" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payload" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "FinaliseUserRegistrationPayload" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "finalizeUserRegistration" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "payload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "payload" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
                { kind: "Field", name: { kind: "Name", value: "expiresIn" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refreshToken" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refreshExpiresIn" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FinalizeUserRegistrationMutation,
  FinalizeUserRegistrationMutationVariables
>;
export const LoginDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "login" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payload" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "LoginPayload" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "login" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "payload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "payload" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
                { kind: "Field", name: { kind: "Name", value: "expiresIn" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refreshToken" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refreshExpiresIn" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const RequestLoginDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "requestLogin" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payload" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "RequestLoginPayload" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "requestLogin" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "payload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "payload" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isNew" } },
                { kind: "Field", name: { kind: "Name", value: "token" } },
                { kind: "Field", name: { kind: "Name", value: "debug_otp" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestLoginMutation,
  RequestLoginMutationVariables
>;
export const RequestDeviceTokenV2Document = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "requestDeviceTokenV2" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payload" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "RequestDeviceTokenPayload" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "requestDeviceToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "payload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "payload" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "deviceToken" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestDeviceTokenV2Mutation,
  RequestDeviceTokenV2MutationVariables
>;
export const MarkAllAsReadDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "markAllAsRead" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "markAllAsRead" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkAllAsReadMutation,
  MarkAllAsReadMutationVariables
>;
export const MarkNotificationAsReadMutationDocumentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "markNotificationAsReadMutationDocument" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "markAsRead" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "message" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkNotificationAsReadMutationDocumentMutation,
  MarkNotificationAsReadMutationDocumentMutationVariables
>;
export const LinkEmploymentNguyenKimDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "linkEmploymentNguyenKim" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payload" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "nguyenkim_LinkEmploymentPayload" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "nguyenkim_linkEmployment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "payload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "payload" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "employment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullName" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LinkEmploymentNguyenKimMutation,
  LinkEmploymentNguyenKimMutationVariables
>;
export const TryAutoLinkDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "tryAutoLink" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payload" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "TryAutoLinkEmploymentPayload" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tryAutoLinkEmployment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "payload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "payload" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "employment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullName" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TryAutoLinkMutation, TryAutoLinkMutationVariables>;
export const LinkEmploymentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "linkEmployment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payload" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UserLinkEmploymentPayload" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "linkEmployment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "payload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "payload" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "employment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullName" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LinkEmploymentMutation,
  LinkEmploymentMutationVariables
>;
export const CreateOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "employmentId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payload" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "CreateOrderPayloadV2" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "order_createOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "employmentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "employmentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "payloads" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "payload" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "order" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "originPrice" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "actualPrice" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paidPrice" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastModifiedDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateOrderMutation, CreateOrderMutationVariables>;
export const PayOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "payOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "employmentId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "paymentPayload" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PayOrderPaymentUnionPayload" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "order_payOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "employmentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "employmentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "paymentPayload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "paymentPayload" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PayOrderMutation, PayOrderMutationVariables>;
export const RequestChangePasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "requestChangePassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phoneNumber" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PhoneNumber" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "otpLength" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "idNumberValidate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "requestChangePasswordV2" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "phoneNumber" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "phoneNumber" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "otpLength" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "otpLength" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "idNumberValidate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "idNumberValidate" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "requestId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "otp" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "debug_otp" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "retryAfter" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "debug_otp" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestChangePasswordMutation,
  RequestChangePasswordMutationVariables
>;
export const RequestChangePasswordWithoutSocialIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "requestChangePasswordWithoutSocialId" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phoneNumber" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PhoneNumber" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "otpLength" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "requestChangePassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "phoneNumber" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "phoneNumber" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "otpLength" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "otpLength" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "requestId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "otp" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "debug_otp" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "retryAfter" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "debug_otp" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestChangePasswordWithoutSocialIdMutation,
  RequestChangePasswordWithoutSocialIdMutationVariables
>;
export const VerifyChangePasswordRequestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "verifyChangePasswordRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payload" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "VerifiyChangePasswordRequestPayload",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "verifyChangePasswordRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "payload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "payload" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "token" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyChangePasswordRequestMutation,
  VerifyChangePasswordRequestMutationVariables
>;
export const FinalizeChangePasswordRequestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "finalizeChangePasswordRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payload" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "FinalizeChangePasswordRequestPayload",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "finalizeChangePasswordRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "payload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "payload" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refreshToken" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FinalizeChangePasswordRequestMutation,
  FinalizeChangePasswordRequestMutationVariables
>;
export const RequestChangePhoneNumberMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "requestChangePhoneNumberMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payload" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateChangePhoneNumberPayload" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "user_createChangePhoneNumberRequest",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "payload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "payload" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "request" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expiredAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestChangePhoneNumberMutationMutation,
  RequestChangePhoneNumberMutationMutationVariables
>;
export const VerifyChangePhoneNumberMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "verifyChangePhoneNumberMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "requestId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payload" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "ExecuteChangePhoneNumberRequestPayload",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "user_verifyOtpChangePhoneNumberRequest",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "requestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "requestId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "payload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "payload" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "request" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expiredAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyChangePhoneNumberMutationMutation,
  VerifyChangePhoneNumberMutationMutationVariables
>;
export const ReAttemptChangePhoneNumberMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "reAttemptChangePhoneNumberMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payload" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ResendChangePhoneNumberOtp" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user_resendChangePhoneNumberOtp" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "payload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "payload" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReAttemptChangePhoneNumberMutationMutation,
  ReAttemptChangePhoneNumberMutationMutationVariables
>;
export const FinalizeChangePhoneNumberMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "finalizeChangePhoneNumberMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "requestId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payload" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "FinalizeChangePhoneNumberRequestPayload",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "user_finalizeChangePhoneNumberRequest",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "requestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "requestId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "payload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "payload" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "authResponse" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "accessToken" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expiresIn" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "refreshToken" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "refreshExpiresIn" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "deviceToken" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FinalizeChangePhoneNumberMutationMutation,
  FinalizeChangePhoneNumberMutationMutationVariables
>;
export const ExchangeTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "exchangeToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payload" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "LoginPayload" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "login" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "payload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "payload" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExchangeTokenMutation,
  ExchangeTokenMutationVariables
>;
export const CreateLoanApplicationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createLoanApplication" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "employmentId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payload" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateAdvanceV3Payload" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "advanceV3_createLoanApplication" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "employmentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "employmentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "payload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "payload" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "order" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "actualPrice" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "payment" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "details" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "voucher" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "description",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "loanApplication",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amountValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "fee" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "actuallyReceived",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateLoanApplicationMutation,
  CreateLoanApplicationMutationVariables
>;
export const AgreementDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "agreement" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "phoneNumber" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentEmployment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "company" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "agreement" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastModifiedDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "agreementHtmlFile",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uri" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sign" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "file" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "uri" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgreementQuery, AgreementQueryVariables>;
export const TotalWorkPeriodsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "totalWorkPeriods" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "EmploymentWorkPeriodQuery" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentEmployment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currentWorkPeriod" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startTime" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endTimeInclusive" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "workPeriods" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "query" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "query" },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "total" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "limit" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "offset" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startTime" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "endTimeInclusive",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TotalWorkPeriodsQuery,
  TotalWorkPeriodsQueryVariables
>;
export const AttendancesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "attendances" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "AttendanceQuery" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "workPeriodId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentEmployment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shiftCountUnit" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "workPeriod" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "id" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "workPeriodId" },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attendances" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sumShiftCount",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attendances" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "query" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "query" },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "limit" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "offset" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sumShiftCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "total" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "lastModifiedDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "date" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "checkinSecond",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "checkoutSecond",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "workingDayType",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "workingTimeHours",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "workingTimeRatePerHour",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "submittedAt",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "earnedBalance",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "vendorType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "times" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "shiftCount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "shiftWork" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AttendancesQuery, AttendancesQueryVariables>;
export const BanksQueryDocumentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "banksQueryDocument" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "BankQuery" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "banks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "query" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "query" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                { kind: "Field", name: { kind: "Name", value: "limit" } },
                { kind: "Field", name: { kind: "Name", value: "offset" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "logo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uri" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BanksQueryDocumentQuery,
  BanksQueryDocumentQueryVariables
>;
export const GetCompanyBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getCompanyBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "companyBySlug" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "slug" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "authRealm" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "shortName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "logo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "uri" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "onboardCompany" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "workflow" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "employeeIdPlaceHolder" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCompanyBySlugQuery,
  GetCompanyBySlugQueryVariables
>;
export const GetLinkableCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getLinkableCompany" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tryMatchPhoneWithLinkableCompany" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "authRealm" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "shortName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "logo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "uri" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "onboardCompany" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "workflow" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "employeeIdPlaceHolder" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLinkableCompanyQuery,
  GetLinkableCompanyQueryVariables
>;
export const PersonalInformationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "personalInformation" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "phoneNumber" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentEmployment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "phoneNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "employeeId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "socialIdNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bank" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "logo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uri" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bankAccount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "company" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shortName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "logo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uri" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "features" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "payslip" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "features" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "withdrawAllAmount",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attendanceToggle" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isSpendingEnable" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "topUp" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "softpin" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PersonalInformationQuery,
  PersonalInformationQueryVariables
>;
export const TransationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "transations" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "LoanApplicationQuery" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentEmployment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "loanApplications" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "query" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "query" },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "limit" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "offset" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "total" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sumAmounts" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "lastModifiedDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "disbursementAccount",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountValue",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fee" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCurrency",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "actuallyReceived",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "rejectionReason",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "rejectionMessage",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fee" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "period" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "startTime",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "endTimeInclusive",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "workPeriod",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "transaction",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "item" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "amount",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "vendorType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "vendorItem",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "beneficiary",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "beneficiaryId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "beneficiaryCode",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "failedReason",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "errorCode",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "errorMessage",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "paymentDetail",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "payment",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "amount",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "order",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "status",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "cards",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "serialNumber",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "pinCode",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "amount",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "carrier",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "settledDate",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "usedFlag",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "carrierDataInfo",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "carrier",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "packageId",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "dataAmount",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "duration",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "billTransaction",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "bill",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "type",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransationsQuery, TransationsQueryVariables>;
export const TransationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "transation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentEmployment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "loanApplication" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "id" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "id" },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastModifiedDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "disbursementAccount",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fee" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCurrency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "actuallyReceived" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rejectionReason" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rejectionMessage" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fee" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "period" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startTime" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "endTimeInclusive",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "workPeriod" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "transaction" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "item" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "vendorType",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "vendorItem",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "beneficiary",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "beneficiaryId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "beneficiaryCode",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "failedReason",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "errorCode",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "errorMessage",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paymentDetail" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "payment" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "order",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "status",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "cards",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "serialNumber",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "pinCode",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "amount",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "carrier",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "settledDate",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "usedFlag",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "carrierDataInfo",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "carrier",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "packageId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "dataAmount",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "duration",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "billTransaction",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "bill",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "type",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransationQuery, TransationQueryVariables>;
export const BalanceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "balance" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentEmployment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shiftCountUnit" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "group" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "transactionFeePercentage",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "limitPercentageBySalaryPeriod",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dailyLimit" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "numberOfAdvanceBySalaryPeriod",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "numberOfAdvanceByDay",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "limitPerAdvance" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "salaryPeriodFreeAmount",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "minAdvanceValue" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "advanceablePeriods" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startTime" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endTimeInclusive" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "workPeriod" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startTime" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "endTimeInclusive",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "withdrawableBalance",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "earnedBalance",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "earnedBalanceCurrent",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "earnedBalanceMax",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "attendances",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "sumShiftCount",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "advanceValidation",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "success",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "failure",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "title",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "mood",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "content",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "extensions",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "deduction",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "code",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "message",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "amount",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BalanceQuery, BalanceQueryVariables>;
export const NextGenActiveBannersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "nextGenActiveBanners" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slot" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentEmployment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "banner_activeBanners" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "slot" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "slot" },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "contentImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uri" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slot" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "priority" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "actionType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "actionData" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "noop" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "internal" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "external" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "inAppBrowser",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NextGenActiveBannersQuery,
  NextGenActiveBannersQueryVariables
>;
export const VouchersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "vouchers" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentEmployment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "vouchers" },
                        name: { kind: "Name", value: "vouchersV2_5" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "query" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "limit" },
                                  value: { kind: "IntValue", value: "100" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "offset" },
                                  value: { kind: "IntValue", value: "0" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "filter" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "active" },
                                        value: {
                                          kind: "BooleanValue",
                                          value: true,
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "types" },
                                        value: {
                                          kind: "ListValue",
                                          values: [
                                            {
                                              kind: "StringValue",
                                              value: "All",
                                              block: false,
                                            },
                                            {
                                              kind: "StringValue",
                                              value: "Cashback",
                                              block: false,
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastModifiedDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "minAmount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "maxAmount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "remain" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "total" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "maxUsageByEmployment",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fixedDiscount" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "unlimitedDiscountAmount",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "unlimitedDiscountTime",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uri" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "banner" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uri" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "preferenceRoute" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "minApplicableAmount",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "displayInfo" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "usedQuantity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isOutOfStock" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "displayDescription",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VouchersQuery, VouchersQueryVariables>;
export const MessagesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "messages" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "MessageQuery" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "messages" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastModifiedDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "content" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "group" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "lastModifiedDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "loanApplication",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastModifiedDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amountValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amountCurrency",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "messages" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastModifiedDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "title",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "content",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "tags" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      { kind: "Field", name: { kind: "Name", value: "limit" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "offset" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessagesQuery, MessagesQueryVariables>;
export const MessageDetailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "messageDetail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "message" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastModifiedDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "content" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "tags" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "group" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastModifiedDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "loanApplication" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "lastModifiedDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountValue",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCurrency",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "messages" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "lastModifiedDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "content" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tags" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageDetailQuery, MessageDetailQueryVariables>;
export const TotalUnReadMessageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "totalUnReadMessage" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "messages" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: { kind: "IntValue", value: "1" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "offset" },
                            value: { kind: "IntValue", value: "0" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "tags" },
                                  value: {
                                    kind: "ListValue",
                                    values: [
                                      {
                                        kind: "StringValue",
                                        value: "primary",
                                        block: false,
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "statuses" },
                                  value: {
                                    kind: "ListValue",
                                    values: [
                                      { kind: "EnumValue", value: "Unread" },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TotalUnReadMessageQuery,
  TotalUnReadMessageQueryVariables
>;
export const CalculateOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "calculateOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "employmentId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "prepaidPaymentPayload" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CalculateOrderPaymentUnionPayload" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "order_calculateOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "employmentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "employmentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "prepaidPaymentPayload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "prepaidPaymentPayload" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "originPrice" } },
                { kind: "Field", name: { kind: "Name", value: "actualPrice" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paidPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "fee" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "feeBeforePromotion" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalEmployeePay" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ewa" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "actualReceiveAmount",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fee" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "workPeriod" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CalculateOrderQuery, CalculateOrderQueryVariables>;
export const GetSpendingOrderByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getSpendingOrderById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentEmployment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "spdUser" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "order" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "id" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "orderId" },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "originPrice",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "actualPrice",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "paidPrice" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "failedInfo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "message",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "topUpData" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "targetPhone",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "carrier",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSpendingOrderByIdQuery,
  GetSpendingOrderByIdQueryVariables
>;
export const GetPhoneCardOrderByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getPhoneCardOrderById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentEmployment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "spdUser" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "order" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "id" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "orderId" },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "originPrice",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "actualPrice",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "paidPrice" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "failedInfo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "message",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cards" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "serialNumber",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "pinCode",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "carrier",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "settledDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "usedFlag",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "carrierDataInfo",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "carrier",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "packageId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataAmount",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "duration",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPhoneCardOrderByIdQuery,
  GetPhoneCardOrderByIdQueryVariables
>;
export const CheckFlowChangePasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "checkFlowChangePassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phoneNumber" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PhoneNumber" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "checkFlowChangePassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "phoneNumber" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "phoneNumber" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "flow" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "idNumberFirst3Characters" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckFlowChangePasswordQuery,
  CheckFlowChangePasswordQueryVariables
>;
export const PayslipDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "payslip" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "loanApplicationQuery" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "LoanApplicationQuery" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentEmployment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "grossSalary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "grossAllowance" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "lanchi_totalCompensation",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shiftCountUnit" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currentWorkPeriod" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startTime" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endTimeInclusive" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attendances" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sumShiftCount",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "loanApplications" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "query" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "loanApplicationQuery",
                                    },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "total" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "limit" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "offset" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "sumAmounts" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdDate",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "payslip" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "earnings" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "category" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "deductions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "category" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PayslipQuery, PayslipQueryVariables>;
export const GetPhoneNumberDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getPhoneNumber" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "phoneNumber" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentEmployment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "phoneNumber" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPhoneNumberQuery, GetPhoneNumberQueryVariables>;
export const CurrentWorkPeriodSummaryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "currentWorkPeriodSummary" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentEmployment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currentWorkPeriod" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startTime" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endTimeInclusive" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "withdrawableBalance",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "earnedBalance" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "earnedBalanceCurrent",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "earnedBalanceMax" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attendances" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sumShiftCount",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "transactionSummary",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CurrentWorkPeriodSummaryQuery,
  CurrentWorkPeriodSummaryQueryVariables
>;
export const TopUpConfigDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "topUpConfig" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "phoneNumber" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentEmployment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currentWorkPeriod" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "transactionSummary",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "topUp_config" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "limitation" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "cellularNetworkConfigs",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "order" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "thumbnail" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "uri" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "discount",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopUpConfigQuery, TopUpConfigQueryVariables>;
export const Spending_ItemsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "spending_items" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "employmentId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "phoneNumber" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentEmployment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "spending_campaign" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "banner" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uri" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "time" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "from" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "to" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "detailUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "textColor" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "discountItems" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "employmentId" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "employmentId",
                                    },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "buyAttempt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "convertedItemCode",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "spending_items" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "employmentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "employmentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "type" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "originPrice" } },
                { kind: "Field", name: { kind: "Name", value: "actualPrice" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "image" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extension" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "replaceable" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "token" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cardInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierType" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "topUpInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierType" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Spending_ItemsQuery, Spending_ItemsQueryVariables>;
export const CalculateFeeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "calculateFee" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "employmentId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payload" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CalculateAdvanceV3Payload" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "advanceV3_calculate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "employmentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "employmentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "payload" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "payload" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "employeeReceiveAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "employeeFeeBeforePromotion" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "fee" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userFee" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "employerFee" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "detail" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "cashbackVoucherResponseDetails",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "voucher" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "cashbackResponseDetails",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "advanceV3ResponseDetails",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fee" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "userFee" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "employerFee",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CalculateFeeQuery, CalculateFeeQueryVariables>;

import { PropsWithChildren, useMemo } from "react";
import Dot from "./Dot";

interface IComponentProps extends PropsWithChildren {
  length?: number;
  value: string;
}

export default function PinInput({ value = "", length = 4 }: IComponentProps) {
  const pin = useMemo(() => {
    return value.slice(0, length);
  }, [length, value]);
  return (
    <>
      <div className="grid grid-cols-4 gap-8">
        {new Array(length).fill(0).map((_, idx) => {
          return <Dot key={`dot_${idx}`}>{pin[idx]}</Dot>;
        })}
      </div>
    </>
  );
}

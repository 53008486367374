import { gql } from "../__generated__/";

export const messagesQueryDocument = gql(/* GraphQL */ `
  query messages($query: MessageQuery!) {
    profile {
      id
      messages(query: $query) {
        nodes {
          id
          createdDate
          lastModifiedDate
          title
          content
          tags
          status
          group {
            id
            createdDate
            lastModifiedDate
            loanApplication {
              id
              createdDate
              lastModifiedDate
              status
              type
              amountValue
              amountCurrency
            }
            messages {
              id
              createdDate
              lastModifiedDate
              title
              content
              tags
              status
            }
          }
        }
        total
        limit
        offset
      }
    }
  }
`);

export const messageQueryDocument = gql(/* GraphQL */ `
  query messageDetail($id: ID!) {
    profile {
      id
      message(id: $id) {
        id
        createdDate
        lastModifiedDate
        title
        content
        tags
        status
        group {
          id
          createdDate
          lastModifiedDate
          loanApplication {
            id
            createdDate
            lastModifiedDate
            status
            type
            amountValue
            amountCurrency
          }
          messages {
            id
            createdDate
            lastModifiedDate
            title
            content
            tags
            status
          }
        }
      }
    }
  }
`);

export const totalUnReadMessageQueryDocument = gql(/* GraphQL */ `
  query totalUnReadMessage {
    profile {
      id
      messages(
        query: {
          limit: 1
          offset: 0
          filter: { tags: ["primary"], statuses: [Unread] }
        }
      ) {
        total
      }
    }
  }
`);

import { AuthResponse } from "@/apollo/__generated__/graphql";
import { API_URI, PLATFORM } from "@/constants";

export const exchangeAccessToken = async (
  refreshToken?: string | null
): Promise<AuthResponse | null> => {
  try {
    const response = await fetch(API_URI, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "x-platform": PLATFORM,
      },
      body: JSON.stringify({
        query: `mutation exchangeRefreshToken($token: String!) {
                  exchangeRefreshToken(token: $token) {
                    accessToken
                    expiresIn
                    refreshToken
                    refreshExpiresIn
                  }
                }`,
        variables: {
          token: refreshToken,
        },
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data?.data?.exchangeRefreshToken;
  } catch (error) {
    console.error("Failed to exchange access token: ", error);
    throw error;
  }
};

import { useNavigate } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import PrimaryContainerButton from "../components/button/PrimaryContainerButton";
import PageContainer from "../components/layouts/PageContainer";
import { RoutePath } from "../router/path";
import { numberToMoney } from "@/utils/money";
import useWithdrawalBalance from "@/hooks/useWithdrawalBalance";
import { format, subSeconds } from "date-fns";
import { useEffect, useMemo } from "react";
import { ShiftUnit } from "@/types/graphql-global-types";
import { gql, useApolloClient, useQuery } from "@apollo/client";
import { currentWorkPeriodSummaryQueryDocument } from "@/apollo/queries/summary.gql";
import { LoanApplicationType } from "@/apollo/__generated__/graphql";
import { ANALYTIC_CATEGORIES, trackEvent } from "@/helpers/events";

export const SummaryPage = () => {
  const client = useApolloClient();
  const { currentAdvanceablePeriod, withdrawableBalance, minAdvanceValue } =
    useWithdrawalBalance();
  const navigate = useNavigate();
  const handleClickWithdrawal = () => {
    // tracker
    trackEvent(
      ANALYTIC_CATEGORIES.LoanApplication,
      "press_withdraw_from_balance_detail"
    );

    if (withdrawableBalance < minAdvanceValue) {
      return;
    }

    navigate(RoutePath.Withdrawal);
    return;
  };

  const { data: summaryData } = useQuery(
    currentWorkPeriodSummaryQueryDocument,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    }
  );

  const transactionSummary =
    summaryData?.profile?.currentEmployment?.currentWorkPeriod
      .transactionSummary;

  const shiftCountUnitData = client.readQuery({
    query: gql`
      query GetShiftCountUnit {
        profile {
          id
          currentEmployment {
            id
            shiftCountUnit
          }
        }
      }
    `,
  });

  const startTimeObj = currentAdvanceablePeriod?.startTime
    ? new Date(currentAdvanceablePeriod?.startTime)
    : new Date();

  const endTimeObj = currentAdvanceablePeriod?.endTimeInclusive
    ? subSeconds(new Date(currentAdvanceablePeriod?.endTimeInclusive), 1)
    : new Date();

  const unit = useMemo(
    () =>
      shiftCountUnitData?.profile?.currentEmployment?.shiftCountUnit ===
      ShiftUnit.Hour
        ? "giờ"
        : "công",
    [shiftCountUnitData?.profile?.currentEmployment?.shiftCountUnit]
  );

  const totalAttendances = useMemo(
    () => currentAdvanceablePeriod?.workPeriod.attendances?.sumShiftCount,
    [currentAdvanceablePeriod?.workPeriod.attendances?.sumShiftCount]
  );

  const totalBalance = useMemo(
    () => currentAdvanceablePeriod?.workPeriod.earnedBalanceMax.value,
    [currentAdvanceablePeriod?.workPeriod.earnedBalanceMax.value]
  );

  const earnedBalance = useMemo(
    () =>
      Math.min(
        currentAdvanceablePeriod?.workPeriod.earnedBalanceCurrent.value,
        totalBalance
      ),
    [
      currentAdvanceablePeriod?.workPeriod.earnedBalanceCurrent.value,
      totalBalance,
    ]
  );

  const percentage = useMemo(
    () =>
      Math.round((totalBalance > 0 ? earnedBalance / totalBalance : 0) * 100),
    [earnedBalance, totalBalance]
  );

  const vasAmount = useMemo(
    () =>
      transactionSummary
        ?.filter(
          (trans) =>
            trans.type === LoanApplicationType.Card ||
            trans.type === LoanApplicationType.TopUp ||
            trans.type === LoanApplicationType.Billing ||
            trans.type === LoanApplicationType.Data
        )
        ?.reduce((prevValue, currentValue) => {
          return prevValue + currentValue.amount.value;
        }, 0) || 0,
    [transactionSummary]
  );

  const savingAmount = useMemo(
    () =>
      transactionSummary
        ?.filter((trans) => trans.type === LoanApplicationType.Saving)
        ?.reduce((prevValue, currentValue) => {
          return prevValue + currentValue.amount.value;
        }, 0) || 0,
    [transactionSummary]
  );

  const ewaAmount = useMemo(
    () =>
      transactionSummary
        ?.filter(
          (trans) =>
            trans.type === LoanApplicationType.SpendingAdvance ||
            trans.type === LoanApplicationType.Advance
        )
        ?.reduce((prevValue, currentValue) => {
          return prevValue + currentValue.amount.value;
        }, 0) || 0,
    [transactionSummary]
  );

  const payLaterAmount = useMemo(
    () =>
      transactionSummary
        ?.filter(
          (trans) =>
            trans.type === LoanApplicationType.PayLater ||
            trans.type === LoanApplicationType.Principal ||
            trans.type === LoanApplicationType.DailyInterest ||
            trans.type === LoanApplicationType.Fee
        )
        ?.reduce((prevValue, currentValue) => {
          return prevValue + currentValue.amount.value;
        }, 0) || 0,
    [transactionSummary]
  );

  const transferAmount = useMemo(
    () =>
      transactionSummary
        ?.filter((trans) => {
          return (
            trans.type === LoanApplicationType.QrTransfer ||
            trans.type === LoanApplicationType.Transfer
          );
        })
        ?.reduce((prevValue, currentValue) => {
          return prevValue + currentValue.amount.value;
        }, 0) || 0,
    [transactionSummary]
  );

  const totalUsedBalance = useMemo(
    () =>
      savingAmount + vasAmount + ewaAmount + payLaterAmount + transferAmount,
    [ewaAmount, payLaterAmount, transferAmount, savingAmount, vasAmount]
  );

  const usedPercentage = useMemo(
    () =>
      Math.round(
        (totalBalance > 0 ? totalUsedBalance / totalBalance : 0) * 100
      ),
    [totalUsedBalance, totalBalance]
  );

  useEffect(() => {
    // tracker
    trackEvent(ANALYTIC_CATEGORIES.LoanApplication, "load_vui_balance_detail");
  }, []);

  return (
    <PageContainer className="min-h-viewport bg-[#F5F5F5]">
      <PageHeader
        wrapperClassName="bg-white"
        title="Thẻ Lương Vui"
        hideRightButton
      />
      <div className="grid grid-cols-1 gap-3 p-4">
        <div className="flex w-full flex-col  gap-4 rounded-xl bg-primary-dark p-4 pb-2">
          <strong className="text-[15px] leading-[21px] text-white">
            Số tiền bạn đã kiếm được
          </strong>
          <div className="grid grid-cols-1 gap-3">
            <div className="relative ">
              <div className="h-7 w-full rounded-lg bg-primaryBlack/30">
                <div
                  className="h-7 rounded-lg bg-primary"
                  style={{ width: `${percentage}%` }}
                />
              </div>
              <div className="absolute bottom-0 right-3 top-0 flex h-7 items-center justify-end">
                <strong className="text-white">
                  {numberToMoney(earnedBalance, false, "đ")}
                </strong>
              </div>
            </div>
            <div className="w-full divide-y divide-white/10">
              <div className="flex h-10 w-full items-center justify-between pr-3">
                <span className="text-sm text-white">Kỳ lương</span>
                <span className="text-[15px] leading-[21px] text-white">
                  {format(startTimeObj, "dd/MM/yyyy")} -{" "}
                  {format(endTimeObj, "dd/MM/yyyy")}
                </span>
              </div>
              <div className="flex h-10 w-full items-center justify-between pr-3 ">
                <span className="text-sm text-white">Số công đi làm</span>
                <span className="text-[15px] leading-[21px] text-white">
                  {totalAttendances} {unit}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full flex-col  gap-4 rounded-xl bg-white p-4 pb-2">
          <strong className="text-[15px] leading-[21px] text-primaryBlack">
            Đã sử dụng trước
          </strong>
          <div className="grid grid-cols-1 gap-3">
            <div className="relative ">
              <div className="h-7 w-full overflow-y-hidden flex items-center rounded-lg bg-[#E5E7EB]">
                {usedPercentage > 0 && (
                  <div
                    className="h-8 rounded-lg bg-primaryOrange border-2 border-white"
                    style={{ width: `${usedPercentage}%` }}
                  />
                )}
              </div>
              <div className="absolute bottom-0 right-3 top-0 flex h-7 items-center justify-end">
                <strong className="text-primaryBlack">
                  {numberToMoney(totalUsedBalance, false, "đ")}
                </strong>
              </div>
            </div>
            <div className="w-full divide-y divide-darkGray/10">
              {ewaAmount > 0 && (
                <div className="flex h-10 w-full items-center justify-between pr-3">
                  <span className="text-sm text-darkGray">Rút lương</span>
                  <span className="text-[15px] leading-[21px] text-darkGray">
                    - {numberToMoney(ewaAmount, false, "đ")}
                  </span>
                </div>
              )}
              {vasAmount > 0 && (
                <div className="flex h-10 w-full items-center justify-between pr-3">
                  <span className="text-sm text-darkGray">
                    Thanh toán hoá đơn
                  </span>
                  <span className="text-[15px] leading-[21px] text-darkGray">
                    -{numberToMoney(vasAmount, false, "đ")}
                  </span>
                </div>
              )}
              {transferAmount > 0 && (
                <div className="flex h-10 w-full items-center justify-between pr-3">
                  <span className="text-sm text-darkGray">Chuyển tiền</span>
                  <span className="text-[15px] leading-[21px] text-darkGray">
                    -{numberToMoney(transferAmount, false, "đ")}
                  </span>
                </div>
              )}
              {savingAmount > 0 && (
                <div className="flex h-10 w-full items-center justify-between pr-3">
                  <span className="text-sm text-darkGray">Tích luỹ</span>
                  <span className="text-[15px] leading-[21px] text-darkGray">
                    -{numberToMoney(savingAmount, false, "đ")}
                  </span>
                </div>
              )}
              {payLaterAmount > 0 && (
                <div className="flex h-10 w-full items-center justify-between pr-3">
                  <span className="text-sm text-darkGray">Trả góp</span>
                  <span className="text-[15px] leading-[21px] text-darkGray">
                    -{numberToMoney(payLaterAmount, false, "đ")}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex w-full flex-col  gap-4 rounded-xl bg-white p-4 ">
          <div className="flex items-center justify-between pr-3">
            <strong className="text-[15px] leading-[21px] text-primaryBlack">
              Có thể sử dụng
            </strong>
            <strong className="text-[18px] leading-[25px] text-primary">
              {numberToMoney(withdrawableBalance, false, "đ")}
            </strong>
          </div>
          <hr className="bg-darkGray/10" />
          <span className="text-gray text-[15px] leading-[21px]">
            Hạn rút lương kỳ này: hết ngày {format(endTimeObj, "dd/MM")}
          </span>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0 mx-auto max-w-[480px] bg-white p-4">
        <PrimaryContainerButton
          onClick={handleClickWithdrawal}
          disabled={withdrawableBalance > minAdvanceValue ? false : true}
        >
          <span className="text-base font-bold text-white">Rút lương</span>
        </PrimaryContainerButton>
      </div>
    </PageContainer>
  );
};

export default SummaryPage;

import PageHeader from "@/components/PageHeader";
import PageContainer from "@/components/layouts/PageContainer";

export const DataSharingPolicyPage = () => {
  return (
    <PageContainer className="h-viewport flex flex-col">
      <PageHeader title="Chính sách bảo vệ dữ liệu cá nhân" hideRightButton />
      <div className="p-4 pt-0 flex-1">
        <iframe
          className="w-full h-full"
          src="https://vuiapp.vn/chinh-sach-bao-ve-du-lieu?disabledAll=1&checkedAll=true"
        />
      </div>
    </PageContainer>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from "react-router-dom";

import { useAppState } from "../hooks/useAppState";
import { agreementQueryDocument } from "@/apollo/queries/agreement.gql";
import { isApolloError, useApolloClient } from "@apollo/client";
import {
  CompanyStatus,
  EmploymentAgreementStatus,
  EmploymentStatus,
} from "@/apollo/__generated__/graphql";
import { RoutePath } from "@/router/path";
import FullScreenLoading from "@/components/FullScreenLoading";
import { useEffect, useRef } from "react";
import { identifyUser } from "@/helpers/tracker";
import { useRecoilCallback, useSetRecoilState } from "recoil";
import { hideSplashScreen } from "@/utils/splashScreen";
import * as Sentry from "@sentry/react";
import { defaultPhoneNumberState, phoneNumberState } from "@/state/phoneNumber";
import { graphqlErrorParser } from "@/helpers";
import { lastSyncTimeState } from "@/state/syncExternal";
import toast from "react-hot-toast";
import {
  syncEmploymentFromExternalSourceMutationDocument,
  unlinkUserEmploymentMutationDocument,
} from "@/apollo/mutations/home.gql";
import { DURATION_BETWEEN_TWO_SYNC } from "@/constants";
import { getPhoneNumberQueryDocument } from "@/apollo/queries/profile.gql";

const IndexPage = () => {
  const initialized = useRef(false);
  const navigate = useNavigate();
  const {
    state: { accessToken, phoneNumberChange: shouldForceUserUpdatePhoneNumber },
  } = useAppState();

  const getLastSyncedTime = useRecoilCallback(({ snapshot }) => () => {
    const loadable = snapshot.getLoadable(lastSyncTimeState);
    if (loadable.state === "hasValue") {
      return loadable.contents;
    }
  });

  const setLastSyncedTime = useSetRecoilState(lastSyncTimeState);

  const setPhoneNumberState = useSetRecoilState(phoneNumberState);

  const client = useApolloClient();

  useEffect(() => {
    const handleMutationError = async (error: any, id?: string) => {
      // Direct send error to Sentry
      Sentry.captureException(error);

      toast(graphqlErrorParser(error));
      if (isApolloError(error)) {
        if (
          id &&
          error.graphQLErrors[0]?.extensions &&
          error.graphQLErrors[0]?.extensions?.applicationCode ===
            "NGUYENKIM_EMPLOYEE_INFO_NOT_MATCHED"
        ) {
          await client.mutate({
            mutation: unlinkUserEmploymentMutationDocument,
            variables: { id },
            fetchPolicy: "no-cache",
          });

          navigate(RoutePath.Onboard);
          return;
        }
      }
      navigate(RoutePath.Home);
      return;
    };

    const syncEmploymentFromExternalSource = async (id?: string) => {
      try {
        if (!id) {
          navigate(RoutePath.Onboard);
          return;
        }
        const lastSyncedTime = getLastSyncedTime();
        const outOfDuration =
          Date.now() - lastSyncedTime > DURATION_BETWEEN_TWO_SYNC;
        if (
          id && // co employment
          outOfDuration // thoi gian sync > DURATION_BETWEEN_TWO_SYNC
        ) {
          await client.mutate({
            mutation: syncEmploymentFromExternalSourceMutationDocument,
            variables: { id },
            fetchPolicy: "no-cache",
            optimisticResponse: {
              syncEmploymentFromExternalSource: null,
            },
          });
        }
        setLastSyncedTime(Date.now());
      } catch (error) {
        handleMutationError(error, id);
      }
    };

    const navigateBasedOnAgreementStatus = async () => {
      // get agreement data
      const { data: agreementData } = await client.query({
        query: agreementQueryDocument,
        fetchPolicy: "no-cache",
      });

      // identify user
      if (agreementData.profile.phoneNumber) {
        identifyUser(agreementData?.profile?.phoneNumber, {
          phone: agreementData?.profile?.phoneNumber,
          username: agreementData?.profile?.phoneNumber,
        });
      }

      // if user has not completed onboarding
      if (!agreementData.profile.currentEmployment) {
        navigate(RoutePath.Onboard);
        return;
      }

      const agreementStatus =
        agreementData?.profile?.currentEmployment?.agreement?.status;

      // if user has not agreed to the agreement
      if (
        agreementStatus !== EmploymentAgreementStatus.Agreed && // chua dong y dieu khoan
        agreementData.profile.currentEmployment?.company.status !==
          CompanyStatus.Pilot // khong phai la pilot
      ) {
        navigate(RoutePath.Agreement);
        return;
      }

      if (
        agreementData.profile.currentEmployment?.status ===
        EmploymentStatus.Active
      ) {
        await syncEmploymentFromExternalSource(
          agreementData.profile.currentEmployment?.id
        );
      }

      if (shouldForceUserUpdatePhoneNumber) {
        // get latest phone number
        const result = await client.query({
          query: getPhoneNumberQueryDocument,
        });
        // Force user to update phone number
        if (
          result.data.profile.phoneNumber !==
            result.data.profile.currentEmployment?.phoneNumber &&
          result.data.profile.currentEmployment?.phoneNumber
        ) {
          setPhoneNumberState({
            ...defaultPhoneNumberState,
            currentPhoneNumber: result.data.profile.phoneNumber,
            phoneNumber: result.data.profile.currentEmployment?.phoneNumber,
            step: "opening",
          });

          navigate(RoutePath.UpdatePhoneNumber);
          return;
        }
      }

      navigate(RoutePath.Home);
      return;
    };

    const asyncAction = async () => {
      try {
        if (!accessToken) {
          navigate(RoutePath.Walkthrough);
          return;
        }
        // navigate to other page base on agreement status
        await navigateBasedOnAgreementStatus();
      } catch (error) {
        Sentry.captureException(error);
        // toast.error(graphqlErrorParser(error));
        navigate(RoutePath.ServiceIneligibilityError, {
          state: { errorMessage: graphqlErrorParser(error) },
        });
      } finally {
        hideSplashScreen();
      }
    };

    if (!initialized.current) {
      initialized.current = true;
      asyncAction();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <FullScreenLoading isOpen />;
};

export default IndexPage;

import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import { hideSplashScreen } from "@/utils/splashScreen";

function useHideBootSplash() {
  const location = useLocation();
  const hasHiddenBootSplash = useRef(false);

  useEffect(() => {
    if (location.pathname !== "/" && !hasHiddenBootSplash.current) {
      // Hide the splash screen
      hideSplashScreen();
      // Mark the splash screen as hidden
      hasHiddenBootSplash.current = true;
    }
  }, [location]);
}

export default useHideBootSplash;

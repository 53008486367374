import PageContainer from "../components/layouts/PageContainer";
import HomeHeader from "../components/home/HomeHeader";
import EWACard from "../components/home/EWACard";
import Menu from "../components/home/Menu";
import { usePersonalInformation } from "../hooks";
import useWithdrawalBalance from "../hooks/useWithdrawalBalance";
import VuiCopyright from "../components/home/VuiCopyright";
import { useNavigate } from "react-router-dom";
import HomeBanner from "../components/home/Banner/HomeBanner";

import { RoutePath } from "../router/path";
import GrayCard from "@/components/home/GrayCard";
import { defaultFeatures, vasFeatures } from "@/components/home/Menu/data";
import { lazy, Suspense, useMemo, useState } from "react";
import Modal from "@/components/Modal";
import { numberToMoney } from "@/utils/money";
import { ANALYTIC_CATEGORIES, trackEvent } from "@/helpers/events";
import withScrollReset from "@/hocs/withScrollReset";
// import VoucherHomeWidget from "@/components/Voucher/widgets/Home";
import { LayoutGroup } from "framer-motion";

const VoucherHomeWidget = lazy(
  () => import("@/components/Voucher/widgets/Home")
);

const HomePage = () => {
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const { personalInformation, loading } = usePersonalInformation();

  const { withdrawableBalance, shouldShowGrayCard, minAdvanceValue } =
    useWithdrawalBalance();

  const handleClickWithdrawal = () => {
    // tracker
    trackEvent(ANALYTIC_CATEGORIES.LoanApplication, "press_withdrawal", {
      name: "Get Paid",
    });

    if (minAdvanceValue > withdrawableBalance) {
      setModalVisible(true);
      return;
    }

    navigate(RoutePath.Withdrawal);
  };

  const handleClickDetail = () => {
    // tracker
    trackEvent(ANALYTIC_CATEGORIES.LoanApplication, "press_home_card_detail");
    navigate(RoutePath.Summary);
  };

  const yourSalaryFeatures = useMemo(() => {
    const shouldShowAttendance =
      personalInformation?.currentEmployment?.features.attendanceToggle;
    const shouldShowPayslip =
      personalInformation?.currentEmployment?.company.features.payslip;

    return defaultFeatures.filter((feature) => {
      if (feature.name === "attendance") {
        return shouldShowAttendance;
      }

      if (feature.name === "payslip") {
        return shouldShowPayslip;
      }

      return true;
    });
  }, [
    personalInformation?.currentEmployment?.company.features.payslip,
    personalInformation?.currentEmployment?.features.attendanceToggle,
  ]);

  const yourVasFeatures = useMemo(() => {
    const shouldShowTopUp =
      personalInformation?.currentEmployment?.features.topUp;
    const shouldShowPhoneCard =
      personalInformation?.currentEmployment?.features.softpin;

    return vasFeatures.filter((feature) => {
      if (feature.name === "top_up") {
        return shouldShowTopUp;
      }

      if (feature.name === "phone_card") {
        return shouldShowPhoneCard;
      }

      return true;
    });
  }, [
    personalInformation?.currentEmployment?.features.topUp,
    personalInformation?.currentEmployment?.features.softpin,
  ]);

  return (
    <>
      <PageContainer className="flex min-h-viewport pb-14 flex-col bg-white px-4">
        <div className="flex flex-1 flex-col gap-y-4">
          <LayoutGroup id="home">
            <HomeHeader
              name={personalInformation?.currentEmployment?.fullName}
            />
            <HomeBanner />
            {shouldShowGrayCard ? (
              <GrayCard
                logoUrl={
                  personalInformation?.currentEmployment?.company.logo?.uri
                }
              />
            ) : (
              <EWACard
                withdrawableBalance={withdrawableBalance}
                logoUrl={
                  personalInformation?.currentEmployment?.company.logo?.uri
                }
                onClickDetail={handleClickDetail}
                onClickWithdraw={handleClickWithdrawal}
              />
            )}

            <Suspense fallback={null}>
              <VoucherHomeWidget />
            </Suspense>

            <Menu
              title="Tiền lương của bạn"
              features={yourSalaryFeatures}
              loading={loading}
            />
            <Menu
              title="Thanh toán vạn năng"
              features={yourVasFeatures}
              loading={loading}
            />
          </LayoutGroup>
        </div>
        <div className="px-4 py-2">
          <VuiCopyright />
        </div>
      </PageContainer>
      <Modal
        open={modalVisible}
        onClose={(val) => setModalVisible(val)}
        onConfirm={() => {
          setModalVisible(false);
        }}
        confirmText="Đóng"
        textClassName="text-center"
        title="Rất tiếc, bạn không thể rút lương sớm hôm nay 🙁"
        description={`Vì số dư hiện tại của bạn dưới ${numberToMoney(
          minAdvanceValue / 1000,
          false,
          "K"
        )}. Làm thêm công, lương sẽ về!`}
      />
    </>
  );
};

const HomePageWithScrollReset = withScrollReset(HomePage);
export default HomePageWithScrollReset;

import PageHeader from "@/components/PageHeader";
import PrimaryContainerButton from "@/components/button/PrimaryContainerButton";
import PageContainer from "@/components/layouts/PageContainer";
import { useAppState } from "@/hooks/useAppState";
import { useForm } from "react-hook-form";
import {
  NavLink,
  Navigate,
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  LATEST_PHONE_REGEX,
  internationalizeVietnamesePhone,
} from "@/utils/phone";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";
import FullScreenLoading from "@/components/FullScreenLoading";
import { useMutation } from "@apollo/client";
import { requestLoginMutationDocument } from "@/apollo/mutations/login.gql";
import { OTP_LENGTH } from "@/constants";
import { graphqlErrorParser } from "@/helpers";
import { RoutePath } from "@/router/path";
import {
  RequestLoginMutation,
  RequestLoginMutationVariables,
} from "@/apollo/__generated__/graphql";
import { ANALYTIC_CATEGORIES, trackEvent } from "@/helpers/events";
import { Suspense, lazy } from "react";
import * as Yup from "yup";
import toast from "react-hot-toast";

const Icon = lazy(() => import("@/components/icons/Phone"));

type LoginInputs = {
  phoneNumber: string;
};

const formSchema = Yup.object({
  phoneNumber: Yup.string()
    .matches(LATEST_PHONE_REGEX, "Số điện thoại không hợp lệ")
    .required("Vui lòng nhập số điện thoại"),
});

export function LoginPage() {
  const navigate = useNavigate();
  const {
    state: { deviceToken, phoneNumber },
    setState,
  } = useAppState();

  const [requestLoginOtpMutation] = useMutation<
    RequestLoginMutation,
    RequestLoginMutationVariables
  >(requestLoginMutationDocument);

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isDirty },
    register,
    reset,
  } = useForm<LoginInputs>({
    defaultValues: async () => ({
      phoneNumber: phoneNumber || "",
    }),
    resolver: yupResolver(formSchema),
    mode: "onChange",
  });

  const onSubmit = async (data: LoginInputs) => {
    try {
      // tracker
      trackEvent(ANALYTIC_CATEGORIES.RegisterCredential, "submit_phone", {
        name: data.phoneNumber,
      });

      const { data: requestLoginData } = await requestLoginOtpMutation({
        variables: {
          payload: {
            phoneNumber: data.phoneNumber,
            otpLength: OTP_LENGTH,
          },
        },
      });
      if (requestLoginData?.requestLogin) {
        const searchStr = createSearchParams({
          isNew: requestLoginData.requestLogin.isNew ? "1" : "0",
          phoneNumber: data.phoneNumber,
          requestId: requestLoginData.requestLogin.token,
        });

        setState((prevState) => ({
          ...prevState,
          phoneNumber: internationalizeVietnamesePhone(data.phoneNumber),
        }));

        navigate(`${RoutePath.VerifyOTP}?${searchStr}`);
      }
    } catch (error) {
      toast.error(graphqlErrorParser(error), {
        id: "login-error",
        duration: 10_000,
      });
    }
  };

  if (deviceToken) {
    return (
      <Navigate
        to={RoutePath.VerifyPin}
        replace
        state={{ from: RoutePath.Login }}
      />
    );
  }

  return (
    <>
      <PageContainer className="h-viewport">
        <PageHeader title="" hideRightButton />
        <form
          id="login-form"
          onSubmit={handleSubmit(onSubmit)}
          className="px-4 pt-4 grid gap-6"
        >
          <Suspense fallback={<div className="w-16 h-16" />}>
            <Icon className="w-16 h-16 text-primary/10" />
          </Suspense>
          <h3 className="text-2xl font-bold text-primaryBlack">
            Số điện thoại
          </h3>
          <div className="relative">
            <label htmlFor="phone" className="sr-only">
              Phone
            </label>
            <input
              type="tel"
              id="phone"
              className={twMerge(
                "appearance-none outline-none px-3.5 block mb-2 lining-nums font-bold w-full text-lg h-14 rounded-xl border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-300 focus:ring-1 focus:ring-primary disabled:bg-gray-100 disabled:ring-0 disabled:cursor-not-allowed",
                errors.phoneNumber?.message
                  ? "ring-1 ring-inset ring-red-500"
                  : ""
              )}
              placeholder="Nhập số điện thoại của bạn"
              disabled={!!phoneNumber || isSubmitting} // disable input if phone number is already set
              {...register("phoneNumber")}
            />
            {isDirty && (
              <button
                type="button"
                className="absolute top-3 right-3 bottom-3 w-8 h-8 flex justify-center items-center"
                onClick={() => reset({ phoneNumber: "" })}
              >
                <div className="w-5 h-5 p-0.5 bg-gray-200 rounded-full">
                  <XMarkIcon className="w-4 h-4  text-white" />
                </div>
              </button>
            )}
            {errors.phoneNumber?.message && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {errors.phoneNumber?.message}
              </p>
            )}
          </div>
        </form>
      </PageContainer>
      <div className="fixed bottom-0 left-0 right-0 mx-auto max-w-[480px] bg-white p-4 grid gap-4">
        <p className="text-center text-sm text-gray96">
          Bằng việc bấm “Tiếp tục”, tôi đồng ý với{" "}
          <NavLink to={RoutePath.TermAndCondition}>
            <span className="text-primary hover:underline">
              Điều&nbsp;khoản&nbsp;bảo&nbsp;mật,&nbsp;Chính&nbsp;sách&nbsp;dịch&nbsp;vụ
            </span>
          </NavLink>
        </p>
        <PrimaryContainerButton
          type="submit"
          form="login-form"
          disabled={!isValid || isSubmitting}
        >
          {isSubmitting && (
            <svg
              className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          )}
          <span className="text-base font-bold text-white group-disabled:text-inherit">
            Tiếp tục
          </span>
        </PrimaryContainerButton>
      </div>
      <FullScreenLoading isOpen={isSubmitting} />
    </>
  );
}

export default LoginPage;

export enum RoutePath {
  Index = "/",
  Walkthrough = "/walkthrough",
  Home = "/home",
  CreatePin = "/create-pin",
  VerifyPin = "/verify-pin",
  Attendance = "/attendance",
  Transaction = "/transactions",
  Profile = "/profile",
  NotFound = "/not-found",
  Onboard = "/onboard",
  Agreement = "/agreement",
  Withdrawal = "/withdrawal",
  TopUp = "/top-up",
  TopUpConfirmation = "/top-up/:orderId/confirmation",
  TopUpSuccess = "/top-up/:orderId/success",
  TopUpFailure = "/top-up/:orderId/failure",
  PhoneCard = "/phone-card",
  PhoneCardConfirmation = "/phone-card/:orderId/confirmation",
  PhoneCardSuccess = "/phone-card/:orderId/success",
  PhoneCardFailure = "/phone-card/:orderId/failure",
  Summary = "/summary",
  Payslip = "/payslip",
  TransactionDetail = "/transactions/:transactionId",
  InAppMessage = "/messages",
  InAppMessageDetail = "/messages/:messageId",
  Login = "/login",
  VerifyOTP = "/verify-otp",
  ForgotPassword = "/forgot-password",
  Setting = "/setting",
  Support = "/support",
  FAQ = "/faq",
  TermAndCondition = "/term-and-condition",
  DataSharingPolicy = "/data-sharing-policy",
  DataSharingConsent = "/data-sharing-consent",
  UpdatePhoneNumber = "/update-phone-number",
  ServiceIneligibilityError = "/service-ineligibility-error",
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError, isApolloError } from "@apollo/client";

export const COMMON_ERROR_MESSAGE =
  "Đã xảy ra lỗi. Vui đang kiểm tra ✊ Bạn thử lại sau nhé";

export const graphqlErrorParser = (error: any): string => {
  let msg = COMMON_ERROR_MESSAGE;

  if (isApolloError(error)) {
    const { graphQLErrors, networkError, message } = error as ApolloError;
    //

    if (graphQLErrors && graphQLErrors.length > 0) {
      const err = graphQLErrors[0] as any; //

      if (err.extensions && "code" in err.extensions) {
        switch (err.extensions.code) {
          case 401:
            if (err.extensions?.data?.disabled) {
              msg = "Tài khoản của bạn đã bị khóa";
            } else if (
              err.extensions.data?.numFailures &&
              err.extensions.data?.failureFactor
            ) {
              msg = `Bạn đã nhập sai ${
                err.extensions.data?.numFailures || 0
              } lần. Bạn sẽ bị TẠM KHÓA nếu nhập sai liên tiếp ${err.extensions
                .data?.failureFactor} LẦN`;
            } else {
              msg = err.message;
            }
            break;
          default:
            if (err.extensions.code.toString().startsWith("4")) {
              if (err.extensions && "message" in err.extensions) {
                msg = err.extensions.message;
              } else {
                msg = err.message;
              }
            }
            break;
        }
      } else if (err.extensions && "message" in err.extensions) {
        msg = err.extensions.message;
      } else {
        msg = err.message;
      }
    } else if (networkError) {
      if ("statusCode" in networkError) {
        switch (networkError.statusCode) {
          case 403:
            msg = "IP của bạn không hợp lệ. Thay đổi IP và đăng nhập lại nhé";
            break;
          case 502:
          case 503:
            msg = "Vui tạm thời mất kết nối máy chủ. Bạn thử lại sau nhé";
            break;
          case 504:
            msg = "Hết thời gian đăng nhập. Thử lại nhé";
            break;
          default:
            msg = COMMON_ERROR_MESSAGE;
            break;
        }
      } else {
        msg = "Không có kết nối mạng 😞";
      }
    } else if (message) {
      msg = message;
    }
  } else {
    if (error?.message) {
      return error.message;
    }
  }

  return msg;
};

import PageContainer from "@/components/layouts/PageContainer";
import { useNavigate } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/20/solid";
import { lazy } from "react";

const NotFoundIcon = lazy(() => import("@/assets/icons/not-found.svg?react"));

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoIndex:
    | React.MouseEventHandler<HTMLButtonElement>
    | undefined = (e) => {
    e.preventDefault();
    navigate("/");
  };

  return (
    <PageContainer className="flex h-viewport w-full flex-col items-center justify-center gap-4">
      <NotFoundIcon />
      <p className="text-center">Không tìm thấy trang</p>
      <button
        onClick={handleGoIndex}
        type="button"
        className="inline-flex items-center gap-x-1.5 rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm"
      >
        <HomeIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
        Về trang chủ
      </button>
    </PageContainer>
  );
};

export default NotFoundPage;

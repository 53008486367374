import { ShiftUnit } from "@/types/graphql-global-types";

type Props = {
  unit?: ShiftUnit | null;
  totalStandard?: number;
  totalRecordMissing?: number;
  totalNightShift?: number;
};

function Instructions({
  unit = ShiftUnit.Day,
  totalStandard,
  totalRecordMissing,
}: Props) {
  return (
    <div className="grid grid-cols-1 rounded-xl bg-white px-4 py-2 shadow-main">
      <div className="group flex flex-row items-center gap-x-3">
        <div className="h-5 w-5 rounded-md bg-primary" />
        <div className="flex h-12 flex-1 flex-row items-center justify-between gap-x-4 border-b group-last:border-b-0">
          <div className="text-base font-medium text-darkGray">Có công</div>
          <div className="text-base font-bold text-darkGray">
            {totalStandard} {unit === ShiftUnit.Hour ? "giờ" : "ngày"}
          </div>
        </div>
      </div>
      <div className="group flex flex-row items-center gap-x-3">
        <div className="h-5 w-5 rounded-md bg-primaryOrange" />
        <div className="flex h-12 flex-1 flex-row items-center justify-between gap-x-4 border-b group-last:border-b-0">
          <div className="text-base font-medium text-darkGray">Thiếu công</div>
          <div className="text-base font-bold text-darkGray">
            {totalRecordMissing} {unit === ShiftUnit.Hour ? "giờ" : "ngày"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Instructions;

/* eslint-disable @typescript-eslint/no-explicit-any */
import OTPInput from "@/components/OTPInput";
import { OTP_LENGTH } from "@/constants";
import { graphqlErrorParser } from "@/helpers";
import {
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import Sheet from "react-modal-sheet";
import { twMerge } from "tailwind-merge";
import { useCountdown } from "usehooks-ts";

export type BottomSheetRef = {
  show: () => void;
  hide: () => void;
  clearInput: () => void;
};

interface IComponentProps extends PropsWithChildren {
  onResend?: () => void;
  onEndEditing?: (otp: string) => void;
  error?: unknown;
  phoneNumber: string;
}

export default forwardRef<BottomSheetRef, IComponentProps>(
  function SignToCBottomSheet(
    { onResend, phoneNumber, error, onEndEditing }: IComponentProps,
    ref
  ) {
    const [sheetVisible, setSheetVisible] = useState<boolean>(false);
    const sheetRef = useRef<any>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [otpValue, setOtpValue] = useState("");

    const [count, { startCountdown, resetCountdown }] = useCountdown({
      countStart: 60,
      countStop: 0,
      intervalMs: 1000,
    });

    const countdownText = useMemo(() => {
      const mins = Math.floor(count / 60);
      const secs = Math.floor(count % 60);

      return `${mins < 10 ? "0" + mins : mins}:${
        secs < 10 ? "0" + secs : secs
      }`;
    }, [count]);

    const handleClose: React.MouseEventHandler<HTMLButtonElement> | undefined =
      useCallback((e: any) => {
        e.preventDefault();
        setSheetVisible(false);
      }, []);

    useEffect(() => {
      if (sheetVisible) {
        document.body.classList.add("overflow-y-hidden");
      } else {
        document.body.classList.remove("overflow-y-hidden");
      }
    }, [sheetVisible]);

    useImperativeHandle(
      ref,
      () => {
        return {
          show() {
            {
              setSheetVisible(true);
              startCountdown();
            }
          },
          hide() {
            sheetRef.current &&
              sheetRef.current?.snapTo &&
              sheetRef.current?.snapTo(0);

            resetCountdown();
          },
          clearInput() {
            setOtpValue("");
          },
        };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    useEffect(() => {
      return () => {
        if (document.body.classList.contains("overflow-y-hidden")) {
          document.body.classList.remove("overflow-y-hidden");
        }
      };
    }, []);

    useEffect(() => {
      if (otpValue.length === OTP_LENGTH) {
        inputRef.current?.blur();
        onEndEditing && onEndEditing(otpValue);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otpValue]);

    return (
      <Sheet
        isOpen={sheetVisible}
        onClose={() => setSheetVisible(false)}
        ref={sheetRef}
        detent="content-height"
      >
        <Sheet.Container>
          <Sheet.Content>
            <div className="flex items-center justify-between border-b border-b-[#A9A9A91A] px-2 py-2 font-primary">
              <button
                type="button"
                onClick={handleClose}
                className="z-10 flex h-8 w-8 items-center justify-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <div className="flex-1">
                <p className="text-center text-base font-bold text-primaryBlack">
                  Nhập mã xác thực
                </p>
              </div>
              <div className="flex h-8 w-8 items-center justify-center" />
            </div>
            <div className="px-5 grid gap-6 py-8 font-primary lining-nums">
              <p className="text-[15px] leading-[21px] text-darkGray">
                Mã xác thực {OTP_LENGTH} ký tự được gửi tới số{" "}
                <strong className="text-primary">{phoneNumber}</strong>
              </p>
              <div className="flex flex-col items-start gap-2">
                <div tabIndex={-1} onClick={() => inputRef.current?.focus()}>
                  <OTPInput length={OTP_LENGTH} value={otpValue} />
                  <input
                    className="sr-only"
                    ref={inputRef}
                    inputMode="tel"
                    name="otp"
                    value={otpValue}
                    autoComplete="one-time-code"
                    onChange={(e) =>
                      setOtpValue(e.target.value.slice(0, OTP_LENGTH))
                    }
                  />
                </div>
                {error ? (
                  <p className="text-left text-[13px] leading-5  text-[#FF2638] lining-nums">
                    {graphqlErrorParser(error)}
                  </p>
                ) : null}
                <div className="w-full py-4 text-center text-sm font-medium">
                  {count ? (
                    <strong className="text-gray96">
                      Gửi lại mã sau {countdownText} giây
                    </strong>
                  ) : (
                    <>
                      <span className="text-gray96">
                        Không nhận được mã?&nbsp;
                      </span>
                      <strong
                        role="button"
                        onClick={onResend}
                        className={twMerge(
                          "inline ",
                          count
                            ? "cursor-not-allowed text-darkGray"
                            : "cursor-pointer text-primary"
                        )}
                      >
                        Gửi lại
                      </strong>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full h-[322px]"></div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          className="bg-transparent"
          onTap={() => setSheetVisible(false)}
        />
      </Sheet>
    );
  }
);
